import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import { PageLayout } from "../../../components/Layout/PageLayout";
import styles from "./DeveloperConfirmation.module.scss";
import { Happy_Face } from "../../../assets/images/index";
import { PageContent } from "../../../components/Layout/PageContent";

export const DeveloperConfirmation = () => {
  const { t } = useTranslation("onboarding");
  const location = useLocation();
  const isEditProfileFromViewPage = location.pathname.includes("profile");


  return (
    <PageLayout>
      <PageContent>
        <div className={styles.confirmationContainer}>
          <img
            data-testid="happy-face"
            src={Happy_Face}
            alt={t("developer.review_profile.smileyalt-icon")}
          />

          <div className={styles.successInfoContainer}>
            <h2 data-testid="confirmation-heading" className={styles.heading}>
              {isEditProfileFromViewPage
                ? t("developer.review_profile.success.heading_update")
                : t("developer.review_profile.success.heading")}
            </h2>
            <p data-testid="confirmation-content" className={styles.info}>
              {isEditProfileFromViewPage
                ? t("developer.review_profile.success.content_update")
                : t("developer.review_profile.success.content")}
            </p>
          </div>

          <div className={styles.successLinkList} data-testid="success-links">
            {isEditProfileFromViewPage ?
              <Link to="/search/investors" className={styles.successInfoLink}>
                {t("developer.review_profile.success.find_investor")}
              </Link>
              :
              <Link to="/onboarding/developer/create" className={styles.successInfoLink}>
                {t("developer.review_profile.success.create_listing")}
              </Link>
            }
            <Link to="/profile/developer" className={styles.successInfoLink}>
              {t("developer.review_profile.success.view_profile")}
            </Link>
          </div>
        </div>
      </PageContent>
    </PageLayout>
  );
};
