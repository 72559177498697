import styles from "./styles.module.scss";
import { useTranslation } from "react-i18next";
import { CheckMark2 } from "../../../assets/icons/index";
import { Link } from "react-router-dom";

export function AcceptedRequest() {
  const { t } = useTranslation(["connectionStatus"]);
  return (
    <div className={styles.connectionDivSuccess}>
      <img
        src={CheckMark2}
        alt={t("alt_text_check_icon")}
        className={styles.iconCheck}
        data-testid="image-icon"
      />
      <h4>
        {t("success_text")}
        <Link
          to={"/connection"}
          className={styles.cardDetailLink}
          data-testid="connection-link"
        >
          {t("hub_link_text")}
        </Link>
      </h4>
    </div>
  );
}
