import { useField, useFormikContext } from "formik";
import { CommonErrorMessage } from "./CommonErrorMessage";
import DatePicker from "react-datepicker";
import styles from "./styles.module.scss";
import "react-datepicker/dist/react-datepicker.css";
import { CalendarToday } from "@mui/icons-material";
import React from "react";

type Props = {
  labelText: string;
  fieldName: string;
  placeholderText?: string;
  [x: string]: unknown;
};

export function DateInput({
  labelText,
  fieldName,
  placeholderText = "",
  ...rest
}: Props) {
  const { setFieldValue } = useFormikContext();
  const [field, meta] = useField(fieldName);

  const CustomInput = (
    props: React.HTMLProps<HTMLInputElement>,
    ref: React.Ref<HTMLInputElement>
  ) => {
    return (
      <>
        <input
          {...props}
          ref={ref}
          className={`${styles.textInput} ${styles.customDateInput} ${
            meta.error && meta.touched ? styles.errorStateInput : null
          }`}
        />

        <CalendarToday className={styles.calendarIcon} />
      </>
    );
  };

  const dateCheckerSetter = (d: Date | null) => {
    if (typeof d === "object" && d !== null && "toLocaleDateString" in d) {
      return d.toLocaleDateString("en-US");
    } else {
      return new Date().toLocaleDateString("en-US");
    }
  };

  return (
    <div className={styles.fieldContainer}>
      <label
        className={`${styles.labelStyle} ${
          meta.error && meta.touched ? styles.errorStateLabel : null
        }`}
        htmlFor={field.name}
      >
        {labelText}
      </label>
      <DatePicker
        className={`${styles.textInput} ${
          meta.error && meta.touched ? styles.errorStateInput : null
        }`}
        id={field.name}
        {...field}
        {...rest}
        dateFormat="MM/dd/yyyy"
        placeholderText={placeholderText}
        showYearDropdown
        dateFormatCalendar="MMMM"
        yearDropdownItemNumber={15}
        scrollableYearDropdown
        selected={(field.value && new Date(field.value)) || null}
        onChange={(val) => setFieldValue(field.name, dateCheckerSetter(val))}
        customInput={React.createElement(React.forwardRef(CustomInput))}
      />
      <CommonErrorMessage fieldName={field.name} />
    </div>
  );
}
