import { Modal } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import styles from "./WaitlistForm.module.scss";
import { ErrorOutlined } from '../../../assets/icons';
import { useTranslation } from "react-i18next";
type Props = {
  termsTitle: string;
  termsStartBody: { title: string; description: string; }[];
  openState: boolean;
  handleClose: () => void;
};

export function LearnMoreModal (props: Props) {
    const { t } = useTranslation("waitlist");
  return (
    <Modal
      open={props.openState}
      onClose={props.handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      data-testid="terms-modal"
    >
      <div className={styles.termsModalContainer}>
        <div className={styles.termsModalHeadingContainer}>
            <div className={styles.LearnMoreHeadingImg}>
            <img
              src={ErrorOutlined}
              alt=""
              data-testid="exclamation-Icon"
            />
          <h2 id="modal-modal-title" className={styles.termsModalHeading}>
            {props.termsTitle}
          </h2>
            </div>
          <button
            onClick={props.handleClose}
            className={styles.termsModalCloseButton}
          >
            <CloseIcon />
          </button>
        </div>
        <p
          id="modal-modal-description"
          className={styles.termModelBodyContainer}
        >
         
          {props.termsStartBody && props.termsStartBody.map((i)=>(
          <>
          <h3 className={styles.learnMoreModalTitle}>{i.title}</h3>
          <p className={styles.learnMoreModalDescription}>{i.description}</p>
          </>
          ))}
        </p>
        <div style={{display:"flex", justifyContent:"center"}}>
        <button  onClick={props.handleClose} className={styles.learnMoreModalButton}>
        {t("waitlist_form.close_btn")}
        </button>
      </div>
        </div>
    </Modal>
  );
}
