import styles from "./styles.module.scss";

type Props = {
  dataLabel: string;
  arrayOfValues: string[];
};

export function IterableValuesDisplay({ dataLabel, arrayOfValues }: Props) {
  return (
    <div>
      <p className={styles.iterableLabel}>{dataLabel}</p>
      <div className={styles.iterablesContainer}>
        {arrayOfValues.map((val, idx) => (
          <p key={idx} className={styles.iterableValue}>
            {`${val}${idx < arrayOfValues.length - 1 ? "," : ""}`}&nbsp;
          </p>
        ))}
      </div>
    </div>
  );
}
