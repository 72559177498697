import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ListingProjectAPI } from "../../APIAction/ListingProjectAPI";
import { ListingPortfolioAPI } from "../../APIAction/ListingPortfolioAPI";
import { DeveloperApi } from "../../APIAction/DeveloperAPI";
import { ProjectCard } from "../../components/common/Cards/ProjectCard";
import { SavedProjectCard } from "../../components/common/Cards/SavedProjectCard";
import { SavedPortfolioCard } from "../../components/common/Cards/SavedPortfolioCard";
import { CreatePortfolio } from "../Developers/Modals/CreatePortfolio";

import { ProjectCardSkeleton } from "../../components/common/Cards/ProjectCardSkeleton";
import { PaginationElement } from "../../components/common/PaginationElement/PaginationElement";
import { ProjectsFilterSidebar } from "../../components/common/SearchFilterSidebar/ProjectsFilterSidebar";
import { Title } from "../../components/common/Title/Title";
import { PageContent } from "../../components/Layout/PageContent";
import { PageLayout } from "../../components/Layout/PageLayout";
import { NoListedProjectsResults } from "./noListedProjectsResults";
import { NoListedMyProjectsResults } from "./noListedMyProjectsResults";
import { NoListedProjectsResultsWithFilter } from "./noListedProjectsResultsWithFilter";
import { NoListedSavedProjectsResults } from "./noListedSavedProjectsResults";

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { useUser } from "../../components/Context/UserContext";
import { InvestorAPI } from "../../APIAction/InvestorAPI";
import Tooltip from '@mui/material/Tooltip';
import { Options } from 'react-select';
import { PortfolioCard } from "../../components/common/Cards/PortfolioCard";
import styles from "./styles.module.scss";
import i18n from "../../i18n";
import { useMsal } from "@azure/msal-react";

export interface DropdownOptionType {
  label: string;
  value: string;
}
export function SearchProjects() {
  const { userState } = useUser();
  const initialSearchValues = {
    start: 0,
    pageSize: 6,
    sortDirection: 0,
    sortColumn: "",
    investorID: "",
    developerID: "",
    filter: {
      projectListingStatuses: [2],
      portfolioStatuses: [2],
      fundingTypes: [],
      investmentPhases: [],
      totalFundingNeededMaximum: null,
      totalFundingNeededMinimum: null,
      investmentRangeMinimum: null,
      investmentRangeMaximum: null,
    },
  };
  const [filteredValues, setFilteredValues] = useState(
    {} as any
  );
  const [filters, setFilters] = useState(initialSearchValues);

  const [impactCriteriaList, setImpactCriteriaList] = useState<
    Options<DropdownOptionType>
  >([]);

  const [cityList, setCityList] = useState([]);
  const [selectedFilter, setSelectedFilter] = useState<any>(null);
  const [createPortfolio1, setCreatePortfolio1] = useState<boolean>(false);
  const { accounts } = useMsal();

  const [expandList, setExpandList] = useState([true, false, false, false, false, false]);

  const [page, setPage] = useState(parseInt(localStorage.getItem("lastPage") || "1"));
  const [loadingData, setloadingData] = useState(true);
  const { t } = useTranslation("filter");
  const localStorageTabIndex = parseInt(
    localStorage.getItem("lastTabIndex") || "0"
  );
  const [value, setValue] = useState(localStorageTabIndex);
  const navigate = useNavigate();

  useEffect(() => {
    setValue(localStorageTabIndex);
  }, [setValue]);

  useEffect(() => {
    const gaUserType =
      accounts[0] && accounts[0].idTokenClaims?.extension_Persona
        ? (accounts[0].idTokenClaims.extension_Persona as string)
        : "";
    const gaUserId = accounts[0] ? accounts[0].localAccountId : "";

    if (accounts[0]) {
      const pageTitle = value === 0 ? "/search/projects" : value === 1 && userState.userPersona !== "Investor" ? "/search/myprojects" :value === 1 && userState.userPersona === "Investor" ? "/search/savedprojects": value === 2 ? "/search/portfolios" : value ===3 && userState.userPersona !== "Investor" ? "search/myportfolios": "seacrh/savedportfolios";

      if (window.dataLayer[window.dataLayer.length - 1].page_title !== pageTitle) {

        window.dataLayer.push({
          event: "virtual_page_view",
          page_title: pageTitle,
          page_language: i18n.language,
          page_location: window.location.href,
          page_environment: process.env.REACT_APP_ENV
            ? process.env.REACT_APP_ENV
            : "dev",
          user_status: "loggedin",
          user_type: gaUserType.toLowerCase(),
          user_id: gaUserId,
        });
      }
    }

  }, [value]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    setloadingData(true);

    const getAPIData = async () => {
      try {
        filters.start = (page - 1) * filters.pageSize;


        if (value === 0) {

          filters.filter.projectListingStatuses = [2];
          let filters2 = filters;

          let response = await ListingProjectAPI.searchListingProjects(filters2);

          if (userState.userPersona === "Investor") {
            if(userState.userId !== null && userState.userId !=='') {
            let investorProjects: any = await InvestorAPI.getInvestor(userState.userId);

            const savedProjectListings = investorProjects.data.savedProjectListings;
            for (let i = 0; i < response.data.length; i++) {

              for (let j = 0; j < savedProjectListings.length; j++) {

                if (response.data[i].id === savedProjectListings[j].projectListingID) {
                  response.data[i].saved = true;

                } if (!response.data[i].saved) {
                  response.data[i].saved = false;

                }
              }
            }
          }
        }
        else {
          for (let i = 0; i < response.data.length; i++) {
          if (!response.data[i].saved) {
            response.data[i].saved = false;
          }
        }
        }

          setFilteredValues(response);
          setloadingData(false);
        } else if (value === 1) {

          if (userState.userPersona !== "Investor") {
            filters.developerID = userState.userId;
            filters.filter.projectListingStatuses = [1, 2, 3]
            const res = await DeveloperApi.searchDeveloperProjectsInfo(filters);

            setFilteredValues(res.data);
            setloadingData(false);

          } else {
            filters.investorID = userState.userId;
            filters.filter.projectListingStatuses = [2, 3];
            let response = await InvestorAPI.searchSavedProject(filters);

            for (let j = 0; j < response.data.length; j++) {
              response.data[j].saved = true;
            }

            setFilteredValues(response);
            setloadingData(false);
          }
        } else if (value === 2) {

          filters.filter.portfolioStatuses = [2];

          let response = await ListingPortfolioAPI.searchListingPortfolios(filters);
          if (userState.userPersona === "Investor") {
            if(userState.userId !== null && userState.userId !=='') {
            let investorProjects: any = await InvestorAPI.getInvestor(userState.userId);

            const savedPortfolios = investorProjects.data.savedPortfolios;
            for (let i = 0; i < response.data.length; i++) {

              for (let j = 0; j < savedPortfolios.length; j++) {
                if (response.data[i].id === savedPortfolios[j].portfolioID) {

                  response.data[i].saved = true;
                } if (!response.data[i].saved) {
                  response.data[i].saved = false;

                }
              }
            }      
          }
        
          else {
            for (let i = 0; i < response.data.length; i++) {
              if (!response.data[i].saved) {
                response.data[i].saved = false;

              }
            }
      
          }
        }

          setFilteredValues(response);
          setloadingData(false);

        } else if (value === 3) {

          if (userState.userPersona !== "Investor") {
            filters.developerID = userState.userId;
            filters.filter.portfolioStatuses = [1, 2, 3];
            // filters.filter.developerID = userState.userId;
            const res = await DeveloperApi.searchDeveloperPortfolioInfo(filters);

            setFilteredValues(res.data);
            setloadingData(false);
          } else {

            filters.filter.totalFundingNeededMaximum = filters.filter.investmentRangeMaximum;
            filters.filter.totalFundingNeededMinimum = filters.filter.investmentRangeMinimum;
            filters.filter.investmentRangeMinimum = null;
            filters.filter.investmentRangeMaximum = null;
            filters.investorID = userState.userId;
            filters.filter.portfolioStatuses = [2, 3];

            let response = await InvestorAPI.searchSavedPortfolio(filters);

            for (let j = 0; j < response.data.length; j++) {
              response.data[j].saved = true;
            }
            setFilteredValues(response);
            setloadingData(false);
          }

        }

      } catch (e) {
        setFilteredValues({ data: [], totalRecords: 0, page: 0 })
        setloadingData(false);

        return;
      }

    };

    getAPIData();

  }, [filters, page, userState]);


  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    setloadingData(true);
    const getAPIData = async () => {
      try {
        filters.start = (page - 1) * filters.pageSize;

        if (value === 1) {
          if (userState.userPersona !== "Investor") {
            filters.developerID = userState.userId;
            filters.filter.projectListingStatuses = [1, 2, 3]
            const res = await DeveloperApi.searchDeveloperProjectsInfo(filters);
            setFilteredValues(res.data);
            setloadingData(false);

          } else {
            filters.investorID = userState.userId;
            filters.filter.projectListingStatuses = [2, 3];
            let response = await InvestorAPI.searchSavedProject(filters);

            for (let j = 0; j < response.data.length; j++) {
              response.data[j].saved = true;
            }

            setFilteredValues(response);
            setloadingData(false);
          }
        }

        if (value === 0) {
          filters.filter.projectListingStatuses = [2];
          let filters2 = filters;

          let response = await ListingProjectAPI.searchListingProjects(filters2);

          if (userState.userPersona === "Investor") {
            if(userState.userId !== null && userState.userId !=='') {
            let investorProjects: any = await InvestorAPI.getInvestor(userState.userId);

            const savedProjectListings = investorProjects.data.savedProjectListings;
            for (let i = 0; i < response.data.length; i++) {

              for (let j = 0; j < savedProjectListings.length; j++) {

                if (response.data[i].id === savedProjectListings[j].projectListingID) {
                  response.data[i].saved = true;

                } if (!response.data[i].saved) {
                  response.data[i].saved = false;

                }
              }
            }
          }
          else {
            for (let i = 0; i < response.data.length; i++) {
              if (!response.data[i].saved) {
                response.data[i].saved = false;

              }
            }
          }
        }

          setFilteredValues(response);
          setloadingData(false);
        }
        if (value === 3) {

          if (userState.userPersona !== "Investor") {
            filters.developerID = userState.userId;
            filters.filter.portfolioStatuses = [1, 2, 3];
            // filters.filter.developerID = userState.userId;
            const res = await DeveloperApi.searchDeveloperPortfolioInfo(filters);

            setFilteredValues(res.data);
            setloadingData(false);
          } else {

            filters.filter.totalFundingNeededMaximum = filters.filter.investmentRangeMaximum;
            filters.filter.totalFundingNeededMinimum = filters.filter.investmentRangeMinimum;
            filters.filter.investmentRangeMinimum = null;
            filters.filter.investmentRangeMaximum = null;
            filters.investorID = userState.userId;
            filters.filter.portfolioStatuses = [2, 3];

            let response = await InvestorAPI.searchSavedPortfolio(filters);

            for (let j = 0; j < response.data.length; j++) {
              response.data[j].saved = true;
            }
            setFilteredValues(response);
            setloadingData(false);
          }

        }

      } catch (e) {
        setFilteredValues({ data: [], totalRecords: 0, page: 0 })
        setloadingData(false);

        return;
      }

    };

    getAPIData();
    localStorage.setItem("lastTabIndex", value.toString());
    localStorage.setItem("lastPage", page.toString());
  }, [value, page, userState]);

  const handleChange = async (event: any, newValue: number) => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    setloadingData(true);
    filters.start = 0;
    filters.filter = initialSearchValues.filter;

    try {
      filters.start = 0;

      if (newValue === 0) {

        filters.filter.projectListingStatuses = [2];

        let response = await ListingProjectAPI.searchListingProjects(filters);

        if (userState.userPersona === "Investor") {
          if(userState.userId !== null && userState.userId !=='') {
          let investorProjects: any = await InvestorAPI.getInvestor(userState.userId);

          const savedProjectListings = investorProjects.data.savedProjectListings;
          for (let i = 0; i < response.data.length; i++) {

            for (let j = 0; j < savedProjectListings.length; j++) {
              if (response.data[i].id === savedProjectListings[j].projectListingID) {

                response.data[i].saved = true;
              } if (!response.data[i].saved) {
                response.data[i].saved = false;

              }
            }
          }
        }
        else {
          for (let i = 0; i < response.data.length; i++) {
            if (!response.data[i].saved) {
              response.data[i].saved = false;

            }
          }
        }
        }
        setFilteredValues(response);
        setloadingData(false);
      } else if (newValue === 1) {

        if (userState.userPersona !== "Investor") {
          filters.developerID = userState.userId;
          filters.filter.projectListingStatuses = [1, 2, 3];
          const res = await DeveloperApi.searchDeveloperProjectsInfo(filters);

          setFilteredValues(res.data);
          setloadingData(false);
        } else {

          filters.investorID = userState.userId;
          filters.filter.projectListingStatuses = [2, 3];
          let response = await InvestorAPI.searchSavedProject(filters);

          for (let j = 0; j < response.data.length; j++) {

            response.data[j].saved = true;

          }

          setFilteredValues(response);
          setloadingData(false);
        }
      } else if (newValue === 2) {

        filters.filter.portfolioStatuses = [2];

        let response = await ListingPortfolioAPI.searchListingPortfolios(filters);
        if (userState.userPersona === "Investor") {
          if(userState.userId !== null && userState.userId !=='') {
          let investorProjects: any = await InvestorAPI.getInvestor(userState.userId);

          const savedPortfolios = investorProjects.data.savedPortfolios;

          for (let i = 0; i < response.data.length; i++) {

            for (let j = 0; j < savedPortfolios.length; j++) {
              if (response.data[i].id === savedPortfolios[j].portfolioID) {

                response.data[i].saved = true;
              }
              if (!response.data[i].saved) {

                response.data[i].saved = false;

              }
            }
          }
        }
        else {
          for (let i = 0; i < response.data.length; i++) {
            if (!response.data[i].saved) {

              response.data[i].saved = false;

            }
          }
        }
        }

        setFilteredValues(response);
        setloadingData(false);

      } else if (newValue === 3) {

        if (userState.userPersona !== "Investor") {
          filters.developerID = userState.userId;
          filters.filter.portfolioStatuses = [1, 2, 3];

          const res = await DeveloperApi.searchDeveloperPortfolioInfo(filters);

          setFilteredValues(res.data);
          setloadingData(false);
        } else {

          filters.investorID = userState.userId;
          filters.filter.portfolioStatuses = [2, 3];

          let response = await InvestorAPI.searchSavedPortfolio(filters);

          for (let j = 0; j < response.data.length; j++) {
            response.data[j].saved = true;
          }
          setFilteredValues(response);
          setloadingData(false);
        }

      }

    } catch (e) {
      setFilteredValues({ data: [], totalRecords: 0, page: 0 })
      setValue(newValue);
      setloadingData(false);
      return;
    }
    setValue(newValue);
    setFilters(initialSearchValues);
    setSelectedFilter(null);
    setPage(1);
    if (impactCriteriaList.length !== 0) {
      setImpactCriteriaList([]);
    }
  };
  const resultsView = loadingData ? (
    <div
      className={styles.searchResultsContainer}
      data-testid="search-project-results-container"
    >
      <ProjectCardSkeleton />
    </div>
  ) : (
    <div
      className={styles.searchResultsContainer}
      data-testid="search-project-results-container"
    >
      {value === 1 && filteredValues.data && filteredValues.data.length > 0 && userState.userPersona !== "Investor" ?
        <div className={styles.createNew}>
          <h4 >
            {t("myProject.projectList_label")}
          </h4>
          <p >
            {t("myProject.projectList_description")}
          </p>
          <Tooltip title="Create New Project">
            <Button
              className={`${styles.formButton} ${styles.textTransform}`}
              variant="contained"
              startIcon={<AddCircleOutlineIcon />} onClick={(e) => {
                e.preventDefault();
                navigate(`/projects/add`);
              }}
              sx={{ ml: 6}}
            >
              {t("myProject.createProject_buttonLabel")}
            </Button>
          </Tooltip>
        </div> : userState.userPersona !== "Investor" && filteredValues.data.length > 0 && value === 3 ?
          <div className={styles.createNew}>
            <h4 >
              {t("myProject.portfolioList_label")}

            </h4>
            <p >
              {t("myProject.portfolioList_description")}
            </p>

            <Button
             className={`${styles.formButton} ${styles.textTransform}`}
              variant="contained"
              startIcon={<AddCircleOutlineIcon />} onClick={(e) => {
                e.preventDefault();
                const skipCreatePortfolioModal = localStorage.getItem("SkipCreatePortfolioModal");

                if (skipCreatePortfolioModal === "1") {
                  navigate(`/portfolio/add`);
                } else {
                  setCreatePortfolio1(true);

                }
              }}
              sx={{ ml: 6 }}
            >
              {t("myProject.createPortfolio_buttonLabel")}

            </Button>
          </div>
          : ""

      }
      {/* {filteredValues.data && filteredValues.data.length > 0 && (
          value === 0 ? <p className={styles.sortDirection}>
            {t("projectFilter.sortInformation")}
          </p> : <p className={styles.sortDirection}>
            {t("projectFilter.savedProjectsSortInfo")}
          </p>
        )} */}
      {filteredValues.data && filteredValues.data.length > 0 && userState.userPersona !== "Investor" && (
        (value === 0 || value === 1) ? <p className={styles.sortDirection}>
          {t("projectFilter.sortInformation")}
        </p> : (value === 2 || value === 3) ? <p className={styles.sortDirection}>
          {t("projectFilter.savedPortfolioSortInfo")}
        </p> : null
      )}
      {filteredValues.data && filteredValues.data.length > 0 && userState.userPersona === "Investor"&& (
        (value === 0 || value === 1) ? <p className={styles.sortDirection}>
        { value === 0 && (t("projectFilter.sortInformation"))}
        { value === 1 && (t("projectFilter.savedProjectsSortInfo_investor"))}
        </p> : (value === 2 || value === 3) ? <p className={styles.sortDirection}>
        { value === 2 && (t("projectFilter.savedPortfolioSortInfo"))}
        { value === 3 && (t("projectFilter.savedPortfolioSortInfo_Investor"))}
        </p> : null
      )}

      {filteredValues.data && filteredValues.data.length > 0 ? (
        //@ts-ignore
        filteredValues.data.map((data, idx) => (
          <React.Fragment>
            {value === 0 ?
              <ProjectCard
                key={idx}
                data={data}
                handleChange={() => handleChange(undefined, 1)}
              />
              : value === 1 && data ?
                <SavedProjectCard key={idx} data={data} /> :
                value === 2 && data ?
                  <PortfolioCard isPortfolio={true} key={idx} data={data} handleChange={() => handleChange(undefined, 4)} />
                  : value === 3 && data &&
                  <SavedPortfolioCard isPortfolio={true} key={idx} data={data} />
            }

          </React.Fragment>
        ))
      ) : (
        value === 0 && selectedFilter === null ?
          <NoListedProjectsResults />
          : value === 0 && selectedFilter !== null ?
            <NoListedProjectsResultsWithFilter />
            : value === 1 && selectedFilter === null && userState.userPersona !== "Investor" ?
              <NoListedMyProjectsResults />
              : value === 1 && selectedFilter !== null && userState.userPersona !== "Investor" ?
                <NoListedProjectsResultsWithFilter />
                : value === 1 && selectedFilter === null && userState.userPersona === "Investor" ?
                  <NoListedSavedProjectsResults browseProjects={() => handleChange(undefined, 0)} />
                  : value === 1 && selectedFilter !== null && userState.userPersona === "Investor" ?
                        <NoListedProjectsResultsWithFilter isInvestorSavedProject={true} /> :

                    value === 2 && selectedFilter === null ?
                      <NoListedProjectsResults isLabelChanged={true} />
                      : value === 2 && selectedFilter !== null ?
                        <NoListedProjectsResultsWithFilter isLabelChanged={true} />
                        : value === 3 && selectedFilter === null && userState.userPersona !== "Investor" ?
                          <NoListedMyProjectsResults isLabelChanged={true} />
                          : value === 3 && selectedFilter !== null && userState.userPersona !== "Investor" ?
                            <NoListedProjectsResultsWithFilter isLabelChanged={true} />
                            : value === 3 && selectedFilter === null && userState.userPersona === "Investor" ?
                              <NoListedSavedProjectsResults browseProjects={() => handleChange(undefined, 0)} isLabelChanged={true} />
                              : value === 3 && selectedFilter !== null && userState.userPersona === "Investor" &&
                              <NoListedProjectsResultsWithFilter isLabelChanged={true} />
      )}
      {filteredValues.data && filteredValues.totalRecords > 6 && (
        <PaginationElement
          start={filters.start + 1}
          totalRecords={filteredValues.totalRecords}
          page={page}
          setPage={setPage}
          pageSize={filters.pageSize}
        />
      )}
    </div>
  );
  interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
  }

  function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <div>
            {children}
          </div>
        )}
      </div>
    );
  }

  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  return (
    <PageLayout>
      <Title
        titleText={t("searchProjectsPage.title")}
        subtitle={userState.userPersona === "Investor" ? t("searchProjectsPage.investorSubtitle") : t("searchProjectsPage.subtitle")}
      />
      <PageContent>
        <Box sx={{ width: '100%' }}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" variant="fullWidth">
              <Tab sx={{ textTransform: 'none' }} label={t("searchProjectsPage.activeProjects")} {...a11yProps(0)} text-transform="none"/>
              <Tab sx={{ textTransform: 'none' }} label={userState.userPersona === "Investor" ? t("searchProjectsPage.investorTabs.savedProjects") : t("searchProjectsPage.developerTabs.myProjects")} {...a11yProps(1)} />
              <Tab sx={{ textTransform: 'none' }} label={t("searchProjectsPage.activePortfolio")} {...a11yProps(2)} />
              <Tab sx={{ textTransform: 'none' }} label={userState.userPersona === "Investor" ? t("searchProjectsPage.investorTabs.savedPortfolios") : t("searchProjectsPage.developerTabs.myPortfolios")} {...a11yProps(3)} />            
            </Tabs>
          </Box>
          <CustomTabPanel value={value} index={0}>
            <div
              className={styles.searchPageContainer}
              data-testid="search-page-container"
            >
              <ProjectsFilterSidebar
                setFilters={setFilters}
                setSelectedFilter={setSelectedFilter}
                selectedFilter={selectedFilter}
                initialSearchValues={initialSearchValues}
                setPage={setPage}
                impactCriteriaList={impactCriteriaList}
                setImpactCriteriaList={setImpactCriteriaList}
                isPortfolio={false}
                setExpandList={setExpandList}
                expandList={expandList}
                setCityList={setCityList}
                cityList={cityList}
              />
              {resultsView}
            </div>
          </CustomTabPanel>
          <CustomTabPanel value={value} index={1}>

            <div
              className={styles.searchPageContainer}
              data-testid="search-page-container"
            >
              <ProjectsFilterSidebar
                setFilters={setFilters}
                setSelectedFilter={setSelectedFilter}
                selectedFilter={selectedFilter}
                initialSearchValues={initialSearchValues}
                setPage={setPage}
                impactCriteriaList={impactCriteriaList}
                setImpactCriteriaList={setImpactCriteriaList}
                isPortfolio={false}
                setExpandList={setExpandList}
                expandList={expandList}
                setCityList={setCityList}
                cityList={cityList}
              />
              {resultsView}
            </div>
          </CustomTabPanel>
          <CustomTabPanel value={value} index={2}>
            <div className={styles.searchPageContainer}>
              <ProjectsFilterSidebar
                setFilters={setFilters}
                setSelectedFilter={setSelectedFilter}
                selectedFilter={selectedFilter}
                initialSearchValues={initialSearchValues}
                setPage={setPage}
                impactCriteriaList={impactCriteriaList}
                setImpactCriteriaList={setImpactCriteriaList}
                isPortfolio={true}
                setExpandList={setExpandList}
                expandList={expandList}
                setCityList={setCityList}
                cityList={cityList}
              />
              {resultsView}
            </div>
          </CustomTabPanel>
          <CustomTabPanel value={value} index={3}>
            {createPortfolio1 && (
              <CreatePortfolio
                setCreatePortfolio={setCreatePortfolio1}
              />
            )}
            <div className={styles.searchPageContainer}>
              <ProjectsFilterSidebar
                setFilters={setFilters}
                setSelectedFilter={setSelectedFilter}
                selectedFilter={selectedFilter}
                initialSearchValues={initialSearchValues}
                setPage={setPage}
                impactCriteriaList={impactCriteriaList}
                setImpactCriteriaList={setImpactCriteriaList}
                isPortfolio={true}
                setExpandList={setExpandList}
                expandList={expandList}
                setCityList={setCityList}
                cityList={cityList}
              />
              {resultsView}
            </div>
          </CustomTabPanel>
        </Box>
      </PageContent>
    </PageLayout>
  );
}
