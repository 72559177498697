import { useField } from "formik";
import { CheckBoxInput } from "./CheckBoxInput";
import { CommonErrorMessage } from "./CommonErrorMessage";
import styles from "./styles.module.scss";
import { Accordion, AccordionSummary } from "@mui/material";
import { ExpandMore } from "@mui/icons-material";

type Props = {
  fieldName: string;
  formQuestionText: string;
  options: { label: string; value: string | number }[];
  linkText?: string;
  setIsOpenModal?: Function;
  [x: string]: unknown;
  placeholder?: string;
  expanded: boolean; // Change type to boolean
  onChange: (index: number) => void;
  index: number;
  onCheckBoxClick?: () => void;
};

export function CheckBoxGroupAccordion({
  fieldName,
  formQuestionText,
  options,
  linkText,
  setIsOpenModal,
  placeholder,
  index,
  expanded,
  onChange,
  onCheckBoxClick,
  ...rest
}: Props) {
  const [field, meta] = useField(fieldName);

  return (

    <div className={styles.fieldContainerAccordion}>
      <Accordion expanded={expanded} onChange={() => onChange(index)}> 
        <div className={styles.labelLinkAccordion}>

          <AccordionSummary
            expandIcon={<ExpandMore className={styles.icon} />}
          >
            <p
              className={`${styles.formQuestionText} ${meta.error && meta.touched ? styles.errorStateLabel : null
                }`}
            >
              {formQuestionText}
            </p>
          </AccordionSummary>


        </div>
        {linkText &&
          <button
            type="button"
            className={styles.glossaryLinkAccordion}
            data-testid="glossary-page-link"
            onClick={(e) => {
              e.preventDefault();
              if (setIsOpenModal) {
                setIsOpenModal(true);
              }
            }}
            aria-label={linkText}
          >
            {linkText}
          </button>
        }

        {placeholder && <p>{placeholder}</p>}
        <CheckBoxInput fieldName={field.name} options={options}  onCheckBoxClick={onCheckBoxClick} {...rest} />
        <CommonErrorMessage fieldName={field.name} />
      </Accordion>
    </div>
  );
}
