import axios, { AxiosResponse } from "axios";
import { config, requestAccessToken } from "./config";
import {
  InvestorInvestmentInformation,
  InvestorInvestmentPreference,
  InvestorDetails,
  InvestorOrganizationImpact,
} from "../TypesAndInterfaces/InvestorDetailsInterface";

const apiClient = axios.create({
  baseURL: config.url.base,
  headers: {
    "Content-type": "application/json",
  },
});

const getGeneralInfoById = async (id: string) => {
  const token = await requestAccessToken();

  const response = await apiClient.get<AxiosResponse>(
    `${config.url.investor.GenInfo}/${id}`,
    { headers: { Authorization: `Bearer ${token}` } }
  );
  return response.data;
};

const getInvestor = async (id: string) => {
  const token = await requestAccessToken();

  const response = await apiClient.get<AxiosResponse>(
    `${config.url.investor.GetInvestor}/${id}`,
    { headers: { Authorization: `Bearer ${token}` } }
  );

  return response.data;
};


const postGenInfo = async (genInfo: Object) => {
  const token = await requestAccessToken();

  const response = await apiClient.post<AxiosResponse>(
    config.url.investor.GenInfo,
    genInfo,
    { headers: { Authorization: `Bearer ${token}` } }
  );
  return response.data;
};

const updateGenInfo = async (id: string, genInfo: Object) => {
  const token = await requestAccessToken();

  const response = await apiClient.put<AxiosResponse>(
    `${config.url.investor.GenInfo}/${id}`,
    genInfo,
    { headers: { Authorization: `Bearer ${token}` } }
  );
  return response.data;
};

const getInvestmentInfoById = async (id: string) => {
  const token = await requestAccessToken();

  const response = await apiClient.get<
    AxiosResponse<InvestorInvestmentInformation>
  >(`${config.url.investor.InvestmentInfo}/${id}`, {
    headers: { Authorization: `Bearer ${token}` },
  });
  return response.data;
};

const postInvestmentInfo = async (genInfo: Object) => {
  const token = await requestAccessToken();

  const response = await apiClient.post<AxiosResponse>(
    config.url.investor.InvestmentInfo,
    genInfo,
    { headers: { Authorization: `Bearer ${token}` } }
  );
  return response.data;
};

const updateInvestmentInfo = async (id: string, genInfo: Object) => {
  const token = await requestAccessToken();

  const response = await apiClient.put<AxiosResponse>(
    `${config.url.investor.InvestmentInfo}/${id}`,
    genInfo,
    { headers: { Authorization: `Bearer ${token}` } }
  );
  return response.data;
};

const getPreferenceInfoById = async (id: string) => {
  const token = await requestAccessToken();

  const response = await apiClient.get<
    AxiosResponse<InvestorInvestmentPreference>
  >(`${config.url.investor.PreferenceInfo}/${id}`, {
    headers: { Authorization: `Bearer ${token}` },
  });
  return response.data;
};

const postPreferenceInfo = async (genInfo: Object) => {
  const token = await requestAccessToken();

  const response = await apiClient.post<AxiosResponse>(
    config.url.investor.PreferenceInfo,
    genInfo,
    { headers: { Authorization: `Bearer ${token}` } }
  );
  return response.data;
};

const updateOrganizationImpactInfo = async (id: string, genInfo: Object) => {
  const token = await requestAccessToken();

  const response = await apiClient.put<AxiosResponse>(
    `${config.url.investor.OrganizationImpactInfo}/${id}`,
    genInfo,
    { headers: { Authorization: `Bearer ${token}` } }
  );
  return response.data;
};

const getOrganizationImpactInfoById = async (id: string) => {
  const token = await requestAccessToken();

  const response = await apiClient.get<
    AxiosResponse<InvestorOrganizationImpact>
  >(`${config.url.investor.OrganizationImpactInfo}/${id}`, {
    headers: { Authorization: `Bearer ${token}` },
  });
  return response.data;
};

const postOrganizationImpactInfo = async (genInfo: Object) => {
  const token = await requestAccessToken();

  const response = await apiClient.post<AxiosResponse>(
    config.url.investor.OrganizationImpactInfo,
    genInfo,
    { headers: { Authorization: `Bearer ${token}` } }
  );
  return response.data;
};

const updatePreferenceInfo = async (id: string, genInfo: Object) => {
  const token = await requestAccessToken();

  const response = await apiClient.put<AxiosResponse>(
    `${config.url.investor.PreferenceInfo}/${id}`,
    genInfo,
    { headers: { Authorization: `Bearer ${token}` } }
  );
  return response.data;
};

const postInvestorSearch = async (filters: Object) => {
  const token = await requestAccessToken();
  const response = await apiClient.post(config.url.investor.Search, filters, {
    headers: { Authorization: `Bearer ${token}` },
  });
  return response.data;
};

const postInvestorIdByEmail = async (email: string) => {
  const token = await requestAccessToken();

  const response = await apiClient.post<AxiosResponse<InvestorDetails>>(
    config.url.investor.investorIdByEmail,
    { emailAddress: email },
    { headers: { Authorization: `Bearer ${token}` } }
  );

  return response.data.data;
};


const postSaveProject = async (info: Object) => {
  const token = await requestAccessToken();

  const response = await apiClient.post<AxiosResponse>(
    config.url.investor.SaveProject,
    info,
    { headers: { Authorization: `Bearer ${token}` } }
  );

  return response.data;
};

const postRemoveSavedProject = async (info: Object) => {
  const token = await requestAccessToken();

  const response = await apiClient.post<AxiosResponse>(
    config.url.investor.RemoveProject,
    info,
    { headers: { Authorization: `Bearer ${token}` } }
  );

  return response.data;
};



const postSavePortfolio = async (info: Object) => {
  const token = await requestAccessToken();

  const response = await apiClient.post<AxiosResponse>(
    config.url.investor.SavePortfolio,
    info,
    { headers: { Authorization: `Bearer ${token}` } }
  );

  return response.data;
};

const postRemoveSavedPortfolio = async (info: Object) => {
  const token = await requestAccessToken();

  const response = await apiClient.post<AxiosResponse>(
    config.url.investor.RemovePortfolio,
    info,
    { headers: { Authorization: `Bearer ${token}` } }
  );

  return response.data;
};


const searchSavedProject = async (filterData: Object) => {
  const token = await requestAccessToken();

  const response = await apiClient.post<any>(
    `${config.url.investor.SearchSavedProject}`,
    filterData,
    { headers: { Authorization: `Bearer ${token}` } }
  );

  return response.data;
};

const searchSavedPortfolio = async (filterData: Object) => {
  const token = await requestAccessToken();

  const response = await apiClient.post<any>(
    `${config.url.investor.SearchSavedPortfolio}`,
    filterData,
    { headers: { Authorization: `Bearer ${token}` } }
  );

  return response.data;
};
export const InvestorAPI = {
  getGeneralInfoById,
  postGenInfo,
  updateGenInfo,
  getInvestmentInfoById,
  postInvestmentInfo,
  updateInvestmentInfo,
  getPreferenceInfoById,
  postPreferenceInfo,
  updatePreferenceInfo,
  getOrganizationImpactInfoById,
  postOrganizationImpactInfo,
  updateOrganizationImpactInfo,
  postInvestorSearch,
  postInvestorIdByEmail,
  postSaveProject,
  postRemoveSavedProject,
  searchSavedProject,
  getInvestor,
  postSavePortfolio,
  postRemoveSavedPortfolio,
  searchSavedPortfolio
};
