import { useState } from "react";
import { useTranslation } from "react-i18next";
import { CloseWhite } from "../../../assets/icons";
import styles from "./MobileModal.module.scss";

export const MobileMessageModal = () => {
  const { t } = useTranslation("common");

  const [openModal, setOpenModal] = useState<boolean>(true);

  return (
    <div>
      {openModal && (
        <div className={styles.mobileModal}>
          <div className={styles.mobileModalContainer}>
            <button
              className={styles.crossIcon}
              onClick={() => setOpenModal(false)}
            >
              <img src={CloseWhite} alt="close" />
            </button>
            <div className={styles.mobileModalText}>
              <h3>{t("mobileModal.heading")}</h3>
              <p>{t("mobileModal.description")}</p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
