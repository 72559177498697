import styles from "./ProblemStatement.module.scss";
import { useTranslation } from "react-i18next";
import { OpenInNew } from "@mui/icons-material";
import HousingInfographich from "../../../../assets/images/Housing_Infographic.png";

export function ProblemStatement() {
  const { t } = useTranslation("landing");

  return (
    <div className={styles.problemStatement}>
      <div className={styles.textContainer}>
        <h2 className={styles.heading} data-testid="statement-heading">
          {t("landing:problem_statement.header")}
          <sup>1</sup>
        </h2>
        <p className={styles.content} data-testid="statement-content">
          {t("landing:problem_statement.content")}
        </p>
        <a
          href={t("problem_statement.learn_more_url")}
          className={styles.externalLink}
          target="_blank"
          rel="noopener noreferrer"
        >
          <sup>1</sup>
          {t("landing:problem_statement.learn_more")}
          <OpenInNew sx={{ height: "0.75rem", widht: "0.75rem" }} />
        </a>
      </div>
      <div className={styles.right} data-testid="statement-image">
        <img
          src={HousingInfographich}
          alt={t("landing:problem_statement.alttext")}
        />
      </div>
    </div>
  );
}
