import { Field, useField } from "formik";
import { CommonErrorMessage } from "./CommonErrorMessage";
import styles from "./styles.module.scss";

type Props = {
  labelText: string;
  fieldName: string;
  options: { label: string; value: string | number, status?: number }[];
  [x: string]: unknown;
  placeholder?: string;
  instructionText?: string;
};

export function SelectInput({ labelText, fieldName, options, placeholder, instructionText, ...rest }: Props) {
  const [field, meta] = useField(fieldName);

  return (
    <div className={styles.fieldContainer}>
      <label
        className={`${styles.labelStyle} ${meta.error && meta.touched ? styles.errorStateLabel : null
          }`}
        htmlFor={field.name}
      >
        {labelText}
      </label>
      <Field
        as="select"
        className={`${styles.selectInput} ${meta.error && meta.touched ? styles.errorStateInput : null
          }`}
        id={field.name}
        {...field}
        {...rest}
        placeholder={placeholder}
      >
        {options.map((option, idx) => (
          <option
            key={idx}
            value={option.value}
            className={styles.options}
            disabled={option.value === "" ? true : false}
          >
            {option.label}
          </option>
        ))}
      </Field>
        {instructionText && <p className={styles.fieldInfoText}>{instructionText}</p>}
      <CommonErrorMessage fieldName={field.name} />
    </div>
  );
}
