export function isEmpty(value: any) {
    if (Array.isArray(value)) {
        return value.length === 0;
    } else if (typeof value === 'string') {
        return value.trim().length === 0;
    }
    return true;
}

export function isNotEmpty(value: any) {
    if (Array.isArray(value)) {
        return !!value.length;
    } else if (typeof value === 'string') {
        return !!value.trim().length;
    }
    return true;
}