import { ReactElement } from "react";
import { Trans, useTranslation } from "react-i18next";
import styles from "./styles.module.scss";
import { PageLayout } from "../../components/Layout/PageLayout";
import { Title } from "../../components/common/Title/Title";
import { PageContent } from "../../components/Layout/PageContent";

type TransFormattedComponent = {
  [tagName: string]: ReactElement;
};

export const TermsConditionPage = () => {
  const { t } = useTranslation("termsConditions");
  const alphaListStyle = {
    margin: "0 0 0 1rem",
  };

  const htmlFormatComponent: TransFormattedComponent = {
    b: <strong />,
    p: <p />,
  };

  const htmlStyleComponent: TransFormattedComponent = {
    u: <u />,
    b: <strong />,
    br: <br />,
    subheading: <p />,
    ol: <ol type="a" />,
    li: <li style={alphaListStyle} />,
  };

  const htmlStrongAndBreakComponent: TransFormattedComponent = {
    b: <strong />,
    br: <br />,
  };

  const listOptionsConfig: {
    heading: string;
    translationKey: string;
    components: TransFormattedComponent;
  }[] = [
      {
        heading: t("content.definitions.heading"),
        translationKey: "termsConditions:content.definitions.body",
        components: htmlFormatComponent,
      },
      {
        heading: t("content.platform.heading"),
        translationKey: "termsConditions:content.platform.body",
        components: htmlFormatComponent,
      },
      {
        heading: t("content.registration.heading"),
        translationKey: "termsConditions:content.registration.content",
        components: htmlFormatComponent,
      },
      {
        heading: t("content.acknowledgements.heading"),
        translationKey: "termsConditions:content.acknowledgements.body",
        components: htmlStyleComponent,
      },
      {
        heading: t("content.no_use_by_minor.heading"),
        translationKey: "termsConditions:content.no_use_by_minor.body",
        components: htmlFormatComponent,
      },
      {
        heading: t("content.restrictions.heading"),
        translationKey: "termsConditions:content.restrictions.body",
        components: htmlStyleComponent,
      },
      {
        heading: t("content.no_endorsement.heading"),
        translationKey: "termsConditions:content.no_endorsement.body",
        components: htmlFormatComponent,
      },
      {
        heading: t("content.compliance.heading"),
        translationKey: "termsConditions:content.compliance.body",
        components: { p: <p />, br: <br /> },
      },
      {
        heading: t("content.limited_license.heading"),
        translationKey: "termsConditions:content.limited_license.body",
        components: htmlStyleComponent,
      },
      {
        heading: t("content.user_generated_content.heading"),
        translationKey: "termsConditions:content.user_generated_content.body",
        components: htmlStyleComponent,
      },
      {
        heading: t("content.cmhc_branding.heading"),
        translationKey: "termsConditions:content.cmhc_branding.body",
        components: { p: <p /> },
      },
      {
        heading: t("content.feedback.heading"),
        translationKey: "termsConditions:content.feedback.body",
        components: { b: <strong />, br: <br />, p: <p /> },
      },
      {
        heading: t("content.limited_liability.heading"),
        translationKey: "termsConditions:content.limited_liability.body",
        components: { br: <br />, p: <p />, b: <strong />, },
      },
      {
        heading: t("content.indemnity.heading"),
        translationKey: "termsConditions:content.indemnity.body",
        components: htmlStrongAndBreakComponent,
      },
      {
        heading: t("content.security_confidentiality.heading"),
        translationKey: "termsConditions:content.security_confidentiality.body",
        components: htmlStrongAndBreakComponent,
      },
      {
        heading: t("content.desclaimer.heading"),
        translationKey: "termsConditions:content.desclaimer.body",
        components: htmlStrongAndBreakComponent,
      },
      {
        heading: t("content.terms_and_termination.heading"),
        translationKey: "termsConditions:content.terms_and_termination.body",
        components: htmlStrongAndBreakComponent,
      },
      {
        heading: t("content.modification.heading"),
        translationKey: "termsConditions:content.modification.body",
        components: htmlStrongAndBreakComponent,
      },
      {
        heading: t("content.personal_information.heading"),
        translationKey: "termsConditions:content.personal_information.body",
        components: {
          b: <strong />,
          br: <br />,
          links: (
            <a href="https://www.cmhc-schl.gc.ca/en/about-cmhc/privacy-policy">
              {""}
            </a>
          ),
          mail: <a href="mailto:PrivacyOffice@cmhc-schl.gc.ca ">{""}</a>,
        },
      },
      {
        heading: t("content.entire_agreement.heading"),
        translationKey: "termsConditions:content.entire_agreement.body",
        components: htmlStrongAndBreakComponent,
      },
      {
        heading: t("content.user_representation.heading"),
        translationKey: "termsConditions:content.user_representation.body",
        components: htmlStrongAndBreakComponent,
      },
      {
        heading: t("content.general_terms.heading"),
        translationKey: "termsConditions:content.general_terms.body",
        components: htmlStyleComponent,
      },
    ];

  return (
    <PageLayout>
      <Title titleText={t("page_header")} data-testid="title-component" />

      <PageContent>
        <p className={styles.date_updated} data-testid="terms-update-date">
          {t("text_component.updated_Date")}
        </p>
        <h2 className={styles.heading} data-testid="terms-heading">
          {t("text_component.header")}
        </h2>
        <p className={styles.summaryText} data-testid="terms-summary">
          <Trans
            i18nKey="termsConditions:text_component.text_0"
            components={{ b: <strong /> }}
          />
        </p>

        <p className={styles.summaryText} data-testid="terms-subheading">
          {t("text_component.subheading")}
        </p>
        <p className={styles.summaryText} data-testid="terms-subText">
          <Trans
            i18nKey="termsConditions:text_component.subText_0"
            components={{ b: <strong />, u: <u /> }}
          />
        </p>

        <ol className={styles.orderedLists} data-testid="terms-ordered-list">
          {listOptionsConfig.map((data, idx) => (
            <li key={idx}>
              <h3>{data.heading}</h3>
              <Trans
                i18nKey={data.translationKey}
                components={data.components}
              />
            </li>
          ))}
        </ol>
      </PageContent>
    </PageLayout>
  );
};
