import styles from "./styles.module.scss";
import { useTranslation } from "react-i18next";
import { Warning } from "../../../assets/icons/index";
import { Link } from "react-router-dom";

export function NewConnectionRequest() {
  const { t } = useTranslation(["connectionStatus"]);
  return (
    <div className={styles.connectionDiv}>
      <img
        src={Warning}
        alt={t("alt_text_warning_icon")}
        className={styles.iconWarn}
        data-testid="image-icon"
      />
      <div>
        <h4 data-testid="request-heading">{t("new_request_heading")}</h4>
        <p data-testid="request-text">{t("new_request_text")}</p>
        <Link
          to={"/connection"}
          className={styles.cardDetailLink}
          data-testid="connection-link"
        >
          {t("hub_link_text")}
        </Link>
      </div>
    </div>
  );
}
