import { useTranslation } from "react-i18next";
import styles from "./styles.module.scss";
import { Sad_Face } from "../../assets/images/index";

type Props = {
  browseProjects: Function;
  isLabelChanged?:boolean

};
export const NoListedSavedProjectsResults = ({ browseProjects, isLabelChanged }: Props) => {
  const { t } = useTranslation("filter");

  return (
    <div className={styles.noInvestorsFound}>
      {
        isLabelChanged ? 
        <>
        <img
        data-testid="crying-face"
        src={Sad_Face}
        alt={t("searchProjectsPage.noResultsImageAlt")}
      />
      <div>
        <h2 data-testid="no-investors-found-heading">
          {t("searchProjectsPage.noResultsTitleForSavedProject_portfolio")}
        </h2>
        <p data-testid="no-investors-found-heading">
          {t("searchProjectsPage.noResultsTextForSavedProject_portfolio")}
        </p>
        <button
          type="button"
          className={styles.linkButtton}

          onClick={() => browseProjects(0)}
        >
          {t("searchProjectsPage.browseProjects")}

        </button>
      </div>
        </> 
        : 
        <>
        <img
        data-testid="crying-face"
        src={Sad_Face}
        alt={t("searchProjectsPage.noResultsImageAlt")}
      />
      <div>
        <h2 data-testid="no-investors-found-heading">
          {t("searchProjectsPage.noResultsTitleForSavedProject")}
        </h2>
        <p data-testid="no-investors-found-heading">
          {t("searchProjectsPage.noResultsTextForSavedProject")}
        </p>
        <button
          type="button"
          className={styles.linkButtton}

          onClick={() => browseProjects(0)}
        >
          {t("searchProjectsPage.browseProjects")}

        </button>
      </div>
        </>
      }
      

    </div>
  );
};
