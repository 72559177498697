import { PortfolioAffordabilityBenchmarkForm } from "../../components/Form/AddPortfolio/PortfolioAffordabilityBenchmarkForm";
import { PageContent } from "../../components/Layout/PageContent";
import { PageLayout } from "../../components/Layout/PageLayout";
import CalculatorIcon from "../../assets/icons/developer/Calculator.svg";
import styles from "./AddPortfolio.module.scss";
import { useTranslation } from "react-i18next";
import {
  FinancialDetailsIcon,
  FocusAreaIcon,
  GeneralInfoIcon,
  dollorIcon,
  Document,
} from "../../assets/icons";
import { PortfolioGeneralInformationForm } from "../../components/Form/AddPortfolio/PortfolioGeneralInformationForm";
import { FormAccordion } from "../FormAccordion";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { PortfolioFinancialDetails } from "../../components/Form/AddPortfolio/PortfolioFinancialDetailsForm";
import { ListingPortfolioAPI } from "../../APIAction/ListingPortfolioAPI";

import { PROJECT_LISTING_STATUS } from "../../Constants/Enumerations";
import { PortfolioImpactCriteriaForm } from "../../components/Form/AddPortfolio/PortfolioImpactCriteria";
import { ProjectsWithinPortfolioForm } from "../../components/Form/AddPortfolio/ProjectsWithinPortfolioForm";
import { PortfolioDocumentationForm } from "../../components/Form/AddPortfolio/PortfolioDocumentationForm";

export function AddPortfolio() {
  const { t } = useTranslation(["profile", "portfolio"]);
  const navigate = useNavigate();
  const { portfolioId } = useParams();
  const location = useLocation();
  const isEditExistingPortfolio = location.pathname.includes("edit");
  const [portfolioListingId, setPortfolioListingId] = useState(
    isEditExistingPortfolio ? portfolioId : ""
  );
  const [isExpanded, setIsExpanded] = useState(true);
  const [isAffordabilitySubmit, setIsAffordabilitySubmit] = useState(
    portfolioId ? true : false
  );
  const [selectedAccordion, setSelectedAccordion] = useState(0);
  const [isFinancialDetailsSubmit, setIsFinancialDetailsSubmit] =
    useState(false);
  const [isGenInfoSubmit, setIsGenInfoSubmit] = useState(false);
  const [isPortfolioDraft, setIsPortfolioDraft] = useState(false);
  const [isImpactAreaSubmit, setIsImpactAreaSubmit] = useState(false);

  const [isProjectsWithinPortfolioSubmit, setIsProjectsWithinPortfolioSubmit] =
    useState(false);
  const [isDocumentationDone, setIsDocumentationDone] = useState(false);

  useEffect(() => {
    const portfolioListingDataByID = async (id: string) => {
      try {
        const response = await ListingPortfolioAPI.getPortfolioListingInfoByID(
          id
        );
        setIsPortfolioDraft(
          response?.data?.portfolioStatus === PROJECT_LISTING_STATUS.Draft
        );
      } catch (error) {
        return;
      }
    };
    if (portfolioListingId) {
      portfolioListingDataByID(portfolioListingId);
    }
  }, [navigate, portfolioListingId, isEditExistingPortfolio]);

  const noteItems = [
    { text: t("developer.projects.note_portfolio.item_1") },
    { text: t("developer.projects.note_portfolio.item_2") },
    { text: t("developer.projects.note_portfolio.item_3") },
  ];

  const handleAccordianChange = (idValue: number) => {
    if (idValue !== selectedAccordion) {
      setIsExpanded(true);
      setSelectedAccordion(idValue);
    }
    if (idValue === selectedAccordion) {
      isExpanded ? setIsExpanded(false) : setIsExpanded(true);
    }
    window.scrollTo(0, 400);
  };

  const handleGeneralInfoResponse = (
    isSuccess: boolean,
    portfolioListingId: string
  ) => {
    if (isSuccess) {
      setIsGenInfoSubmit(true);
      handleAccordianChange(2);
      portfolioListingId !== "" && setPortfolioListingId(portfolioListingId);
    } else {
      return;
    }
  };

  const handleResponse = (
    infoSectionName: string,
    isSuccess: boolean,
    id?: string
  ) => {
    if (isSuccess) {
      switch (infoSectionName) {
        case "Affordability":
          if (id) {
            setPortfolioListingId(id);
          }
          setIsAffordabilitySubmit(true);
          handleAccordianChange(1);
          break;
        case "FinancialDetails":
          setIsFinancialDetailsSubmit(true);
          handleAccordianChange(3);
          break;
        case "ImpactCriteria":
          setIsImpactAreaSubmit(true);
          handleAccordianChange(4);

          break;
        case "ProjectsWithinPortfolio":
          setIsProjectsWithinPortfolioSubmit(true);
          handleAccordianChange(5);
          break;

        case "Documentation":
          if (isEditExistingPortfolio && !isPortfolioDraft) {
            navigate(`/portfolio/updated/${portfolioListingId}`);
            navigate(0);
          } else {
            navigate(`/portfolio/review/${portfolioListingId}`);
            navigate(0);
          }
          break;
        default:
          break;
      }
    } else {
      return;
    }
  };

  const ProjectFormList = [
    {
      formElement: (
        <PortfolioAffordabilityBenchmarkForm
          handleResponse={handleResponse}
          portfolioListingId={portfolioListingId}
        />
      ),
      altTextForIcon: t("developer.projects.add_listing_form.calculatorIcon"),
      formHeading: t(
        "developer.projects.add_listing_form.affordability_benchmark_title"
      ),
      formInfoSummary: t(
        "developer.projects.add_listing_form.affordability_benchmark_portfolio_info"
      ),
      formIconSrc: CalculatorIcon,
      disabled: !isAffordabilitySubmit ? true : false,
      showEditLink: isAffordabilitySubmit,
    },
    {
      formElement: (
        <PortfolioGeneralInformationForm
          handleResponse={handleGeneralInfoResponse}
          isAffordabilitySubmit={isAffordabilitySubmit}
          portfolioListingId={portfolioListingId}
        />
      ),
      altTextForIcon: t("developer.projects.general_info_form.personicon"),
      formHeading: t("developer.projects.general_info_form.heading"),
      formInfoSummary: t(
        "developer.projects.general_info_form.portfolioSummary"
      ),
      formIconSrc: GeneralInfoIcon,
      disabled: !isAffordabilitySubmit,
      showEditLink: isGenInfoSubmit,
    },
    {
      formElement: (
        <PortfolioFinancialDetails
          handleResponse={handleResponse}
          portfolioListingId={portfolioListingId}
        />
      ),
      formHeading: t("developer.projects.financial_details_doc_form.heading"),
      formIconSrc: dollorIcon,
      altTextForIcon: t(
        "portfolio:portfolio_details.icons.financial_details_alt_text"
      ),
      formInfoSummary: t(
        "developer.projects.financial_details_doc_form.portfolioSummary"
      ),
      disabled: !isGenInfoSubmit,
      showEditLink: isFinancialDetailsSubmit,
    },
    {
      formElement: (
        <PortfolioImpactCriteriaForm
          handleResponse={handleResponse}
          portfolioListingId={portfolioListingId}
        />
      ),
      altTextForIcon: t("developer.projects.impact_criteria_form.targetIcon"),
      formHeading: t("developer.projects.impact_criteria_form.heading"),
      formInfoSummary: t(
        "developer.projects.impact_criteria_form.impact_portfolio_summary"
      ),
      formIconSrc: FocusAreaIcon,
      disabled: !isFinancialDetailsSubmit,
      showEditLink: isImpactAreaSubmit,
    },
    {
      formElement: (
        <ProjectsWithinPortfolioForm
          handleResponse={handleResponse}
          portfolioListingId={portfolioListingId}
          selectedAccordion={selectedAccordion}
          isProjectsWithinPortfolioSubmit={isProjectsWithinPortfolioSubmit}
        />
      ),
      altTextForIcon: t("developer.projects.impact_criteria_form.targetIcon"),
      formHeading: t("developer.projects.project_within_portfolio.heading"),
      formInfoSummary: t(
        "developer.projects.project_within_portfolio.portfolio_summary"
      ),
      formIconSrc: FinancialDetailsIcon,
      disabled: !isFinancialDetailsSubmit,
      showEditLink: isImpactAreaSubmit,
    },
    {
      formElement: (
        <PortfolioDocumentationForm
          handleResponse={handleResponse}
          portfolioListingId={portfolioListingId}
        />
      ),
      altTextForIcon: t(
        "portfolio:portfolio_details.icons.documentation_alt_text"
      ),
      formHeading: t("developer.projects.file_upload_doc.documentation"),
      formInfoSummary: t(
        "developer.projects.file_upload_doc.summary_portfolio"
      ),
      formIconSrc: Document,
      disabled: !isProjectsWithinPortfolioSubmit,
      showEditLink: false,
    },
  ];

  return (
    <PageLayout>
      <PageContent>
        <h1 className={styles.pageTitle}>
          {portfolioId
            ? t("developer.projects.add_listing.portfolio_title")
            : t("developer.projects.update_listing.portfolio_title")}
        </h1>

        <p className={styles.summaryText}>
          {portfolioId
            ? t("developer.projects.add_listing.portfolioPage_summary")
            : t("developer.projects.update_listing.page_summary_portfolio")}
        </p>
        <div className={styles.noteContainer}>
          <h2 className={styles.noteHeading}>
            {t("developer.projects.note_portfolio.heading")}
          </h2>
          <ul className={styles.noteList}>
            {noteItems.map((item, idx) => (
              <li key={idx} className={styles.noteListItem}>
                <p>{item.text}</p>
              </li>
            ))}
          </ul>
        </div>

        {ProjectFormList.map((form, index) => {
          return (
            <FormAccordion
              index={index}
              formElement={form.formElement}
              formHeading={form.formHeading}
              formIconSrc={form.formIconSrc}
              formInfoSummary={form.formInfoSummary}
              formIconAlt={form.altTextForIcon}
              disabled={form.disabled}
              key={index}
              handleChange={handleAccordianChange}
              selectedAccordion={selectedAccordion}
              showEditLink={form.showEditLink}
              isExpanded={isExpanded}
            />
          );
        })}
      </PageContent>
    </PageLayout>
  );
}
