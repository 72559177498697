import { useTranslation } from "react-i18next";
import { GeneralInfoIcon } from "../../../assets/icons";
import styles from "./ReviewInfo.module.scss";
import { DeveloperApi } from "../../../APIAction/DeveloperAPI";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useUser } from "../../../components/Context/UserContext";
import { getEnumKeyByValue, PROVINCES } from "../../../Constants/Enumerations";
import { DeveloperGeneralInfo } from "../../../TypesAndInterfaces/DeveloperDetailsInterface";

export const ReviewGeneralInfo = () => {
  const { developerId = "" } = useParams();
  const { t } = useTranslation("onboarding");
  const { userState } = useUser();
  const navigate = useNavigate();
  const [dataExists, setDataExists] = useState<boolean>(false);
  const [generalInfoData, setGeneralInfoData] =
    useState<DeveloperGeneralInfo>();

  useEffect(() => {
    const GeneralInfoDataFetch = async () => {
      const response = await DeveloperApi.getGeneralInfoById(developerId);
      setGeneralInfoData(response.data);
      setDataExists(true);
    };
    GeneralInfoDataFetch();
  }, [userState.userId]);

  const provinceMappedValue = generalInfoData
    ? getEnumKeyByValue(PROVINCES, generalInfoData.province)
    : "";

  const provinceTranslated = t(
    `common:enumerations.provinces.${provinceMappedValue}`
  );

  const handleEditClick = () => {
    navigate("/onboarding/developer/edit");
  };

  return (
    <div className={styles.informationContainer}>
      <div className={styles.infoHeadingContainer}>
        <div className={styles.left}>
          <div className={styles.imgContainer}>
            <img src={GeneralInfoIcon} alt="" />
          </div>
          <h3 className={styles.sectionHeading}>
            {t("developer.main_page.form_accordions.gen_info.heading")}
          </h3>
        </div>
        <button className={styles.link} onClick={handleEditClick}>
          {t("developer.review_profile.edit_link")}
        </button>
      </div>
      {dataExists && generalInfoData && (
        <div className={styles.content}>
          <div className={styles.grid_container}>
            <p>{t("investor.general_info_form.org_name_label")}</p>
            <p>{generalInfoData.organizationName}</p>
            <p>{t("investor.review_profile.gen_info.org_summary_label")}</p>
            <p>{generalInfoData.organizationSummary}</p>
            <p>{t("developer.review_profile.gen_info.website_link")}</p>
            <p>{generalInfoData.websiteLink}</p>
          </div>
          <hr className={styles.horizontalLine} />
          <p className={styles.title}>
            {t("developer.review_profile.gen_info.org_address")}
          </p>
          <div className={styles.grid_container}>
            <p>{t("investor.general_info_form.address_label")}</p>
            <p>
              {generalInfoData.address}, {generalInfoData.unitNumber}
              {generalInfoData.unitNumber ? ", " : null}
              {generalInfoData.city}, {provinceTranslated}{" "}
              {generalInfoData.country}, {generalInfoData.postalCode}
            </p>
          </div>
          <hr className={styles.horizontalLine} />
          <p className={styles.title}>
            {t("developer.review_profile.gen_info.contact")}
          </p>
          <div className={styles.grid_container}>
            <p>{t("developer.general_info_form.email_label")}</p>
            <p>{generalInfoData.connectionEmailAddress}</p>
            <p>{t("developer.review_profile.gen_info.phone")}</p>
            <p>{generalInfoData.connectionPhoneNumber}</p>
          </div>
        </div>
      )}
    </div>
  );
};
