import styles from "./Opportunity.module.scss";
import { useTranslation } from "react-i18next";
import { OpenInNew } from "@mui/icons-material";
import Project_001 from "../../../../assets/images/Project_001.jpg";
import Project_002 from "../../../../assets/images/Project_002.jpg";
import Project_003 from "../../../../assets/images/Project_003.jpg";

export function Opportunity() {
  const { t } = useTranslation("landing");

  const projectsShowcase = [
    {
      image: Project_001,
      title: t("opportunities.project_1.title"),
      city: t("opportunities.project_1.city"),
      description: t("opportunities.project_1.description"),
      url: t("opportunities.project_1.url"),
      alt_text: t("opportunities.project_1.alt_text"),
    },
    {
      image: Project_002,
      title: t("opportunities.project_2.title"),
      city: t("opportunities.project_2.city"),
      description: t("opportunities.project_2.description"),
      url: t("opportunities.project_2.url"),
      alt_text: t("opportunities.project_2.alt_text"),
    },
    {
      image: Project_003,
      title: t("opportunities.project_3.title"),
      city: t("opportunities.project_3.city"),
      description: t("opportunities.project_3.description"),
      url: t("opportunities.project_3.url"),
      alt_text: t("opportunities.project_3.alt_text"),
    },
  ];

  return (
    <div className={styles.opportunities}>
      <h2 className={styles.heading} data-testid="opprtunity-heading">
        {t("opportunities.heading")}
      </h2>
      <p className={styles.text} data-testid="opprtunity-text">
        {t("opportunities.sub_heading")}
      </p>

      <div className={styles.showcase} data-testid="project-section">
        {projectsShowcase.map((project, idx) => (
          <div key={idx} className={styles.project}>
            <img
              className={styles.projectImage}
              src={project.image}
              alt={project.alt_text}
            />

            <div className={styles.textContainer}>
              <div className={styles.projectHeader}>
                <h3 className={styles.projectTitle}>{project.title}</h3>
                <p className={styles.projectCity}>{project.city}</p>
              </div>

              <div className={styles.projectSummary}>
                <p className={styles.description}>{project.description}</p>
                <a
                  href={project.url}
                  className={styles.externalLink}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {t("opportunities.see_more_text")}
                  <OpenInNew sx={{ height: "1rem" }} />
                </a>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
