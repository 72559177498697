import { FieldArray, Form, Formik } from "formik";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import styles from "./styles.module.scss";
import { TextInput } from "../common/TextInput";
import { TextArea } from "../common/TextArea";
import { ProvincesSelect } from "../common/ProvincesSelect";
import { DeveloperApi } from "../../../APIAction/DeveloperAPI";
import { useEffect, useState } from "react";
import { useUser } from "../../Context/UserContext";
import { DeveloperOrganizationInfo } from "../../../TypesAndInterfaces/DeveloperDetailsInterface";
import { CheckBoxGroup } from "../common/CheckBoxGroup";
import {
  FINANCING_TYPE,
  ORGANIZATION_TYPE,
  PROJECT_SIZE,
  PROVINCES_CODES,
  getKVArrayFormat,
  getKVArrayFormatForDropdown,
} from "../../../Constants/Enumerations";
import { SelectInput } from "../common/SelectInput";
import { FieldArrayRemoveButton } from "../common/FieldArrayRemoveButton";
import { FieldArrayAddButton } from "../common/FieldArrayAddButton";
import { GlossaryDefinition } from "../../../pages/Developers/Modals/GlossaryDefinitionModal/GlossaryDefinition";
import { ProjectLocationFields } from "../AddProject/ProjectLocationFields";
import { City } from "country-state-city";

type Props = {
  handleResponse: (
    formLable: string,
    successStatus: boolean,
    error?: any,
    developerId?: string,
  ) => void;
  developerId?: string,

};

export function OrganizationInfoForm({ handleResponse, developerId }: Props) {
  const { t } = useTranslation("onboarding");
  const { userState } = useUser();
  const [organizationInfo, setOrganizationInfo] =
    useState<DeveloperOrganizationInfo>();
  const [isDataExist, setIsDataExist] = useState<boolean>(false);
  const [isOrgGlossaryOpenModal, setIsOrgGlossaryOpenModal] =
    useState<boolean>(false);
  const [isOrgTypeGlossaryOpenModal, setIsOrgTypeGlossaryOpenModal] =
    useState<boolean>(false);
  const [isOffordabilityGlossaryModal, setIsOffordabilityGlossaryModal] =
    useState<boolean>(false);

  useEffect(() => {
    const getOrganizationInfoData = async () => {
      try {
        const response = await DeveloperApi.getOrganizationInfoById(
          userState.userId
        );
        setIsDataExist(true);
        setOrganizationInfo(response.data);
      } catch (error) {
        setIsDataExist(false);
        handleResponse("OrganizationInfo", false, error);
      }
    };
    userState.userId !== "" && getOrganizationInfoData();
  }, [userState.userId, handleResponse]);

  const getCityListForProvince = (province:any) => {
    const selectedProvince = PROVINCES_CODES.find((element) => element.value === Number(province));
    if (selectedProvince) {
      const isoCode = selectedProvince.isoCode;
      return City.getCitiesOfState('CA', isoCode).map((city) => city.name);
    }
    return [];
  };

  const regionalFocusValues = {
    province: "",
    city: "",
  };
  
  
  // Function to check if city exists for a given province
  const checkCityExistence = (province:any, cityList:any) => {
    const city =  organizationInfo?.regionalFocus?.find((item:any) => item.province === province)?.city;
    return city && cityList.includes(city) ? city : '';
  };
  
  // Iterate over the data array and check if city exists for each province
  const regionalFocusData = organizationInfo?.regionalFocus?.map((item:any) => ({
    province: item.province,
    city: checkCityExistence(item.province, getCityListForProvince(item.province)),
  }));
  const OrganizationInfoAPIData = {
    establishmentYear: organizationInfo?.establishmentYear?.toString(),
    organizationType: organizationInfo?.organizationType.length === 0 ? organizationInfo?.organizationType : organizationInfo?.organizationType.filter(value =>
      Object.values(ORGANIZATION_TYPE).includes(value)).toString().split(','),
    populationFocus: organizationInfo?.populationFocus,
    impactDescription: organizationInfo?.impactDescription,
    regionalFocus: regionalFocusData && regionalFocusData.length>0 ? regionalFocusData: [regionalFocusValues],
    averageProjectSize: organizationInfo?.averageProjectSize,
    affordabilityOffered: organizationInfo?.affordabilityOffered,
    financingPreviouslyReceived:
      organizationInfo?.financingPreviouslyReceived?.map((opt) =>
        opt.toString()
      ),
  };

  const initialValues = {
    establishmentYear: "",
    organizationType: [],
    populationFocus: "",
    impactDescription: "",
    regionalFocus: [regionalFocusValues],
    averageProjectSize: "",
    affordabilityOffered: "",
    financingPreviouslyReceived: [],
  };
  const maxCharErrorMsg = "investor.general_form_error.max_characters";
  const maxCharsErrorText = t(maxCharErrorMsg, { charNum: 64 });
  const spacingErrorText = t("investor.general_form_error.no_spaces");
  const regxNotAllowOnlySpace = /^(?!\s*$)/;

  const organizationInfoSchema = Yup.object().shape({
    establishmentYear: Yup.string().max(4, t(maxCharErrorMsg, { charNum: 4 })),
    populationFocus: Yup.string().max(300, maxCharsErrorText),
    impactDescription: Yup.string().max(300, maxCharsErrorText),
    regionalFocus: Yup.array()
      .of(
        Yup.object().shape({
          province: Yup.string(),
          city: Yup.string()
            .max(64, maxCharsErrorText)
            .matches(regxNotAllowOnlySpace, spacingErrorText),
        })
      )
      .max(5, "A maximum of 5 is premitted"),
    affordabilityOffered: Yup.string().max(300, maxCharsErrorText),
  });

  const updateOrganizationInformation = async (formValues: Object) => {
    try {
      await DeveloperApi.updateOrganizationInfoById(
        userState.userId,
        formValues
      );
      handleResponse("OrganizationInfo", true);
    } catch (err) {
      handleResponse("OrganizationInfo", false, err);
    }
  };

  const submitHandler = async (formData: object) => {
    try {
      await DeveloperApi.postOrganizationInfo(formData);
      handleResponse("OrganizationInfo", true);
    } catch (err) {
      handleResponse("OrganizationInfo", false, err);
    }
  };

  const OrganizationTypeTranslations = Object.keys(ORGANIZATION_TYPE).filter(val => val !== undefined && val !== "").map(
    (val) => t(`common:enumerations.organization_type.${val}`)
  );
  const OrganizationTypeOptions = getKVArrayFormat(
    ORGANIZATION_TYPE,
    OrganizationTypeTranslations
  );

  const averageProjectSizeTranslation = Object.keys(PROJECT_SIZE).map((val) =>
    t(`common:enumerations.project_size.${val}`)
  );
  const averageProjectSizeRange = getKVArrayFormatForDropdown(
    PROJECT_SIZE,
    averageProjectSizeTranslation
  );
  averageProjectSizeRange.unshift({
    label: t("developer.orgnization_info_form.financial_detail.project_size_placeholder"),
    value: "",
  });
  const averageProjectSize = Array.from(averageProjectSizeRange);
  const FinancingPreviouslyReceivedTranslations = Object.keys(
    FINANCING_TYPE
  ).map((val) => t(`common:enumerations.financing_type.${val}`));
  const FinancingPreviouslyReceivedOptions = getKVArrayFormat(
    FINANCING_TYPE,
    FinancingPreviouslyReceivedTranslations
  );
  const orgTypeErrorMessage = t("developer.orgnization_info_form.org_info.orgTypeSelectionError");
  const validateForm = (values: any, setErrors: any) => {
    const { organizationType } = values;
    let errors: any = {};

    const containsAllOrganizationTypes = [ORGANIZATION_TYPE.NonProfitOrCharity, ORGANIZATION_TYPE.ForProfit].every((value) =>
      organizationType.includes(value)
    )
    if (containsAllOrganizationTypes) {
      errors.organizationType = orgTypeErrorMessage;
    }

    if (Object.keys(errors).length !== 0) {
      setErrors(errors);
      return true;
    }
    return false;
  }

  return (
    <Formik
      initialValues={isDataExist ? OrganizationInfoAPIData : initialValues}
      validationSchema={organizationInfoSchema}
      validateOnChange={false}
      validateOnBlur={false}
      enableReinitialize={true}
      onSubmit={(values: any, { setErrors }) => {
        const {
          establishmentYear,
          regionalFocus,
          financingPreviouslyReceived,
          organizationType,
          ...restValues
        } = values;
        const regionalFocusList = regionalFocus.filter((item:any) => item.province !== "" || item.city !== "");
        const financingPreviouslyReceivedList =
          financingPreviouslyReceived?.map((str: any) => +str);
        const organizationTypeList = organizationType?.map((str: any) => +str);
        const updatedValues = {
          developerID: userState.userId === "" ? developerId : userState.userId,
          establishmentYear: establishmentYear ? +establishmentYear : "",
          regionalFocus: regionalFocusList ? regionalFocusList : [],
          financingPreviouslyReceived: financingPreviouslyReceivedList,
          organizationType: organizationTypeList,
          ...restValues,
        };
        const validationCheck = validateForm(updatedValues, setErrors);
        if (!validationCheck) {
          isDataExist
            ? updateOrganizationInformation({
              ...updatedValues,
            })
            : submitHandler({
              ...updatedValues,
            });
        }
      }}
    >
      {({ values }) => (
        <Form className={styles.formElement} noValidate>
          <>
            {/* Organization Information Section */}
            <div className={styles.fieldContainer}>
              <h3
                className={styles.developerExperienceHeading}
                data-testid="developer-heading"
              >
                {t("developer.orgnization_info_form.org_info.heading")}
              </h3>
              <p
                className={styles.developerExperienceInfo}
                data-testid="developer-info"
              >
                {t("developer.orgnization_info_form.org_info.description")}
              </p>
            </div>
            <div className={styles.fieldContainer}>
              <TextInput
                fieldName="establishmentYear"
                labelText={t(
                  "developer.orgnization_info_form.org_info.year_establishment_label"
                )}
                data-testid="year-of-establishment"
                numOnly
              />
            </div>
            <CheckBoxGroup
              fieldName="organizationType"
              formQuestionText={t(
                "developer.orgnization_info_form.org_info.org_type"
              )}
              setIsOpenModal={setIsOrgTypeGlossaryOpenModal}
              options={OrganizationTypeOptions}
              data-testid="investment-phases"
              linkText={t(
                "developer.orgnization_info_form.focus_area.glossary.organizationTypeGlossary.glossaryDefinitionLink"
              )}
            />
          </>
          {isOrgTypeGlossaryOpenModal && (
              <GlossaryDefinition
                setCloseGlossaryModal={setIsOrgTypeGlossaryOpenModal}
                title={t(
                  "developer.orgnization_info_form.focus_area.glossary.organizationTypeGlossary.title"
                )}
                glossaryContent={t(
                  "developer.orgnization_info_form.focus_area.glossary.organizationTypeGlossary.organizationTypeGlossaryList",
                  {
                    returnObjects: true,
                  }
                )}
                glossaryLink={t(
                  "developer.orgnization_info_form.focus_area.glossary.viewFullGlossaryLink"
                )}
                closeText={t(
                  "developer.orgnization_info_form.focus_area.glossary.closeButton"
                )}
                selectedId="#6"
              />
            )}
          {/* Focus Area */}
          <>
            <hr className={styles.formHorizontalLine} />
            <h3 className={styles.formSectionHeading}>
              {t("developer.orgnization_info_form.focus_area.heading")}
            </h3>
            <p className={styles.formInfo}>
              {t("developer.orgnization_info_form.focus_area.description")}
            </p>
            {isOrgGlossaryOpenModal && (
              <GlossaryDefinition
                setCloseGlossaryModal={setIsOrgGlossaryOpenModal}
                title={t(
                  "developer.orgnization_info_form.focus_area.glossary.title"
                )}
                glossaryContent={t(
                  "developer.orgnization_info_form.focus_area.glossary.organizationInfoGlossaryContent",
                  {
                    returnObjects: true,
                  }
                )}
                paraContent={t("developer.orgnization_info_form.focus_area.glossary.organizationInfoParaContent", {returnObjects: true})}
                glossaryLink={t(
                  "developer.orgnization_info_form.focus_area.glossary.viewFullGlossaryLink"
                )}
                closeText={t(
                  "developer.orgnization_info_form.focus_area.glossary.closeButton"
                )}
                selectedId="#1"
              />
            )}
            <TextArea
              fieldName="populationFocus"
              labelText={t(
                "developer.orgnization_info_form.focus_area.population_focus_label"
              )}
              data-testid="population-focus"
              charLimit={300}
              placeholder={t(
                "developer.orgnization_info_form.focus_area.population_focus_placeholder"
              )}
            />
            <TextArea
              fieldName="impactDescription"
              labelText={t(
                "developer.orgnization_info_form.focus_area.org_impact_label"
              )}
              data-testid="organization-impact"
              charLimit={300}
              linkText={t(
                "developer.orgnization_info_form.focus_area.glossary.glossaryDefinitionLink"
              )}
              setIsOpenModal={setIsOrgGlossaryOpenModal}
              placeholder={t(
                "developer.orgnization_info_form.focus_area.org_impact_placeholder"
              )}
            />
            <FieldArray
              name="regionalFocus"
              render={(arrayHelpers) => (
                <div className={styles.experienceSectionContainer}>
                  <div className={styles.regionalHeadingContainer}>
                    <h4 className={styles.regionalFocusHeading}>
                      {t(
                        "developer.orgnization_info_form.focus_area.regional_focus_label"
                      )}
                    </h4>
                  </div>
                  {values.regionalFocus && values.regionalFocus.length > 0
                    ? values.regionalFocus.map((region: string, index: number) => (
                      <div key={index} className={styles.regionalSectionItem}>

                        <div className={styles.regionTextButton}>
                            <p className={styles.regionText}>
                              {t(
                                "developer.orgnization_info_form.focus_area.region_text"
                              ) + (index + 1)}
                            </p>
                          {values.regionalFocus.length  > 1 && (
                            <FieldArrayRemoveButton
                              onClickHandler={() =>
                                arrayHelpers.remove(index)
                              }
                              buttonText={
                                t(
                                  "developer.orgnization_info_form.focus_area.remove_regional_focus"
                                ) + (index + 1)
                              }
                            />
                          )}
                        </div>
                      <ProjectLocationFields 
                        name={`regionalFocus[${index}]`} 
                        province={values.regionalFocus[index].province} 
                        isDataExist={isDataExist} 
                        placeholder={t("developer.orgnization_info_form.focus_area.province_placeholder")} 
                        cityLabelText={t("developer.orgnization_info_form.focus_area.city_label")} 
                        cityPlaceholder={t("developer.orgnization_info_form.focus_area.city_placeholder")}/>
                      </div>
                    ))
                    : null}
                  {values.regionalFocus &&
                    values.regionalFocus.length > 0 &&
                    values.regionalFocus.length < 5 ? (
                    <div className={styles.centreAlignContainer}>
                      <FieldArrayAddButton
                        onClickHandler={() =>
                          arrayHelpers.push(regionalFocusValues)
                        }
                        buttonText={t(
                          "developer.orgnization_info_form.focus_area.add_regional_focus_text"
                        )}
                      />
                    </div>
                  ) : null}
                </div>
              )}
            />
          </>
          <>
            <hr className={styles.formHorizontalLine} />
            <h3 className={styles.formSectionHeading}>
              {t("developer.orgnization_info_form.financial_detail.heading")}
            </h3>
            <p className={styles.formInfo}>
              {t("developer.orgnization_info_form.financial_detail.description")}
            </p>
            <SelectInput
              fieldName="averageProjectSize"
              labelText={t(
                "developer.orgnization_info_form.financial_detail.project_size_label"
              )}
              options={averageProjectSize}
              data-testid="average-project-size"
              placeholder={t(
                "developer.orgnization_info_form.financial_detail.project_size_placeholder"
              )}
            />
            {isOffordabilityGlossaryModal && (
              <GlossaryDefinition
                setCloseGlossaryModal={setIsOffordabilityGlossaryModal}
                title={t(
                  "developer.orgnization_info_form.financial_detail.glossary.title"
                )}
                glossaryContent={[]}
                paraContent={t("developer.orgnization_info_form.financial_detail.glossary.offordabilityInfoGlossaryParaContent",    {
                  returnObjects: true,
                })}
                glossaryLink={t(
                  "developer.orgnization_info_form.financial_detail.glossary.viewFullGlossaryLink"
                )}
                closeText={t(
                  "developer.orgnization_info_form.financial_detail.glossary.closeButton"
                )}
                selectedId="#2"
              />
            )}
            <TextArea
              fieldName="affordabilityOffered"
              labelText={t(
                "developer.orgnization_info_form.financial_detail.affordability_label"
              )}
              data-testid="affordability-offered"
              charLimit={300}
              linkText={t(
                "developer.orgnization_info_form.financial_detail.glossary.glossaryDefinitionLink"
              )}
              setIsOpenModal={setIsOffordabilityGlossaryModal}
              placeholder={t(
                "developer.orgnization_info_form.financial_detail.affordability_placeholder"
              )}
            />

            <CheckBoxGroup
              fieldName="financingPreviouslyReceived"
              formQuestionText={t(
                "developer.orgnization_info_form.financial_detail.financing_type_label"
              )}
              options={FinancingPreviouslyReceivedOptions}
              data-testid="financing-previously-received"
              placeholder={t(
                "developer.orgnization_info_form.financial_detail.financing_type_placeholder"
              )}
            />
          </>
          <div className={styles.buttonContainer}>
            <button
              type="submit"
              className={styles.formButton}
              data-testid="submit-button"
            >
              {t("developer.main_page.next")}
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
}
