import { AffordabilityBenchmarkForm } from "../../components/Form/AddProject/AffordabilityBenchmarkForm";
import { PageContent } from "../../components/Layout/PageContent";
import { PageLayout } from "../../components/Layout/PageLayout";
import CalculatorIcon from "../../assets/icons/developer/Calculator.svg";
import styles from "./AddProject.module.scss";
import { useTranslation } from "react-i18next";
import {
  FinancialDetailsIcon,
  FocusAreaIcon,
  GeneralInfoIcon,
} from "../../assets/icons";
import { ProjectGeneralInformationForm } from "../../components/Form/AddProject/ProjectGeneralInformationForm";
import { FormAccordion } from "../FormAccordion";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { ProjectFinancialDetails } from "../../components/Form/AddProject/ProjectFinancialDetailsForm";
import { ListingProjectAPI } from "../../APIAction/ListingProjectAPI";
import { PROJECT_LISTING_STATUS } from "../../Constants/Enumerations";
import { ProjectImpactCriteriaForm } from "../../components/Form/AddProject/ProjectImpactCriteria";
import { ProjectDocumentationForm } from "../../components/Form/AddProject/ProjectDocumentationForm";

export function AddProject() {
  const { t } = useTranslation("profile");
  const navigate = useNavigate();
  const { projectId } = useParams();
  const location = useLocation();
  const isEditExistingProject = location.pathname.includes("edit");
  const [projectListingId, setprojectListingId] = useState(
    isEditExistingProject ? projectId : ""
  );
  const [isExpanded, setIsExpanded] = useState(true);
  const [isAffordabilitySubmit, setIsAffordabilitySubmit] = useState(
    false
  );
  const [selectedAccordion, setSelectedAccordion] = useState(0);
  const [isFinancialDetailsSubmit, setIsFinancialDetailsSubmit] =
    useState(false);
  const [isGenInfoSubmit, setIsGenInfoSubmit] = useState(false);
  const [isProjectDraft, setIsProjectDraft] = useState(false);
  const [isImpactAreaSubmit, setIsImpactAreaSubmit] = useState(false);
  const [isDocumentationDone, setIsDocumentationDone] = useState(false);

  useEffect(() => {
    const projectListingDataByID = async (id: string) => {
      try {
        const response = await ListingProjectAPI.getProjectListingInfoByID(id);
        setIsProjectDraft(
          response?.data?.projectListingStatus === PROJECT_LISTING_STATUS.Draft
        );
      } catch (error) {
        return;
      }
    };
    if (projectListingId) {
      projectListingDataByID(projectListingId);
    }
  }, [navigate, projectListingId, isEditExistingProject]);

  useEffect(() => {
    if (isDocumentationDone) {
      isEditExistingProject && !isProjectDraft
        ? navigate(`/projects/updated/${projectListingId}`)
        : navigate(`/projects/review/${projectListingId}`);
    }
  }, [
    isDocumentationDone,
    navigate,
    projectListingId,
    isEditExistingProject,
    isProjectDraft,
  ]);

  const noteItems = [
    { text: t("developer.projects.note.item_1") },
    { text: t("developer.projects.note.item_2") },
    { text: t("developer.projects.note.item_3") },
  ];

  const handleAccordianChange = (idValue: number) => {
    if (idValue !== selectedAccordion) {
      setIsExpanded(true);
      setSelectedAccordion(idValue);
    }
    if (idValue === selectedAccordion) {
      isExpanded ? setIsExpanded(false) : setIsExpanded(true);
    }
    window.scrollTo(0, 400);
  };

  const handleGeneralInfoResponse = (
    isSuccess: boolean,
    projectListingId: string
  ) => {
    if (isSuccess) {
      setIsGenInfoSubmit(true);
      handleAccordianChange(2);
      projectListingId !== "" && setprojectListingId(projectListingId);
    } else {
      return;
    }
  };

  const handleResponse = (infoSectionName: string, isSuccess: boolean, id?: string) => {
    if (isSuccess) {
      switch (infoSectionName) {
        case "Affordability":
          if (id) {
            setprojectListingId(id);
          }
          setIsAffordabilitySubmit(true);
          handleAccordianChange(1);
          break;
        case "FinancialDetails":
          setIsFinancialDetailsSubmit(true);
          handleAccordianChange(3);
          break;
        case "ImpactCriteria":
          setIsImpactAreaSubmit(true);
          handleAccordianChange(4);
          break;
        case "Documentation":
          setIsDocumentationDone(true);
          break;
        default:
          break;
      }
    } else {
      return;
    }
  };

  const ProjectFormList = [
    {
      formElement: (
        <AffordabilityBenchmarkForm
          handleResponse={handleResponse}
          projectListingId={projectListingId}
        />
      ),
      altTextForIcon: t("developer.projects.add_listing_form.calculatorIcon"),
      formHeading: t(
        "developer.projects.add_listing_form.affordability_benchmark_title"
      ),
      formInfoSummary: t(
        "developer.projects.add_listing_form.affordability_benchmark_info"
      ),
      formIconSrc: CalculatorIcon,
      disabled: false,
      showEditLink: isAffordabilitySubmit,
    },
    {
      formElement: (
        <ProjectGeneralInformationForm
          handleResponse={handleGeneralInfoResponse}
          isAffordabilitySubmit={isAffordabilitySubmit}
          projectListingId={projectListingId}
          isGenInfoSubmit={isGenInfoSubmit}
        />
      ),
      altTextForIcon: t("developer.projects.general_info_form.personicon"),
      formHeading: t("developer.projects.general_info_form.heading"),
      formInfoSummary: t("developer.projects.general_info_form.summary"),
      formIconSrc: GeneralInfoIcon,
      disabled: !isAffordabilitySubmit,
      showEditLink: isGenInfoSubmit,
    },
    {
      formElement: (
        <ProjectFinancialDetails
          handleResponse={handleResponse}
          projectListingId={projectListingId}
        />
      ),
      formHeading: t("developer.projects.financial_details_doc_form.heading"),
      formIconSrc: FinancialDetailsIcon,
      altTextForIcon: t("developer.projects.general_info_form.personicon"),
      formInfoSummary: t(
        "developer.projects.financial_details_doc_form.summary"
      ),
      disabled: !isGenInfoSubmit,
      showEditLink: isFinancialDetailsSubmit,
    },
    {
      formElement: (
        <ProjectImpactCriteriaForm
          handleResponse={handleResponse}
          projectListingId={projectListingId}
        />
      ),
      altTextForIcon: t("developer.projects.impact_criteria_form.targetIcon"),
      formHeading: t("developer.projects.impact_criteria_form.heading"),
      formInfoSummary: t("developer.projects.impact_criteria_form.summary"),
      formIconSrc: FocusAreaIcon,
      disabled: !isFinancialDetailsSubmit,
      showEditLink: isImpactAreaSubmit,
    },
    {
      formElement: (
        <ProjectDocumentationForm
          handleResponse={handleResponse}
          projectListingId={projectListingId}
        />
      ),
      altTextForIcon: t("developer.projects.impact_criteria_form.targetIcon"),
      formHeading: t("developer.projects.file_upload_doc.documentation"),
      formInfoSummary: t("developer.projects.file_upload_doc.summary"),
      formIconSrc: FocusAreaIcon,
      disabled: !isImpactAreaSubmit,
      showEditLink: false,
    },
  ];

  return (
    <PageLayout>
      <PageContent>
        <h1 className={styles.pageTitle}>
          {projectId
            ? t("developer.projects.add_listing.page_title")
            : t("developer.projects.update_listing.page_title")}
        </h1>

        <p className={styles.summaryText}>
          {projectId
            ? t("developer.projects.add_listing.page_summary")
            : t("developer.projects.update_listing.page_summary")}
        </p>
        <div className={styles.noteContainer}>
          <h2 className={styles.noteHeading}>
            {t("developer.projects.note.heading")}
          </h2>
          <ul className={styles.noteList}>
            {noteItems.map((item, idx) => (
              <li key={idx} className={styles.noteListItem}>
                <p>{item.text}</p>
              </li>
            ))}
          </ul>
        </div>

        {ProjectFormList.map((form, index) => {
          return (
            <FormAccordion
              index={index}
              formElement={form.formElement}
              formHeading={form.formHeading}
              formIconSrc={form.formIconSrc}
              formInfoSummary={form.formInfoSummary}
              formIconAlt={form.altTextForIcon}
              disabled={form.disabled}
              key={index}
              handleChange={handleAccordianChange}
              selectedAccordion={selectedAccordion}
              showEditLink={form.showEditLink}
              isExpanded={isExpanded}
            />
          );
        })}
      </PageContent>
    </PageLayout>
  );
}
