import styles from "./FinancialDetails.module.scss";

type Props = {
  highlightLabel: string;
  highlightValueArrary: string[];
};

export function FinancialDetailHighlightMulti(props: Props) {
  return (
    <div className={styles.highlightContainer}>
      <p className={styles.highlightLabel}>{props.highlightLabel}</p>
      <p className={styles.highlightValue}>
        {props.highlightValueArrary?.map(
          (val, idx) =>
            `${val}${idx < props.highlightValueArrary.length - 1 ? ", " : ""}`
        )}
      </p>
    </div>
  );
}
