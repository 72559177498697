import { ReactNode } from "react";
import styles from "./PageContent.module.scss";

type Props = {
  children: ReactNode;
};

export function PageContent(props: Props) {
  return <div className={styles.content}>{props.children}</div>;
}
