import { Modal } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Dispatch, SetStateAction, useState } from "react";
import { RemoveIcon } from "../../../assets/icons";

import styles from "./RemoveProject.module.scss";
import CloseIcon from "@mui/icons-material/Close";

type Props = {
  setRemoveProject: Dispatch<SetStateAction<boolean>>;
  removeProject: Function;
};

export function RemoveProject(props: Props) {
  const [openModal, setOpenModal] = useState(true);
  const { t } = useTranslation(["project"]);
  const setRemoveProject = props.setRemoveProject;

  const handleClose = () => {
    setOpenModal(false);
    setRemoveProject(false);
  };

  const removeProject = async () => {
    props.removeProject();
    handleClose();
  };

  return (
    <Modal open={openModal} onClose={handleClose}>
      <div
        className={styles.deleteprojectContainer}
      >
        <div className={styles.modalContentWrapper}>
          <div className={styles.titleContainer} data-testid="title-container">
            <img
              src={RemoveIcon}
              alt=""
              className={styles.icon}
              data-testid="exclamation-Icon"
            />
            <h2 className={styles.title} data-testid="pageTitle">
              {t("project:project.delete_modal.remove_project_title")}
            </h2>
          </div>
          {/* <div className={styles.termsModalcloseicon}> */}

          <button
            onClick={handleClose}
            className={styles.crossIcon}
            data-testid="close-button"
          >
            <CloseIcon />
          </button>
          {/* </div> */}

          <div
            className={styles.removecontainer}
            data-testid="delete-Container"
          >
            <p>
            {t("project:project.delete_modal.remove_project_confirmation")}
            </p>
          </div>
          <div className={styles.buttonsContainer}>


            <button
              className={styles.closeProjectButton}
              onClick={removeProject}
            >
            {t("project:project.delete_modal.sure_text")}

            </button>
            <button
              className={styles.cancelButton}
              onClick={handleClose}
              data-testid="Cancel-button"
            >
              {t("project:project.delete_modal.cancel_btn")}
            </button>

          </div>
        </div>
      </div>
    </Modal>
  );
}
