import { useTranslation } from "react-i18next";
import styles from "./styles.module.scss";
import { Sad_Face } from "../../assets/images/index";

interface Props {
  isLabelChanged?:boolean;
  isInvestorSavedProject?: boolean;
}

export const NoListedProjectsResultsWithFilter = ({isLabelChanged,isInvestorSavedProject}:Props) => {
  const { t } = useTranslation("filter");
  return (
    <div className={styles.noInvestorsFound}>
      {isLabelChanged ? 
      <>
      <img
        data-testid="crying-face"
        src={Sad_Face}
        alt={t("searchProjectsPage.noResultsImageAlt")}
      />
      <div>
        <h2 data-testid="no-investors-found-heading">
          {t("searchProjectsPage.noResultsTitleWithFilter_portfolio")}
        </h2>
        <p data-testid="no-investors-found-heading">
          {t("searchProjectsPage.noResultsTextWithFilter_portfolio")}
        </p>
      </div>
      </>
      :
      <>
      <img
        data-testid="crying-face"
        src={Sad_Face}
        alt={t("searchProjectsPage.noResultsImageAlt")}
      />
      <div>
        <h2 data-testid="no-investors-found-heading">
          {t("searchProjectsPage.noResultsTitleWithFilter")}
        </h2>
        <p data-testid="no-investors-found-heading">
              {isInvestorSavedProject ? t("searchProjectsPage.noResultsTextWithFilterSavedProject") : t("searchProjectsPage.noResultsTextWithFilter")}
        </p>
      </div>
      </>
    }
    </div>
  );

};
