import { ExpandMore } from "@mui/icons-material";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import styles from "./styles.module.scss";
import { PortfolioProjects } from "../../../../TypesAndInterfaces/PortfolioDetailsInterface";
import {
  getKVArrayFormat,
  CONSTRUCTION_TYPE,
  getKVArrayFormatForDropdown,
  COMMITMENT_TO_AFFORDABILITY,
} from "../../../../Constants/Enumerations";
import { useTranslation } from "react-i18next";
import { useState } from "react";

type AccordionContainerProps = {
  content: PortfolioProjects;
  expanded?: boolean;
};

export const AccordionContainer = ({
  content,
  expanded
}: AccordionContainerProps) => {

  const { t } = useTranslation("portfolio");
  const [isExpanded, setIsExpanded] = useState(expanded);

  const projectPhaseOption = [
    {
      label: t(`common:enumerations.investment_phases.Acquisition`),
      value: 1,
    },
    {
      label: t(`common:enumerations.investment_phases.PreDevelopment`),
      value: 2,
    },
    {
      label: t(`common:enumerations.investment_phases.Construction`),
      value: 3,
    },
    {
      label: t(`common:enumerations.investment_phases.PostConstruction`),
      value: 4,
    },
    {
      label: t(`common:enumerations.investment_phases.Seed`),
      value: 5,
    },
  ];
  const projectPhase = projectPhaseOption.find(
    (item) =>
      //@ts-ignore
      +item.value === content.projectPhase
  )?.label;

  const landStatusOption = [
    {
      label: t(`common:enumerations.land_status.LandSecured`),
      value: 1,
    },
    {
      label: t(`common:enumerations.land_status.LandNeeded`),
      value: 2,
    },
    {
      label: t(`common:enumerations.land_status.LandAcquisitioInProcess`),
      value: 3,
    },
    {
      label: t(`common:enumerations.land_status.PreferNotToDdisclose`),
      value: 9,
    },
  ];


  const landStatus = landStatusOption.find(
    (item) =>
      //@ts-ignore
      +item.value === content.landStatus
  )?.label;

  const constructionTranslations = Object.keys(CONSTRUCTION_TYPE).map((val) =>
    t(`common:enumerations.construction_type.${val}`)
  );
  const constructionTypeOption = getKVArrayFormat(
    CONSTRUCTION_TYPE,
    constructionTranslations
  );
  const constructionType = constructionTypeOption.find(
    (item) => item.value === content.constructionType
  )?.label;


  const commitmentAffordabilityTrans = Object.keys(COMMITMENT_TO_AFFORDABILITY).map((val) =>
    t(`common:enumerations.commitment_to_affordability.${val}`)
  );
  const commitmentAffordabilityOptions: {
    label: string;
    value: string | number;
  }[] = getKVArrayFormatForDropdown(COMMITMENT_TO_AFFORDABILITY, commitmentAffordabilityTrans);

  const affordabilityCommitment =
    commitmentAffordabilityOptions.find(
      (item) => +item.value === content.affordabilityCommitment
    )?.label;
      
    const unitCountVal = parseFloat(content?.unitCount);
    const unitPercentBelowMarketRent = content?.unitPercentBelowMarketRent;

  return (
    <Accordion
      key={content.projectName}
      disableGutters
      style={{
        minWidth: "600px",
        minHeight: "15px",
        border: "1px solid #CCCCCC",
        backgroundColor: "#FFFFFF",
      }}
      className={styles.border}
      expanded={isExpanded}
      onChange={() => { setIsExpanded(!isExpanded) }}
    >
      <AccordionSummary
        expandIcon={<ExpandMore className={styles.icon} />}
        className={styles.accordionSummary}
      >
        <h4>{t("portfolio_details.project.view_proj_detail")}

        </h4>
      </AccordionSummary>
      <AccordionDetails className={styles.accordionDetails}>
        <div className={styles.splitFieldContainer}>
          <div>
            <p>{t("portfolio_details.project.num_of_unit")}</p>
            <p>{content.unitCount}</p>
          </div>
          <div>
            <p>{t("portfolio_details.project.percentage_affordable_unit")}</p>
            <p> {unitPercentBelowMarketRent}% </p>
          </div>
          <div>
            <p>{t("profile:developer.projects.affordability_details.commitment_to_affordability")}</p>
            <p> {affordabilityCommitment} </p>
          </div>
        </div>

        {(content.targetStartDate && content.targetCompletionDate) && <div className={styles.splitFieldContainerDate}>
          {content.targetStartDate &&
            <div>
              <p>{t("portfolio_details.project.target_startdate")}</p>
              <p> {content.targetStartDate} </p>
            </div>
          }
          {content.targetCompletionDate &&
            <div>
              <p>{t("portfolio_details.project.target_completion")}</p>
              <p> {content.targetCompletionDate} </p>
            </div>
          }

        </div>
        }

        <div className={styles.descriptionContainer}>
          <p className={styles.heading}>{t("portfolio_details.project.project_desc")}</p>
          <p className={styles.summary}>{content.projectDescription}</p>
        </div>
        <hr className={styles.horizontalDivider} />
        <div className={styles.descriptionContainer}>
          <p className={styles.heading}>{t("portfolio_details.project.cost_project")}</p>
          <div className={styles.gridContainer}>
            <p>{t("portfolio_details.project.construction_type")}</p>
            <p>{constructionType}</p>
            <p>{t("portfolio_details.project.development_process")}</p>
            <p>{projectPhase}</p>
            <p>{t("portfolio_details.project.land_status")}</p>
            <p>{landStatus && landStatus}</p>
          </div>
        </div>
        <hr className={styles.horizontalDivider} />
        <div className={styles.descriptionContainer}>
          <p className={styles.heading}>{t("portfolio_details.project.development_impact")}</p>
          <div className={styles.gridContainer}>
            <p>{t("portfolio_details.project.project_impact_desc")}</p>
            <p> {content.developmentImpactDescription}</p>
          </div>
        </div>
      </AccordionDetails>
    </Accordion>
  );
};
