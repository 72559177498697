import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './styles.module.scss'

type Props = {
  content: any;
  limit: number;
  renderFunction: (item: any,index:number) => React.ReactNode;
}
export const ShowMoreLess = (props: Props) => {
  const { content, limit, renderFunction } = props;
  const [isExpanded, setIsExpanded] = useState(false);
  const displayedData = isExpanded ? content : content.slice(0, limit);
  const { t } = useTranslation('common');
  const showText = t("showMoreLessText.show");
  const lessText = t("showMoreLessText.less");
  const moreText = t("showMoreLessText.more");

  return (
    <>
      {displayedData.map((item: any, index: number) => (
        renderFunction(item,index)
      ))}
      {content.length > limit &&
        <button type="button" data-testid="show-moreless-btn"
          className={styles.showMoreLessLink}
          onClick={() => setIsExpanded(!isExpanded)}>
          {isExpanded ? showText + ' ' + lessText :
            showText + ' ' + (content.length - limit) + ' ' + moreText}
        </button>
      }
    </>
  )

};

export default ShowMoreLess;
