import { Formik, Form } from "formik";
import { useTranslation } from "react-i18next";
import styles from "./SearchFilterSidebar.module.scss";
import { Divider } from "@mui/material";
import { CheckBoxGroup } from "../../Form/common/CheckBoxGroup";
import {
  PROVINCES,
  FUNDING_TYPES,
  ROI_RANGE,
  getKVArrayFormat,
  getEnumKeyByValue,
  LAND_FILTER,
  PROVINCES_CODES,
} from "../../../Constants/Enumerations";
import { SearchRangeInput } from "./SearchRangeInput";
import Select, { components, Options, OptionProps } from "react-select";
import { useState } from "react";
import { City } from "country-state-city";
import { DropdownOptionType } from "./ProjectsFilterSidebar";

type Props = {
  setFilteredResults: Function;
  setFilters: Function;
  setPage: Function;
  setSelectedFilter?: Function;
  selectedFilter?: Object;
  setCityList: Function;
  cityList: any;
};

type InvestorFilterValues = {
  provincialFocusArears?: string[];
  fundingTypes?: string[];
  roiTargetMinimum?: string;
  roiTargetMaximum?: string;
  cities?: string[];
  offeringLand?: string[];
};

type GAEvents = {
  [key: string]: any;
  event: string;
};

type FormValues = {
  provincialFocusArears?: string[];
  fundingTypes?: string[];
  roiTargetMinimum?: string;
  roiTargetMaximum?: string;
  offeringLand?: string[];
  cities?: string[];
}

const customStyles = {
  control: (base: any) => ({
    ...base,
    minHeight: 35,
    maxHeight: 120,
    width: 240,
    maxWidth: "90%",
    marginLeft: "1rem",
    overflow: "auto",
    disabled: (styles: any) => ({
      ...styles,
      backgroundColor: 'red', // Color of the disabled state
    }),

  }),
};

export function InvestorsFilterSidebar(props: Props) {
  const { t } = useTranslation(["filter", "common"]);
  const [isoCode, setIsoCode] = useState("");
  const { cityList, setCityList } = props;

  const initialValues: FormValues = {
    provincialFocusArears: [],
    fundingTypes: [],
    roiTargetMinimum: "",
    roiTargetMaximum: "",
    offeringLand: [],
    cities: [],
  };

  const noFilters = {
    start: 0,
    pageSize: 6,
    sortDirection: 0,
  };

  const provincesTranslated = Object.keys(PROVINCES).map((val) =>
    t(`common:enumerations.provinces.${val}`)
  );
  const provinceOptions = getKVArrayFormat(PROVINCES, provincesTranslated);

  const funingTypesTranslated = Object.keys(FUNDING_TYPES).map((val) =>
    t(`common:enumerations.funding_types.${val}`)
  );
  const fundingOptions = getKVArrayFormat(FUNDING_TYPES, funingTypesTranslated);

  const landTranslated = Object.keys(LAND_FILTER).map((val) =>
    t(`common:enumerations.land_filter.${val}`)
  );
  const landOptions = getKVArrayFormat(LAND_FILTER, landTranslated);

  const roirangesTrans = Object.keys(ROI_RANGE).map((val) =>
    t(`common:enumerations.roi_range.${val}`)
  );
  const roiPlaceholder = { label: t("selectPlaceHolder"), value: "" };
  const roiOptions: { label: string; value: string | number }[] =
    getKVArrayFormat(ROI_RANGE, roirangesTrans);
  roiOptions.unshift(roiPlaceholder);

  function handleGa(v: InvestorFilterValues) {
    const gaFilters: GAEvents = { event: "apply_filter" };
    // Flush related filters from Project Search
    gaFilters["project_phase"] = undefined;
    gaFilters["financing_range"] = undefined;
    gaFilters["ESG_impact_criteria"] = undefined;

    if (v.provincialFocusArears && v.provincialFocusArears.length > 0) {
      const provinceFocusAreaMappedValue = v.provincialFocusArears.map((val) =>
        getEnumKeyByValue(PROVINCES, Number(val))
      );
      const provinceFocusAreaTranslated = provinceFocusAreaMappedValue.map(
        (val) => t(`common:enumerations.provinces.${val}`)
      );
      provinceFocusAreaTranslated.sort();
      gaFilters["provincial_focus_area"] =
        provinceFocusAreaTranslated.join(", ");
    }
    if (v.fundingTypes && v.fundingTypes.length > 0) {
      const fundingTypesMappedValues = v.fundingTypes.map((val) =>
        getEnumKeyByValue(FUNDING_TYPES, Number(val))
      );
      const fundingTypesTranslated = fundingTypesMappedValues.map((val) =>
        t(`common:enumerations.funding_types.${val}`)
      );
      fundingTypesTranslated.sort();
      gaFilters["type_of_funding"] = fundingTypesTranslated.join(", ");
    }
    if (v.roiTargetMinimum !== "" || v.roiTargetMaximum !== "") {
      const targetRoiMaxMappedValue = getEnumKeyByValue(
        ROI_RANGE,
        Number(v.roiTargetMaximum)
      );
      const targetRoiMinMappedValue = getEnumKeyByValue(
        ROI_RANGE,
        Number(v.roiTargetMinimum)
      );
      const targeRoiMinTranslated = t(
        `common:enumerations.roi_range.${targetRoiMinMappedValue}`
      );
      const targeRoiMaxTranslated = t(
        `common:enumerations.roi_range.${targetRoiMaxMappedValue}`
      );
      let targeRoiTranslated = `${targeRoiMinTranslated}, ${targeRoiMaxTranslated}`;

      if (v.roiTargetMinimum === "") {
        targeRoiTranslated = targeRoiMaxTranslated;
      } else if (v.roiTargetMaximum === "") {
        targeRoiTranslated = targeRoiMinTranslated;
      }
      gaFilters["target_roi_range"] = targeRoiTranslated;
    }

    if (v.cities && v.cities.length > 0) {
      const cities = v.cities;
      cities.sort();
      gaFilters["city"] = cities.join(", ");
    }

    if (v.offeringLand && v.offeringLand.length > 0) {
      gaFilters["offering_land"] = landOptions[0].label;
    }
    window.dataLayer.push(gaFilters);
  }

  function resetGa() {
    const gaFilters: GAEvents = { event: "apply_filter" };

    // Flush related filters from Project Search
    gaFilters["project_phase"] = undefined;
    gaFilters["financing_range"] = undefined;

    // Reset this component's filter values
    gaFilters["provincial_focus_area"] = undefined;
    gaFilters["type_of_funding"] = undefined;
    gaFilters["target_roi_range"] = undefined;

    window.dataLayer.push(gaFilters);
  }

  const initialValuesWithSelectedFilter: FormValues = {
    ...initialValues,
    ...props.selectedFilter,
    //@ts-ignore
    offeringLand: props.selectedFilter?.offeringLand ? ["1"] : [], // Convert boolean value to ["1"] if true, otherwise []
  };

  const getProvince = (selectedProvinces: any) => {
    if (selectedProvinces.length === 1) {
      const selectedProvince = PROVINCES_CODES.find((element) => element.value === Number(selectedProvinces[0]));
      if (selectedProvince) {
        setIsoCode(selectedProvince.isoCode);
      }
    } else {
      setIsoCode(""); // Set isoCode to empty string when no province is selected
      setCityList(null);
    }
  };

  const handleCityChange = (selectedOptions: any) => {
    if (selectedOptions && selectedOptions.length <= 15) {
      setCityList(selectedOptions);
    }
  };

  const CustomOption = (innerProps: OptionProps<DropdownOptionType, true>) => {
    const style = {
      alignItems: "center",
      backgroundColor: "red",
      color: "inherit",
      display: "flex ",
    };

    // prop assignment
    const temp = {
      ...innerProps,
      style,
    };

    return (
      <components.Option
        {...temp}
        isDisabled={innerProps.isDisabled}
        isFocused={innerProps.isFocused}
        isSelected={innerProps.isSelected}
        className={
          innerProps.isSelected
            ? styles.optionSelected
            : styles.optionUnselected
        }
      >
        <input
          type="checkbox"
          checked={innerProps.isSelected}
          className={styles.checkboxStyle}
        />
        &nbsp;{innerProps.children}
      </components.Option>
    );
  };

  return (
    <div
      className={styles.sidebarContainer}
      data-testid="investor-sidebar-container"
    >
      <h2 className={styles.textPadding} data-testid="investor-sidebar-heading">
        {t("filtersHeading")}
      </h2>
      <p
        className={styles.textPaddingNoTop}
        data-testid="investor-sidebar-description"
      >
        {t("filtersSubText")}
      </p>
      <Divider light />

      <Formik
        initialValues={initialValuesWithSelectedFilter || initialValues as FormValues}
        onSubmit={(values: FormValues) => {
          const offeringLand = Array.isArray(values.offeringLand) ? values.offeringLand.includes("1") : values.offeringLand;
          let selectedCities: string[] = [];
          if (cityList) {
            cityList.map((city: any) => (
              selectedCities.push(city.name)
            )
            )
          }
          //@ts-ignore
          values.cities = selectedCities;
          // Create the updateFilters object
          const updateFilters = {
            start: 0,
            pageSize: 6,
            sortColumn: "organizationName",
            sortDirection: 0,
            filter: {
              ...values, // Include other filter values as they are
              offeringLand, // Set offeringLand to boolean
            }
          };
          handleGa(values);
          props.setSelectedFilter &&
            props.setSelectedFilter(updateFilters.filter);

          props.setPage(1);
          props.setFilters(updateFilters);
        }}
        onReset={() => {
          props.setSelectedFilter && props.setSelectedFilter(null);

          props.setPage(1);
          props.setFilters(noFilters);
          resetGa();
        }}
      >
        {(values) => (
          <Form>
            <div className={styles.investorCheckBoxGroupStyle}>
              <CheckBoxGroup
                fieldName="provincialFocusArears"
                formQuestionText={t("filterInvestors.formFieldName1")}
                options={provinceOptions}
                data-testid="province"
                onCheckBoxClick={getProvince(values.values.provincialFocusArears!)!}
              />
              <div className={styles.multiSelectPadding}>
                <h3 className={`${styles.textPadding}`}>
                  {t("projectFilter.cities")}
                </h3>
                <p className={styles.cityCaption}>{t("projectFilter.cityCaption")}</p>
                <Select
                  isDisabled={isoCode === ""}
                  isMulti={true}
                  name="cities"
                  value={cityList && cityList}
                  isClearable={true}
                  // @ts-ignore
                  options={
                    City.getCitiesOfState(
                      "CA",
                      isoCode
                    )}
                  getOptionLabel={(options) => {
                    //@ts-ignore
                    return options["name"];
                  }}
                  getOptionValue={(options: any) => {
                    return options["name"];
                  }}
                  onChange={(list: Options<DropdownOptionType>) => {
                    props.setSelectedFilter && props.setSelectedFilter(values.values);
                    handleCityChange(list);
                  }}

                  components={{
                    Option: CustomOption,
                  }}
                  openMenuOnFocus={true}
                  closeMenuOnSelect={false}
                  hideSelectedOptions={false}
                  placeholder={t("impact_criteria.placeholder")}
                  styles={customStyles}
                  isSearchable={true}
                />
              </div>
              <CheckBoxGroup
                fieldName="fundingTypes"
                formQuestionText={t("filterInvestors.formFieldName2")}
                options={fundingOptions}
                data-testid="capital-funding-type"
              />
              <CheckBoxGroup
                fieldName="offeringLand"
                formQuestionText={t("filterInvestors.formFieldName4")}
                options={landOptions}
                data-testid="provide-Land-type"
              />
            </div>
            <h3 className={styles.textPadding}>
              {t("filterInvestors.formFieldName3")}
            </h3>
            <div className={styles.selectInputStyle}>
              <SearchRangeInput
                fieldName="roiTargetMinimum"
                labelText={t("filterInvestors.formFieldName3_1")}
                options={roiOptions}
                data-testid="roi-target-min"
              />
              <SearchRangeInput
                fieldName="roiTargetMaximum"
                labelText={t("filterInvestors.formFieldName3_2")}
                options={roiOptions}
                data-testid="roi-target-max"
              />
            </div>

            <button
              type="reset"
              className={styles.formButton2}
              data-testid="reset-button"
            >
              {t("buttonFilterClear")}
            </button>
            <button
              type="submit"
              className={styles.formButton}
              data-testid="submit-button"
            >
              {t("buttonFilterText")}
            </button>
          </Form>
        )}
      </Formik>
    </div>
  );
}
