import React from "react";
import { useTranslation } from "react-i18next";
import { PortfolioProjectDetail } from "../../../TypesAndInterfaces/PortfolioDetailsInterface";
import style from "./projectsInPortfolio.module.scss";
import { PROVINCES, getEnumKeyByValue } from "../../../Constants/Enumerations";
import { AccordionContainer } from "./AccordionContainer/AccordionContainer";
import { ImageGallery } from "../../../components/Form/common/ProjectImages";

type Props = {
  portfolioProjectDetail: PortfolioProjectDetail;
  isPortfolio: boolean;
};

export function ProjectsInPortfolio({ portfolioProjectDetail, isPortfolio }: Props) {
  const { t } = useTranslation(["portfolio", "profile"]);
  const { totalProjectCount, portfolioProjects } = portfolioProjectDetail;
  const getProvinceName = (provinceNumber: number) => {
    const provinceMappedValue = getEnumKeyByValue(PROVINCES, provinceNumber);

    const provinceTranslated = t(
      `common:enumerations.provinces.${provinceMappedValue}`
    );
    return provinceTranslated;
  };

  const sasTokenDocumentStorage = process.env.REACT_APP_DOCUMENT_SAS_TOKEN;
  return (
    <div className={style.projectContainer}>
      {portfolioProjects.length > 0 &&
        portfolioProjects.map((proj, index) => {
          return (
            <React.Fragment>
              {portfolioProjects[index].blobs && portfolioProjects[index].blobs?.length !== 0 &&
                <React.Fragment>
                  <h3
                    className={style.sectionHeading}
                  >
                    {t("profile:developer.projects.file_upload.portfolio_project_images")}
                  </h3>
                  <div style={{ marginBottom: "5rem" }}>
                    <ImageGallery images={portfolioProjects[index].blobs} isPortfolio={isPortfolio}/>
                  </div>
                </React.Fragment>
              }
              <div className={style.container}>
                <div className={style.containerHeader}>
                  {
                    //@ts-ignore
                    portfolioProjects[index].blobs && portfolioProjects[index].blobs?.length > 0 &&
                    <div className={style.imageContainer}>
                      <img
                        src={    //@ts-ignore
                          portfolioProjects[index]?.blobs[0]?.blobUri + sasTokenDocumentStorage ?? ""}
                        alt={"Image"}
                        data-testid="proj-image"
                        className={style.imageContainer}
                      />
                    </div>
                  }
                  <div className={style.projectInfo}>
                    <p className={style.textStyle}>{`Project ${index + 1}`}</p>
                    <p className={style.projectName}>{proj.projectName}</p>
                    <p className={style.description}>
                      {proj.city}{", "}
                      {getProvinceName(proj.province)}
                    </p>
                  </div>
                </div>
                <div className={style.containerBody}>
                  <AccordionContainer
                    expanded={true}
                    content={portfolioProjects[index]}
                  />
                </div>
              </div>
            </React.Fragment>
          );
        })}
    </div>
  );
}
