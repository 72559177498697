import styles from "./styles.module.scss";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useTranslation } from "react-i18next";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal,
} from "@azure/msal-react";
import { Menu, MenuItem } from "@mui/material";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useUser } from "../../Context/UserContext";

export function HeaderTop() {
  const { i18n, t } = useTranslation();
  const { instance } = useMsal();
  const { userState } = useUser();
  const navigate = useNavigate();
  const location = useLocation();
  const persona = userState.userPersona || "";
  let num = "1"
  const handleLanguageSelection = () => {
    i18n.changeLanguage(i18n.language === "fr" ? "en" : "fr");
  };

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const dropDownLinksDeveloper = [
    {
      text: t("common:header.account_menu.profile"),
      path: "/profile/developer",
    },
    {
      text: t("common:header.account_menu.project"),
      path: "/search/projects",
    },
    {
      text: t("common:header.account_menu.connection"),
      path: "/connection",
    },
  ];
  const dropDownLinksInvestor = [
    {
      text: t("common:header.account_menu.profile"),
      path: "/profile/investor",
    },
    {
      text: t("common:header.account_menu.connection"),
      path: "/connection",
    },
  ];

  const dropDownLinks =
    persona.toLowerCase() === "developer"
      ? dropDownLinksDeveloper
      : dropDownLinksInvestor;

  return (
    <div className={styles.headerTopBar}>
      <div className={styles.headerTopContainer}>
        <AuthenticatedTemplate>
          <>
            <button
              id="account-button"
              className={styles.headerTopControls}
              aria-controls={open ? "account-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={handleClick}
              data-testid="account-button"
            >
              {t("common:header.account_menu.my_account")}{" "}
              <KeyboardArrowDownIcon />
            </button>

            <Menu
              id="account-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "account-button",
              }}
              data-testid="account-menu"
            >
              {dropDownLinks.map((link, idx) => (
                <MenuItem
                  key={idx}
                  sx={{
                    "&:hover": { backgroundColor: "#E6EFF3" },
                    backgroundColor: "#FFFFFF",
                    textDecoration:
                      location.pathname === link.path ? "underline" : "none",
                    fontFamily: "Roboto",
                    fontStyle: "normal",
                    fontWeight: "500",
                    fontSize: "0.875rem",
                    lineHeight: "1.5rem",
                    padding: "1rem 2rem",
                  }}
                  onClick={() => {
                    handleClose();
                    localStorage.setItem("lastTabIndex", num);
                    localStorage.setItem("lastPage", num);
                    navigate(link.path);
                    if(link.path === "/search/projects"){
                      navigate("/search/projects",{state:{lastTabIndex:1}})
                      window.location.reload();
                    }else{
                      navigate(link.path);
                    }
                  }}
                 
                >
                  {link.text}
                </MenuItem>
              ))}
              <MenuItem
                onClick={() => {
                  handleClose();
                  const skipCreatePortfolioModal = localStorage.getItem("SkipCreatePortfolioModal");
                  const feedbackMessage = localStorage.getItem("feedbackMessage");

                  localStorage.clear();
                  localStorage.setItem("SkipCreatePortfolioModal", skipCreatePortfolioModal !== null ? skipCreatePortfolioModal : "");
                  localStorage.setItem("feedbackMessage", feedbackMessage !== null ? feedbackMessage : "");

                  sessionStorage.clear();
                  instance.logoutRedirect();
                }}
                sx={{
                  "&:hover": { backgroundColor: "#E6EFF3" },
                  backgroundColor: "#FFFFFF",
                  fontFamily: "Roboto",
                  fontStyle: "normal",
                  fontWeight: "500",
                  fontSize: "0.875rem",
                  lineHeight: "1.5rem",
                  padding: "1rem 2rem",
                }}
              >
                {t("common:header.account_menu.logout")}
              </MenuItem>
            </Menu>
          </>
        </AuthenticatedTemplate>
        <UnauthenticatedTemplate>
          <button
            className={styles.headerTopControls}
            type="button"
            onClick={() =>
              instance.loginRedirect({
                extraQueryParameters: { ui_locales: i18n.language },
                scopes: [],
              })
            }
            data-testid="login-button"
          >
            {t("common:header.login")}
          </button>
        </UnauthenticatedTemplate>

        <button
          type="button"
          className={styles.headerTopControls}
          onClick={handleLanguageSelection}
          data-testid="language_toggle"
        >
          {t("common:header.language_toggle")}
        </button>
      </div>
    </div>
  );
}
