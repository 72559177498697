import { isNotEmpty } from '../../../../Constants/basicFunctions';
import styles from './styles.module.scss';

type props = {
   selectLabel: string;
   selectedValues: string;
   descriptionLabel: string;
   descriptionValue: string;
}

export function ImpactInfoReviewSection(props: props) {
   
   const {
      selectLabel,
      selectedValues,
      descriptionLabel,
      descriptionValue } = props;

   return (
      <div className={styles.impactInfoReviewContainer} data-testid="impact-criteria-container">
         <div className={styles.impactCriteriaDetails}>
            <div className={styles.gridContainer}>
               <p className={styles.highlightLabel} data-testid="select-label" >{selectLabel}</p>
               <p className={styles.highlightValue} data-testid="selected-values">{isNotEmpty(selectedValues) ? selectedValues : ''}</p>
               <p className={styles.highlightLabel} data-testid="descrption-label">{descriptionLabel}</p>
               <p className={styles.highlightValue} data-testid="description">{isNotEmpty(descriptionValue) ? descriptionValue : ''}</p>
            </div>
         </div>
      </div>
   )
}