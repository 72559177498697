import { Modal } from "@mui/material";
import { Dispatch, SetStateAction, useRef } from "react";
import { ErrorOutlined } from "../../../assets/icons";
import styles from "./CloseProject.module.scss";
import CloseIcon from "@mui/icons-material/Close";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { ListingProjectAPI } from "../../../APIAction/ListingProjectAPI";
import { useNavigate } from "react-router-dom";
import { TextArea } from "../../../components/Form/common/TextArea";

type Props = {
  setCloseProjectModal: Dispatch<SetStateAction<boolean>>;
  setProjectCloseSuccess: Dispatch<SetStateAction<boolean>>;
  closeProjectModal: boolean;
  projectId: string;
};

export function CloseProject(props: Props) {
  const setCloseProjectModal = props.setCloseProjectModal;
  const closeProjectModal = props.closeProjectModal;
  const setProjectCloseSuccess = props.setProjectCloseSuccess;
  const projectId = props.projectId;
  const { t } = useTranslation(["project", "common", "onboarding"]);
  const navigate = useNavigate();
  const inputRef = useRef<HTMLTextAreaElement>(null);

  const ProjectCloseReasons = [
    {
      label: t("project:project.closing_modal.label1"),
      value: "1",
    },
    {
      label: t("project:project.closing_modal.label2"),
      value: "2",
    },
    {
      label: t("project:project.closing_modal.label3"),
      value: "3",
    },
    { label: t("project:project.closing_modal.label4"), value: "4" },
  ];

  const handleClose = () => {
    setCloseProjectModal(false);
  };

  const initialValues = {
    closeReasonCode: "1",
    acknowledgeMent: false,
    otherReason: "",
  };

  const closeProjectSchema = Yup.object().shape({
    otherReason: Yup.string().when("closeReasonCode", {
      is: "4",
      then: Yup.string().required(
        t("onboarding:investor.general_form_error.general_required")
      ),
      otherwise: Yup.string(),
    }),
    acknowledgeMent: Yup.boolean()
      .required(t("onboarding:investor.terms_condition.error"))
      .oneOf([true], t("onboarding:investor.terms_condition.error")),
  });

  const submitHandler = async (values: any) => {
    const closeReasonNoteFilter = ProjectCloseReasons.find(
      (x) => x.value === values.closeReasonCode
    );
    let closeReasonNote = closeReasonNoteFilter?.label;
    if (closeReasonNoteFilter?.value === "4") {
      closeReasonNote = values.otherReason;
    }

    const closeProjectObject = {
      projectListingID: projectId,
      closeReasonCode: parseInt(values.closeReasonCode),
      closeReasonNote: closeReasonNote,
      acknowledgementFlag: values.acknowledgeMent,
    };

    try {
      await ListingProjectAPI.postCloseProject(closeProjectObject);
      window.dataLayer.push({
        event: "close_project",
        closing_reason: closeReasonNoteFilter?.label,
      });
      handleClose();
      setProjectCloseSuccess(true);
    } catch (error) {
      navigate("/api-error");
    }
  };

  return (
    <Modal open={closeProjectModal} onClose={handleClose}>
      <div className={styles.closeProjectModalContainer}>
        <div className={styles.modalContentWrapper}>
          <div className={styles.titleContainer} data-testid="title-container">
            <img
              src={ErrorOutlined}
              alt=""
              className={styles.icon}
              data-testid="exclamation-Icon"
            />
            <h2 className={styles.title} data-testid="pageTitle">
              {t("project:project.closing_modal.page_title")}
            </h2>
          </div>
          <button
            className={styles.crossIcon}
            onClick={handleClose}
            data-testid="close-Icon"
          >
            <CloseIcon />
          </button>
          <p className={styles.description} data-testid="close-description">
            {t("project:project.closing_modal.note_text")}
          </p>
          <hr className={styles.horizontalLine} />
          <div className={styles.contentBlock}>
            <h3 className={styles.reasonHeading}>
              {t("project:project.closing_modal.reason_title")}
            </h3>
            <p className={styles.closingQuestion}>
              {t("project:project.closing_modal.query")}
            </p>
            <Formik
              initialValues={initialValues}
              validateOnChange={false}
              validateOnBlur={false}
              enableReinitialize={true}
              validationSchema={closeProjectSchema}
              onSubmit={(values) => submitHandler(values)}
            >
              {({ values, errors, touched }) => (
                <Form>
                  <div className={styles.optionsContainer}>
                    {ProjectCloseReasons.map((data, index) => (
                      <label key={index} className={styles.inputElement}>
                        <Field
                          type="radio"
                          name="closeReasonCode"
                          value={data.value}
                        />
                        <span className={styles.inputText}>{data.label}</span>
                      </label>
                    ))}
                    <ErrorMessage
                      name="closeReasonCode"
                      render={(msg) => (
                        <div className={styles.errorMessage}>{msg}</div>
                      )}
                    />
                  </div>
                  {values.closeReasonCode === "4" && (
                    <div>
                      <TextArea
                        fieldName="otherReason"
                        charLimit={1000}
                        labelText={t(
                          "project:project.closing_modal.reason_summary.title"
                        )}
                        data-testid="otherReason"
                      />
                    </div>
                  )}
                  <p className={styles.acknowledgementTitle}>
                    {t("project:project.closing_modal.terms_title")}
                  </p>
                  <label className={styles.checkboxlabel}>
                    <Field type="checkbox" name="acknowledgeMent" />
                    <div className={styles.inputText}>
                      {t("project:project.closing_modal.terms_summary")}
                    </div>
                  </label>
                  <ErrorMessage
                    name="acknowledgeMent"
                    render={(msg) => (
                      <div className={styles.errorMessage}>{msg}</div>
                    )}
                  />
                  <div className={styles.buttonsContainer}>
                    <button
                      className={styles.cancelButton}
                      onClick={handleClose}
                    >
                      {t("project:project.closing_modal.cancel_btn")}
                    </button>
                    <button className={styles.closeProjectButton} type="submit">
                      {t("project:project.closing_modal.delete_btn")}
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </Modal>
  );
}
