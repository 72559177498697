import { ProjectStatus } from "./ProjectStatus";
import FavoriteIcon from '@mui/icons-material/Favorite';
import { useNavigate } from "react-router-dom";

import {
    getKVArrayFormatForDropdown, getEnumKeyByValue, PROVINCES, FUNDING_TYPES, getKVArrayFormat, AFFORDABILITY_TYPES, ORGANIZATION_TYPE,
} from "../../../Constants/Enumerations";
import { useTranslation } from "react-i18next";
import { numberWithCommas } from "../../../Constants/Utils/FormateNumber";
import { useUser } from "../../Context/UserContext";
import { InvestorAPI } from "../../../APIAction/InvestorAPI";
import { Social, Supply, Focus, Balance } from "../../../assets/icons";
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import React, { useEffect, useRef, useState } from "react";

import { pink } from '@mui/material/colors';
import styles from "./styles.module.scss";

import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Chip from "@mui/material/Chip";
import Tooltip from "@mui/material/Tooltip";

type Props = {
    isPortfolio? :boolean;
    data: any;
    handleChange: Function;
};

export function PortfolioCard({ isPortfolio,data, handleChange }: Props) {
    const { t } = useTranslation(["onboarding", "cards"]);

    const navigate = useNavigate();
    const { userState } = useUser();
    const [check, setCheck] = useState(data.saved);
    const [istoolTipOpen, setIstoolTipOpen] = useState(-1);
    const [tooltipLabel, setTooltipLabel] = useState("");
    const tooltipRef = useRef<HTMLDivElement>(null);

    const [open, setOpen] = useState({ switch: false, message: "success" });
    const provincesTranslated = Object.keys(PROVINCES).map((val) =>
        t(`common:enumerations.provinces.${val}`)
    );
    const provinceOptions = getKVArrayFormatForDropdown(PROVINCES, provincesTranslated);

    const fundingTypesToRender = data.financialDetails?.fundingTypes?.map((val: any) =>
        getEnumKeyByValue(FUNDING_TYPES, val)
    );

    const fundingTypesTranslated = fundingTypesToRender?.filter((val:any) => val !== undefined && val !== "").map((val: any) =>
        t(`common:enumerations.portfolio_funding_types.${val}`)
    );

    const organizationTypeTranslations = Object.keys(ORGANIZATION_TYPE).filter(val => val !== undefined && val !== "").map(
        (val) => t(`common:enumerations.organization_type.${val}`)
    );
    const organizationTypeOptions = getKVArrayFormat(
        ORGANIZATION_TYPE,
        organizationTypeTranslations
    );
    const organizationTypes = data?.organizationTypes;

    const organizationTypeValue =
        organizationTypes &&
        organizationTypes.length > 0 &&
        organizationTypes.map(
            (val: number, idx: number) =>
                organizationTypeOptions.find((obj) => obj.value === val)?.label
        );

    const affordabilityTypesTranslated = Object.keys(AFFORDABILITY_TYPES).map((val) =>
        t(`common:enumerations.affordabilityCriteria_type.${val}`)
    );

    const affordabilityTypes = getKVArrayFormat(
        AFFORDABILITY_TYPES,
        affordabilityTypesTranslated
    );

    const badges = [Social, Focus, Balance, Supply];
    const affordabilitywithBadge = affordabilityTypes.map((type, index) => ({
        ...type,
        src: badges[index % badges.length]
    }));

    const affordabilityInfo = affordabilitywithBadge.filter(option => data?.affordabilityDetails?.projectAffordabilityTypes?.includes(option?.value));

    const environmental = data?.developmentImpact?.environmentalImpact?.length;
    const social = data?.developmentImpact?.socialImpact?.length;
    const governance = data?.developmentImpact?.governanceImpact?.length;

    const handlePortfolioClick = () => {
        // 1-Draft, 2-Active, 3-Cancelled, 4-Deleted
        // data.projectListingStatus === PROJECT_LISTING_STATUS.Draft && navigate(`/projects/edit/${id}`);
        // (data.projectListingStatus === PROJECT_LISTING_STATUS.Active || data.projectListingStatus === PROJECT_LISTING_STATUS.Cancelled) &&
        navigate(`/portfolio/${data.id}`, { state: data.saved ? "true" : "false" });

        // navigate(`/projects/${id}`, { state: data.saved ? "true" : "false" });

    };
    const savePortfolio = async (projectId: string, portfolioName: string) => {
        if (!userState.isOnboarded) {
            setCheck(false);
            setOpen({ switch: true, message: t("cards:project_cards.add_portfolio_error") });
            return;
        }
        try {
            const info = {
                investorID: userState.userId,
                portfolioID: projectId
            }

            await InvestorAPI.postSavePortfolio(info);
            setCheck(!check);
            setOpen({ switch: true, message: t("cards:project_cards.add_portfolio_favourites") })


            window.dataLayer.push({
                event: "save_portfolio",
                portfolio_name: portfolioName,
            });
            data.saved = true;


        } catch (error) {
            setCheck(false);
            setOpen({ switch: true, message: t("cards:project_cards.add_portfolio_error") });
        }

    };
    const removePortfolio = async (projectId: string) => {
        try {

            const info = {
                investorID: userState.userId,
                portfolioID: projectId
            }

            await InvestorAPI.postRemoveSavedPortfolio(info);
            setCheck(!check);
            setOpen({ switch: true, message: t("cards:project_cards.removed_portfolio_from_favourites") });

            data.saved = false;

        } catch (error) {
            setCheck(false);
            setOpen({ switch: true, message: t("cards:project_cards.remove_portfolio_error") });

        }

    };

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (tooltipRef.current && !tooltipRef.current.contains(event.target as Node)) {
                setIstoolTipOpen(-1); // Close tooltip when clicking outside
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    //@ts-ignore
    const selectedProvinces = [...new Map(data.portfolioProjectDetail.portfolioProjects.map(v => [v.province, v])).values()];

    const displayProjectDetailsFlag = data?.portfolioProjectDetail?.displayProjectDetailsFlag;
    //@ts-ignore
    const developmentRegions = [...new Map(data.portfolioProjectDetail.developmentRegions.map(v => [v.province, v])).values()];


    const action = (
        <React.Fragment>
            {check &&
                <p onClick={
                    () => {
                        navigate("/search/projects");
                        window.location.reload();
                        localStorage.setItem("lastTabIndex", "3");
                        localStorage.setItem("lastPage", "1");
                    }}
                    className={styles.linkFavorite}>
                    {t("cards:project_cards.save_portfolio_link")}
                </p>
            }
            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={() => setOpen({ ...open, switch: false })}
            >
                <CloseIcon fontSize="small" />
            </IconButton>
        </React.Fragment>
    );


    return (
        <div className={styles.portfolioCardContainer}>
            <Snackbar
                className={styles.ccSnackbar}
                ContentProps={{
                    sx: {
                        background: check === false ? "red" : undefined
                    }
                }}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                open={open.switch}
                onClose={() => setOpen({ ...open, switch: false })}
                message={open.message}
                key={"top" + "center"}
                action={action}

            />
            <div className={styles.StatusConatiner}>
                <div className={styles.portfolioStatus}>
                    <ProjectStatus status={data.portfolioStatus} isPortfolio={isPortfolio} />

                </div>
                <div className={styles.affordabilityBadges} ref={tooltipRef}>
                    {affordabilityInfo?.map((opt, index) =>
                        opt?.src && (
                            <Tooltip arrow open={index === istoolTipOpen} title={tooltipLabel}
                                componentsProps={{
                                    tooltip: {
                                        sx: {
                                            bgcolor: "#E0E4E7",
                                            color: "#454E56",
                                            '& .MuiTooltip-arrow': {
                                                color: '#E0E4E7',
                                            },
                                        }
                                    }
                                }}
                                placement="top">
                                <button type="button" className={styles.badgeButton} onClick={() => { setIstoolTipOpen(index); setTooltipLabel(opt?.label) }}>
                                    <img src={opt?.src} className={styles.badgeIcon} alt={opt?.label} />
                                </button>
                            </Tooltip>
                        )
                    )}
                </div>
                <div
                    className={styles.portfolioFavorite}
                    data-testid="project-status">
                    {userState.userPersona === "Investor" && data.saved && check ?
                        <FavoriteIcon sx={{ color: pink[500] }} onClick={() => removePortfolio(data.id)} /> : userState.userPersona === "Investor" &&

                        <FavoriteBorderIcon
                            className={styles.favoriteIcon}
                            onClick={() => savePortfolio(data.id, data.generalInformation.portfolioName)} />
                    }                </div>
            </div>
            <div className={styles.portfolioDetails}>
                <button
                    type="button"
                    className={styles.portfolioCardLink}
                    data-testid="proj-name-link"
                    onClick={handlePortfolioClick}
                >
                    {data.generalInformation.portfolioName ? data.generalInformation.portfolioName : t("cards:project_cards.untitle_portfolio")}
                </button>
                {/* 
                <p>
              {fundingTypesTranslated &&
                fundingTypesTranslated.length > 0 && fundingTypesTranslated.map((val, idx) => (
                  <span key={idx}>
                    {
                      val
                    }
                    {idx < fundingTypesTranslated.length - 1
                      ? ", "
                      : " "}
                  </span>
                ))}
            </p> */}
                {/* <p>
              {FinancialDetailInfo.capitalSources &&
                FinancialDetailInfo.capitalSources.length > 0 && FinancialDetailInfo.capitalSources.map((val, idx) => (
                  <span key={idx}>
                    {
                      capitalSourcesTypes.find((obj) => obj.value === val)
                        ?.label
                    }
                    {idx < FinancialDetailInfo.capitalSources.length - 1
                      ? ", "
                      : " "}
                  </span>
                ))}
            </p> */}


                <p className={`${styles.iterableValue} ${styles.labelMargin}`} data-testid="org-name">

                    {`${data.portfolioProjectDetail.totalProjectCount} ${t("cards:project_cards.projects")} 
    ${data.portfolioProjectDetail.totalUnitPortfolioProject !== null ? `(${data.portfolioProjectDetail.totalUnitPortfolioProject} ${t("cards:project_cards.units")}) - ` : ''}
    ${data.portfolioProjectDetail.belowAverageMarketRentUnitCount !== null ? `${data.portfolioProjectDetail.belowAverageMarketRentUnitCount}% ${t("cards:project_cards.belowAvgRent")})` : ''}
    ${t("cards:project_cards.within")}`}&nbsp;
                    {displayProjectDetailsFlag === true ? selectedProvinces.map((obj: any, index: any) =>
                        <>
                            {/* {obj.city} */}
                            {
                                //@ts-ignore
                                provinceOptions.find((province) => province.value === obj.province)
                                    ?.label
                            }
                            {
                                //@ts-ignore
                                index < selectedProvinces.length - 1
                                    ? ", "
                                    : " "
                            }
                        </>
                    ) : developmentRegions.map((obj: any, index: any) =>
                        <>
                            {/* {obj.city} */}
                            {
                                //@ts-ignore
                                provinceOptions.find((province) => province.value === obj.province)
                                    ?.label
                            }
                            {
                                //@ts-ignore
                                index < selectedProvinces.length - 1
                                    ? ", "
                                    : " "
                            }
                        </>
                    )}
                </p>
                <p
                    className={`${styles.iterableLabel} ${styles.labelMargin}`}
                    data-testid="address"
                >{t("cards:portfolio_cards.createdBy")} &nbsp;{data.organizationName} | {organizationTypeValue &&
                    organizationTypeValue?.map(
                        (val: number, idx: number) =>
                            `${val}${idx < organizationTypeValue.length - 1 ? ", " : ""
                            }`
                    )}</p>
            </div>
            <hr className={styles.horizontalLine} />

            <div className={styles.portfolioAmoutDetails}>
                <div>
                    <p className={styles.iterableLabel}>{t("cards:portfolio_cards.capital_needed")}</p>
                    <p className={styles.iterableValue}>
                        {"$" + numberWithCommas(data.financialDetails?.totalFundingNeeded)}
                    </p>
                </div>
                <div>
                    <p className={styles.iterableLabel}>{t("cards:portfolio_cards.total_portfolio_cost")}</p>
                    <p className={styles.iterableValue}>
                        {"$" + numberWithCommas(data.financialDetails?.totalPortfolioCost)}
                    </p>
                </div>
                <div>
                    <p className={`${styles.iterableLabel} ${styles.labelMargin}`}>
                        {t("cards:funding_type_label")}
                    </p>
                    <p className={`${styles.iterableValue}`}>
                        <p>
                            {fundingTypesTranslated &&
                                fundingTypesTranslated.length > 0 && fundingTypesTranslated.map((val: any, idx: any) => (
                                    <span key={idx}>
                                        {
                                            val
                                        }
                                        {idx < fundingTypesTranslated.length - 1
                                            ? ", "
                                            : " "}
                                    </span>
                                ))}
                        </p>
                    </p>
                </div>

                <div className={styles.labelMargin}>
                    <p className={styles.iterableLabel}>{t("cards:portfolio_cards.minimum_investment")}</p>
                    <p className={styles.iterableValue}>
                        {"$" + numberWithCommas(data.financialDetails?.minimumInvestmentAmount)}
                    </p>
                </div>

            </div>
            <div className={styles.labelMargin}>
                <p className={`${styles.iterableLabel} ${styles.labelMargin}`}>{t("cards:impact_criteria")}</p>
                <p className={styles.iterableValue}>
                    {environmental > 0 && <Chip label={`${environmental} ${t("cards:environmental")}`} style={{ backgroundColor: "#E6EFF3", marginRight: "0.5rem" }} />}
                    {social > 0 && <Chip label={`${social} ${t("cards:social")}`} style={{ backgroundColor: "#E6EFF3", marginRight: "0.5rem" }} />}
                    {governance > 0 && <Chip label={`${governance} ${t("cards:governance")}`} style={{ backgroundColor: "#E6EFF3" }} />}
                </p>
            </div>
        </div>

    );
}
