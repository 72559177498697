import { useTranslation } from "react-i18next";
import React, { useState, useEffect } from "react";
import styles from "./styles.module.scss";
import { GeneralInfoIcon } from "../../../assets/icons";
import { useNavigate } from "react-router-dom";
import { getEnumKeyByValue, PROVINCES, COMMITMENT_TO_AFFORDABILITY, getKVArrayFormatForDropdown } from "../../../Constants/Enumerations";

export const ReviewProjectsWithinPortfolioInfo = ({
  portfolioListingID,
  data,
  dataExist
}: any) => {
  const { t } = useTranslation(["profile", "onboarding", "project"]);
  const sasTokenDocumentStorage = process.env.REACT_APP_DOCUMENT_SAS_TOKEN;

  const navigate = useNavigate();

  const [info, setInfo] = useState<any>(data);
  const [isDataExist, setIsDataExist] = useState(dataExist);

  useEffect(() => {
    setInfo(data);
    setIsDataExist(dataExist);
  }, [data]);

  const constructionTypeOption = [
    {
      label: t("developer.projects.financial_details_doc_form.project_type_1"),
      value: "1",
    },
    {
      label: t("developer.projects.financial_details_doc_form.project_type_2"),
      value: "2",
    },
  ];

  const projectPhaseOption = [
    {
      label: t(`common:enumerations.investment_phases.Acquisition`),
      value: 1,
    },
    {
      label: t(`common:enumerations.investment_phases.PreDevelopment`),
      value: 2,
    },
    {
      label: t(`common:enumerations.investment_phases.Construction`),
      value: 3,
    },
    {
      label: t(`common:enumerations.investment_phases.PostConstruction`),
      value: 4,
    },
    {
      label: t(`common:enumerations.investment_phases.Seed`),
      value: 5,
    },
  ];
  const landStatusOption = [
    {
      label: t(`common:enumerations.land_status.LandSecured`),
      value: 1,
    },
    {
      label: t(`common:enumerations.land_status.LandNeeded`),
      value: 2,
    },
    {
      label: t(`common:enumerations.land_status.LandAcquisitioInProcess`),
      value: 3,
    },
    {
      label: t(`common:enumerations.land_status.PreferNotToDdisclose`),
      value: 9,
    },
  ];

  const commitmentAffordabilityTrans = Object.keys(COMMITMENT_TO_AFFORDABILITY).map((val) =>
    t(`common:enumerations.commitment_to_affordability.${val}`)
  );
  const commitmentAffordabilityOptions: {
    label: string;
    value: string | number;
  }[] = getKVArrayFormatForDropdown(COMMITMENT_TO_AFFORDABILITY, commitmentAffordabilityTrans);

  const getProvinceName = (provinceNumber: number) => {
    const provinceMappedValue = getEnumKeyByValue(PROVINCES, provinceNumber);
    const provinceTranslated = t(
      `common:enumerations.provinces.${provinceMappedValue}`
    );
    return provinceTranslated;
  };

  const getStartAndCompletionDateRange = (project: any) => 
    `${project.targetStartDate} - ${project.targetCompletionDate}`;

  function renderYesOrNoOptions(truthyValue: boolean) {
    if (truthyValue) {
      return t(
        "profile:developer.projects.financial_details_doc_form.yes_label"
      );
    }
    return t("profile:developer.projects.financial_details_doc_form.no_label");
  }
  const handleEditClick = () => {
    navigate(`/portfolio/edit/${portfolioListingID}`);
  };

  return (
    <div className={styles.informationContainer}>
      <div className={styles.infoHeadingContainer}>
        <div className={styles.left}>
          <div className={styles.imgContainer}>
            <img src={GeneralInfoIcon} alt="" />
          </div>
          <h3 className={styles.sectionHeading}>{t("developer.projects.project_within_portfolio.heading")}</h3>
        </div>
        <button className={styles.link} onClick={handleEditClick}>
          {t("developer.projects.review_project.edit_link")}
        </button>
      </div>

      {isDataExist && (
        <div className={styles.content}>
          <div className={styles.grid_container}>
            <p>{t("developer.projects.project_within_portfolio.number_of_portfolio_review")}</p>
            <p>{info.totalProjectCount}</p>
            <p>{t("developer.projects.project_within_portfolio.portfolio_total_number_of_units_review")}</p>
            <p>{info.totalUnitPortfolioProject}</p>
            <p>{t("developer.projects.project_within_portfolio.portfolio_percentage_AMR_review")}</p>
            <p>{info.belowAverageMarketRentUnitCount}%</p>
            {!info.displayProjectDetailsFlag && (
              <React.Fragment>
                {info.developmentRegions.length > 0 &&
                  info.developmentRegions.map((region: any, index: number) => {
                    return (
                      <>
                        <p>{t("developer.projects.project_within_portfolio.reginal_focus")}</p>
                        <p>{`${getProvinceName(region.province)}, ${region.cityOrRegion}`}</p>
                      </>
                    );
                  })}
              </React.Fragment>
            )}
            {info.displayProjectDetailsFlag &&
              info.portfolioProjects.map((project: any, idx: any) => (
                <React.Fragment>
                  <p>
                    <h4>{t("developer.projects.project_within_portfolio.project")} {idx + 1}</h4>
                  </p>
                  <p></p>
                  <p>{t("developer.projects.project_within_portfolio.project_name_field")}</p>
                  <p>{project.projectName}</p>
                  <p>{t("developer.projects.project_within_portfolio.location")}</p>
                  <p>
                    {project.city}, {getProvinceName(project.province)}
                  </p>
                  <p>{t("developer.projects.project_within_portfolio.number_of_units_developed_review")}</p>
                  <p>{project.unitCount}</p>
                  <p>
                    {t("developer.projects.project_within_portfolio.avg_below_market_rent_review")}
                  </p>
                  <p>{project.unitPercentBelowMarketRent}</p>
                  <p>
                    {t("developer.projects.add_listing_form.commitment_to_affordability")}
                  </p>
                  <p>{commitmentAffordabilityOptions.find(
                    (item) => +item.value === project.affordabilityCommitment ? project.affordabilityCommitment : ""
                  )?.label}</p>
                  {project.targetStartDate &&
                    <React.Fragment>
                      <p>{t("developer.projects.add_listing_form.start_and_completion_date_review")}</p>
                      <p>{getStartAndCompletionDateRange(project)} </p>
                    </React.Fragment>
                  }
                  <p>{t("developer.projects.general_info_form.project_desc_label")}</p>
                  <p>{project.projectDescription}</p>
                  {project?.blobs && project?.blobs.length !== 0 &&
                    <React.Fragment>
                      <p>
                        {t("developer.projects.file_upload.images")}
                      </p>
                      <p>
                        {project?.blobs?.map((item: any, idx: number) => (
                          <div className={styles.uploadCard} key={idx}>
                            <div className={styles.imageContent}>
                              <div>
                                <img
                                  className={styles.image}
                                  src={item.blobUri + sasTokenDocumentStorage}
                                  alt={item.fileName}
                                />
                              </div>
                              <div className={styles.imagetitle}>
                                <p>{item.fileName} </p>
                                <span className={styles.imageSize}> {item.fileName.split('.').pop()} | {item.blobSize} MB</span>
                              </div>
                              <div className={styles.primaryTag}>
                                {item.primaryBlobFlag ? "Primary" : ""}
                              </div>
                            </div>
                          </div>
                        ))}
                      </p>
                    </React.Fragment>
                  }

                  <p>{t("developer.projects.project_within_portfolio.typeOf_project_field_review")}</p>
                  <p>
                    {
                      constructionTypeOption.find(
                        (item) => +item.value === project.constructionType
                      )?.label
                    }
                  </p>
                  <p>
                    {t("developer.projects.project_within_portfolio.project_phase_review")}
                  </p>
                  <p>
                    {
                      projectPhaseOption.find(
                        (element: any) => element.value === project.projectPhase
                      )?.label
                    }
                  </p>

                  <p>
                    {t("developer.projects.project_within_portfolio.is_land_acquired")}
                  </p>
                  <p>
                    {landStatusOption &&
                      landStatusOption.find(
                        (element: any) => element.value === project.landStatus
                      )?.label
                    }
                  </p>
                  <p>{t("developer.projects.project_within_portfolio.impact_description_review")}</p>
                  <p>{project.developmentImpactDescription}</p>
                </React.Fragment>
              ))}
          </div>
        </div>
      )
      }
    </div >
  );
};
