import { Link, NavLink, useLocation,useNavigate } from "react-router-dom";
import { HeaderLogo } from "./HeaderLogo";
import styles from "./styles.module.scss";
import { useTranslation } from "react-i18next";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import { useUser } from "../../../components/Context/UserContext";
import { ConnectApi } from "../../../APIAction/ConnectAPI";
import { AwaitingFeedbackToast } from "../../../components/common/ConnectionStatusTabs/AwaitingFeedbackToast";

import { useEffect, useState } from "react";


export function HeaderNav() {
  const { t } = useTranslation("common");
  const { userState } = useUser();
  const navigate = useNavigate();
  const [connectionStatus, setConnectionStatus] = useState(false);

  const unauthenticatedLinks = [
    {
      text: t("header_links.about_us"),
      path: "/about",
    },
    {
      text: t("header_links.resources_unauthenicated"),
      path: "/resources",
    },
  ];



  useEffect(() => {
    const checkPendingConnectionStatus = async () => {
      const postConnectionData = {
        entityID: userState.userId,
        entityType: userState.userPersona,
      };
      try {
        const res = await ConnectApi.checkFeedBack(
          postConnectionData
        );
        setConnectionStatus(res?.data?.isGreaterThan14Days);
      } catch (err) {
        return;
      }
    };

    if (userState.userId && userState.userId !== "" && localStorage.getItem("feedbackMessage") !== "true") {
      checkPendingConnectionStatus();
    }

  }, []);
  const authenticatedLinks = [
    { text: t("header_links.connections"), path: "/connection" },
    { text: t("header_links.project_finder"), path: "/search/projects" },
    { text: t("header_links.investors"), path: "/search/investors" },
    { text: t("header_links.resources"), path: "/resources" },

  ];

  const location = useLocation();

  const handleGTM = () => {
    window.dataLayer.push({
      event: "request_access",
    });
  };

  return (
    <header className={styles.header}>
      <div className={styles.headerContainer}>
        <HeaderLogo />
        <div className={styles.headerNavContainer} data-testid="nav-links">
          <AuthenticatedTemplate>
            <ul className={styles.navMenuList}>
              {authenticatedLinks.map((link, idx) => (
                <li key={idx}>
                  <Link
                    className={
                      location.pathname === link.path
                        ? styles.activeListItem
                        : styles.navMenuLink
                    }
                    to={link.path}
                    onClick={() => {
                      if (link.path === "/search/projects") {
                        localStorage.setItem("lastTabIndex", "0");
                        localStorage.setItem("lastPage", "1");
                      };
                      if(link.path === "/resources") {
                        localStorage.setItem("glossarySelectedLinkId", "#1");
                      };
                      navigate(link.path);
                    }}
                  >
                    {link.text}
                  </Link>
                </li>
              ))}
            </ul>
            {connectionStatus && (
              <AwaitingFeedbackToast />
            )}
          </AuthenticatedTemplate>
          <UnauthenticatedTemplate>
            <ul className={styles.navMenuList}>
              {unauthenticatedLinks.map((link, idx) => (
                <li key={idx}>
                  <Link
                    className={
                      location.pathname === link.path
                        ? styles.activeListItem
                        : styles.navMenuLink
                    }
                    onClick={() => {
                      if (link.path === "/search/projects") {
                        localStorage.setItem("lastTabIndex", "0")
                        localStorage.setItem("lastPage", "1");
                      };
                      if(link.path === "/resources") {
                        localStorage.setItem("glossarySelectedLinkId", "#1");
                      }
                    }}
                    to={link.path}
                  >
                    {link.text}
                  </Link>
                </li>
              ))}
              <li>
                <NavLink
                  className={styles.buttonStyle}
                  to="/request-access"
                  onClick={handleGTM}
                >
                  {t("header_links.request_access")}
                </NavLink>
              </li>
            </ul>
          </UnauthenticatedTemplate>
        </div>
      </div>
    </header>
  );
}
