import { createContext, useContext, useEffect, useReducer } from "react";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import {
  UserDispatch,
  UserState,
} from "../../TypesAndInterfaces/UserContextInterface";
import { userReducer } from "./UserReducer";
import { resetData, setAzureData, setUserApiData } from "./UserActions";

type UserContextProps = { children: React.ReactNode };

const UserContext = createContext<
  { userState: UserState; dispatch: UserDispatch } | undefined
>(undefined);

const initialState: UserState =
  sessionStorage.getItem("userState") == null
    ? {
      userPersona: "",
      userId: "",
      userOrganization: "",
      isOnboarded: false,
      onboardingStatus: null,
      userOrganizationTypes: [],
    }
    : JSON.parse(sessionStorage.getItem("userState") || "");

function UserProvider({ children }: UserContextProps) {
  const [userState, dispatch] = useReducer(userReducer, initialState);

  const isAuthenticated = useIsAuthenticated();
  const { accounts } = useMsal();

  useEffect(() => {
    if (!isAuthenticated) {
      resetData(dispatch);
      return;
    }

    if (
      userState.userPersona === "" &&
      accounts[0].username &&
      accounts[0].idTokenClaims
    ) {
      setAzureData(
        dispatch,
        accounts[0].idTokenClaims.extension_Persona as string,
        accounts[0].idTokenClaims.extension_OnboardingStatus as number
      );
    }

    if (userState.userId === "" && userState.userPersona !== "") {
      setUserApiData(dispatch, accounts[0].username, userState.userPersona);
    }
  }, [
    isAuthenticated,
    accounts,
    userState.userPersona,
    userState.userId,
    userState.isOnboarded,
  ]);

  useEffect(() => {
    if (window.sessionStorage) {
      sessionStorage.setItem("userState", JSON.stringify(userState));
    }
  }, [userState]);

  const value = { userState, dispatch };

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
}

function useUser() {
  const context = useContext(UserContext);
  if (context === undefined) {
    throw new Error("The useUser hook must be used within a UserProvider");
  }
  return context;
}

export { UserProvider, useUser };
