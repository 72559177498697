import { Skeleton } from "@mui/material";
import styles from "./styles.module.scss";

export function InvestorCardSkeleton() {
  return (
    <div className={styles.SkeletonCardContainer}>
      <div className={styles.SkeletonCardLeft}>
        <Skeleton variant="circular" width={50} height={50} />
        <Skeleton variant="text" width={90} sx={{ fontSize: "1.5rem" }} />
        <Skeleton width={120} sx={{ fontSize: "1.5rem" }} />
      </div>
      <div className={styles.SkeletonCardDetails}>
        <Skeleton sx={{ fontSize: "1.5rem" }} width={400} />
        <Skeleton sx={{ fontSize: "1.5rem" }} width={400} />
        <Skeleton sx={{ fontSize: "1.5rem" }} width={400} />
      </div>
    </div>
  );
}
