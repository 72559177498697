import { useTranslation } from "react-i18next";
import styles from "./ReviewInvestor.module.scss";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import {
    InvestorOrganizationImpact
} from "../../../TypesAndInterfaces/InvestorDetailsInterface";
import { InvestorAPI } from "../../../APIAction/InvestorAPI";
import axios from "axios";
import { useUser } from "../../../components/Context/UserContext";
import { ImpactInfoReviewSection } from "../../../components/common/ImpactSection/ImpactInfoReviewSection/ImpactReviewSection";
import { ImpactData } from "../../../components/common/Chips/types";
import { isEmpty } from "../../../Constants/basicFunctions";
import { EditLinkHeader } from "../../../components/common/ImpactSection/EditLinkHeader/EditLinkHeader";
import {
    SOCIAL_INDICATORS,
    GOVERNANCE_INDICATORS,
    ENVIRONMENTAL_INDICATORS,
    getTagFormat,
} from "../../../Constants/Enumerations";

export const ReviewInvestorOrgImpactInfo = ({
    setEsgSelection
}: any) => {
    const { t } = useTranslation(["onboarding", "profile"]);
    const { userState } = useUser();
    const navigate = useNavigate();
    const [investOrgImpactInfo, setInvestOrgImpactInfo] =
        useState<InvestorOrganizationImpact>();
    const [isDataExist, setIsDataExist] = useState(false);

    const environmentalIndicatorsTranslations = Object.keys(
        ENVIRONMENTAL_INDICATORS
    ).map((val) =>
        t(
            `profile:developer.projects.impact_criteria_form.environmental.environmental_indicators.${val}`
        )
    );

    const socialIndicatorsTranslations = Object.keys(SOCIAL_INDICATORS).map(
        (val) =>
            t(
                `profile:developer.projects.impact_criteria_form.social.social_indicators.${val}`
            )
    );

    const governanceIndicatorsTranslations = Object.keys(
        GOVERNANCE_INDICATORS
    ).map((val) =>
        t(
            `profile:developer.projects.impact_criteria_form.governance.governance_indicators.${val}`
        )
    );

    const predefinedTags = environmentalIndicatorsTranslations.concat(socialIndicatorsTranslations, governanceIndicatorsTranslations);

    const getImpactNames = (impacts: ImpactData[]) => {
        if (isEmpty(impacts)) {
            return "";
        }

        const impactNames = impacts.map(impact => impact.impactName);
        return impactNames.join(",");
    }

    useEffect(() => {
        const getInvestorOrganizationImpactInfo = async () => {
            try {
                const res = await InvestorAPI.getOrganizationImpactInfoById(userState.userId);
                const data = res.data;
                setIsDataExist(true);
                setInvestOrgImpactInfo(data);


                const selectedTags = data.environmental.concat(data.social, data.governance);

                let count = 0;

                if (selectedTags.length !== 0) {


                    for (let i = 0; i < selectedTags.length; i++) {
                        for (let j = 0; j < predefinedTags.length; j++) {
                            if (predefinedTags[j] === selectedTags[i].impactName) {
                                count++;
                                break;
                            }
                        }
                    }


                    if (count === 0) {
                        setEsgSelection("user defined");
                    } else if (count === selectedTags.length) {
                        setEsgSelection("predefined");
                    } else if (count !== selectedTags.length) {
                        setEsgSelection("both");
                    }
                }

            } catch (error) {
                setIsDataExist(false);
                if (axios.isAxiosError(error) && error.response?.status !== 404) {
                    navigate("/api-error");
                }
            }
        };

        userState.userId !== "" && getInvestorOrganizationImpactInfo();
    }, [userState.userId, navigate]);

    const handleEditClick = () => {
        navigate("/onboarding/investor/edit");
    };

    return (
        <div className={styles.informationContainer}>
            <EditLinkHeader
                sectionHeading={t(
                    "onboarding:investor.main_page.form_accordions.investor_impact_criteria.heading"
                )}
                editLinkText={t("onboarding:investor.review_profile.edit_link")}
                handleEditLink={handleEditClick}
            />
            {isDataExist && investOrgImpactInfo &&
                <div className={styles.impactInfoSections} data-testid="impact-info-sections">
                    <ImpactInfoReviewSection
                        selectLabel={t("onboarding:investor.investor_impact_criteria_form.environmental.environmental_indicator_label_review")}
                        selectedValues={getImpactNames(investOrgImpactInfo.environmental)}
                        descriptionLabel={t("onboarding:investor.investor_impact_criteria_form.environmental.impact_description_label_review")}
                        descriptionValue={investOrgImpactInfo?.environmentalDescription}
                    />
                    <hr className={styles.impactInfohorizontalLine} data-testid="horizaontal-line"></hr>
                    <ImpactInfoReviewSection
                        selectLabel={t("onboarding:investor.investor_impact_criteria_form.social.social_indicator_label_review")}
                        selectedValues={getImpactNames(investOrgImpactInfo.social)}
                        descriptionLabel={t("investor.investor_impact_criteria_form.social.impact_description_label_review")}
                        descriptionValue={investOrgImpactInfo?.socialDescription}
                    />
                    <hr className={styles.impactInfohorizontalLine}></hr>
                    <ImpactInfoReviewSection
                        selectLabel={t("onboarding:investor.investor_impact_criteria_form.governance.governance_indicator_label_review")}
                        selectedValues={getImpactNames(investOrgImpactInfo.governance)}
                        descriptionLabel={t("onboarding:investor.investor_impact_criteria_form.governance.impact_description_label_review")}
                        descriptionValue={investOrgImpactInfo?.governanceDescription}
                    />
                </div>
            }
        </div>
    );
};