import { Field, useField } from "formik";
import styles from "./styles.module.scss";

type Props = {
  fieldName: string;
  options: { label: string; value: string | number, src?: string ,color?: string }[];
  [x: string]: unknown;
  onCheckBoxClick?: () => void;
};

export function CheckBoxInput({ fieldName, options, onCheckBoxClick, ...rest }: Props) {
  const [field, meta] = useField(fieldName);
  // const handleCheckBoxClick = () => {
  //   alert("work");
  //   onCheckBoxClick && onCheckBoxClick();
  // }

  return (
    <div className={styles.fieldcheckboxContainer}>
      {options.map((opt, idx) => (
        <label
          key={idx}
          className={styles.checkboxLabel}
        >
          <Field
            type="checkbox"
            className={`${meta.error && meta.touched ? styles.errorStateInput : null
              }`}
            id={field.name+idx}
            {...field}
            {...rest}
            value={String(opt.value)}
            optionSelected={onCheckBoxClick}
          />
          {opt?.src && 
         <img src={opt?.src} className={styles.badgeIcon}  style={{ backgroundColor: `${opt?.color}40`}} alt="" />
          }
          {opt.label}
        </label>
      ))}
    </div>
  );
}
