import { Avatar } from "@mui/material";
import { useTranslation } from "react-i18next";
import {
  getEnumKeyByValue,
  getKVArrayFormat,
  PROVINCES,
  ORGANIZATION_TYPE,
  PROJECT_SIZE
} from "../../../Constants/Enumerations";
import { IterableValuesDisplay } from "./IterableValuesDisplay";
import { useNavigate } from "react-router-dom";

import styles from "./styles.module.scss";

type Props = {
  data: any;
};

export function DeveloperCard({ data }: Props) {
  const { t } = useTranslation(["common", "cards"]);
  const navigate = useNavigate();


  const provinceMappedValue = getEnumKeyByValue(
    PROVINCES,
    data.generalInformation.province
  );

  const provincesTranslated = Object.keys(PROVINCES).map((val) =>
    t(`common:enumerations.provinces.${val}`)
  );
  const provinceOptions = getKVArrayFormat(PROVINCES, provincesTranslated);


  const regionalFocusValue =
    data.organizationInformation.regionalFocus &&
    data.organizationInformation.regionalFocus.length > 0 &&
    data.organizationInformation.regionalFocus.map(
      (val: any, idx: any) =>provinceOptions.find((obj) => obj.value === val.province)?.label
     
    ).filter((val: any,id: any,array: string | any[]) => array.indexOf(val) === id).sort();

  const OrganizationTypeTranslations = Object.keys(ORGANIZATION_TYPE).filter(val => val !== undefined && val !== "").map(
    (val) => t(`common:enumerations.organization_type.${val}`)
  );
  
  const OrganizationTypeOptions = getKVArrayFormat(
    ORGANIZATION_TYPE,
    OrganizationTypeTranslations
  );

  const organizationTypeValue =
    data.organizationInformation.organizationType &&
    data.organizationInformation.organizationType.length > 0 &&
    data.organizationInformation.organizationType.map(
      (val: any, idx: any) =>
        OrganizationTypeOptions.find((obj) => obj.value === val)?.label
    );


  const averageProjectSizeTranslation = Object.keys(PROJECT_SIZE).map((val) =>
    t(`common:enumerations.project_size.${val}`)
  );
  const averageProjectSizeRange = getKVArrayFormat(
    PROJECT_SIZE,
    averageProjectSizeTranslation
  );

  const averageProjectSizeValue =
    data.organizationInformation.averageProjectSize &&
    averageProjectSizeRange.find(
      (obj) => obj.value === data.organizationInformation.averageProjectSize
    )?.label;

  const listedOpportunitiesValue = data.projectListings.filter((project: any) => project.projectListingStatus === 2).length + data.portfolios.filter((portfolio: any) => portfolio.portfolioStatus === 2).length;

  return (
    <div className={styles.cardContainer}>
      <div className={styles.investorSummary}>
        <Avatar className={styles.avatarStyles} data-testid="inv-avatar">
          {data.generalInformation.organizationName[0]}
        </Avatar>
        <h2 className={styles.OrganizationtitleHyperLink}
          onClick={(e) => {
            e.preventDefault();
            navigate("/profile/developer/developerInfo", { state: data.id });
          }}
        >     {data.generalInformation.organizationName}</h2>
        <p className={styles.regionFocus}>{t(`cards:developer_profile_cards.locatedIn`)}</p>
        <div
          className={styles.iterablesRegionsContainer}
          data-testid="regions-pref"
        >
          {t(`common:enumerations.provinces.${provinceMappedValue}`)}, {data.generalInformation.city}
        </div>
      </div>

      <div className={styles.preferenceContainer} data-testid="pref-container">

        <div className={styles.developerItems}>
          <IterableValuesDisplay
            dataLabel={t(`cards:developer_profile_cards.organizationType`)}
            arrayOfValues={organizationTypeValue ? organizationTypeValue : []}
          />

          <IterableValuesDisplay
            dataLabel={t(`cards:developer_profile_cards.reginonalFocus`)}
            arrayOfValues={regionalFocusValue ? regionalFocusValue : []}
          />
        </div>
        <div className={styles.developerItems}>
          <IterableValuesDisplay
            dataLabel={t(`cards:developer_profile_cards.listedOpportunity`)}
            arrayOfValues={[listedOpportunitiesValue]}
          />
          <IterableValuesDisplay
            dataLabel={t(`cards:developer_profile_cards.projectSize`)}
            arrayOfValues={[averageProjectSizeValue ? averageProjectSizeValue : ""]}
          />
        </div>

      </div>
    </div >
  );
}
