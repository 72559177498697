import React from "react";
import { PageContent } from "../../../components/Layout/PageContent";
import { PageLayout } from "../../../components/Layout/PageLayout";
import { ReviewDeveloperInfo } from "./ReviewDeveloperInfo";
import { ReviewGeneralInfo } from "./ReviewGeneralInfo";
import styles from "./ReviewInfo.module.scss";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ReviewOrganizationInfo } from "./ReviewOrganizationInfo";

export const ReviewDeveloperProfile = () => {
  const { t } = useTranslation("onboarding");
  const navigate = useNavigate();
  const handleSubmit = () => {
    window.dataLayer.push({ "event": "developer_sign_up" });
    navigate("/onboarding/developer/success");
  };
  return (
    <PageLayout>
      <PageContent>
        <div className={styles.container}>
          <div className={styles.headerContainer}>
            <h2 className={styles.mainHeading} data-testid="reviewpage-heading">
              {t("developer.review_profile.main_heading")}{" "}
            </h2>
            <p className={styles.subHeading} data-testid="reviewpage-info">
              {t("developer.review_profile.info")}
            </p>
          </div>
          <div className={styles.contentSection} data-testid="GeneralInfoData">
            <ReviewGeneralInfo />
          </div>
          <div className={styles.contentSection} data-testid="OrganizationData">
            <ReviewOrganizationInfo />
          </div>
          <div className={styles.contentSection} data-testid="DeveloperData">
            <ReviewDeveloperInfo />
          </div>
          <div className={styles.buttonContainer}>
            <button
              type="submit"
              className={styles.formButton}
              onClick={handleSubmit}
              data-testid="submitButton"
            >
              {t("investor.review_profile.confirm_button")}
            </button>
          </div>
        </div>
      </PageContent>
    </PageLayout>
  );
};
