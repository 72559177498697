import styles from "./styles.module.scss";
import { Form, Formik } from "formik";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { TextArea } from "../common/TextArea";
import { useEffect, useMemo, useState } from "react";
import MultiSelectSearch from "../../common/MultiSelectSearch";
import { Tag } from "../../common/MultiSelectSearch/types";
import {
  SOCIAL_INDICATORS,
  GOVERNANCE_INDICATORS,
  ENVIRONMENTAL_INDICATORS,
  getTagFormat,
} from "../../../Constants/Enumerations";
import { SectionTitle } from "../../common/SectionTitle";
import { ListingPortfolioAPI } from "../../../APIAction/ListingPortfolioAPI";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { GlossaryDefinition } from "../../../pages/Developers/Modals/GlossaryDefinitionModal/GlossaryDefinition";

export function PortfolioImpactCriteriaForm({
  portfolioListingId,
  handleResponse,
}: any) {
  const { t } = useTranslation("profile");
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [selectedFieldName, setSelectedFieldName] = useState("environmental");

  //Predefined tags
  const [
    environmentalIndicatorsPredefinedTags,
    setEnvironmentalIndicatorsPredefinedTags,
  ] = useState<Tag[]>([]);
  const [socialIndicatorsPredefinedTags, setSocialIndicatorsPredefinedTags] =
    useState<Tag[]>([]);
  const [
    governanceIndicatorsPredefinedTags,
    setGovernanceIndicatorsPredefinedTags,
  ] = useState<Tag[]>([]);

  //Selected Tags
  const [selectedEnvironmentalTags, setSelectedEnvironmentalTags] = useState<
    Tag[]
  >([]);
  const [selectedSocialTags, setSelectedSocialTags] = useState<Tag[]>([]);
  const [selectedGovernanceTags, setSelectedGovernanceTags] = useState<Tag[]>(
    []
  );

  // User tags state
  const [userEnvironmentalTags, setUserEnvironmentalTags] = useState<Tag[]>([]);
  const [userSocialTags, setUserSocialTags] = useState<Tag[]>([]);
  const [userGovernanceTags, setUserGovernanceTags] = useState<Tag[]>([]);

  const [ImpactCriteriaInfo, setImpactCriteriaInfo] =
    useState<any>();
  const [isApiLoading, setIsApiLoading] = useState(false);
  const [isDataExist, setIsDataExist] = useState(false);

  const navigate = useNavigate();
  const apiErrorPage = "/api-error";

  const initialValues = {
    social: [],
    socialDescription: "",
    environmental: [],
    environmentalDescription: "",
    governance: [],
    governanceDescription: "",
  };

  const environmentalIndicatorsTranslations = useMemo(() => {
    return Object.keys(ENVIRONMENTAL_INDICATORS).map((val) =>
      t(
        `developer.projects.impact_criteria_form.environmental.environmental_indicators.${val}`
      )
    );
  }, [t]);

  const environmentalPredefinedTags = useMemo(() => {
    return getTagFormat(
      ENVIRONMENTAL_INDICATORS,
      environmentalIndicatorsTranslations
    );
  }, [environmentalIndicatorsTranslations]);

  const socialIndicatorsTranslations = useMemo(() => {
    return Object.keys(SOCIAL_INDICATORS).map(
      (val) =>
        t(
          `developer.projects.impact_criteria_form.social.social_indicators.${val}`
        )
    );
  }, [t]);
  const socialPredefinedTags = useMemo(() => {
    return getTagFormat(
      SOCIAL_INDICATORS,
      socialIndicatorsTranslations
    );
  }, [socialIndicatorsTranslations]);

  const governanceIndicatorsTranslations = useMemo(() => {
    return Object.keys(
      GOVERNANCE_INDICATORS
    ).map((val) =>
      t(
        `developer.projects.impact_criteria_form.governance.governance_indicators.${val}`
      )
    );
  }, [t]);

  const governancePredefinedTags = useMemo(() => {
    return getTagFormat(
      GOVERNANCE_INDICATORS,
      governanceIndicatorsTranslations
    );
  }, [governanceIndicatorsTranslations]);

  const isLabelAlreadyExist = (label: string, tagsArray: Tag[]): boolean => {
    return tagsArray.some((tag) => tag.label === label);
  };

  useEffect(() => {
    setEnvironmentalIndicatorsPredefinedTags(environmentalPredefinedTags);
    setSocialIndicatorsPredefinedTags(socialPredefinedTags);
    setGovernanceIndicatorsPredefinedTags(governancePredefinedTags);
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [environmentalPredefinedTags, socialPredefinedTags, governancePredefinedTags]);

  const updateTagsFromApiData = (
    impactData: any[],
    predefinedTags: Tag[],
    selectedTags: Tag[],
    userTagsState: Tag[]
  ) => {
    impactData.forEach((impact: any) => {
      const matchingPredefinedTag = predefinedTags.find(
        (tag) => tag.label === impact.impactName
      );

      if (matchingPredefinedTag) {
        // If the tag exists in predefined tags and not in selected tags, update its added state
        if (!isLabelAlreadyExist(matchingPredefinedTag.label, selectedTags)) {
          matchingPredefinedTag.added = true;
          // Push the tag into selected tags
          selectedTags.push(matchingPredefinedTag);
        }
      } else {
        // If the tag doesn't exist in predefined tags, create a new tag and add it to selected tags
        const newTag: Tag = {
          inputValue: impact.impactName,
          label: impact.impactName,
          selected: true,
          added: true,
        };
        if (
          !isLabelAlreadyExist(newTag.label, selectedTags) &&
          !isLabelAlreadyExist(newTag.label, userTagsState)
        ) {
          const index = impact.impactOrder;
          userTagsState.push(newTag);
          selectedTags.splice(index, 0, newTag);
        }
      }
    });

    return { predefinedTags, selectedTags, userTagsState };
  };

  const getImpactCriteriaInfomation = async (id: string) => {
    try {
      setIsApiLoading(true);
      const res = await ListingPortfolioAPI.getImpactCriteriaById(id);
      const data = res.data;
      setIsApiLoading(false);
      setIsDataExist(true);
      setImpactCriteriaInfo(data);

      const updatedSocialTags = updateTagsFromApiData(
        data.socialImpact,
        socialPredefinedTags,
        selectedSocialTags,
        userSocialTags
      );
      setSocialIndicatorsPredefinedTags(updatedSocialTags.predefinedTags);
      setSelectedSocialTags(updatedSocialTags.selectedTags);
      setUserSocialTags(updatedSocialTags.userTagsState);

      const updatedEnvironmentalTags = updateTagsFromApiData(
        data.environmentalImpact,
        environmentalPredefinedTags,
        selectedEnvironmentalTags,
        userEnvironmentalTags
      );
      setEnvironmentalIndicatorsPredefinedTags(
        updatedEnvironmentalTags.predefinedTags
      );
      setSelectedEnvironmentalTags(updatedEnvironmentalTags.selectedTags);
      setUserEnvironmentalTags(updatedEnvironmentalTags.userTagsState);

      const updatedGovernanceTags = updateTagsFromApiData(
        data.governanceImpact,
        governancePredefinedTags,
        selectedGovernanceTags,
        userGovernanceTags
      );
      setGovernanceIndicatorsPredefinedTags(
        updatedGovernanceTags.predefinedTags
      );
      setSelectedGovernanceTags(updatedGovernanceTags.selectedTags);
      setUserGovernanceTags(updatedGovernanceTags.userTagsState);
    } catch (error) {
      setIsApiLoading(false);
      setIsDataExist(false);

      if (axios.isAxiosError(error) && error.response?.status !== 404) {
        navigate(apiErrorPage);
      }
    }
  };

  useEffect(() => {
    portfolioListingId && getImpactCriteriaInfomation(portfolioListingId);
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [portfolioListingId, navigate]);

  const handleSelectedSocialTagsChange = (newSelectedTags: Tag[]) => {
    setSelectedSocialTags(newSelectedTags);
  };

  const handleSelectedGovernanceTagsChange = (newSelectedTags: Tag[]) => {
    setSelectedGovernanceTags(newSelectedTags);
  };

  const handleSelectedEnvironmentalTagsChange = (newSelectedTags: Tag[]) => {
    setSelectedEnvironmentalTags(newSelectedTags);
  };

  const max_char = 150;
  const impactCriteriaSchema = Yup.object().shape({
    socialDescription: Yup.string().max(
      max_char,
      t("developer.projects.general_form_error.max_characters", {
        charNum: max_char,
      })
    ),
    environmentalDescription: Yup.string().max(
      max_char,
      t("developer.projects.general_form_error.max_characters", {
        charNum: max_char,
      })
    ),
    governanceDescription: Yup.string().max(
      max_char,
      t("developer.projects.general_form_error.max_characters", {
        charNum: max_char,
      })
    ),
  });

  async function submitImpactCriteriaInfo(formValues: Object) {
    try {
      setIsApiLoading(true);
      await ListingPortfolioAPI.postImpactCriteria({
        ...formValues,
      });
      setIsApiLoading(false);
      handleResponse("ImpactCriteria", true);
    } catch (error) {
      setIsApiLoading(false);
      handleResponse("ImpactCriteria", false);

      if (axios.isAxiosError(error) && error.response?.status !== 404) {
        navigate(apiErrorPage);
      }
    }
  }

  async function updateImpactCriteriaInfo(formValues: Object) {
    if (!portfolioListingId) {
      return;
    }

    try {
      setIsApiLoading(true);
      await ListingPortfolioAPI.updateImpactCriteria(
        portfolioListingId,
        { ...formValues }
      );
      setIsApiLoading(false);
      handleResponse("ImpactCriteria", true);
    } catch (error) {
      setIsApiLoading(false);
      handleResponse("ImpactCriteria", false);

      if (axios.isAxiosError(error) && error.response?.status !== 404) {
        navigate(apiErrorPage);
      }
    }
  }

  const onSubmit = (values: any) => {
    // Format social tags
    const social = selectedSocialTags.map((tag, index) => ({
      impactName: tag.label,
      impactOrder: index,
    }));
    // Format environmental tags
    const environmental = selectedEnvironmentalTags.map((tag, index) => ({
      impactName: tag.label,
      impactOrder: index,
    }));
    // Format governance tags
    const governance = selectedGovernanceTags.map((tag, index) => ({
      impactName: tag.label,
      impactOrder: index,
    }));

    // Create the payload
    const payload = {
      portfolioID: portfolioListingId,
      socialImpact: social,
      socialImpactDescription: values.socialDescription,
      environmentalImpact: environmental,
      environmentalImpactDescription: values.environmentalDescription,
      governanceImpact: governance,
      governanceImpactDescription: values.governanceDescription,
    };
    isDataExist
      ? updateImpactCriteriaInfo(payload)
      : submitImpactCriteriaInfo(payload);
  };

  const apiData = {
    social: ImpactCriteriaInfo?.socialImpact,
    socialDescription: ImpactCriteriaInfo?.socialImpactDescription,
    environmental: ImpactCriteriaInfo?.environmentalImpact,
    environmentalDescription: ImpactCriteriaInfo?.environmentalImpactDescription,
    governance: ImpactCriteriaInfo?.governanceImpact,
    governanceDescription: ImpactCriteriaInfo?.governanceImpactDescription,
  };

  return (
    <Formik
      initialValues={isDataExist ? apiData : initialValues}
      validationSchema={impactCriteriaSchema}
      validateOnChange={false}
      validateOnBlur={false}
      enableReinitialize={true}
      onSubmit={onSubmit}
    >
      <Form className={styles.formElement} noValidate>
        <p className={styles.subtitleImpact}>
          {t("developer.projects.impact_criteria_form.portfolio_subtitle")}
        </p>
        {/* Environmental section */}
        <SectionTitle
          titleText={t(
            "developer.projects.impact_criteria_form.environmental.heading"
          )}
          description={t(
            "developer.projects.impact_criteria_form.environmental.description"
          )}
        />
        {isOpenModal && (
          <GlossaryDefinition
            setCloseGlossaryModal={setIsOpenModal}
            title={t("glossary.title_" + selectedFieldName)}
            glossaryContent={t(
              "glossary.projectInfoGlossaryContent_" + selectedFieldName,
              { returnObjects: true }
            )}
            glossaryLink={t("glossary.viewFullGlossaryLink")}
            closeText={t("glossary.closeButton")}
            selectedId="#1"
          />
        )}
        <MultiSelectSearch
          fieldName="environmental"
          predefinedTagsList={environmentalIndicatorsPredefinedTags}
          labelText={t(
            "developer.projects.impact_criteria_form.environmental.portfolio_environmental_indicator_label"
          )}
          descPredefinedTags={t(
            "developer.projects.impact_criteria_form.environmental.desc_environmental_indicators_portfolio"
          )}
          placeholderText={t(
            "developer.projects.impact_criteria_form.environmental.placeholder"
          )}
          selectedTags={selectedEnvironmentalTags}
          userTags={userEnvironmentalTags}
          onSelectedTagsChange={handleSelectedEnvironmentalTagsChange}
          setIsOpenModal={setIsOpenModal}
          setSelectedFieldName={setSelectedFieldName}
          linkText={t("glossary.glossaryDefinitionLink")}
        />
        <TextArea
          fieldName="environmentalDescription"
          charLimit={150}
          labelText={t(
            "developer.projects.impact_criteria_form.environmental.impact_description_label"
          )}
          data-testid="impact-desired-description"
          placeholder={t("developer.projects.impact_criteria_form.environmental.impact_description_portfolio_placeholder"
          )}
        />

        <hr className={styles.horizontalLine}></hr>

        {/* Social Section */}
        <SectionTitle
          titleText={t(
            "developer.projects.impact_criteria_form.social.heading"
          )}
          description={t(
            "developer.projects.impact_criteria_form.social.description"
          )}
        />
        <MultiSelectSearch
          fieldName="social"
          predefinedTagsList={socialIndicatorsPredefinedTags}
          labelText={t(
            "developer.projects.impact_criteria_form.social.social_indicator_label_portfolio"
          )}
          descPredefinedTags={t(
            "developer.projects.impact_criteria_form.social.desc_social_indicators_portfolio"
          )}
          placeholderText={t(
            "developer.projects.impact_criteria_form.social.placeholder"
          )}
          selectedTags={selectedSocialTags}
          userTags={userSocialTags}
          onSelectedTagsChange={handleSelectedSocialTagsChange}
          setIsOpenModal={setIsOpenModal}
          setSelectedFieldName={setSelectedFieldName}
          linkText={t("glossary.glossaryDefinitionLink")}
        />

        <TextArea
          fieldName="socialDescription"
          charLimit={150}
          labelText={t(
            "developer.projects.impact_criteria_form.social.impact_description_label"
          )}
          data-testid="impact-desired-description"
          placeholder={t("developer.projects.impact_criteria_form.social.impact_description_portfolio_placeholder"
          )}
        />

        <hr className={styles.horizontalLine}></hr>

        {/* Governance Section */}

        <SectionTitle
          titleText={t(
            "developer.projects.impact_criteria_form.governance.heading"
          )}
          description={t(
            "developer.projects.impact_criteria_form.governance.description"
          )}
        />
        <MultiSelectSearch
          fieldName="governance"
          predefinedTagsList={governanceIndicatorsPredefinedTags}
          labelText={t(
            "developer.projects.impact_criteria_form.governance.governance_indicator_portfolio_label"
          )}
          descPredefinedTags={t(
            "developer.projects.impact_criteria_form.governance.desc_governance_indicators_portfolio"
          )}
          placeholderText={t(
            "developer.projects.impact_criteria_form.governance.placeholder"
          )}
          selectedTags={selectedGovernanceTags}
          userTags={userGovernanceTags}
          onSelectedTagsChange={handleSelectedGovernanceTagsChange}
          setIsOpenModal={setIsOpenModal}
          setSelectedFieldName={setSelectedFieldName}
          linkText={t("glossary.glossaryDefinitionLink")}
        />

        <TextArea
          fieldName="governanceDescription"
          charLimit={150}
          labelText={t(
            "developer.projects.impact_criteria_form.governance.impact_description_label"
          )}
          data-testid="governance-impact-description"
          placeholder={t("developer.projects.impact_criteria_form.governance.impact_description_portfolio_placeholder"
          )}
        />
        <div className={styles.buttonContainer}>
          <button
            type="submit"
            className={styles.formButton}
            data-testid="submit-button"
            disabled={isApiLoading}
          >
            {t("developer.projects.impact_criteria_form.btn_text")}
          </button>
        </div>
      </Form>
    </Formik>
  );
}
