import React from "react";
import { useTranslation } from "react-i18next";

import styles from "./FinancialDetails.module.scss";
import { FinancialDetailHighlightSingle } from "./FinancialDetailHighlightSingle";
import { AffordabilityProjectBadge } from "./AffordabilityProjectBadge";

import {
  getKVArrayFormatForDropdown,
  COMMITMENT_TO_AFFORDABILITY,
} from "../../../Constants/Enumerations";

type Props = {
  affordabilityDetails: any;
};

export function AffordabilityDetails({ affordabilityDetails }: Props) {
  const { t } = useTranslation(["profile", "onboarding", "project"]);
  const totalNumberOfUnitsPercent = affordabilityDetails.belowAverageMarketRentUnitCount && affordabilityDetails.totalUnitCount
  ? (affordabilityDetails.belowAverageMarketRentUnitCount / affordabilityDetails.totalUnitCount) * 100
  : 0;

  const commitmentAffordabilityTrans = Object.keys(COMMITMENT_TO_AFFORDABILITY).map((val) =>
    t(`common:enumerations.commitment_to_affordability.${val}`)
  );
  const commitmentAffordabilityOptions: {
    label: string;
    value: string | number;
  }[] = getKVArrayFormatForDropdown(COMMITMENT_TO_AFFORDABILITY, commitmentAffordabilityTrans);

  const affordabilityCommitment =
    commitmentAffordabilityOptions.find(
      (item) => +item.value === affordabilityDetails.affordabilityCommitment
    )?.label;

  return (
    <div className={styles.summaryContainer} data-testid="summary-Container">
      <React.Fragment>
        <AffordabilityProjectBadge
          highlightLabel={t("profile:developer.projects.add_listing_form.type_of_affordability_review")}
          highlightValue={affordabilityDetails.projectAffordabilityType}
        />

        {affordabilityDetails.belowAverageMarketRentUnitCount &&
          <FinancialDetailHighlightSingle
            highlightLabel={t("profile:developer.projects.affordability_details.units_below_avg_rent")}
            highlightValue={affordabilityDetails.belowAverageMarketRentUnitCount}
          />
        }

        {affordabilityDetails.equalToAverageMarketRentUnitCount &&
          <FinancialDetailHighlightSingle
            highlightLabel={t("profile:developer.projects.affordability_details.units_equal_avg_rent")}
            highlightValue={affordabilityDetails.equalToAverageMarketRentUnitCount}
          />
        }


        {affordabilityDetails.aboveAverageMarketRentUnitCount &&
          <FinancialDetailHighlightSingle
          highlightLabel={t("profile:developer.projects.affordability_details.units_above_avg_rent")}
            highlightValue={affordabilityDetails.aboveAverageMarketRentUnitCount}
          />
        }


        {affordabilityDetails.totalUnitCount &&
          <FinancialDetailHighlightSingle
          highlightLabel={t("profile:developer.projects.affordability_details.total_units_developed")}
            highlightValue={affordabilityDetails.totalUnitCount}
            projectAffordabilityType = {affordabilityDetails.projectAffordabilityType}
            totalNumberOfUnitsPercent={totalNumberOfUnitsPercent}
          />
        }


        <FinancialDetailHighlightSingle
            highlightLabel={t("profile:developer.projects.affordability_details.commitment_to_affordability")}
          highlightValue={affordabilityCommitment ? affordabilityCommitment : ""}
        />

        {affordabilityDetails.marketLevelDefinition !== "" &&
          <FinancialDetailHighlightSingle
          highlightLabel={t("profile:developer.projects.affordability_details.affordability_description")}
            highlightValue={affordabilityDetails.marketLevelDefinition ? affordabilityDetails.marketLevelDefinition : ""}
          />
        }

        {affordabilityDetails.additionalDetails !== "" &&
          <FinancialDetailHighlightSingle
          highlightLabel={t("profile:developer.projects.affordability_details.additional_details")}
            highlightValue={affordabilityDetails.additionalDetails ? affordabilityDetails.additionalDetails : ""}
          />
        }


      </React.Fragment>

    </div>
  );
}
