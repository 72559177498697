import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  FUNDING_TYPES,
  getEnumKeyByValue,
  INVESTMENT_PHASES,
  ROI_RANGE,
  getKVArrayFormat,
  CAPITALSOURCES_TYPES
} from "../../../Constants/Enumerations";
import { PortfolioFinancialDetailsInterface } from "../../../TypesAndInterfaces/PortfolioDetailsInterface";
import styles from "./FinancialDetails.module.scss";
import { FinancialDetailHighlightSingle } from "./FinancialDetailHighlightSingle";
import { FinancialDetailHighlightMulti } from "./FinancialDetailHighlightMulti";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { numberWithCommas } from "../../../Constants/Utils/FormateNumber";

type Props = {
  financialDetail: PortfolioFinancialDetailsInterface;
};

export function PortfolioFinancialDetails({ financialDetail }: Props) {
  const { t } = useTranslation(["profile", "onboarding", "project"]);
  const [expand, setExpand] = useState(false);

  function renderYesOrNoOptions(truthyValue: boolean) {
    if (truthyValue) {
      return t(
        "profile:developer.projects.financial_details_doc_form.yes_label"
      );
    }
    return t("profile:developer.projects.financial_details_doc_form.no_label");
  }

  const targetRoiMaxMappedValue = getEnumKeyByValue(
    ROI_RANGE,
    financialDetail.capitalCostRangeMaximum
  );
  const targetRoiMinMappedValue = getEnumKeyByValue(
    ROI_RANGE,
    financialDetail.capitalCostRangeMinimum
  );

  const totalCost = financialDetail.totalPortfolioCost?.toString();

  const capitalAcquiredAmount =
    financialDetail.capitalAcquiredAmount?.toString();
  const minmiumInvestment = financialDetail.minimumInvestmentAmount?.toString();

  const targeRoiMinTranslated = t(
    `common:enumerations.roi_range.${targetRoiMinMappedValue}`
  );
  const targeRoiMaxTranslated = t(
    `common:enumerations.roi_range.${targetRoiMaxMappedValue}`
  );
  const targetRoiRangeTranslated = `${targeRoiMinTranslated} - ${targeRoiMaxTranslated}`;

  const fundingTypesToRender = financialDetail.fundingTypes.map((val) =>
    getEnumKeyByValue(FUNDING_TYPES, val)
  );

  const fundingTypesTranslated = fundingTypesToRender?.filter((val:any) => val !== undefined && val !== "").map((val:any) =>
  t(`common:enumerations.funding_types.${val}`)
  );

  const investmentPhasesTranslations = Object.keys(INVESTMENT_PHASES).map(
    (val) => t(`common:enumerations.investment_phases.${val}`)
  );
  const investmentPhasesOptions = getKVArrayFormat(
    INVESTMENT_PHASES,
    investmentPhasesTranslations
  );

  const investmentPhases =
    financialDetail.investmentPhases &&
    financialDetail.investmentPhases.length > 0 &&
    financialDetail.investmentPhases.map(
      (val, idx) =>
        investmentPhasesOptions.find((obj) => obj.value === val)?.label
    );


  const capitalSourcesTranslations = Object.keys(CAPITALSOURCES_TYPES).map((val) =>
    t(`common:enumerations.capitalSources_types.${val}`)
  );

  const capitalSourcesTypes = getKVArrayFormat(
    CAPITALSOURCES_TYPES,
    capitalSourcesTranslations
  );
  const handleExpand = () => {
    setExpand(!expand);
  };
  return (
    <div className={styles.summaryContainer} data-testid="summary-Container">
      <div className={styles.infoHeadingContainer}>
        <div className={styles.left}>
          <h2 className={styles.sectionHeading}>
            {t("project:project.main_page.project_open.financial_details")}
          </h2>
        </div>
        <button className={styles.link} onClick={handleExpand} aria-controls={expand ? "Collapse More Financial Details" : "Expand"}
          aria-expanded={expand ? "true" : "false"}
          aria-label={expand ? "Collapse More Financial Details" : "Expand"}>
          {expand ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        </button>
      </div>

      {expand && (
        <React.Fragment>
          <h4>{t("project:project.financial_details.portfolio_title")} </h4>
          <FinancialDetailHighlightMulti
            highlightLabel={t("project:project.financial_details.total_portfolio_cost_label")}
            highlightValueArrary={[
              totalCost ? "$" + numberWithCommas(totalCost) : "",
            ]}
          />

          <FinancialDetailHighlightMulti
            highlightLabel={t(
              "project:project.financial_details.phase_project_label"
            )}
            //@ts-ignore
            highlightValueArrary={investmentPhases}
          />
          <hr></hr>
          <h4>{t("project:project.financial_details.capital_needed_title")}</h4>

          <FinancialDetailHighlightSingle
            highlightLabel={t("project:project.financial_details.minimum_investment")}
            highlightValue={"$" + numberWithCommas(minmiumInvestment ?? "")}
          />

          <FinancialDetailHighlightMulti
            highlightLabel={t(
              "project:project.financial_details.capital_injection_label"
            )}
            highlightValueArrary={fundingTypesTranslated}
          />

          {!financialDetail.displayFundingFlag &&
            financialDetail.totalFundingNeeded && (
              <React.Fragment>
                <FinancialDetailHighlightSingle
                  highlightLabel={t(
                    "developer.projects.financial_details.total_funding_needed_label"
                  )}
                  highlightValue={
                    "$" + numberWithCommas(financialDetail.totalFundingNeeded)
                  }
                />
              </React.Fragment>
            )}

          {financialDetail.displayFundingFlag && (
            <React.Fragment>
              {financialDetail.grantAmount && (
                <FinancialDetailHighlightSingle
                  highlightLabel={t(
                    "project:project.financial_details.granting"
                  )}
                  highlightValue={
                    "$" + numberWithCommas(financialDetail.grantAmount)
                  }
                />
              )}

              {financialDetail.equityAmount && (
                <FinancialDetailHighlightSingle
                  highlightLabel={t(
                    "project:project.financial_details.equity"
                  )}
                  highlightValue={
                    "$" + numberWithCommas(financialDetail.equityAmount)
                  }
                />
              )}

              {financialDetail.debtFinancingAmount && (
                <FinancialDetailHighlightSingle
                  highlightLabel={t(
                    "project:project.financial_details.debt_financing"
                  )}
                  highlightValue={
                    "$" + numberWithCommas(financialDetail.debtFinancingAmount)
                  }
                />
              )}

              {financialDetail.bridgeFinancingAmount && (
                <FinancialDetailHighlightSingle
                  highlightLabel={t(
                    "project:project.financial_details.bridge_financing"
                  )}
                  highlightValue={
                    "$" +
                    numberWithCommas(financialDetail.bridgeFinancingAmount)
                  }
                />
              )}

              {financialDetail.anyCapitalAmount && (
                <FinancialDetailHighlightSingle
                  highlightLabel={t(
                    "project:project.financial_details.anyTypeofCapital"
                  )}
                  highlightValue={
                    "$" + numberWithCommas(financialDetail.anyCapitalAmount)
                  }
                />
              )}
            </React.Fragment>
          )}
         {financialDetail.coInvestingFlag != null && (
          <FinancialDetailHighlightSingle
            highlightLabel={t(
              "project:project.financial_details.co_investing_label"
            )}
            highlightValue={renderYesOrNoOptions(
              financialDetail.coInvestingFlag
            )}
          />
        )}
          {financialDetail.lockInPeriodRequiredFlag!=null && (
          <FinancialDetailHighlightSingle
            highlightLabel={t(
              "project:project.financial_details.lockIn_period_viewPage_label"
            )}
            highlightValue={renderYesOrNoOptions(
              financialDetail.lockInPeriodRequiredFlag
            )}
          />
          )}
          {financialDetail.lockInPeriodDetailDescription && (
            <FinancialDetailHighlightSingle
              highlightLabel={t(
                "project:project.financial_details.lockIn_period_description"
              )}
              highlightValue={financialDetail.lockInPeriodDetailDescription}
            />
          )}
          <hr></hr>
          <h4>
            {t("project:project.financial_details.capital_acquired_title")}
          </h4>

          <FinancialDetailHighlightSingle
            highlightLabel={t(
              "project:project.financial_details.capital_secured_label"
            )}
            highlightValue={
              capitalAcquiredAmount
                ? "$" + numberWithCommas(capitalAcquiredAmount)
                : ""
            }
          />
          {financialDetail.capitalSources && financialDetail.capitalSources.length > 0 &&
            <FinancialDetailHighlightMulti
              highlightLabel={t("project:project.financial_details.capital_sources_label")}
              //@ts-ignore
              highlightValueArrary={financialDetail.capitalSources.map((val, idx) => capitalSourcesTypes.find((obj) => obj.value === val)?.label)}
            />}

          <FinancialDetailHighlightSingle
            highlightLabel={t(
              "project:project.financial_details.current_expected_roi_label"
            )}
            highlightValue={targetRoiRangeTranslated}
          />
        {financialDetail?.additionalCapitalAcquiredDetails &&
          <FinancialDetailHighlightSingle
            highlightLabel={t(
              "project:project.financial_details.additional_details_label"
            )}
            highlightValue={
              financialDetail?.additionalCapitalAcquiredDetails ?? ""
            }
          />
        }
        </React.Fragment>
      )}
    </div>
  );
}
