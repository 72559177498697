import React, { useEffect, useState } from "react";
import { PageLayout } from "../../../components/Layout/PageLayout";
import { PageContent } from "../../../components/Layout/PageContent";
import styles from "./ViewDeveloperPortfolio.module.scss";
import { ListingPortfolioAPI } from "../../../APIAction/ListingPortfolioAPI";
import { useLocation } from "react-router-dom";
import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import { pink } from "@mui/material/colors";
import { AffordabilityDetails } from "./AffordabilityDetails";
import { ProjectInPortfolioDetails } from "./ProjectInPortfolioDetails";

import { DeveloperApi } from "../../../APIAction/DeveloperAPI";
import { InvestorAPI } from "../../../APIAction/InvestorAPI";

import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { useUser } from "../../../components/Context/UserContext";
import { getKVArrayFormat, PROVINCES } from "../../../Constants/Enumerations";
import { PortfolioDetailsInterface } from "../../../TypesAndInterfaces/PortfolioDetailsInterface";
import { PortfolioDropdown } from "./PortfolioDropdown";
import { PortfolioSummary } from "./PortfolioSummary";
import { DeveloperDetails } from "../../../TypesAndInterfaces/DeveloperDetailsInterface";
import { PortfolioFinancialDetailsChart } from "./PortfolioFinancialDetailsChart";
import { PortfolioFinancialDetails } from "./PortfolioFinancialDetails";
import { ProjectsInPortfolio } from "./ProjectsInPortfolio";
import { ViewDeveloperInfo } from "../../DeveloperOnboarding/ViewDeveloperProfile/DeveloperInfo";
import { ViewGeneralInfo } from "../../DeveloperOnboarding/ViewDeveloperProfile/ViewGeneralInfo";
import { ImpactCriteriaDeatils } from "./ImpactCriteriaDetails";
import { ClosePortfolio } from "../../Developers/Modals/ClosePortfolio";
import { PortfolioCloseSuccess } from "../../Developers/Modals/PortfolioCloseSuccess";
import { DeletePortfolio } from "../../Developers/Modals/DeletePortfolio";
import { ConnectionStatusMessage } from "../../Developers/ProjectDetails/ConnectionMessage";
import { RequestSentSuccess } from "../../../components/ConnectionRequest/RequestSentSuccess";
import { InvestorToDevRequestPortfolio } from "../../../components/ConnectionRequest/InvestorToDevPortfolio";
import { PDFCover } from "../../../assets/images";

import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { GlossaryDefinition } from "../../Developers/Modals/GlossaryDefinitionModal/GlossaryDefinition";

export const ViewDeveloperPortfolio = () => {
  const { t } = useTranslation(["portfolio", "cards", "profile"]);
  const { portfolioId } = useParams();

  const [isLoading, setIsLoading] = useState(false);
  const { userState } = useUser();
  const [portfolioData, setPortfolioData] =
    useState<PortfolioDetailsInterface>();
  const [developerData, setDeveloperData] = useState<DeveloperDetails>();
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isOpenAffordabilityModal, setIsOpenAffordabilityModal] =
    useState(false);
  const [showRequestSentSuccess, setShowRequestSentSuccess] = useState(false);
  const [connectToDeveloper, setConnectToDeveloper] = useState(false);

  const sasTokenDocumentStorage = process.env.REACT_APP_DOCUMENT_SAS_TOKEN;

  const navigate = useNavigate();

  const [closeProjectModal, setCloseProjectModal] = useState<boolean>(false);
  const [projectCloseSuccess, setProjectCloseSuccess] =
    useState<boolean>(false);
  const [deleteProject, setDeleteProject] = useState<boolean>(false);

  const location = useLocation();

  const [check, setCheck] = useState(location.state === "true" ? true : false);

  const [open, setOpen] = useState({ switch: false, message: "success" });

  useEffect(() => {
    const portfolioDetailsByID = async (id: string) => {
      try {
        // setIsLoading(true);
        const response = await ListingPortfolioAPI.getPortfolioListingInfoByID(
          id
        );
        setPortfolioData(response.data);
        // setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        return;
      }
    };
    if (portfolioId) {
      portfolioDetailsByID(portfolioId);
    }
    const developerDataById = async (id: string) => {
      try {
        const response = await DeveloperApi.getDeveloperById(id);
        setDeveloperData(response.data);
      } catch {
        setIsLoading(false);
        return;
      }
    };

    if (portfolioData?.developerID) {
      developerDataById(portfolioData.developerID);
    }
  }, [
    navigate,
    portfolioId,
    portfolioData?.developerID,
    userState,
    showRequestSentSuccess,
  ]);

  const provincesTranslated = Object.keys(PROVINCES).map((val) =>
    t(`common:enumerations.provinces.${val}`)
  );
  const provinceOptions = getKVArrayFormat(PROVINCES, provincesTranslated);
  const isOtherUserProject = portfolioData?.developerID !== userState.userId;
  const totalProjectCount = t(
    "portfolio:portfolio_details.portfolio_summary.num_of_projects",
    {
      totalProjectCount:
        portfolioData?.portfolioProjectDetail.totalProjectCount,
    }
  );

  const handleDropDownClick = (value: string) => {
    switch (value) {
      case "edit_portfolio":
        navigate(`/portfolio/edit/${portfolioId}`);
        break;
      case "close_portfolio":
        setCloseProjectModal(true);
        break;
      case "delete_portfolio":
        setDeleteProject(true);
        break;
      default:
        break;
    }
  };

  const handleConnect = () => {
    setConnectToDeveloper(!connectToDeveloper);
  };

  const handleRequestSent = () => {
    setConnectToDeveloper(false);
    setShowRequestSentSuccess(true);
  };

  const savePortfolio = async (portfolioID: string, portfolioName: string) => {
    if (!userState.isOnboarded) {
      setCheck(false);
      setOpen({
        switch: true,
        message: t("cards:project_cards.add_portfolio_error"),
      });
      return;
    }
    try {
      const info = {
        investorID: userState.userId,
        portfolioID: portfolioID,
      };
      await InvestorAPI.postSavePortfolio(info);
      setCheck(!check);
      setOpen({
        switch: true,
        message: t("cards:project_cards.add_portfolio"),
      });
      window.dataLayer.push({
        event: "save_portfolio",
        portfolio_name: portfolioName,
      });
    } catch (error) {
      setCheck(false);
      setOpen({
        switch: true,
        message: t("cards:project_cards.add_portfolio_error"),
      });
    }
  };
  const removePortfolio = async (portfolioID: string) => {
    try {
      const info = {
        investorID: userState.userId,
        portfolioID: portfolioID,
      };
      await InvestorAPI.postRemoveSavedPortfolio(info);
      setCheck(!check);
      setOpen({
        switch: true,
        message: t("cards:project_cards.remove_portfolio"),
      });
    } catch (error) {
      setCheck(false);
      setOpen({
        switch: true,
        message: t("cards:project_cards.remove_portfolio_error"),
      });
    }
  };
  const action = (
    <React.Fragment>
      {check && (
        <p
          onClick={() => {
            navigate("/search/projects");
            window.location.reload();
            localStorage.setItem("lastTabIndex", "3");
            localStorage.setItem("lastPage", "1");
          }}
          className={styles.linkFavorite}
        >
          {t("cards:project_cards.save_portfolio_link")}
        </p>
      )}
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={() => setOpen({ ...open, switch: false })}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  return (
    <PageLayout>
      <hr className={styles.divider} />
      <PageContent>
        <div className={styles.container}>
          <Snackbar
            className={styles.ccSnackbar}
            ContentProps={{
              sx: {
                background: check === false ? "red" : undefined,
              },
            }}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            open={open.switch}
            onClose={() => setOpen({ ...open, switch: false })}
            message={open.message}
            key={"top" + "center"}
            action={action}
          />
          {closeProjectModal && (
            <ClosePortfolio
              closeProjectModal={closeProjectModal}
              setCloseProjectModal={setCloseProjectModal}
              setProjectCloseSuccess={setProjectCloseSuccess}
              portfolioId={portfolioId ? portfolioId : ""}
            />
          )}
          {projectCloseSuccess && (
            <PortfolioCloseSuccess
              projectCloseSuccess={projectCloseSuccess}
              setProjectCloseSuccess={setProjectCloseSuccess}
            />
          )}
          {deleteProject && (
            <DeletePortfolio
              setDeleteProject={setDeleteProject}
              portfolioId={portfolioId ? portfolioId : ""}
            />
          )}
          <div className={styles.headerContainer}>
            {/* {pendingConnectionStatus?.isConnectionPending && (
              <AwaitingRequestsToast />
            )} */}
            <h2
              className={styles.sectionHeading}
              data-testid="projectDetail-Heading"
            >
              {t("portfolio_details.heading")}
            </h2>
            {userState.userId === portfolioData?.developerID ? (
              <PortfolioDropdown
                handleDropDownClick={handleDropDownClick}
                closeFlag={portfolioData.portfolioStatus === 3 ? true : false}
              />
            ) : (
              <div>
                {userState.userPersona === "Investor" && check ? (
                  <div>
                    <div className={styles.favoriteContainer}>
                      <p className={styles.favoriteText}>
                        {t("cards:project_cards.saved_Text")}
                      </p>
                      <FavoriteIcon
                        sx={{ color: pink[500] }}
                        onClick={() =>
                          removePortfolio(portfolioId ? portfolioId : "")
                        }
                      />
                    </div>
                  </div>
                ) : (
                  userState.userPersona === "Investor" && (
                    <div className={styles.favoriteContainer}>
                      <p className={styles.favoriteText}>
                        {t("cards:project_cards.save")}
                      </p>
                      <FavoriteBorderIcon
                        className={styles.favoriteIcon}
                        onClick={() =>
                          savePortfolio(
                            portfolioId ? portfolioId : "",
                            portfolioData
                              ? portfolioData.generalInformation.portfolioName
                              : ""
                          )
                        }
                      />
                    </div>
                  )
                )}
              </div>
            )}
          </div>
          {!isLoading && portfolioData ? (
            <>
              <div
                className={styles.summaryContainer}
                data-testid="summary-Container"
              >
                {portfolioData?.portfolioProjectDetail && developerData && (
                  <PortfolioSummary
                    portfolioProjectDetail={
                      portfolioData.portfolioProjectDetail
                    }
                    generalInformation={portfolioData.generalInformation}
                    developerData={developerData}
                    portfolioStatus={portfolioData?.portfolioStatus}
                    closeReasonCode={portfolioData?.closeReasonCode}
                  />
                )}

                <div className={styles.connectContainer}>
                  {userState.userId !== portfolioData?.developerID && true ? (
                    <ConnectionStatusMessage
                      handleConnect={handleConnect}
                      developerID={portfolioData.developerID}
                      projectListingID={portfolioData.id}
                      updateConnectionStatus={showRequestSentSuccess === true}
                      isPortfolio={true}
                      portfolioStatus={portfolioData?.portfolioStatus}
                    />
                  ) : null}
                </div>
              </div>
              <div className={styles.noteContainer}>
                <h2>{t("portfolio_details.portfolio_summary.note_label")}</h2>
                <p>{t("portfolio_details.portfolio_summary.note_content")}</p>
              </div>

              {portfolioData?.affordabilityDetails && (
                <React.Fragment>
                  <div className={styles.financialheading}>
                    <h2
                      className={styles.sectionHeading}
                      data-testid="financialDetails-Heading"
                    >
                      {t(
                        "portfolio_details.main_page.project_open.affordability"
                      )}
                    </h2>
                    <button
                      type="button"
                      className={styles.glossaryLink}
                      data-testid="glossary-page-link"
                      onClick={() => {
                        setIsOpenAffordabilityModal(true);
                      }}
                    >
                      {t(
                        "portfolio_details.main_page.project_open.glossry_link"
                      )}
                    </button>
                    {isOpenAffordabilityModal && (
                      <GlossaryDefinition
                        setCloseGlossaryModal={setIsOpenAffordabilityModal}
                        title={t(
                          "profile:developer.projects.add_listing_form.affordabilityGlossary.title"
                        )}
                        glossaryContent={t(
                          "profile:developer.projects.add_listing_form.affordabilityGlossary.affordabilityGlossaryContent",
                          {
                            returnObjects: true,
                          }
                        )}
                        paraContent={t(
                          "profile:developer.projects.add_listing_form.affordabilityGlossary.affordabilityParaContent",
                          {
                            returnObjects: true,
                          }
                        )}
                        glossaryLink={t(
                          "profile:developer.projects.add_listing_form.affordabilityGlossary.viewFullGlossaryLink"
                        )}
                        closeText={t(
                          "profile:developer.projects.add_listing_form.affordabilityGlossary.closeButton"
                        )}
                        selectedId="#2"
                      />
                    )}
                  </div>
                  <AffordabilityDetails
                    affordabilityDetails={portfolioData?.affordabilityDetails}
                    data={portfolioData?.portfolioProjectDetail}
                  />
                </React.Fragment>
              )}

              <div className={styles.financialheading}>
                <h2
                  className={styles.sectionHeading}
                  data-testid="financialDetails-Heading"
                >
                  {t(
                    "portfolio_details.main_page.project_open.financial_details"
                  )}
                </h2>
                <button
                  type="button"
                  className={styles.glossaryLink}
                  data-testid="glossary-page-link"
                  onClick={() => {
                    setIsOpenModal(true);
                  }}
                >
                  {t("portfolio_details.main_page.project_open.glossry_link")}
                </button>
                {isOpenModal && (
                  <GlossaryDefinition
                    setCloseGlossaryModal={setIsOpenModal}
                    title={t(
                      "profile:glossary.capitalAcquiredGlossaryContent.title"
                    )}
                    glossaryContent={t(
                      "profile:glossary.capitalAcquiredGlossaryContent.capitalAcquiredGlossaryList",
                      { returnObjects: true }
                    )}
                    glossaryLink={t("profile:glossary.viewFullGlossaryLink")}
                    closeText={t("profile:glossary.closeButton")}
                    selectedId="#4"
                  />
                )}
              </div>
              <div data-testid="financial-info">
                {portfolioData?.financialDetails &&
                  portfolioData?.financialDetails?.totalPortfolioCost && (
                    <PortfolioFinancialDetailsChart
                      financialDetail={portfolioData?.financialDetails}
                    />
                  )}
              </div>

              <div className={styles.noteContainer}>
                <h2>
                  {t(
                    "portfolio:portfolio_details.portfolio_summary.note_label"
                  )}
                </h2>
                <p>
                  {t(
                    "portfolio:portfolio_details.portfolio_summary.note_content_accuracy_portfolio"
                  )}
                </p>
              </div>

              <div className={styles.financialheading}></div>
              <div data-testid="financial-info">
                {portfolioData?.financialDetails && (
                  <PortfolioFinancialDetails
                    financialDetail={portfolioData?.financialDetails}
                  />
                )}
              </div>
              <div
                className={styles.impactContainer}
                data-testid="impact-summary-Container"
              >
                <h2
                  className={styles.sectionHeading}
                  data-testid="impact-criteria-Heading"
                >
                  {t(
                    "project:project.main_page.project_open.impact_criteria_portfolio"
                  )}
                </h2>
                {portfolioData?.developmentImpact && (
                  <ImpactCriteriaDeatils
                    portfolioListingID={portfolioData.id}
                    impactCriteria={portfolioData?.developmentImpact}
                    otherUserProjectflag={isOtherUserProject}
                    portfolioStatus={portfolioData?.portfolioStatus}
                  />
                )}
                <div className={styles.noteContainer}>
                  <h2>
                    {t(
                      "portfolio:portfolio_details.portfolio_summary.note_label"
                    )}
                  </h2>
                  <p>
                    {t(
                      "portfolio:portfolio_details.portfolio_summary.note_impact_criteria"
                    )}
                  </p>
                </div>
              </div>

              {portfolioData?.portfolioProjectDetail &&
                !portfolioData?.portfolioProjectDetail
                  .displayProjectDetailsFlag && (
                  <div className={styles.detailsContainer}>
                    <h2 className={styles.sectionHeading}>
                      {t("portfolio_details.project.heading")}
                    </h2>
                    <ProjectInPortfolioDetails
                      portfolioProjectDetail={
                        portfolioData.portfolioProjectDetail
                      }
                    />
                  </div>
                )}
              <div>
                {portfolioData?.portfolioProjectDetail &&
                  portfolioData?.portfolioProjectDetail
                    .displayProjectDetailsFlag && (
                    <div className={styles.detailsContainer}>
                      <h2 className={styles.sectionHeading}>
                        {t("portfolio_details.project.heading")}
                      </h2>
                      <p>{totalProjectCount}</p>
                      <ProjectInPortfolioDetails
                        portfolioProjectDetail={
                          portfolioData.portfolioProjectDetail
                        }
                      />

                      <ProjectsInPortfolio
                        portfolioProjectDetail={
                          portfolioData.portfolioProjectDetail
                        }
                        isPortfolio={true}
                      />
                    </div>
                  )}
              </div>

              {portfolioData.documentationDetail &&
                portfolioData.documentationDetail.blobs &&
                portfolioData.documentationDetail.blobs.length !== 0 && (
                  <div>
                    <h2 className={styles.sectionHeading}>
                      {t(
                        "profile:developer.projects.file_upload_doc.documentation"
                      )}
                    </h2>
                    <div className={styles.docsContainer}>
                      {portfolioData.documentationDetail.blobs?.map(
                        (item: any, idx: number) =>
                          item.blobStatus !== 2 ? (
                            <div className={styles.uploadCard} key={idx}>
                              <div className={styles.imageContent}>
                                <div>
                                  <img
                                    className={styles.image}
                                    src={PDFCover}
                                    alt={item.fileName}
                                  />
                                </div>
                                <div className={styles.imagetitle}>
                                  <p>
                                    {item.fileName}{" "}
                                    <span className={styles.imageSize}>
                                      {item.blobSize} MB
                                    </span>
                                  </p>
                                </div>

                                <div
                                  className={styles.downloadLink}
                                  onClick={(e) => {
                                    e.preventDefault();
                                    const a = document.createElement("a");
                                    a.style.display = "none";
                                    a.download = item.fileName;
                                    a.target = "_blank";
                                    a.href =
                                      item.blobUri + sasTokenDocumentStorage;
                                    document.body.appendChild(a);
                                    a.click();
                                  }}
                                >
                                  {t(
                                    "profile:developer.projects.file_upload_doc.download_file"
                                  )}
                                </div>
                              </div>
                            </div>
                          ) : (
                            <React.Fragment></React.Fragment>
                          )
                      )}
                    </div>
                    <div className={styles.noteContainer}>
                      <h2>
                        {t(
                          "portfolio:portfolio_details.portfolio_summary.note_label"
                        )}
                      </h2>
                      <p>
                        {t(
                          "profile:developer.projects.file_upload_doc.doc_note_project_details"
                        )}
                      </p>
                    </div>
                    <br></br>
                    <br></br>
                  </div>
                )}

              <h2
                className={styles.sectionHeading}
                data-testid="developerProfile-Heading"
              >
                {t("project:project.main_page.project_open.developer_profile")}
              </h2>

              {developerData?.generalInformation ? (
                <ViewGeneralInfo
                  genInfo={developerData.generalInformation}
                  provinceOptions={provinceOptions}
                  projectDetailsPage={true}
                  id={developerData.id}
                />
              ) : null}

              {developerData?.developerExperience ? (
                <div className={styles.developerProjectsWrapper}>
                  <ViewDeveloperInfo
                    DeveloperInfo={developerData?.developerExperience}
                    provinceOptions={provinceOptions}
                  />
                </div>
              ) : null}
            </>
          ) : null}

          {connectToDeveloper &&
            developerData?.generalInformation &&
            portfolioData && (
              <InvestorToDevRequestPortfolio
                projectData={portfolioData}
                openModal={connectToDeveloper}
                handleClose={handleConnect}
                developerInfo={developerData}
                handleSubmitResponse={handleRequestSent}
              />
            )}

          {showRequestSentSuccess && (
            <RequestSentSuccess
              openModal={showRequestSentSuccess}
              handleClose={() => setShowRequestSentSuccess(false)}
              isPortfolio={true}
            />
          )}
        </div>
      </PageContent>
    </PageLayout>
  );
};
