import styles from "./styles.module.scss";
import { Form, Formik } from "formik";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { TextArea } from "../common/TextArea";
import { TextInput } from "../common/TextInput";
import { ProjectGeneralInfoProps } from "./AddProjectProps";
import { ListingProjectAPI } from "../../../APIAction/ListingProjectAPI";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { PROVINCES_CODES } from "../../../Constants/Enumerations";
import { useUser } from "../../Context/UserContext";
import { ProjectLocationFields } from "./ProjectLocationFields";
import { ProjectDateFields } from "./ProjectDateFields";
import {
  schemaDateComparison,
  submitGeneralInformation,
  updateGeneralInformation,
} from "./ProjectGeneralInformationHelpers";
import { handleApiErrorRedirect } from "../../../APIAction/config";
import { ProjectGeneralInfo } from "../../../TypesAndInterfaces/ProjectDetailsInterface";
import { useMsal } from "@azure/msal-react";
import Dropzone from 'react-dropzone';
import { BlobServiceClient } from '@azure/storage-blob';
import { CloudUpload, CloseIconSvg } from "../../../assets/icons";
import { UploadLoading, ErrorImage } from "../../../assets/images";
import CircularProgress from '@mui/material/CircularProgress';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { City } from "country-state-city";
import { checkFileSignature } from "../../../Constants/mimeTypeFunction";

export function ProjectGeneralInformationForm({
  isAffordabilitySubmit,
  handleResponse,
  projectListingId,
  isGenInfoSubmit
}: ProjectGeneralInfoProps) {
  const { t } = useTranslation("profile");
  const { userState } = useUser();

  const { accounts } = useMsal();
  const userEmail = accounts[0].username;


  const account = process.env.REACT_APP_QUARANTINE_STORAGE_ACCOUNT_NAME;
  const sasToken = process.env.REACT_APP_QUARANTINE_SAS_TOKEN;
  const sasTokenDocumentStorage = process.env.REACT_APP_DOCUMENT_SAS_TOKEN;

  const containerName = process.env.REACT_APP_QUARANTINE_CONTAINER_NAME;
  const blobServiceClient = new BlobServiceClient(`https://${account}.blob.core.windows.net/${sasToken}`);  // create a blobServiceClient
  //@ts-ignore
  const containerClient = blobServiceClient.getContainerClient(containerName);



  const initialValues = {
    projectName: "",
    organizationName: userState.userOrganization,
    province: "",
    city: "",
    targetProjectStartDate: "",
    targetProjectCompleteDate: "",
    projectDescription: "",
  };
  const navigate = useNavigate();
  const [GeneralInfo, setGeneralInfo] = useState<ProjectGeneralInfo>();
  const [isDataExist, setIsDataExist] = useState(false);
  const [isApiLoading, setIsApiLoading] = useState(false);
  const [images, setImages] = useState<any[]>([]);
  const [open, setOpen] = useState({ switch: false, message: "success" });
  const [isBrowseButtonDisabled, setIsBrowseButtonDisabled] = useState(false);


  const getGeneralInfomation = useCallback(
    async (id: string) => {
      try {
        setIsApiLoading(true);
        const res = await ListingProjectAPI.getProjectGeneralInfoById(id);
        const data = res.data;

        setIsApiLoading(false);
        setIsDataExist(true);
        if (data.blobs) {
          setImages(data.blobs);
        }
        if( data.blobs != null && data.blobs.length  >= 6)
        {  
         setIsBrowseButtonDisabled(true);        
        }    
        setGeneralInfo(data);
      } catch (error) {
        setIsApiLoading(false);
        setIsDataExist(false);
        handleApiErrorRedirect(error, navigate);
      }
    },
    [navigate]
  );

  useEffect(() => {
    projectListingId && getGeneralInfomation(projectListingId);
  }, [projectListingId, getGeneralInfomation]);

  useEffect(() => {
    projectListingId && getGeneralInfomation(projectListingId);
  }, [isGenInfoSubmit === true]);

  const selectedProvince = PROVINCES_CODES.find((element) => element.value === Number(GeneralInfo?.province));
  const isoCode = selectedProvince && selectedProvince.isoCode;
  const cityList = isoCode && City.getCitiesOfState('CA', isoCode);
  const formattedCityOptions = cityList && cityList.map(city => ({ label: city.name, value: city.name }));

  // Check if the user's city code matches any city in the city list
  const matchingCity = formattedCityOptions && formattedCityOptions.find(city => city.value === GeneralInfo?.city);

  const apiData = {
    projectName: GeneralInfo?.projectName,
    province: GeneralInfo?.province,
    city: matchingCity ? matchingCity?.label : "",
    targetProjectStartDate: GeneralInfo?.targetProjectStartDate,
    targetProjectCompleteDate: GeneralInfo?.targetProjectCompleteDate,
    projectDescription: GeneralInfo?.projectDescription,
  };

  const maxCharsErrorText = t(
    "developer.projects.general_form_error.max_characters",
    { charNum: 64 }
  );
  const spacingErrorText = t("developer.projects.general_form_error.no_spaces");
  const regxNotAllowOnlySpace = /^(?!\s*$)/;

  const generalInfoSchema = Yup.object().shape({
    projectName: Yup.string()
      .required(
        t("developer.projects.general_form_error.project_name_required")
      )
      .max(64, maxCharsErrorText)
      .matches(regxNotAllowOnlySpace, spacingErrorText),
    province: Yup.number()
      .required(t("developer.projects.general_form_error.province_required"))
      .max(64, maxCharsErrorText),
    city: Yup.string()
      .required(t("developer.projects.general_form_error.city_required"))
      .max(64, maxCharsErrorText)
      .matches(regxNotAllowOnlySpace, spacingErrorText),

    targetProjectStartDate: Yup.date().required(
      t("developer.projects.general_form_error.start_date_required")
    ),
    targetProjectCompleteDate: Yup.date()
      .required(t("developer.projects.general_form_error.end_date_required"))
      .when("targetProjectStartDate", (st: Date, schema: Yup.DateSchema) =>
        schemaDateComparison(
          st,
          schema,
          t("developer.projects.general_form_error.date_comparision")
        )
      ),
    projectDescription: Yup.string()
      .required(
        t("developer.projects.general_form_error.project_desc_required")
      )
      .max(
        1000,
        t("developer.projects.general_form_error.max_characters", {
          charNum: 1000,
        })
      )
      .matches(regxNotAllowOnlySpace, spacingErrorText),
  });


  function isValid(fileName: string) {

    return (new RegExp('(' + ['.jpg', '.jpeg', '.png', '.JPG', '.JPEG', '.PNG'].join('|').replace(/\./g, '\\.') + ')$')).test(fileName);
  }

  async function uploadAttachment(file: any) {

    if (!file) {  // check if the file is selected
      return;
    }
    if (!account || !sasToken || !containerName) {  // check if the credentials are set
      alert('Please make sure you have set the Azure Storage credentials in the .env file');
      return;
    }

    const existingImg = images.filter(img => (img.blobStatus !== 2 && img.fileName === file.name));

    if (images.length !== 0 && (images[images.length - 1].imgError || existingImg.length !== 0)) {
      return;
    }
    try {

      const blobName = "projectListing_" + projectListingId?.split(":").pop() + "_" + file.name; // Specify a default blob name if needed

      let imagesTemp = images;
      const sizeImg = file.size / 1000000;

      let imgError = false;
      let limitError = false;
      let isValidImage = false;

      const formatError = isValid(file.name);
      const fileType = await checkFileSignature(file);
      
      if (fileType === 'png' || fileType === 'jpeg') {
        isValidImage = true;
      }
      
      if (sizeImg > 5 || !formatError || !isValidImage) {
        imgError = true;
      }

      const activeImages = imagesTemp.filter(img => img.blobStatus !== 2);

      if (activeImages.length + 1 >= 7) {
        imgError = true;
        limitError = true;
      }

      if (activeImages.length + 1 >= 6) {
         setIsBrowseButtonDisabled(true);
      }
      else{
        setIsBrowseButtonDisabled(false);
      }


      let img = {
        primaryBlobFlag: images.length === 0 ? true : false,
        blobUri: "",
        blobName: blobName,
        fileName: file.name,
        blobStatus: 1,
        blobSize: sizeImg,
        blobOrder: images.length + 1,
        imgError: imgError,
        limitError: limitError,
      }
      if (limitError) {
        setOpen({
          switch: true,
          message: t("developer.projects.file_upload.error_upload_banner"),
        });
      }
      imagesTemp.push(img);
      setImages([...imagesTemp]);

      if (!imgError && !limitError) {
        const blobClient = containerClient.getBlockBlobClient(blobName);  // get the blob client

        const check = await blobClient.uploadData(file, { blobHTTPHeaders: { blobContentType: file.type } }); // upload the image
        imagesTemp = images;

        imagesTemp[images.length - 1].blobUri = blobClient.url.substring(0, blobClient.url.indexOf('?'));;


        setImages([...imagesTemp]);
      }
    } catch (error) {

      let imagesTemp2 = images;
      imagesTemp2[images.length - 1].imgError = true;

      setImages([...imagesTemp2]);

    } finally {
      // setLoading(false); // Turn off loading
    }

  }

  async function handleAttachmentDrop(rawAttachments: File[]) {

    uploadAttachment(rawAttachments[0]);
  }

  async function handleClose(index: number) {
    setIsBrowseButtonDisabled(false);

    let imagesTemp = images;

    if (isDataExist && imagesTemp[index].blobStatus !== 1) {
      if (imagesTemp[index].primaryBlobFlag) {

        if (imagesTemp.length > 1) {
          if (index === 0) {
            imagesTemp[1].primaryBlobFlag = true;
          } else if (index === imagesTemp.length - 1) {
            imagesTemp[imagesTemp.length - 2].primaryBlobFlag = true;
          } else {
            if (imagesTemp[index + 1].blobStatus === 2) {
              let activeIndex = index - 1;
              for (let i = index; i < imagesTemp.length; i++) {
                if (imagesTemp[i].blobStatus === 1 && i !== index) {

                  activeIndex = i;
                  break;
                }
              }
              imagesTemp[activeIndex].primaryBlobFlag = true;
            } else {
              imagesTemp[index + 1].primaryBlobFlag = true;
            }
          }
        }
        imagesTemp[index].primaryBlobFlag = false;
      }
      imagesTemp[index].blobStatus = 2;
      imagesTemp[index].imgError = false;
    } else {
      if (images[index].primaryBlobFlag) {

        if (imagesTemp.length > 1) {
          if (index === 0) {
            imagesTemp[1].primaryBlobFlag = true;
          } else if (index === imagesTemp.length - 1) {
            imagesTemp[imagesTemp.length - 2].primaryBlobFlag = true;
          } else {

            if (imagesTemp[index + 1].blobStatus === 2) {
              const imagesTempFiltered = imagesTemp.filter(img => img.blobStatus !== 2);
              let activeIndex = index - 1;
              for (let i = index; i < imagesTemp.length; i++) {
                if (imagesTemp[i].blobStatus === 1 && i !== index) {
                  activeIndex = i;
                  break;
                } else {
                  activeIndex = imagesTempFiltered.length - 2;
                }
              }
              imagesTemp[activeIndex].primaryBlobFlag = true;
            } else {
              imagesTemp[index + 1].primaryBlobFlag = true;
            }

          }
        }
        imagesTemp[index].primaryBlobFlag = false;
      }
      imagesTemp[index].blobStatus = 2;
      imagesTemp[index].imgError = false;
    }


    if (isDataExist) {
      if (imagesTemp[index].blobUri.length === 0 && imagesTemp[index].blobStatus !== 1) {
        imagesTemp.splice(index, 1);
      }
      imagesTemp.forEach(function (img, i) {
        if (img.blobStatus == 2) {
          imagesTemp.push(imagesTemp[i]);
          imagesTemp.splice(i, 1);

        }
      });
      setImages([...imagesTemp]);
    } else {
      if (imagesTemp[index].blobUri.length === 0) {
        imagesTemp.splice(index, 1);
      } else {
        try {
          await containerClient.deleteBlob(images[index].blobName);
          imagesTemp.splice(index, 1);

        } catch (err) {

        }
      }

      setImages([...imagesTemp]);
    }
  }


  function handlePrimaryBlobFlag(index: number) {

    let imagesTemp = images;

    const currentIndex = imagesTemp.findIndex(img => img.primaryBlobFlag === true);
    if (currentIndex >= 0) {
      imagesTemp[currentIndex].primaryBlobFlag = false;
    }
    imagesTemp[index].primaryBlobFlag = true;

    setImages([...imagesTemp]);

  }

  function handleAttachmentInput(event: any) {
    uploadAttachment(event.target.files[0]);
  }

  function onInputClick(event: React.MouseEvent<HTMLInputElement, MouseEvent>) {
    const element = event.target as HTMLInputElement
    element.value = ''
  }

  const action = (
    <React.Fragment>

      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={() => setOpen({ ...open, switch: false })}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  return (
    <Formik
      initialValues={isDataExist ? apiData : initialValues}
      validationSchema={generalInfoSchema}
      validateOnChange={false}
      validateOnBlur={false}
      enableReinitialize={true}
      onSubmit={(values) => {
        const {
          province,
          projectName,
          city,
          projectDescription,
          ...restValues
        } = values;

        for (let i = 0; i < images.length; i++) {
          if ((images[i].imgError || images[i].blobUri.length === 0) && images[i].blobStatus !== 2) {
            return;
          }
        }

        const updatedValues = {
          type: "projectListing",
          projectListingID: projectListingId,
          developerID: userState.userId,
          emailAddress: userEmail,
          organizationName: userState.userOrganization,
          affordabilityThresholdFlag: isAffordabilitySubmit,
          province: province ? +province : "",
          projectName: projectName?.trim(),
          city: city?.trim(),
          projectDescription: projectDescription?.trim(),
          blobs: images,
          ...restValues,
        };
        isDataExist
          ? updateGeneralInformation(
            projectListingId,
            { id: projectListingId, ...updatedValues },
            setIsApiLoading,
            handleResponse,
            navigate
          )
          : submitGeneralInformation(
            updatedValues,
            setIsApiLoading,
            handleResponse,
            navigate
          );
      }}
    >
      {({
        values,
        errors,
      }) => (
        <Form className={styles.formElement} noValidate>
          <Snackbar
            className={styles.ccSnackbar}
            ContentProps={{
              sx: {
                background: "red"
              },
            }}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            open={open.switch}
            onClose={() => setOpen({ ...open, switch: false })}
            message={open.message}
            key={"top" + "center"}
            action={action}
          />
          <TextInput
            fieldName="projectName"
            labelText={t(
              "developer.projects.general_info_form.project_name_label"
            )}
            required
            data-testid="project-name"
          />

          <ProjectLocationFields province={values.province} isDataExist={isDataExist} />
          <ProjectDateFields />

          <TextArea
            fieldName="projectDescription"
            labelText={t(
              "developer.projects.general_info_form.project_desc_label"
            )}
            data-testid="project-description"
          />

          <h4 className={styles.formQuestion}>
            {t("developer.projects.file_upload.upload_images_general_info")}
          </h4>
          <p>{t("developer.projects.file_upload.upload_images_description")} </p>
          <br />
          <Dropzone onDrop={handleAttachmentDrop} disabled={isBrowseButtonDisabled}>
            {({ getRootProps, getInputProps, isDragActive }) => (
              <div
                {...getRootProps({
                  onClick: (e) => {
                    e.stopPropagation();
                  }
                })}
                className={isDragActive ? styles.uploadBoxDraged : styles.uploadBox}
              >
                <div className={styles.uploadContent}>
                  <div>
                    <img
                      className={styles.uploadIcon}

                      src={CloudUpload}
                      alt={"upload image"}
                    />
                  </div>
                  <div
                    className={styles.uploadDetails}
                  >
                    {t("developer.projects.file_upload.drop_images_project")}&nbsp;
                    <label className={ isBrowseButtonDisabled ? styles.browserBtnDisabled: styles.browser }>
                      <input
                        {...getInputProps()}
                        type="file"
                        id={`UploadFiles`}
                        aria-labelledby={`UploadFilesLabel`}
                        name="filename"
                        className="form__attachment-manual-upload"
                        onChange={(e) => handleAttachmentInput(e)}
                        onClick={onInputClick}
                        disabled={isBrowseButtonDisabled}
                      />
                      {t("developer.projects.file_upload.drop_images_browse")}
                    </label>
                    &nbsp;{t("developer.projects.file_upload.drop_images_your_files_project")}</div>
                  <div className={styles.uploadInstructions}>{t("developer.projects.file_upload.drop_images_description")}</div>
                  <div className={styles.uploadInstructions}>{t("developer.projects.file_upload.drop_images_description_individual_project")}</div>
                </div>
              </div>
            )}

          </Dropzone>

          {images?.map((item, idx) => (
            item.blobStatus !== 2 ?
              <div className={item.imgError ? styles.uploadCardError : styles.uploadCard} key={idx}>
                <div>
                  <input type="radio" id="1" name="1" value="1"
                    onChange={() => handlePrimaryBlobFlag(idx)}
                    checked={item.primaryBlobFlag}
                  />
                  <label>&nbsp;{t("developer.projects.file_upload.primary_image")}</label>
                </div>
                <div className={styles.imageContent}>
                  <div>
                    {item.blobUri.length === 0 ?
                      <img
                        className={styles.image}
                        src={item.imgError ? ErrorImage : UploadLoading}
                        alt={"upload"}
                      />
                      :
                      <img
                        className={styles.image}
                        src={item.blobUri.includes("quarantine") ? item.blobUri + sasToken : item.blobUri + sasTokenDocumentStorage}
                        alt={item.fileName}
                      />

                    }
                  </div>
                  <div className={styles.imagetitle}>
                    <p>{item.fileName} <span className={styles.imageSize}>{item.blobSize} MB</span></p>
                    {item.imgError && item.limitError ? <p> {t("developer.projects.file_upload.error_upload_limit")}</p> :
                      item.imgError ? <p>{t("developer.projects.file_upload.error_uploaded")}</p>
                        : item.blobUri.length === 0 ? <p className={styles.imageSize}>{t("developer.projects.file_upload.uploading")}</p>
                          : <p className={styles.imageSize}>{t("developer.projects.file_upload.uploaded")}</p>}
                  </div>
                  <div
                    onClick={() => handleClose(idx)}
                  >
                    {item.blobUri.length === 0 ?
                      !item.imgError ?
                        <CircularProgress />
                        :
                        <img
                          src={CloseIconSvg}
                          alt={"close icon"}
                        />
                      :
                      <img
                        src={CloseIconSvg}
                        alt={"close icon"}
                      />
                    }
                  </div>
                </div>
              </div>
              :
              <React.Fragment></React.Fragment>

          ))}


          <div className={styles.buttonContainer}>
            <button
              type="submit"
              className={styles.formButton}
              data-testid="submit-button"
              disabled={isApiLoading}
            >
              {t("developer.projects.general_info_form.btn_text")}
            </button>
          </div>
        </Form>
      )}

    </Formik>
  );
}
