import { PageContent } from "../../components/Layout/PageContent";
import { PageLayout } from "../../components/Layout/PageLayout";
import { Title } from "../../components/common/Title/Title";
import { useTranslation } from "react-i18next";
import { ConnectionHubPage } from "../../components/connectionHub/ConnectionHubPage";

export function ConnectionHub() {
  const { t } = useTranslation("connectionHub");
  return (
    <PageLayout>
      <Title
        titleText={t("ConnectionHub.title")}
        subtitle={t("ConnectionHub.subTitle")}
      />
      <PageContent>
          <ConnectionHubPage />
      </PageContent>
    </PageLayout>
  );
}
