import { PageLayout } from "../../components/Layout/PageLayout";
import styles from "./ErrorPage.module.scss";
import { SearchIcon } from "../../assets/icons/index";
import { PageContent } from "../../components/Layout/PageContent";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

export const PageNotFoundError = () => {
  const { t } = useTranslation("common");
  const navigate = useNavigate();
  const handleTryAgain = () => {
    navigate("/");
  };

  return (
    <PageLayout>
      <PageContent>
        <div className={styles.erorContainer}>
          <img
            data-testid="search"
            src={SearchIcon}
            alt={t("error_page_404.error_image_alt_text")}
          />
          <div className={styles.errorInfoContainer}>
            <h2 data-testid="error-heading" className={styles.heading}>
              {t("error_page_404.error_heading")}
            </h2>
            <p data-testid="error-content" className={styles.info}>
              {t("error_page_404.error_info")}
            </p>
          </div>
          <div>
            <button
              type="button"
              className={styles.formButton}
              data-testid="submit-button"
              onClick={handleTryAgain}
            >
              {t("error_page_404.return_home")}
            </button>
          </div>
        </div>
      </PageContent>
    </PageLayout>
  );
};
