import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";

import { PortfolioFinancialDetailsInterface } from "../../../TypesAndInterfaces/PortfolioDetailsInterface";
import styles from "./FinancialDetailsChart.module.scss";
import { numberWithCommas } from "../../../Constants/Utils/FormateNumber";

import { PieChart, Pie, Tooltip, Label, Cell } from "recharts";

type Props = {
  financialDetail: PortfolioFinancialDetailsInterface;
};

export function PortfolioFinancialDetailsChart({ financialDetail }: Props) {
  const { t } = useTranslation(["profile", "onboarding", "portfolio"]);
  const [totalFundingNeeded, setTotalFundingNeeded] = useState(0);
  const [width, setWidth] = useState(window.innerWidth);
  const [data, setData] = useState([{name: "",
      amount: 0,
      color: "#E6EFF3"}]
    );  

  useEffect(() => {
    let dataTemp = [
    {
      name: t(
        "portfolio:portfolio_details.financial_details.funding_types_chart.granting"
      ),
      amount: 0,
      color: "#009BB5",
    },
    {
      name: t(
        "portfolio:portfolio_details.financial_details.funding_types_chart.equity"
      ),
      amount: 0,
      color: "#7A64D8",
    },
    {
      name: t(
        "portfolio:portfolio_details.financial_details.funding_types_chart.debt_financing"
      ),
      amount: 0,
      color: "#812822",
    },
    {
      name: t(
        "portfolio:portfolio_details.financial_details.funding_types_chart.mezzanine_financing"
      ),
      amount: 0,
      color: "#DA291C",
    },
    {
      name: t(
        "portfolio:portfolio_details.financial_details.funding_types_chart.bridge_financing"
      ),
      amount: 0,
      color: "#ED948D",
    },
    {
      name: t(
        "portfolio:portfolio_details.financial_details.funding_types_chart.other_capital_outside_capital_connect"
      ),
      amount: 0,
      color: "#CCCCCC",
    },
    {
      name: t(
        "portfolio:portfolio_details.financial_details.funding_types_chart.capital_secured"
      ),
      amount: 0,
      color: "#E6EFF3",
    },
    {
      name: t(
        "portfolio:portfolio_details.financial_details.funding_types_chart.capital_needed"
      ),
      amount: 0,
      color: "#004F71",
    },
  ];
    if (financialDetail.displayFundingFlag === false) {
      if (financialDetail.totalFundingNeeded) {
        setTotalFundingNeeded(financialDetail.totalFundingNeeded);

        dataTemp[7].amount = financialDetail.totalFundingNeeded;
      }
      if (financialDetail.capitalAcquiredAmount) {
        dataTemp[6].amount = financialDetail.capitalAcquiredAmount;

        dataTemp[5].amount =
          financialDetail.totalPortfolioCost -
          //@ts-ignore
          financialDetail.totalFundingNeeded -
          financialDetail.capitalAcquiredAmount;
      }
    } else {
      if (financialDetail.grantAmount) {
        dataTemp[0].amount = financialDetail.grantAmount;
      }
      if (financialDetail.equityAmount) {
        dataTemp[1].amount = financialDetail.equityAmount;
      }
      if (financialDetail.debtFinancingAmount) {
        dataTemp[2].amount = financialDetail.debtFinancingAmount;
      }
      if (financialDetail.bridgeFinancingAmount) {
        dataTemp[4].amount = financialDetail.bridgeFinancingAmount;
      }
      if (financialDetail.capitalAcquiredAmount) {
        dataTemp[6].amount = financialDetail.capitalAcquiredAmount;

        dataTemp[5].amount =
          financialDetail.totalPortfolioCost -
          //@ts-ignore
          financialDetail.totalFundingNeeded -
          financialDetail.capitalAcquiredAmount;
      }

      if (financialDetail.totalFundingNeeded) {
        setTotalFundingNeeded(financialDetail.totalFundingNeeded);
      }
    }

    dataTemp = dataTemp.filter((temp) => temp.amount !== 0);

    setData(dataTemp);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);


  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className={styles.summaryContainer} data-testid="summary-Container">
      <h4 className={styles.chartTitle}>
        {t(
          "portfolio:portfolio_details.financial_details.funding_types_chart.total_portfolio_title"
        )}{" "}
        {"$" + numberWithCommas(String(totalFundingNeeded))}
      </h4>
      <div className={styles.chartList}>
        <div className={styles.chartPie}>
          <PieChart
            width={width < 960 ? 250 : 300}
            height={width < 960 ? 250 : 300}
          >
            <Tooltip formatter={(value) => `$${numberWithCommas(String(value))}`} />
            <Pie
              data={data}
              dataKey="amount"
              outerRadius={width < 960 ? 110 : 150}
              innerRadius={width < 960 ? 90 : 120}
            >
              {data.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={entry.color} />
              ))}

              <Label
                value={
                  t(
                    "portfolio:portfolio_details.financial_details.funding_types_chart.total_porfolio_cost_label"
                  ) +
                  " $" +
                  numberWithCommas(String(financialDetail.totalPortfolioCost))
                }
                position="center"
                width={width < 960 ? 150 : 200}
                className={styles.labelText}
              />
            </Pie>
          </PieChart>
        </div>
        <div className={styles.chartOptions}>
          {data.map((entry, index) => (
            <div key={`${entry.name}-${index}`} className={styles.options}>
              <div>
                <svg height="10" width="10">
                  <circle
                    cx="5"
                    cy="5"
                    r="4"
                    stroke="black"
                    stroke-width="1"
                    fill={entry.color}
                  />
                </svg>{" "}
                &nbsp;{entry.name}&nbsp;({"$" + numberWithCommas(String(entry.amount))})
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
