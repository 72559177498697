import styles from "./HowCCWorks.module.scss";
import { useTranslation } from "react-i18next";
import { Signup, Preferences, Connect, Share } from "../../../../assets/icons";
import { ColorDivider } from "../../../Resources/ColorDivider";

export function HowCapitalConnectWorks() {
  const { t } = useTranslation("landing");

  const easySteps = [
    {
      image: Signup,
      heading: t("capital_connect_works.sign_up"),
      body: t("capital_connect_works.sign_up_summary"),
    },
    {
      image: Preferences,
      heading: t("capital_connect_works.share_preferences"),
      body: t("capital_connect_works.share_preferences_summary"),
    },
    {
      image: Connect,
      heading: t("capital_connect_works.connect"),
      body: t("capital_connect_works.connect_summary"),
    },
    {
      image: Share,
      heading: t("capital_connect_works.share"),
      body: t("capital_connect_works.share_summary"),
    },
  ];

  return (
    <div className={styles.howItWorks}>
      <ColorDivider />

      <h3 className={styles.heading} data-testid="works-heading">
        {t("capital_connect_works.heading")}
      </h3>
      <p className={styles.text} data-testid="works-text">
        {t("capital_connect_works.sub_heading")}
      </p>

      <div className={styles.easySteps} data-testid="easy-steps">
        {easySteps.map((step, idx) => (
          <div key={idx} className={styles.stepContainer}>
            <img src={step.image} alt="" />
            <div className={styles.stepDescription}>
              <h4 className={styles.stepHeading}>{step.heading}</h4>
              <p className={styles.stepBody}>{step.body}</p>
            </div>
          </div>
        ))}
      </div>

      <ColorDivider />
    </div>
  );
}
