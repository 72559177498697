import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import styles from "./styles.module.scss";
import { Sad_Face } from "../../assets/images/index";
import Button from '@mui/material/Button';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { CreatePortfolio } from "../Developers/Modals/CreatePortfolio";
import { ErrorOutlined } from "../../assets/icons";
import { useUser } from "../../components/Context/UserContext";

interface Props {
  isLabelChanged?: boolean;
}

export const NoListedMyProjectsResults = ({ isLabelChanged }: Props) => {
  const { t } = useTranslation("filter");
  const navigate = useNavigate();
  const [createPortfolio1, setCreatePortfolio1] = useState<boolean>(false);
  const { userState } = useUser();

  return (
    <div className={styles.noInvestorsFound}>
      {
        isLabelChanged ?
          <>
            <img
              data-testid="crying-face"
              src={Sad_Face}
              alt={t("searchProjectsPage.noResultsImageAlt")}
            />
            <div>
              <h2 data-testid="no-investors-found-heading">
                {t("searchProjectsPage.noResultsTitleForSavedProject_portfolio")}

              </h2>
              <p data-testid="no-investors-found-heading">
                {t("searchProjectsPage.noResultTextForMyPortfolio")}
              </p>
              <Button
                className={!userState.isOnboarded ? styles.formButtonDisabled : styles.formButton}
                variant="contained"
                startIcon={<AddCircleOutlineIcon />} onClick={(e) => {
                  e.preventDefault();
                  const skipCreatePortfolioModal = localStorage.getItem("SkipCreatePortfolioModal");
                  if (userState.isOnboarded) {
                    if (skipCreatePortfolioModal === "1") {
                      navigate(`/portfolio/add`);
                    } else {
                      setCreatePortfolio1(true);
                    }
                  }
                }}
                sx={{ ml: 6 }}
              >
                {t("myProject.createPortfolio_buttonLabel")}
              </Button>

              {!userState.isOnboarded &&
                <div className={styles.onboardedContainer} >
                  <img
                    src={ErrorOutlined}
                    alt=""
                    className={styles.icon}
                  />
                  <p className={styles.para}>
                    {t("searchProjectsPage.notOnboardedUserPortfolio")}
                    <button className={styles.linkNotOnboarded}
                      onClick={(e) => {
                        e.preventDefault();
                        navigate("/onboarding/developer");
                      }}

                    >
                      {t("searchProjectsPage.completeProfile")}
                    </button>.
                  </p>
                </div>
              }

              {createPortfolio1 && (
                <CreatePortfolio
                  setCreatePortfolio={setCreatePortfolio1}
                />
              )}
            </div>
          </>
          :
          <>
            <img
              data-testid="crying-face"
              src={Sad_Face}
              alt={t("searchProjectsPage.noResultsImageAlt")}
            />
            <div>
              <h2 data-testid="no-investors-found-heading">
                {t("searchProjectsPage.noResultsTitleForMyProject")}
              </h2>
              <p data-testid="no-investors-found-heading">
                {t("searchProjectsPage.noResultsTextForMyProject")}
              </p>
              <Button
                className={!userState.isOnboarded ? styles.formButtonDisabled : styles.formButton}
                variant="contained"
                startIcon={<AddCircleOutlineIcon />} onClick={(e) => {
                  e.preventDefault();

                  if (userState.isOnboarded) {
                    navigate(`/projects/add`);
                  }

                }}


                sx={{ ml: 6 }}
              >
                {t("myProject.createProject_buttonLabel")}

              </Button>

              {!userState.isOnboarded &&
                <div className={styles.onboardedContainer} >
                  <img
                    src={ErrorOutlined}
                    alt=""
                    className={styles.icon}
                  />
                  <p className={styles.para}>
                    {t("searchProjectsPage.notOnboardedUserProject")}
                    <button className={styles.linkNotOnboarded}
                      onClick={(e) => {
                        e.preventDefault();
                        navigate("/onboarding/developer");
                      }}

                    >
                      {t("searchProjectsPage.completeProfile")}
                    </button>.
                  </p>
                </div>
              }

            </div>
          </>
      }

    </div>
  );
};
