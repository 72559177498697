import {
  UserAction,
  UserState,
} from "../../TypesAndInterfaces/UserContextInterface";

export function userReducer(userState: UserState, action: UserAction):UserState {
  switch (action.type) {
    case "setAzureData": {
      return {
        ...userState,
        userPersona: action.payload.personaData,
        onboardingStatus: action.payload.onboardingStatusData,
      };
    }
    case "setOptimisticOnboardedStatus": {
      return {
        ...userState,
        onboardingStatus: 3,
      };
    }
    case "setApiData": {
      return {
        ...userState,
        userId: action.payload.userIdData,
        userOrganization: action.payload.userOrganizationData,
        isOnboarded: action.payload.onboardingStatus,
        userOrganizationTypes: action.payload.userOrganizationTypes    
      };
    }
    case "setApiError": {
      return {
        ...userState,
        userId: "",
        userOrganization: "",
        isOnboarded: false,
        userOrganizationTypes: [],
      };
    }
    case "reset":
      return {
        userPersona: "",
        userId: "",
        userOrganization: "",
        isOnboarded: false,
        onboardingStatus: null,
        userOrganizationTypes: []
      };
    default: {
      throw new Error(`Unhandled action type.`);
    }
  }
}
