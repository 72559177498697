import { useState } from "react";
import ImageSlider from "./ImageSlider";
import styles from "./styles.module.scss";
import { useTranslation } from "react-i18next";

export function ImageGallery(props: any) {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);
  const { t } = useTranslation(["profile"]);

  const sasTokenDocumentStorage = process.env.REACT_APP_DOCUMENT_SAS_TOKEN;

  const openSlider = (index: number) => {
    setSelectedImageIndex(index);
    setIsOpen(true);
  };

  const closeSlider = () => {
    setIsOpen(false);
  };

  return (
    <div className={styles.gallery}>
      {props.images.map((image: any, index: number) => (
        <div key={index} className={styles.galleryItem} onClick={() => openSlider(index)}>
          <img src={image.blobUri + sasTokenDocumentStorage} alt={`Image ${index}`} className={styles.imageItem} />
          <div className={styles.imageLabel}>{`${t("profile:developer.projects.file_upload.view_image")} ${index + 1}`}</div>
        </div>
      ))}
      {isOpen && (
        <div className={styles.overlay}>
          <div className={styles.sliderContainer}>
            <ImageSlider images={props.images} startIndex={selectedImageIndex} closeBtn={closeSlider} isOpenModal={isOpen} isPortfolio={props.isPortfolio} />
          </div>
        </div>
      )}
    </div>
  );
}
