import { Tabs } from "@mui/material";
import Divider from "@mui/material/Divider";
import Tab from "@mui/material/Tab";
import { SyntheticEvent, useEffect, useState } from "react";
import { TabPanel } from "./TabPanel";
import styles from "./connectionHub.module.scss";
import { ConnectionCard } from "./ConnectionCard";
import { useTranslation } from "react-i18next";
import { ConnectionNotFound } from "../../pages/connectionHub/ConnectionError/ConnectionNotFound";
import { useUser } from "../Context/UserContext";
import { ConnectApi } from "../../APIAction/ConnectAPI";
import {
  DeveloperConnected,
  DeveloperConnectionAPI,
} from "../../TypesAndInterfaces/DeveloperDetailsInterface";
import { CONNECTION_STATUS_CODE } from "../../Constants/Enumerations";
import { SkeletonConnectionCard } from "./SkeletonConnectionCard";
import { AirPlane, HandShake, MailBox } from "../../assets/icons";

const sortTextLabel = "ConnectionHub.connectionTab.sortTextMessage";
export function ConnectionHubPage() {
  const { t } = useTranslation("connectionHub");
  const [connectionValue, setConnectionValue] = useState(window.location.pathname === "/newconnection" ? 1 : 0);
  const [statusCode, setStatusCode] = useState(1);

  const [connectedInvestors, setConnectedInvestors] =
    useState<DeveloperConnected[]>();
  const [newConnections, setNewConnections] = useState<DeveloperConnected[]>();
  const [pendingConnections, setPendingConnections] =
    useState<DeveloperConnected[]>();
  const { userState } = useUser();
  const [showSkeleton, setShowSkeleton] = useState(true);
  const NoConnectionsHeading = t("ConnectionHub.error_text.error_heading");
  const NoConnectionsText = userState.userPersona.toLowerCase() === "developer" ? t("ConnectionHub.error_text.errorMessage1") : t("ConnectionHub.error_text.investor.errorMessage1");
  const NoPendingConnectionHeading = t(
    "ConnectionHub.error_text.pending_connection_heading"
  );
  const NoPendingConnectionText =
    userState.userPersona.toLowerCase() === "developer"
      ? t(
        "ConnectionHub.error_text.pending_errorMessage"
      ) : t(
        "ConnectionHub.error_text.investor.pending_errorMessage"
      );

  const NoConnectionRequestsHeading = t(
    "ConnectionHub.error_text.request_connection_heading"
  );

  const NoConnectionRequestsText =
    userState.userPersona.toLowerCase() === "developer"
      ? t("ConnectionHub.error_text.request_errorMessage")
      : t("ConnectionHub.error_text.investor.request_errorMessage");

  const notification =
    userState.userPersona.toLowerCase() === "developer"
      ? t("ConnectionHub.error_text.errorNotes")
      : t("ConnectionHub.error_text.investor.errorNotes");

  const viewReceivedRequest =
    userState.userPersona.toLowerCase() === "developer" ?
      t("ConnectionHub.connectionTab.findInvestor")
      : t("ConnectionHub.connectionTab.investorRecievedRequest");

  const findInvestorPage = "/search/investors";
  const findProjectsRecivedReq = "/search/projects";
  const findProjects = userState.userPersona.toLowerCase() === "developer" ?
    findInvestorPage.replace(window.location.pathname, findInvestorPage) : 
    findProjectsRecivedReq.replace(window.location.pathname, findProjectsRecivedReq);
    localStorage.setItem("lastTabIndex", "0");
    localStorage.setItem("lastPage", "1");

  useEffect(() => {
    if (connectionValue === 0) {
      setStatusCode(CONNECTION_STATUS_CODE.Connected);
    }
    if (connectionValue === 1) {
      setStatusCode(CONNECTION_STATUS_CODE.NewConnection);
    }
    if (connectionValue === 2) {
      setStatusCode(CONNECTION_STATUS_CODE.Pending);
    }

    const connectionDataHandler = (
      rawData: any,
      statusCode: number
    ) => {
      let connectingData;
      if (
        statusCode !== 3 &&
        rawData.fromEntity.entityType === userState.userPersona.toLowerCase()
      ) {
        connectingData = rawData.toEntity;
      } else {
        connectingData = rawData.fromEntity;
      }

      return {
        connectionStatusCode: statusCode,
        entityType: connectingData.entityType,
        entityID: connectingData.entityID,
        projectListingID: rawData.project === null ? rawData.portfolio.portfolioID : rawData.project.projectListingID,
        organizationName: connectingData.organizationName,
        message: rawData.message,
        city: connectingData.city,
        province: connectingData.province,
        emailAddress: connectingData.emailAddress,
        phoneNumber: connectingData.phoneNumber,
        connectionId: rawData.id,
        projectName: rawData.project === null ? rawData.portfolio.portfolioName : rawData.project.projectName,
        projectListingStatus: rawData.project === null ? rawData.portfolio.portfolioStatus : rawData.project.projectListingStatus,
        isProject: rawData.project === null ? true: false,
        totalProjectCount: rawData.project === null ? rawData.portfolio?.totalProjectCount : ''
      };
    };

    function handleConnectionAPIData(
      apiData: DeveloperConnectionAPI[],
      statusCode: number
    ) {
      const dataArray: any[] = [];
      apiData.forEach((rawData: any) => {
        return dataArray.push(connectionDataHandler(rawData, statusCode));
      });

      switch (statusCode) {
        case CONNECTION_STATUS_CODE.Connected: {
          setConnectedInvestors(dataArray);
          return;
        }
        case CONNECTION_STATUS_CODE.Pending: {
          setPendingConnections(dataArray);
          return;
        }
        case CONNECTION_STATUS_CODE.NewConnection: {
          setNewConnections(dataArray);
          return;
        }
        default: {
          return;
        }
      }
    }

    async function fetchAPIData(apiInput: {
      entityID: string;
      entityType: string;
      connectionStatusCode: number;
    }) {
      try {
        setShowSkeleton(true);
        const connectionsData = await ConnectApi.postConnectSearchRequest(
          apiInput
        );
        setShowSkeleton(false);
        return handleConnectionAPIData(connectionsData.data, statusCode);
      } catch (error) {
        setShowSkeleton(false);
        return error;
      }
    }

    if (userState.isOnboarded) {
      fetchAPIData({
        entityID: userState.userId,
        entityType: userState.userPersona.toLowerCase(),
        connectionStatusCode: statusCode,
      });
    } else {
      setShowSkeleton(false);
    }
  }, [userState, connectionValue, statusCode]);



  const handleChange = async (event: SyntheticEvent, newValue: number) => {
    setConnectionValue(newValue);

  };

  const newConnectionHandler = (connectionid: string) => {
    if (newConnections) {
      const updatedNewConnections = newConnections.filter(
        (item: DeveloperConnected) => item.connectionId !== connectionid
      );
      setNewConnections(updatedNewConnections);
    }
  };

  function ConnectionProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  return (
    <div className={styles.tabConatainer}>
      <div className={styles.tabContainerHeading}>
        <Tabs
          value={connectionValue}
          onChange={handleChange}
          aria-label="connection tabs"
          data-testid="connection-tabs"
          variant="fullWidth"
        >
          <Tab
            sx={{ textTransform: "none" }}
            label={t("ConnectionHub.connectionTab.myConnectionHeading")}
            {...ConnectionProps(0)}
          />
          <Tab
            sx={{ textTransform: "none" }}
            label={t("ConnectionHub.connectionTab.newConnectionHeading")}
            {...ConnectionProps(1)}
          />
          <Tab
            sx={{ textTransform: "none" }}
            label={t("ConnectionHub.connectionTab.pendingConnectionHeading")}
            {...ConnectionProps(2)}
          />
        </Tabs>
      </div>
      <Divider />
      <div className={styles.sortTextStyles} data-testid="sort-text">
        {t(sortTextLabel)}
        {showSkeleton && <SkeletonConnectionCard />}
      </div>
      {!showSkeleton && (
        <>
          <TabPanel value={connectionValue} index={0} data-testid="tab-panel">
            {connectedInvestors && connectedInvestors.length > 0
              ? connectedInvestors.map((item, index) => (
                <ConnectionCard
                  key={`${item.organizationName}-${index}`}
                  connectionStatusCode={item.connectionStatusCode}
                  entityTypeText={item.entityType}
                  organizationNameText={item.organizationName}
                  organizationLink={item.entityID}
                  projectListingID={item.projectListingID}
                  messageText={item.message}
                  cityText={item.city}
                  province={item.province}
                  emailAddress={item.emailAddress}
                  phoneNumber={item.phoneNumber}
                  projectName={item.projectName}
                  projectListingStatus={item.projectListingStatus}
                  isProject={item.isProject}
                  totalProjectCount={item.totalProjectCount}
                />
              ))
              : !showSkeleton && (
                <ConnectionNotFound
                  heading={NoConnectionsHeading}
                  message={NoConnectionsText}
                  icon={HandShake}
                  buttonTextOne={t("ConnectionHub.connectionTab.developerRecievedRequest")}
                  buttonTextTwo={viewReceivedRequest}
                  linkTextOne={() => setConnectionValue(1)}
                  linkTextTwo={findProjects}
                />
              )}
          </TabPanel>
          <TabPanel value={connectionValue} index={1} data-testid="tab-panel">
            {newConnections && newConnections.length > 0
              ? newConnections
                .filter((ele) => ele.connectionStatusCode === 3)
                .map((item, index) => (
                  <ConnectionCard
                    key={`${item.organizationName}-${index}`}
                    connectionStatusCode={item.connectionStatusCode}
                    entityTypeText={item.entityType}
                    organizationNameText={item.organizationName}
                    organizationLink={item.entityID}
                    projectListingID={item.projectListingID}
                    messageText={item.message}
                    cityText={item.city}
                    province={item.province}
                    connectionID={item.connectionId}
                    projectName={item.projectName}
                    newConnectionHandler={newConnectionHandler}
                    projectListingStatus={item.projectListingStatus}
                    isProject={item.isProject}
                    totalProjectCount={item.totalProjectCount}
                  />
                ))
              : !showSkeleton && (
                <ConnectionNotFound
                  heading={NoConnectionRequestsHeading}
                  message={NoConnectionRequestsText}
                  notes={notification}
                  icon={MailBox}
                  buttonTextTwo={viewReceivedRequest}
                  linkTextTwo={findProjects}
                />
              )}
          </TabPanel>
          <TabPanel value={connectionValue} index={2} data-testid="tab-panel">
            {pendingConnections && pendingConnections.length > 0
              ? pendingConnections.map((item, index) => (
                <ConnectionCard
                  key={`${item.organizationName}-${index}`}
                  connectionStatusCode={item.connectionStatusCode}
                  entityTypeText={item.entityType}
                  organizationNameText={item.organizationName}
                  organizationLink={item.entityID}
                  projectListingID={item.projectListingID}
                  messageText={item.message}
                  cityText={item.city}
                  province={item.province}
                  projectName={item.projectName}
                  projectListingStatus={item.projectListingStatus}
                  isProject={item.isProject}
                  totalProjectCount={item.totalProjectCount}
                />
              ))
              : !showSkeleton && (
                <ConnectionNotFound
                  heading={NoPendingConnectionHeading}
                  message={NoPendingConnectionText}
                  notes={notification}
                  icon={AirPlane}
                  buttonTextTwo={viewReceivedRequest}
                  linkTextTwo={findProjects}
                />
              )}
          </TabPanel>
        </>
      )}
    </div>
  );
}
