import styles from "./AboutCapitalConnect.module.scss";
import { useTranslation } from "react-i18next";
import { Investors, Developers } from "../../../../assets/icons";

export function AboutCapitalConnect() {
  const { t } = useTranslation("landing");

  const profileHighlights = [
    {
      image: Investors,
      heading: t("landing:what_capital_connect.investor_heading"),
      body: t("landing:what_capital_connect.investor_content"),
    },
    {
      image: Developers,
      heading: t("landing:what_capital_connect.developers_heading"),
      body: t("landing:what_capital_connect.developers_content"),
    },
  ];

  return (
    <div className={styles.capitalConnect}>
      <div className={styles.summary}>
        <h3 className={styles.heading} data-testid="what-capital-connect">
          {t("landing:what_capital_connect.heading")}
        </h3>
        <p className={styles.text} data-testid="capital-connect-text">
          {t("landing:what_capital_connect.sub_heading")}
        </p>
      </div>

      <div className={styles.highlights} data-testid="circular-highlight">
        {profileHighlights.map((profile, idx) => (
          <div key={idx} className={styles.circularContainer}>
            <img className={styles.highlightImage} src={profile.image} alt="" />
            <h3 className={styles.highlightHeading}>{profile.heading}</h3>
            <p className={styles.highlightBody}>{profile.body}</p>
          </div>
        ))}
      </div>
    </div>
  );
}
