import styles from "./styles.module.scss";
import { Form, Formik } from "formik";
import { useTranslation } from "react-i18next";
import { AddProjectProps, ProjImpactAreaInfoI } from "./AddProjectProps";
import React, { useEffect, useState } from "react";

import { ListingProjectAPI } from "../../../APIAction/ListingProjectAPI";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Dropzone from 'react-dropzone';
import { BlobServiceClient } from '@azure/storage-blob';
import { CloudUpload, CloseIconSvg } from "../../../assets/icons";
import { UploadLoading, ErrorImage } from "../../../assets/images";
import CircularProgress from '@mui/material/CircularProgress';
import { PDFCover } from "../../../assets/images";
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { checkFileSignature } from "../../../Constants/mimeTypeFunction";

export function ProjectDocumentationForm({
  projectListingId,
  handleResponse,
}: AddProjectProps) {
  const { t } = useTranslation("profile");

  const [docs, setDocs] = useState<any[]>([]);

  const [open, setOpen] = useState({ switch: false, message: "success" });

  const [isApiLoading, setIsApiLoading] = useState(false);
  const [isDataExist, setIsDataExist] = useState(false);
  const [isBrowseDisabled, setIsBrowseDisabled] = useState(false);

  const navigate = useNavigate();
  const apiErrorPage = "/api-error";


  const account = process.env.REACT_APP_QUARANTINE_STORAGE_ACCOUNT_NAME;

  const sasToken = process.env.REACT_APP_QUARANTINE_SAS_TOKEN;

  const containerName = process.env.REACT_APP_QUARANTINE_CONTAINER_NAME;
  const blobServiceClient = new BlobServiceClient(`https://${account}.blob.core.windows.net/${sasToken}`);
  //@ts-ignore
  const containerClient = blobServiceClient.getContainerClient(containerName);
  const initialValues = {

  };


  useEffect(() => {

    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);



  const getDocumentationInfomation = async (id: string) => {
    try {
      const res = await ListingProjectAPI.getDocumentationInfoById(
        id
      );
      const data = res.data;

      setIsDataExist(true);
      setDocs(data.blobs);
      if(data.blobs != null && data.blobs.length >= 6)
        {
          setIsBrowseDisabled(true);
        }
    } catch (error) {
      setIsDataExist(false);
      if (axios.isAxiosError(error) && error.response?.status !== 404) {
        navigate("/api-error");
      }
    }


  };

  useEffect(() => {
    projectListingId && getDocumentationInfomation(projectListingId);
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectListingId, navigate]);

  async function submitDocumentationInfo(formValues: Object) {
    try {
      setIsApiLoading(true);
      await ListingProjectAPI.postDocumentationInfo(formValues);
      setIsApiLoading(false);
      handleResponse("Documentation", true);
    } catch (error) {
      setIsApiLoading(false);
      handleResponse("Documentation", false);

      if (axios.isAxiosError(error) && error.response?.status !== 404) {
        navigate(apiErrorPage);
      }
    }
  }

  async function updateDocumentationInfo(formValues: Object) {
    if (!projectListingId) {
      return;
    }

    try {
      setIsApiLoading(true);
      await ListingProjectAPI.updateDocumentationInfo(
        projectListingId,
        { ...formValues }
      );
      setIsApiLoading(false);
      handleResponse("Documentation", true);
    } catch (error) {
      setIsApiLoading(false);
      handleResponse("Documentation", false);

      if (axios.isAxiosError(error) && error.response?.status !== 404) {
        navigate(apiErrorPage);
      }
    }
  }

  const onSubmit = (values: any) => {
    for (let i = 0; i < docs.length; i++) {
      if ((docs[i].imgError || docs[i].blobUri.length === 0) && docs[i].blobStatus !== 2) {
        return;
      }
    }
    let selectedDocs = docs;

    // Create the payload
    const payload = {
      projectListingID: projectListingId,
      blobs: selectedDocs,

    };

    isDataExist
      ? updateDocumentationInfo(payload)
      : submitDocumentationInfo(payload);
  };

  const apiData = {

  };

  function isValid(fileName: string) {

    return (new RegExp('(' + ['.pdf', '.PDF'].join('|').replace(/\./g, '\\.') + ')$')).test(fileName);
  }

  async function uploadAttachment(file: any) {
    if (!file) {  // check if the file is selected
      return;
    }

    if (!account || !sasToken || !containerName) {  // check if the credentials are set
      alert('Please make sure you have set the Azure Storage credentials in the .env file');
      return;
    }

    const existingfile = docs.filter(doc => (doc.blobStatus !== 2 && doc.fileName === file.name));

    if (docs.length !== 0 && (docs[docs.length - 1].imgError || existingfile.length !== 0)) {
      return;
    }

    try {

      const blobName = "projectListing_" + projectListingId?.split(":").pop() + "_" + file.name; // Specify a default blob name if needed

      let docsTemp = docs;
      const sizeDoc = file.size / 1000000;

      let docError = false;
      let limitError = false;
      let isValidFile = false;

      const formatError = isValid(file.name);
      const fileType = await checkFileSignature(file);
      
      if (fileType === 'pdf') {
        isValidFile = true;
      }

      if (sizeDoc > 5 || !formatError || !isValidFile) {

        docError = true;
      }

      const activeDocs = docsTemp.filter(doc => doc.blobStatus !== 2);

      if (activeDocs.length + 1 >= 7) {
        docError = true;
        limitError = true;
      }
      if (activeDocs.length + 1 >= 6) {
        setIsBrowseDisabled(true);
      }
      else{
        setIsBrowseDisabled(false);
      }


      let doc = {
        blobUri: "",
        blobName: "projectListing_" + projectListingId?.split(":").pop() + "_" + file.name,
        fileName: file.name,
        blobStatus: 1,
        blobSize: sizeDoc,
        blobOrder: docs.length + 1,
        docError: docError,
        limitError: limitError,
      }
      if (limitError) {
        setOpen({
          switch: true,
          message: t("developer.projects.file_upload.error_upload_banner"),
        });
      }

      docsTemp.push(doc);
      setDocs([...docsTemp]);


      if (!docError && !limitError) {
        const blobClient = containerClient.getBlockBlobClient(blobName);  // get the blob client

        const check = await blobClient.uploadData(file, { blobHTTPHeaders: { blobContentType: file.type } }); // upload the image
        docsTemp = docs;
        // imagesTemp[images.length - 1].blobUri = blobClient.url;
        docsTemp[docs.length - 1].blobUri = blobClient.url.substring(0, blobClient.url.indexOf('?'));

        setDocs([...docsTemp]);

        //await fetchDocs(blobName);   // fetch all images again after the upload is completed
      }
    } catch (error) {

      let docsTemp2 = docs;
      docsTemp2[docs.length - 1].docError = true;
      setDocs([...docsTemp2]);

    } finally {
      // setLoading(false); // Turn off loading
    }

  }

  async function handleAttachmentDrop(rawAttachments: File[]) {

    uploadAttachment(rawAttachments[0]);
  }


  async function handleClose(index: number) {
    let docsTemp = docs;
    setIsBrowseDisabled(false);

    if (isDataExist && docsTemp[index].blobStatus !== 1) {
      docsTemp[index].blobStatus = 2;
      docsTemp[index].docError = false;
      if (docsTemp[index].blobUri.length === 0) {
        docsTemp.splice(index, 1);
      }
    } else {
      docsTemp[index].docError = false;
      if (docsTemp[index].blobUri.length === 0) {
        docsTemp.splice(index, 1);
      } else {
        try {
          await containerClient.deleteBlob(docsTemp[index].blobName);
          docsTemp.splice(index, 1);

        } catch (err) {

        }
      }
    }
    docsTemp.forEach(function (doc, i) {
      if (doc.blobStatus == 2) {
        docsTemp.push(docsTemp[i]);
        docsTemp.splice(i, 1);
      }
    });
    setDocs([...docsTemp]);
  }


  function handleAttachmentInput(event: any) {

    uploadAttachment(event.target.files[0]);

  }

  function onInputClick(event: React.MouseEvent<HTMLInputElement, MouseEvent>) {
    const element = event.target as HTMLInputElement
    element.value = ''
  }

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={() => setOpen({ ...open, switch: false })}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  return (
    <Formik
      initialValues={isDataExist ? apiData : initialValues}
      validateOnChange={false}
      validateOnBlur={false}
      enableReinitialize={true}
      onSubmit={onSubmit}
    >
      <Form className={styles.formElement} noValidate>
        <Snackbar
          className={styles.ccSnackbar}
          ContentProps={{
            sx: {
              background: "red"
            },
          }}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={open.switch}
          onClose={() => setOpen({ ...open, switch: false })}
          message={open.message}
          key={"top" + "center"}
          action={action}
        />
        <h4 className={styles.formQuestion}>
          {t("developer.projects.file_upload_doc.upload_files_label")}
        </h4>
        <Dropzone onDrop={handleAttachmentDrop} disabled={isBrowseDisabled}>
          {({ getRootProps, getInputProps, isDragActive }) => (
            <div
              {...getRootProps({
                onClick: (e) => {
                  e.stopPropagation();
                }
              })}
              className={isDragActive ? styles.uploadBoxDraged : styles.uploadBox}

            >
              <div className={styles.uploadContent}>
                <div>
                  <img
                    className={styles.uploadIcon}

                    src={CloudUpload}
                    alt={"upload Doc"}
                  />
                </div>
                <div
                  className={styles.uploadDetails}
                >
                  {t("developer.projects.file_upload_doc.drop_files")}&nbsp;
                  <label className={ isBrowseDisabled ? styles.browserBtnDisabled: styles.browser}>
                    <input
                      {...getInputProps()}
                      type="file"
                      id={`UploadFiles`}
                      aria-labelledby={`UploadFilesLabel`}
                      name="filename"
                      className="form__attachment-manual-upload"
                      onChange={handleAttachmentInput}
                      onClick={onInputClick}
                      disabled={isBrowseDisabled}
                    />
                    {t("developer.projects.file_upload_doc.drop_files_browse")}
                  </label>
                  &nbsp;{t("developer.projects.file_upload_doc.drop_files_your_files")}</div>
                <div className={styles.uploadInstructions}>{t("developer.projects.file_upload_doc.drop_files_description")}</div>
                <div className={styles.uploadInstructions}>{t("developer.projects.file_upload_doc.drop_files_description_individual")}</div>
              </div>
            </div>
          )}

        </Dropzone>

        {docs?.map((item, idx) => (
          item.blobStatus !== 2 ?

            <div className={item.docError ? styles.uploadCardError : styles.uploadCard} key={idx}>
              <div className={styles.imageContent}>
                <div>
                  {item.blobUri.length === 0 ?
                    <img
                      className={styles.image}
                      src={item.docError ? ErrorImage : UploadLoading}
                      alt={"upload"}
                    />
                    :
                    <img
                      className={styles.image}
                      src={PDFCover}
                      alt={item.fileName}
                    />

                  }
                </div>
                <div className={styles.imagetitle}>
                  <p>{item.fileName} <span className={styles.imageSize}>{item.blobSize} MB</span></p>
                  {item.docError && item.limitError 
                    ? <p>{t("developer.projects.file_upload.error_upload_limit")}</p>
                    : item.docError 
                      ? <p>{t("developer.projects.file_upload.error_uploaded_file")}</p>
                      : item.blobUri.length === 0 
                        ? <p className={styles.imageSize}>{t("developer.projects.file_upload.uploading")}</p>
                        : <p className={styles.imageSize}>{t("developer.projects.file_upload.uploaded")}</p>}
                </div>
                <div
                  onClick={() => handleClose(idx)}
                >
                  {item.blobUri.length === 0 ?
                    !item.docError ?
                      <CircularProgress /> :
                      <img
                        src={CloseIconSvg}
                        alt={"close icon"}
                      />
                    :
                    <img
                      src={CloseIconSvg}
                      alt={"close icon"}
                    />
                  }
                </div>
              </div>
            </div> :
            <React.Fragment></React.Fragment>
        ))}

        <div className={styles.noteContainerDocumentation}>
          <h2 className={styles.noteHeading}>
            {t("developer.projects.note.heading_2")}
          </h2>
          <ul className={styles.noteList}>
            <li className={styles.noteListItem}>
              <p>
                {t("developer.projects.file_upload_doc.doc_note")}
              </p>
            </li>
          </ul>
        </div>
        <div className={styles.buttonContainer}>
          <button
            type="submit"
            className={styles.formButton}
            data-testid="submit-button"
            disabled={isApiLoading}
          >
            {t("developer.projects.impact_criteria_form.btn_text")}
          </button>
        </div>
      </Form>
    </Formik>
  );
}
