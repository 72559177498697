import { useTranslation } from "react-i18next";
import styles from "./styles.module.scss";
import { Sad_Face } from "../../../assets/images/index";

export const NoProjectFound = () => {
  const { t } = useTranslation("profile");

  return (
    <div className={styles.noProjectFound}>
      <img
        data-testid="happy-face"
        src={Sad_Face}
        alt={t("developer.projects.no_project.sad-icon")}
      />

      <div className={styles.noProjectContainer}>
        <h2 data-testid="no-project-list-heading" className={styles.heading}>
          {t("developer.projects.no_project.heading")}
        </h2>
        <p data-testid="no-project-list-content" className={styles.info}>
          {t("developer.projects.no_project.content1")}
        </p>
      </div>
    </div>
  );
};
