import { Avatar } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import {
  currencyFormat,
  DOLLAR_RANGE,
  FUNDING_TYPES_ONBOARDING,
  getEnumKeyByValue,
  getKVArrayFormat,
  INVESTMENT_PHASES_ONBOARDING,
  LOCKUP_PERIOD,
  PROVINCES,
  ROI_RANGE,
} from "../../../Constants/Enumerations";
import { InvestorDetails } from "../../../TypesAndInterfaces/InvestorDetailsInterface";
import { IterableFinancialDetails } from "./IterableFinancialDetails";
import { IterableValuesDisplay } from "./IterableValuesDisplay";
import styles from "./styles.module.scss";

type Props = {
  data: InvestorDetails;
};

export function InvestorCard({ data }: Props) {
  const { t } = useTranslation(["common", "cards", "onboarding"]);

  const provinceFocusAreaMappedValue =
    data.investmentPreference.investmentRegions.map((val) =>
      getEnumKeyByValue(PROVINCES, val.province)
    );

 
  const provinceFocusAreaTranslated = provinceFocusAreaMappedValue.map((val) =>
    t(`common:enumerations.provinces.${val}`)
  ).filter((val: any,id: any,array: string | any[]) => array.indexOf(val) === id).sort();

  const projectStagesMappedValues =
    data.investmentPreference.investmentPhases.map((val) =>
      getEnumKeyByValue(INVESTMENT_PHASES_ONBOARDING, val)
    );
  const projectStagesTranslated = projectStagesMappedValues.map((val) =>
    t(`common:enumerations.investment_phases_onboarding.${val}`)
  );

  const fundingTypesMappedValues = data.investmentPreference.fundingTypes.map(
    (val) => getEnumKeyByValue(FUNDING_TYPES_ONBOARDING, val)
  );
  const fundingTypesTranslated = fundingTypesMappedValues.filter(val => val !== undefined && val !== "").map((val) =>
    t(`common:enumerations.funding_types_onboarding.${val}`)
  );

  const targetRoiMaxMappedValue = getEnumKeyByValue(
    ROI_RANGE,
    data.investmentPreference.roiTargetMaximum
  );
  const targetRoiMinMappedValue = getEnumKeyByValue(
    ROI_RANGE,
    data.investmentPreference.roiTargetMinimum
  );

  const targeRoiMinTranslated = t(
    `common:enumerations.roi_range.${targetRoiMinMappedValue}`
  );
  const targeRoiMaxTranslated = t(
    `common:enumerations.roi_range.${targetRoiMaxMappedValue}`
  );

  const targeRoiTranslated = `${targeRoiMinTranslated}-${targeRoiMaxTranslated}`;

  const lockUpPeriodMappedValue = getEnumKeyByValue(
    LOCKUP_PERIOD,
    data.investmentPreference.maximumLockupPeriod
  );
  const lockUpPeriodTranslated = t(
    `common:enumerations.lockup_period.${lockUpPeriodMappedValue}`
  );

  const dolllarRangesTrans = Object.keys(DOLLAR_RANGE).map((val) =>
    t(`common:enumerations.dollar_range.${val}`)
  );
  const dollarOptions = getKVArrayFormat(DOLLAR_RANGE, dolllarRangesTrans).map(
    ({ label, value }) => ({ label: label, value: value })
  );

  const investorProfileLink = "/profile/investor/" + data.id;

  const financingRangeToDisplay = `${currencyFormat(
    dollarOptions,
    data.investmentInformation.investmentDollarMinimum
  )} - ${currencyFormat(
    dollarOptions,
    data.investmentInformation.investmentDollarMaximum
  )}`;

  function renderYesOrNoOptions(truthyValue: boolean) {
    if (truthyValue) {
      return t("onboarding:developer.orgnization_info_form.org_info.yes_label");
    }
    return t("onboarding:developer.orgnization_info_form.org_info.no_label");
  }

  const financialDetails = [
    {
      label: t("cards:investor_general_range_label"),
      value: financingRangeToDisplay,
    },
    { label: t("cards:investor_range_roi_label"), value: targeRoiTranslated },
    {
      label: t("cards:investor_target_lock_label"),
      value: lockUpPeriodTranslated,
    },
    {
      label: t("cards:investor_Offering_Land"),
      value:  renderYesOrNoOptions(data.investmentPreference.ableToProvideLandFlag)
    },
  ];

  return (
    <div className={styles.cardContainer}>
      <div className={styles.investorSummary}>
        <Avatar className={styles.avatarStyles} data-testid="inv-avatar">
          {data.generalInformation.organizationName[0]}
        </Avatar>
        <Link
          to={investorProfileLink}
          className={styles.cardDetailLink}
          data-testid="org-name"
        >
          {data.generalInformation.organizationName}
        </Link>
        <p className={styles.regionFocus}>{t("cards:focus_area_label")}</p>
        <div
          className={styles.iterablesRegionsContainer}
          data-testid="regions-pref"
        >
          {provinceFocusAreaTranslated.map((val, idx) => (
            <p key={idx} className={styles.iterableRegions}>
              {`${val}${
                idx < provinceFocusAreaTranslated.length - 1 ? "," : ""
              }`}
            </p>
          ))}
        </div>
      </div>

      <div className={styles.preferenceContainer} data-testid="pref-container">
        <IterableValuesDisplay
          dataLabel={t("cards:investor_prefer_label")}
          arrayOfValues={projectStagesTranslated}
        />

        <IterableValuesDisplay
          dataLabel={t("cards:investor_funding_label")}
          arrayOfValues={fundingTypesTranslated}
        />

        <IterableFinancialDetails financialDetails={financialDetails} />
      </div>
    </div>
  );
}
