import { useState } from 'react';
import styles from './styles.module.scss'
import { useTranslation } from 'react-i18next';
import { chipsProps, ImpactData } from './types';
import { isNotEmpty } from '../../../Constants/basicFunctions';

export const Chips = (props: chipsProps) => {
    const { chipData } = props;
    const [expanded, setExpanded] = useState(false);
    const chipLabels = expanded ? chipData : chipData.slice(0, 5);
    const { t } = useTranslation('common');
    const showText = t("showMoreLessText.show");
    const lessText = t("showMoreLessText.less");
    const moreText = t("showMoreLessText.more");

    return (
        <div className={styles.chipContainer}>
            {isNotEmpty(chipLabels) && chipLabels.map((value: ImpactData, index: number) => (
                <p className={styles.chip} key={index} data-testid={`impactName${value.impactOrder}`}>{value.impactName}</p>
            ))
            }
            {chipData.length > 5 && <button type="button" data-testid="show-moreless-btn" className={styles.showMoreLessLink}
                onClick={() => setExpanded(!expanded)}>
                {expanded ? showText + ' ' + lessText :
                    showText + ' ' + (chipData.length - 5) + ' ' + moreText}
            </button>
            }
        </div>

    )
}
