import { LayoutProps } from "./TwoColumnLayout.types";
import styles from "./styles.module.scss";
import { OpenInNew } from "@mui/icons-material";

export function TwoColumnLayout({
  title,
  column1,
  column2,
  externalLink,
  linkText,
}: LayoutProps) {
  return (
    <div
      className={styles.aboutUsContainer}
      data-testid="two-column-layout">
      <h2 className={styles.title} data-testid="two-column-layout-title">
        {title}
      </h2>

      <div className={styles.columnsContainer}>
        <p className={styles.column1} data-testid="two-column-layout-col1">
          {column1}
        </p>
        <p className={styles.column2} data-testid="two-column-layout-col2">
          {column2}
          {externalLink && (
            <a
              href={externalLink}
              className={styles.externalUrl}
              target="_blank"
              rel="noopener noreferrer"
              data-testid="two-column-layout-external-link"
            >
              {linkText}
              <OpenInNew sx={{ height: "0.94rem", widht: "0.94rem" }} />
            </a>
          )}
        </p>
      </div>
    </div>
  );
}
