import { useTranslation } from "react-i18next";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { PageLayout } from "../../../components/Layout/PageLayout";
import styles from "./InvestorConfirmation.module.scss";
import { Happy_Face } from "../../../assets/images/index";
import { PageContent } from "../../../components/Layout/PageContent";

export const InvestorConfirmation = () => {
  const { t } = useTranslation("onboarding");
  const location = useLocation();
  const navigate = useNavigate();
  const isEditProfileFromViewPage = location.pathname.includes("profile");
  const learnMoreLinks = [
    { text: t("investor.success.link_Project"), path: "/search/projects" },
    { text: t("investor.success.link_profile"), path: "/profile/investor" },
  ];

  return (
    <PageLayout>
      <PageContent>
        <div className={styles.confirmationContainer}>
          <img
            data-testid="happy-face"
            src={Happy_Face}
            alt={t("investor.success.smileyalt-icon")}
          />

          <div className={styles.successInfoContainer}>
            <h2 data-testid="confirmation-heading" className={styles.heading}>
              {isEditProfileFromViewPage
                ? t("investor.success.heading_update")
                : t("investor.success.heading")}
            </h2>
            <p data-testid="confirmation-content" className={styles.info}>
              {t(
                isEditProfileFromViewPage
                  ? "investor.success.content_update"
                  : "investor.success.content"
              )}
            </p>
          </div>

          <div className={styles.successLinkList} data-testid="success-links">
            {learnMoreLinks.map((link, idx) => (
              <Link key={idx} to={link.path} className={styles.successInfoLink} onClick={() => {
                if (link.path === "/search/projects") {
                  navigate("/search/projects");
                  localStorage.setItem("lastTabIndex", "0");
                  localStorage.setItem("lastPage", "1");
                }
              }}>
                {link.text}
              </Link>
            ))}
          </div>
        </div>
      </PageContent>
    </PageLayout>
  );
};
