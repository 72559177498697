import { useTranslation } from "react-i18next";
import React, { useState, useEffect } from "react";
import styles from "./styles.module.scss";
import { GeneralInfoIcon } from "../../../assets/icons";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { ListingProjectAPI } from "../../../APIAction/ListingProjectAPI";
import {
  getEnumKeyByValue,
  getImageOptions,
  IMAGE_TYPES,
  PROVINCES,
} from "../../../Constants/Enumerations";
import {
  ProjectGeneralInfo,
  ReviewProjectProps,
} from "../../../TypesAndInterfaces/ProjectDetailsInterface";

export const ReviewProjectGeneralInfo = ({
  projectListingID,
}: ReviewProjectProps) => {
  const { t } = useTranslation("profile");
  const navigate = useNavigate();

  const [GeneralInfo, setGeneralInfo] = useState<ProjectGeneralInfo>();
  const [isDataExist, setIsDataExist] = useState(false);
  const sasTokenDocumentStorage = process.env.REACT_APP_DOCUMENT_SAS_TOKEN;

  useEffect(() => {
    const getGeneralInfomation = async () => {
      try {
        const res = await ListingProjectAPI.getProjectGeneralInfoById(
          projectListingID
        );
        const data = res.data;
        setIsDataExist(true);
        setGeneralInfo(data);
      } catch (error) {
        setIsDataExist(false);
        if (axios.isAxiosError(error) && error.response?.status !== 404) {
          navigate("/api-error");
        }
      }
    };
    projectListingID !== "" && getGeneralInfomation();
  }, [projectListingID, navigate]);

  function setProvinceText() {
    if (!GeneralInfo) {
      return "";
    }

    const provinceMappedValue = getEnumKeyByValue(
      PROVINCES,
      GeneralInfo.province
    );

    return t(`common:enumerations.provinces.${provinceMappedValue}`);
  }

  const handleEditClick = () => {
    navigate(`/projects/edit/${projectListingID}`);
  };

  const imagesAltTextTranslated = Object.keys(IMAGE_TYPES).map((val) =>
    t(`common:enumerations.image_alt_text.${val}`)
  );
  const projectBannersOptions = getImageOptions(
    IMAGE_TYPES,
    imagesAltTextTranslated
  );
  const selectedImage = projectBannersOptions.find(
    (banner) => banner.value === GeneralInfo?.projectBannerCode
  );

  return (
    <div className={styles.informationContainer}>
      <div className={styles.infoHeadingContainer}>
        <div className={styles.left}>
          <div className={styles.imgContainer}>
            <img src={GeneralInfoIcon} alt="" />
          </div>
          <h3 className={styles.sectionHeading}>
            {t("developer.projects.general_info_form.heading")}
          </h3>
        </div>
        <button className={styles.link} onClick={handleEditClick}>
          {t("developer.projects.review_project.edit_link")}
        </button>
      </div>
      {isDataExist && (
        <div className={styles.content}>
          <div className={styles.grid_container}>
            <p>
              {t("developer.projects.general_info_form.project_name_label")}
            </p>
            <p>{GeneralInfo?.projectName}</p>
            <p>{t("developer.projects.general_info_form.province_label")}</p>
            <p> {setProvinceText()} </p>
            <p>{t("developer.projects.general_info_form.city_label")}</p>
            <p> {GeneralInfo?.city} </p>
            <p>{t("developer.projects.general_info_form.start_date_label")}</p>

            <p>{GeneralInfo?.targetProjectStartDate}</p>
            <p>{t("developer.projects.general_info_form.end_date_label")}</p>
            <p>{GeneralInfo?.targetProjectCompleteDate}</p>
            <p>
              {t("developer.projects.general_info_form.project_desc_label")}
            </p>
            <p>{GeneralInfo?.projectDescription}</p>
            {GeneralInfo?.blobs && GeneralInfo?.blobs.length !== 0 &&
              <React.Fragment>
                <p>
                  {t("developer.projects.file_upload.images")}
                </p>
                <p>
                  {GeneralInfo?.blobs?.map((item, idx) => (
                    <div className={styles.uploadCard} key={idx}>
                      <div className={styles.imageContent}>
                        <div>
                          <img
                            className={styles.image}
                            src={item.blobUri + sasTokenDocumentStorage}
                            alt={item.fileName}
                          />
                        </div>
                        <div className={styles.imagetitle}>
                          <p>{item.fileName} </p>
                          <span className={styles.imageSize}> {item.fileName.split('.').pop()} | {item.blobSize} MB</span>
                        </div>
                        <div className={styles.primaryTag}>
                          {item.primaryBlobFlag ? t("developer.projects.file_upload.primary") : ""}
                        </div>
                      </div>
                    </div>
                  ))}
                </p>
              </React.Fragment>
            }
          </div>
        </div>
      )}
    </div>
  );
};
