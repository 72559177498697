import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import styles from "./styles.module.scss";

type Props = {
  formIconSrc: string;
  formIconAlt: string;
  formHeading: string;
  formInfoSummary: string;
  formElement: ReactNode;
  disabled: boolean;
  index: number;
  handleChange: (idValue: number) => void;
  selectedAccordion: number;
  isExpanded: boolean;
  showEditLink: boolean;
};

export function FormAccordion(props: Props) {
  const { t } = useTranslation("common");
  const handleChange = props.handleChange;
  const infoSummaryList = props.formInfoSummary.split("\n");
  const [heading, ...listItem] = infoSummaryList;

  function createListItem() {
    if (infoSummaryList.length > 1) {
      return (
        <div className={styles.info}>
          <p>{heading}</p>
          <ul className={styles.noteList}>
            {listItem.map((info, index) => {
              return <li key={index}>{info} </li>;
            })}
          </ul>
        </div>
      );
    }
    return null;
  }

  return (
    <Accordion
      className={styles.accordion}
      disabled={props.disabled}
      style={{ backgroundColor: "#FFFFFF" }}
      onChange={() => handleChange(props.index)}
      expanded={props.index === props.selectedAccordion && props.isExpanded}
    >
      <AccordionSummary
        aria-controls="panel-content"
        id="panel-header"
        className={styles.accordionSummary}
      >
        <div className={styles.accordionImgContainer}>
          <img src={props.formIconSrc} alt={props.formIconAlt} />
        </div>
        <h3
          className={styles.accordionHeading}
          style={{ color: props.disabled ? "#4A4A4A" : "#282828" }}
        >
          {props.formHeading}
        </h3>
        {props.showEditLink && (
          <button type="button" className={styles.actionLinkStyle}>
            {t("form.edit")}
          </button>
        )}
      </AccordionSummary>
      {infoSummaryList && infoSummaryList.length > 1 ? (
        createListItem()
      ) : (
        <p className={styles.info}>{props.formInfoSummary}</p>
      )}

      <hr className={styles.horizontalLine} />

      <AccordionDetails className={styles.accordionDetails}>
        {props.formElement}
      </AccordionDetails>
    </Accordion>
  );
}
