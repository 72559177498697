import styles from "./styles.module.scss";
import { Snackbar } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { SyntheticEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export function AwaitingRequestsToast() {
  const { t } = useTranslation(["connectionStatus"]);

  const [open, setOpen] = useState(false);

  useEffect(() => {
    const isCloseBtnClicked =
      window.sessionStorage?.getItem("pendingConnection") === "true"
        ? true
        : false;
    if (!isCloseBtnClicked) {
      setOpen(true);
    }
  }, [open]);

  function handleCloseBtn() {
    window.sessionStorage?.setItem("pendingConnection", "true");
    setOpen(false);
  }

  const handleClose = (event: Event | SyntheticEvent, reason: string) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const toastMsg = (
    <div className={styles.snackbarContent}>
      <div>
        <h4 data-testid="request-heading">
          {t("new_request_snackbar_heading")}
        </h4>
        <p>
          {t("new_request_snackbar_text")}{" "}
          <Link
            to={"/connection"}
            className={styles.snackbarCardDetailLink}
            data-testid="request-link"
          >
            {t("connection_link_text")}
          </Link>
        </p>
      </div>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleCloseBtn}
        data-testid="request-button"
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </div>
  );

  return (
    <Snackbar
      className={styles.ccSnackbar}
      ContentProps={{
        sx: {
          background: "#004F71"
        }
      }}
      open={open}
      anchorOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      onClose={handleClose}
      message={toastMsg}
    />
  );
}
