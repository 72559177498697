import { HeaderNav } from "../common/Header";
import i18n from "../../i18n";
import { useMsal } from "@azure/msal-react";
import { Footer } from "../common/Footer/Footer";
import { HeaderTop } from "../common/Header/HeaderTop";
import { HeaderIsOnboarded } from "../common/Header/HeaderIsOnboarded";
import { useUser } from "../../components/Context/UserContext";
import { useEffect } from "react";
import { SkipToMain } from "../common/SkipToMain";
import { MobileMessageModal } from "../common/MobileMessageModal/MobileMessageModal";
import { useLocation } from "react-router-dom";

type Props = {
  children: React.ReactNode;
};

export function PageLayout({ children }: Props) {
  const { accounts } = useMsal();
  const location = useLocation();
  const { userState } = useUser();

  const path = location.pathname;
  let pageTitle = path.includes(":") ? removeIdFromURL() : path;

  if (pageTitle === '/') {
    pageTitle = '/landing';
  }
  if (pageTitle === '/portfolio/portfolio') {
    pageTitle = '/portfolios/portfoliolisting';
  }
  function removeIdFromURL() {
    const pathWithoutId = path.split(':')[0];
    if (path.includes('profile')) {
      return pathWithoutId.concat('profile')
    }
    return pathWithoutId;
  }

  useEffect(() => {
    window.scrollTo(0, 0);

    const gaUserType =
      accounts[0] && accounts[0].idTokenClaims?.extension_Persona
        ? (accounts[0].idTokenClaims.extension_Persona as string)
        : "";
    const gaUserId = accounts[0] ? accounts[0].localAccountId : "";
    const perivousPageTitle = window.dataLayer &&
      window.dataLayer[window.dataLayer.length - 1] &&
      window.dataLayer[window.dataLayer.length - 1].page_title !== undefined ?
      window.dataLayer[window.dataLayer.length - 1].page_title :
      "";

    if (perivousPageTitle !== pageTitle && pageTitle !== "/resources" && pageTitle !== '/search/investors' && pageTitle !== '/search/projects') {
      window.dataLayer.push({
        event: "virtual_page_view",
        page_title: pageTitle,
        page_language: i18n.language,
        page_location: window.location.href,
        page_environment: process.env.REACT_APP_ENV
          ? process.env.REACT_APP_ENV
          : "dev",
        user_status: accounts[0] ? "loggedin" : "guest",
        user_type: gaUserType.toLowerCase(),
        user_id: gaUserId,
      });
    }


  }, [accounts, pageTitle]);

  return (
    <>
      <SkipToMain />
      <HeaderTop />
      <HeaderNav />
      {!userState.isOnboarded && <HeaderIsOnboarded />}
      <MobileMessageModal />
      <main id="mainContent">{children}</main>
      <Footer />
    </>
  );
}
