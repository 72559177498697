import { useTranslation } from "react-i18next";
import { WaitlistForm } from "../../components/Form/WaitlistForm";
import styles from "./Waitlist.module.css";
import { PageLayout } from "../../components/Layout/PageLayout";
import { useMsal } from "@azure/msal-react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export function Waitlist() {
  const { t, i18n } = useTranslation("waitlist");
  const navigate = useNavigate();
  const { instance,accounts } = useMsal();
  const isAuthenticated = accounts.length > 0;
  const isDeveloper = accounts.some(account => account.idTokenClaims && account.idTokenClaims.extension_Persona === "Developer");
  const isInvestor = accounts.some(account => account.idTokenClaims && account.idTokenClaims.extension_Persona === "Investor");
  
  const handleRedirect = () => {
    // Redirect logic based on user persona
    if (isDeveloper) {
      navigate("/search/investors");
    } else if (isInvestor) {
      localStorage.setItem("lastTabIndex", "0")
      localStorage.setItem("lastPage", "1");
      navigate("/search/projects");
      
    } 
  };
  useEffect(() => {
    handleRedirect();
  }, [isDeveloper, isInvestor]);
  return (
    <PageLayout>
      <div className={styles.waitlistContainer}>
        <div
          className={styles.waitlistBodyContainer}
          data-testid="waitlist-page-body-text"
        >
          <h1 className={styles.waitlistHeading}>
            {t("waitlist_page.heading")}
          </h1>

          <p className={styles.waitlistBodyParagraph}>
            {t("waitlist_page.info")}
          </p>
          {!isAuthenticated && (
          <p className={styles.waitlistBodyParagraph}>
            {t("waitlist_page.have_account")}{" "}
            
            <button
              className={styles.termsModalButton}
              type="button"
              onClick={() => {
                instance.loginRedirect({
                  extraQueryParameters: { ui_locales: i18n.language },
                  scopes: [],
                });
              }}
              data-testid="login-button"
            >
              {t("waitlist_page.login_text")}
            </button>
           
          </p>
             )}
          <p className={styles.waitlistNotice}>
            <strong>{t("waitlist_page.note")}</strong>{" "}
            {t("waitlist_page.notice")}
          </p>
        </div>
        <WaitlistForm />
      </div>
    </PageLayout>
  );
}
