import React, { useEffect, useState } from "react";
import { MultiSelectSearchProps, Tag } from "./types";
import { Chip } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import CreatableSelect from "./CreatableSelect";
import styles from "./MultiSelectSearch.module.scss";

const MultiSelectSearch: React.FC<MultiSelectSearchProps> = ({
  predefinedTagsList,
  labelText,
  fieldName,
  placeholderText,
  descPredefinedTags,
  selectedTags,
  userTags: addedUserTags,
  onSelectedTagsChange,
  setIsOpenModal,
  setSelectedFieldName,
  linkText,
}) => {
  const [predefinedTags, setPredefinedTags] = useState<Tag[]>([]);
  const [focusedChipIndex, setFocusedChipIndex] = useState<number | null>(null);
  const [userTags, setUserTags] = useState<Tag[]>([]);

  useEffect(() => {
    // Skip initialization on subsequent renders
    if (predefinedTags.length === 0 || predefinedTags.length> 0) {
      const sortedPredefinedTagsList = predefinedTagsList
        .filter(tag => !selectedTags.some(selectedTag => selectedTag.label === tag.label))
        .sort((a, b) => a.label.localeCompare(b.label));
      setPredefinedTags(sortedPredefinedTagsList);
    }
    if (userTags.length === 0) {
      setUserTags(addedUserTags);
    }
  }, [predefinedTagsList, addedUserTags, userTags]);

  const onDeleteTag = (tag: Tag) => {
    if (tag.inputValue === undefined) {
      //update Predefined tags added state
      const tagIndex = predefinedTags.findIndex(
        (t) => t.label.toLowerCase() === tag.label.toLowerCase()
      );
      if (tagIndex !== -1) {
        setPredefinedTags((prevPredefinedTags) => {
          const updatedTags = [...prevPredefinedTags];
          updatedTags[tagIndex] = { ...updatedTags[tagIndex], added: false };
          return updatedTags;
        });
      }
    } else {
      // Delete user tag
      setUserTags((prevUserTags) =>
        prevUserTags.filter(
          (userTag) => userTag.label.toLowerCase() !== tag.label.toLowerCase()
        )
      );
    }

    const updatedSelectedTags = selectedTags.filter(
      (t) => t.label.toLowerCase() !== tag.label.toLowerCase()
    );
    onSelectedTagsChange(updatedSelectedTags);

    const updatedPredefinedTagsList = predefinedTagsList.map(tag => {
      if (updatedSelectedTags.some(selectedTag => selectedTag.label === tag.label)) {
        // If the tag is selected, set added to true
        return { ...tag, added: true };
      } else {
        // If the tag is not selected, set added to false
        return { ...tag, added: false };
      }
    });
    setPredefinedTags(updatedPredefinedTagsList);
  
  };

  const onAddTag = (tag: Tag) => {
    //If tag is existing in predefined tags then update added state of predefined tag so that it will not display in predefined tags list
    if (selectedTags.length < 15) {
      if (tag.inputValue === undefined) {
        setPredefinedTags((prevPredefinedTags) =>
          prevPredefinedTags.map((t) =>
            t.label.toLowerCase() === tag.label.toLowerCase()
              ? { ...t, added: true }
              : t
          )
        );

        const updatedSelectedTags = [...selectedTags, { ...tag, added: true }];
        onSelectedTagsChange(updatedSelectedTags);
      } else {
        //Check if new added tag is existing in user tags list or not.To handle duplicate
        const isTagInUserTags = userTags.some(
          (userTag) => userTag.label.toLowerCase() === tag.label.toLowerCase()
        );
        // user generated tags should be limited to the number of predefined tags
        const isUserTagsLTPredefinedTag =
          userTags.length < predefinedTags.length;
        if (!isTagInUserTags && isUserTagsLTPredefinedTag) {
          setUserTags((prevUserTags) => [...prevUserTags, tag]);
          const updatedSelectedTags = [
            ...selectedTags,
            { ...tag, added: true },
          ];
          onSelectedTagsChange(updatedSelectedTags);
        }
      }
    }
  };

  const handleMouseEnter = (index: number) => {
    setFocusedChipIndex(index);
  };

  const handleMouseLeave = () => {
    setFocusedChipIndex(null);
  };
  const handleFocus = (index: number) => {
    setFocusedChipIndex(index);
  };

  const handleBlur = () => {
    setFocusedChipIndex(null);
  };

  return (
    <div className={styles.selectSearchContainer}>
      <CreatableSelect
        labelText={labelText}
        fieldName={fieldName}
        placeholderText={placeholderText}
        predefinedTags={[...predefinedTags, ...userTags]}
        onDeleteTag={onDeleteTag}
        selectedTags={selectedTags}
        onAddTag={onAddTag}
        linkText={linkText}
        setIsOpenModal={setIsOpenModal}
        setSelectedFieldName={setSelectedFieldName}
      />
      {selectedTags.length > 0 && (
        <div className={styles.chipPadding}>
          {selectedTags.map((tag, index) => (
            <Chip
              key={`selected_${tag.label}`}
              label={tag.label}
              style={{
                backgroundColor: "#E6EFF3",
                color: "#000000",
                border: `${focusedChipIndex === index
                    ? "2px solid #669EB6"
                    : "1px solid #669EB6"
                  }`,
                borderRadius: "20px",
                padding: "8px 16px",
                fontSize: "14px",
                margin: "0 16px 16px 0",
              }}
              onMouseEnter={() => handleMouseEnter(index)}
              onMouseLeave={handleMouseLeave}
              onFocus={() => handleFocus(index)}
              onBlur={handleBlur}
              avatar={
                <ClearOutlinedIcon
                  style={{ width: "16px", height: "16px" }}
                  onClick={() => onDeleteTag(tag)}
                />
              }
            />
          ))}
        </div>
      )}
      <div className={styles.tagsContainer}>
        <p>{descPredefinedTags}</p>
        <div className={styles.chipsContainer}>
          {predefinedTags.map(
            (tag, index) =>
              !tag.added && (
                <Chip
                  key={`predefined_${tag.label}`}
                  label={tag.label}
                  style={{
                    backgroundColor: "#FFFFFF",
                    color: "#000000",
                    border: `${focusedChipIndex === index
                        ? "2px solid #669EB6"
                        : "1px solid #669EB6"
                      }`,
                    borderRadius: "20px",
                    padding: "8px 16px",
                    fontSize: "14px",
                    margin: "0 16px 16px 0",
                  }}
                  onClick={() => onAddTag(tag)}
                  onMouseEnter={() => handleMouseEnter(index)}
                  onMouseLeave={handleMouseLeave}
                  onFocus={() => handleFocus(index)}
                  onBlur={handleBlur}
                  avatar={<AddIcon style={{ width: "16px", height: "16px" }} />}
                />
              )
          )}
        </div>
      </div>
    </div>
  );
};

export default MultiSelectSearch;
