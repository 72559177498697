import { Form, Formik } from "formik";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { TextArea } from "../common/TextArea";
import { TextInput } from "../common/TextInput";
import styles from "./styles.module.scss";
import { ProvincesSelect } from "../common/ProvincesSelect";
import { InvestorAPI } from "../../../APIAction/InvestorAPI";
import {
  InvestorProps,
  InvestorGeneralInfo,
} from "../../../TypesAndInterfaces/InvestorDetailsInterface";
import { useUser } from "../../Context/UserContext";
import { useMsal } from "@azure/msal-react";
import { ProjectLocationFields } from "../AddProject/ProjectLocationFields";
import { PROVINCES_CODES } from "../../../Constants/Enumerations";
import { City } from "country-state-city";

export function GeneralInformationForm({ handleResponse }: InvestorProps) {
  const { t } = useTranslation("onboarding");
  const [genInfo, setGenInfo] = useState<InvestorGeneralInfo>();
  const [isDataExist, setIsDataExist] = useState(false);
  const { userState } = useUser();
  const { accounts } = useMsal();
  const userEmail = accounts[0].username;
  const userPhoneNumber = accounts[0].idTokenClaims
    ?.extension_OrganizationPhone as string;

  useEffect(() => {
    const getGeneralInfomation = async () => {
      try {
        const res = await InvestorAPI.getGeneralInfoById(userState.userId);
        setIsDataExist(true);
        setGenInfo(res.data);
      } catch (error) {
        setIsDataExist(false);
        handleResponse("GeneralInfo", false, error);
      }
    };

    userState.userId !== "" && getGeneralInfomation();
  }, [userState.userId, handleResponse]);

  const selectedProvince = PROVINCES_CODES.find((element) => element.value === Number(genInfo?.province));
  const isoCode = selectedProvince && selectedProvince.isoCode;
  const cityList = isoCode && City.getCitiesOfState('CA', isoCode);
  const formattedCityOptions = cityList && cityList.map(city => ({ label: city.name, value: city.name }));

  // Check if the user's city code matches any city in the city list
  const matchingCity = formattedCityOptions && formattedCityOptions.find(city => city.value === genInfo?.city);

  const apiData = {
    address: genInfo?.address,
    city: matchingCity? matchingCity?.label : "",
    country: genInfo?.country,
    postalCode: genInfo?.postalCode,
    province: genInfo?.province,
    unitNumber: genInfo?.unitNumber,
    organizationName: genInfo?.organizationName,
    organizationSummary: genInfo?.organizationSummary,
    type: userState.userPersona,
    emailAddress: genInfo?.emailAddress,
    phoneNumber: genInfo?.phoneNumber,
  };

  const initialValues = {
    address: "",
    city: "",
    country: "Canada",
    postalCode: "",
    province: "",
    unitNumber: "",
    organizationName: "",
    organizationSummary: "",
    type: userState.userPersona,
    emailAddress: userEmail,
    phoneNumber: userPhoneNumber || "",
  };
  const maxCharErrorMsg = "investor.general_form_error.max_characters";
  const maxCharsErrorText = t(maxCharErrorMsg, { charNum: 64 });
  const spacingErrorText = t("investor.general_form_error.no_spaces");
  const regxNotAllowOnlySpace = /^(?!\s*$)/;
  const phoneNumberCharacters = 10;
  const phoneNumberError = t("investor.general_form_error.phone_number_error");

  const generalInfoSchema = Yup.object().shape({
    organizationName: Yup.string()
      .required(t("investor.general_form_error.organization_name_required"))
      .max(128, t(maxCharErrorMsg, { charNum: 128 }))
      .matches(regxNotAllowOnlySpace, spacingErrorText),
    address: Yup.string()
      .required(t("investor.general_form_error.address_required"))
      .max(64, maxCharsErrorText)
      .matches(regxNotAllowOnlySpace, spacingErrorText),
    unitNumber: Yup.string()
      .max(64, maxCharsErrorText)
      .matches(regxNotAllowOnlySpace, spacingErrorText),
    province: Yup.number()
      .required(t("investor.general_form_error.province_required"))
      .max(64, maxCharsErrorText),
    city: Yup.string()
      .required(t("investor.general_form_error.city_required"))
      .max(64, maxCharsErrorText)
      .matches(regxNotAllowOnlySpace, spacingErrorText),
    postalCode: Yup.string()
      .required(t("investor.general_form_error.postal_code_required"))
      .max(6, t(maxCharErrorMsg, { charNum: 6 }))
      .matches(
        /^[A-Z]\d[A-Z]\d[A-Z]\d$/i,
        t("investor.general_form_error.postal_code_required")
      ),
    country: Yup.string().required(
      t("investor.general_form_error.country_required")
    ),
    organizationSummary: Yup.string()
      .max(1000, t(maxCharErrorMsg, { charNum: 1000 }))
      .matches(regxNotAllowOnlySpace, spacingErrorText),
    phoneNumber: Yup.number()
      .required(phoneNumberError)
      .typeError(phoneNumberError)
      .test(
        "length",
        phoneNumberError,
        (val) => val?.toString().length === phoneNumberCharacters
      ),
  });

  const updateGeneralInformation = (formValues: Object) => {
    InvestorAPI.updateGenInfo(userState.userId, formValues)
      .then((res) => {
        handleResponse("GeneralInfo", true);
      })
      .catch((error) => {
        handleResponse("GeneralInfo", false, error);
      });
  };

  async function submitGeneralInformation(formValues: Object) {
    try {
      await InvestorAPI.postGenInfo(formValues);
      handleResponse("GeneralInfo", true);
    } catch (error) {
      handleResponse("GeneralInfo", false, error);
    }
  }

  return (
    <Formik
      initialValues={isDataExist ? apiData : initialValues}
      validationSchema={generalInfoSchema}
      validateOnChange={false}
      validateOnBlur={false}
      enableReinitialize={true}
      onSubmit={(values) => {
        const {
          organizationName,
          address,
          unitNumber,
          city,
          organizationSummary,
          phoneNumber,
          ...restValues
        } = values;
        const updatedValue = {
          organizationName: organizationName?.trim(),
          address: address?.trim(),
          unitNumber: unitNumber?.trim(),
          city: city?.trim(),
          organizationSummary: organizationSummary?.trim(),
          phoneNumber: phoneNumber?.trim(),
          ...restValues,
        };
        isDataExist
          ? updateGeneralInformation({ id: userState.userId, ...updatedValue })
          : submitGeneralInformation(updatedValue);
      }}
    >
     { (values) => (
      <Form className={styles.formElement} noValidate>
        <TextInput
          fieldName="organizationName"
          labelText={t("investor.general_info_form.org_name_label")}
          required
          data-testid="orginization-name"
        />
        <TextInput
          fieldName="phoneNumber"
          labelText={t("investor.general_info_form.phone_number")}
          maxLength={phoneNumberCharacters}
          placeholder="1234567890"
          required
          data-testid="phone-number"
        />
        <div className={styles.splitFieldContainer}>
          <TextInput
            fieldName="address"
            labelText={t("investor.general_info_form.address_label")}
            required
            data-testid="address"
          />
          <TextInput
            fieldName="unitNumber"
            labelText={t("investor.general_info_form.unit_label")}
            required
            data-testid="unit-number"
          />
        </div>
        <ProjectLocationFields province={values.values.province} isDataExist={isDataExist}/>
        
        <div className={styles.splitFieldContainer}>
          <TextInput
            fieldName="postalCode"
            labelText={t("investor.general_info_form.postal_code_label")}
            placeholder="A1A2A3"
            required
            data-testid="postal-code"
          />
          <TextInput
            fieldName="country"
            labelText={t("investor.general_info_form.country_label")}
            disabled
            data-testid="country"
          />
        </div>
        <TextArea
          fieldName="organizationSummary"
          labelText={t("investor.general_info_form.org_summary_label")}
          placeholder={t("investor.general_info_form.org_summary_placeholder")}
          data-testid="organization-summary"
        />
        <div className={styles.buttonContainer}>
          <button
            type="submit"
            className={styles.formButton}
            data-testid="submit-button"
          >
            {t("investor.main_page.next_button_text")}
          </button>
        </div>
      </Form>
     )}
    </Formik>
  );
}
