import { Skeleton } from "@mui/material";
import styles from "./styles.module.scss";

export function ProjectCardSkeleton() {
  return (
    <div className={styles.SkeletonCardContainer}>
      <div className={styles.SkeletonCardProjectsLeft}>
        <Skeleton variant="rectangular" width={200} height={140} />
        <Skeleton variant="text" width={90} sx={{ fontSize: "1.5rem" }} />
        <Skeleton width={120} sx={{ fontSize: "1.5rem" }} />
      </div>
      <div className={styles.SkeletonCardDetails}>
        <Skeleton sx={{ fontSize: "1.5rem" }} width={475} />
        <Skeleton sx={{ fontSize: "1.5rem" }} width={475} />
        <Skeleton sx={{ fontSize: "1.5rem" }} width={475} />
      </div>
    </div>
  );
}
