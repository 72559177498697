import { useTranslation } from "react-i18next";
import styles from "./styles.module.scss";
import { Sad_Face } from "../../assets/images/index";

interface Props {
  isLabelChanged?:boolean;
}
export const NoListedProjectsResults = ({isLabelChanged}:Props) => {
  const { t } = useTranslation("filter");

  return (
    <div className={styles.noInvestorsFound}>
      {isLabelChanged ? 
      <>
      <img
      data-testid="crying-face"
      src={Sad_Face}
      alt={t("searchProjectsPage.noResultsImageAlt")}
    />
    <div>
      <h2 data-testid="no-investors-found-heading">
        {t("searchProjectsPage.noResultsTitle")}
      </h2>
      <p data-testid="no-investors-found-heading">
        {t("searchProjectsPage.noResultsText")}
      </p>
    </div>
      </>
       : 
    <>
    <img
      data-testid="crying-face"
      src={Sad_Face}
      alt={t("searchProjectsPage.noResultsImageAlt")}
    />
    <div>
      <h2 data-testid="no-investors-found-heading">
        {t("searchProjectsPage.noResultsTitle")}
      </h2>
      <p data-testid="no-investors-found-heading">
        {t("searchProjectsPage.noResultsText")}
      </p>
    </div>
    </>
    }
      
    </div>
  );
};
