import React from "react";

import { Link } from "react-router-dom";
import styles from "./styles.module.scss";
import { useUser } from "../../../components/Context/UserContext";
import { useTranslation } from "react-i18next";
import {
    AuthenticatedTemplate,
} from "@azure/msal-react";

export function HeaderIsOnboarded() {
    const { t } = useTranslation("common");
    const { userState } = useUser();


    return (
        <React.Fragment>
            <AuthenticatedTemplate>
                <header className={styles.headerIsOnboarded}>
                    <div className={styles.headerContainerIsOnboarded}>

                        <div className={styles.headerNavContainerIsOnboarded} data-testid="nav-links">

                            <div>{t("header.on_onboarded_description")}<Link to={userState.userPersona === "Investor" 
                                ? "/onboarding/investor" 
                                : "/onboarding/developer"
                            }>{t("header.complete_profile")}</Link>.</div>

                        </div>
                    </div>
                </header>
            </AuthenticatedTemplate>
        </React.Fragment>
    );
}
