import { BrowserRouter, Routes, Route } from "react-router-dom";
import { AboutUs } from "../pages/About/Aboutus";
import { Error } from "../pages/JoinWaitList/Error";
import { Success } from "../pages/JoinWaitList/Success";
import { Waitlist } from "../pages/JoinWaitList/Waitlist";
import { LandingPage } from "../pages/Landing/landing";
import { TermsConditionPage } from "../pages/TermsCondition";
import { Resources } from "../pages/Resources/Resources";
import { InvestorConfirmation } from "../pages/InvestorOnboarding/Success/InvestorConfirmation";
import { DeveloperConfirmation } from "../pages/DeveloperOnboarding/Success/DeveloperConfirmation";
import { InvestorOnboarding } from "../pages/InvestorOnboarding/InvestorOnboarding";
import { ReviewInvestorProfile } from "../pages/InvestorOnboarding/ReviewInvestorProfile";
import { ProtectedRoutes } from "./ProtectedRoutes";
import { ViewInvestorProfile } from "../pages/ViewInvestorProfile";
import { EditInvestorProfile } from "../pages/InvestorOnboarding/EditInvestorProfile";
import { EditDeveloperProfile } from "../pages/DeveloperOnboarding/EditDeveloperProfile";
import { ReviewDeveloperProfile } from "../pages/DeveloperOnboarding/ReviewDeveloperProfile/Index";
import { ViewDeveloperProfile } from "../pages/DeveloperOnboarding/ViewDeveloperProfile/index";
import { NoProjectsConfirmation } from "../pages/DeveloperOnboarding/NoDeveloperProfile/index";
import { DeveloperOnboarding } from "../pages/DeveloperOnboarding/DeveloperOnboarding";
import { AddProject } from "../pages/Developers/AddProject";
import { AddPortfolio } from "../pages/Portfolio/AddPortfolio";
import { ReviewAddProject } from "../pages/Developers/ReviewAddProject";
import { ReviewAddPortfolio } from "../pages/Developers/ReviewAddPortfolio";
import { ProjectSuccessPage } from "../pages/Developers/Success/ProjectSuccessPage";
import { PortfolioSuccessPage } from "../pages/Developers/Success/PortfolioSuccessPage";

import { ProjectDetails } from "../pages/Developers/ProjectDetails/ProjectDetails";
import { ViewDeveloperPortfolio } from "../pages/Portfolio/ViewPortfolio/ViewDeveloperPortfolio";

import { DeleteConfirmation } from "../pages/Developers/Modals/DeleteConfirmation/DeleteConfirmation";
import { DeleteConfirmationPortfolio } from "../pages/Developers/Modals/DeleteConfirmation/DeleteConfirmationPortfolio";

import { SearchInvestors } from "../pages/Search/SearchInvestors";
import { SearchProjects } from "../pages/Search/SearchProjects";
import { UpdatedSuccessPage } from "../pages/Developers/Success/UpdatedSuccessPage";
import { UpdatedSuccessPagePortfolio } from "../pages/Developers/Success/UpdatedSuccessPagePortfolio";

import { PageNotFoundError } from "../pages/ErrorPage/pageNotFoundError";
import { ApiError } from "../pages/ErrorPage/ApiError";
import { DeveloperProject } from "../pages/Developers/DeveloperProject";
import { ConnectionHub } from "../pages/connectionHub";
import { RedirectHandler } from "../pages/RedirectHandler/RedirectHandler";
import { ListingOpportunity } from "../pages/Developers/OpportunityListing";

export function AppRoute() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/">
          <Route index element={<LandingPage />} />
          <Route path="about" element={<AboutUs />} />
          <Route path="resources" element={<Resources />} />
          <Route path="terms" element={<TermsConditionPage />} />
          <Route path="api-error" element={<ApiError />} />
          <Route path="request-access">
            <Route index element={<Waitlist />} />
            <Route path="success" element={<Success />} />
            <Route path="error" element={<Error />} />
          </Route>
          <Route path="redirect" element={<ProtectedRoutes />}>
            <Route index element={<RedirectHandler />} />
          </Route>
          <Route path="onboarding" element={<ProtectedRoutes />}>
            <Route path="investor">
              <Route index element={<InvestorOnboarding />} />
              <Route path="review" element={<ReviewInvestorProfile />} />
              <Route path="success" element={<InvestorConfirmation />} />
              <Route path="edit" element={<EditInvestorProfile />} />
            </Route>
            <Route path="developer">
              <Route index element={<DeveloperOnboarding />} />
              <Route path="review/:developerId" element={<ReviewDeveloperProfile />} />
              <Route path="success" element={<DeveloperConfirmation />} />
              <Route path="edit" element={<EditDeveloperProfile />} />
              <Route path="create" element={<ListingOpportunity />} />
            </Route>
          </Route>
          <Route path="profile" element={<ProtectedRoutes />}>
            <Route path="investor">
              <Route index element={<ViewInvestorProfile />} />
              <Route path="edit" element={<EditInvestorProfile />} />
              <Route path="success" element={<InvestorConfirmation />} />
              <Route path=":investorId" element={<ViewInvestorProfile />} />
            </Route>
            <Route path="developer">
              {["", "developerInfo"].map((path, index) =>
                <Route path={path} element={<ViewDeveloperProfile />} />
              )}
              <Route path="edit" element={<EditDeveloperProfile />} />
              <Route path="success" element={<DeveloperConfirmation />} />
              <Route path="noproject" element={<NoProjectsConfirmation />} />
            </Route>
          </Route>
          <Route path="projects" element={<ProtectedRoutes />}>
            <Route index element={<DeveloperProject />} />
            <Route path="add" element={<AddProject />} />
            <Route path="review/:projectId" element={<ReviewAddProject />} />
            <Route path="success/:projectId" element={<ProjectSuccessPage />} />
            <Route path="updated/:projectId" element={<UpdatedSuccessPage />} />
            <Route path=":projectId" element={<ProjectDetails />} />
            <Route path="delete" element={<DeleteConfirmation />} />
            <Route path="edit/:projectId" element={<AddProject />} />
          </Route>
          <Route path="portfolio" element={<ProtectedRoutes />}>
            <Route index element={<DeveloperProject />} />
            <Route path="add" element={<AddPortfolio />} />
            <Route path="review/:portfolioId" element={<ReviewAddPortfolio />} />
            <Route path="success/:portfolioId" element={<PortfolioSuccessPage />} />
            <Route path="updated/:portfolioId" element={<UpdatedSuccessPagePortfolio />} />
            <Route path=":portfolioId" element={<ViewDeveloperPortfolio />} />
            <Route path="delete" element={<DeleteConfirmationPortfolio />} />
            <Route path="edit/:portfolioId" element={<AddPortfolio />} />
          </Route>
          <Route path="search" element={<ProtectedRoutes />}>
            <Route path="investors" element={<SearchInvestors />} />
            {["projects", "myprojects"].map((path, index) =>
              <Route path={path} element={<SearchProjects />} />
            )}
          </Route>

          {["/connection", "/newconnection"].map((path, index) =>
            <Route path={path} element={<ProtectedRoutes />}>
              <Route index element={<ConnectionHub />} />
            </Route>
          )}
          <Route path="*" element={<PageNotFoundError />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}
