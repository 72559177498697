import { useNavigate } from "react-router-dom";
import ListProject from "../../../assets/icons/portfolio/ListProject.svg";
import styles from "./styles.module.scss";
import { useTranslation } from "react-i18next";

export function ProjectCreateListCard() {
  const navigate = useNavigate();
  const { t } = useTranslation("profile");

  return (
    <div className={styles.portfolioCreateListContainer}>
      <div className={styles.createCard}>
        <img
          src={ListProject}
          alt={""}
          className={styles.portfolioIcon}
          data-testid="portfolio-icon"
        />
        <h2 className={styles.title}>
        {t("developer.projects.listing_opportunity.list_a_project")}
        </h2>
        <p className={styles.paraText}>
        {t("developer.projects.listing_opportunity.list_a_project_summary")}
        </p>
        <button
          className={styles.linkButton}
          onClick={() => navigate("/projects/add")}
        >          
          
        {t("developer.projects.listing_opportunity.create_project")}

        </button>
      </div>
    </div>
  );
}
