import { PublicClientApplication } from "@azure/msal-browser";
import axios from "axios";
import { NavigateFunction } from "react-router-dom";
import { msalConfig } from "../authConfig";

export const config = {
  url: {
    base: process.env.REACT_APP_DATA_API_BASE_URL,
    investor: {
      GenInfo: "/api/investors/GeneralInformation",
      InvestmentInfo: "/api/investors/InvestmentInformation",
      PreferenceInfo: "/api/investors/InvestmentPreference",
      OrganizationImpactInfo: "/api/investors/ImpactInformation",
      Search: "/api/investors/Investor/Search",
      GetInvestor: "/api/investors/investor",

      SaveProject: "/api/investors/savedprojectlisting/save",
      RemoveProject: "/api/investors/savedprojectlisting/remove",
      SavePortfolio: "/api/investors/savedportfolio/save",
      RemovePortfolio: "/api/investors/savedportfolio/remove",
      SearchSavedProject: "/api/investors/savedprojectlisting/search",
      SearchSavedPortfolio: "/api/investors/savedportfolio/search",

      investorIdByEmail: "/api/investors/Investor",
    },
    developer: {
      GenInfo: "/api/developers/GeneralInformation",
      developerInfo: "/api/developers/DeveloperExperience",
      projectInfo: "api/developers/Developer",
      projectInfoSearch: "api/developers/Developer/Search",
      portfolioInfoSearch: "api/developers/developer/searchportfolios",
      developerIdByEmail: "/api/developers/Developer",
      activeProjects: "api/developers/Developer/GetActiveProjects",
      OrgInfo: "/api/developers/OrganizationInformation",
      allProjects: "api/developers/Developer/GetAllProjects",
      Search: "/api/developers/developer/searchdevelopers",
      activePortfolio: "api/developers/developer/getactiveportfolios"
    },
    projectListing: {
      ProjectAffordability: "/api/projectListings/AffordabilityDetail",

      ProjectGenInfo: "/api/projectListings/GeneralInformation",
      ProjectFinancialDetail: "/api/projectListings/FinancialDetail",
      ProjectFocusArea: "/api/projectListings/FocusArea",
      ProjectImpactCriteria: "/api/projectListings/ImpactInformation",
      projectListingDocumentation: "api/projectListings/DocumentationDetail",
      CloseProject: "/api/ProjectListing/Close",
      ProjectListingInfoById: "/api/ProjectListing",
      DeleteProjectById: "api/ProjectListing",
      projectListingSearch: "api/ProjectListing/Search",
      confirm: "api/ProjectListing/Confirm",
    },
    portfolioListing: {
      PortfolioAffordability: "/api/portfolio/AffordabilityDetails",
      PortfolioAffordabilityInfo: "/api/portfolio/AffordabilityDetails",
      PortfolioGenInfo: "/api/portfolio/generalinformation",
      PortfolioFinancialDetail: "/api/portfolio/financialdetails",
      PortfolioImpactCriteria: "/api/portfolio/developmentimpact",
      portfolioListingDocumentation: "api/portfolio/DocumentationDetail",
      ClosePortfolio: "/api/portfolio/Close",
      PortfolioListingInfoById: "/api/portfolio",
      PortfolioProjects: "/api/portfolio/portfolioprojectdetail",

      DeletePortfolioById: "api/portfolio",
      PortfolioListingSearch: "api/portfolio/search",
      confirm: "api/portfolio/confirm",
    },
    account: {
      JoinWaitList: "/api/Account/Email",
      onboardingVerify: "/api/Account/Onboarding",
    },
    connect: {
      connectRequest: "/api/connections/Connection/connect",
      connectSearchRequest: "/api/connections/Connection/search",
      connectAcceptRequest: "/api/connections/Connection",
      verifyRequest: "/api/connections/Connection/Verify",
      pendingConnections:
        "/api/connections/Connection/VerifyPendingConnections",
      verifySuccessfulConnection:
        "/api/connections/Connection/verifysuccessfulconnection",
    },
  },
};

const msalInstance = new PublicClientApplication(msalConfig);

export async function requestAccessToken() {
  const activeAccount = msalInstance.getActiveAccount();
  const accounts = msalInstance.getAllAccounts();

  if (!activeAccount && accounts.length === 0) {
    /*
     * User is not signed in. Throw error or wait for user to login.
     * Do not attempt to log a user in outside of the context of MsalProvider
     */
    throw new Error("User must be logged in.");
  }

  const request = {
    endpoint: config.url.base,
    scopes: [process.env.REACT_APP_AZURE_SCOPE_DATA_API || ""],
    account: activeAccount || accounts[0],
  };

  try {
    const res = await msalInstance.acquireTokenSilent(request);
    return res.accessToken;
  } catch (error) {
    console.error(error);
    return Promise.reject("Unable to get access token.");
  }
}

export function handleApiErrorRedirect(
  error: unknown,
  localNavigateHook: NavigateFunction
) {
  if (axios.isAxiosError(error) && error.response?.status !== 404) {
    localNavigateHook("/api-error");
  }
}
