import { useTranslation } from "react-i18next";
import styles from "./ReviewInvestor.module.scss";
import { InvestorPreferenceIcon } from "../../../assets/icons";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import {
  InvestorInvestmentPreference,
  ProvinceProps,
} from "../../../TypesAndInterfaces/InvestorDetailsInterface";
import { InvestorAPI } from "../../../APIAction/InvestorAPI";
import axios from "axios";
import { useUser } from "../../../components/Context/UserContext";
import {
  FUNDING_TYPES_ONBOARDING,
  getKVArrayFormat,
  INVESTMENT_PHASES_ONBOARDING,
  LOCKUP_PERIOD,
  ROI_RANGE,
} from "../../../Constants/Enumerations";

export const ReviewPreferences = ({ provinceOptions }: ProvinceProps) => {
  const { t } = useTranslation("onboarding");
  const { userState } = useUser();
  const navigate = useNavigate();
  const [PreferenceInfo, setPreferenceInfo] =
    useState<InvestorInvestmentPreference>();
  const [isDataExist, setIsDataExist] = useState(false);

  useEffect(() => {
    const getPreferenceInfomation = async () => {
      try {
        const res = await InvestorAPI.getPreferenceInfoById(userState.userId);
        const data = res.data;
        setIsDataExist(true);
        setPreferenceInfo(data);
      } catch (error) {
        setIsDataExist(false);
        if (axios.isAxiosError(error) && error.response?.status !== 404) {
          navigate("/api-error");
        }
      }
    };

    userState.userId !== "" && getPreferenceInfomation();
  }, [userState.userId, navigate]);

  const investmentPhasesTranslations = Object.keys(INVESTMENT_PHASES_ONBOARDING).map(
    (val) => t(`common:enumerations.investment_phases_onboarding.${val}`)
  );
  const investmentPhasesOptions = getKVArrayFormat(
    INVESTMENT_PHASES_ONBOARDING,
    investmentPhasesTranslations
  );

  const capitalInjectionTranslations = Object.keys(FUNDING_TYPES_ONBOARDING)?.filter((val:any) => val !== undefined && val !== "").map((val:any) =>
  t(`common:enumerations.funding_types_onboarding.${val}`)
  );
  const investmentFundingTypes = getKVArrayFormat(
    FUNDING_TYPES_ONBOARDING,
    capitalInjectionTranslations
  );


  const lockupPeriodTrans = Object.keys(LOCKUP_PERIOD).map((val) =>
    t(`common:enumerations.lockup_period.${val}`)
  );

  const maximumLockupPeriodOptions: {
    label: string;
    value: string | number;
  }[] = getKVArrayFormat(LOCKUP_PERIOD, lockupPeriodTrans);

  const capitalCostTranslation = Object.keys(ROI_RANGE).map((val) =>
    t(`common:enumerations.roi_range.${val}`)
  );

  const roiTargets = getKVArrayFormat(ROI_RANGE, capitalCostTranslation);
  const constructionTypeOption = [
    {
      label: t("investor.investment_preferences_form.preferencetype_new_label"),
      value: "1",
    },
    {
      label: t(
        "investor.investment_preferences_form.preferencetype_repair_label"
      ),
      value: "2",
    },
  ];

  const investmentRegions =
    PreferenceInfo &&
    PreferenceInfo.investmentRegions &&
    PreferenceInfo.investmentRegions.length > 0 &&
    PreferenceInfo.investmentRegions.map((region) => {
      const province = provinceOptions.find(
        (val) => val.value === region.province
      )?.label;
      const city = region.city;
      return { province, city };
    });

  const handleEditClick = () => {
    navigate("/onboarding/investor/edit");
  };

  function renderYesOrNoOptions(truthyValue: boolean) {
    if (truthyValue) {
      return t("developer.orgnization_info_form.org_info.yes_label");
    }
    return t("developer.orgnization_info_form.org_info.no_label");
  }

  return (
    <div className={styles.informationContainer}>
      <div className={styles.infoHeadingContainer}>
        <div className={styles.left}>
          <div className={styles.imgContainer}>
            <img src={InvestorPreferenceIcon} alt="" />
          </div>
          <h3 className={styles.sectionHeading}>
            {t(
              "investor.main_page.form_accordions.investment_preferences.heading"
            )}
          </h3>
        </div>
        <button className={styles.link} onClick={handleEditClick}>
          {t("investor.review_profile.edit_link")}
        </button>
      </div>
      {isDataExist && PreferenceInfo && (
        <div className={styles.content}>
          <p className={styles.title}>
            {t("investor.investment_preferences_form.funding_heading")}
          </p>
          <div className={styles.grid_container}>
            {/* constructionType options */}
            <p>
              {t(
                "investor.investment_preferences_form.preferencetype_question_review"
              )}
            </p>
            <p>
              {
                constructionTypeOption.find(
                  (item) => +item.value === PreferenceInfo.constructionType
                )?.label
              }
            </p>
            {/* Investment Phases options */}
            <p>
              {t(
                "investor.investment_preferences_form.funding_investment_types_review"
              )}
            </p>
            <p>
              {PreferenceInfo.investmentPhases &&
                PreferenceInfo.investmentPhases.length > 0 &&
                PreferenceInfo.investmentPhases.map((val, idx) => (
                  <span key={idx}>
                    {
                      investmentPhasesOptions.find((obj) => obj.value === val)
                        ?.label
                    }
                    {idx < PreferenceInfo.investmentPhases.length - 1
                      ? ", "
                      : " "}
                  </span>
                ))}
            </p>
            {/* Funding Types options */}
            <p>
              {t("investor.investment_preferences_form.funding_funding_type_review")}
            </p>
            <p>
              {PreferenceInfo.fundingTypes &&
                PreferenceInfo.fundingTypes.length > 0 &&
                PreferenceInfo.fundingTypes.map((val, idx) => (
                  <span key={idx}>
                    {
                      investmentFundingTypes.find((obj) => obj.value === val)
                        ?.label
                    }
                    {idx < PreferenceInfo.fundingTypes.length - 1 ? ", " : " "}
                  </span>
                ))}
            </p>
            {/* Roi Target range */}
            <p>{t("investor.investment_preferences_form.funding_ideal_roi_review")}</p>
            <p>
              {
                roiTargets.find(
                  (obj) => obj.value === PreferenceInfo.roiTargetMinimum
                )?.label
              }{" "}
              -{" "}
              {
                roiTargets.find(
                  (obj) => obj.value === PreferenceInfo.roiTargetMaximum
                )?.label
              }
            </p>
            {/* Maximum lockup period */}
            <p>
              {t("investor.investment_preferences_form.funding_invest_period_review")}
            </p>
            <p>
              {
                maximumLockupPeriodOptions.find(
                  (obj) => obj.value === PreferenceInfo.maximumLockupPeriod
                )?.label
              }
            </p>
            <p>{t("investor.investment_preferences_form.able_to_provide_land_question_review")}</p>
            <p>{renderYesOrNoOptions(PreferenceInfo.ableToProvideLandFlag)}</p>
          </div>
          <hr className={styles.horizontalLine} />
          <p className={styles.title}>
            {t("investor.investment_preferences_form.focus_areas_heading")}
          </p>
          <div className={styles.grid_container}>
            <p>
              {t(
                "investor.investment_preferences_form.focus_areas_sub-heading"
              )}
            </p>
            <p>
              {" "}
              {investmentRegions &&
                investmentRegions.map((region, index) => (
                  <span
                    key={index}
                  >{`${region.province}, ${region.city} `}</span>
                ))}
            </p>
          </div>
        </div>
      )}
    </div>
  );
};
