import { useTranslation } from "react-i18next";
import { TextArea } from "../common/TextArea";
import { TextInput } from "../common/TextInput";
import styles from "./styles.module.scss";
import {
    getKVArrayFormatForDropdown,
    COMMITMENT_TO_AFFORDABILITY,
} from "../../../Constants/Enumerations";
import { SelectInput } from "../common/SelectInput";
export function AffordabilityFirstRadioOptionForm() {
    const { t } = useTranslation("profile");

    const commitmentAffordabilityTrans = Object.keys(COMMITMENT_TO_AFFORDABILITY).map((val) =>
        t(`common:enumerations.commitment_to_affordability.${val}`)
    );
    const commitmentAffordabilityPlaceholder = {
        label: t("developer.projects.add_listing_form.select_value_option"),
        value: "",
    };
    const commitmentAffordabilityOptions: {
        label: string;
        value: string | number;
    }[] = getKVArrayFormatForDropdown(COMMITMENT_TO_AFFORDABILITY, commitmentAffordabilityTrans);
    commitmentAffordabilityOptions.unshift(commitmentAffordabilityPlaceholder);

    return (
        <div>
            <div className={styles.fieldContainer}>
                <h3 className={styles.formTitle}>
                    {t(
                        "developer.projects.add_listing_form.affordabilityDetails"
                    )}
                </h3>
            </div>
            <TextInput
                fieldName="totalUnitCount"
                labelText={t(
                    "developer.projects.add_listing_form.number_of_units_developed_through_projects"
                )}
                required
                numOnly={true}
                data-testid="units-of-projects"
            />
            <SelectInput
                fieldName="affordabilityCommitment"
                labelText={t(
                    "developer.projects.add_listing_form.commitment_to_affordability"
                )}
                options={commitmentAffordabilityOptions}
                placeholder={t("developer.projects.add_listing_form.select_value_option")}
                data-testid="commitment_to_affordability"
            />
            <TextArea
                fieldName="marketLevelDefinition"
                labelText={t(
                    "developer.projects.add_listing_form.define_market_level"
                )}
                data-testid="define-market-level"
                charLimit={300}
            />
            <TextArea
                fieldName="additionalDetails"
                labelText={t(
                    "developer.projects.add_listing_form.add_details"
                )}
                data-testid="add-details"
                charLimit={300}
            />

        </div>
    )
}