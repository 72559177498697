import {
  ProjImage_1,
  ProjImage_2,
  ProjImage_2X_1,
  ProjImage_2X_2,
  ProjImage_2X_3,
  ProjImage_2X_4,
  ProjImage_2X_5,
  ProjImage_2X_6,
  ProjImage_2X_7,
  ProjImage_2X_8,
  ProjImage_3,
  ProjImage_4,
  ProjImage_5,
  ProjImage_6,
  ProjImage_7,
  ProjImage_8,
} from "../assets/images";
/**
 * Pattern to match data using the Enumeration Constants
 * 1. Map API data number to enumeration constant using `getEnumKeyByValue()` function
 * 2. Assign translation which are based on equivalent keys in both the enum constanst and translation JSON
 */

export const PROVINCES = {
  Alberta: 1,
  BritishColumbia: 2,
  Manitoba: 3,
  NewBrunswick: 4,
  NewfoundlandAndLabrador: 5,
  NorthwestTerritories: 6,
  NovaScotia: 7,
  Nunavut: 8,
  Ontario: 9,
  PrinceEdwardIsland: 10,
  Quebec: 11,
  Saskatchewan: 12,
  Yukon: 13,
};

export const DOLLAR_RANGE = {
  TenThousand: 10000,
  TwentyThousand: 20000,
  ThirtyThousand: 30000,
  FourtyThousand: 40000,
  FiftyThousand: 50000,
  HundredThousand: 100000,
  HundredFiftyThousand: 150000,
  TwoHundredThousand: 200000,
  TwoHundredFiftyThousand: 250000,
  ThreeHundredThousand: 300000,
  FourHundredThousand: 400000,
  FiveHundredThousand: 500000,
  OneMillion: 1000000,
  OneMillionFiveHundredThousand: 1500000,
  TwoMillion: 2000000,
  ThreeMillion: 3000000,
  FourMillion: 4000000,
  FiveMillion: 5000000,
  SixMillion: 6000000,
  SevenMillion: 7000000,
  EightMillion: 8000000,
  NineMillion: 9000000,
  TenMillion: 10000000,
  TwentyMillion: 20000000,
  FiftyMillion: 50000000,
  HundredMillion: 100000000,
  OneBillion: 1000000000,
  FiftyBillion: 50000000000,
  HundredBillion: 100000000000
};

export const INVESTMENT_PHASES = {
  Acquisition: 1,
  PreDevelopment: 2,
  Construction: 3,
  PostConstruction: 4,
  Seed: 5,
};

export const INVESTMENT_PHASES_ONBOARDING = {
  Seed: 6,
  LandAcquisition: 2,
  PreDevelopmentOrPostConstruction: 4,
  Construction: 1,
  LongTermFinancing: 3,
  RetrofitOrRenovations: 5,
};

export const LAND_STATUS_PHASES = {
  LandSecured: 1,
  LandNeeded: 2,
  LandAcquisitioInProcess: 3,
  PreferNotToDdisclose: 9,
};

export const AFFORDABILITY_TYPES_OPTIONS = {
  SocialImpactingHousing: 1,
  FocusOnAffordableHousing: 2,
  BalanceBetweenAffordableAndSupply: 3,
  FocusOnHousingSupply: 4,
};

export const AFFORDABILITY_TYPES = {
  SocialImpactHousing: 1,
  FocusOnAffordableHousing: 2,
  Balance: 3,
  FocusOnHousingSupply: 4,
};

export const FUNDING_TYPES = {
  Granting: 1,
  DebtFinancing: 2,
  BridgeFinancing: 4,
  Equity: 5,
  AnyTypeofCapital: 9,
};

export const FUNDING_TYPES_ONBOARDING = {
  Granting: 1,
  DebtFinancing: 2,
  Mezzanine: 3,
  BridgeFinancing: 4,
  Equity: 5,
};

export const LAND_FILTER = {
  landOffer: 1,
};
export const FUNDING_TYPES_PORTFOLIO = {
  Granting: 1,
  DebtFinancing: 2,
  BridgeFinancing: 4,
  Equity: 5,
  AnyTypeofCapital: 9,
};

export const ROI_RANGE = {
  ZeroPercent: -1,
  HalfPercent: 0,
  OnePercent: 1,
  TwoPercent: 2,
  ThreePercent: 3,
  FourPercent: 4,
  FivePercent: 5,
  SixPercent: 6,
  SevenPercent: 7,
  EightPercent: 8,
  NinePercent: 9,
  TenPercent: 10,
  ElevenPercent: 11,
  TwelvePercent: 12,
  ThirteenPercent: 13,
  FourteenPercent: 14,
  FifteenPercent: 15,
  SixteenPercent: 16,
  Seventeen: 17,
};

export const LOCKUP_PERIOD = {
  ZeroToThreeYears: 1,
  ThreeToFiveYears: 2,
  FiveToTenYears: 3,
  TenToFifteenYears: 4,
  FifteenToTwenty: 5,
  NoPreference: 6,
};

export const COMMITMENT_TO_AFFORDABILITY = {
  FifteenToTwentyYears: 1,
  TwentyToTwentyFiveYears: 2,
  TwentyFiveToThirtyYears: 3,
  ThirtyToThirtyFiveYears: 4,
  ThirtyFiveToFourtyYears: 5,
  FourtyToFourtyFiveYears: 6,
  FourtyFiveToFifityYears: 7,
  FiftyPlusYears: 8,
  inPerpetuity: 9,
};
export const AFFORDABILITY_RADIO_OPTIONS = {
  UnitBelowMarket: 1,
  MixedMarketHousing: 2,
  ProjectNotMeetAffordabilityThreshold: 3,
};

export const VULNERABLE_GROUPS = {
  WomenFleeingDomesticViolence: 1,
  Seniors: 2,
  PeopleWithDisabilities: 3,
  LGBTQTwoPlus: 4,
  RacializedGroups: 5,
  PeopleDealingWithMentalHealthIssues: 6,
  Veterans: 7,
  RecentImmigrants: 8,
  IndigenousPeoples: 9,
  PeopleExperiencingHomelessness: 10,
  YoungAdults: 11,
  WorkingPoor: 12,
  NoPreference: 13,
};

export const CLIMATE_CHANGE_CRITERIA = {
  CloseProximityAffordableTransportation: 1,
  CleanSustainableMaterialUsage: 2,
  MeetExceedCertification: 3,
  EfficientEnergyUse: 4,
  MinimizedFloodRisk: 5,
  MinimizedFireRisk: 6,
  WasteReductionPractices: 7,
  ReducedHailRisk: 8,
  NoPreference: 9,
};

export const PROJECT_LISTING_STATUS = {
  Draft: 1,
  Active: 2,
  Cancelled: 3,
  Deleted: 4,
};

export const CONNECTION_STATUS_CODE = {
  Connected: 1,
  Pending: 2,
  NewConnection: 3,
};

export const CLOSE_REASON_CODE = {
  FundingWasAchievedOnCaptialConnect: 1,
  IHaveMadeEnoughConnectionsWithThisProject: 2,
  IWasFundedThroughOtherChannels: 3,
  Other: 4,
};

export const CONSTRUCTION_TYPE = {
  NewConstruction: 1,
  RetroFitOrRepair: 2,
};

export const CAPITALSOURCES_TYPES = {
  Government: 1,
  Philanthropy: 2,
  DeveloperCashEquity: 3,
  ImpactInvesting: 4,
  FinancialInstitutions: 5,
  OtherPublicOrganizationsOrPrograms: 7,
  OtherPrivateOrganizationsOrPograms: 8,
};

export const IMAGE_TYPES = {
  img_1: ProjImage_1,
  img_2: ProjImage_2,
  img_3: ProjImage_3,
  img_4: ProjImage_4,
  img_5: ProjImage_5,
  img_6: ProjImage_6,
  img_7: ProjImage_7,
  img_8: ProjImage_8,
};

export const IMAGE_TYPES_2X = {
  img_1: ProjImage_2X_1,
  img_2: ProjImage_2X_2,
  img_3: ProjImage_2X_3,
  img_4: ProjImage_2X_4,
  img_5: ProjImage_2X_5,
  img_6: ProjImage_2X_6,
  img_7: ProjImage_2X_7,
  img_8: ProjImage_2X_8,
};

export const ENVIRONMENTAL_INDICATORS = {
  label_1: 1,
  label_2: 2,
  label_3: 3,
  label_4: 4,
  label_5: 5,
  label_6: 6,
};

export const SOCIAL_INDICATORS = {
  label_1: 1,
  label_2: 2,
  label_3: 3,
  label_4: 4,
  label_5: 5,
  label_6: 6,
  label_7: 7,
  label_8: 8,
  label_9: 9,
  label_10: 10,
  label_11: 11,
  label_12: 12,
  label_13: 13,
  label_14: 14,
  label_15: 15,
  label_16: 16,
  label_17: 17,
};

export const SOCIAL_INDICATORS_ONBOARDING = {
  label_1: 1,
  label_2: 2,
  label_3: 3,
  label_4: 4,
  label_5: 5,
  label_6: 6,
  label_7: 7,
  label_8: 8,
  label_9: 9,
  label_10: 10,
  label_11: 11,
  label_12: 12,
  label_13: 13,
  label_14: 14,
  label_15: 15,
  label_16: 16,
  label_17: 17,
};

export const GOVERNANCE_INDICATORS = {
  label_1: 1,
  label_2: 2,
  label_3: 3,
  label_4: 4,
  label_5: 5,
};

export const ORGANIZATION_TYPE = {
  NonProfitOrCharity: 1,
  ForProfit: 2,
  IndigenousOrganization: 3,
  Government: 4,
};

export const FINANCING_TYPE = {
  Grants: 1,
  Debt: 2,
  Loans: 3,
  Equity: 4,
  GovernmentFinancing: 5,
  Other: 9,
};

export const MIN_UNIT = {
  label_1: 1,
  label_2: 10,
  label_3: 25,
  label_4: 50,
  label_5: 100,
  label_6: 500,
  label_7: 1000,
};

export const PORTFOLIO_MIN_UNIT = {
  label_1: 5,
  label_2: 10,
  label_3: 25,
  label_4: 50,
  label_5: 100,
  label_6: 500,
  label_7: 1000,
};

export const MAX_UNIT = {
  label_1: 10,
  label_2: 25,
  label_3: 50,
  label_4: 100,
  label_5: 500,
  label_6: 1000,
  label_7: 5000,
};

export const PROJECT_SIZE = {
  Small: 1,
  Medium: 2,
  Large: 3,
};

export const PROVINCES_CODES = [
  {
    name: "Alberta",
    isoCode: "AB",
    value: 1,
  },
  {
    name: "British Columbia",
    isoCode: "BC",
    value: 2,
  },
  {
    name: "Manitoba",
    isoCode: "MB",
    value: 3,
  },
  {
    name: "New Brunswick",
    isoCode: "NB",
    value: 4,
  },
  {
    name: "Newfoundland and Labrador",
    isoCode: "NL",
    value: 5,
  },
  {
    name: "Northwest Territories",
    isoCode: "NT",
    value: 6,
  },
  {
    name: "Nova Scotia",
    isoCode: "NS",
    value: 7,
  },
  {
    name: "Nunavut",
    isoCode: "NU",
    value: 8,
  },
  {
    name: "Ontario",
    isoCode: "ON",
    value: 9,
  },
  {
    name: "Prince Edward Island",
    isoCode: "PE",
    value: 10,
  },
  {
    name: "Quebec",
    isoCode: "QC",
    value: 11,
  },
  {
    name: "Saskatchewan",
    isoCode: "SK",
    value: 12,
  },
  {
    name: "Yukon",
    isoCode: "YT",
    value: 13,
  },
];

type EnumTypes =
  | typeof PROVINCES
  | typeof DOLLAR_RANGE
  | typeof INVESTMENT_PHASES
  | typeof INVESTMENT_PHASES_ONBOARDING
  | typeof FUNDING_TYPES
  | typeof FUNDING_TYPES_ONBOARDING
  | typeof FUNDING_TYPES_PORTFOLIO
  | typeof ROI_RANGE
  | typeof LOCKUP_PERIOD
  | typeof VULNERABLE_GROUPS
  | typeof CLIMATE_CHANGE_CRITERIA
  | typeof PROJECT_LISTING_STATUS
  | typeof CLOSE_REASON_CODE
  | typeof CONSTRUCTION_TYPE
  | typeof SOCIAL_INDICATORS
  | typeof GOVERNANCE_INDICATORS
  | typeof ENVIRONMENTAL_INDICATORS
  | typeof FINANCING_TYPE
  | typeof PROJECT_SIZE
  | typeof LAND_STATUS_PHASES
  | typeof ORGANIZATION_TYPE
  | typeof AFFORDABILITY_TYPES
  | typeof LAND_FILTER;

/**
 * Returns a key for a given value of an object
 * @param object
 * @param value
 * @returns string | undefined
 */
export function getEnumKeyByValue(object: EnumTypes, value: number) {
  return Object.keys(object).find(
    (key) => object[key as keyof EnumTypes] === value
  );
}

export function currencyFormat(
  translObj: { label: string; value: number }[],
  value: number
) {
  return translObj.find((obj) => obj.value === value)?.label;
}

/**
 * Takes Enum object, preserves their values and returns array of ojects
 * with {label: '', value ''}, can be the translations to pass when constructing form inputs.
 * @param enumobj, translObj
 * @returns
 */
export function getKVArrayFormat(enumObj: any, translObj: any) {
  const arry: { label: string; value: number }[] = [];
  Object.keys(enumObj).forEach(function (key, index) {
    arry.push({
      label: translObj[index],
      value: Number(Object.values(enumObj)[index]),
    });
  });
  return arry;
}

export function getKVArrayFormatForDropdown(enumObj: any, translObj: any) {
  const arry: { label: string; value: any }[] = [];
  Object.keys(enumObj).forEach(function (key, index) {
    arry.push({
      label: translObj[index],
      value:
        Object.values(enumObj)[index] === ""
          ? ""
          : Number(Object.values(enumObj)[index]),
    });
  });
  return arry;
}

export function getImageOptions(enumObj: any, translObj: any) {
  const arry: { img: string; altText: string; value: string }[] = [];
  Object.keys(enumObj).forEach(function (key, index) {
    arry.push({
      img: enumObj[key],
      value: key,
      altText: translObj[index],
    });
  });
  return arry;
}

export function getTagFormat(enumObj: any, translObj: any) {
  const arry: { label: string; selected: boolean; added: boolean }[] = [];
  Object.keys(enumObj).forEach(function (key, index) {
    arry.push({
      label: translObj[index],
      selected: false,
      added: false,
    });
  });
  return arry;
}
