import axios from "axios";
import styles from "./ProjectDetails.module.scss";
import { PageContent } from "../../../components/Layout/PageContent";
import { PageLayout } from "../../../components/Layout/PageLayout";
import { ConnectApi } from "../../../APIAction/ConnectAPI";
import { useNavigate, useParams } from "react-router-dom";
import { ProjectSummary } from "./ProjectSummary";
import { FinancialDetails } from "./FinancialDetails";
import { AffordabilityDetails } from "./AffordabilityDetails";

import { FinancialDetailsChart } from "./FinancialDetailsChart";
import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import { ViewDeveloperInfo } from "../../DeveloperOnboarding/ViewDeveloperProfile/DeveloperInfo";
import { ViewGeneralInfo } from "../../DeveloperOnboarding/ViewDeveloperProfile/ViewGeneralInfo";
import { CloseProject } from "../Modals/CloseProject";
import { ProjectDetailsDropdown } from "../ProjectDetailsDropdown";
import { ProjectCloseSuccess } from "../Modals/ProjectCloseSuccess";
import { DeleteProject } from "../Modals/DeleteProject";
import { ListingProjectAPI } from "../../../APIAction/ListingProjectAPI";
import { DeveloperApi } from "../../../APIAction/DeveloperAPI";
import { ProjectDetails as ProjectDetailsInterface } from "../../../TypesAndInterfaces/ProjectDetailsInterface";
import { DeveloperDetails } from "../../../TypesAndInterfaces/DeveloperDetailsInterface";
import { useUser } from "../../../components/Context/UserContext";
import { getKVArrayFormat, PROVINCES } from "../../../Constants/Enumerations";
import { PendingConnectionStatusI } from "../../../TypesAndInterfaces/ConnectionInterface";
import { InvestorToDevRequest } from "../../../components/ConnectionRequest/InvestorToDev";
import { RequestSentSuccess } from "../../../components/ConnectionRequest/RequestSentSuccess";
import { AwaitingRequestsToast } from "../../../components/common/ConnectionStatusTabs/AwaitingRequestsToast";
import { ImpactCriteriaDeatils } from "./ImpactCriteriaDetails";
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import { InvestorAPI } from "../../../APIAction/InvestorAPI";
import { pink } from '@mui/material/colors';
import { useLocation } from 'react-router-dom';
import { GlossaryDefinition } from "../../../pages/Developers/Modals/GlossaryDefinitionModal/GlossaryDefinition";
import { Link } from "react-router-dom";

import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { ImageGallery } from "../../../components/Form/common/ProjectImages";
import { PDFCover } from "../../../assets/images";

export function ProjectDetails() {
  const { projectId } = useParams();
  const { userState } = useUser();
  const { t } = useTranslation(["project", "profile", "portfolio", "cards", "common"]);
  const sasTokenDocumentStorage = process.env.REACT_APP_DOCUMENT_SAS_TOKEN;

  const navigate = useNavigate();
  const [connectToDeveloper, setConnectToDeveloper] = useState(false);
  const [showRequestSentSuccess, setShowRequestSentSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [closeProjectModal, setCloseProjectModal] = useState<boolean>(false);
  const [projectCloseSuccess, setProjectCloseSuccess] =
    useState<boolean>(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isFinancialOpenModal, setIsFinancialOpenModal] = useState(false);

  const [deleteProject, setDeleteProject] = useState<boolean>(false);

  const [projectData, setProjectData] = useState<ProjectDetailsInterface>();
  const [developerData, setDeveloperData] = useState<DeveloperDetails>();

  const location = useLocation();

  const [check, setCheck] = useState(location.state === "true" ? true : false);

  const [open, setOpen] = useState({ switch: false, message: "success" });



  const provincesTranslated = Object.keys(PROVINCES).map((val) =>
    t(`common:enumerations.provinces.${val}`)
  );
  const provinceOptions = getKVArrayFormat(PROVINCES, provincesTranslated);

  const [pendingConnectionStatus, setPendingConnectionStatus] =
    useState<PendingConnectionStatusI>();

  useEffect(() => {
    const projectListingDataByID = async (id: string) => {
      try {
        const response = await ListingProjectAPI.getProjectListingInfoByID(id);
        setProjectData(response.data);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        return;
      }
    };
    if (projectId) {
      projectListingDataByID(projectId);
    }
    const developerDataById = async (id: string) => {
      try {
        const response = await DeveloperApi.getDeveloperById(id);
        setDeveloperData(response.data);
      } catch {
        setIsLoading(false);
        return;
      }
    };
    if (projectData?.developerID) {
      developerDataById(projectData.developerID);
    }

    if (userState && userState.isOnboarded && projectData?.developerID) {
      const verifyPendingConnectionStatus = async () => {
        const postPendingConnectionData = {
          requestorEntityID: userState.userId,
          requestorEntityType: userState.userPersona,
        };
        try {
          const res = await ConnectApi.checkPendingConnectionStatus(
            postPendingConnectionData
          );
          setPendingConnectionStatus(res?.data);
        } catch (err) {
          if (axios.isAxiosError(err) && err.response?.status !== 404) {
            navigate("/api-error");
          }
        }
      };
        verifyPendingConnectionStatus();
    }
  }, [
    navigate,
    projectId,
    projectData?.developerID,
    userState,
    showRequestSentSuccess,
    projectCloseSuccess,
  ]);

  const isOtherUserProject = projectData?.developerID !== userState.userId;

  const handleDropDownClick = (value: string) => {
    switch (value) {
      case "edit_project":
        navigate(`/projects/edit/${projectId}`);
        break;
      case "close_project":
        setCloseProjectModal(true);
        break;
      case "delete_project":
        setDeleteProject(true);
        break;
      default:
        break;
    }
  };

  const handleConnect = () => {
    setConnectToDeveloper(!connectToDeveloper);
  };

  const handleRequestSent = () => {
    setConnectToDeveloper(false);
    setShowRequestSentSuccess(true);
  };

  const saveProject = async (projectId: string, projectName: string) => {
    if (!userState.isOnboarded) {
      setCheck(false);
      setOpen({ switch: true, message: t("cards:project_cards.add_project_error") })
      return;
    }
    try {
      const info = {
        investorID: userState.userId,
        projectListingID: projectId
      }

      await InvestorAPI.postSaveProject(info);
      setCheck(!check);
      setOpen({ switch: true, message: t("cards:project_cards.add_project") })
      window.dataLayer.push({
        event: "save_project",
        project_name: projectName,
      });
    } catch (error) {
      setCheck(false);
      setOpen({ switch: true, message: t("cards:project_cards.add_project_error") })
    }

  };
  const removeProject = async (projectId: string) => {
    try {

      const info = {
        investorID: userState.userId,
        projectListingID: projectId
      }

      await InvestorAPI.postRemoveSavedProject(info);
      setCheck(!check);
      setOpen({ switch: true, message: t("cards:project_cards.remove_project") })

    } catch (error) {
      setCheck(false);
      setOpen({ switch: true, message: t("cards:project_cards.remove_project_error") })
    }

  };
  const action = (
    <React.Fragment>
      {check &&
        <p onClick={
          () => {
            navigate("/search/projects");
            window.location.reload();
            localStorage.setItem("lastTabIndex", "1");
            localStorage.setItem("lastPage", "1");
          }
        }
          className={styles.link}>{t("cards:project_cards.save_project_link")}</p>}
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={() => setOpen({ ...open, switch: false })}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  return (
    <PageLayout>
      <PageContent>
        <Snackbar
          className={styles.ccSnackbar}
          ContentProps={{
            sx: {
              background: check === false ? "red" : undefined
            }
          }}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={open.switch}
          onClose={() => setOpen({ ...open, switch: false })}
          message={open.message}
          key={"top" + "center"}
          action={action}
        />

        {closeProjectModal && (
          <CloseProject
            closeProjectModal={closeProjectModal}
            setCloseProjectModal={setCloseProjectModal}
            setProjectCloseSuccess={setProjectCloseSuccess}
            projectId={projectId ? projectId : ""}
          />
        )}
        {projectCloseSuccess && (
          <ProjectCloseSuccess
            projectCloseSuccess={projectCloseSuccess}
            setProjectCloseSuccess={setProjectCloseSuccess}
          />
        )}
        {deleteProject && (
          <DeleteProject
            setDeleteProject={setDeleteProject}
            projectListingId={projectId ? projectId : ""}
          />
        )}

        <div className={styles.sectionHeadingContainer}>
          {pendingConnectionStatus?.isConnectionPending && (
            <AwaitingRequestsToast />
          )}
          <h2
            className={styles.sectionHeading}
            data-testid="projectDetail-Heading"
          >
            {t("project:project.main_page.project_open.project_details")}
          </h2>
          {userState.userId === projectData?.developerID ? (
            <ProjectDetailsDropdown
              handleDropDownClick={handleDropDownClick}
              closeFlag={projectData.closeProject}
            />
          ) : <div>
            {userState.userPersona === "Investor" && check ?
              <div>
                <div className={styles.favoriteContainer}>
                  <p className={styles.favoriteText}>{t("cards:project_cards.saved_Text")}</p>
                  <FavoriteIcon
                    sx={{ color: pink[500] }}
                    onClick={() => removeProject(projectId ? projectId : "")} />
                </div>
              </div> :
              userState.userPersona === "Investor" &&
              <div className={styles.favoriteContainer}>
                <p className={styles.favoriteText}>{t("cards:project_cards.save")}</p>
                <FavoriteBorderIcon
                  className={styles.favoriteIcon}
                  onClick={() => saveProject(projectId ? projectId : "", projectData ? projectData.generalInformation.projectName : "")} />
              </div>
            }</div>}
        </div>

        {!isLoading && projectData ? (
          <>
            <div
              className={styles.summaryContainer}
              data-testid="summary-Container"
            >
              {projectData?.generalInformation && (
                <ProjectSummary
                  generalInformation={projectData.generalInformation}
                  projectCloseStatus={projectData.closeProject}
                  affordabilityDetails={projectData.affordabilityDetails ? projectData.affordabilityDetails : undefined}
                  handleConnect={handleConnect}
                  developerID={projectData.developerID}
                  projectListingID={projectData.id}
                  showRequestSentSuccess={showRequestSentSuccess === true}
                  closeProject={projectData.closeProject}
                  projectListingStatus={projectData.projectListingStatus}
                />
              )}
            </div>

            {projectData.generalInformation.projectDescription &&
              <div className={styles.noteContainer}>
                <h2>{t("project:project.main_page.project_open.project_summary")}</h2>
                <p>{projectData.generalInformation.projectDescription}</p>
              </div>
            }

            <div className={styles.noteContainer}>
              <h2>{t("portfolio:portfolio_details.portfolio_summary.note_label")}</h2>
              <p>{t("portfolio:portfolio_details.portfolio_summary.note_content")}</p>
            </div>

            {projectData.generalInformation.blobs && projectData.generalInformation.blobs.length !== 0 &&
              <React.Fragment>
                <h2
                  className={styles.sectionHeading}
                  data-testid="financialDetails-Heading"
                >
                  {t("profile:developer.projects.file_upload.project_images")}
                </h2>
                <div style={{ marginBottom: "5rem" }}>
                  <ImageGallery images={projectData.generalInformation.blobs} />
                </div>
              </React.Fragment>
            }

            {projectData?.affordabilityDetails && (
              <React.Fragment>
                <div className={styles.financialheading}>
                  <h2
                    className={styles.sectionHeading}
                    data-testid="affordability-Heading"
                  >
                    {t("project:project.main_page.project_open.affordability")}
                  </h2>
                  <button
                    type="button"
                    className={styles.glossaryLink}
                    data-testid="glossary-page-link"
                    onClick={() => {
                      setIsOpenModal(true);
                    }}
                  >
                    {t("portfolio:portfolio_details.main_page.project_open.glossry_link")}
                  </button>

                  {isOpenModal && (
                    <GlossaryDefinition
                      setCloseGlossaryModal={setIsOpenModal}
                      title={t("profile:developer.projects.add_listing_form.affordabilityGlossary.title")}
                      glossaryContent={t(
                        "profile:developer.projects.add_listing_form.affordabilityGlossary.affordabilityGlossaryContent",
                        {
                          returnObjects: true,
                        }
                      )}
                      paraContent={t(
                        "profile:developer.projects.add_listing_form.affordabilityGlossary.affordabilityParaContent",
                        {
                          returnObjects: true,
                        }
                      )}
                      glossaryLink={t(
                        "profile:developer.projects.add_listing_form.affordabilityGlossary.viewFullGlossaryLink"
                      )}
                      closeText={t("profile:developer.projects.add_listing_form.affordabilityGlossary.closeButton")}
                      selectedId="#2"
                    />
                  )}
                </div>
                <AffordabilityDetails
                  affordabilityDetails={projectData?.affordabilityDetails}
                />
              </React.Fragment>
            )}
            <div className={styles.financialheading}>
              <h2
                className={styles.sectionHeading}
                data-testid="financialDetails-Heading"
              >
                {t("project:project.main_page.project_open.financial_details_chart")}
              </h2>
              <button
                type="button"
                className={styles.glossaryLink}
                data-testid="glossary-page-link"
                onClick={() => {
                  setIsFinancialOpenModal(true);
                }}
              >
                {t("portfolio:portfolio_details.main_page.project_open.glossry_link")}
              </button>


            </div>
            {isFinancialOpenModal && (
              <GlossaryDefinition
                setCloseGlossaryModal={setIsFinancialOpenModal}
                title={t("profile:glossary.capitalAcquiredGlossaryContent.title")}
                glossaryContent={t(
                  "profile:glossary.capitalAcquiredGlossaryContent.capitalAcquiredGlossaryList",
                  { returnObjects: true }
                )}
                glossaryLink={t("profile:glossary.viewFullGlossaryLink")}
                closeText={t("profile:glossary.closeButton")}
                selectedId="#4"
              />
            )}
            {projectData?.financialDetail &&
              projectData?.financialDetail.totalProjectCost && (
                <FinancialDetailsChart
                  financialDetail={projectData?.financialDetail}
                />
              )}

            <div className={styles.noteContainer}>
              <h2>{t("portfolio:portfolio_details.portfolio_summary.note_label")}</h2>
              <p>{t("portfolio:portfolio_details.portfolio_summary.note_content_accuracy_project")}</p>
            </div>

            {projectData?.financialDetail && (
              <FinancialDetails
                financialDetail={projectData?.financialDetail}
              />
            )}
            <div
              className={styles.impactContainer}
              data-testid="impact-summary-Container"
            >
              <h2
                className={styles.sectionHeading}
                data-testid="impact-criteria-Heading"
              >
                {t("project:project.main_page.project_open.impact_criteria")}
              </h2>
              {projectData?.impactInformation && (
                <ImpactCriteriaDeatils
                  projectListingID={projectData.id}
                  impactCriteria={projectData?.impactInformation}
                  otherUserProjectflag={isOtherUserProject}
                  projectStatus={projectData?.projectListingStatus}
                />
              )}
              <div className={styles.noteContainer}>
                <h2>{t("portfolio:portfolio_details.portfolio_summary.note_label")}</h2>
                <p>{t("portfolio:portfolio_details.portfolio_summary.note_impact_criteria")}</p>
              </div>
            </div>

            {projectData.documentationDetail &&
              projectData.documentationDetail.blobs &&
              projectData.documentationDetail.blobs.length !== 0 &&
              <div>
                <h2
                  className={styles.sectionHeading}
                >
                  {t("profile:developer.projects.file_upload_doc.documentation")}
                </h2>
                <div className={styles.docsContainer}>
                  {projectData.documentationDetail.blobs?.map((item: any, idx: number) => (
                    item.blobStatus !== 2 ?
                      <div className={styles.uploadCard} key={idx}>
                        <div className={styles.imageContent}>
                          <div>

                            <img
                              className={styles.image}
                              src={PDFCover}
                              alt={item.fileName}
                            />

                          </div>
                          <div className={styles.imagetitle}>
                            <p>{item.fileName} <span className={styles.imageSize}>{item.blobSize} MB</span></p>
                          </div>

                          <div className={styles.downloadLink}
                            onClick={(e) => {
                              e.preventDefault();
                              const a = document.createElement('a');
                              a.style.display = 'none';
                              a.download = item.fileName;
                              a.target = '_blank';
                              a.href = item.blobUri + sasTokenDocumentStorage;
                              document.body.appendChild(a);
                              a.click();
                            }}
                          >
                            {t("profile:developer.projects.file_upload_doc.download_file")}
                          </div>
                        </div>
                      </div> :
                      <React.Fragment></React.Fragment>
                  ))}
                </div>
                <div className={styles.noteContainer}>
                  <h2>{t("portfolio:portfolio_details.portfolio_summary.note_label")}</h2>
                  <p>{t("profile:developer.projects.file_upload_doc.doc_note_project_details")}</p>
                </div>
              </div>
            }
            <h2
              className={styles.sectionHeading}
              data-testid="developerProfile-Heading"
            >
              {t("project:project.main_page.project_open.developer_profile")}
            </h2>
            {developerData?.generalInformation ? (
              <ViewGeneralInfo
                genInfo={developerData.generalInformation}
                provinceOptions={provinceOptions}
                projectDetailsPage={true}
                id={developerData.id}

              />
            ) : null}

            {developerData?.developerExperience ? (
              <div className={styles.developerProjectsWrapper}>
                <ViewDeveloperInfo
                  DeveloperInfo={developerData?.developerExperience}
                  provinceOptions={provinceOptions}
                />
              </div>
            ) : null}
          </>
        ) : null}

        {connectToDeveloper &&
          developerData?.generalInformation &&
          projectData && (
            <InvestorToDevRequest
              projectData={projectData}
              openModal={connectToDeveloper}
              handleClose={handleConnect}
              developerInfo={developerData}
              handleSubmitResponse={handleRequestSent}
            />
          )}
        {showRequestSentSuccess && (
          <RequestSentSuccess
            openModal={showRequestSentSuccess}
            handleClose={() => setShowRequestSentSuccess(false)}
          />
        )}
      </PageContent>
    </PageLayout>
  );
}
