import { Form, Formik } from "formik";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { RadioInputColumn } from "../common/RadioInputColumn";
import styles from "./styles.module.scss";
import { CheckBoxGroupColumn } from "../common/CheckBoxGroupColumn";
import { TextArea } from "../common/TextArea";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useUser } from "../../Context/UserContext";
import { useEffect, useState } from "react";
import { handleApiErrorRedirect } from "../../../APIAction/config";
import { GlossaryDefinition } from "../../../pages/Developers/Modals/GlossaryDefinitionModal/GlossaryDefinition";

import { ListingPortfolioAPI } from "../../../APIAction/ListingPortfolioAPI";

const apiErrorPage = "/api-error";

export function PortfolioAffordabilityBenchmarkForm({
  handleResponse,
  portfolioListingId,
}: any) {
  const { t } = useTranslation(["profile", "portfolio"]);
  const navigate = useNavigate();
  const { userState } = useUser();

  const [isDataExist, setIsDataExist] = useState(false);
  const [isApiLoading, setIsApiLoading] = useState(false);
  const [affordabilityInfo, setAffordabilityInfo] = useState<any>(null);
  const [isOpenModal, setIsOpenModal] = useState(false);


  const getAffordabilityBenchmarkInfo = async (id: string) => {
    try {
      setIsApiLoading(true);
      const res = await ListingPortfolioAPI.getPortfolioAffordabilityInfoById(id);
      const data = res.data;
      setIsApiLoading(false);
      setIsDataExist(true);
      setAffordabilityInfo(data);

    } catch (error) {
      setIsApiLoading(false);
      handleApiErrorRedirect(error, navigate);
    }
  };

  useEffect(() => {
    portfolioListingId && getAffordabilityBenchmarkInfo(portfolioListingId);

  }, [portfolioListingId]);


  const radioOptions = [
    {
      label: portfolioListingId ? t("developer.projects.add_listing_form.affordability_benchmark_portfolio_edit_yes") : t(
        "developer.projects.add_listing_form.affordability_benchmark_portfolio_yes"
      ),
      value: "true",
    },
    {
      label: portfolioListingId ? t("developer.projects.add_listing_form.affordability_benchmark_portfolio_edit_no") : t(
        "developer.projects.add_listing_form.affordability_benchmark_portfolio_no"
      ),
      value: "false",
      description: portfolioListingId && t(
        "developer.projects.add_listing_form.affordability_benchmark_portfolio_no_description"),
      disabled: isDataExist ? true : false

    },

  ];

  const initialValues = {
    affordabilityThresholdFlag: portfolioListingId ? "true" : "",
    affordabilityDescription: " ",
    projectAffordabilityTypes: []

  };

  const affordabilitySchema = Yup.object().shape({
    affordabilityThresholdFlag: Yup.boolean().required(
      t("developer.projects.add_listing_form.error_msg")
    ),
    projectAffordabilityTypes: Yup.array()
      .required(
        t(
          "developer.projects.financial_details_form_error.required_error_message"
        )
      )
      .min(
        1,
        t(
          "developer.projects.financial_details_form_error.required_error_message"
        )
      ),
  });

  const affirdabilityTypesOptions = [
    {
      label: t(
        "common:enumerations.affordability_Types.SocialImpactingHousing"
      ),
      value: 1,
      description: t(
        "developer.projects.add_listing_form.socialImpactHousingDescription"
      )
    },
    {
      label: t(
        "common:enumerations.affordability_Types.FocusOnAffordableHousing"
      ),
      value: 2,
      description: t(
        "developer.projects.add_listing_form.affordableHousingDescription"
      )
    },
    {
      label: t(
        "common:enumerations.affordability_Types.BalanceBetweenAffordableAndSupply"
      ),
      value: 3,
      description: t(
        "developer.projects.add_listing_form.affirdabilityAndSupplyDescription"
      )
    },
    {
      label: t(
        "common:enumerations.affordability_Types.FocusOnHousingSupply"
      ),
      value: 4,
      description: t(
        "developer.projects.add_listing_form.housingSupplydescription"
      )
    },
  ]

  const apiData = {
    affordabilityThresholdFlag: affordabilityInfo !== null && affordabilityInfo.affordabilityThresholdFlag ? "true" : "false",
    projectAffordabilityTypes: affordabilityInfo !== null && affordabilityInfo?.projectAffordabilityTypes.map((opt: number) => opt.toString()),
    affordabilityDescription: affordabilityInfo !== null && affordabilityInfo?.affordabilityDescription,
  };



  const submitAffordabilityInformation = async (formValues: Object) => {
    try {
      //  setIsApiLoading(true); 
      const response = await ListingPortfolioAPI.postAffordabilityInfo(formValues);
      //setIsApiLoading(false);

      handleResponse("Affordability", true, response.data.id);
    } catch (error) {
      // setIsApiLoading(false);
      handleResponse("Affordability", false);

      if (axios.isAxiosError(error) && error.response?.status !== 404) {
        navigate(apiErrorPage);
      }
    }
  };



  const updateAffordabilityInformation = async (formValues: Object) => {
    try {
      //  setIsApiLoading(true);
      const response = await ListingPortfolioAPI.updateAffordabilityInfo(portfolioListingId, formValues);
      //setIsApiLoading(false);

      handleResponse("Affordability", true, response.data.id);
    } catch (error) {
      // setIsApiLoading(false);
      handleResponse("Affordability", false);

      if (axios.isAxiosError(error) && error.response?.status !== 404) {
        navigate(apiErrorPage);
      }
    }
  };

  return (
    <Formik
      initialValues={isDataExist ? apiData : initialValues}
      validationSchema={affordabilitySchema}
      validateOnChange={false}
      validateOnBlur={false}
      enableReinitialize={true}
      onSubmit={(values) => {
        if (values.affordabilityThresholdFlag === "true") {
          let updatedValues = null;
          if (isDataExist) {
            updatedValues = {
              portfolioID: portfolioListingId,
              affordabilityThresholdFlag: values.affordabilityThresholdFlag === "true" ? true : false,
              affordabilityDescription: values.affordabilityDescription === null || values.affordabilityDescription.length === 0 ? null : values.affordabilityDescription,
              projectAffordabilityTypes: values.projectAffordabilityTypes.map((opt: string) => Number(opt)),

            }
          } else {
            updatedValues = {
              type: "portfolio",
              developerID: userState.userId,
              organizationName: userState.userOrganization,
              organizationTypes: userState.userOrganizationTypes,
              affordabilityThresholdFlag: values.affordabilityThresholdFlag === "true" ? true : false,
              affordabilityDescription: values.affordabilityDescription === null || values.affordabilityDescription.length === 0 ? null : values.affordabilityDescription,
              projectAffordabilityTypes: values.projectAffordabilityTypes.map((opt: string) => Number(opt)),

            }
          }
          isDataExist ? updateAffordabilityInformation(updatedValues) :
            submitAffordabilityInformation(updatedValues);
        }
      }}
    >
      {({ values }) => (
        <Form className={styles.formElement} noValidate>
          <h4 className={styles.formQuestion} data-testid="benchmark_question">
            {portfolioListingId ? t(
              "developer.projects.add_listing_form.affordability_criteria_edit_portfolio_question"
            ) : t("developer.projects.add_listing_form.affordability_criteria_portfolio_question"
            )}
          </h4>

          <div
            className={styles.fieldContainer}
            data-testid="affordability-options"
          >
            <RadioInputColumn
              fieldName="affordabilityThresholdFlag"
              options={radioOptions}
              data-testid="affordability-radio"
            />
          </div>

          {values.affordabilityThresholdFlag === "false" ? (
            <p>
              {t(
                "developer.projects.add_listing_form.affordability_benchmark_help_text_portfolio"
              )}{" "}
              <a
                href="mailto:capital.connect@cmhc-schl.gc.ca"
                className={styles.contactLink}
              >
                capital.connect@cmhc-schl.gc.ca
              </a>
            </p>
          ) : values.affordabilityThresholdFlag === "true" && (
            <>
              <CheckBoxGroupColumn
                fieldName="projectAffordabilityTypes"
                formQuestionText={t(
                  "developer.projects.add_listing_form.project_affordability_types_label"
                )
                }
                options={affirdabilityTypesOptions}
                setIsOpenModal={setIsOpenModal}

                linkText={t("developer.projects.add_listing_form.link_label")}
              />
              {isOpenModal && (
                <GlossaryDefinition
                  setCloseGlossaryModal={setIsOpenModal}
                  title={t("developer.projects.add_listing_form.affordabilityGlossary.title")}
                  glossaryContent={t(
                    "developer.projects.add_listing_form.affordabilityGlossary.affordabilityGlossaryContent",
                    {
                      returnObjects: true,
                    }
                  )}
                  paraContent={t(
                    "developer.projects.add_listing_form.affordabilityGlossary.affordabilityParaContent",
                    {
                      returnObjects: true,
                    }
                  )}
                  glossaryLink={t(
                    "developer.projects.add_listing_form.affordabilityGlossary.viewFullGlossaryLink"
                  )}
                  closeText={t("developer.projects.add_listing_form.affordabilityGlossary.closeButton")}
                  selectedId="#2"
                />
              )}
              <TextArea
                fieldName="affordabilityDescription"
                labelText={t("developer.projects.add_listing_form.affordability_description")}
                instructionText={t("developer.projects.add_listing_form.decription_instructionText")}
                charLimit={300}
              />

              <button
                type="submit"
                className={styles.formButton}
                data-testid="submit-button"
              >
                {t("portfolio:portfolio_details.save_and_continue")}
              </button>
            </>
          )}
        </Form>
      )}
    </Formik>
  );
}
