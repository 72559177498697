import { useNavigate } from "react-router-dom";
import { IterableValuesDisplay } from "./IterableValuesDisplay";
import styles from "./styles.module.scss";
import { ProjectStatus } from "./ProjectStatus";
import { useTranslation } from "react-i18next";
import {
  AFFORDABILITY_TYPES,
  currencyFormat,
  DOLLAR_RANGE,
  FUNDING_TYPES,
  getEnumKeyByValue,
  getKVArrayFormat,
  INVESTMENT_PHASES,
  LAND_STATUS_PHASES,
  ORGANIZATION_TYPE,
  PROVINCES,
  ROI_RANGE,
} from "../../../Constants/Enumerations";
import { PROJECT_LISTING_STATUS } from "../../../../src/Constants/Enumerations";
import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import { pink } from "@mui/material/colors";
import { useUser } from "../../Context/UserContext";
import { InvestorAPI } from "../../../APIAction/InvestorAPI";
import { ProjectCardType } from "../../../TypesAndInterfaces/ProjectDetailsInterface";
import React, { useEffect, useRef, useState } from "react";

import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Chip from "@mui/material/Chip";
import Tooltip from "@mui/material/Tooltip";
import { Social, Supply, Focus, Balance } from "../../../assets/icons";
import { numberWithCommas } from "../../../Constants/Utils/FormateNumber";
import { ProjectImg } from "../../../assets/images";

type Props = {
  data: ProjectCardType;
};

export function SavedProjectCard({ data }: Props) {
  const {
    organizationName,
    organizationTypes,
    projectListingID,
    province,
    city,
    projectName,
    investmentPhases,
    fundingTypes,
    capitalCostRangeMaximum,
    capitalCostRangeMinimum,
    financingRangeMinimum,
    financingRangeMaximum,
    projectListingStatus,
    totalFundingNeeded,
    totalProjectCost,
    projectAffordabilityType,
    totalUnitCount,
    belowAverageMarketRentUnitCount,
    impactInformation,
    landStatus,
    primaryBlobUri
  } = data;
  const totalNumberOfUnitsPercent = totalUnitCount && belowAverageMarketRentUnitCount ? (belowAverageMarketRentUnitCount / totalUnitCount) * 100 : 0;
  const sasTokenDocumentStorage = process.env.REACT_APP_DOCUMENT_SAS_TOKEN;

  const { t, i18n } = useTranslation(["common", "cards"]);

  const { userState } = useUser();
  const [check, setCheck] = useState(data.saved);

  const langEng = i18n.language === 'en';
  const langFr= i18n.language === 'fr';
  

  const [open, setOpen] = useState({ switch: false, message: "success" });
  const [istoolTipOpen, setIstoolTipOpen] = useState(-1);
  const [tooltipLabel, setTooltipLabel] = useState("");
  const [totalNumberOfUnits, setTotalNumberOfUnits] = useState(false);
  const tooltipRef = useRef<HTMLDivElement>(null);

  const navigate = useNavigate();
  const provinceMappedValue = getEnumKeyByValue(PROVINCES, province);
  const projectLocation = {
    city: city,
    province: t(`common:enumerations.provinces.${provinceMappedValue}`),
  };

  const projectStagesMappedValues = investmentPhases.map((val) =>
    getEnumKeyByValue(INVESTMENT_PHASES, val)
  );
  const projectStagesTranslated = projectStagesMappedValues.map((val) =>
    t(`common:enumerations.investment_phases.${val}`)
  );

  const fundingTypesMappedValues = fundingTypes.map((val) =>
    getEnumKeyByValue(FUNDING_TYPES, val)
  );
  const fundingTypesTranslated = fundingTypesMappedValues?.filter((val: any) => val !== undefined && val !== "").map((val: any) =>
    t(`common:enumerations.funding_types.${val}`)
  );

  const targetRoiMaxMappedValue = getEnumKeyByValue(
    ROI_RANGE,
    capitalCostRangeMaximum
  );
  const targetRoiMinMappedValue = getEnumKeyByValue(
    ROI_RANGE,
    capitalCostRangeMinimum
  );

  const dolllarRangesTrans = Object.keys(DOLLAR_RANGE).map((val) =>
    t(`common:enumerations.dollar_range.${val}`)
  );
  const dollarOptions = getKVArrayFormat(DOLLAR_RANGE, dolllarRangesTrans).map(
    ({ label, value }) => ({ label: label, value: value })
  );

  let financingRangeToDisplay = `${currencyFormat(
    dollarOptions,
    financingRangeMinimum
  )} 
  - ${currencyFormat(dollarOptions, financingRangeMaximum)}`;

  if (financingRangeMinimum == null && financingRangeMaximum == null) {
    financingRangeToDisplay = '$'
  }

  const landStatusMappedValue = getEnumKeyByValue(
    LAND_STATUS_PHASES,
    landStatus
  );

  const landStatusTranslated = landStatusMappedValue
    ? t(`common:enumerations.land_status_card.${landStatusMappedValue}`)
    : "--";

  const organizationTypeTranslations = Object.keys(ORGANIZATION_TYPE).filter(val => val !== undefined && val !== "").map(
    (val) => t(`common:enumerations.organization_type.${val}`)
  );
  const organizationTypeOptions = getKVArrayFormat(
    ORGANIZATION_TYPE,
    organizationTypeTranslations
  );

  const organizationTypeValue =
    organizationTypes &&
    organizationTypes.length > 0 &&
    organizationTypes.map(
      (val, idx) =>
        organizationTypeOptions.find((obj) => obj.value === val)?.label
    );

  const financialDetails = [
    {
      label: t("cards:financing_range_label"),
      value: financingRangeToDisplay,
    },
  ];


  const projectImg = primaryBlobUri ? primaryBlobUri + sasTokenDocumentStorage : ProjectImg;


  const affordabilityTypesTranslated = Object.keys(AFFORDABILITY_TYPES).map(
    (val) => t(`common:enumerations.affordabilityCriteria_type.${val}`)
  );

  const affordabilityTypes = getKVArrayFormat(
    AFFORDABILITY_TYPES,
    affordabilityTypesTranslated
  );

  const badges = [Social, Focus, Balance, Supply];
  const affordabilitywithBadge = affordabilityTypes.map((type, index) => ({
    ...type,
    src: badges[index % badges.length],
  }));

  const affordabilityInfo = affordabilitywithBadge.filter(
    (option) =>
      projectAffordabilityType && projectAffordabilityType === option?.value
  );
  useEffect(() => {
    affordabilityInfo.map((i) => {
      if (i.value === 1) {
        setTotalNumberOfUnits(true)
      }
    })
  }, [affordabilityInfo])

  const environmental = impactInformation?.environmental?.length;
  const social = impactInformation?.social?.length;
  const governance = impactInformation?.governance?.length;

  const handleProjectClick = () => {
    // 1-Draft, 2-Active, 3-Cancelled, 4-Deleted
    projectListingStatus === PROJECT_LISTING_STATUS.Draft &&
      navigate(`/projects/edit/${projectListingID}`);
    (projectListingStatus === PROJECT_LISTING_STATUS.Active ||
      projectListingStatus === PROJECT_LISTING_STATUS.Cancelled) &&
      navigate(`/projects/${projectListingID}`, {
        state: data.saved ? "true" : "false",
      });
  };

  const saveProject = async (projectId: string) => {
    try {
      const info = {
        investorID: userState.userId,
        projectListingID: projectId,
      };

      await InvestorAPI.postSaveProject(info);
      setCheck(!check);
      setOpen({
        switch: true,
        message: t("cards:project_cards.added_project_to_favourites"),
      });

      window.dataLayer.push({
        event: "save_project",
        project_name: projectName,
      });

      data.saved = true;
    } catch (error) {
      setOpen({
        switch: true,
        message: t("cards:project_cards.add_project_error"),
      });
    }
  };
  const removeProject = async (projectId: string) => {
    try {
      const info = {
        investorID: userState.userId,
        projectListingID: projectId,
      };

      await InvestorAPI.postRemoveSavedProject(info);
      setCheck(!check);
      setOpen({
        switch: true,
        message: t("cards:project_cards.removed_project_from_favourites"),
      });

      data.saved = false;
    } catch (error) {
      setOpen({
        switch: true,
        message: t("cards:project_cards.remove_project_error"),
      });
    }
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        tooltipRef.current &&
        !tooltipRef.current.contains(event.target as Node)
      ) {
        setIstoolTipOpen(-1); // Close tooltip when clicking outside
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={() => setOpen({ ...open, switch: false })}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );
  return (
    <div className={styles.projectCardContainer}>
      <Snackbar
        className={styles.ccSnackbar}
        ContentProps={{
          sx: {
            background: check === false ? "red" : undefined,
          },
        }}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={open.switch}
        onClose={() => setOpen({ ...open, switch: false })}
        message={open.message}
        key={"top" + "center"}
        action={action}
      />
      <div className={styles.projectListSummary}>
        <div className={styles.imgContainer}>
          <img
            className={styles.projectImage}
            src={projectImg}
            alt={"Image"}
            data-testid="proj-image"
          />
        </div>
        <div className={styles.projectListDetails}>
          <div className={styles.projectStatus}>
            <div className={styles.listStatus}>
              <ProjectStatus status={data.projectListingStatus} />
            </div>
            <div className={styles.affordabilityBadges} ref={tooltipRef}>
              {affordabilityInfo?.map(
                (opt, index) =>
                  opt?.src && (
                    <Tooltip
                      arrow
                      open={index === istoolTipOpen}
                      title={tooltipLabel}
                      componentsProps={{
                        tooltip: {
                          sx: {
                            bgcolor: "#E0E4E7",
                            color: "#454E56",
                            "& .MuiTooltip-arrow": {
                              color: "#E0E4E7",
                            },
                          },
                        },
                      }}
                      placement="top"
                    >
                      <button
                        type="button"
                        className={styles.badgeButton}
                        onClick={() => {
                          setIstoolTipOpen(index);
                          setTooltipLabel(opt?.label);
                        }}
                      >
                        <img
                          src={opt?.src}
                          className={styles.badgeIcon}
                          alt={opt?.label}
                        />
                      </button>
                    </Tooltip>
                  )
              )}
            </div>
          </div>
          <button
            type="button"
            className={styles.projectCardLink}
            data-testid="proj-name-link"
            onClick={handleProjectClick}
          >
            {projectName ? projectName : t("cards:project_cards.untitle_project")}
          </button>
          <p className={styles.projectDetailText} data-testid="org-name">
            {organizationName} | {organizationTypeValue &&
              organizationTypeValue?.map(
                (val, idx) =>
                  `${val}${idx < organizationTypeValue.length - 1 ? ", " : ""
                  }`
              )}
          </p>
          <p
            className={styles.projectDetailText}
            data-testid="address"
          >{`${projectLocation.city}, ${projectLocation.province}`}</p>
        </div>
        <div
          className={styles.projectListFavorite}
          data-testid="project-status"
        >
          {userState.userPersona === "Investor" && data.saved && check ? (
            <FavoriteIcon
              sx={{ color: pink[500] }}
              onClick={() => removeProject(projectListingID)}
            />
          ) : (
            userState.userPersona === "Investor" && (
              <FavoriteBorderIcon
                className={styles.favoriteIcon}
                onClick={() => saveProject(projectListingID)}
              />
            )
          )}
        </div>
      </div>

      <div className={styles.projectListContainer}>
        <div className={styles.projectDataContainer}>
          <IterableValuesDisplay
            dataLabel={t("cards:stage_of_project")}
            arrayOfValues={projectStagesTranslated}
          />
          <div>
            <p className={styles.iterableLabel}>
              {t("cards:land_status_label")}
            </p>
            <p className={styles.iterableValue}>{landStatusTranslated}</p>
          </div>
          <div>
            <p className={styles.iterableLabel}>
              {t("cards:capital_needed")}
            </p>
            <p className={styles.iterableValue}>
              {totalFundingNeeded ? (langEng ? "$ " : "") + numberWithCommas(totalFundingNeeded) + (langFr ? " $" : "") : financingRangeToDisplay}
            </p>
          </div>
          <div>
            <p className={styles.iterableLabel}>{t("cards:project_cost")}</p>
            <p className={styles.iterableValue}>
              {totalProjectCost && (langEng ? "$ " : "") + numberWithCommas(totalProjectCost)+ (langEng ? " $" : "")}
            </p>
          </div>
          <IterableValuesDisplay
            dataLabel={t("cards:funding_type_label")}
            arrayOfValues={fundingTypesTranslated}
          />
          <div>
            <p className={styles.iterableLabel}>{t("cards:total_units")}</p>
            <p className={styles.iterableValue}>
              {/* {totalUnitCount && totalUnitCount} */}
              {totalNumberOfUnits ? `${totalUnitCount && totalUnitCount} (100% ${t("cards:affordable_units")})`
                :
                totalUnitCount !== null ?
                  `${totalUnitCount && totalUnitCount} (${(totalNumberOfUnitsPercent.toFixed(0))}% ${t("cards:affordable_units")})` : ""}
            </p>
          </div>
        </div>
        <div className={styles.impactDetails}>
          <p className={`${styles.iterableLabel} ${styles.labelMargin}`}>{t("cards:impact_criteria")}</p>
          <p className={styles.iterableValue}>
            {environmental > 0 && (
              <Chip
                label={`${environmental} ${t("cards:environmental")}`}
                style={{ backgroundColor: "#E6EFF3", marginRight: "0.5rem" }}
              />
            )}
            {social > 0 && (
              <Chip
                label={`${social} ${t("cards:social")}`}
                style={{ backgroundColor: "#E6EFF3", marginRight: "0.5rem" }}
              />
            )}
            {governance > 0 && (
              <Chip
                label={`${governance} ${t("cards:governance")}`}
                style={{ backgroundColor: "#E6EFF3" }}
              />
            )}
          </p>
        </div>
      </div>
    </div>
  );
}
