import { useTranslation } from "react-i18next";
import styles from "./styles.module.scss";
import { Sad_Face } from "../../assets/images/index";

interface Props {
  isDeveloper?: boolean;
}

export const NoInvestorResultsView = ({ isDeveloper }: Props) => {
  const { t } = useTranslation("filter");

  return (
    <div className={styles.noInvestorsFound}>
      <img
        data-testid="crying-face"
        src={Sad_Face}
        alt={t("searchInvestorsPage.noResultsImageAlt")}
      />
      <div>
        <h2 data-testid="no-investors-found-heading">
          {isDeveloper
            ? t("searchInvestorsPage.noResultsTitleForDeveloper")
            : t("searchInvestorsPage.noResultsTitle")}
        </h2>
        <p data-testid="no-investors-found-heading">
          {isDeveloper
            ? t("searchInvestorsPage.noResultsTextForDeveloper")
            : t("searchInvestorsPage.noResultsText")}
        </p>
      </div>
    </div>
  );
};
