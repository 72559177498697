import { useTranslation } from "react-i18next";
import styles from "./styles.module.scss";

export function MyProjectsHeading() {
  const { t } = useTranslation("profile");

  return (
    <div className={styles.headerContainer}>
      <h2
        className={styles.mainHeading}
        data-testid="developer-project-heading"
      >
        {t("developer.projects.developer_project.heading")}
      </h2>
    </div>
  );
}
