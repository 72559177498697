import { Formik, Form } from "formik";
import { useTranslation } from "react-i18next";
import styles from "./SearchFilterSidebar.module.scss";
import { Divider } from "@mui/material";
import { CheckBoxGroup } from "../../Form/common/CheckBoxGroup";
import {
  PROVINCES,
  FUNDING_TYPES,
  ORGANIZATION_TYPE,
  ROI_RANGE,
  PROJECT_SIZE,
  getKVArrayFormat,
  getEnumKeyByValue,
  PROVINCES_CODES,
} from "../../../Constants/Enumerations";
import Select, { components, Options, OptionProps } from "react-select";
import { useState } from "react";
import { City } from "country-state-city";
import { DropdownOptionType } from "./ProjectsFilterSidebar";

type Props = {
  setFilteredResults: Function;
  setFilters: Function;
  setPage: Function;
  setSelectedFilter?: Function;
  selectedFilter?: Object;
  setCityList: Function;
  cityList: any;
};

type InvestorFilterValues = {
  provincialFocusAreas?: string[];
  fundingTypes?: string[];
  roiTargetMinimum?: string;
  roiTargetMaximum?: string;
  cities?: string[];
  organizationTypes?: string[];
  offeringLand?: string[];
  projectSizes?: string[];
};

const customStyles = {
  control: (base: any) => ({
    ...base,
    minHeight: 35,
    maxHeight: 120,
    width: 240,
    maxWidth: "90%",
    marginLeft: "1rem",
    overflow: "auto",
    disabled: (styles: any) => ({
      ...styles,
      backgroundColor: 'red', // Color of the disabled state
    }),

  }),
};

type GAEvents = {
  [key: string]: any;
  event: string;
};

export function DevelopersFilterSidebar(props: Props) {
  const { t } = useTranslation(["filter", "common"]);
  const [isoCode, setIsoCode] = useState("");
  const { cityList, setCityList } = props;
  const initialValues = {
    provincialFocusAreas: [],
    fundingTypes: [],
    projectSizes: [],
    cities: [],
  };

  const noFilters = {
    start: 0,
    pageSize: 6,
    sortDirection: 0,
  };

  const updateFilters = {
    start: 0,
    pageSize: 6,
    sortColumn: "organizationName",
    sortDirection: 0,
    filter: {},
  };

  const averageProjectSizeTranslation = Object.keys(PROJECT_SIZE).map((val) =>
    t(`common:enumerations.project_size.${val}`)
  );
  const averageProjectSizeRange = getKVArrayFormat(
    PROJECT_SIZE,
    averageProjectSizeTranslation
  );

  const OrganizationTypeTranslations = Object.keys(ORGANIZATION_TYPE).filter(val => val !== undefined && val !== "").map(
    (val) => t(`common:enumerations.organization_type.${val}`)
  );
  const OrganizationTypeOptions = getKVArrayFormat(
    ORGANIZATION_TYPE,
    OrganizationTypeTranslations
  );


  const provincesTranslated = Object.keys(PROVINCES).map((val) =>
    t(`common:enumerations.provinces.${val}`)
  );
  const provinceOptions = getKVArrayFormat(PROVINCES, provincesTranslated);

  const funingTypesTranslated = Object.keys(FUNDING_TYPES).map((val) =>
    t(`common:enumerations.funding_types.${val}`)
  );
  const fundingOptions = getKVArrayFormat(FUNDING_TYPES, funingTypesTranslated);

  const roirangesTrans = Object.keys(ROI_RANGE).map((val) =>
    t(`common:enumerations.roi_range.${val}`)
  );
  const roiPlaceholder = { label: t("selectPlaceHolder"), value: "" };
  const roiOptions: { label: string; value: string | number }[] =
    getKVArrayFormat(ROI_RANGE, roirangesTrans);
  roiOptions.unshift(roiPlaceholder);

  function handleGa(v: InvestorFilterValues) {
    const gaFilters: GAEvents = { event: "apply_filter" };
    // Flush related filters from Project Search
    gaFilters["project_phase"] = undefined;
    gaFilters["financing_range"] = undefined;
    gaFilters["ESG_impact_criteria"] = undefined;
    
    if (v.provincialFocusAreas && v.provincialFocusAreas.length > 0) {
      const provinceFocusAreaMappedValue = v.provincialFocusAreas.map((val) =>
        getEnumKeyByValue(PROVINCES, Number(val))
      );
      const provinceFocusAreaTranslated = provinceFocusAreaMappedValue.map(
        (val) => t(`common:enumerations.provinces.${val}`)
      );
      provinceFocusAreaTranslated.sort();
      gaFilters["provincial_focus_area"] =
        provinceFocusAreaTranslated.join(", ");
    }

    if (v.organizationTypes && v.organizationTypes.length > 0) {
      const organizationTypesMappedValue = v.organizationTypes.map((val) =>
        getEnumKeyByValue(ORGANIZATION_TYPE, Number(val))
      );
      const organizationTypesTranslatedGa = organizationTypesMappedValue.map((val) =>
        t(`common:enumerations.organization_type.${val}`)
      );
      organizationTypesTranslatedGa.sort();
      gaFilters["organization_type"] = organizationTypesTranslatedGa.join(", ");
    }

    if (v.cities && v.cities.length > 0) {
      const cities = v.cities;
      cities.sort();
      gaFilters["city"] = cities.join(", ");
    }

    if (v.projectSizes && v.projectSizes.length > 0) {
      const projectSizesMappedValue = v.projectSizes.map((val) =>
        getEnumKeyByValue(PROJECT_SIZE, Number(val))
      );
      const projectSizesTranslatedGa = projectSizesMappedValue.map((val) =>
        t(`common:enumerations.project_size.${val}`)
      );
      projectSizesTranslatedGa.sort();
      gaFilters["project_size"] = projectSizesTranslatedGa.join(", ");
    }

    window.dataLayer.push(gaFilters);
  }

  const getProvince = (selectedProvinces: any) => {
    if (selectedProvinces.length === 1) {
      const selectedProvince = PROVINCES_CODES.find((element) => element.value === Number(selectedProvinces[0]));
      if (selectedProvince) {
        setIsoCode(selectedProvince.isoCode);
      }
    } else {
      setIsoCode(""); // Set isoCode to empty string when no province is selected
      setCityList(null);
    }
  };

  const handleCityChange = (selectedOptions: any) => {
    if (selectedOptions && selectedOptions.length <= 15) {
      setCityList(selectedOptions);
    }
  };

  const CustomOption = (innerProps: OptionProps<DropdownOptionType, true>) => {
    const style = {
      alignItems: "center",
      backgroundColor: "red",
      color: "inherit",
      display: "flex ",
    };

    // prop assignment
    const temp = {
      ...innerProps,
      style,
    };

    return (
      <components.Option
        {...temp}
        isDisabled={innerProps.isDisabled}
        isFocused={innerProps.isFocused}
        isSelected={innerProps.isSelected}
        className={
          innerProps.isSelected
            ? styles.optionSelected
            : styles.optionUnselected
        }
      >
        <input
          type="checkbox"
          checked={innerProps.isSelected}
          className={styles.checkboxStyle}
        />
        &nbsp;{innerProps.children}
      </components.Option>
    );
  };

  return (
    <div
      className={styles.sidebarContainer}
      data-testid="investor-sidebar-container"
    >
      <h2 className={styles.textPadding} data-testid="investor-sidebar-heading">
        {t("filtersHeading")}
      </h2>
      <p
        className={styles.textPaddingNoTop}
        data-testid="investor-sidebar-description"
      >
        {t("filtersSubTextForDeveloper")}
      </p>
      <Divider light />
      <Formik
        initialValues={props.selectedFilter || initialValues as InvestorFilterValues}

        onSubmit={(values: InvestorFilterValues) => {
          let selectedCities: string[] = [];
          if (cityList) {
            cityList.map((city: any) => (
              selectedCities.push(city.name)
            )
            )
          }
          //@ts-ignore
          values.cities = selectedCities;
          updateFilters.filter = values;
          handleGa(values);
          props.setSelectedFilter && props.setSelectedFilter(updateFilters.filter);

          props.setPage(1);
          props.setFilters(updateFilters);
        }}
        onReset={() => {
          props.setSelectedFilter && props.setSelectedFilter(null);

          props.setPage(1);
          props.setFilters(noFilters);
        }}
      >
        {(values) => (
          <Form>
            <div className={`${styles.checkBoxGroupStyle} ${styles.developerCheckBoxGroupStyle}`}>
              <CheckBoxGroup
                fieldName="provincialFocusAreas"
                formQuestionText={t("filterInvestors.formFieldName1")}
                options={provinceOptions}
                data-testid="province"
                onCheckBoxClick={getProvince(values.values.provincialFocusAreas)!}
              />
              <div className={styles.multiSelectPadding}>
                <h3 className={styles.textPadding}>
                  {t("projectFilter.cities")}
                </h3>
                <p className={styles.cityCaption}>{t("projectFilter.cityCaption")}</p>
                <Select
                  isDisabled={isoCode === ""}
                  isMulti={true}
                  name="cities"
                  value={cityList && cityList}
                  isClearable={true}
                  // @ts-ignore
                  options={
                    City.getCitiesOfState(
                      "CA",
                      isoCode
                    )}
                  getOptionLabel={(options) => {
                    //@ts-ignore
                    return options["name"];
                  }}
                  getOptionValue={(options: any) => {
                    return options["name"];
                  }}
                  onChange={(list: Options<DropdownOptionType>) => {
                    props.setSelectedFilter && props.setSelectedFilter(values.values);
                    handleCityChange(list);
                  }}

                  components={{
                    Option: CustomOption,
                  }}
                  openMenuOnFocus={true}
                  closeMenuOnSelect={false}
                  hideSelectedOptions={false}
                  placeholder={t("impact_criteria.placeholder")}
                  styles={customStyles}
                  isSearchable={true}
                />
              </div>
              <CheckBoxGroup
                fieldName="organizationTypes"
                formQuestionText={t("filterInvestors.formFieldName2_developer")}
                options={OrganizationTypeOptions}
                data-testid="capital-funding-type"
              />
              <CheckBoxGroup
                fieldName="projectSizes"
                formQuestionText={t("filterInvestors.formFieldName3_developer")}
                options={averageProjectSizeRange}
                data-testid="capital-funding-type"
              />

            </div>


            <button
              type="reset"
              className={styles.formButton2}
              data-testid="reset-button"
            >
              {t("buttonFilterClear")}
            </button>
            <button
              type="submit"
              className={styles.formButton}
              data-testid="submit-button"
            >
              {t("buttonFilterText")}
            </button>
          </Form>
        )}
      </Formik>
    </div>
  );
}
