export async function checkFileSignature (file: File) {
    return new Promise<string>((resolve, reject) => {
        const reader = new FileReader();
        
        reader.onloadend = () => {
            if (reader.readyState === FileReader.DONE) {
                const uint = new Uint8Array(reader.result as ArrayBuffer).subarray(0, 8);
                const bytes = Array.from(uint);
                
                // Check if the file signature matches the expected signature for files
                const pngSignature = [137, 80, 78, 71, 13, 10, 26, 10]; // PNG file signature
                const jpegSignature = [255, 216, 255]; // JPEG file signature
                
                const isPNG = bytes.every((value, index) => value === pngSignature[index]);
                const isJPEG = bytes.slice(0, 3).every((value, index) => value === jpegSignature[index]);
                
                if (isPNG) {
                    resolve('png');
                } else if (isJPEG) {
                    resolve('jpeg');
                } else if (bytes[0] === 37 && bytes[1] === 80 && bytes[2] === 68 && bytes[3] === 70) {
                    resolve('pdf');
                } else {
                    resolve('unknown');
                }
            }
        };
        
        // Read the first 8 bytes of the file (where the signature is located)
        const blob = file.slice(0, 4);
        reader.readAsArrayBuffer(blob);
    });
}