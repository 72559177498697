import { useTranslation } from "react-i18next";

import styles from "./ViewDeveloper.module.scss";
import { ProjectStatus } from "../../../components/common/Cards/ProjectStatus";
import ShowMoreLess from "../../../components/common/ShowMoreLess";
import React from "react";
import { useNavigate, useLocation } from "react-router-dom";

import { numberWithCommas } from "../../../Constants/Utils/FormateNumber";
import { useUser } from "../../../components/Context/UserContext";

type ViewPortfolioInfoProps = {
    portfolioInfo: any[];
    provinceOptions: {
        label: string;
        value: number;
    }[];
    otherUserLoggedInflag: boolean;
    developerId?: string;
};

export const ViewPortfolioInfo = ({
    portfolioInfo,
    provinceOptions,
    otherUserLoggedInflag,
    developerId
}: ViewPortfolioInfoProps) => {

    const { t } = useTranslation("profile");
    const navigate = useNavigate();
    const location = useLocation();
    const { userState } = useUser();

    const limit = 4;
    const projectFinderPage = '/portfolio/add';
    const pathname = location.pathname;
    const portfolioPath = pathname.replace(pathname, '/portfolio/');

    const projectCards: (portfolio: any, index: number) => React.ReactNode = (portfolio, index) => {

        const isProjectProvince = portfolio.portfolioProjects.length > 0 ? true : false;
        //@ts-ignore
        const selectedProvinces = isProjectProvince ? [...new Map(portfolio.portfolioProjects.map(v => [v.province, v])).values()] : [...new Map(portfolio.developmentRegions.map(v => [v.province, v])).values()];

        return (
            <div className={styles.projectsTile} key={index}>
                <div className={styles.projectSummary}>
                    <div
                        className={`${styles.projectStatus} ${portfolio.portfolioStatus === 2 ? styles.greenBackground : styles.redBackground
                            }`}
                        data-testid="project-status"
                    >
                        <ProjectStatus status={portfolio.portfolioStatus} isPortfolio={true} />
                    </div>
                    <div className={styles.projectDetails}>
                        <button
                            type="button"
                            className={styles.projectCardLink}
                            data-testid="proj-name-link"
                            onClick={() => { window.location.href = portfolioPath + `${portfolio.portfolioID}` }}
                        >
                            {portfolio.portfolioName}
                        </button>
                        <p
                            className={styles.projectDetailText}
                            data-testid="address"
                        >

                            {portfolio.totalProjectCount} projects within &nbsp;

                            {selectedProvinces.map((obj: any, index: any) =>
                                <>
                                    {
                                        //@ts-ignore
                                        provinceOptions.find((province) => province.value === obj.province)
                                            ?.label
                                    }
                                    {
                                        //@ts-ignore
                                        index < selectedProvinces.length - 1
                                            ? ", "
                                            : " "
                                    }
                                </>
                            )}
                        </p>
                    </div>
                </div>
                <div className={styles.preferenceContainer}>
                    <p className={styles.totalCostTitle}>{t("developer.developer_view_project.minimumInvestment")}</p>
                    <p className={styles.totalCost}>{"$" + numberWithCommas(portfolio.minimumInvestmentAmount)}</p>

                    {portfolio.totalUnitPortfolioProject && portfolio.belowAverageMarketRentUnitCount &&
                        <React.Fragment>
                            <p className={styles.totalCostTitle}>{t("developer.developer_view_project.totalNumberOfUnits")}</p>
                            <p className={styles.totalCost}>
                                {portfolio.totalUnitPortfolioProject} <span className={styles.totalCostTitle}>({portfolio.belowAverageMarketRentUnitCount}{t("developer.developer_view_project.affordableUnits")})</span>
                            </p>
                        </React.Fragment>
                    }
                </div>
            </div>
        )
    }

    return (
        <div data-testid="projects" className={styles.projectsContainer}>
            <h3 className={styles.mainHeading} data-testid="title">{t("developer.developer_view_project.portfolios")} {`(${portfolioInfo.length})`} </h3>
            {portfolioInfo.length > 0 ? (<div className={styles.projectCards}>
                <ShowMoreLess content={portfolioInfo} limit={limit} renderFunction={projectCards} />
            </div>)
                :
                <div className={styles.noProject}>
                    <p>{otherUserLoggedInflag ? t("developer.developer_view_project.noPortfolioDeveloper") : t("developer.developer_view_project.noPortfolioLabel")}</p>
                    {userState.userId === developerId && otherUserLoggedInflag ? (
                        <button
                            type="button"
                            className={styles.link}
                            data-testid="add-impact-link"
                            onClick={() => { navigate(projectFinderPage) }}
                        >
                            {t("developer.developer_view_project.clickToAddProjectLink")}
                        </button>) : ""
                    }
                </div>
            }
        </div>
    );
};
