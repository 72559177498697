import { useTranslation } from "react-i18next";
import styles from "./ImpactCriteria.module.scss";
import { ImpactInfoDetailsSection } from "../../../components/common/ImpactSection/ImpactInfoDetailsSection/ImpactInfoDetailsSection";
import { ProjectImpactArea } from "../../../TypesAndInterfaces/ProjectDetailsInterface";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { isEmpty } from "../../../Constants/basicFunctions";

type ImpactCriteriaInfoProps = {
  impactCriteria: ProjectImpactArea;
  otherUserProjectflag: boolean;
  projectListingID: string;
  projectStatus: number;
};

export function ImpactCriteriaDeatils({
  impactCriteria,
  otherUserProjectflag,
  projectListingID,
  projectStatus
}: ImpactCriteriaInfoProps) {
  const { t } = useTranslation("project");
  const navigate = useNavigate();
  const [noImpactInfo, setNoImpactInfo] = useState(false);
  const {
    environmental,
    social,
    governance,
    environmentalDescription,
    socialDescription,
    governanceDescription,
  } = impactCriteria;
  const editImpactCriteria = `/projects/edit/${projectListingID}`;

  useEffect(() => {
    if (
      isEmpty(environmental) &&
      isEmpty(social) &&
      isEmpty(governance) &&
      isEmpty(environmentalDescription) &&
      isEmpty(socialDescription) &&
      isEmpty(governanceDescription)
    ) {
      setNoImpactInfo(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [impactCriteria]);

  return (
    <>
      <div className={styles.content} data-testid="impact-container">
        <div
          className="impactInfoDetailsSection"
          data-testid="impact-infoDetails-section"
        >
          {!noImpactInfo &&
            <div className="impactInfoDetailsSection" data-testid="impact-infoDetails-section">
              {/* Environmental */}
              <ImpactInfoDetailsSection
                titleText={t("project.impact_info.environmental")}
                descriptionLabel={t("project.impact_info.environmenta_des_label")}
                description={environmentalDescription}
                noImpactLabel={t("project.impact_info.noImpactLabel")}
                addImpactLinkText={t("project.impact_info.addImpactLinkText")}
                addImpactLink={editImpactCriteria}
                otherUserLoggedInflag={otherUserProjectflag}
                tagsData={environmental}
                status={projectStatus}
              />

              {/* /* Social */}
              <ImpactInfoDetailsSection
                titleText={t("project.impact_info.social")}
                descriptionLabel={t("project.impact_info.social_des_label")}
                description={socialDescription}
                noImpactLabel={t("project.impact_info.noImpactLabel")}
                addImpactLinkText={t("project.impact_info.addImpactLinkText")}
                addImpactLink={editImpactCriteria}
                otherUserLoggedInflag={otherUserProjectflag}
                tagsData={social}
                status={projectStatus}
              />

              {/* Governance */}
              <ImpactInfoDetailsSection
                titleText={t("project.impact_info.governance")}
                descriptionLabel={t("project.impact_info.governance_des_label")}
                description={governanceDescription}
                noImpactLabel={t("project.impact_info.noImpactLabel")}
                addImpactLinkText={t("project.impact_info.addImpactLinkText")}
                addImpactLink={editImpactCriteria}
                otherUserLoggedInflag={otherUserProjectflag}
                tagsData={governance}
                status={projectStatus}
              />
            </div>
          }
          {noImpactInfo && (
            <div className={styles.noOrgImpact}>
              <p>{t("project.impact_info.noImpactSectionText")}</p>
              {!otherUserProjectflag && (
                <button
                  type="button"
                  className={styles.link}
                  disabled={projectStatus === 3 ? true : false}
                  data-testid="add-impact-link"
                  onClick={() => {
                    navigate(editImpactCriteria);
                  }}
                >
                  {t("project.impact_info.addImpactLinkText")}
                </button>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
}
