import { Field, useField } from "formik";
import { SetStateAction, useRef } from "react";
import { useTranslation } from "react-i18next";
import { CommonErrorMessage } from "./CommonErrorMessage";
import styles from "./styles.module.scss";

type Props = {
  labelText: string;
  fieldName: string;
  charLimit?: number;
  fieldInfo?: string;
  linkText?: string;
  placeholder?: string;
  setIsOpenModal?: React.Dispatch<SetStateAction<boolean>>;
  [x: string]: unknown;
  instructionText?: string;
};

export function TextArea({
  labelText,
  fieldName,
  charLimit = 1000,
  fieldInfo,
  placeholder,
  linkText,
  setIsOpenModal,
  instructionText = "",
  ...rest
}: Props) {
  const [field, meta] = useField(fieldName);
  const inputRef = useRef<HTMLTextAreaElement>(null);
  const { t } = useTranslation("common");

  return (
    <div className={styles.fieldContainer}>
      <div>
        <label
          className={`${styles.labelStyle} ${meta.error && meta.touched ? styles.errorStateLabel : null
            }`}
          htmlFor={field.name}
        >
          {labelText}
        </label>
        {linkText && setIsOpenModal && (
          <button
            type="button"
            className={styles.glossaryLink}
            data-testid="glossary-page-link"
            onClick={() => {
              setIsOpenModal(true);
            }}
          >
            {linkText}
          </button>
        )}
      </div>
      <p className={styles.fieldInfo}>{fieldInfo}</p>
      <Field
        as="textarea"
        className={`${styles.textAreaInput} ${meta.error && meta.touched ? styles.errorStateInput : null
          }`}
        id={field.name}
        {...field}
        {...rest}
        innerRef={inputRef}
        maxLength={charLimit}
        placeholder={placeholder}
      />
      <div className={styles.charLimitContainer}>
        <p className={styles.fieldInfoText}>
          <p >{instructionText}</p>
          {t("form.char_limit", { charlimit: `${charLimit}` })}
        </p>
        <p>
          {inputRef.current
            ? `${inputRef.current.value.length} / ${charLimit}`
            : null}
        </p>
      </div>
      <CommonErrorMessage fieldName={field.name} />
    </div>
  );
}
