import styles from "./styles.module.scss";
import { useTranslation } from "react-i18next";
import { Info } from "../../../assets/icons/index";
import { Link } from "react-router-dom";
import { useUser } from "../../../components/Context/UserContext";

type Props = {
  isInvestorPage: boolean;
  isPortfolio?: boolean;
};

export function UnavailableRequest({ isInvestorPage, isPortfolio}: Props) {
  const { t } = useTranslation(["common","connectionStatus"]);

  const { userState } = useUser();
  const textReturn = () => {
    if (userState.isOnboarded && isInvestorPage) {
      return (
        <span>
          {" "}
          {t("connectionStatus:available_only_developers")}{" "}
          <Link to={"/connection"} className={styles.cardDetailLink}>
            {t("connectionStatus:view_connection_hub")}
          </Link>
        </span>
      );
    } else if (userState.isOnboarded && !isInvestorPage) {
      return (
        <span>
          {" "}
          {t("connectionStatus:available_only_investors")}{" "}
          <Link to={"/connection"} className={styles.cardDetailLink}>
            {t("connectionStatus:view_connection_hub")}
          </Link>
        </span>
      );
    } else if (!userState.isOnboarded && isInvestorPage) {
      if (userState.userPersona.toLowerCase() === "investor") {
        return t("connectionStatus:available_only_developers");
      } else {
        return (
          <span>
            {t("connectionStatus:available_only_developers_onboarded")}
            <Link to="/onboarding/developer">{t("header.complete_profile")}</Link>.
          </span>
        );
      }
    } else {
      //  when !userState.isOnboarded && !isInvestorPage
      if (userState.userPersona.toLowerCase() === "developer") {
        return t("connectionStatus:available_only_investors");
      } else {
        return isPortfolio
          ? (
            <span>{t("connectionStatus:available_only_investors_onboarded_portfolio")}
              <Link className={styles.actionLinkStyle} to="/onboarding/investor">{t("header.complete_profile")}</Link>.
            </span>
          )
          : (
            <span>{t("connectionStatus:available_only_investors_onboarded_project")}
              <Link className={styles.actionLinkStyle} to="/onboarding/investor">{t("header.complete_profile")}</Link>.
            </span>
          );
      }
    }
  };

  return (
    <>
      <Link
        to="#"
        className={styles.connectLinkDisabled}
        data-testid="connection-link"
      >
        {t("connectionStatus:connect_button_text")}
      </Link>
      <div className={styles.connectionOpportunityDetailsDiv}>
        <img
          src={Info}
          alt={t("connectionStatus:alt_text_error_icon")}
          className={styles.iconInfo}
          data-testid="image-icon"
        />
        <p>{textReturn()}</p>
      </div>
    </>
  );
}
