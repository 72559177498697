import { useTranslation } from "react-i18next";
import { useState, useEffect } from "react";
import styles from "./ReviewInvestor.module.scss";
import { GeneralInfoIcon } from "../../../assets/icons";
import {
  InvestorGeneralInfo,
  ProvinceProps,
} from "../../../TypesAndInterfaces/InvestorDetailsInterface";
import { InvestorAPI } from "../../../APIAction/InvestorAPI";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useUser } from "../../../components/Context/UserContext";
import { getEnumKeyByValue, PROVINCES } from "../../../Constants/Enumerations";

export const ReviewGeneralInfo = ({ provinceOptions }: ProvinceProps) => {
  const { t } = useTranslation("onboarding");
  const { userState } = useUser();
  const navigate = useNavigate();
  const [GeneralInfo, setGeneralInfo] = useState<InvestorGeneralInfo>();
  const [isDataExist, setIsDataExist] = useState(false);

  useEffect(() => {
    const getGeneralInfomation = async () => {
      try {
        const res = await InvestorAPI.getGeneralInfoById(userState.userId);
        const data = res.data;
        setIsDataExist(true);
        setGeneralInfo(data);
      } catch (error) {
        setIsDataExist(false);
        if (axios.isAxiosError(error) && error.response?.status !== 404) {
          navigate("/api-error");
        }
      }
    };
    userState.userId !== "" && getGeneralInfomation();
  }, [userState.userId, navigate]);

  const provinceMappedValue = GeneralInfo
    ? getEnumKeyByValue(PROVINCES, GeneralInfo.province)
    : "";

  const provinceTranslated = t(
    `common:enumerations.provinces.${provinceMappedValue}`
  );
  const handleEditClick = () => {
    navigate("/onboarding/investor/edit");
  };

  return (
    <div className={styles.informationContainer}>
      <div className={styles.infoHeadingContainer}>
        <div className={styles.left}>
          <div className={styles.imgContainer}>
            <img src={GeneralInfoIcon} alt="" />
          </div>
          <h3 className={styles.sectionHeading}>
            {t("investor.main_page.form_accordions.gen_info.heading")}
          </h3>
        </div>
        <button className={styles.link} onClick={handleEditClick}>
          {t("investor.review_profile.edit_link")}
        </button>
      </div>
      {isDataExist && GeneralInfo && (
        <div className={styles.content}>
          <p className={styles.title}>
            {t("investor.review_profile.gen_info.org_info")}
          </p>
          <div className={styles.grid_container}>
            <p>{t("investor.general_info_form.org_name_label")}</p>
            <p>{GeneralInfo.organizationName}</p>
            <p>{t("investor.general_info_form.phone_number")}</p>
            <p>{GeneralInfo.phoneNumber}</p>
            <p>{t("investor.general_info_form.address_label")}</p>
            <p>
              {GeneralInfo.address}, {GeneralInfo.unitNumber}
              {GeneralInfo.unitNumber ? ", " : null}
              {GeneralInfo.city}, {provinceTranslated} {GeneralInfo.country},{" "}
              {GeneralInfo.postalCode}
            </p>
            <p>{t("investor.review_profile.gen_info.org_summary_label")}</p>
            <p>{GeneralInfo.organizationSummary}</p>
          </div>
        </div>
      )}
    </div>
  );
};
