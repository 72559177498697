import { TabPanelProps } from "./connectionHubProps";
import styles from "./connectionHub.module.scss";

export function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
  return (
    <div
      className={styles.tabPanelContainer}
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <div className={styles.cardContainer} data-testid="tab-panel-content">
          {children}
        </div>
      )}
    </div>
  );
}
