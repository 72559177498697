import { useTranslation } from "react-i18next";
import styles from "./connectionHub.module.scss";

export function CardDetailsPending() {
  const { t } = useTranslation("connectionHub");

  return (
    <div className={styles.information} data-testid="card-pending-info">
      <div className={styles.titleInfoStyles}>
        {t("ConnectionHub.connectionTab.pendingInfo")}
      </div>
      <div className={styles.infomail}>
        {t("ConnectionHub.connectionTab.infoText")}
      </div>
    </div>
  );
}
