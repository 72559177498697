import { Form, Formik, FieldArray } from "formik";
import { SyntheticEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { ProvincesSelect } from "../common/ProvincesSelect";
import { FieldArrayAddButton } from "../common/FieldArrayAddButton";
import { FieldArrayRemoveButton } from "../common/FieldArrayRemoveButton";
import { RadioInput } from "../common/RadioInput";
import { SelectInput } from "../common/SelectInput";
import { TextArea } from "../common/TextArea";
import { TextInput } from "../common/TextInput";
import { InvestorAPI } from "../../../APIAction/InvestorAPI";
import {
  InvestorInvestmentInformation,
  InvestorProps,
} from "../../../TypesAndInterfaces/InvestorDetailsInterface";
import styles from "./styles.module.scss";
import { useUser } from "../../Context/UserContext";
import { DOLLAR_RANGE, PROVINCES_CODES, getKVArrayFormatForDropdown } from "../../../Constants/Enumerations";
import { ProjectLocationFields } from "../AddProject/ProjectLocationFields";
import { City } from "country-state-city";

export function InvestorInformationForm({ handleResponse }: InvestorProps) {
  const { t,i18n } = useTranslation("onboarding");
  const { userState } = useUser();
  const [investmentInfo, setInvestmentInfo] =
    useState<InvestorInvestmentInformation>();
  const [isDataExist, setIsDataExist] = useState(false);

  useEffect(() => {
    const getInvestmentInfomation = async () => {
      try {
        const res = await InvestorAPI.getInvestmentInfoById(userState.userId);
        const data = res.data;
        setIsDataExist(true);
        setInvestmentInfo(data);
      } catch (error) {
        setIsDataExist(false);
        handleResponse("InvestmentInfo", false, error);
      }
    };

    userState.userId !== "" && getInvestmentInfomation();
  }, [userState.userId, handleResponse]);

  const dolllarRangesTrans = Object.keys(DOLLAR_RANGE).map((val) =>
    t(`common:enumerations.dollar_range.${val}`)
  );

  const dollarPlaceholderMin = {
    label: t("investor.investment_info_form.investment_amount_min_placeholder"),
    value: "",
  };

  const investmentMinAmountOptions = getKVArrayFormatForDropdown(
    DOLLAR_RANGE,
    dolllarRangesTrans
  ).map(({ label, value }) => ({ label: label, value: value }));
  investmentMinAmountOptions.unshift(dollarPlaceholderMin);

  const dollarPlaceholderMax = {
    label: t("investor.investment_info_form.investment_amount_max_placeholder"),
    value: "",
  };

  const investmentMaxAmountOptions = getKVArrayFormatForDropdown(
    DOLLAR_RANGE,
    dolllarRangesTrans
  ).map(({ label, value }) => ({ label: label, value: value }));
  investmentMaxAmountOptions.unshift(dollarPlaceholderMax);

  const generalRadioOptions = [
    { label: t("investor.investment_info_form.yes_label"), value: "true" },
    { label: t("investor.investment_info_form.no_label"), value: "false" },
  ];
  const initialExperienceValues = {
    projectName: "",
    province: "",
    city: "",
    projectDescription: "",
  };

  let investmentInfoProject: any = [];

  if (investmentInfo?.projects) {
    investmentInfoProject =  investmentInfo?.projects.map((val: any) => {
      const selectedProvince = PROVINCES_CODES.find((element) => element.value === Number(val?.province));
      const isoCode = selectedProvince && selectedProvince.isoCode;
      const cityList = isoCode && City.getCitiesOfState('CA', isoCode);
      const formattedCityOptions = cityList && cityList.map(city => ({ label: city.name, value: city.name }));

      // Check if the user's city code matches any city in the city list
      const matchingCity = formattedCityOptions && formattedCityOptions.find(city => city.value === val?.city);
      return {
        city: matchingCity ? matchingCity.label : "",
        projectDescription: val.projectDescription,
        projectName: val.projectName,
        province: val.province,
      };
    });
  }
  const initialValues = {
    investmentDollarMinimum: "",
    investmentDollarMaximum: "",
    coInvestingFlag: "",
    investingExperienceFlag: "",
    projects: [initialExperienceValues],
  };

  const apiData = {
    investmentDollarMinimum: investmentInfo?.investmentDollarMinimum,
    investmentDollarMaximum: investmentInfo?.investmentDollarMaximum,
    //Boolean to string as formik work with string for radio buttons values
    coInvestingFlag:
      investmentInfo?.coInvestingFlag && investmentInfo.coInvestingFlag === true
        ? "true"
        : "false",
    investingExperienceFlag:
      investmentInfo?.investingExperienceFlag &&
        investmentInfo.investingExperienceFlag === true
        ? "true"
        : "false",
    projects: investmentInfoProject,
  };

  const maxCharsErrorText = t("investor.general_form_error.max_characters", { charNum: 64 });
  const spacingErrorText = t("investor.general_form_error.no_spaces");
  const regxNotAllowOnlySpace = /^(?!\s*$)/;

  const InvestmentInfoSchema = Yup.object().shape({
    investmentDollarMinimum: Yup.number().required(
      t("investor.investment_form_error.investmentDollar_minimum")
    ),
    investmentDollarMaximum: Yup.number()
      .required(t("investor.investment_form_error.investmentDollar_maximum"))
      .moreThan(
        Yup.ref("investmentDollarMinimum"),
        t("investor.investment_form_error.maximum_amount_required")
      ),
    coInvestingFlag: Yup.boolean().required(
      t("investor.investment_form_error.coinvesting_required")
    ),
    investingExperienceFlag: Yup.boolean().required(
      t("investor.investment_form_error.previousImpact")
    ),
    projects: Yup.array()
      .of(
        Yup.object().shape({
          projectName: Yup.string()
            .required(t("investor.investment_form_error.projectName"))
            .max(64, maxCharsErrorText)
            .matches(regxNotAllowOnlySpace, spacingErrorText),
          province: Yup.string().required(
            t("investor.general_form_error.province_required")
          ),
          city: Yup.string()
            .required(t("investor.general_form_error.city_required"))
            .max(64, maxCharsErrorText)
            .matches(regxNotAllowOnlySpace, spacingErrorText),
          projectDescription: Yup.string()
            .required(t("investor.investment_form_error.projectDesc"))
            .max(1000, t("investor.general_form_error.max_characters", { charNum: 1000 }))
            .matches(regxNotAllowOnlySpace, spacingErrorText),
        })
      )
      .max(5, "A maximum of 5 is permitted")
      .nullable(true),
  });

  const updateInvestmentInformation = async (formValues: Object) => {
    try {
      await InvestorAPI.updateInvestmentInfo(userState.userId, formValues)
      handleResponse("InvestmentInfo", true);
    } catch (error) {
      handleResponse("InvestmentInfo", false, error);
    }
  };

  const submitInvestmentInformation = async (formValues: Object) => {
    try {
      await InvestorAPI.postInvestmentInfo({
        ...formValues,
        investorID: userState.userId,
      });
      handleResponse("InvestmentInfo", true);
    } catch (error) {
      handleResponse("InvestmentInfo", false, error);
    }
  };

  return (
    <Formik
      initialValues={isDataExist && investmentInfo ? apiData : initialValues}
      validateOnBlur={false}
      validateOnChange={false}
      validationSchema={InvestmentInfoSchema}
      enableReinitialize={true}
      onSubmit={(values) => {
        const {
          coInvestingFlag,
          investingExperienceFlag,
          investmentDollarMaximum,
          investmentDollarMinimum,
          ...rest
        } = values;
        //Backend accepts boolean values for radio buttons so need to format before sending data
        const updatedValue = {
          investmentDollarMaximum: investmentDollarMaximum
            ? +investmentDollarMaximum
            : "",
          investmentDollarMinimum: investmentDollarMinimum
            ? +investmentDollarMinimum
            : "",
          coInvestingFlag: coInvestingFlag === "true" ? true : false,
          investingExperienceFlag:
            investingExperienceFlag === "true" ? true : false,
          ...rest,
        };
        isDataExist
          ? updateInvestmentInformation({
            investorID: userState.userId,
            ...updatedValue,
          })
          : submitInvestmentInformation({ ...updatedValue });
      }}
    >
      {({ values, setFieldValue }) => (
        <Form className={styles.formElement} noValidate>
          <h3 className={styles.formSectionHeading}>
            {t("investor.investment_info_form.investment_amount_heading")}
          </h3>

          <h4 className={styles.formQuestion}>
            {t("investor.investment_info_form.investment_amount_question")}
          </h4>

          <div className={styles.splitFieldContainer}>
            <SelectInput
              fieldName="investmentDollarMinimum"
              labelText={t(
                "investor.investment_info_form.investment_amount_min_label"
              )}
              options={investmentMinAmountOptions}
              data-testid="investment-min"
            />
            <SelectInput
              fieldName="investmentDollarMaximum"
              labelText={t(
                "investor.investment_info_form.investment_amount_max_label"
              )}
              options={investmentMaxAmountOptions}
              data-testid="investment-max"
            />
          </div>

          <h4 className={styles.formQuestion}>
            {t("investor.investment_info_form.co_investment_question")}
          </h4>

          <div className={styles.fieldContainer}>
            <RadioInput
              fieldName="coInvestingFlag"
              options={generalRadioOptions}
              data-testid="coinvest-radio"
            />
          </div>

          <hr className={styles.horizontalLine} />

          <h3 className={styles.formSectionHeading}>
            {t("investor.investment_info_form.previous_experience_heading")}
          </h3>

          <p className={styles.formInfo}>
            {t("investor.investment_info_form.previous_experience_info")}
          </p>

          <h4 className={styles.formQuestion}>
            {t("investor.investment_info_form.previous_experience_question")}
          </h4>

          <div className={styles.fieldContainer}>
            <RadioInput
              fieldName="investingExperienceFlag"
              options={generalRadioOptions}
              onClick={(e: SyntheticEvent<HTMLInputElement>) => {
                if (e.currentTarget.value === "true") {
                  setFieldValue("projects", [initialExperienceValues]);
                } else if (e.currentTarget.value === "false") {
                  setFieldValue("projects", []);
                }
              }}
              data-testid="previous-experience-radio"
            />
          </div>
          {values.investingExperienceFlag === "true" && (
            <FieldArray
              name="projects"
              render={(arrayHelpers) => (
                <div className={styles.experienceSectionContainer}>
                  {values.investingExperienceFlag &&
                    values.projects &&
                    values.projects.length > 0
                    ? values.projects.map((exp:any, index:number) => (
                      <div
                        key={index}
                        className={styles.experienceSectionItem}
                      >
                        <div className={styles.experienceHeadingContainer}>
                          <h4 className={styles.experienceHeading}>
                            {t(
                              "investor.investment_experience_form.investment_exp_heading"
                            )}
                            {i18n.language==="fr" && index > 0 &&(" n")}                         
                            {i18n.language==="fr" &&  index > 0 && (<sup>0</sup>)}                     
                            {index > 0 ? " " + (index + 1) : ''}
                          </h4>
                          {index > 0 && (
                            <div className={styles.actionLinkStyle}> 
                            <u>
                            <FieldArrayRemoveButton
                              onClickHandler={() =>
                                arrayHelpers.remove(index)
                              }
                              buttonText={t(
                                "investor.investment_experience_form.remove_exp_text"
                              )}
                            />
                            {i18n.language==="fr" &&(" n")}  
                            {i18n.language==="fr" &&(<sup>0</sup>)}                     
                            {' '+ (index + 1)}
                            </u>    
                          </div>                       
                          )}
                        </div>

                        <TextInput
                          fieldName={`projects[${index}].projectName`}
                          labelText={t(
                            "investor.investment_experience_form.project_name_label"
                          )}
                        />

                        <ProjectLocationFields name={`projects[${index}]`} province={values?.projects && values?.projects[index]?.province} isDataExist={isDataExist} />

                        <TextArea
                          fieldName={`projects[${index}].projectDescription`}
                          labelText={t(
                            "investor.investment_experience_form.project_desc_label"
                          )}
                          charLimit={1000}
                        />
                      </div>
                    ))
                    : null}
                  {values.projects &&
                    values.projects.length > 0 &&
                    values.projects.length < 5 ? (
                    <div className={styles.centreAlignContainer}>
                      <FieldArrayAddButton
                        onClickHandler={() =>
                          arrayHelpers.push(initialExperienceValues)
                        }
                        buttonText={t(
                          "investor.investment_experience_form.add_exp_text"
                        )}
                      />
                    </div>
                  ) : null}
                </div>
              )}
            />
          )}
          <div className={styles.buttonContainer}>
            <button
              type="submit"
              className={styles.formButton}
              data-testid="submit-button"
            >
              {t("investor.main_page.next_button_text")}
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
}
