import { useTranslation } from "react-i18next";
import { DateInput } from "../common/DateInput";
import styles from "./styles.module.scss";

export function ProjectDateFields() {
  const { t } = useTranslation("profile");

  return (
    <div className={styles.splitFieldContainer} data-testid="date">
      <DateInput
        fieldName="targetProjectStartDate"
        labelText={t("developer.projects.general_info_form.start_date_label")}
        placeholderText={t(
          "developer.projects.general_info_form.datePlaceHolderText"
        )}
        required
      />

      <DateInput
        fieldName="targetProjectCompleteDate"
        labelText={t("developer.projects.general_info_form.end_date_label")}
        placeholderText={t(
          "developer.projects.general_info_form.datePlaceHolderText"
        )}
        required
      />
    </div>
  );
}
