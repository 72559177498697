import { useTranslation } from "react-i18next";
import React, { useState, useEffect } from "react";
import styles from "./styles.module.scss";
import { GeneralInfoIcon } from "../../../assets/icons";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { ListingProjectAPI } from "../../../APIAction/ListingProjectAPI";
import {
  getEnumKeyByValue,
  getImageOptions,
  IMAGE_TYPES,
  PROVINCES,
} from "../../../Constants/Enumerations";
import {
  ProjectGeneralInfo,
  ReviewProjectProps,
} from "../../../TypesAndInterfaces/ProjectDetailsInterface";
import { PDFCover } from "../../../assets/images";

export const ReviewProjectDocumentation = ({
  projectListingID,
}: ReviewProjectProps) => {
  const { t } = useTranslation("profile");
  const navigate = useNavigate();

  const [docInfo, setDocInfo] = useState<any>();
  const [isDataExist, setIsDataExist] = useState(false);

  useEffect(() => {
    const getGeneralInfomation = async () => {
      try {
        const res = await ListingProjectAPI.getDocumentationInfoById(
          projectListingID
        );
        const data = res.data;

        setIsDataExist(true);
        setDocInfo(data);
      } catch (error) {
        setIsDataExist(false);
        if (axios.isAxiosError(error) && error.response?.status !== 404) {
          navigate("/api-error");
        }
      }
    };
    projectListingID !== "" && getGeneralInfomation();
  }, [projectListingID, navigate]);



  const handleEditClick = () => {
    navigate(`/projects/edit/${projectListingID}`);
  };



  return (
    <div className={styles.informationContainer}>
      <div className={styles.infoHeadingContainer}>
        <div className={styles.left}>
          <div className={styles.imgContainer}>
            <img src={GeneralInfoIcon} alt="" />
          </div>
          <h3 className={styles.sectionHeading}>
            {t("developer.projects.file_upload_doc.documentation")}
          </h3>
        </div>
        <button className={styles.link} onClick={handleEditClick}>
          {t("developer.projects.review_project.edit_link")}
        </button>
      </div>
      {isDataExist && (
        <div className={styles.content}>
          <div className={styles.grid_container}>
            {docInfo?.blobs && docInfo?.blobs.length !== 0 &&
              <React.Fragment>
                <p>
                  {t("developer.projects.file_upload_doc.files_uploaded")}
                </p>
                <p>
                  {docInfo?.blobs?.map((item: any, idx: number) => (
                    <div className={styles.uploadCard} key={idx}>
                      <div className={styles.imageContent}>
                        <div>
                          <img
                            className={styles.image}
                            src={PDFCover}
                            alt={item.fileName}
                          />
                        </div>
                        <div className={styles.imagetitle}>
                          <p>{item.fileName} </p>
                          <span className={styles.imageSize}> {item.fileName.split('.').pop()} | {item.blobSize} MB</span>
                        </div>

                      </div>
                    </div>
                  ))}
                </p>
              </React.Fragment>
            }
          </div>
        </div>
      )}
    </div>
  );
};
