import { useTranslation } from "react-i18next";
import { Link, useNavigate, useParams } from "react-router-dom";
import { PageLayout } from "../../../components/Layout/PageLayout";
import { Happy_Face } from "../../../assets/images/index";
import { PageContent } from "../../../components/Layout/PageContent";
import styles from "./ProjectSuccess.module.scss";


export const PortfolioSuccessPage = () => {
    const { t } = useTranslation("profile");
    const { portfolioId = "" } = useParams();
    const navigate = useNavigate();
    const handleConfirm = () => {
        navigate(`/portfolio/${portfolioId}`);
    };

    return (
        <PageLayout>
            <PageContent>
                <div className={styles.confirmationContainer}>
                    <img
                        data-testid="happy-face"
                        src={Happy_Face}
                        alt={t("developer.projects.success.smileyalt-icon")}
                    />

                    <div className={styles.successInfoContainer}>
                        <h2 data-testid="confirmation-heading" className={styles.heading}>
                            {t("developer.projects.success.portfolio_heading")}
                        </h2>
                        <p data-testid="confirmation-content" className={styles.info}>
                            {t("developer.projects.success.portfolio_content")}
                        </p>
                    </div>
                    <div className={styles.buttonContainer}>
                        <button
                            type="submit"
                            className={styles.formButton}
                            onClick={handleConfirm}
                            data-testid="confirm-btn"
                        >
                            {t("developer.projects.success.link_portfolio")}
                        </button>
                    </div>
                    <div className={styles.successLinkList} data-testid="success-links">
                        <Link to="/search/investors" className={styles.successInfoLink}>
                            {t("developer.projects.success.link_investor")}
                        </Link>
                    </div>
                </div>
            </PageContent>
        </PageLayout>
    );
};
