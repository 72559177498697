import { Field, useField } from "formik";
import { Fragment } from "react";
import { CommonErrorMessage } from "./CommonErrorMessage";
import styles from "./styles.module.scss";

type Props = {
  fieldName: string;
  options: { label: string; value: unknown }[];
  [x: string]: unknown;
};

export function RadioInput({ fieldName, options, ...rest }: Props) {
  const [field, meta] = useField(fieldName);

  return (
    <>
      <div className={styles.radioContainer}>
        {options.map((opt, idx) => (
          <Fragment key={idx}>
            <Field
              type="radio"
              id={`${field.name}-${idx}`}
              {...field}
              {...rest}
              value={String(opt?.value)}
            />
            <label
              className={`${styles.labelTextStyle} ${meta.error && meta.touched ? styles.errorStateLabel : null
                }`}
              htmlFor={`${field.name}-${idx}`}
            >
              {opt.label}
            </label>
          </Fragment>
        ))}
      </div>
      <CommonErrorMessage fieldName={field.name} />
    </>
  );
}
