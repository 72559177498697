import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import { FilterOptionsState, Paper } from "@mui/material";
import { AddCircleOutline, HighlightOff } from "@mui/icons-material";
import { MultiSelectTagsProps, Tag, CustomPaperComponentProps } from "./types";
import styles from "./MultiSelectSearch.module.scss";
import { useField } from "formik";
import { useTranslation } from "react-i18next";

const filter = createFilterOptions<Tag>();

const CreatableSelect: React.FC<MultiSelectTagsProps> = ({
  labelText,
  fieldName,
  predefinedTags,
  onDeleteTag,
  selectedTags,
  onAddTag,
  placeholderText,
  setIsOpenModal,
  setSelectedFieldName,
  linkText,
}) => {
  const [value, setValue] = useState<Tag | null>(null);
  const [field, meta] = useField(fieldName);
  const { t } = useTranslation("common");

  useEffect(() => {
    setValue(null);
  }, [predefinedTags]);

  const handleInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
    // Allow only alphanumeric characters and plus sign and space and french text to a maximum of 50 characters
    const sanitizedValue = inputValue.replace(/[^a-zA-Z0-9À-ÖÙ-öÙ-ÿ+ ]+/g, "");
    const truncatedValue = sanitizedValue.slice(0, 50);
    event.target.value = truncatedValue;
  };

  const renderInput = (params: any) => (
    <>
    <TextField
      {...params}
      placeholder={placeholderText}
      onInput={handleInput}
      InputProps={{
        ...params.InputProps,
        endAdornment: <span>{params.inputProps.value?.length}/50</span>,
      }}
    />
    <p>{t("form.multiselect_input_message")}</p>
    </>
  );
  const renderOption = (props: any, option: Tag) => {
    const isOptionSelected = selectedTags.some(
      (tag) => tag.label.toLowerCase() === option.label.toLowerCase()
    );


    return (
      <li
        {...props}
        key={option.inputValue}
      >
        <span className={styles.iconandtext}>
          {isOptionSelected ? (
            <>
              <HighlightOff />
              <span>Delete</span>
            </>
          ) : (
            <>
              <AddCircleOutline />
              <span>Add</span>
            </>
          )}
        </span>
        <span className={styles.labelText}>{option.label}</span>
      </li>
    );
  };

  const handleInputChange = (newValue: string) => {
    const tag = {
      inputValue: newValue,
      label: newValue,
      selected: false,
      added: true,
    };
    onAddTag(tag);
  };

  const onChange = (
    event: React.ChangeEvent<{}>,
    newValue: Tag | string | null
  ) => {
    if (typeof newValue === "string") {
      handleInputChange(newValue);
    } else if (newValue) {
      const isOptionSelected = selectedTags.some(
        (tag) => tag.label.toLowerCase() === newValue.label.toLowerCase()
      );

      if (isOptionSelected) {
        onDeleteTag(newValue);
      } else {
        onAddTag(newValue);
      }
    }
    const tag = {
      inputValue: "",
      label: "",
      selected: false,
      added: false,
    };
    setValue(tag);
  };

  //Filter options based on entered value where optionslist is combination of all predefined tags and user added tags
  const customFilterOptions = (
    options: Tag[],
    params: FilterOptionsState<Tag>
  ) => {
    const filtered = filter(options, params);

    if (params.inputValue !== "") {
      const isInputValueInPredefinedTags = options.some(
        (tag) => tag.label.toLowerCase() === params.inputValue?.toLowerCase()
      );

      if (!isInputValueInPredefinedTags) {
        filtered.unshift({
          inputValue: params.inputValue,
          label: params.inputValue,
          selected: false,
          added: false,
        });
      }
    }
    return filtered;
  };

  const CustomPaperComponent: React.FC<CustomPaperComponentProps> = ({
    children,
  }) => (
    <Paper
      elevation={3}
      sx={{
        border: "1px solid #669EB6",
        borderRadius: "4px",
        marginTop: "4px",
      }}
    >
      {children}
    </Paper>
  );

  return (
    <div className={styles.fieldContainer}>
      <div className={styles.lableLink}>
        <label
          className={`${styles.labelStyle} ${meta.error && meta.touched ? styles.errorStateLabel : null
            }`}
          htmlFor={field.name}
        >
          {labelText}
        </label>
        <button
          type="button"
          className={styles.glossaryLink}
          data-testid="glossary-page-link"
          onClick={() => {
            if (setSelectedFieldName) {
              setSelectedFieldName(fieldName);
            }
            setIsOpenModal(true);
          }}
        >
          {linkText}
        </button>
      </div>
      <Autocomplete
        sx={{
          height: 40,
          "&:focus-within": {
            border: "1px solid #669EB6",
            borderRadius: "4px",
          },
          ".MuiFormControl-root": {
            height: 40,
            display: "flex",
            alignItems: "center",
            ".MuiOutlinedInput-root": {
              height: 40,
              padding: "4px",
              ".MuiAutocomplete-input": {
                padding: "4px !important",
                marginRight: "40px",
              },
            },
            ".MuiAutocomplete-endAdornment": {
              display: "none",
            },
          },
        }}
        PaperComponent={CustomPaperComponent}
        classes={{
          option: styles.option
        }}
        value={value}
        onChange={onChange}
        filterOptions={customFilterOptions}
        id="multiselect_search"
        options={predefinedTags}
        getOptionLabel={(option) =>
          typeof option === "string" ? option : option.label
        }
        selectOnFocus
        clearOnBlur
        autoHighlight={true}
        handleHomeEndKeys
        renderOption={renderOption}
        renderInput={renderInput}
      />
    </div>
  );
};

export default CreatableSelect;
