import { Modal } from "@mui/material";
import { Dispatch, SetStateAction, useState } from "react";
import styles from "./GlossaryDefinition.module.scss";
import CloseIcon from "@mui/icons-material/Close";
import { ErrorOutlined } from "../../../../assets/icons";

type Props = {
  setCloseGlossaryModal: Dispatch<SetStateAction<boolean>>;
  glossaryContent: { subHeading: string, description: string }[];
  title: string;
  glossaryLink: string;
  closeText: string;
  paraContent?: { para: string }[];
  selectedId?: string;
};

export function GlossaryDefinition(props: Props) {

  const [isOpenModal, setOpenModal] = useState(true);
  const setCloseGlossaryModal = props.setCloseGlossaryModal;

  const handleClose = () => {
    setOpenModal(false);
    setCloseGlossaryModal(false);
  };

  const viewFullGlossaryLink = () => {
    localStorage.setItem("glossarySelectedLinkId", props.selectedId!);
    handleClose();
    window.open('/resources#glossary', '_blank')
  };

  return (
    <Modal open={isOpenModal} onClose={handleClose}>
      <div className={styles.glossaryModalContainer}>
        <div className={styles.modalContentWrapper}>
          <div className={styles.titleContainer} data-testid="title-container">
            <img
              src={ErrorOutlined}
              alt=""
              className={styles.icon}
              data-testid="exclamation-Icon"
            />
            <h4 className={styles.title} data-testid="pageTitle">
              {props.title}
            </h4>
          </div>
          <button
            className={styles.crossIcon}
            onClick={handleClose}
            data-testid="close-Icon"
          >
            <CloseIcon />
          </button>

          <div className={styles.contentBlock} data-testid="glossary-content">
            {props.paraContent && props.paraContent?.map((data, i) => (
              <p className={styles.glossaryText}>{data.para}</p>
            ))
            }
            {props.glossaryContent?.map((data, i) => (
              <p className={styles.glossaryText} key={i}>
                <b>{data.subHeading}</b> {data.description}
              </p>
            )
            )}
            <button
              type="button"
              className={styles.viewGlossaryLink}
              data-testid="view-full-glossary-link"
              onClick={viewFullGlossaryLink}
            >
              {props.glossaryLink}
            </button>
          </div>
          <button
            type="button"
            className={styles.closeButton}
            data-testid="close-button"
            onClick={handleClose}
          >
            {props.closeText}
          </button>
        </div>
      </div>
    </Modal>
  );
}
