import { useEffect, useState } from "react";
import { PageLayout } from "../../../components/Layout/PageLayout";
import { PageContent } from "../../../components/Layout/PageContent";
import { EditIcon } from "../../../assets/icons";
import styles from "./ViewDeveloper.module.scss";
import { ViewDeveloperInfo } from "./DeveloperInfo";
import { ViewGeneralInfo } from "./ViewGeneralInfo";
import { DeveloperApi } from "../../../APIAction/DeveloperAPI";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useUser } from "../../../components/Context/UserContext";
import { getKVArrayFormat, PROVINCES } from "../../../Constants/Enumerations";
import {
  DeveloperExperience,
  DeveloperGeneralInfo,
  DeveloperOrganizationInfo,
  DeveloperProjectInfo,
  ProjectInfo,
} from "../../../TypesAndInterfaces/DeveloperDetailsInterface";
import { ViewOrganizationInfo } from "./ViewOrganizationInfo";
import { ViewProjectInfo } from "./ViewProjectInfo";
import { ViewPortfolioInfo } from "./ViewPortfolioInfo";


import { useLocation } from "react-router-dom";

type LocationState = {
  state: string;
}

export const ViewDeveloperProfile = () => {
  const { t } = useTranslation(["profile", "onboarding"]);
  const navigate = useNavigate();
  const postData = useLocation() as LocationState;
  const [GeneralInfo, setGeneralInfo] = useState<DeveloperGeneralInfo>();
  const [DeveloperInfo, setDeveloperInfo] = useState<DeveloperExperience>();
  const [OrganizationInfo, setOrganizationInfo] =
    useState<DeveloperOrganizationInfo>();
  const [developerID, setDeveloperID] = useState();
  const [isProjectsDataExist, setIsProjectsDataExist] = useState(false);;
  const [developerProjectInfo, setDeveloperProjectInfo] = useState<DeveloperProjectInfo[]>();
  const [developerPortfolioInfo, setDeveloperPortfolioInfo] = useState<any[]>();

  const [isGenDataExist, setIsGenDataExist] = useState(false);
  const [isDeveloperDataExist, setIsDeveloperDataExist] = useState(false);
  const [isOrganizationDataExist, setIsOrganizationDataExist] = useState(false);

  const { userState } = useUser();

  const handleEditClick = () => {
    navigate("/profile/developer/edit");
  };

  const provincesTranslated = Object.keys(PROVINCES).map((val) =>
    t(`common:enumerations.provinces.${val}`)
  );
  const provinceOptions = getKVArrayFormat(PROVINCES, provincesTranslated);

  useEffect(() => {
    if (postData.state == null && !userState.isOnboarded) {
      //If NOT onboarded redirect to onboarding flow
      navigate("/onboarding/developer");
    }
  }, [
    userState.userId,
    userState.isOnboarded,
    DeveloperInfo?.developerID,
    navigate,
  ]);

  useEffect(() => {
    const getGeneralInfomation = async () => {
      try {
        const res = await DeveloperApi.getGeneralInfoById(window.location.pathname.split('/')[3] === "developerInfo" ? postData.state : userState.userId);
        const data = res.data;
        setIsGenDataExist(true);
        setGeneralInfo(data);
      } catch (err) {
        if (axios.isAxiosError(err)) {
          if (err.response?.status === 404) {
            setIsGenDataExist(false);
          } else {
            navigate("/api-error");
          }
        }
      }
    };
    const getOrganizationInfomation = async () => {
      try {
        const res = await DeveloperApi.getOrganizationInfoById(window.location.pathname.split('/')[3] === "developerInfo" ? postData.state : userState.userId);
        const data = res.data;
        setIsOrganizationDataExist(true);
        setOrganizationInfo(data);
      } catch (err) {
        if (axios.isAxiosError(err)) {
          if (err.response?.status === 404) {
            setIsOrganizationDataExist(false);
          } else {
            navigate("/api-error");
          }
        }
      }
    };

    const getDeveloperInfomation = async () => {
      try {
        const res = await DeveloperApi.getDeveloperInfoById(window.location.pathname.split('/')[3] === "developerInfo" ? postData.state : userState.userId);

        const data = res.data;
        setDeveloperID(data.developerID);
        setIsDeveloperDataExist(true);
        setDeveloperInfo(data);
      } catch (err) {
        if (axios.isAxiosError(err)) {
          if (err.response?.status === 404) {
            setIsDeveloperDataExist(false);
          } else {
            navigate("/api-error");
          }
        }
      }
    };

    const getDeveloperProjectsById = async () => {
      try {
        const res = await DeveloperApi.getDeveloperById(window.location.pathname.split('/')[3] === "developerInfo" ? postData.state : userState.userId);
        const data = res.data;
        const developerProjectData: DeveloperProjectInfo[] = data.projectListings
          ?.filter((item: ProjectInfo) => item.projectListingStatus === 2 || item.projectListingStatus === 3)
          ?.map((item: ProjectInfo) => ({
            projectListingID: item.projectListingID,
            projectName: item.projectName,
            projectListingStatus: item.projectListingStatus,
            province: item.province,
            city: item.city,
            fundingTypes: item.fundingTypes,
            totalFundingNeeded: item.totalFundingNeeded,
            totalUnitCount: item.totalUnitCount,
            belowAverageMarketRentUnitCount: item.belowAverageMarketRentUnitCount,
            projectAffordabilityType: item.projectAffordabilityType,
          }));

        const developerPortfolioData: DeveloperProjectInfo[] = data.portfolios
          ?.filter((item: any) => item.portfolioStatus === 2 || item.portfolioStatus === 3)
          ?.map((item: any) => ({
            portfolioID: item.portfolioID,
            portfolioName: item.portfolioName,
            portfolioStatus: item.portfolioStatus,
            portfolioProjects: item.portfolioProjects,
            minimumInvestmentAmount: item.minimumInvestmentAmount,
            totalProjectCount: item.totalProjectCount,
            totalUnitPortfolioProject: item.totalUnitPortfolioProject,
            belowAverageMarketRentUnitCount: item.belowAverageMarketRentUnitCount,
            developmentRegions: item.developmentRegions,
          }));

        setIsProjectsDataExist(true);
        setDeveloperProjectInfo(developerProjectData);
        setDeveloperPortfolioInfo(developerPortfolioData);
      } catch (err) {
        if (axios.isAxiosError(err)) {
          if (err.response?.status === 404) {
            setIsDeveloperDataExist(false);
          } else {
            navigate("/api-error");
          }
        }
      }
    };
    if (userState.userId !== "") {
      getGeneralInfomation();
      getOrganizationInfomation();
      getDeveloperInfomation();
      getDeveloperProjectsById();
    }
    else if (
      postData.state != null
    ) {
      getGeneralInfomation();
      getOrganizationInfomation();
      getDeveloperInfomation();
      getDeveloperProjectsById();
    }
  }, [userState, navigate]);

  const isOtherUserProject = developerID === userState.userId;
  return (
    <PageLayout>
      <hr className={styles.divider} />
      <PageContent>
        <div className={styles.container}>
          <div className={styles.headerContainer}>
            <h1 className={styles.mainHeading} data-testid="main-heading">
              {t("developer.main_page.page_title")}
            </h1>
            {window.location.pathname.split('/')[3] !== "developerInfo" && <div>
              <button className={styles.link} onClick={handleEditClick}>
                {t("developer.main_page.edit_link")}
              </button>
              <img src={EditIcon} alt="" />
            </div>}
          </div>
          <div data-testid="general-info">
            {isGenDataExist && GeneralInfo && (
              <ViewGeneralInfo
                genInfo={GeneralInfo}
                provinceOptions={provinceOptions}
              />
            )}
          </div>
          <div data-testid="project-info">
            {isProjectsDataExist && developerProjectInfo && (
              <ViewProjectInfo
                projectInfo={developerProjectInfo}
                provinceOptions={provinceOptions}
                otherUserLoggedInflag={isOtherUserProject}
                developerId={OrganizationInfo && OrganizationInfo.developerID ? OrganizationInfo.developerID : ""}
              />
            )}
          </div>
          <div data-testid="project-info">
            {isProjectsDataExist && developerPortfolioInfo && (
              <ViewPortfolioInfo
                portfolioInfo={developerPortfolioInfo}
                provinceOptions={provinceOptions}
                otherUserLoggedInflag={isOtherUserProject}
                developerId={OrganizationInfo && OrganizationInfo.developerID ? OrganizationInfo.developerID : ""}
              />
            )}
          </div>
          <div data-testid="general-info">
            {isOrganizationDataExist && OrganizationInfo && (
              <ViewOrganizationInfo
                orgInfo={OrganizationInfo}
              />
            )}
          </div>

          <div
            className={styles.sectionContainer}
            data-testid="developer-experience"
          >
            {isDeveloperDataExist && DeveloperInfo ? (
              <ViewDeveloperInfo
                DeveloperInfo={DeveloperInfo}
                provinceOptions={provinceOptions}
              />
            ) : null}
          </div>
        </div>
      </PageContent>
    </PageLayout>
  );
};
