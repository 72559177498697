import { useTranslation } from "react-i18next";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import { GeneralInformationForm } from "../../../components/Form/DeveloperOnboarding/GeneralInformationForm";
import { DevelopmentExperience } from "../../../components/Form/DeveloperOnboarding/DevelopmentExperience";
import { PageContent } from "../../../components/Layout/PageContent";
import { PageLayout } from "../../../components/Layout/PageLayout";
import { GeneralInfoIcon, dollorIcon, OrganizationIcon } from "../../../assets/icons";
import { useLocation, useNavigate } from "react-router-dom";
import styles from "../DeveloperOnboarding.module.scss";
import accordionStyles from "../../FormAccordion/styles.module.scss";
import { useState } from "react";
import { useUser } from "../../../components/Context/UserContext";
import axios from "axios";
import { setUserApiData } from "../../../components/Context/UserActions";
import { useMsal } from "@azure/msal-react";
import { OrganizationInfoForm } from "../../../components/Form/DeveloperOnboarding/OrganizationInfoForm";

export function EditDeveloperProfile() {
  const { userState, dispatch } = useUser();
  const { t } = useTranslation("onboarding");
  const location = useLocation();
  const [isExpanded, setIsExpanded] = useState(true);
  const [selectedAccordion, setSelectedAccordion] = useState(0);

  const { accounts } = useMsal();
  const userEmail = accounts[0].username;

  const handleAccordianChange = (idValue: number) => {
    if (idValue !== selectedAccordion) {
      setIsExpanded(true);
      setSelectedAccordion(idValue);
    }
    if (idValue === selectedAccordion) {
      isExpanded ? setIsExpanded(false) : setIsExpanded(true);
    }
  };
  const isEditProfileFromViewPage = location.pathname.includes("profile");
  const navigate = useNavigate();
  const noteItems = [
    { text: t("developer.main_page.note.item_1") },
    { text: t("developer.main_page.note.item_2") },
    { text: t("developer.main_page.note.item_3") },
  ];

  const handleResponse = (
    infoSectionName: string,
    isSuccess: boolean,
    error?: any
  ) => {
    if (isSuccess) {
      switch (infoSectionName) {
        case "GeneralInfo":
          setUserApiData(dispatch, userEmail, userState.userPersona);
          handleAccordianChange(1);
          break;
        case "OrganizationInfo":
          setUserApiData(dispatch, userEmail, userState.userPersona);
          handleAccordianChange(2);
          break;
        case "DeveloperInfo":
          setUserApiData(dispatch, userEmail, userState.userPersona);
          navigate("/onboarding/developer/review");
          break;
        default:
          break;
      }
    } else {
      if (axios.isAxiosError(error) && error.response?.status !== 404) {
        navigate("/api-error");
      }
    }
  };
  const investorFormList = [
    {
      formElement: (
        <GeneralInformationForm
          handleResponse={handleResponse}
          isTNCSubmit={true}
        />
      ),
      formHeading: t("developer.main_page.form_accordions.gen_info.heading"),
      formIconSrc: GeneralInfoIcon,
      formIconAlt: t("developer.main_page.form_accordions.gen_info.alticon"),
      formInfoSummary: t(
        "developer.main_page.form_accordions.gen_info.summary"
      ),
    },
    {
      formElement: (
        <OrganizationInfoForm
          handleResponse={handleResponse} 
        />
      ),
      formHeading: t("developer.main_page.form_accordions.org_info.heading"),
      formIconSrc: OrganizationIcon,
      formIconAlt: t("developer.main_page.form_accordions.org_info.alticon"),
      formInfoSummary: t(
        "developer.main_page.form_accordions.org_info.summary"
      ),
    },
    {
      formElement: <DevelopmentExperience handleResponse={handleResponse} />,
      formHeading: t(
        "developer.main_page.form_accordions.developerInfo.heading"
      ),
      formIconSrc: dollorIcon,
      formIconAlt: t(
        "developer.main_page.form_accordions.developerInfo.alticon"
      ),
      formInfoSummary: t(
        "developer.main_page.form_accordions.developerInfo.summary"
      ),
    },
  ];

  return (
    <PageLayout>
      <PageContent>
        <h1 className={styles.pageTitle} data-testid="page-title">
          {isEditProfileFromViewPage
            ? t("developer.main_page.edit_page_title")
            : t("developer.main_page.page_title")}
        </h1>

        {isEditProfileFromViewPage ? (
          <p className={styles.summaryText} style={{ marginBottom: "5rem" }}>
            {t("developer.main_page.summary_text_edit")}
          </p>
        ) : (
          <p className={styles.summaryText}>
            {t("developer.main_page.summary_text")}
          </p>
        )}

        <div className={styles.noteContainer}>
          <h2 className={styles.noteHeading} data-testid="note-heading">
            {t("developer.main_page.note.heading")}
          </h2>
          <ul className={styles.noteList} data-testid="note-list">
            {noteItems.map((item, idx) => (
              <li key={idx} className={styles.noteListItem}>
                <p>{item.text}</p>
              </li>
            ))}
          </ul>
        </div>

        {investorFormList.map((form, index) => {
          return (
            <Accordion
              key={index}
              data-testid="accordian-component"
              className={accordionStyles.accordion}
              expanded={selectedAccordion === index && isExpanded}
              onChange={() => handleAccordianChange(index)}
            >
              <AccordionSummary
                aria-controls="panel-content"
                id="panel-header"
                className={accordionStyles.accordionSummary}
              >
                <div className={accordionStyles.accordionImgContainer}>
                  <img
                    src={form.formIconSrc}
                    alt={form.formIconAlt}
                    className={
                      form.formIconSrc === dollorIcon ? styles.circle : " "
                    }
                  />
                </div>
                <h3 className={accordionStyles.accordionHeading}>
                  {form.formHeading}
                </h3>
                <button
                  type="button"
                  className={accordionStyles.actionLinkStyle}
                >
                  {t("common:form.edit")}
                </button>
              </AccordionSummary>
              <p className={accordionStyles.info}>{form.formInfoSummary}</p>
              <hr className={accordionStyles.horizontalLine} />
              <AccordionDetails className={accordionStyles.accordionDetails}>
                {form.formElement}
              </AccordionDetails>
            </Accordion>
          );
        })}
      </PageContent>
    </PageLayout>
  );
}
