import axios, { AxiosResponse } from "axios";
import { PendingConnectionStatusI, connectionStatus } from "../TypesAndInterfaces/ConnectionInterface";
import { config, requestAccessToken } from "./config";

const apiClient = axios.create({
  baseURL: config.url.base,
  headers: {
    "Content-type": "application/json",
  },
});

const postConnectRequest = async (requestInfo: Object) => {
  const token = await requestAccessToken();
  const postcallResponse = await apiClient.post<AxiosResponse>(
    config.url.connect.connectRequest,
    requestInfo,
    { headers: { Authorization: `Bearer ${token}` } }
  );
  return postcallResponse.data;
};

const postConnectSearchRequest = async (requestInfo: Object) => {
  const token = await requestAccessToken();
  const postSearchResponse = await apiClient.post<AxiosResponse>(
    config.url.connect.connectSearchRequest,
    requestInfo,
    { headers: { Authorization: `Bearer ${token}` } }
  );
  return postSearchResponse.data;
};

const acceptConnectionRequest = async (id: string) => {
  const token = await requestAccessToken();
  const acceptRequest = await apiClient.put<AxiosResponse>(
    `${config.url.connect.connectAcceptRequest}/${id}`,
    { id: id },
    { headers: { Authorization: `Bearer ${token}` } }
  );
  return acceptRequest.data;
};

const VerifyConnectionStatus = async (entityDetails: Object) => {
  const token = await requestAccessToken();
  const postcallResponse = await apiClient.post<AxiosResponse>(
    config.url.connect.verifyRequest,
    entityDetails,
    { headers: { Authorization: `Bearer ${token}` } }
  );
  return postcallResponse.data;
};

const checkPendingConnectionStatus = async (requestorDetails: Object) => {
  const token = await requestAccessToken();
  return apiClient.post<PendingConnectionStatusI>(
    config.url.connect.pendingConnections,
    requestorDetails,
    { headers: { Authorization: `Bearer ${token}` } }
  );
};
const checkFeedBack = async (requestorDetails: Object) => {
  const token = await requestAccessToken();
  return apiClient.post<connectionStatus>(
    config.url.connect.verifySuccessfulConnection,
    requestorDetails,
    { headers: { Authorization: `Bearer ${token}` } }
  );
};
export const ConnectApi = {
  postConnectRequest,
  postConnectSearchRequest,
  acceptConnectionRequest,
  VerifyConnectionStatus,
  checkPendingConnectionStatus,
  checkFeedBack
};
