import { Field, useField } from "formik";
import { CommonErrorMessage } from "./CommonErrorMessage";
import styles from "./styles.module.scss";

type Props = {
  labelText: string;
  fieldName: string;
  [x: string]: unknown;
  placeholder?: string;
  numOnly?: boolean;
  instructionText?:string;
};

export function TextInput({
  labelText,
  fieldName,
  placeholder,
  numOnly = false,
  instructionText,
  ...rest
}: Props) {
  const [field, meta] = useField(fieldName);

  const isValueNum = (val: string) => {
    if (typeof val !== "undefined" && typeof val === "string") {
      const numericValue = val.replace(/[^0-9]/g, "");
      return numericValue;
    }
    return "";
  };

  return (
    <div className={styles.fieldContainer}>
      <label
        className={`${styles.labelStyle} ${
          meta.error && meta.touched ? styles.errorStateLabel : null
        }`}
        htmlFor={field.name}
      >
        {labelText}
      </label>
      <Field
        className={`${styles.textInput} ${
          meta.error && meta.touched ? styles.errorStateInput : null
        }`}
        id={field.name}
        {...field}
        {...rest}
        maxLength={1000}
        placeholder={placeholder}
        value={numOnly ? isValueNum(field.value) : field.value}
      />
      {instructionText && <p className={styles.fieldInfoText}>{instructionText}</p>}
      <CommonErrorMessage fieldName={field.name} />
    </div>
  );
}
