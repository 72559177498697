import { InteractionType } from "@azure/msal-browser";
import { MsalAuthenticationTemplate } from "@azure/msal-react";
import { Outlet } from "react-router-dom";

export function ProtectedRoutes() {
  const loginRequest = {
    scopes: [],
  };

  return (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      authenticationRequest={loginRequest}
    >
      <Outlet />
    </MsalAuthenticationTemplate>
  );
}
