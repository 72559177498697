import { useTranslation } from "react-i18next";
import styles from "./styles.module.scss";

export function SkipToMain() {
  const { t } = useTranslation("common");

  return (
    <a href="#mainContent" className={styles.mainContentButton}>
      {t("header.skip_to_main")}
    </a>
  );
}
