import axios, { AxiosResponse } from "axios";
import { WaitListInfo } from "../TypesAndInterfaces/WaitListInterface";
import { config, requestAccessToken } from "./config";

const apiClient = axios.create({
  baseURL: config.url.base,
  headers: {
    "Content-type": "application/json",
  },
});

export async function postJoinWaitList(waitListData: WaitListInfo) {
  const postcallResponse = await apiClient.post<AxiosResponse>(
    config.url.account.JoinWaitList,
    waitListData
  );
  return postcallResponse.data;
}

export async function postOnboardingVerify(onboardingStatus: number) {
  const token = await requestAccessToken();

  const postcallResponse = await apiClient.post<AxiosResponse>(
    config.url.account.onboardingVerify,
    { onboardingStatus },
    { headers: { Authorization: `Bearer ${token}` } }
  );

  return postcallResponse.data;
}
