import { Modal } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import styles from "./WaitlistForm.module.scss";

type Props = {
  termsTitle: string;
  termsStartBody: string;
  termsLink: string;
  termsEndBody: string;
  openState: boolean;
  handleClose: () => void;
};
const privacyUrl = "https://www.cmhc-schl.gc.ca/en/about-us/privacy-policy";

export function TermsModal(props: Props) {
  return (
    <Modal
      open={props.openState}
      onClose={props.handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      data-testid="terms-modal"
    >
      <div className={styles.termsModalContainer}>
        <div className={styles.termsModalHeadingContainer}>
          <h2 id="modal-modal-title" className={styles.termsModalHeading}>
            {props.termsTitle}
          </h2>
          <button
            onClick={props.handleClose}
            className={styles.termsModalCloseButton}
          >
            <CloseIcon />
          </button>
        </div>
        <p
          id="modal-modal-description"
          className={styles.termModelBodyContainer}
        >
          {props.termsStartBody}&nbsp;
          <a href={privacyUrl} target="_blank" rel="noopener noreferrer">
            {props.termsLink}
          </a>
          &nbsp;
          {props.termsEndBody}
        </p>
      </div>
    </Modal>
  );
}
