import { useTranslation } from "react-i18next";
import { DeveloperOrganizationInfo } from "../../../TypesAndInterfaces/DeveloperDetailsInterface";

import styles from "./ViewDeveloper.module.scss";
import {
  FINANCING_TYPE,
  ORGANIZATION_TYPE,
  PROJECT_SIZE,
  PROVINCES,
  getKVArrayFormat,
} from "../../../Constants/Enumerations";
import { useUser } from "../../../components/Context/UserContext";

type ViewOrganizationInfoProps = {
  orgInfo: DeveloperOrganizationInfo;

  projectDetailsPage?: boolean;
};

export const ViewOrganizationInfo = ({
  orgInfo,
  projectDetailsPage,
}: ViewOrganizationInfoProps) => {
  const { userState } = useUser();
  const headingKey = orgInfo.developerID === userState.userId ? 'heading_own' : 'heading';
  const { t } = useTranslation("onboarding");

  const regionalFocusValues = {
    province: "",
    city: "",
  };
  const {
    establishmentYear = "",
    organizationType = [],
    populationFocus = "",
    impactDescription = "",
    regionalFocus = [regionalFocusValues],
    averageProjectSize = "",
    affordabilityOffered = "",
    financingPreviouslyReceived = [],
  } = orgInfo;

  const provincesTranslated = Object.keys(PROVINCES).map((val) =>
    t(`common:enumerations.provinces.${val}`)
  );
  const provinceOptions = getKVArrayFormat(PROVINCES, provincesTranslated);

  const OrganizationTypeTranslations = Object.keys(ORGANIZATION_TYPE).filter(val => val !== undefined && val !== "").map(
    (val) => t(`common:enumerations.organization_type.${val}`)
  );
  const OrganizationTypeOptions = getKVArrayFormat(
    ORGANIZATION_TYPE,
    OrganizationTypeTranslations
  );

  const organizationTypeValue =
    organizationType &&
    organizationType.length > 0 &&
    organizationType.map(
      (val, idx) =>
        OrganizationTypeOptions.find((obj) => obj.value === val)?.label
    );

  const FinancingPreviouslyReceivedTranslations = Object.keys(
    FINANCING_TYPE
  ).map((val) => t(`common:enumerations.financing_type.${val}`));

  const FinancingPreviouslyReceivedOptions = getKVArrayFormat(
    FINANCING_TYPE,
    FinancingPreviouslyReceivedTranslations
  );

  const financingType =
    financingPreviouslyReceived &&
    financingPreviouslyReceived.length > 0 &&
    financingPreviouslyReceived.map(
      (val, idx) =>
        FinancingPreviouslyReceivedOptions.find((obj) => obj.value === val)
          ?.label
    );

  const averageProjectSizeTranslation = Object.keys(PROJECT_SIZE).map((val) =>
    t(`common:enumerations.project_size.${val}`)
  );
  const averageProjectSizeRange = getKVArrayFormat(
    PROJECT_SIZE,
    averageProjectSizeTranslation
  );

  const averageProjectSizeValue =
    orgInfo &&
    averageProjectSizeRange.find((obj) => obj.value === averageProjectSize)
      ?.label;

  return (
    <div >
      <div className={styles.infoHeadingContainer}>
        <div className={styles.left}>
          <h3 className={styles.sectionHeading}>
            {t(`developer.main_page.form_accordions.org_info.${headingKey}`)} 
            {/* TODO: didn't want to introduce regression 
              - added _own to the heading key
              - should be
                "heading": {
                  "general": "",
                  "own": "",
                }
            */}
          </h3>
        </div>
      </div>
      {orgInfo && (
        <div className={styles.orgInfoContentContainer}>
          <p className={styles.Organizationtitle}>
            {t(`developer.review_profile.org_info.org_info_${headingKey}`)}
            {/* TODO: didn't want to introduce regression 
              - added _own to the heading key
              - should be
                "org_info_heading": {
                  "general": "",
                  "own": "",
                }
            */}
          </p>
          <div className={styles.grid_container}>
            <p>{t("developer.review_profile.org_info.year_establishment")}</p>
            <p className={styles.contentRight}>{establishmentYear}</p>
            {organizationTypeValue && <><p>{t("developer.review_profile.org_info.org_type")}</p>
              <p className={styles.contentRight}>
                {organizationTypeValue?.map(
                  (val, idx) =>
                    `${val}${idx < organizationTypeValue.length - 1 ? ", " : ""
                    }`
                )}
              </p>
            </>
            }
          </div>
          <hr className={styles.horizontalLine} />
          <p className={styles.Organizationtitle}>
            {t("developer.review_profile.org_info.focus_area")}
          </p>
          <div className={styles.grid_container}>
            <p>{t("developer.review_profile.org_info.population_focus")}</p>
            <p className={styles.contentRight}>{populationFocus}</p>
            <p>{t("developer.review_profile.org_info.org_impact_desc")}</p>
            <p className={styles.contentRight}>{impactDescription}</p>
            {regionalFocus.length > 0 &&
              regionalFocus.map((region, index) => {
                return (
                  <>
                    <p>
                      {t("developer.review_profile.org_info.regional_focus")}
                    </p>
                    <p className={styles.contentRight}>
                      {`${provinceOptions.find(
                        (val) => val.value === region.province
                      )?.label
                        }, ${region.city}`}
                    </p>
                  </>
                );
              })}
          </div>
          <hr className={styles.horizontalLine} />
          <p className={styles.Organizationtitle}>
            {t("developer.review_profile.org_info.financial_detail")}
          </p>
          <div className={styles.grid_container}>
            <p>{t("developer.review_profile.org_info.avg_project_size")}</p>
            <p className={styles.contentRight}>{averageProjectSizeValue}</p>
            <p>{t("developer.review_profile.org_info.affordability")}</p>
            <p className={styles.contentRight}>{affordabilityOffered}</p>
            <p>{t("developer.review_profile.org_info.finance_recived")}</p>
            <p className={styles.contentRight}>
              {financingType &&
                financingType?.map(
                  (val, idx) =>
                    `${val}${idx < financingType.length - 1 ? ", " : ""}`
                )}
            </p>
          </div>
        </div>
      )}

    </div>
  );
};
