import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import { useTranslation } from "react-i18next";
import accordionStyles from "./styles.module.scss";
import { TermsConditionIcon } from "../../assets/icons";
import { useState } from "react";


type Props = {
    handleChange: () => void;
    isExpanded: boolean;
};

export function TermsConditionAccordion(props: Props) {
    const { t } = useTranslation("onboarding");
    const [isChecked, setIsChecked] = useState(false);
    const [isTermsPageOpen, setIsTermsPageOpen] = useState(false);
    const [showError, setShowError] = useState(false);

    const openTermsPage = () => {
        setIsTermsPageOpen(true);
        window.open('/terms', '_blank')
    }

    const submitTermsCondition = () => {
        isChecked ? props.handleChange() : setShowError(true)
    }

    const handleCheckboxClick = () => {
        setIsChecked(!isChecked);
        setShowError(false)
    }

    return (
        <Accordion
            className={accordionStyles.accordion}
            expanded={props.isExpanded}
        >
            <AccordionSummary
                aria-controls="panel-content"
                id="panel-header"
                className={accordionStyles.accordionSummary}
            >
                <div className={accordionStyles.accordionImgContainer}>
                    <img src={TermsConditionIcon} alt="" />
                </div>
                <h3 className={accordionStyles.accordionHeading} data-testid="terms-heading">{t("investor.terms_condition.heading")}</h3>
            </AccordionSummary>
            <p className={accordionStyles.info} data-testid="terms-summary">{t("investor.terms_condition.summary")}</p>
            <hr className={accordionStyles.horizontalLine} />
            <AccordionDetails className={accordionStyles.accordionDetails}>
                <p className={accordionStyles.condition_text}>
                    {t("investor.terms_condition.tc_text_start")}
                    <button
                        type="button"
                        className={accordionStyles.termsModalButton}
                        data-testid="terms-link"
                        onClick={openTermsPage}
                    >
                        {t("investor.terms_condition.tc_link")}
                    </button>
                    {t("investor.terms_condition.tc_text_end")}
                </p>
                <p className={`${accordionStyles.condition_ack_heading} ${showError ? accordionStyles.error : null}`}>{t("investor.terms_condition.ack")}</p>
                <div className={accordionStyles.checkboxes} >
                    <label className={`${accordionStyles.checkboxlabel} }`}>
                        <input
                            type="checkbox"
                            disabled={!isTermsPageOpen}
                            onChange={handleCheckboxClick}
                            className={`${accordionStyles.checkboxInput} ${showError ? accordionStyles.errorInput : null}`}
                            data-testid="ack-input"
                        />
                        <span className={accordionStyles.checkboxSpan} data-testid="ack-label">{t("investor.terms_condition.ack_text")}</span>
                    </label>
                    {showError && <p className={accordionStyles.errorText}>{t("investor.terms_condition.error")}</p>}
                </div>
                <button
                    type="button"
                    className={accordionStyles.formButton}
                    data-testid="submit-button"
                    onClick={submitTermsCondition}
                >
                    {t("investor.terms_condition.button_text")}
                </button>
            </AccordionDetails>
        </Accordion>
    );
}
