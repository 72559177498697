import styles from "./Hero.module.scss";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export const Hero = () => {
  const { t } = useTranslation("landing");
  const handleGTM = () => {
    window.dataLayer.push({
      event: "request_access",
    });
  };
  return (
    <div className={styles.heroContainer}>
      <div className={styles.heroInfo}>
        <h2 className={styles.heroHeading} data-testid="hero-header">
          {t("landing:value_proposition.header")}
        </h2>
        <p className={styles.heroText} data-testid="hero-text">
          {t("landing:value_proposition.content")}
        </p>
        <Link
          type="button"
          className={styles.access_button}
          data-testid="request-access-button"
          onClick={handleGTM}
          to="/request-access"
        >
          {t("landing:request_access")}
        </Link>
      </div>
    </div>
  );
};
