import i18n from "../../../i18n";

export function numberWithCommas(x: string | number) {
    if (typeof x === 'number') {
        x = x.toString();
    }
    if (x === "") {

        return "";
    }
    x = i18n.language !== "fr" ? parseFloat(x.replace(/,/g, "")).toString() : parseFloat(x.replace(/\s/g, '')).toString();

    const pattern = /(-?\d+)(\d{3})/;
    while (pattern.test(x)) {

        x = i18n.language !== "fr" ? x.replace(/\B(?=(\d{3})+(?!\d))/g, ",") : x.replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    }

    if (x === "NaN") {

        return "";
    }
    return x;
}

export function removeCommas(x: any) {

    if (x === "" || x === null || typeof x !== 'string') {
        return x;
    }

    return x && i18n.language !== "fr" ? parseFloat(x.replace(/,/g, '')) : parseFloat(x.replace(/ /g, ''));
}
