import { Link } from "react-router-dom";
import styles from "./styles.module.scss";
import { useTranslation } from "react-i18next";
import i18n from "../../../i18n";
import CMHC_logo_ENG from "../../../assets/images/CMHC_logo-ENG.svg";
import CMHC_logo_FR from "../../../assets/images/CMHC_logo-FR.svg";

export function HeaderLogo() {
  const { t } = useTranslation(["common"]);
  const imageSource = i18n.language !== "fr" ? CMHC_logo_ENG : CMHC_logo_FR;

  return (
    <div className={styles.logoContainer} data-testid="logo-component">
      <Link to="/" className={styles.logoLink}>
        <img
          src={imageSource}
          alt={t("header_links.cmhc_logo_alttext")}
          className={styles.chmcLogo}
          data-testid="chmc__logo"
        />
      </Link>
      <div className={styles.titleContainer}>
        <p className={styles.logoTitle} data-testid="logo__title">
          {t(`common:logoText.logoTitle`)}
        </p>
        <p className={styles.logoHeading} data-testid="logo__heading">
          {t(`common:logoText.logoHeading`)}
        </p>
      </div>
    </div>
  );
}
