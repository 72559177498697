import styles from "./styles.module.scss";
import { Form, Formik } from "formik";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { TextArea } from "../common/TextArea";
import { TextInput } from "../common/TextInput";
import { ListingPortfolioAPI } from "../../../APIAction/ListingPortfolioAPI";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useUser } from "../../Context/UserContext";

import {
  submitGeneralInformation,
  updateGeneralInformation,
} from "./PortfolioGeneralInformationHelpers";
import { handleApiErrorRedirect } from "../../../APIAction/config";
import { PortfolioGeneralInfo } from "../../../TypesAndInterfaces/PortfolioDetailsInterface";

export function PortfolioGeneralInformationForm({
  isAffordabilitySubmit,
  handleResponse,
  portfolioListingId,
}: any) {
  const { t } = useTranslation("profile");
  const { userState } = useUser();

  const initialValues = {
    portfolioName: "",
    portfolioDescription: "",
  };
  const navigate = useNavigate();
  const [GeneralInfo, setGeneralInfo] = useState<PortfolioGeneralInfo>();
  const [isDataExist, setIsDataExist] = useState(false);
  const [isApiLoading, setIsApiLoading] = useState(false);

  const getGeneralInfomation = useCallback(
    async (id: string) => {
      try {
        setIsApiLoading(true);
        const res = await ListingPortfolioAPI.getPortfolioGeneralInfoById(id);
        const data = res.data;
        setIsApiLoading(false);
        setIsDataExist(true);
        setGeneralInfo(data);
      } catch (error) {
        setIsApiLoading(false);
        setIsDataExist(false);
        handleApiErrorRedirect(error, navigate);
      }
    },
    [navigate]
  );

  useEffect(() => {
    portfolioListingId && getGeneralInfomation(portfolioListingId);
  }, [portfolioListingId, getGeneralInfomation]);

  const apiData = {
    portfolioName: GeneralInfo?.portfolioName,
    portfolioDescription: GeneralInfo?.portfolioDescription,
  };

  const maxCharsErrorText = t(
    "developer.projects.general_form_error.max_characters",
    { charNum: 64 }
  );
  const spacingErrorText = t("developer.projects.general_form_error.no_spaces");
  const regxNotAllowOnlySpace = /^(?!\s*$)/;

  const generalInfoSchema = Yup.object().shape({
    portfolioName: Yup.string()
      .required(
        t("developer.projects.general_form_error.fill_required_fields")
      )
      .max(64, maxCharsErrorText)
      .matches(regxNotAllowOnlySpace, spacingErrorText),
    portfolioDescription: Yup.string()
      .required(
        t("developer.projects.general_form_error.fill_required_fields")
      )
      .max(
        300,
        t("developer.projects.general_form_error.max_characters", {
          charNum: 300,
        })
      )
      .matches(regxNotAllowOnlySpace, spacingErrorText),

  });


  return (
    <Formik
      initialValues={isDataExist ? apiData : initialValues}
      validationSchema={generalInfoSchema}
      validateOnChange={false}
      validateOnBlur={false}
      enableReinitialize={true}
      onSubmit={(values) => {
        const {
          portfolioName,
          portfolioDescription,
          ...restValues
        } = values;
        const updatedValues = {
          type: "portfolio",
          developerID: userState.userId,
          organizationName: userState.userOrganization,

          affordabilityThresholdFlag: isAffordabilitySubmit,
          portfolioName: portfolioName?.trim(),
          portfolioDescription: portfolioDescription?.trim(),
          ...restValues,
        };

        isDataExist
          ? updateGeneralInformation(
            portfolioListingId,
            { portfolioID: portfolioListingId, ...updatedValues },
            setIsApiLoading,
            handleResponse,
            navigate
          )
          : submitGeneralInformation(
            { portfolioID: portfolioListingId, ...updatedValues },
            setIsApiLoading,
            handleResponse,
            navigate
          );
      }}
    >
      <Form className={styles.formElement} noValidate>
        <TextInput
          fieldName={"portfolioName"}
          labelText={t(
            "developer.projects.general_info_form.portfolio_name_label"
          )}
          required
        />

        <TextArea
          fieldName={"portfolioDescription"}
          labelText={t(
            "developer.projects.general_info_form.portfolio_desc_label"
          )}
          charLimit={300}
        />

        <div className={styles.buttonContainer}>
          <button
            type="submit"
            className={styles.formButton}
            data-testid="submit-button"
            disabled={isApiLoading}
          >
            {t("developer.projects.general_info_form.btn_text")}
          </button>
        </div>
      </Form>
    </Formik>
  );
}
