import { Form, Formik } from "formik";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import styles from "./styles.module.scss";
import { TextInput } from "../common/TextInput";
import { TextArea } from "../common/TextArea";
import { ProvincesSelect } from "../common/ProvincesSelect";
import { DeveloperApi } from "../../../APIAction/DeveloperAPI";
import { useEffect, useState } from "react";
import { useUser } from "../../Context/UserContext";
import {
  DeveloperGeneralInfo,
  GeneralInfoProps,
} from "../../../TypesAndInterfaces/DeveloperDetailsInterface";
import { useMsal } from "@azure/msal-react";
import { ProjectLocationFields } from "../AddProject/ProjectLocationFields";
import { PROVINCES_CODES } from "../../../Constants/Enumerations";
import { City } from "country-state-city";

export function GeneralInformationForm({ handleResponse }: GeneralInfoProps) {
  const { t } = useTranslation("onboarding");
  const { userState } = useUser();
  const { accounts } = useMsal();
  const userEmail = accounts[0].username;
  const userPhoneNumber = accounts[0].idTokenClaims
    ?.extension_OrganizationPhone as string;
  const [generalInfo, setGeneralInfo] = useState<DeveloperGeneralInfo>();
  const [isDataExist, setIsDataExist] = useState<boolean>(false);

  useEffect(() => {
    const getGeneralInfoData = async () => {
      try {
        const response = await DeveloperApi.getGeneralInfoById(
          userState.userId
        );
        setIsDataExist(true);
        setGeneralInfo(response.data);
      } catch (error) {
        setIsDataExist(false);
        handleResponse("GeneralInfo", false, error);
      }
    };
    userState.userId !== "" && getGeneralInfoData();
  }, [userState.userId, handleResponse]);

  const selectedProvince = PROVINCES_CODES.find((element) => element.value === Number(generalInfo?.province));
  const isoCode = selectedProvince && selectedProvince.isoCode;
  const cityList = isoCode && City.getCitiesOfState('CA', isoCode);
  const formattedCityOptions = cityList && cityList.map(city => ({ label: city.name, value: city.name }));

  // Check if the user's city code matches any city in the city list
  const matchingCity = formattedCityOptions && formattedCityOptions.find(city => city.value === generalInfo?.city);
  
  const GeneralInfoAPIData = {
    address: generalInfo?.address,
    city: matchingCity ? matchingCity?.label : "",
    country: generalInfo?.country,
    postalCode: generalInfo?.postalCode,
    province: generalInfo?.province,
    unitNumber: generalInfo?.unitNumber,
    organizationName: generalInfo?.organizationName,
    organizationSummary: generalInfo?.organizationSummary,
    type: userState.userPersona,
    emailAddress: generalInfo?.emailAddress,
    phoneNumber: userPhoneNumber,
    connectionEmailAddress:
      generalInfo?.connectionEmailAddress !== null
        ? generalInfo?.connectionEmailAddress
        : userEmail,
    connectionPhoneNumber:
      generalInfo?.connectionPhoneNumber !== null
        ? generalInfo?.connectionPhoneNumber
        : userPhoneNumber,
    websiteLink: generalInfo?.websiteLink,
  };

  const initialValues = {
    address: "",
    city: "",
    country: "Canada",
    postalCode: "",
    province: "",
    unitNumber: "",
    organizationName: "",
    organizationSummary: "",
    websiteLink: "",
    type: userState.userPersona,
    phoneNumber: userPhoneNumber,
    emailAddress: userEmail,
    connectionEmailAddress: userEmail,
    connectionPhoneNumber: userPhoneNumber || "",
  };
  const maxCharErrorMsg = "investor.general_form_error.max_characters";

  const maxCharsErrorText = t(maxCharErrorMsg, { charNum: 64 });
  const spacingErrorText = t("investor.general_form_error.no_spaces");
  const regxNotAllowOnlySpace = /^(?!\s*$)/;
  const phoneNumberCharacters = 10;
  const phoneNumberError = t("investor.general_form_error.phone_number_error");

  const generalInfoSchema = Yup.object().shape({
    organizationName: Yup.string()
      .required(t("investor.general_form_error.organization_name_required"))
      .max(128, t(maxCharErrorMsg, { charNum: 128 }))
      .matches(regxNotAllowOnlySpace, spacingErrorText),
    address: Yup.string()
      .required(t("investor.general_form_error.address_required"))
      .max(64, maxCharsErrorText)
      .matches(regxNotAllowOnlySpace, spacingErrorText),
    unitNumber: Yup.string()
      .max(64, maxCharsErrorText)
      .matches(regxNotAllowOnlySpace, spacingErrorText),
    province: Yup.number()
      .required(t("investor.general_form_error.province_required"))
      .max(64, maxCharsErrorText),
    city: Yup.string()
      .required(t("investor.general_form_error.city_required"))
      .max(64, maxCharsErrorText)
      .matches(regxNotAllowOnlySpace, spacingErrorText),
    postalCode: Yup.string()
      .required(t("investor.general_form_error.postal_code_required"))
      .max(6, t(maxCharErrorMsg, { charNum: 6 }))
      .matches(
        /^[A-Z]\d[A-Z]\d[A-Z]\d$/i,
        t("investor.general_form_error.postal_code_required")
      ),
    country: Yup.string().required(
      t("investor.general_form_error.country_required")
    ),
    organizationSummary: Yup.string()
      .max(1000, t(maxCharErrorMsg, { charNum: 1000 }))
      .matches(regxNotAllowOnlySpace, spacingErrorText),
    connectionPhoneNumber: Yup.number()
      .required(phoneNumberError)
      .typeError(phoneNumberError)
      .test(
        "length",
        phoneNumberError,
        (val) => val?.toString().length === phoneNumberCharacters
      ),
  });

  const updateGeneralInformation = async (formValues: Object) => {
    try {
      await DeveloperApi.updateGeneralInfoById(userState.userId, formValues);
      handleResponse("GeneralInfo", true);
    } catch (err) {
      handleResponse("GeneralInfo", false, err);
    }
  };

  const submitHandler = async (formData: object) => {
    try {
      const response = await DeveloperApi.postGeneralInfo(formData);
      //@ts-ignore
      handleResponse("GeneralInfo", true, undefined, response.data.id);
    } catch (err) {
      handleResponse("GeneralInfo", false, err);
    }
  };

  return (
    <Formik
      initialValues={isDataExist ? GeneralInfoAPIData : initialValues}
      validationSchema={generalInfoSchema}
      validateOnChange={false}
      validateOnBlur={false}
      enableReinitialize={true}
      onSubmit={(values) => {
        const {
          organizationName,
          address,
          unitNumber,
          city,
          organizationSummary,
          connectionPhoneNumber,
          connectionEmailAddress,
          phoneNumber,
          ...restValues
        } = values;
        const updatedValues = {
          organizationName: organizationName?.trim(),
          address: address?.trim(),
          unitNumber: unitNumber?.trim(),
          city: city?.trim(),
          organizationSummary: organizationSummary?.trim(),
          connectionPhoneNumber: connectionPhoneNumber?.trim(),
          connectionEmailAddress: connectionEmailAddress?.trim(),
          phoneNumber: userPhoneNumber
            ? userPhoneNumber?.trim()
            : connectionPhoneNumber?.trim(),
          ...restValues,
        };
        isDataExist
          ? updateGeneralInformation({ id: userState.userId, ...updatedValues })
          : submitHandler(updatedValues);
      }}
    >
      {(values) => (
      <Form className={styles.formElement} noValidate>
        <TextInput
          fieldName="organizationName"
          labelText={t("developer.general_info_form.org_name_label")}
          required
          data-testid="orginization-name"
        />
        <TextArea
          fieldName="organizationSummary"
          labelText={t("developer.general_info_form.org_summary_label")}
          data-testid="organization-summary"
          placeholder={t("developer.general_info_form.org_summary_placeholder")}
          charLimit={1000}
        />
        <TextInput
          fieldName="websiteLink"
          labelText={t("developer.general_info_form.website_link_label")}
          data-testid="website-link"
        />
        <hr className={styles.formHorizontalLine} />
        <h3 className={styles.formSectionHeading}>
          {t("developer.general_info_form.address_header")}
        </h3>

        <div className={styles.splitFieldContainer}>
          <TextInput
            fieldName="address"
            labelText={t("developer.general_info_form.address_label")}
            required
            data-testid="address"
          />

          <TextInput
            fieldName="unitNumber"
            labelText={t("developer.general_info_form.unit_label")}
            required
            data-testid="unit-number"
          />
        </div>

        <ProjectLocationFields province={values.values.province} isDataExist={isDataExist}/>
        

        <div className={styles.splitFieldContainer}>
          <TextInput
            fieldName="postalCode"
            labelText={t("developer.general_info_form.postal_code_label")}
            placeholder="A1A2A3"
            required
            data-testid="postal-code"
          />

          <TextInput
            fieldName="country"
            labelText={t("developer.general_info_form.country_label")}
            disabled
            data-testid="country"
          />
        </div>
        <hr className={styles.formHorizontalLine} />
        <h3 className={styles.formSectionHeading}>
          {t("developer.general_info_form.contact_header")}
        </h3>
        <p className={styles.formInfo}>
          {t("developer.general_info_form.contact_description")}
        </p>
        <div className={styles.splitFieldContainer}>
          <TextInput
            fieldName="connectionEmailAddress"
            labelText={t("developer.general_info_form.email_label")}
            type="email"
            required
            data-testid="email"
          />
          <TextInput
            fieldName="connectionPhoneNumber"
            labelText={t("developer.general_info_form.phone_number")}
            maxLength={phoneNumberCharacters}
            placeholder="1234567890"
            required
            numOnly
            data-testid="phone-number"
          />
        </div>
        <div className={styles.buttonContainer}>
          <button
            type="submit"
            className={styles.formButton}
            data-testid="submit-button"
          >
            {t("developer.main_page.next")}
          </button>
        </div>
      </Form>
      )}
    </Formik>
  );
}
