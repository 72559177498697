import React, { useState, useEffect } from "react";

import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { PageLayout } from "../../../components/Layout/PageLayout";
import { PageContent } from "../../../components/Layout/PageContent";
import styles from "./styles.module.scss";
import { ReviewAffordability } from "./ReviewAffordability";
import { ReviewPortfolioGeneralInfo } from "./ReviewPortfolioGeneralInfo";
import { ReviewFinancialDetail } from "./ReviewFinancialDetails";
import { ListingPortfolioAPI } from "../../../APIAction/ListingPortfolioAPI";

import axios from "axios";
import { ReviewPortfolioImpactCriteriaInfo } from "./ReviewPortfolioImpactCriteria";
import { ReviewProjectsWithinPortfolioInfo } from "./ReviewProjectsWithinPortfolio";
import { ReviewPortfolioDocumentation } from "./ReviewPortfolioDocumentation";

export const ReviewAddPortfolio = () => {
  const { t } = useTranslation(["onboarding", "profile"]);

  const navigate = useNavigate();
  const { portfolioId = "" } = useParams();

  const [info, setInfo] = useState<any>(null);
  const [isDataExist, setIsDataExist] = useState(false);
  const [affordability, setAffordability] = useState(null);

  const [esgSelection, setEsgSelection] = useState("");
  const [projectDetails, setProjectDetails] = useState("");
  const [capitalStacking, setCapitalStacking] = useState("");


  useEffect(() => {
    const getProjectsWithinPortfolio = async () => {
      try {
        const res = await ListingPortfolioAPI.getPortfolioProjectsInfoById(
          portfolioId
        );
        const data = res.data;
        setIsDataExist(true);
        setProjectDetails(data.displayProjectDetailsFlag ? "yes" : "no");

        setInfo(data);
      } catch (error) {
        setIsDataExist(false);
        if (axios.isAxiosError(error) && error.response?.status !== 404) {
          navigate("/api-error");
        }
      }
    };


    const getAffordabilityBenchmarkInfo = async () => {
      try {
        const res = await ListingPortfolioAPI.getPortfolioAffordabilityInfoById(portfolioId);
        const data = res.data;
        setAffordability(data);

      } catch (error) {
        if (axios.isAxiosError(error) && error.response?.status !== 404) {
          navigate("/api-error");
        }
      }
    };
    portfolioId !== "" && getAffordabilityBenchmarkInfo();
    portfolioId !== "" && getProjectsWithinPortfolio();


  }, [portfolioId]);


  const handleConfirm = async () => {
    const data = { portfolioID: portfolioId };
    try {

      portfolioId !== "" && (await ListingPortfolioAPI.postConfirmApi(data));
      window.dataLayer.push({
        event: "list_porfolio",
        project_details: projectDetails,
        esg_criteria: esgSelection,
        capital_stacking: capitalStacking,
      });
      navigate(`/portfolio/success/${portfolioId}`);
    } catch (err) {
      if (axios.isAxiosError(err)) {
        if (err.response?.status === 404) {
          return;
        } else {
          navigate("/api-error");
        }
      }
    }
  };
  return (
    <PageLayout>

      <PageContent>
        <div className={styles.container}>
          <div className={styles.headerContainer}>
            <h2
              className={styles.mainHeading}
              data-testid="review-project-heading"
            >
              {t("profile:developer.projects.review_portfolio.main_heading")}

            </h2>
            <p className={styles.subHeading}>
              {t("profile:developer.projects.review_portfolio.info")}
            </p>
          </div>
          <div
            className={styles.contentSection}
            data-testid="review-affordability-info"
          >
            {info !== null && affordability !== null &&
              <ReviewAffordability
                portfolioListingID={portfolioId}
                affordability={affordability}
                data={info.affordabilityTypesProjectCount}
              />
            }
          </div>
          <div
            className={styles.contentSection}
            data-testid="review-general-info"
          >
            <ReviewPortfolioGeneralInfo portfolioListingID={portfolioId} />
          </div>
          <div
            className={styles.contentSection}
            data-testid="review-Financial-info"
          >
            <ReviewFinancialDetail portfolioListingID={portfolioId} setCapitalStacking={setCapitalStacking} />
          </div>
          <div
            className={styles.contentSection}
            data-testid="review-Focus-info"
          >
            <ReviewPortfolioImpactCriteriaInfo portfolioListingID={portfolioId} setEsgSelection={setEsgSelection} />
          </div>
          <div
            className={styles.contentSection}
            data-testid="review-Focus-info"
          >
            <ReviewProjectsWithinPortfolioInfo portfolioListingID={portfolioId} data={info} dataExist={isDataExist} />
          </div>

          <div className={styles.contentSection}>
            <ReviewPortfolioDocumentation portfolioListingID={portfolioId} />
          </div>
          <div className={styles.noteConfirmationContainer}>
            <h2 className={styles.noteHeading}>
              {t("profile:developer.projects.file_upload.confirm_heading")}
            </h2>
                <p>
                  {t("profile:developer.projects.file_upload.confirm_note_portfolio")}
                </p>
          </div>

          <div className={styles.buttonContainer}>
            <button
              type="submit"
              className={styles.formButton}
              onClick={handleConfirm}
              data-testid="confirm-btn"
            >
              {t("profile:developer.projects.review_project.confirm_button")}
            </button>
          </div>
        </div>
      </PageContent>
    </PageLayout>
  );

};
