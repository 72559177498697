import axios, { AxiosResponse } from "axios";
import { ProjImpactAreaInfoI } from "../components/Form/AddProject/AddProjectProps";
import { ProjectSearchResults } from "../pages/Search/ProjectListingResultsProps";
import { config, requestAccessToken } from "./config";

const apiClient = axios.create({
    baseURL: config.url.base,
    headers: {
        "Content-type": "application/json",
    },
});

const getPortfolioGeneralInfoById = async (id: string) => {
    const token = await requestAccessToken();

    const response = await apiClient.get<AxiosResponse<any>>(
        `${config.url.portfolioListing.PortfolioGenInfo}/${id}`,
        { headers: { Authorization: `Bearer ${token}` } }
    );

    return response.data;
};


const postAffordabilityInfo = async (genInfo: Object) => {
    const token = await requestAccessToken();

    const response = await apiClient.post<AxiosResponse>(
        config.url.portfolioListing.PortfolioAffordabilityInfo,
        genInfo,
        { headers: { Authorization: `Bearer ${token}` } }
    );

    return response.data;
};




const updateAffordabilityInfo = async (id: string, genInfo: Object) => {
    const token = await requestAccessToken();

    const response = await apiClient.put<AxiosResponse>(
        `${config.url.portfolioListing.PortfolioAffordabilityInfo}/${id}`,
        genInfo,
        { headers: { Authorization: `Bearer ${token}` } }
    );

    return response.data;
}

const postPortfolioGenInfo = async (genInfo: Object) => {
    const token = await requestAccessToken();

    const response = await apiClient.post<AxiosResponse>(
        config.url.portfolioListing.PortfolioGenInfo,
        genInfo,
        { headers: { Authorization: `Bearer ${token}` } }
    );

    return response.data;
};

const updatePortfolioGenInfo = async (id: string, genInfo: Object) => {
    const token = await requestAccessToken();

    const response = await apiClient.put<AxiosResponse>(
        `${config.url.portfolioListing.PortfolioGenInfo}/${id}`,
        genInfo,
        { headers: { Authorization: `Bearer ${token}` } }
    );

    return response.data;
};

const getFinancialInfoById = async (id: string) => {
    const token = await requestAccessToken();

    const response = await apiClient.get<AxiosResponse>(
        `${config.url.portfolioListing.PortfolioFinancialDetail}/${id}`,
        { headers: { Authorization: `Bearer ${token}` } }
    );

    return response.data;
};

const postFinancialInfo = async (genInfo: Object) => {
    const token = await requestAccessToken();

    const response = await apiClient.post<AxiosResponse>(
        config.url.portfolioListing.PortfolioFinancialDetail,
        genInfo,
        { headers: { Authorization: `Bearer ${token}` } }
    );

    return response.data;
};

const updateFinancialInfo = async (id: string, genInfo: Object) => {
    const token = await requestAccessToken();

    const response = await apiClient.put<AxiosResponse>(
        `${config.url.portfolioListing.PortfolioFinancialDetail}/${id}`,
        genInfo,
        { headers: { Authorization: `Bearer ${token}` } }
    );

    return response.data;
};
const getPortfolioAffordabilityInfoById = async (id: string) => {
    const token = await requestAccessToken();
    const response = await apiClient.get<AxiosResponse>(
        `${config.url.portfolioListing.PortfolioAffordability}/${id}`,
        { headers: { Authorization: `Bearer ${token}` } }
    );

    return response.data;
};


const getPortfolioProjectsInfoById = async (id: string) => {
    const token = await requestAccessToken();

    const response = await apiClient.get<AxiosResponse>(
        `${config.url.portfolioListing.PortfolioProjects}/${id}`,
        { headers: { Authorization: `Bearer ${token}` } }
    );

    return response.data;
};

const postPortfolioProjectsInfo = async (genInfo: Object) => {
    const token = await requestAccessToken();

    const response = await apiClient.post<AxiosResponse>(
        config.url.portfolioListing.PortfolioProjects,
        genInfo,
        { headers: { Authorization: `Bearer ${token}` } }
    );

    return response.data;
};

const updatePortfolioProjectsInfo = async (id: string, genInfo: Object) => {
    const token = await requestAccessToken();

    const response = await apiClient.put<AxiosResponse>(
        `${config.url.portfolioListing.PortfolioProjects}/${id}`,
        genInfo,
        { headers: { Authorization: `Bearer ${token}` } }
    );

    return response.data;
};


const postClosePortfolio = async (projectInfo: Object) => {
    const token = await requestAccessToken();

    const response = await apiClient.post<AxiosResponse>(
        `${config.url.portfolioListing.ClosePortfolio}`,
        projectInfo,
        { headers: { Authorization: `Bearer ${token}` } }
    );

    return response.data;
};

const getPortfolioListingInfoByID = async (id: string) => {
    const token = await requestAccessToken();

    const response = await apiClient.get<AxiosResponse>(
        `${config.url.portfolioListing.PortfolioListingInfoById}/${id}`,
        { headers: { Authorization: `Bearer ${token}` } }
    );

    return response.data;
};

const deletePortfolioByID = async (id: string) => {
    const token = await requestAccessToken();

    const response = await apiClient.delete<AxiosResponse>(
        `${config.url.portfolioListing.DeletePortfolioById}/${id}`,
        { headers: { Authorization: `Bearer ${token}` } }
    );

    return response.data;
};

const searchListingPortfolios = async (filterData: Object) => {
    const token = await requestAccessToken();

    const response = await apiClient.post<ProjectSearchResults>(
        `${config.url.portfolioListing.PortfolioListingSearch}`,
        filterData,
        { headers: { Authorization: `Bearer ${token}` } }
    );

    return response.data;
};

const postConfirmApi = async (data: Object) => {
    const token = await requestAccessToken();

    const response = await apiClient.post<AxiosResponse>(
        `${config.url.portfolioListing.confirm}`,
        data,
        { headers: { Authorization: `Bearer ${token}` } }
    );

    return response.data;
};

const postImpactCriteria = async (impactInfo: Object) => {
    const token = await requestAccessToken();

    const response = await apiClient.post<AxiosResponse>(
        config.url.portfolioListing.PortfolioImpactCriteria,
        impactInfo,
        { headers: { Authorization: `Bearer ${token}` } }
    );

    return response.data;
};

const getImpactCriteriaById = async (id: string) => {
    const token = await requestAccessToken();

    const response = await apiClient.get<AxiosResponse>(
        `${config.url.portfolioListing.PortfolioImpactCriteria}/${id}`,
        { headers: { Authorization: `Bearer ${token}` } }
    );

    return response.data;
};

const updateImpactCriteria = async (id: string, impactInfo: Object) => {
    const token = await requestAccessToken();

    const response = await apiClient.put<AxiosResponse>(
        `${config.url.portfolioListing.PortfolioImpactCriteria}/${id}`,
        impactInfo,
        { headers: { Authorization: `Bearer ${token}` } }
    );

    return response.data;
};

const postDocumentationInfo = async (docInfo: Object) => {
    const token = await requestAccessToken();

    const response = await apiClient.post<AxiosResponse>(
        config.url.portfolioListing.portfolioListingDocumentation,
        docInfo,
        { headers: { Authorization: `Bearer ${token}` } }
    );

    return response.data;
};

const getDocumentationInfoById = async (id: string) => {
    const token = await requestAccessToken();

    const response = await apiClient.get<AxiosResponse<any>>(
        `${config.url.portfolioListing.portfolioListingDocumentation}/${id}`,
        { headers: { Authorization: `Bearer ${token}` } }
    );

    return response.data;
};


const updateDocumentationInfo = async (id: string, docInfo: Object) => {
    const token = await requestAccessToken();

    const response = await apiClient.put<AxiosResponse>(
        `${config.url.portfolioListing.portfolioListingDocumentation}/${id}`,
        docInfo,
        { headers: { Authorization: `Bearer ${token}` } }
    );

    return response.data;
};


export const ListingPortfolioAPI = {
    postAffordabilityInfo,
    updateAffordabilityInfo,
    getPortfolioAffordabilityInfoById,
    getPortfolioGeneralInfoById,
    postPortfolioGenInfo,
    updatePortfolioGenInfo,
    getFinancialInfoById,
    postFinancialInfo,
    updateFinancialInfo,
    getPortfolioProjectsInfoById,
    postPortfolioProjectsInfo,
    updatePortfolioProjectsInfo,
    postClosePortfolio,
    getPortfolioListingInfoByID,
    deletePortfolioByID,
    searchListingPortfolios,
    postConfirmApi,
    postImpactCriteria,
    updateImpactCriteria,
    getImpactCriteriaById,
    postDocumentationInfo,
    getDocumentationInfoById,
    updateDocumentationInfo
};
