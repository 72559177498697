import axios, { AxiosResponse } from "axios";
import { ProjFocusAreaInfoI, ProjImpactAreaInfoI } from "../components/Form/AddProject/AddProjectProps";
import { ProjectSearchResults } from "../pages/Search/ProjectListingResultsProps";
import { ProjectGeneralInfo } from "../TypesAndInterfaces/ProjectDetailsInterface";
import { config, requestAccessToken } from "./config";

const apiClient = axios.create({
  baseURL: config.url.base,
  headers: {
    "Content-type": "application/json",
  },
});

const getProjectGeneralInfoById = async (id: string) => {
  const token = await requestAccessToken();

  const response = await apiClient.get<AxiosResponse<ProjectGeneralInfo>>(
    `${config.url.projectListing.ProjectGenInfo}/${id}`,
    { headers: { Authorization: `Bearer ${token}` } }
  );

  return response.data;
};

const postProjectGenInfo = async (genInfo: Object) => {
  const token = await requestAccessToken();

  const response = await apiClient.post<AxiosResponse>(
    config.url.projectListing.ProjectGenInfo,
    genInfo,
    { headers: { Authorization: `Bearer ${token}` } }
  );

  return response.data;
};

const updateProjectGenInfo = async (id: string, genInfo: Object) => {
  const token = await requestAccessToken();

  const response = await apiClient.put<AxiosResponse>(
    `${config.url.projectListing.ProjectGenInfo}/${id}`,
    genInfo,
    { headers: { Authorization: `Bearer ${token}` } }
  );

  return response.data;
};



const getProjectAffordabilityInfoById = async (id: string) => {
  const token = await requestAccessToken();

  const response = await apiClient.get<AxiosResponse<ProjectGeneralInfo>>(
    `${config.url.projectListing.ProjectAffordability}/${id}`,
    { headers: { Authorization: `Bearer ${token}` } }
  );

  return response.data;
};

const postProjectAffordability = async (genInfo: Object) => {
  const token = await requestAccessToken();

  const response = await apiClient.post<AxiosResponse>(
    config.url.projectListing.ProjectAffordability,
    genInfo,
    { headers: { Authorization: `Bearer ${token}` } }
  );

  return response.data;
};

const updateProjectAffordability = async (id: string, genInfo: Object) => {
  const token = await requestAccessToken();

  const response = await apiClient.put<AxiosResponse>(
    `${config.url.projectListing.ProjectAffordability}/${id}`,
    genInfo,
    { headers: { Authorization: `Bearer ${token}` } }
  );

  return response.data;
};


const getFinancialInfoById = async (id: string) => {
  const token = await requestAccessToken();

  const response = await apiClient.get<AxiosResponse>(
    `${config.url.projectListing.ProjectFinancialDetail}/${id}`,
    { headers: { Authorization: `Bearer ${token}` } }
  );

  return response.data;
};

const postFinancialInfo = async (genInfo: Object) => {
  const token = await requestAccessToken();

  const response = await apiClient.post<AxiosResponse>(
    config.url.projectListing.ProjectFinancialDetail,
    genInfo,
    { headers: { Authorization: `Bearer ${token}` } }
  );

  return response.data;
};

const updateFinancialInfo = async (id: string, genInfo: Object) => {
  const token = await requestAccessToken();

  const response = await apiClient.put<AxiosResponse>(
    `${config.url.projectListing.ProjectFinancialDetail}/${id}`,
    genInfo,
    { headers: { Authorization: `Bearer ${token}` } }
  );

  return response.data;
};

const getFocusAreaById = async (id: string) => {
  const token = await requestAccessToken();

  const response = await apiClient.get<AxiosResponse<ProjFocusAreaInfoI>>(
    `${config.url.projectListing.ProjectFocusArea}/${id}`,
    { headers: { Authorization: `Bearer ${token}` } }
  );

  return response.data;
};

const postFocusArea = async (genInfo: Object) => {
  const token = await requestAccessToken();

  const response = await apiClient.post<AxiosResponse>(
    config.url.projectListing.ProjectFocusArea,
    genInfo,
    { headers: { Authorization: `Bearer ${token}` } }
  );

  return response.data;
};

const updateFocusArea = async (id: string, genInfo: Object) => {
  const token = await requestAccessToken();

  const response = await apiClient.put<AxiosResponse>(
    `${config.url.projectListing.ProjectFocusArea}/${id}`,
    genInfo,
    { headers: { Authorization: `Bearer ${token}` } }
  );

  return response.data;
};

const postCloseProject = async (projectInfo: Object) => {
  const token = await requestAccessToken();

  const response = await apiClient.post<AxiosResponse>(
    `${config.url.projectListing.CloseProject}`,
    projectInfo,
    { headers: { Authorization: `Bearer ${token}` } }
  );

  return response.data;
};

const getProjectListingInfoByID = async (id: string) => {
  const token = await requestAccessToken();

  const response = await apiClient.get<AxiosResponse>(
    `${config.url.projectListing.ProjectListingInfoById}/${id}`,
    { headers: { Authorization: `Bearer ${token}` } }
  );

  return response.data;
};

const deleteProjectByID = async (id: string) => {
  const token = await requestAccessToken();

  const response = await apiClient.delete<AxiosResponse>(
    `${config.url.projectListing.DeleteProjectById}/${id}`,
    { headers: { Authorization: `Bearer ${token}` } }
  );

  return response.data;
};

const searchListingProjects = async (filterData: Object) => {
  const token = await requestAccessToken();

  const response = await apiClient.post<ProjectSearchResults>(
    `${config.url.projectListing.projectListingSearch}`,
    filterData,
    { headers: { Authorization: `Bearer ${token}` } }
  );

  return response.data;
};

const postConfirmApi = async (data: Object) => {
  const token = await requestAccessToken();

  const response = await apiClient.post<AxiosResponse>(
    `${config.url.projectListing.confirm}`,
    data,
    { headers: { Authorization: `Bearer ${token}` } }
  );

  return response.data;
};

const postImpactCriteria = async (impactInfo: Object) => {
  const token = await requestAccessToken();

  const response = await apiClient.post<AxiosResponse>(
    config.url.projectListing.ProjectImpactCriteria,
    impactInfo,
    { headers: { Authorization: `Bearer ${token}` } }
  );

  return response.data;
};

const getImpactCriteriaById = async (id: string) => {
  const token = await requestAccessToken();

  const response = await apiClient.get<AxiosResponse<ProjImpactAreaInfoI>>(
    `${config.url.projectListing.ProjectImpactCriteria}/${id}`,
    { headers: { Authorization: `Bearer ${token}` } }
  );

  return response.data;
};

const updateImpactCriteria = async (id: string, impactInfo: Object) => {
  const token = await requestAccessToken();

  const response = await apiClient.put<AxiosResponse>(
    `${config.url.projectListing.ProjectImpactCriteria}/${id}`,
    impactInfo,
    { headers: { Authorization: `Bearer ${token}` } }
  );

  return response.data;
};

const postDocumentationInfo = async (docInfo: Object) => {
  const token = await requestAccessToken();

  const response = await apiClient.post<AxiosResponse>(
    config.url.projectListing.projectListingDocumentation,
    docInfo,
    { headers: { Authorization: `Bearer ${token}` } }
  );

  return response.data;
};

const getDocumentationInfoById = async (id: string) => {
  const token = await requestAccessToken();

  const response = await apiClient.get<AxiosResponse<any>>(
    `${config.url.projectListing.projectListingDocumentation}/${id}`,
    { headers: { Authorization: `Bearer ${token}` } }
  );

  return response.data;
};


const updateDocumentationInfo = async (id: string, docInfo: Object) => {
  const token = await requestAccessToken();

  const response = await apiClient.put<AxiosResponse>(
    `${config.url.projectListing.projectListingDocumentation}/${id}`,
    docInfo,
    { headers: { Authorization: `Bearer ${token}` } }
  );

  return response.data;
};
export const ListingProjectAPI = {
  getProjectGeneralInfoById,
  postProjectGenInfo,
  updateProjectGenInfo,
  getProjectAffordabilityInfoById,
  postProjectAffordability,
  updateProjectAffordability,
  getFinancialInfoById,
  postFinancialInfo,
  updateFinancialInfo,
  getFocusAreaById,
  postFocusArea,
  updateFocusArea,
  postCloseProject,
  getProjectListingInfoByID,
  deleteProjectByID,
  searchListingProjects,
  postConfirmApi,
  postImpactCriteria,
  updateImpactCriteria,
  getImpactCriteriaById,
  postDocumentationInfo,
  getDocumentationInfoById,
  updateDocumentationInfo
};
