import { useTranslation } from "react-i18next";
import styles from "./connectionHub.module.scss";

type Props = {
  emailAddress: string;
  phoneNumber: string;
};

export function CardDetailsConnected(props: Props) {
  const { t } = useTranslation("connectionHub");

  return (
    <div className={styles.information} data-testid="card-connected-info">
      <div className={styles.titleInfoStyles}>
        {t("ConnectionHub.connectionTab.infoTitle")}
      </div>
      <div className={styles.infomail}>
        {t("ConnectionHub.connectionTab.email")}
        {props.emailAddress}
      </div>
      <div className={styles.infomail}>
        {t("ConnectionHub.connectionTab.mobile")}
        {props.phoneNumber}
      </div>
    </div>
  );
}
