import { useTranslation } from "react-i18next";
import { AccordionContainer } from "../../../components/common/AccordionContainer/AccordionContainer";
import styles from "../Resources.module.scss";
import { PageNavigation } from "../PageNavigation";

export function GeneralResources() {
  const { t } = useTranslation("resources");
  const pageNavLinks = [
    { text: t("general_reources_links.housing_affordable"), id: "#1" },
    { text: t("general_reources_links.building_affordable"), id: "#2" },
    { text: t("general_reources_links.invest_affordable"), id: "#3" },
  ];
  function generateGeneralResourceAccordionData(
    labelTranslationString: string,
    totalLabels: number,
    pdfLinkSectionDetail?: { sectionNum: number; numLinks: number }[]
  ) {
    const accordionData = [];
    for (let i = 1; i <= totalLabels; i++) {
      const matchingPdfIndex = pdfLinkSectionDetail
        ? pdfLinkSectionDetail.findIndex((pdf) => pdf.sectionNum === i)
        : -1;
      accordionData.push({
        heading: t(`${labelTranslationString}_${i}.heading`),
        summary: t(`${labelTranslationString}_${i}.body`),
        linkpath: t(`${labelTranslationString}_${i}.learn_more_href`),
        pdfLinks:
          matchingPdfIndex > -1
            ? pdfLinkSectionDetail &&
            generatePdfLinks(
              "building_affordable_housing_accordion.section",
              i,
              pdfLinkSectionDetail[matchingPdfIndex].numLinks
            )
            : undefined,
        content: t("learn_more_content"),
        trackGA: {
          event: "open_resource_section",
          resource_name: t(`${labelTranslationString}_${i}.heading`),
        },
      });
    }

    return accordionData;
  }

  function generatePdfLinks(
    labelTranslationString: string,
    sectionNumber: number,
    totalLabels: number
  ) {
    const pdfLinks = [];
    for (let i = 1; i <= totalLabels; i++) {
      pdfLinks.push({
        path: t(`${labelTranslationString}_${sectionNumber}.pdf.link_${i}`),
        label: t(`${labelTranslationString}_${sectionNumber}.pdf.label_${i}`),
      });
    }
    return pdfLinks;
  }

  const accordionMakingHousingAffordable = generateGeneralResourceAccordionData(
    "making_housing_affordable_accordion.section",
    3
  );
  const accordionBuildingAffordableHousing =
    generateGeneralResourceAccordionData(
      "building_affordable_housing_accordion.section",
      4,
      [{ sectionNum: 4, numLinks: 2 }]
    );
  const accordionSocialImpact = generateGeneralResourceAccordionData(
    "social_impact_accordion.section",
    1
  );

  return (
    <section
      className={styles.sectionContainerResources}
      data-testid="resources-general"
    >
      <div className={styles.sideSection}>
        <PageNavigation
          heading={t("page_navigation_heading")}
          links={pageNavLinks}
        />
      </div>
      <div>
        <div className={styles.container}>
          <div className={styles.info}>
            <h3 className={styles.subHeading} id="1">
              {t("general_resources.subheading_making_housing")}
            </h3>

            <AccordionContainer data={accordionMakingHousingAffordable} />

            <h3 className={styles.subHeading} id="2">
              {t("general_resources.subheading_building_housing")}
            </h3>

            <AccordionContainer data={accordionBuildingAffordableHousing} />

            <h3 className={styles.subHeading} id="3">
              {t("general_resources.subheading_social_impact")}
            </h3>

            <AccordionContainer data={accordionSocialImpact} />
          </div>
        </div>
      </div>

    </section>
  );
}
