import styles from "./ProjectSummary.module.scss";
import { RedCircleCrossIcon } from "../../../assets/icons/index";
import { CheckMarkPortfolio } from "../../../assets/icons/index";
import { useTranslation } from "react-i18next";
import {
  getEnumKeyByValue,
  getImageOptions,
  IMAGE_TYPES_2X,
  PROVINCES,
} from "../../../Constants/Enumerations";
import { ProjectGeneralInfo } from "../../../TypesAndInterfaces/ProjectDetailsInterface";
import { ProjectImg } from "../../../assets/images";
import { ConnectionStatusMessage } from "./ConnectionMessage";
import { useUser } from "../../../components/Context/UserContext";
import { ProjectStatus } from "../../../components/common/Cards/ProjectStatus";

type Props = {
  projectCloseStatus: boolean;
  generalInformation: ProjectGeneralInfo;
  affordabilityDetails: any;
  handleConnect: Function;
  developerID: string;
  projectListingID: string;
  showRequestSentSuccess: boolean;
  closeProject: any;
  projectListingStatus: number;
};

export function ProjectSummary(props: Props) {
  const { t } = useTranslation(["project"]);
  const { userState } = useUser();

  const selectedImageName = props.generalInformation.projectBannerCode;
  const projectCloseStatus = props.projectCloseStatus;
  const totalUnitCount = props.affordabilityDetails?.totalUnitCount;
  const belowAverageMarketRentUnitCount =
    props.affordabilityDetails?.belowAverageMarketRentUnitCount;
  const sasTokenDocumentStorage = process.env.REACT_APP_DOCUMENT_SAS_TOKEN;

  const primaryImg =
    props.generalInformation.blobs &&
    props.generalInformation.blobs.length !== 0
      ? props.generalInformation.blobs.find(
          (blob) => blob.primaryBlobFlag === true
        ).blobUri
      : undefined;

  const projectImg = primaryImg
    ? primaryImg + sasTokenDocumentStorage
    : ProjectImg;

  let affordableUnitsPercentage: number | string = t(
    "project.details.one_hundred_percent"
  );
  if (
    totalUnitCount &&
    belowAverageMarketRentUnitCount &&
    totalUnitCount !== 0
  ) {
    const percentage = (belowAverageMarketRentUnitCount / totalUnitCount) * 100;
    if (!isNaN(percentage)) {
      affordableUnitsPercentage = percentage.toFixed() + "%";
    }
  }
  const imagesAltTextTranslated = Object.keys(IMAGE_TYPES_2X).map((val) =>
    t(`common:enumerations.image_alt_text.${val}`)
  );
  const projectBannersOptions = getImageOptions(
    IMAGE_TYPES_2X,
    imagesAltTextTranslated
  );
  const selectedImage = projectBannersOptions.find(
    (banner) => banner.value === selectedImageName
  );
  const projectHighlights: { label: string; value: string }[] = [
    {
      label: t("project:project.details.total_units"),
      value: props.affordabilityDetails
        ? props.affordabilityDetails.totalUnitCount
          ? props.affordabilityDetails.totalUnitCount
          : props.affordabilityDetails.totalUnitCount
        : "",
    },
    {
      label: t("project:project.details.affordable_percent"),
      value: affordableUnitsPercentage,
    },
    {
      label: t("project:project.details.start_date"),
      value: props.generalInformation.targetProjectStartDate,
    },
    {
      label: t("project:project.details.completion_date"),
      value: props.generalInformation.targetProjectCompleteDate,
    },
  ];

  const provinceMappedValue = getEnumKeyByValue(
    PROVINCES,
    props.generalInformation.province
  );

  const provinceTranslated = t(
    `common:enumerations.provinces.${provinceMappedValue}`
  );

  return (
    <div className={styles.summaryContainer}>
      <div className={styles.imgContainer}>
        <img
          src={projectImg}
          alt={selectedImage?.altText}
          className={styles.projectBannerImage}
          data-testid="banner-Image"
        />
        <div
          className={`${styles.status} ${
            props.projectListingStatus === 2
              ? styles.greenBackground
              : styles.redBackground
          }`}
        >
          <ProjectStatus status={props.projectListingStatus} />
        </div>
      </div>
      <div className={styles.infoContainer}>
        <p className={styles.orgName} data-testid="organization-Name">
          {props.generalInformation.organizationName}
        </p>
        <h2 className={styles.projectName} data-testid="project-Name">
          {props.generalInformation.projectName}
        </h2>
        <h4
          className={styles.locationText}
          data-testid="location-Info"
        >{`${props.generalInformation.city}, ${provinceTranslated}`}</h4>

        <hr className={styles.hrDivider} />

        <div
          className={styles.projectHighlightsContainer}
          data-testid="project-Highlights"
        >
          {projectHighlights.map((prj, idx) => (
            <div key={idx}>
              <p className={styles.highlightLabel}>{prj.label}</p>
              <p className={styles.highlightValue}>{prj.value}</p>
            </div>
          ))}
        </div>
        {userState.userId !== props?.developerID && !props.closeProject ? (
          <div className={styles.connectContainer}>
            <ConnectionStatusMessage
              handleConnect={() => props.handleConnect()}
              developerID={props.developerID}
              projectListingID={props.projectListingID}
              updateConnectionStatus={props.showRequestSentSuccess}
            />
          </div>
        ) : null}
        {projectCloseStatus ? (
          <button className={styles.closedProject}>
            <img
              src={
                props.closeProject.closeReasonCode % 2 == 0
                  ? RedCircleCrossIcon
                  : CheckMarkPortfolio
              }
              alt={
                props.closeProject.closeReasonCode % 2 == 0
                  ? t("project:project.main_page.project_closed.wrong_icon")
                  : t("project:project.main_page.project_closed.check_icon")
              }
              className={styles.crossIcon}
            />
            <p className={styles.closedProjectText}>
              {props.closeProject.closeReasonCode % 2 == 0
                ? t("project:project.main_page.project_closed.closed")
                : t("project:project.main_page.project_closed.funded")}
            </p>
          </button>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}
