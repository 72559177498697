import axios, { AxiosResponse } from "axios";
import { config, requestAccessToken } from "./config";
import { ProjectCardI } from "../TypesAndInterfaces/ProjectDetailsInterface";
import { DeveloperDetails } from "../TypesAndInterfaces/DeveloperDetailsInterface";

const apiClient = axios.create({
  baseURL: config.url.base,
  headers: {
    "Content-type": "application/json",
  },
});

const getGeneralInfoById = async (id: string) => {
  const token = await requestAccessToken();
  const getcallResponse = await apiClient.get<AxiosResponse>(
    `${config.url.developer.GenInfo}/${id}`,
    { headers: { Authorization: `Bearer ${token}` } }
  );
  return getcallResponse.data;
};

const postGeneralInfo = async (genInfo: Object) => {
  const token = await requestAccessToken();
  const postcallResponse = await apiClient.post<AxiosResponse>(
    config.url.developer.GenInfo,
    genInfo,
    { headers: { Authorization: `Bearer ${token}` } }
  );
  return postcallResponse.data;
};

const updateGeneralInfoById = async (id: string, genInfo: Object) => {
  const token = await requestAccessToken();
  const putcallResponse = await apiClient.put<AxiosResponse>(
    `${config.url.developer.GenInfo}/${id}`,
    genInfo,
    { headers: { Authorization: `Bearer ${token}` } }
  );
  return putcallResponse.data;
};

const getDeveloperInfoById = async (id: string) => {
  const token = await requestAccessToken();
  const getcallResponse = await apiClient.get<AxiosResponse>(
    `${config.url.developer.developerInfo}/${id}`,
    { headers: { Authorization: `Bearer ${token}` } }
  );
  return getcallResponse.data;
};

const postDeveloperInfo = async (devInfo: Object) => {
  const token = await requestAccessToken();
  const postcallResponse = await apiClient.post<AxiosResponse>(
    config.url.developer.developerInfo,
    devInfo,
    { headers: { Authorization: `Bearer ${token}` } }
  );
  return postcallResponse.data;
};

const updateDeveloperInfoById = async (id: string, developerInfo: Object) => {
  const token = await requestAccessToken();
  const putcallResponse = await apiClient.put<AxiosResponse>(
    `${config.url.developer.developerInfo}/${id}`,
    developerInfo,
    { headers: { Authorization: `Bearer ${token}` } }
  );
  return putcallResponse.data;
};

const searchDeveloperProjectsInfo = async (searchData: Object) => {
  const token = await requestAccessToken();
  return apiClient.post<ProjectCardI>(
    `${config.url.developer.projectInfoSearch}`,
    searchData,
    { headers: { Authorization: `Bearer ${token}` } }
  );
};

const searchDeveloperPortfolioInfo = async (searchData: Object) => {
  const token = await requestAccessToken();
  return apiClient.post<ProjectCardI>(
    `${config.url.developer.portfolioInfoSearch}`,
    searchData,
    { headers: { Authorization: `Bearer ${token}` } }
  );
};


const postDeveloperIdByEmail = async (email: string) => {
  const token = await requestAccessToken();

  const res = await apiClient.post<AxiosResponse<DeveloperDetails>>(
    config.url.developer.developerIdByEmail,
    { emailAddress: email },
    { headers: { Authorization: `Bearer ${token}` } }
  );
  return res.data.data;
};

const getActiveProjects = async (id: string) => {
  const token = await requestAccessToken();
  const res = await apiClient.post<ProjectCardI>(
    config.url.developer.activeProjects,
    { id: id },
    { headers: { Authorization: `Bearer ${token}` } }
  );
  return res.data;
};

const getActivePortfolio = async (id: string) => {
  const token = await requestAccessToken();
  const res = await apiClient.post<ProjectCardI>(
    config.url.developer.activePortfolio,
    { developerId: id },
    { headers: { Authorization: `Bearer ${token}` } }
  );
  return res.data;
};


const getDeveloperById = async (id: string) => {
  const token = await requestAccessToken();
  const res = await apiClient.get(`${config.url.developer.projectInfo}/${id}`, {
    headers: { Authorization: `Bearer ${token}` },
  });

  return res.data;
};

const getOrganizationInfoById = async (id: string) => {
  const token = await requestAccessToken();
  const getcallResponse = await apiClient.get<AxiosResponse>(
    `${config.url.developer.OrgInfo}/${id}`,
    { headers: { Authorization: `Bearer ${token}` } }
  );
  return getcallResponse.data;
};

const postOrganizationInfo = async (orgInfo: Object) => {
  const token = await requestAccessToken();
  const postcallResponse = await apiClient.post<AxiosResponse>(
    config.url.developer.OrgInfo,
    orgInfo,
    { headers: { Authorization: `Bearer ${token}` } }
  );
  return postcallResponse.data;
};

const updateOrganizationInfoById = async (id: string, genInfo: Object) => {
  const token = await requestAccessToken();
  const putcallResponse = await apiClient.put<AxiosResponse>(
    `${config.url.developer.OrgInfo}/${id}`,
    genInfo,
    { headers: { Authorization: `Bearer ${token}` } }
  );
  return putcallResponse.data;
};

const postDeveloperSearch = async (filters: Object) => {
  const token = await requestAccessToken();
  const response = await apiClient.post(config.url.developer.Search, filters, {
    headers: { Authorization: `Bearer ${token}` },
  });
  return response.data;
};


export const DeveloperApi = {
  getGeneralInfoById,
  postGeneralInfo,
  updateGeneralInfoById,
  getDeveloperInfoById,
  postDeveloperInfo,
  updateDeveloperInfoById,
  searchDeveloperProjectsInfo,
  postDeveloperIdByEmail,
  getActiveProjects,
  getActivePortfolio,
  getDeveloperById,
  getOrganizationInfoById,
  updateOrganizationInfoById,
  postOrganizationInfo,
  searchDeveloperPortfolioInfo,
  postDeveloperSearch
};
