import React, { useState } from "react";
import styles from "./FinancialDetails.module.scss";
import { Social, Supply, Focus, Balance } from "../../../assets/icons";
import { useTranslation } from "react-i18next";

type Props = {
  highlightLabel: string;
  highlightValue: number;
  projectCountData?:any;
};

export function AffordabilityProjectBadge(props: Props) {
  const { t } = useTranslation("profile");
  
  const focusOnAffordableHousingCount = props.projectCountData?.affordabilityTypesProjectCount?.focusOnAffordableHousingCount;
  const socialImpactingHousingCount = props.projectCountData?.affordabilityTypesProjectCount?.socialImpactingHousingCount;
  const balanceBetweenAffordableAndSupplyCount = props.projectCountData?.affordabilityTypesProjectCount?.balanceBetweenAffordableAndSupplyCount;
  const focusOnHousingSupplyCount = props.projectCountData?.affordabilityTypesProjectCount?.focusOnHousingSupplyCount;
  const affirdabilityTypesOptions = [
    {
      label: t(
        "common:enumerations.affordability_Types.SocialImpactingHousing"
      ),
      value: 1,
      description: t(
        "developer.projects.add_listing_form.socialImpactHousingDescription"
      ),
      affordabilityProjectCount: socialImpactingHousingCount ?? ""
    },
    {
      label: t(
        "common:enumerations.affordability_Types.FocusOnAffordableHousing"
      ),
      value: 2,
      description: t(
        "developer.projects.add_listing_form.affordableHousingDescription"
      ),
      affordabilityProjectCount: focusOnAffordableHousingCount ?? ""
    },
    {
      label: t(
        "common:enumerations.affordability_Types.BalanceBetweenAffordableAndSupply"
      ),
      value: 3,
      description: t(
        "developer.projects.add_listing_form.affirdabilityAndSupplyDescription"
      ),
      affordabilityProjectCount: balanceBetweenAffordableAndSupplyCount ?? ""
    },
    {
      label: t(
        "common:enumerations.affordability_Types.FocusOnHousingSupply"
      ),
      value: 4,
      description: t(
        "developer.projects.add_listing_form.housingSupplydescription"
      ),
      affordabilityProjectCount: focusOnHousingSupplyCount ?? ""
    },
  ]
  const projectAffordabilityTypeValue =
    affirdabilityTypesOptions.find(
      (item) => +item.value === props.highlightValue
    );

  return (
    <div className={styles.highlightContainer}>
      <p className={styles.highlightLabel}>{props.highlightLabel}</p>
      <p className={styles.highlightValue}>{
        <React.Fragment>
          <img
            className={styles.icon}
            alt={projectAffordabilityTypeValue?.label}
            src={props.highlightValue === 1 ? Social : props.highlightValue === 2 ? Focus : props.highlightValue === 3 ? Balance : Supply}
          />
          <span className={styles.highlightbadge} >{`${projectAffordabilityTypeValue?.label} (${
          projectAffordabilityTypeValue?.affordabilityProjectCount.length > 1
            ? `${projectAffordabilityTypeValue?.affordabilityProjectCount} Projects`
            : `${projectAffordabilityTypeValue?.affordabilityProjectCount} Project`
            })`}</span>
        </React.Fragment>

      }<br />{projectAffordabilityTypeValue?.description}</p>
    </div >
  );
}
