import styles from "./styles.module.scss";
import { Snackbar } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { SyntheticEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export function AwaitingFeedbackToast() {
  const { t } = useTranslation(["connectionStatus"]);

  const [open, setOpen] = useState(false);

  useEffect(() => {

    const isCloseBtnClicked =
      localStorage.getItem("feedbackMessage") === "true"
        ? true
        : false;
    if (!isCloseBtnClicked) {
      setOpen(true);
    }
  }, [open]);

  function handleCloseBtn() {
    localStorage.setItem("feedbackMessage", "true");
    setOpen(false);
  }

  const handleClose = (event: Event | SyntheticEvent, reason: string) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const toastMsg = (
    <div className={styles.snackbarFeedbackContent}>
      <div>
        <p>
          {t("feedback_request_snackbar_text")}{" "}
          <a
            href="https://questionnaire.simplesurvey.com/f/s.aspx?s=69c03bc5-f178-49d9-b765-a25177fb1870"
            className={styles.snackbarCardDetailLink}
            target="_blank"
          >
            {t("feedback_link_text")}
          </a>
        </p>
      </div>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleCloseBtn}
        data-testid="request-button"

      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </div>
  );

  return (
    <Snackbar
      className={styles.ccSnackbar}
      ContentProps={{
        sx: {
          background: "#004F71"
        }
      }}
      open={open}
      anchorOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      onClose={handleClose}
      message={toastMsg}
    />
  );
}
