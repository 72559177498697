import React from "react";
import TagManager from "react-gtm-module";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./i18n";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
const tagManagerArgs = { gtmId: process.env.REACT_APP_GTM_ID || "" };
declare global {
  interface Window {
    dataLayer: any[];
  }
}

TagManager.initialize(tagManagerArgs);

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
