import { Menu, MenuItem } from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { DropDown } from "../../assets/icons/index";
import styles from "./ProjectDetailsDropdown.module.scss";

type Props = {
  handleDropDownClick: (value: string) => void;
  closeFlag: boolean;
};

export const ProjectDetailsDropdown = (props: Props) => {
  const { t } = useTranslation("project");
  const [anchorElement, setanchorElement] = useState<null | HTMLElement>();
  const open = Boolean(anchorElement);

  const handleDropDownClick = props.handleDropDownClick;
  const close = props.closeFlag;
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setanchorElement(event.currentTarget);
  };

  const handleClose = () => {
    setanchorElement(null);
  };

  const dropDownLinks = [
    { link: t("project.edit_project"), id: "edit_project" },
    { link: t("project.close_project"), id: "close_project" },
    { link: t("project.delete_project"), id: "delete_project" },
  ];
  return (
    <div>
      <button
        type="button"
        id="info-menu-button"
        aria-controls={open ? "info-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        className={styles.dropDownBtn}
      >
        {t("project.manage_project")}
        <img
          src={DropDown}
          alt="dropdowntext"
          className={styles.dropDownStyles}
        />
      </button>
      <div className={styles.menuContainer}>
        <Menu
          open={open}
          onClose={handleClose}
          id="info-menu"
          anchorEl={anchorElement}
          MenuListProps={{
            "aria-labelledby": "info-menu-button",
          }}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          transformOrigin={{ vertical: "top", horizontal: "right" }}
        >
          {dropDownLinks.map((dropDownValue, index) => (
            <MenuItem
              key={index}
              onClick={() => {
                handleDropDownClick(dropDownValue.id);
                handleClose();
              }}
              className={styles.menuStyles}
              disabled={close && (index === 0 || index === 1)}
            >
              {dropDownValue.link}
            </MenuItem>
          ))}
        </Menu>
      </div>
    </div>
  );
};
