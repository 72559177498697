import styles from "./FinancialDetails.module.scss";

type Props = {
  highlightLabel: string;
  highlightValue: string;
};

export function FinancialDetailHighlightSingle(props: Props) {
  return (
    <div className={styles.highlightContainer}>
      <p className={styles.highlightLabel}>{props.highlightLabel}</p>
      <p className={styles.highlightValue}>{props.highlightValue}</p>
    </div>
  );
}
