import { FocusAreaIcon } from '../../../../assets/icons';
import styles from './styles.module.scss';
type Props = {
    sectionHeading: string;
    editLinkText: string;
    handleEditLink: () => void;
}
export const EditLinkHeader = (props: Props) => {
    const { sectionHeading, editLinkText, handleEditLink } = props;
    return (
        <div className={styles.infoHeadingContainer} data-testid="infoheading-container">
            <div className={styles.left}>
                <div className={styles.imgContainer} data-testid="img-container">
                    <img src={FocusAreaIcon} alt="target" />
                </div>
                <h3 className={styles.sectionHeading} data-testid="section-heading">
                    {sectionHeading}
                </h3>
            </div>
            <button className={styles.link} onClick={handleEditLink} data-testid="edit-link">
                {editLinkText}
            </button>
        </div>
    )
}