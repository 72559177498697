import { useNavigate } from "react-router-dom";
import { Chips } from "../../Chips/Chips";
import { ImpactData } from "../../Chips/types";
import styles from "./styles.module.scss";
import { isEmpty, isNotEmpty } from "../../../../Constants/basicFunctions";

type Props = {
  titleText: string;
  descriptionLabel: string;
  description: string;
  tagsData: ImpactData[];
  noImpactLabel?: string;
  addImpactLinkText?: string;
  addImpactLink?: string;
  otherUserLoggedInflag?: boolean;
  status?: number;
};

export const ImpactInfoDetailsSection = (props: Props) => {

  const {
    titleText,
    tagsData,
    description,
    descriptionLabel,
    addImpactLinkText,
    noImpactLabel,
    addImpactLink,
    otherUserLoggedInflag,
    status,
    } = props;
  const navigate = useNavigate();

  return (
    <div className={styles.sectionTitleContainer}>
      <div className={styles.subContainer}>
        <h3 className={styles.title} data-testid={"title-text"}>{titleText}</h3>
        {(isEmpty(tagsData) && isEmpty(description)) ?        
          (<p data-testid={"noimpact-label"}> 
          {noImpactLabel?.replace('{{indicator}}', titleText.toLowerCase())}
            {!otherUserLoggedInflag && <button
              type="button"
              className={styles.clickAddButtton}
              data-testid={"add-impact-link"}
              disabled={status === 3 ? true : false}
              onClick={() => { navigate(addImpactLink!) }}
            >
              {addImpactLinkText}
            </button>
            }
            </p>)       
          : (
            <>
              {isNotEmpty(tagsData) && tagsData.length > 0 && (
                <div className={styles.chipsContainer}>
                  <Chips chipData={tagsData} />
                </div>
              )
              }
              {isNotEmpty(description) && <>
                <p className={styles.label} data-testid="description-label">{descriptionLabel}</p>
                <h3 className={styles.desc} data-testid="description">{description}</h3>
              </>
              }
            </>
          )}
      </div>
    </div>
  );
};
