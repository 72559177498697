import { Form, Formik } from "formik";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { RadioInputColumn } from "../common/RadioInputColumn";
import { AddProjectProps } from "./AddProjectProps";
import styles from "./styles.module.scss";
import { useNavigate } from "react-router-dom";

import { AffordabilityFirstRadioOptionForm } from "./AffordabilityFirstRadioOptionForm";
import { AffordabilitySecondRadioOptionForm } from "./AffordabilitySecondRadioOptionForm";
import { useUser } from "../../Context/UserContext";
import axios from "axios";
import { ListingProjectAPI } from "../../../APIAction/ListingProjectAPI";
import { useEffect, useState, useRef } from "react";

import { useMsal } from "@azure/msal-react";

export function AffordabilityBenchmarkForm({
  handleResponse,
  projectListingId,
}: AddProjectProps) {
  const { t } = useTranslation("profile");
  const navigate = useNavigate();
  const { userState } = useUser();
  const apiErrorPage = "/api-error";

  const { accounts } = useMsal();
  const userEmail = accounts[0].username;
  const ref = useRef(null);
  const [affordabilityInfo, setAffordabilityInfo] = useState<any>();
  const [isDataExist, setIsDataExist] = useState(false);
  const [isApiLoading, setIsApiLoading] = useState(false);

  const radioOptions = [
    {
      label: t(
        "common:enumerations.affordability_radio_options.UnitBelowMarket"
      ),
      value: "1",
      description: t(
        "developer.projects.add_listing_form.description1"
      )
    },
    {
      label: t(
        "common:enumerations.affordability_radio_options.MixedMarketHousing"
      ),
      value: "2",
      description: t(
        "developer.projects.add_listing_form.description2"
      )
    },
    {
      label: t(
        "common:enumerations.affordability_radio_options.ProjectNotMeetAffordabilityThreshold"
      ),
      description: projectListingId ? t(
        "developer.projects.add_listing_form.description3"
      ): '',
      value: "3",
      disabled: isDataExist ? true : false
    }
  ];

  useEffect(() => {
    const getAffordabilityInfomation = async (projectListingId: string) => {
      try {
        setIsApiLoading(true);

        const res = await ListingProjectAPI.getProjectAffordabilityInfoById(
          projectListingId
        );
        const data = res.data;
        setIsApiLoading(false);
        setIsDataExist(true);
        setAffordabilityInfo(data);
      } catch (error) {
        setIsDataExist(false);
        if (axios.isAxiosError(error) && error.response?.status !== 404) {
          navigate("/api-error");
        }
      }
    };
    projectListingId !== "" && projectListingId && getAffordabilityInfomation(projectListingId);
  }, [projectListingId, navigate]);


  const initialValues = {
    totalUnitCount: "",
    affordabilityCommitment: "",
    belowAverageMarketRentUnitCount: "",
    equalToAverageMarketRentUnitCount: "",
    aboveAverageMarketRentUnitCount: "",
    affordabilityHousingType: "",
    marketLevelDefinition: "",
    additionalDetails: "",
  };

  const apiData = {
    totalUnitCount: affordabilityInfo?.affordabilityHousingType === 1 && affordabilityInfo?.totalUnitCount ? affordabilityInfo?.totalUnitCount.toString() : "",
    affordabilityCommitment: affordabilityInfo?.affordabilityCommitment ? affordabilityInfo?.affordabilityCommitment.toString() : "",
    belowAverageMarketRentUnitCount: affordabilityInfo?.belowAverageMarketRentUnitCount ? affordabilityInfo?.belowAverageMarketRentUnitCount.toString() : "",
    equalToAverageMarketRentUnitCount: affordabilityInfo?.equalToAverageMarketRentUnitCount ? affordabilityInfo?.equalToAverageMarketRentUnitCount.toString() : "",
    aboveAverageMarketRentUnitCount: affordabilityInfo?.aboveAverageMarketRentUnitCount ? affordabilityInfo?.aboveAverageMarketRentUnitCount.toString() : "",
    affordabilityHousingType: affordabilityInfo?.affordabilityHousingType ? affordabilityInfo?.affordabilityHousingType.toString() : "",
    marketLevelDefinition: affordabilityInfo?.marketLevelDefinition ? affordabilityInfo?.marketLevelDefinition : "",
    additionalDetails: affordabilityInfo?.additionalDetails ? affordabilityInfo?.additionalDetails : "",
  };


  const affordabilitySchema = Yup.object().shape({
    affordabilityCommitment: Yup.string().required(
      t(
        "developer.projects.general_form_error.general_selection_required"
      )
    ),

    belowAverageMarketRentUnitCount: Yup.number().required(
      t("developer.projects.add_listing_form.belowMarketUnitError")
    )
      .min(1, t("developer.projects.general_form_error.pattern_error_num")),
    equalToAverageMarketRentUnitCount: Yup.number()
      .when("aboveAverageMarketRentUnitCount", {
        is: (aboveAverageMarketRentUnitCount: number) => !aboveAverageMarketRentUnitCount,
        then: Yup.number().required(t("developer.projects.add_listing_form.atleastTwoFieldsError"))
      })
      .min(1, t("developer.projects.general_form_error.pattern_error_num")),
    aboveAverageMarketRentUnitCount: Yup.number()
      .when('equalToAverageMarketRentUnitCount', {
        is: (equalToAverageMarketRentUnitCount: number) => !equalToAverageMarketRentUnitCount,
        then: Yup.number().required(t("developer.projects.add_listing_form.atleastTwoFieldsError"))
      })
      .min(1, t("developer.projects.general_form_error.pattern_error_num"))
  }, [["aboveAverageMarketRentUnitCount", "equalToAverageMarketRentUnitCount"]]);

  const affordabilitySchema2 = Yup.object().shape({
    totalUnitCount: Yup.number().required(
      t("developer.projects.financial_details_form_error.required_error")
    )
      .min(1, t("developer.projects.general_form_error.pattern_error_num")),
    affordabilityCommitment: Yup.string().required(
      t(
        "developer.projects.general_form_error.general_selection_required"
      )
    ),
  })

  const submitAffordabilityInformation = async (formValues: Object) => {
    try {
      setIsApiLoading(true);

      const res = await ListingProjectAPI.postProjectAffordability(formValues);

      setIsApiLoading(false);
      const projectListingId: string = res.data.id;
      //@ts-ignore
      handleResponse("Affordability", true, projectListingId);

    } catch (error) {


      if (axios.isAxiosError(error) && error.response?.status !== 404) {
        navigate(apiErrorPage);
      }
    }
  }



  const updateAffordabilityInformation = async (formValues: Object) => {
    if (!projectListingId) {
      return;
    }
    try {
      setIsApiLoading(true);
      await ListingProjectAPI.updateProjectAffordability(projectListingId, formValues);
      setIsApiLoading(false);
      handleResponse("Affordability", true);
    } catch (error) {
      setIsApiLoading(false);
      handleResponse("Affordability", false);

      if (axios.isAxiosError(error) && error.response?.status !== 404) {
        navigate(apiErrorPage);
      }
    }
  };

  const selectprojectAffordabilityType = (belowAvgUnit: number, equalAvgUnit: number, aboveAvgUnit: number) => {
    // Scenario 1: Market average > (Below market average < Above market average)
    if (equalAvgUnit > belowAvgUnit && equalAvgUnit > aboveAvgUnit && belowAvgUnit < aboveAvgUnit) {
      return 4; // Market is largest, above is second largest, below is third largest
    }
    // Scenario 2: Above market average > (Below market average = Market average)
    if (aboveAvgUnit > belowAvgUnit && aboveAvgUnit > equalAvgUnit && belowAvgUnit === equalAvgUnit) {
      return 4; // Above is largest, below = market is second largest
    }
    // Scenario 3: Above market average > (Below market average < Market average)
    if (aboveAvgUnit > belowAvgUnit && aboveAvgUnit > equalAvgUnit && belowAvgUnit < equalAvgUnit) {
      return 4; // Above is largest, market is second largest, below is third largest
    }
    // Scenario 4: (Market average = Above market average) > Below market average
    if (belowAvgUnit !== 0 &&equalAvgUnit === aboveAvgUnit && equalAvgUnit > belowAvgUnit) {
        return 4; // Market = above largest, below is second largest
    }
    // Scenario 5: Market average > (Below market average > Above market average)
    if (equalAvgUnit > belowAvgUnit && equalAvgUnit > aboveAvgUnit) {
      return 3; // Market is largest, below is second largest, above is third largest
    }
    // Scenario 6: Market average > (Below market average = Above market average)
    if (equalAvgUnit > belowAvgUnit && equalAvgUnit === aboveAvgUnit) {
      return 3; // Market is largest, below = above is second largest
    }
    // Scenario 7: Above market average > (Below market average > Market average)
    if (aboveAvgUnit > belowAvgUnit && aboveAvgUnit > equalAvgUnit) {
      return 3; // Above is largest, below is second largest, market is third largest
    }
    // Scenario 8: (Below market average = Market average) > Above market average
    if (aboveAvgUnit !== 0 && belowAvgUnit === equalAvgUnit && belowAvgUnit > aboveAvgUnit) {
        return 3; // Below = market largest, above is second largest
    }
    // Scenario 9: (Below market average = Above market average) > Market average
    if (equalAvgUnit !== 0 && belowAvgUnit === aboveAvgUnit && belowAvgUnit > equalAvgUnit) {
        return 3; // Below = above largest, market is second largest
    }
    // Scenario 10: Below market average = Market average = Above market average
    if (belowAvgUnit === equalAvgUnit && equalAvgUnit === aboveAvgUnit) {
      return 3; // Below = market = above
    }
    // Scenario 11: Below market average > (Market average < Above market average)
    if (belowAvgUnit > equalAvgUnit && belowAvgUnit > aboveAvgUnit && equalAvgUnit < aboveAvgUnit) {
      return 2; // Below is largest, above is second largest, market is third largest
    }
    // Scenario 12: Below market average > (Market average > Above market average)
    if (belowAvgUnit > equalAvgUnit && belowAvgUnit > aboveAvgUnit && equalAvgUnit > aboveAvgUnit) {
      return 2; // Below is largest, market is second largest, above is third largest
    }
    // Scenario 13: Below market average > (Market average = Above market average)
    if (belowAvgUnit > equalAvgUnit && belowAvgUnit > aboveAvgUnit && equalAvgUnit === aboveAvgUnit) {
      return 2; // Below is largest, market = above is second largest
    }
    // Scenario 14: Below market average > Market average
    if (belowAvgUnit > equalAvgUnit && belowAvgUnit > aboveAvgUnit) {
      return 2; // Below is largest, market is second largest
    }
    // Scenario 15: Below market average = Market average
    if (belowAvgUnit === equalAvgUnit) {
      return 2; // Below = market
    }
    // Scenario 16: Below market average > Above market average
    if (belowAvgUnit > equalAvgUnit && belowAvgUnit > aboveAvgUnit) {
      return 2; // Below is largest, above is second largest
    }
    // Scenario 17: Below market average = Above market average
    if (belowAvgUnit === aboveAvgUnit) {
      return 2; // Below = above
    }

  };

  function validateForm(belowAvgUnit: number, equalAvgUnit: number, aboveAvgUnit: number, setErrors: any) {
    let errors: any = {
      belowAverageMarketRentUnitCount: t("developer.projects.add_listing_form.belowAverageMarketRentUnitCountError"),
      equalToAverageMarketRentUnitCount: t("developer.projects.add_listing_form.belowAverageMarketRentUnitCountError"),
      aboveAverageMarketRentUnitCount: t("developer.projects.add_listing_form.belowAverageMarketRentUnitCountError")
    };
    const sumofUnits = belowAvgUnit + equalAvgUnit + aboveAvgUnit;

    const amrValue = belowAvgUnit / sumofUnits;

    if (amrValue < 0.2) {
      setErrors(errors);
      return false;
    }
    return true;
  }

  return (
    <Formik
      innerRef={ref}
      initialValues={isDataExist ? apiData : initialValues}
      validationSchema={() => {
        //@ts-ignore
        if (ref.current && ref.current.values.affordabilityHousingType === "2") {
          return affordabilitySchema;
        } else {
          return affordabilitySchema2;
        }
      }}
      validateOnChange={false}
      validateOnBlur={false}
      enableReinitialize={true}
      onSubmit={(values, { setErrors }) => {

        let updatedValues = null;

        let validationCheck = true;

        if (values.affordabilityHousingType === "1") {

          updatedValues = {
            developerID: userState.userId,
            emailAddress: userEmail,
            type: "projectListing",
            organizationName: userState.userOrganization,
            organizationTypes: userState.userOrganizationTypes,
            totalUnitCount: Number(values.totalUnitCount),
            affordabilityHousingType: Number(values.affordabilityHousingType),
            affordabilityCommitment: Number(values.affordabilityCommitment),
            marketLevelDefinition: values.marketLevelDefinition,
            additionalDetails: values.additionalDetails,
            projectAffordabilityType: 1,
            projectListingID: projectListingId

          }
        } else {

          const belowAvgUnit = Number(values.belowAverageMarketRentUnitCount);
          const equalAvgUnit = Number(values.equalToAverageMarketRentUnitCount);
          const aboveAvgUnit = Number(values.aboveAverageMarketRentUnitCount);

          validationCheck = validateForm(belowAvgUnit, equalAvgUnit, aboveAvgUnit, setErrors);

          updatedValues = {
            developerID: userState.userId,
            emailAddress: userEmail,
            type: "projectListing",
            organizationName: userState.userOrganization,
            organizationTypes: userState.userOrganizationTypes,
            affordabilityHousingType: Number(values.affordabilityHousingType),
            affordabilityCommitment: Number(values.affordabilityCommitment),
            marketLevelDefinition: values.marketLevelDefinition,
            additionalDetails: values.additionalDetails,
            belowAverageMarketRentUnitCount: belowAvgUnit,
            equalToAverageMarketRentUnitCount: equalAvgUnit === 0 ? null : equalAvgUnit,
            aboveAverageMarketRentUnitCount: aboveAvgUnit === 0 ? null : aboveAvgUnit,
            totalUnitCount: belowAvgUnit + equalAvgUnit + aboveAvgUnit,
            projectAffordabilityType: selectprojectAffordabilityType(belowAvgUnit, equalAvgUnit, aboveAvgUnit),
            projectListingID: projectListingId
          }

        }

        if (validationCheck) {
          isDataExist
            ? updateAffordabilityInformation(updatedValues)
            : submitAffordabilityInformation(updatedValues)
        }


      }}
    >
      {({ values }) => (
        <Form className={styles.formElement} style={{ width: "100%" }} noValidate>
          <div
            className={styles.fieldContainer}
          // data-testid="affordability-options"
          >
            <h3 className={styles.formTitle}>
              {t(
                "developer.projects.add_listing_form.type-of-housing-project"
              )}
            </h3>
          </div>

          <h4 className={styles.formQuestion} data-testid="benchmark_question">
            {t(
              "developer.projects.add_listing_form.affordability_benchmark_question"
            )}
          </h4>

          <div
            className={styles.fieldContainer}
            data-testid="affordability-options"
          >
            <RadioInputColumn
              fieldName="affordabilityHousingType"
              options={radioOptions}
              data-testid="affordability-radio"
            />
          </div>

          {values.affordabilityHousingType === "3" ? (
            <p>
              {t(
                "developer.projects.add_listing_form.affordability_benchmark_help_text"
              )}{" "}
              <a
                href="mailto:capital.connect@cmhc-schl.gc.ca"
                className={styles.contactLink}
              >
                capital.connect@cmhc-schl.gc.ca
              </a>
            </p>
          ) : ""
          }
          {values.affordabilityHousingType === "1" ? (
            <>
              <hr className={styles.horizontalLine} />
              <AffordabilityFirstRadioOptionForm />
              <div className={styles.buttonContainer}>
                <button
                  type="submit"
                  className={styles.formButton}
                  data-testid="submit-button"
                  disabled={false}
                >
                  {t("developer.projects.general_info_form.btn_text")}
                </button>
              </div>
            </>
          ) : values.affordabilityHousingType === "2" ? (
            <div>
              <hr className={styles.horizontalLine} />
              <AffordabilitySecondRadioOptionForm />
              <div className={styles.buttonContainer}>
                <button
                  type="submit"
                  className={styles.formButton}
                  data-testid="submit-button"
                  disabled={isApiLoading}
                >
                  {t("developer.projects.general_info_form.btn_text")}
                </button>
              </div>
            </div>
          ) : null}

        </Form>
      )}

    </Formik>
  );
}
