import { useTranslation } from "react-i18next";
import { TextArea } from "../Form/common/TextArea";
import styles from "./styles.module.scss";

type Props = {
  textAreaFieldNames: Array<string>;
  textAreaLabelText: string;
  cancelButtonHandler: () => void;
  cancelButtonText: string;
  connectButtonText: string;
};

export function ConnectionRequestCommonElements(props: Props) {
  const { t, i18n } = useTranslation("connect");

  return (
    <>

      {props.textAreaFieldNames.map((fieldName, idx) => (
        <TextArea
          fieldName={fieldName}
          labelText={fieldName === "message" ? t(props.textAreaLabelText) : t("investorToDev.requestModal.project_within_portfolio_message_label")}
          data-testid="message"
        />

      ))}

      <div className={`${styles.buttonsContainer} ${styles.button_bottom}`}>        
        <button
          type="submit"
          className={styles.connectButton}
          data-testid="connect-button"
        >
          {t(props.connectButtonText)}
        </button>
        <button
          className={styles.cancelButton}
          onClick={props.cancelButtonHandler}
          data-testid="Cancel-button"
        >
          {t(props.cancelButtonText)}
        </button>
      </div>
    </>
  );
}
