import { RequestSent } from "../../../components/common/ConnectionStatusTabs/RequestSent";
import { AcceptedRequest } from "../../../components/common/ConnectionStatusTabs/AcceptedRequest";
import { UnavailableRequest } from "../../../components/common/ConnectionStatusTabs/UnavailableRequest";
import { NewConnectionRequest } from "../../../components/common/ConnectionStatusTabs/NewConnectionRequest";
import { useUser } from "../../../components/Context/UserContext";
import { CONNECTION_STATUS_CODE } from "../../../Constants/Enumerations";
import styles from "./ProjectDetails.module.scss";
import { useEffect, useState } from "react";
import { ConnectionStatusI } from "../../../TypesAndInterfaces/ConnectionInterface";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { ConnectApi } from "../../../APIAction/ConnectAPI";
import { useTranslation } from "react-i18next";

type Props = {
  developerID: string;
  projectListingID: string;
  handleConnect: () => void;
  updateConnectionStatus: boolean;
  isPortfolio?: boolean;
  portfolioStatus?: number;
};

export function ConnectionStatusMessage({
  developerID,
  projectListingID,
  handleConnect,
  updateConnectionStatus,
  isPortfolio,
  portfolioStatus,
}: Props) {
  const { userState } = useUser();
  const isUserDeveloper = userState.userPersona.toLowerCase() === "developer";
  const [connectionStatus, setConnectionStatus] = useState<ConnectionStatusI>();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation("project");

  useEffect(() => {
    async function getConnectionStatus() {
      let postData = null;
      if (isPortfolio) {

        postData = {
          fromEntityID: userState.userId,
          fromEntityType: userState.userPersona,
          toEntityID: developerID,
          portolioID: projectListingID,
        };

      } else {
        postData = {
          fromEntityID: userState.userId,
          fromEntityType: userState.userPersona,
          toEntityID: developerID,
          projectListingID: projectListingID,
        };
      }
      try {
        const res = await ConnectApi.VerifyConnectionStatus(postData);
        const data = res.data;

        setConnectionStatus(data);
      } catch (err) {
        if (axios.isAxiosError(err) && err.response?.status !== 404) {
          navigate("/api-error");
        }
      }
    }
    !isUserDeveloper &&
      userState?.isOnboarded &&
      developerID &&
      getConnectionStatus();
  }, [developerID, projectListingID, userState, navigate, isUserDeveloper, updateConnectionStatus]);



  if (isUserDeveloper || !userState.isOnboarded) {
    return <UnavailableRequest isInvestorPage={false} isPortfolio={isPortfolio}/>;
  } else {
    if (connectionStatus && connectionStatus?.isConnectionEstablished) {
      switch (connectionStatus?.connectionStatusCode) {
        case CONNECTION_STATUS_CODE.Connected:
          return <AcceptedRequest />;
        case CONNECTION_STATUS_CODE.Pending:
          return <RequestSent />;
        case CONNECTION_STATUS_CODE.NewConnection:
          return <NewConnectionRequest />;
        default:
          return <></>;
      }
    } else {
      return (
        <button
          type="submit"
          className={`${styles.formButton} ${styles.button_bottom}`}

          onClick={handleConnect}
          disabled={portfolioStatus === 3 ? true : false}
        >
          {isPortfolio ? t("portfolio:portfolio_details.main_page.project_open.connect") : t("project:project.main_page.project_open.connect")}
        </button>
      );
    }
  }
}
