import { useNavigate } from "react-router-dom";
import { IterableFinancialDetails } from "./IterableFinancialDetails";
import { IterableValuesDisplay } from "./IterableValuesDisplay";
import styles from "./styles.module.scss";
import { ProjectStatus } from "./ProjectStatus";
import { useTranslation } from "react-i18next";
import { ProjectCardType } from "../../../TypesAndInterfaces/ProjectDetailsInterface";
import {
  currencyFormat,
  DOLLAR_RANGE,
  FUNDING_TYPES,
  getEnumKeyByValue,
  getImageOptions,
  getKVArrayFormat,
  IMAGE_TYPES,
  INVESTMENT_PHASES,
  LOCKUP_PERIOD,
  PROVINCES,
  ROI_RANGE,
} from "../../../Constants/Enumerations";

type Props = {
  data: ProjectCardType;
};

export function DeveloperProjectCard({ data }: Props) {
  const { t } = useTranslation(["common", "cards"]);
  const navigate = useNavigate();
  const provinceMappedValue = getEnumKeyByValue(PROVINCES, data.province);
  const projectLocation = {
    city: data.city,
    province: t(`common:enumerations.provinces.${provinceMappedValue}`),
  };

  const projectStagesMappedValues = data.investmentPhases.map((val) =>
    getEnumKeyByValue(INVESTMENT_PHASES, val)
  );
  const projectStagesTranslated = projectStagesMappedValues.map((val) =>
    t(`common:enumerations.investment_phases.${val}`)
  );

  const fundingTypesMappedValues = data.fundingTypes.map((val) =>
    getEnumKeyByValue(FUNDING_TYPES, val)
  );
  
  const fundingTypesTranslated = fundingTypesMappedValues.filter(val => val !== undefined && val !== "").map((val) =>
    t(`common:enumerations.funding_types.${val}`)
  );

  function checkForEmpty(value: number | null) {
    if (value !== null) {
      return true;
    }
    return false;
  }

  const targetRoiMaxMappedValue = getEnumKeyByValue(
    ROI_RANGE,
    data.capitalCostRangeMaximum
  );
  const targetRoiMinMappedValue = getEnumKeyByValue(
    ROI_RANGE,
    data.capitalCostRangeMinimum
  );
  const targeRoiMinTranslated = t(
    `common:enumerations.roi_range.${targetRoiMinMappedValue}`
  );
  const targeRoiMaxTranslated = t(
    `common:enumerations.roi_range.${targetRoiMaxMappedValue}`
  );
  const targeRoiTranslated =
    checkForEmpty(data.capitalCostRangeMinimum) && checkForEmpty(data.capitalCostRangeMaximum)
      ? `${targeRoiMinTranslated}-${targeRoiMaxTranslated}`
      : "";


  const dolllarRangesTrans = Object.keys(DOLLAR_RANGE).map((val) =>
    t(`common:enumerations.dollar_range.${val}`)
  );
  const dollarOptions = getKVArrayFormat(DOLLAR_RANGE, dolllarRangesTrans).map(
    ({ label, value }) => ({ label: label, value: value })
  );

  const financingRangeToDisplay =
    data.financingRangeMinimum && data.financingRangeMaximum
      ? `${currencyFormat(dollarOptions, data.financingRangeMinimum)} 
  - ${currencyFormat(dollarOptions, data.financingRangeMaximum)}`
      : "";

  const financialDetails = [
    {
      label: t("cards:financing_range_label"),
      value: financingRangeToDisplay,
    },
  ];

  const imagesAltTextTranslated = Object.keys(IMAGE_TYPES).map((val) =>
    t(`common:enumerations.image_alt_text.${val}`)
  );
  const projectBannersOptions = getImageOptions(
    IMAGE_TYPES,
    imagesAltTextTranslated
  );
  const selectedImage = projectBannersOptions.find(
    (banner) => banner.value === data.projectBannerCode
  );

  const handleProjectClick = () => {
    // 1-Draft, 2-Active, 3-Cancelled, 4-Deleted
    //projectListingstatus if draft navigate to listing an opportunity else for active and cancelled navigate to project details page
    data.projectListingStatus === 1 &&
      navigate(`/projects/edit/${data.projectListingID}`);
    (data.projectListingStatus === 2 || data.projectListingStatus === 3) &&
      navigate(`/projects/${data.projectListingID}`);
  };

  return (
    <div className={styles.cardContainer}>
      <div className={styles.projectSummary}>
        <img
          className={styles.projectImage}
          src={selectedImage?.img}
          alt={selectedImage?.altText}
          data-testid="proj-image"
        />
        <div className={styles.projectDetails}>
          <button
            type="button"
            className={styles.projectCardLink}
            data-testid="proj-name-link"
            onClick={handleProjectClick}
          >
            {data.projectName}
          </button>
          <p className={styles.projectDetailText} data-testid="org-name">
            {data.organizationName}
          </p>
          <p
            className={styles.projectDetailText}
            data-testid="address"
          >{`${projectLocation.city}, ${projectLocation.province}`}</p>
        </div>
      </div>

      <div className={styles.preferenceContainer}>
        <div className={styles.projectStatus} data-testid="project-status">
          <ProjectStatus status={data.projectListingStatus} />
        </div>
        <IterableValuesDisplay
          dataLabel={t("cards:investment_stage_label")}
          arrayOfValues={projectStagesTranslated}
        />

        <IterableValuesDisplay
          dataLabel={t("cards:funding_type_label")}
          arrayOfValues={fundingTypesTranslated}
        />

        <IterableFinancialDetails financialDetails={financialDetails} />
      </div>
    </div>
  );
}
