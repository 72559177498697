import * as Yup from "yup";
import { Form, Formik } from "formik";
import { useTranslation } from "react-i18next";
import { TextArea } from "../common/TextArea";
import { useEffect, useMemo, useState } from "react";
import { useUser } from "../../Context/UserContext";
import MultiSelectSearch from "../../common/MultiSelectSearch";
import { Tag } from "../../common/MultiSelectSearch/types";
import { InvestorAPI } from "../../../APIAction/InvestorAPI";
import styles from "./styles.module.scss";

import {
  GOVERNANCE_INDICATORS,
  ENVIRONMENTAL_INDICATORS,
  getTagFormat,
  SOCIAL_INDICATORS_ONBOARDING,
} from "../../../Constants/Enumerations";
import { InvestorOrganizationImpact, InvestorProps } from "../../../TypesAndInterfaces/InvestorDetailsInterface";
import { SectionTitle } from "../../common/SectionTitle";
import { useNavigate, useLocation } from "react-router-dom";
import { GlossaryDefinition } from "../../../pages/Developers/Modals/GlossaryDefinitionModal/GlossaryDefinition";
import axios from "axios";


export function InvestorOrganizationImpactForm(
  { handleResponse }: InvestorProps) {
  const { t } = useTranslation("onboarding");
  const navigate = useNavigate();
  const location = useLocation();
  const isEditProfileFromViewPage = location.pathname.includes("profile");
  const { userState } = useUser();

  const apiErrorPage = "/api-error";

  //Predefined tags
  const [
    environmentalIndicatorsPredefinedTags,
    setEnvironmentalIndicatorsPredefinedTags,
  ] = useState<Tag[]>([]);
  const [socialIndicatorsPredefinedTags, setSocialIndicatorsPredefinedTags] =
    useState<Tag[]>([]);
  const [
    governanceIndicatorsPredefinedTags,
    setGovernanceIndicatorsPredefinedTags,
  ] = useState<Tag[]>([]);

  // User tags state
  const [userEnvironmentalTags, setUserEnvironmentalTags] = useState<Tag[]>([]);
  const [userSocialTags, setUserSocialTags] = useState<Tag[]>([]);
  const [userGovernanceTags, setUserGovernanceTags] = useState<Tag[]>([]);
  const [selectedEnvironmentalTags, setSelectedEnvironmentalTags] = useState<
    Tag[]
  >([]);

  //Selected Tags
  const [selectedSocialTags, setSelectedSocialTags] = useState<Tag[]>([]);
  const [selectedGovernanceTags, setSelectedGovernanceTags] = useState<Tag[]>(
    []
  );
  const [organizationImpactInfo, setOrganizationImpactInfo] =
    useState<InvestorOrganizationImpact>();

  const [isDataExist, setIsDataExist] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isApiLoading, setIsApiLoading] = useState(false);
  const [selectedFieldName, setSelectedFieldName] = useState("environmental");


  useEffect(() => {
    const getOrganizationImpactInfomation = async () => {
      try {
        const res = await InvestorAPI.getOrganizationImpactInfoById(userState.userId);
        const data = res.data;
        setOrganizationImpactInfo(data);
        setIsDataExist(true);
      } catch (error) {
        setIsDataExist(false);
        handleResponse("InvestorOrgImpactInfo", false, error);
      }
    };

    userState.userId !== "" && getOrganizationImpactInfomation();
  }, [userState.userId, handleResponse]);

  const initialValues = {
    social: [],
    socialDescription: "",
    environmental: [],
    environmentalDescription: "",
    governance: [],
    governanceDescription: "",
  };

  const environmentalIndicatorsTranslations = useMemo(() => {
    return Object.keys(ENVIRONMENTAL_INDICATORS).map((val) =>
      t(
        `investor.investor_impact_criteria_form.environmental.environmental_indicators.${val}`
      )
    );
  }, [t]);

  const environmentalPredefinedTags = useMemo(() => {
    return getTagFormat(
      ENVIRONMENTAL_INDICATORS,
      environmentalIndicatorsTranslations
    );
  }, [environmentalIndicatorsTranslations]);



  const socialIndicatorsTranslations = useMemo(() => {
    return Object.keys(SOCIAL_INDICATORS_ONBOARDING).map(
      (val) =>
        t(
          `investor.investor_impact_criteria_form.social.social_indicators.${val}`
        )
    );
  }, [t]);
  const socialPredefinedTags = useMemo(() => {
    return getTagFormat(
      SOCIAL_INDICATORS_ONBOARDING,
      socialIndicatorsTranslations
    );
  }, [socialIndicatorsTranslations]);

  const governanceIndicatorsTranslations = useMemo(() => {
    return Object.keys(
      GOVERNANCE_INDICATORS
    ).map((val) =>
      t(
        `investor.investor_impact_criteria_form.governance.governance_indicators.${val}`
      )
    );
  }, [t]);

  const governancePredefinedTags = useMemo(() => {
    return getTagFormat(
      GOVERNANCE_INDICATORS,
      governanceIndicatorsTranslations
    );
  }, [governanceIndicatorsTranslations]);


  const isLabelAlreadyExist = (label: string, tagsArray: Tag[]): boolean => {
    return tagsArray.some((tag) => tag.label === label);
  };

  const max_char = 150;
  const impactCriteriaSchema = Yup.object().shape({
    socialDescription: Yup.string()
      .max(
        max_char,
        t("developer.projects.general_form_error.max_characters", {
          charNum: max_char,
        })
      ),
    environmentalDescription: Yup.string()
      .max(
        max_char,
        t("developer.projects.general_form_error.max_characters", {
          charNum: max_char,
        })
      ),
    governanceDescription: Yup.string()
      .max(
        max_char,
        t("developer.projects.general_form_error.max_characters", {
          charNum: max_char,
        })
      ),
  });

  const updateTagsFromApiData = (
    impactData: any[],
    predefinedTags: Tag[],
    selectedTags: Tag[],
    userTagsState: Tag[]
  ) => {
    impactData.forEach((impact: any) => {
      const matchingPredefinedTag = predefinedTags.find(
        (tag) => tag.label === impact.impactName
      );

      if (matchingPredefinedTag) {
        // If the tag exists in predefined tags and not in selected tags, update its added state
        if (!isLabelAlreadyExist(matchingPredefinedTag.label, selectedTags)) {
          matchingPredefinedTag.added = true;
          // Push the tag into selected tags
          selectedTags.push(matchingPredefinedTag);
        }
      } else {
        // If the tag doesn't exist in predefined tags, create a new tag and add it to selected tags
        const newTag: Tag = {
          inputValue: impact.impactName,
          label: impact.impactName,
          selected: true,
          added: true,
        };
        if (
          !isLabelAlreadyExist(newTag.label, selectedTags) &&
          !isLabelAlreadyExist(newTag.label, userTagsState)
        ) {
          const index = impact.impactOrder;
          userTagsState.push(newTag);
          selectedTags.splice(index, 0, newTag);
        }
      }
    });

    return { predefinedTags, selectedTags, userTagsState };
  };

  const getOrgImpactCriteriaInfomation = async (id: string) => {
    try {
      setIsApiLoading(true);
      const res = await InvestorAPI.getOrganizationImpactInfoById(id);
      const data = res.data;
      setIsApiLoading(false);
      setIsDataExist(true);
      setOrganizationImpactInfo(data);

      const updatedSocialTags = updateTagsFromApiData(
        data.social,
        socialPredefinedTags,
        selectedSocialTags,
        userSocialTags
      );
      setSocialIndicatorsPredefinedTags(updatedSocialTags.predefinedTags);
      setSelectedSocialTags(updatedSocialTags.selectedTags);
      setUserSocialTags(updatedSocialTags.userTagsState);

      const updatedEnvironmentalTags = updateTagsFromApiData(
        data.environmental,
        environmentalPredefinedTags,
        selectedEnvironmentalTags,
        userEnvironmentalTags
      );
      setEnvironmentalIndicatorsPredefinedTags(
        updatedEnvironmentalTags.predefinedTags
      );
      setSelectedEnvironmentalTags(updatedEnvironmentalTags.selectedTags);
      setUserEnvironmentalTags(updatedEnvironmentalTags.userTagsState);

      const updatedGovernanceTags = updateTagsFromApiData(
        data.governance,
        governancePredefinedTags,
        selectedGovernanceTags,
        userGovernanceTags
      );
      setGovernanceIndicatorsPredefinedTags(
        updatedGovernanceTags.predefinedTags
      );
      setSelectedGovernanceTags(updatedGovernanceTags.selectedTags);
      setUserGovernanceTags(updatedGovernanceTags.userTagsState);
    } catch (error) {
      setIsApiLoading(false);
      setIsDataExist(false);

      if (axios.isAxiosError(error) && error.response?.status !== 404) {
        navigate(apiErrorPage);
      }
    }
  };

  useEffect(() => {
    (userState.userId !== "") && getOrgImpactCriteriaInfomation(userState.userId);

    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userState.userId, handleResponse]);

  const handleSelectedSocialTagsChange = (newSelectedTags: Tag[]) => {
    setSelectedSocialTags(newSelectedTags);
  };

  const handleSelectedGovernanceTagsChange = (newSelectedTags: Tag[]) => {
    setSelectedGovernanceTags(newSelectedTags);
  };

  const handleSelectedEnvironmentalTagsChange = (newSelectedTags: Tag[]) => {
    setSelectedEnvironmentalTags(newSelectedTags);
  };

  useEffect(() => {
    setEnvironmentalIndicatorsPredefinedTags(environmentalPredefinedTags);
    setSocialIndicatorsPredefinedTags(socialPredefinedTags);
    setGovernanceIndicatorsPredefinedTags(governancePredefinedTags);
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(environmentalPredefinedTags), JSON.stringify(socialPredefinedTags), JSON.stringify(governancePredefinedTags)]);

  async function submitImpactCriteriaInfo(formValues: Object) {
    try {
      setIsApiLoading(true);
      await InvestorAPI.postOrganizationImpactInfo({
        ...formValues,
        investorID: userState.userId,
      });
      setIsApiLoading(false);
      handleResponse("InvestorOrgImpactInfo", true);
    } catch (error) {
      setIsApiLoading(false);
      handleResponse("InvestorOrgImpactInfo", false);

      if (axios.isAxiosError(error) && error.response?.status !== 404) {
        navigate(apiErrorPage);
      }
    }
  }

  async function updateImpactCriteriaInfo(formValues: Object) {
    if (!userState.userId) {
      return;
    }

    try {
      setIsApiLoading(true);
      await InvestorAPI.updateOrganizationImpactInfo(userState.userId, {
        investorID: userState.userId,
        ...formValues,
      });
      setIsApiLoading(false);
      handleResponse("InvestorOrgImpactInfo", true);
      navigate("/profile/investor/success");
    } catch (error) {
      setIsApiLoading(false);
      handleResponse("InvestorOrgImpactInfo", false);

      if (axios.isAxiosError(error) && error.response?.status !== 404) {
        navigate(apiErrorPage);
      }
    }
  }

  const onSubmit = (values: any) => {
    // Format social tags
    const social = selectedSocialTags.map((tag, index) => ({
      impactName: tag.label,
      impactOrder: index,
    }));
    // Format environmental tags
    const environmental = selectedEnvironmentalTags.map((tag, index) => ({
      impactName: tag.label,
      impactOrder: index,
    }));
    // Format governance tags
    const governance = selectedGovernanceTags.map((tag, index) => ({
      impactName: tag.label,
      impactOrder: index,
    }));

    // Create the payload
    const payload = {
      social: social,
      socialDescription: values.socialDescription,
      environmental: environmental,
      environmentalDescription: values.environmentalDescription,
      governance: governance,
      governanceDescription: values.governanceDescription,
    };

    isDataExist
      ? updateImpactCriteriaInfo(payload)
      : submitImpactCriteriaInfo(payload);
  };


  const apiData = {
    social: organizationImpactInfo?.social,
    socialDescription: organizationImpactInfo?.socialDescription,
    environmental: organizationImpactInfo?.environmental,
    environmentalDescription: organizationImpactInfo?.environmentalDescription,
    governance: organizationImpactInfo?.governance,
    governanceDescription: organizationImpactInfo?.governanceDescription,
  };

  return (
    <Formik
      initialValues={isDataExist ? apiData : initialValues}
      validationSchema={impactCriteriaSchema}
      validateOnChange={false}
      validateOnBlur={false}
      enableReinitialize={true}
      onSubmit={onSubmit}
    >
      <Form className={styles.formElement} noValidate>
        <p className={styles.subtitleImpact} data-testid="sub-title-impact">{t(
          "investor.investor_impact_criteria_form.subtitle"
        )}</p>
        {/* Environmental section */}
        <SectionTitle
          titleText={t(
            "investor.investor_impact_criteria_form.environmental.heading"
          )}
          description={t(
            "investor.investor_impact_criteria_form.environmental.description"
          )}
        />

        {isOpenModal && (
          <GlossaryDefinition
            setCloseGlossaryModal={setIsOpenModal}
            title={t("glossary.title_" + selectedFieldName)}
            glossaryContent={t(
              "glossary.projectInfoGlossaryContent_" + selectedFieldName,
              { returnObjects: true }
            )}
            glossaryLink={t("glossary.viewFullGlossaryLink")}
            closeText={t("glossary.closeButton")}
            selectedId="#1"
          />
        )}


        <MultiSelectSearch
          fieldName="environmental"
          predefinedTagsList={environmentalIndicatorsPredefinedTags}
          labelText={t(
            "investor.investor_impact_criteria_form.environmental.environmental_indicator_label"
          )}
          descPredefinedTags={t(
            "investor.investor_impact_criteria_form.environmental.desc_environmental_indicators"
          )}
          placeholderText={t(
            "investor.investor_impact_criteria_form.environmental.placeholder"
          )}
          selectedTags={selectedEnvironmentalTags}
          onSelectedTagsChange={handleSelectedEnvironmentalTagsChange}
          userTags={userEnvironmentalTags}
          setIsOpenModal={setIsOpenModal}
          setSelectedFieldName={setSelectedFieldName}
          linkText={t("investor.glossary.glossaryDefinitionLink")}
        />
        <TextArea
          fieldName="environmentalDescription"
          charLimit={150}
          labelText={t(
            "investor.investor_impact_criteria_form.environmental.impact_description_label"
          )}
          data-testid="environmental-description"
          placeholder={t(
            "investor.investor_impact_criteria_form.environmental.impact_description_placeholder"
          )}
        />

        <hr className={styles.horizontalLine}></hr>

        {/* Social Section */}
        <SectionTitle
          titleText={t(
            "investor.investor_impact_criteria_form.social.heading"
          )}
          description={t(
            "investor.investor_impact_criteria_form.social.description"
          )}
        />

        <MultiSelectSearch
          fieldName="social"
          predefinedTagsList={socialIndicatorsPredefinedTags}
          labelText={t(
            "investor.investor_impact_criteria_form.social.social_indicator_label"
          )}
          descPredefinedTags={t(
            "investor.investor_impact_criteria_form.social.desc_social_indicators"
          )}
          placeholderText={t(
            "investor.investor_impact_criteria_form.social.placeholder"
          )}
          selectedTags={selectedSocialTags}
          userTags={userSocialTags}
          onSelectedTagsChange={handleSelectedSocialTagsChange}
          setIsOpenModal={setIsOpenModal}
          setSelectedFieldName={setSelectedFieldName}
          linkText={t("investor.glossary.glossaryDefinitionLink")}
        />

        <TextArea
          fieldName="socialDescription"
          charLimit={150}
          labelText={t(
            "investor.investor_impact_criteria_form.social.impact_description_label"
          )}
          data-testid="social-description"
          placeholder={t(
            "investor.investor_impact_criteria_form.social.impact_description_placeholder"
          )}
        />

        <hr className={styles.horizontalLine}></hr>
        {/* Governance Section */}

        <SectionTitle
          titleText={t(
            "investor.investor_impact_criteria_form.governance.heading"
          )}
          description={t(
            "investor.investor_impact_criteria_form.governance.description"
          )}
        />

        <MultiSelectSearch
          fieldName="governance"
          predefinedTagsList={governanceIndicatorsPredefinedTags}
          labelText={t(
            "investor.investor_impact_criteria_form.governance.governance_indicator_label"
          )}
          descPredefinedTags={t(
            "investor.investor_impact_criteria_form.governance.desc_governance_indicators"
          )}
          placeholderText={t(
            "investor.investor_impact_criteria_form.governance.placeholder"
          )}
          selectedTags={selectedGovernanceTags}
          userTags={userGovernanceTags}
          onSelectedTagsChange={handleSelectedGovernanceTagsChange}
          setIsOpenModal={setIsOpenModal}
          setSelectedFieldName={setSelectedFieldName}
          linkText={t("investor.glossary.glossaryDefinitionLink")}
        />

        <TextArea
          fieldName="governanceDescription"
          charLimit={150}
          labelText={t(
            "investor.investor_impact_criteria_form.governance.impact_description_label"
          )}
          data-testid="governance-description"
          placeholder={t(
            "investor.investor_impact_criteria_form.governance.impact_description_placeholder"
          )}
        />
        <div className={styles.buttonContainer}>
          {isEditProfileFromViewPage ? (
            <button
              type="submit"
              className={styles.formButton}
              data-testid="submit-button"
            >
              {t("investor.investor_impact_criteria_form.save_button")}
            </button>
          ) : (
            <button
              type="submit"
              className={styles.formButton}
              data-testid="submit-button"
              disabled={isApiLoading}
            >
              {t("investor.investor_impact_criteria_form.review_button")}
            </button>
          )}
        </div>
      </Form>
    </Formik>
  );
}
