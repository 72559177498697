import { useTranslation } from "react-i18next";
import styles from "./ConnectionErrorPage.module.scss";
import { useNavigate } from "react-router-dom";
interface Props {
  heading: string;
  message: string;
  notes?: string;
  notificationHeading?: string;
  notificationList?: string[];
  icon?: string;
  buttonTextOne?: string;
  buttonTextTwo?: string;
  linkTextOne?: ()=>void;
  linkTextTwo?: string;
}

export function ConnectionNotFound({
  heading,
  message,
  notes,
  notificationHeading,
  notificationList,
  icon,
  linkTextOne,
  linkTextTwo,
  buttonTextOne,
  buttonTextTwo,
}: Props) {
  const { t } = useTranslation("connectionHub");
  const navigate = useNavigate();

  return (
    <div className={styles.erorContainer}>
      <img
        src={icon}
        alt={t("ConnectionHub.error_text.sadFace")}
        data-testid="connected-happy-face"
      />
      <div className={styles.errorInfoContainer}>
        <h2 className={styles.heading} data-testid="error-heading">
          {heading}
        </h2>
        <p className={styles.info} data-testid="error-content">
          {message}
        </p>
        {notes ? (
          <p className={styles.noteInfo} data-testid="error-note-text">
            {notes}
          </p>
        ) : null}
        {notificationHeading ? (
          <p className={styles.notification} data-testid="error-note-heading">
            {notificationHeading}
          </p>
        ) : null}
        {notificationList ? (
          <ul
            className={styles.notification}
            data-testid="error-notificationList"
          >
            {notificationList?.map((item, idx) => (
              <li key={idx} className={styles.notes}>
                {item}
              </li>
            ))}
          </ul>
        ) : null}
      </div>
      <div className={styles.buttonContainer}>
        {buttonTextOne &&
          <button
            type="button"
            className={styles.connectionButtton}
            data-testid={"add-impact-link"}
            onClick={linkTextOne}
          >
            {buttonTextOne}
          </button>
        }
        {buttonTextTwo &&
          <button
            type="button"
            className={styles.connectionButtton}
            data-testid={"add-impact-link"}
            onClick={() => { navigate(linkTextTwo!) }}
          >
            {buttonTextTwo}
          </button>
        }
      </div>
    </div>
  );
}
