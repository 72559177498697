import { Field, useField } from "formik";
import { CommonErrorMessage } from "../common/CommonErrorMessage";
import styles from "./WaitlistForm.module.scss";

type Props = {
  labelText: string;
  termsAcknowledgmentTextStart: string;
  termsAcknowledgmentTextModalToggle: string;
  termsAcknowledgmentTextEnd: string;
  fieldName: string;
  buttonToggleFunc: () => void;
};

export function TermsCheckbox(props: Props) {
  const [field, meta] = useField(props.fieldName);

  return (
    <fieldset className={`${styles.formSection} ${styles.fieldContainer}`}>
      <legend
        className={`${styles.labelStyle} ${
          meta.error && meta.touched ? styles.errorStateLabel : null
        }`}
      >
        {props.labelText}
      </legend>
      <div className={styles.termsLayout}>
        <Field type="checkbox" id={field.name} name={field.name} />
        <label htmlFor={field.name}>
          {props.termsAcknowledgmentTextStart}
          <button
            type="button"
            className={styles.termsModalButton}
            onClick={props.buttonToggleFunc}
            data-testid="terms-modal-button"
          >
            {props.termsAcknowledgmentTextModalToggle}
          </button>
          {props.termsAcknowledgmentTextEnd}
        </label>
      </div>
      <CommonErrorMessage fieldName={field.name} />
    </fieldset>
  );
}
