import { Suspense } from "react";
import "./App.scss";
import { createTheme, CssBaseline, ThemeProvider } from "@mui/material";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./authConfig";
import { AppRoute } from "./routes/AppRoute";
import { UserProvider } from "./components/Context/UserContext";
import { AppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { reactPlugin } from "./AppInsights";

const theme = createTheme({});

function App() {
  const msalInstance = new PublicClientApplication(msalConfig);

  return (
    <AppInsightsContext.Provider value={reactPlugin}>
      <MsalProvider instance={msalInstance}>
        <Suspense fallback={null}>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <UserProvider>
              <AppRoute />
            </UserProvider>
          </ThemeProvider>
        </Suspense>
      </MsalProvider>
    </AppInsightsContext.Provider>
  );
}

export default App;
