import { useMsal } from "@azure/msal-react";
import axios from "axios";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { postOnboardingVerify } from "../../APIAction/AccountAPI";
import { FinancialDetailsIcon, GeneralInfoIcon, dollorIcon } from "../../assets/icons";
import {
  setOptimisticOnboardingStatus,
  setUserApiData,
} from "../../components/Context/UserActions";
import { useUser } from "../../components/Context/UserContext";
import { DevelopmentExperience } from "../../components/Form/DeveloperOnboarding/DevelopmentExperience";
import { GeneralInformationForm } from "../../components/Form/DeveloperOnboarding/GeneralInformationForm";
import { PageContent } from "../../components/Layout/PageContent";
import { PageLayout } from "../../components/Layout/PageLayout";
import { FormAccordion } from "../FormAccordion";
import { TermsConditionAccordion } from "../FormAccordion/TNCAccordion";
import styles from "./DeveloperOnboarding.module.scss";
import { OrganizationInfoForm } from "../../components/Form/DeveloperOnboarding/OrganizationInfoForm";
import { InformationalNotice } from "../../components/common/InformationalNotice";

export function DeveloperOnboarding() {
  const { t, i18n } = useTranslation("onboarding");
  const { userState, dispatch } = useUser();
  const navigation = useNavigate();

  const [isGenInfoSubmit, setIsGenInfoSubmit] = useState<boolean>(false);
  const [isOrgInfoSubmit, setIsOrgInfoSubmit] = useState<boolean>(false);
  const [isDeveloperInfoSubmit, setIsDeveloperInfoSubmit] =
    useState<boolean>(false);
  const [generalInfoEditLink, setgeneralInfoEditLink] =
    useState<boolean>(false);
  const [organizationInfoEditLink, setorganizationInfoEditLink] =
    useState<boolean>(false);
  const [developerInfoEditLink, setdeveloperInfoEditLink] =
    useState<boolean>(false);
  const [isTNCSubmit, setIsTNCSubmit] = useState(false);

  const [developerId, setDeveloperId] = useState("");
  useEffect(() => {
    userState.userId === "" ? setIsTNCSubmit(false) : setIsTNCSubmit(true)
  }, [userState.userId])

  const { accounts } = useMsal();
  const userEmail = accounts[0].username;

  const noteItems = [
    { text: t("developer.main_page.note.item_1") },
    {
      text: t("developer.main_page.note.item_2"),
    },
    {
      text: t("developer.main_page.note.item_3"),
    },
  ];

  useEffect(() => {
    isGenInfoSubmit &&
      isOrgInfoSubmit &&
      isDeveloperInfoSubmit &&
      navigation(`/onboarding/developer/review/${userState.userId === "" ? developerId : userState.userId}`);
  }, [isGenInfoSubmit, isDeveloperInfoSubmit, isOrgInfoSubmit, navigation]);

  useEffect(() => {
    // If user is onboarded in DB (isOnboarded = true), but Azure is not (onboardingStatus !== 3), then update Azure value
    if (userState.isOnboarded && userState.onboardingStatus !== 3) {
      postOnboardingVerify(3).catch();
    }
  }, [userState.isOnboarded, userState.onboardingStatus]);

  const handleFormSubmit = (
    formLable: string,
    successStatus: boolean,
    error?: any,
    developerId?: string
  ) => {
    if (successStatus) {
      switch (formLable) {
        case "GeneralInfo":
          setIsGenInfoSubmit(true);
          if (developerId) {
            setDeveloperId(developerId);

          }
          setUserApiData(dispatch, userEmail, userState.userPersona);
          setgeneralInfoEditLink(true);
          handleAccordianChange(1);
          break;
        case "OrganizationInfo":
          setIsOrgInfoSubmit(true);
          setUserApiData(dispatch, userEmail, userState.userPersona);
          setorganizationInfoEditLink(true);
          handleAccordianChange(2);
          break;
        case "DeveloperInfo":
          setUserApiData(dispatch, userEmail, userState.userPersona);
          setOptimisticOnboardingStatus(dispatch);
          setIsDeveloperInfoSubmit(true);
          setdeveloperInfoEditLink(true);
          handleAccordianChange(3);
          break;
        default:
          break;
      }
    } else {
      if (axios.isAxiosError(error) && error.response?.status !== 404) {
        navigation("/api-error");
      }
    }
  };

  const investorFormList = [
    {
      formElement: (
        <GeneralInformationForm
          handleResponse={handleFormSubmit}
          isTNCSubmit={isTNCSubmit}
        />
      ),
      formHeading: t("developer.main_page.form_accordions.gen_info.heading"),
      formIconSrc: GeneralInfoIcon,
      formIconAlt: t("developer.main_page.form_accordions.gen_info.alticon"),
      formInfoSummary: t(
        "developer.main_page.form_accordions.gen_info.summary"
      ),
      disabled: !isTNCSubmit,
      showEditLink: generalInfoEditLink,
    },
    {
      formElement: (
        <OrganizationInfoForm
          handleResponse={handleFormSubmit}
          developerId={developerId}
        />
      ),
      formHeading: t("developer.main_page.form_accordions.org_info.heading"),
      formIconSrc: FinancialDetailsIcon,
      formIconAlt: t("developer.main_page.form_accordions.org_info.alticon"),
      formInfoSummary: t(
        "developer.main_page.form_accordions.org_info.summary"
      ),
      disabled: !isGenInfoSubmit,
      showEditLink: organizationInfoEditLink,
    },
    {
      formElement: <DevelopmentExperience handleResponse={handleFormSubmit} developerId={developerId} />,
      formHeading: t(
        "developer.main_page.form_accordions.developerInfo.heading"
      ),
      formIconSrc: dollorIcon,
      formIconAlt: t(
        "developer.main_page.form_accordions.developerInfo.alticon"
      ),
      formInfoSummary: t(
        "developer.main_page.form_accordions.developerInfo.summary"
      ),
      disabled: !isOrgInfoSubmit,
      showEditLink: developerInfoEditLink,
    },
  ];

  const [isExpanded, setIsExpanded] = useState(true);
  const [selectedAccordion, setSelectedAccordion] = useState(
    isTNCSubmit ? 0 : investorFormList.length
  );

  const handleAccordianChange = (idValue: number) => {
    if (idValue !== selectedAccordion) {
      setIsExpanded(true);
      setSelectedAccordion(idValue);
    }
    if (idValue === selectedAccordion) {
      isExpanded ? setIsExpanded(false) : setIsExpanded(true);
    }
  };

  const handleTNCSubmit = () => {
    handleAccordianChange(0);
    setIsTNCSubmit(true);
  };

  const handleVisitFaqLink = () => {
    window.open(i18n.language === 'fr'?'https://www.cmhc-schl.gc.ca/professionnels/innovation-et-leadership-dans-le-secteur/expertise-de-lindustrie/logement-des-autochtones': 
    'https://www.cmhc-schl.gc.ca/professionals/industry-innovation-and-leadership/industry-expertise/indigenous-housing',
     '_blank')
  };

  return (
    <PageLayout>
      <hr className={styles.horizontalLine} />
      <PageContent>
        {!isTNCSubmit && (
          <InformationalNotice
            title={t("investor.informational_notice.title")}
            content={t("investor.informational_notice.summary")}
            visitFaqLinkText={t("investor.informational_notice.visitFaqLinkText")}
            handleClick={handleVisitFaqLink} />
        )
        }
        
        <h1 className={styles.pageTitle} data-testid="page-title">
          {t("developer.main_page.page_title")}
        </h1>
        <p className={styles.summaryText} data-testid="summary-text">
          {t("developer.main_page.summary_text")}
        </p>
        <div className={styles.noteContainer}>
          <h2 className={styles.noteHeading} data-testid="note-heading">
            {" "}
            {t("developer.main_page.note_text")}
          </h2>
          <ul className={styles.noteList} data-testid="note-list">
            {noteItems.map((item, idx) => (
              <li key={idx} className={styles.noteListItem}>
                <p>{item.text}</p>
              </li>
            ))}
          </ul>
        </div>
        {!isTNCSubmit && (
          <TermsConditionAccordion
            handleChange={handleTNCSubmit}
            isExpanded={selectedAccordion === investorFormList.length}
          />
        )}
        {investorFormList.map((form, index) => {
          return (
            <FormAccordion
              index={index}
              formElement={form.formElement}
              formHeading={form.formHeading}
              formIconSrc={form.formIconSrc}
              formIconAlt={form.formIconAlt}
              formInfoSummary={form.formInfoSummary}
              disabled={form.disabled}
              key={index}
              handleChange={handleAccordianChange}
              selectedAccordion={selectedAccordion}
              showEditLink={form.showEditLink}
              isExpanded={isExpanded}
            />
          );
        })}
      </PageContent>
    </PageLayout>
  );
}
