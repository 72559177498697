import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { PageLayout } from "../../../components/Layout/PageLayout";
import styles from "./styles.module.scss";
import { Happy_Face } from "../../../assets/images/index";
import { PageContent } from "../../../components/Layout/PageContent";

export const NoProjectsConfirmation = () => {
  const { t } = useTranslation("onboarding");
  const learnMoreLinks = [
    { text: t("developer.noproject.link_Project"), path: "/" },
    { text: t("developer.noproject.link_newProject"), path: "/profile/investor" },
  ];

  return (
    <PageLayout>
      <PageContent>
        <div className={styles.confirmationContainer}>
        <div className={styles.noprojectLinkList} data-testid="noproject-links">
        <h2 data-testid="project-heading" className={styles.projectheading}>
        {t("developer.noproject.projectheading")}
        </h2>
            {learnMoreLinks.map((link, idx) => (
              <Link key={idx} to={link.path} className={styles.noprojectInfoLink}>
                {link.text}
              </Link>
            ))}
          </div>
          
          <img
            data-testid="happy-face"
            src={Happy_Face}
            alt={t("developer.noproject.smileyalt-icon")}
          />

          <div className={styles.noprojectInfoContainer}>
            <h2 data-testid="confirmation-heading" className={styles.heading}>
            {t("developer.noproject.heading")}
            </h2>
            <p data-testid="confirmation-content" className={styles.info}>
            {t("developer.noproject.content")}
            </p>
          </div>
        </div>
      </PageContent>
    </PageLayout>
  );
};
