import { Field, useField } from "formik";
import { CommonErrorMessage } from "../../Form/common/CommonErrorMessage";
import commonFormStyles from "../../Form/common/styles.module.scss";

type Props = {
  labelText: string;
  fieldName: string;
  options: { label: string; value: string | number }[];
  [x: string]: unknown;
};

export function SearchRangeInput({
  labelText,
  fieldName,
  options,
  ...rest
}: Props) {
  const [field, meta] = useField(fieldName);

  return (
    <div className={commonFormStyles.fieldContainer}>
      <label
        className={`${commonFormStyles.labelStyle} ${
          meta.error && meta.touched ? commonFormStyles.errorStateLabel : null
        }`}
        htmlFor={field.name}
      >
        {labelText}
      </label>
      <Field
        as="select"
        className={`${commonFormStyles.selectInput} ${
          meta.error && meta.touched ? commonFormStyles.errorStateInput : null
        }`}
        id={field.name}
        {...field}
        {...rest}
      >
        {options.map((option, idx) => (
          <option
            key={idx}
            value={option.value}
            className={commonFormStyles.options}
          >
            {option.label}
          </option>
        ))}
      </Field>
      <CommonErrorMessage fieldName={field.name} />
    </div>
  );
}
