import { Configuration } from "@azure/msal-browser";

/**
 * Enter here the user flows and custom policies for your B2C application
 * To learn more about user flows, visit: https://docs.microsoft.com/en-us/azure/active-directory-b2c/user-flow-overview
 * To learn more about custom policies, visit: https://docs.microsoft.com/en-us/azure/active-directory-b2c/custom-policy-overview
 */
export const b2cPolicies = {
  names: {
    signIn: process.env.REACT_APP_AZURE_POLICY_NAME_SIGN_IN,
    forgotPassword: process.env.REACT_APP_AZURE_POLICY_NAME_FORGOT_PASSWORD,
    editProfile: process.env.REACT_APP_AZURE_POLICY_NAME_EDIT_PROFILE,
  },
  authorities: {
    signIn: {
      authority: `${process.env.REACT_APP_AZURE_AUTHORITY_URL}/${process.env.REACT_APP_AZURE_POLICY_NAME_SIGN_IN}`,
    },
    forgotPassword: {
      authority: `${process.env.REACT_APP_AZURE_AUTHORITY_URL}/${process.env.REACT_APP_AZURE_POLICY_NAME_FORGOT_PASSWORD}`,
    },
    editProfile: {
      authority: `${process.env.REACT_APP_AZURE_AUTHORITY_URL}/${process.env.REACT_APP_AZURE_POLICY_NAME_EDIT_PROFILE}`,
    },
  },
  authorityDomain: process.env.REACT_APP_AZURE_AUTHORITY_DOMAIN || "",
};

/**
 * Configuration object to be passed to MSAL instance on creation.
 * For a full list of MSAL.js configuration parameters, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md
 */
export const msalConfig: Configuration = {
  auth: {
    clientId: process.env.REACT_APP_AZURE_CLIENT_ID || "", // This is the ONLY mandatory field that you need to supply.
    authority: b2cPolicies.authorities.signIn.authority, // Choose SUSI as your default authority.
    knownAuthorities: [b2cPolicies.authorityDomain], // Mark your B2C tenant's domain as trusted.
    redirectUri: "/", // You must register this URI on Azure Portal/App Registration. Defaults to window.location.origin
    postLogoutRedirectUri: "/", // Indicates the page to navigate after logout.
    navigateToLoginRequestUrl: true, // If "true", will navigate back to the original request location before processing the auth code response.
  },
  cache: {
    cacheLocation: "sessionStorage", // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
};
