import { useTranslation } from "react-i18next";
import styles from "./ViewInvestor.module.scss";
import { InvestorInvestmentInformation } from "../../TypesAndInterfaces/InvestorDetailsInterface";

type ViewInvestmentInfoProps = {
  InvestmentInfo: InvestorInvestmentInformation;
  provinceOptions: {
    label: string;
    value: number;
  }[];
};

export const ViewInvestmentInfo = ({
  provinceOptions,
  InvestmentInfo,
}: ViewInvestmentInfoProps) => {
  const { t } = useTranslation("profile");

  const color = ["#1D4561", "#32736E", "#A1472F", "#7A64D8", "#BF372B"];

  return (
    <div className={styles.content}>
      {InvestmentInfo.investingExperienceFlag && (
        <h2 className={styles.mainHeading}>
          {t("investor.sub_module.page_title")}
        </h2>
      )}
      {InvestmentInfo.investingExperienceFlag &&
        InvestmentInfo.projects.length > 0 &&
        InvestmentInfo.projects.map((project, index) => {
          return (
            <div
              key={index}
              className={styles.projectContainer}
              style={{ borderLeftColor: color[index] }}
            >
              <div className={styles.projectflex}>
                <div>
                  <h2 className={styles.mainHeading}>{project.projectName}</h2>
                  <p className={styles.Projectaddress}>
                    {project.city},{" "}
                    {
                      provinceOptions.find(
                        (val) => val.value === project.province
                      )?.label
                    }
                  </p>
                </div>
                <p className={styles.Organizationsummary}>
                  {project.projectDescription}
                </p>
              </div>
            </div>
          );
        })}
    </div>
  );
};
