import { useEffect } from "react";
import { GeneralResources } from "./GeneralResources";
import { Glossary } from "./Glossary";
import { Faqs } from "./Faqs";
import { useTranslation } from "react-i18next";
import { PageLayout } from "../../components/Layout/PageLayout";
import { PageContent } from "../../components/Layout/PageContent";
import { Title } from "../../components/common/Title/Title";
import { useState } from "react";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { AboutUs } from "../../pages/About/Aboutus";
import { useUser } from "../../components/Context/UserContext";
import { useMsal } from "@azure/msal-react";
import i18n from "../../i18n";

export function Resources() {
  const { t } = useTranslation("resources");
  const { userState } = useUser();

  // const [value, setValue] = useState(window.location.hash === "#glossary" && userState.isOnboarded ? 2 : window.location.hash === "#glossary" && !userState.isOnboarded ? 1 : window.location.hash === "#faqs" && userState.userId !== "" ? 3 : window.location.hash === "#faqs" && userState.userId === "" ? 2 : window.location.hash === "#faqs" && !userState.isOnboarded ? 2 : 0);
  const [value, setValue] = useState(window.location.hash === "#glossary" &&  userState.userId !== "" ? 2 : window.location.hash === "#glossary" &&  userState.userId === "" ? 1 : window.location.hash === "#faqs" &&  userState.userId !== ""  ? 3  : window.location.hash === "#faqs" &&  userState.userId === "" ? 2: 0);
  const { accounts } = useMsal();


  useEffect(() => {
    const gaUserType =
      accounts[0] && accounts[0].idTokenClaims?.extension_Persona
        ? (accounts[0].idTokenClaims.extension_Persona as string)
        : "";
    const gaUserId = accounts[0] ? accounts[0].localAccountId : "";

    if (accounts[0]) {
      const pageTitle = value === 0 ? "/about" : value === 1 ? "/resources" : value === 2 ? "/glossary" : "/faq";

      if (window.dataLayer[window.dataLayer.length - 1].page_title !== pageTitle) {

        window.dataLayer.push({
          event: "open_resource_section",
          page_title: pageTitle,
          page_language: i18n.language,
          page_location: window.location.href,
          page_environment: process.env.REACT_APP_ENV
            ? process.env.REACT_APP_ENV
            : "dev",
          user_status: "loggedin",
          user_type: gaUserType.toLowerCase(),
          user_id: gaUserId,
        });
      }
    } else {
      const pageTitle = value === 0 ? "/resources" : value === 1 ? "/glossary" : "/faq";
      if (window.dataLayer[window.dataLayer.length - 1].page_title !== pageTitle) {

        window.dataLayer.push({
          event: "virtual_page_view",
          page_title: pageTitle,
          page_language: i18n.language,
          page_location: window.location.href,
          page_environment: process.env.REACT_APP_ENV
            ? process.env.REACT_APP_ENV
            : "dev",
          user_status: "guest",
          user_type: gaUserType.toLowerCase(),
          user_id: gaUserId,
        });
      }

    }

  }, [value]);


  interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
  }
  function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    const gaUserType =
      accounts[0] && accounts[0].idTokenClaims?.extension_Persona
        ? (accounts[0].idTokenClaims.extension_Persona as string)
        : "";
    const gaUserId = accounts[0] ? accounts[0].localAccountId : "";

    // window.dataLayer.push({
    //   event: "virtual_page_view",
    //   page_title: pageTitle,
    //   page_language: i18n.language,
    //   page_location: window.location.href, 
    //   page_environment: process.env.REACT_APP_ENV
    //     ? process.env.REACT_APP_ENV
    //     : "dev",
    //   user_status: accounts[0] ? "loggedin" : "guest",
    //   user_type: gaUserType.toLowerCase(),
    //   user_id: gaUserId,
    // });

    localStorage.setItem("glossarySelectedLinkId", "#1");
    setValue(newValue);
  };

  return (
    <PageLayout>
      <Title
        titleText={t("page_title")}
        subtitle={t("subtitle")}
      />
      <PageContent>
        <Box sx={{ width: '100%' }}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs sx={{ textTransform: 'none' }} value={value} onChange={handleChange} aria-label="basic tabs example" variant="fullWidth">
             {userState.userId !== "" && <Tab sx={{ textTransform: 'none' }} label={t("resources_tabs.about_us_tab")}  {...a11yProps(0)} />}
              <Tab sx={{ textTransform: 'none' }} label={t("resources_tabs.general_resources_tab")} {...a11yProps(1)} />
              <Tab sx={{ textTransform: 'none' }} label={t("resources_tabs.glossary_tab")} {...a11yProps(2)} />
              <Tab sx={{ textTransform: 'none' }} label={t("resources_tabs.Faq_tab")} {...a11yProps(3)} />

            </Tabs>
          </Box>
          {userState.userId !== "" && 
            <CustomTabPanel value={value} index={0}>
              <AboutUs />
            </CustomTabPanel>
          }
          <CustomTabPanel value={value} index={userState.userId !== "" ? 1 : 0}>
            <GeneralResources />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={userState.userId !== "" ? 2 : 1}>
            <Glossary />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={userState.userId !== "" ? 3 : 2}>
            <Faqs />
          </CustomTabPanel>
        </Box>
      </PageContent>
    </PageLayout>
  );
}
