
import React, { useEffect, useState } from "react";
import { Modal } from "@mui/material";
import styles from "./styles.module.scss";
import { Box, Typography, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import { PhotoShape } from "../../../assets/icons";
import { useTranslation } from "react-i18next";

interface ImageSliderProps {
  images: string[];
  startIndex?: number;
  closeBtn: () => void;
  isOpenModal: boolean;
  isPortfolio: boolean;
}

const ImageSlider: React.FC<ImageSliderProps> = ({ images, startIndex = 0, closeBtn, isOpenModal, isPortfolio }) => {
  const { t } = useTranslation(["profile"]);

  const [currentImageIndex, setCurrentImageIndex] = useState(startIndex);

  const sasTokenDocumentStorage = process.env.REACT_APP_DOCUMENT_SAS_TOKEN;

  const modalStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
  };


  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "ArrowLeft") {
        prevSlide()
      } else if (event.key === "ArrowRight") {
        nextSlide()
      }
    };
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    }
  }, [currentImageIndex])

  const nextSlide = () => {
    setCurrentImageIndex((prevIndex: number) =>
      prevIndex === images.length - 1 ? 0 : prevIndex + 1
    );

  };

  const prevSlide = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );

  };

  const selectImage = (index: number, event: React.MouseEvent<HTMLDivElement>) => {
    setCurrentImageIndex(index);
    event.stopPropagation()
  };

  let currentWidth = window.innerWidth

  return (
    <Modal
      open={isOpenModal} onClose={() => closeBtn}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
      style={modalStyle}
    >
      <div className={styles.modalContentStyle} >
        <Box display="flex" justifyContent="space-between" alignItems="center" marginBottom={1}>
          <Typography variant="h6" id="modal-title">
            <img
              src={PhotoShape}
              alt={"photo icon"}
            />
            &nbsp;
            {isPortfolio ?
             t("profile:developer.projects.file_upload.portfolio_images") : 
             t("profile:developer.projects.file_upload.project_images")
}
          </Typography>
          <IconButton onClick={closeBtn}>
            <CloseIcon />
          </IconButton>
        </Box>
        <div className={styles.sliderContainer}>
          <div className={styles.slider}>
            {images.map((image: any, index) => (
              <div
                key={index}
                className={
                  index === currentImageIndex ? styles.slideActive : styles.slide
                }
              >
                {index === currentImageIndex && (
                  <img src={image.blobUri + sasTokenDocumentStorage} alt={`Slide ${index}`}
                    className={styles.sliderImg}
                  />
                )}
              </div>
            ))}
          </div>
          {images.length > 1 && (
            <div style={{ display: "flex", flexDirection: "row" }}>
              {(images.length > 3 && currentWidth <= 960) && (
                <IconButton onClick={prevSlide}><ArrowLeftIcon style={{ fontSize: "28px", color: "#004F71" }} /></IconButton>
              )}
              <div className={styles.thumbnails}>
                {images.map((image: any, index) => (
                  <div
                    key={index}
                    className={
                      index === currentImageIndex ? styles.thumbnailActive : styles.thumbnail
                    }
                    onClick={(event) => selectImage(index, event)}
                  >
                    <img src={image.blobUri + sasTokenDocumentStorage} alt={`Thumbnail ${index}`}
                      className={styles.thumbnailImg}
                    />
                  </div>
                ))}
              </div>
              {(images.length > 3 && currentWidth <= 960) && (
                <IconButton onClick={nextSlide}><ArrowRightIcon style={{ fontSize: "28px", color: "#004F71" }} /></IconButton>
              )}
            </div>
          )}

        </div>
        <div>
          <button
            type="submit"
            className={styles.formButton}
            data-testid="submit-button"
            onClick={closeBtn}
          >
            {t("profile:developer.projects.file_upload.closeButton")}
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default ImageSlider;




