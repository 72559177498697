import styles from "./styles.module.scss";

type Props = {
    fieldName: string;
    linkText?: string;
    setIsOpenModal?: Function;
};

export function SubtitleLink({
    fieldName,
    linkText,
    setIsOpenModal,
    ...rest
}: Props) {
    return (
        <div className={styles.fieldContainer}>
            <div className={styles.lableLink}>
                <button
                    type="button"
                    className={styles.learnMoreLink}
                    data-testid="subtitle-link"
                    onClick={(e) => {
                        e.preventDefault();
                        if (setIsOpenModal) {
                            setIsOpenModal(true);
                        }
                    }}
                >
                    {linkText}
                </button>
            </div>
        </div>
    );
}
