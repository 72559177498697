import { useTranslation } from "react-i18next";
import { useState, useEffect } from "react";
import styles from "./styles.module.scss";
import { ProjImpactAreaInfoI } from "../../../components/Form/AddProject/AddProjectProps";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { ListingProjectAPI } from "../../../APIAction/ListingProjectAPI";
import { ReviewProjectProps } from "../../../TypesAndInterfaces/ProjectDetailsInterface";
import { ImpactInfoReviewSection } from "../../../components/common/ImpactSection/ImpactInfoReviewSection/ImpactReviewSection";
import { EditLinkHeader } from "../../../components/common/ImpactSection/EditLinkHeader/EditLinkHeader";
import { ImpactData } from "../../../components/common/Chips/types";
import { isEmpty } from "../../../Constants/basicFunctions";
import {
  SOCIAL_INDICATORS,
  GOVERNANCE_INDICATORS,
  ENVIRONMENTAL_INDICATORS,
  getTagFormat,
} from "../../../Constants/Enumerations";

export const ReviewProjectImpactCriteriaInfo = ({
  projectListingID,
  setEsgSelection
}: any) => {
  const { t } = useTranslation("profile");
  const navigate = useNavigate();
  const [ImpactCriteriaInfo, setImpactCriteriaInfo] =
    useState<ProjImpactAreaInfoI>({} as ProjImpactAreaInfoI);
  const [isDataExist, setIsDataExist] = useState(false);

  const environmentalIndicatorsTranslations = Object.keys(
    ENVIRONMENTAL_INDICATORS
  ).map((val) =>
    t(
      `developer.projects.impact_criteria_form.environmental.environmental_indicators.${val}`
    )
  );
  const environmentalPredefinedTags = getTagFormat(
    ENVIRONMENTAL_INDICATORS,
    environmentalIndicatorsTranslations
  );

  const socialIndicatorsTranslations = Object.keys(SOCIAL_INDICATORS).map(
    (val) =>
      t(
        `developer.projects.impact_criteria_form.social.social_indicators.${val}`
      )
  );
  const socialPredefinedTags = getTagFormat(
    SOCIAL_INDICATORS,
    socialIndicatorsTranslations
  );

  const governanceIndicatorsTranslations = Object.keys(
    GOVERNANCE_INDICATORS
  ).map((val) =>
    t(
      `developer.projects.impact_criteria_form.governance.governance_indicators.${val}`
    )
  );

  const governancePredefinedTags = getTagFormat(
    GOVERNANCE_INDICATORS,
    governanceIndicatorsTranslations
  );
  const predefinedTags = environmentalIndicatorsTranslations.concat(socialIndicatorsTranslations, governanceIndicatorsTranslations);

  const {
    environmental,
    environmentalDescription,
    social,
    socialDescription,
    governance,
    governanceDescription,
  } = ImpactCriteriaInfo;

  useEffect(() => {
    const getImpactCriteriaInfomation = async () => {
      try {
        const res = await ListingProjectAPI.getImpactCriteriaById(
          projectListingID
        );
        const data = res.data;
        setIsDataExist(true);
        setImpactCriteriaInfo(data);

        const selectedTags = data.environmental.concat(data.social, data.governance);

        let count = 0;

        if (selectedTags.length !== 0) {


          for (let i = 0; i < selectedTags.length; i++) {
            for (let j = 0; j < predefinedTags.length; j++) {
              if (predefinedTags[j] === selectedTags[i].impactName) {
                count++;
                break;
              }
            }
          }


          if (count === 0) {
            setEsgSelection("user defined");
          } else if (count === selectedTags.length) {
            setEsgSelection("predefined");
          } else if (count !== selectedTags.length) {
            setEsgSelection("both");
          }
        }


      } catch (error) {
        setIsDataExist(false);
        if (axios.isAxiosError(error) && error.response?.status !== 404) {
          navigate("/api-error");
        }
      }
    };
    projectListingID !== "" && getImpactCriteriaInfomation();
  }, [projectListingID, navigate]);

  const handleEditClick = () => {
    navigate(`/projects/edit/${projectListingID}`);
  };

  const getImpactNames = (impacts: ImpactData[]) => {
    return isEmpty(impacts)
      ? ""
      : impacts.map((impact) => impact.impactName).join(", ");
  };

  return (
    <div className={styles.informationContainer}>
      <EditLinkHeader
        sectionHeading={t("developer.projects.impact_criteria_form.heading")}
        editLinkText={t("developer.projects.review_project.edit_link")}
        handleEditLink={handleEditClick}
      />
      {isDataExist && ImpactCriteriaInfo && (
        <div className={styles.content} data-testid="impact-info-sections">
          <ImpactInfoReviewSection
            selectLabel={t(
              "developer.projects.impact_criteria_form.environmental.environmental_indicator_label_review"
            )}
            selectedValues={getImpactNames(environmental)}
            descriptionLabel={t(
              "developer.projects.impact_criteria_form.environmental.impact_description_label_review"
            )}
            descriptionValue={environmentalDescription}
          />
          <hr
            className={styles.impactInfohorizontalLine}
            data-testid="horizaontal-line"
          ></hr>
          <ImpactInfoReviewSection
            selectLabel={t(
              "developer.projects.impact_criteria_form.social.social_indicator_label_review"
            )}
            selectedValues={getImpactNames(social)}
            descriptionLabel={t(
              "developer.projects.impact_criteria_form.social.impact_description_label_review"
            )}
            descriptionValue={socialDescription}
          />
          <hr className={styles.impactInfohorizontalLine}></hr>
          <ImpactInfoReviewSection
            selectLabel={t(
              "developer.projects.impact_criteria_form.governance.governance_indicator_label_review"
            )}
            selectedValues={getImpactNames(governance)}
            descriptionLabel={t(
              "developer.projects.impact_criteria_form.governance.impact_description_label_review"
            )}
            descriptionValue={governanceDescription}
          />
        </div>
      )}
    </div>
  );
};
