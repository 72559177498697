import styles from "./styles.module.scss";

type Props = {
  titleText: string;
  subtitle?: string;
};

export const Title = (props: Props) => {
  return (
    <div className={styles.pageTitleContainer} data-testid="title-component">
      <div className={styles.textContainer}>
        <h1 className={styles.titleText}>{props.titleText}</h1>
        {props.subtitle ? (
          <h4 className={styles.subtitle}>{props.subtitle}</h4>
        ) : null}
      </div>
    </div>
  );
};
