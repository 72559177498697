
import Accordion from "@mui/material/Accordion";
import { PageContent } from "../../../components/Layout/PageContent";
import { PageLayout } from "../../../components/Layout/PageLayout";
import { PortfolioCreateListCard } from "../../../components/common/Cards/PortfolioCreateListCard";
import { ProjectCreateListCard } from "../../../components/common/Cards/ProjectCreateListCard copy";
import styles from "./styles.module.scss";
import { useTranslation } from "react-i18next";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import { ExpandMore } from "@mui/icons-material";

export function ListingOpportunity() {
    const { t } = useTranslation("profile");
    function generateAccordionData(
        labelTranslationString: string,
        totalLabels: number,
    ) {
        const accordionData: any = [];

        for (let i = 1; i <= totalLabels; i++) {
            accordionData.push({
                heading: t(`${labelTranslationString}_${i}.heading`),
                summary: t(`${labelTranslationString}_${i}.body`),
                linkpath: t(`${labelTranslationString}_${i}.view_all_resources_href`),
                content: t(`${labelTranslationString}_${i}.view_all_resources`),
                listItems: t(`${labelTranslationString}_${i}.opportunityList`)
            });
        }
        return accordionData;
    }
    const accordionDisclaimer: any = generateAccordionData(
        "developer.disclaimer.section",
        1
    );

    const listItems:[] = t("developer.disclaimer.section_1.opportunityList", { returnObjects: true });

    return (
        <PageLayout>
            <PageContent>
                <h1 className={styles.pageTitle}>
                    {t("developer.projects.listing_opportunity.listing_opportunity_heading")}
                </h1>
                <p className={styles.summaryText}>
                    {t("developer.projects.listing_opportunity.listing_opportunity_summary")}
                </p>
                <div className={styles.createOpportunityCards}>
                    <ProjectCreateListCard />
                    <PortfolioCreateListCard />
                </div>
              <Accordion
                style={{
                  minWidth: "600px",
                  minHeight: "15px",
                  border: "1px solid #CCCCCC",
                  backgroundColor: "#F8F8F8",
                }}
                className={styles.border}
              >
                <AccordionSummary
                  expandIcon={<ExpandMore className={styles.icon} />}
                >
                  <p className={styles.accordionSummary}>  {t("developer.disclaimer.section_1.heading")}</p>
                </AccordionSummary>
                <AccordionDetails >
                  <p className={styles.accordionDetails}>
                    {t("developer.disclaimer.section_1.body")}
                    {listItems && listItems?.map((item: any) => (
                      <div className={styles.accordianList}>
                        <p>{t(item.title)}</p>
                        {item.list.map((li: any) => (
                          <ul>
                            <li>{t(li)}</li>
                          </ul>
                        ))}
                      </div>
                    ))}
                  </p>
                  <p className={styles.noteText}>
                  <b>{t("developer.disclaimer.section_1.note")}</b>
                  {t("developer.disclaimer.section_1.noteText")}
                  </p>
                </AccordionDetails>
              </Accordion>

            </PageContent>
        </PageLayout>
    );
}
