import { Field, useField } from "formik";
import styles from "./styles.module.scss";

type Props = {
  fieldName: string;
  options: { label: string; value: string | number, description?: string }[];
  [x: string]: unknown;
};

export function CheckBoxInputColumn({ fieldName, options, ...rest }: Props) {
  const [field, meta] = useField(fieldName);

  return (
    <div className={styles.fieldcheckboxContainerColumn}>



      {options.map((opt, idx) => (
        <>
          <label
            key={idx}
            className={styles.checkboxLabelColumn}
          >

            <Field
              type="checkbox"
              className={`${meta.error && meta.touched ? styles.errorStateInput : null
                }`}
              id={field.name}
              {...field}
              {...rest}
              value={String(opt.value)}
            />
            {opt.label}
          </label>
          {opt?.description && <label className={styles.descriptionTextStyle}>{opt.description}</label>}

        </>))}
    </div>
  );
}
