import { useTranslation } from "react-i18next";
import { GeneralInformationForm } from "../../components/Form/InvestorOnboarding/GeneralInformationForm";
import { InvestmentPreferencesForm } from "../../components/Form/InvestorOnboarding/InvestmentPreferencesForm";
import { PageContent } from "../../components/Layout/PageContent";
import { PageLayout } from "../../components/Layout/PageLayout";
import styles from "./InvestorOnboarding.module.scss";
import {
  GeneralInfoIcon,
  dollorIcon,
  InvestorPreferenceIcon,
  FocusAreaIcon,
} from "../../assets/icons";
import { InvestorInformationForm } from "../../components/Form/InvestorOnboarding/InvestorInformationForm";
import { FormAccordion } from "../FormAccordion";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { TermsConditionAccordion } from "../FormAccordion/TNCAccordion";
import { useUser } from "../../components/Context/UserContext";
import axios from "axios";
import {
  setOptimisticOnboardingStatus,
  setUserApiData,
} from "../../components/Context/UserActions";
import { useMsal } from "@azure/msal-react";
import { postOnboardingVerify } from "../../APIAction/AccountAPI";
import { InvestorOrganizationImpactForm } from "../../components/Form/InvestorOnboarding/InvestorOrganizationImpactForm";
import { InformationalNotice } from "../../components/common/InformationalNotice";

export function InvestorOnboarding() {
  const { t, i18n } = useTranslation("onboarding");
  const { userState, dispatch } = useUser();
  const navigate = useNavigate();
  const [isGenInfoSubmit, setIsGenInfoSubmit] = useState(false);
  const [isInvestmentInfoSubmit, setIsInvestmentInfoSubmit] = useState(false);
  const [isPreferenceInfoSubmit, setIsPreferenceInfoSubmit] = useState(false);
  const [isOrganizationImpactSubmit, setIsOrganizationImpactSubmit] = useState(false);
  const [isTNCSubmit, setIsTNCSubmit] = useState(
    userState.userId === "" ? false : true
  );
  const noteItems = [
    { text: t("investor.main_page.note.item_1") },
    { text: t("investor.main_page.note.item_2") },
    { text: t("investor.main_page.note.item_3") },
  ];
  const [generalInfoEditLink, setgeneralInfoEditLink] = useState(false);
  const [investorInfoEditLink, setInvestorInfoEditLink] = useState(false);
  const [investmentPrefEditLink, setInvestmentPrefEditLink] = useState(false);
  const [investorOrganizationEditLink, setInvestorOrganizationEditLink] = useState(false);

  const { accounts } = useMsal();
  const userEmail = accounts[0].username;

  useEffect(() => {
    isOrganizationImpactSubmit &&
      investorOrganizationEditLink &&
      navigate("/onboarding/investor/review");
  }, [isOrganizationImpactSubmit, investorOrganizationEditLink, navigate]);

  useEffect(() => {
    // If user is onboarded in DB (isOnboarded = true), but Azure is not (onboardingStatus !== 3), then update Azure value
    if (userState.isOnboarded && userState.onboardingStatus !== 3) {
      postOnboardingVerify(3).catch();
    }
  }, [userState.isOnboarded, userState.onboardingStatus]);

  const handleResponse = (
    infoSectionName: string,
    isSuccess: boolean,
    error?: any
  ) => {
    if (isSuccess) {
      switch (infoSectionName) {
        case "GeneralInfo":
          setIsGenInfoSubmit(true);
          setUserApiData(dispatch, userEmail, userState.userPersona);
          setgeneralInfoEditLink(true);
          handleAccordianChange(1);
          break;
        case "InvestmentInfo":
          setUserApiData(dispatch, userEmail, userState.userPersona);
          setIsInvestmentInfoSubmit(true);
          setInvestorInfoEditLink(true);
          handleAccordianChange(2);
          break;
        case "PreferenceInfo":
          setUserApiData(dispatch, userEmail, userState.userPersona);
          setOptimisticOnboardingStatus(dispatch);
          setInvestmentPrefEditLink(true);
          setIsPreferenceInfoSubmit(true);
          handleAccordianChange(3);
          break;
        case "InvestorOrgImpactInfo":
          setUserApiData(dispatch, userEmail, userState.userPersona);
          setOptimisticOnboardingStatus(dispatch);
          setInvestorOrganizationEditLink(true);
          setIsOrganizationImpactSubmit(true);
          break;
        default:
          break;
      }
    } else {
      if (axios.isAxiosError(error) && error.response?.status !== 404) {
        navigate("/api-error");
      }
    }
  };

  const investorFormList = [
    {
      formElement: <GeneralInformationForm handleResponse={handleResponse} />,
      formHeading: t("investor.main_page.form_accordions.gen_info.heading"),
      formIconSrc: GeneralInfoIcon,
      formIconAlt: t("investor.main_page.form_accordions.gen_info.alticon"),
      formInfoSummary: t("investor.main_page.form_accordions.gen_info.summary"),
      disabled: !isTNCSubmit,
      showEditLink: generalInfoEditLink,
    },
    {
      formElement: <InvestorInformationForm handleResponse={handleResponse} />,
      formHeading: t(
        "investor.main_page.form_accordions.investor_info.heading"
      ),
      formIconSrc: dollorIcon,
      formIconAlt: t(
        "investor.main_page.form_accordions.investor_info.alticon"
      ),
      formInfoSummary: t(
        "investor.main_page.form_accordions.investor_info.summary"
      ),
      disabled: !isGenInfoSubmit,
      showEditLink: investorInfoEditLink,
    },
    {
      formElement: (
        <InvestmentPreferencesForm handleResponse={handleResponse} />
      ),
      formHeading: t(
        "investor.main_page.form_accordions.investment_preferences.heading"
      ),
      formIconSrc: InvestorPreferenceIcon,
      formIconAlt: t(
        "investor.main_page.form_accordions.investment_preferences.alticon"
      ),
      formInfoSummary: t(
        "investor.main_page.form_accordions.investment_preferences.summary"
      ),
      disabled: !isInvestmentInfoSubmit,
      showEditLink: investmentPrefEditLink,
    },
    {
      formElement: (
        <InvestorOrganizationImpactForm handleResponse={handleResponse} />
      ),
      formHeading: t(
        "investor.main_page.form_accordions.investor_impact_criteria.heading"
      ),
      formIconSrc: FocusAreaIcon,
      formIconAlt: t(
        "investor.main_page.form_accordions.investor_impact_criteria.alticon"
      ),
      formInfoSummary: t(
        "investor.main_page.form_accordions.investor_impact_criteria.summary"
      ),
      disabled: !isPreferenceInfoSubmit,
      showEditLink: investorOrganizationEditLink,
    },
  ];

  const [isExpanded, setIsExpanded] = useState(true);
  const [selectedAccordion, setSelectedAccordion] = useState(
    isTNCSubmit ? 0 : investorFormList.length
  );

  const handleAccordianChange = (idValue: number) => {
    if (idValue !== selectedAccordion) {
      setIsExpanded(true);
      setSelectedAccordion(idValue);
    }
    if (idValue === selectedAccordion) {
      isExpanded ? setIsExpanded(false) : setIsExpanded(true);
    }
  };

  const handleTNCSubmit = () => {
    handleAccordianChange(0);
    setIsTNCSubmit(true);
  };

  const handleVisitFaqLink = () => {
    window.open(i18n.language === 'fr' ? 'https://www.cmhc-schl.gc.ca/professionnels/innovation-et-leadership-dans-le-secteur/expertise-de-lindustrie/logement-des-autochtones' :
      'https://www.cmhc-schl.gc.ca/professionals/industry-innovation-and-leadership/industry-expertise/indigenous-housing',
      '_blank')
  };

  return (
    <PageLayout>
      <hr className={styles.horizontalLine} />
      <PageContent>
        {!isTNCSubmit &&
          <InformationalNotice
            title={t("investor.informational_notice.title")}
            content={t("investor.informational_notice.summary")}
            visitFaqLinkText={t("investor.informational_notice.visitFaqLinkText")}
            handleClick={handleVisitFaqLink} />
        }
        <h1 className={styles.pageTitle}>
          {t("investor.main_page.page_title")}
        </h1>

        <p className={styles.summaryText}>
          {t("investor.main_page.summary_text")}
        </p>

        <div className={styles.noteContainer}>
          <h2 className={styles.noteHeading}>
            {t("investor.main_page.note.heading")}
          </h2>
          <ul className={styles.noteList}>
            {noteItems.map((item, idx) => (
              <li key={idx} className={styles.noteListItem}>
                <p>{item.text}</p>
              </li>
            ))}
          </ul>
        </div>
        {!isTNCSubmit && (
          <TermsConditionAccordion
            handleChange={handleTNCSubmit}
            isExpanded={selectedAccordion === investorFormList.length}
          />
        )}
        {investorFormList.map((form, index) => {
          return (
            <FormAccordion
              index={index}
              formElement={form.formElement}
              formHeading={form.formHeading}
              formIconSrc={form.formIconSrc}
              formIconAlt={form.formIconAlt}
              formInfoSummary={form.formInfoSummary}
              disabled={form.disabled}
              key={index}
              handleChange={handleAccordianChange}
              selectedAccordion={selectedAccordion}
              showEditLink={form.showEditLink}
              isExpanded={isExpanded}
            />
          );
        })}
      </PageContent>
    </PageLayout>
  );
}
