import { useTranslation } from "react-i18next";
import styles from "./ImpactCriteria.module.scss";
import { ImpactInfoDetailsSection } from "../../../components/common/ImpactSection/ImpactInfoDetailsSection/ImpactInfoDetailsSection";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { isEmpty } from "../../../Constants/basicFunctions";

type ImpactCriteriaInfoProps = {
  impactCriteria: any;
  otherUserProjectflag: boolean;
  portfolioListingID: string;
  portfolioStatus: number;
};

export function ImpactCriteriaDeatils({
  impactCriteria,
  otherUserProjectflag,
  portfolioListingID,
  portfolioStatus
}: ImpactCriteriaInfoProps) {
  const { t } = useTranslation("project");
  const navigate = useNavigate();
  const [noImpactInfo, setNoImpactInfo] = useState(false);
  const {
    environmentalImpact,
    socialImpact,
    governanceImpact,
    environmentalImpactDescription,
    socialImpactDescription,
    governanceImpactDescription,
  } = impactCriteria;

  const editImpactCriteria = `/portfolio/edit/${portfolioListingID}`;

  useEffect(() => {
    if (
      isEmpty(environmentalImpact) &&
      isEmpty(socialImpact) &&
      isEmpty(governanceImpact) &&
      isEmpty(environmentalImpactDescription) &&
      isEmpty(socialImpactDescription) &&
      isEmpty(governanceImpactDescription)
    ) {
      setNoImpactInfo(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [impactCriteria]);

  return (
    <>
      <div className={styles.content} data-testid="impact-container">
        <div
          className="impactInfoDetailsSection"
          data-testid="impact-infoDetails-section"
        >

          {!noImpactInfo &&
            <div className="impactInfoDetailsSection" data-testid="impact-infoDetails-section">
              {/* Environmental */}
              <ImpactInfoDetailsSection
                titleText={t("project.impact_info.environmental")}
                descriptionLabel={t("project.impact_info.environmenta_des_label")}
                description={environmentalImpactDescription}
                noImpactLabel={t("project.impact_info.noImpactLabelPortfolio")}
                addImpactLinkText={t("project.impact_info.addImpactLinkText")}
                addImpactLink={editImpactCriteria}
                otherUserLoggedInflag={otherUserProjectflag}
                tagsData={environmentalImpact}
                status={portfolioStatus}
              />

              {/* /* Social */}
              <ImpactInfoDetailsSection
                titleText={t("project.impact_info.social")}
                descriptionLabel={t("project.impact_info.social_des_label")}
                description={socialImpactDescription}
                noImpactLabel={t("project.impact_info.noImpactLabelPortfolio")}
                addImpactLinkText={t("project.impact_info.addImpactLinkText")}
                addImpactLink={editImpactCriteria}
                otherUserLoggedInflag={otherUserProjectflag}
                tagsData={socialImpact}
                status={portfolioStatus}
              />

              {/* Governance */}
              <ImpactInfoDetailsSection
                titleText={t("project.impact_info.governance")}
                descriptionLabel={t("project.impact_info.governance_des_label")}
                description={governanceImpactDescription}
                noImpactLabel={t("project.impact_info.noImpactLabelPortfolio")}
                addImpactLinkText={t("project.impact_info.addImpactLinkText")}
                addImpactLink={editImpactCriteria}
                otherUserLoggedInflag={otherUserProjectflag}
                tagsData={governanceImpact}
                status={portfolioStatus}
              />
            </div>
          }
          {noImpactInfo && (
            <div className={styles.noOrgImpact}>
              <p>{t("project.impact_info.noImpactSectionText")}</p>
              {!otherUserProjectflag && (
                <button
                  type="button"
                  disabled={portfolioStatus === 3 ? true : false}
                  className={styles.link}
                  data-testid="add-impact-link"
                  onClick={() => {
                    navigate(editImpactCriteria);
                  }}
                >
                  {t("project.impact_info.addImpactLinkText")}
                </button>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
}
