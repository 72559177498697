import { useState, useRef } from "react";
import { Form, Formik } from "formik";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { RadioInput } from "../common/RadioInput";
import { TermsCheckbox } from "./TermsCheckbox";
import { TermsModal } from "./TermsModal";
import { useTranslation } from "react-i18next";
import ReCAPTCHA from "react-google-recaptcha";
import styles from "./WaitlistForm.module.scss";
import { WaitListInfo } from "../../../TypesAndInterfaces/WaitListInterface";
import { TextInput } from "../common/TextInput";
import { CommonErrorMessage } from "../common/CommonErrorMessage";
import { postJoinWaitList } from "../../../APIAction/AccountAPI";
import { LearnMoreModal } from "./LearnMoreModal";

export function WaitlistForm() {
  const { t } = useTranslation("waitlist");
  const ref = useRef(null);

  const userType = [
    { value: "developer", text: t("waitlist_form.developer_label") },
    { value: "investor", text: t("waitlist_form.investor_label") },
  ];

  const maxCharsError = (charNum: number) => {
    return t("waitlist_form_errors.max_characters", {
      charNum,
    });
  }

  const spacingErrorText = t("waitlist_form_errors.no_spaces");
  const emailErrorText = t("waitlist_form_errors.email_required");
  const regxNotAllowOnlySpace = /^(?!\s*$)/;

  const WaitListSchemaDeveloper = Yup.object().shape({
    firstName: Yup.string()
      .required(t("waitlist_form_errors.first_name_required"))
      .max(64, maxCharsError(64))
      .matches(regxNotAllowOnlySpace, spacingErrorText),
    lastName: Yup.string()
      .required(t("waitlist_form_errors.last_name_required"))
      .max(64, maxCharsError(64))
      .matches(regxNotAllowOnlySpace, spacingErrorText),
    email: Yup.string()
      .required(emailErrorText)
      .email(emailErrorText)
      .max(64, maxCharsError(64)),
    organization: Yup.string()
      .required(t("waitlist_form_errors.organization_required"))
      .max(128, maxCharsError(128))
      .matches(regxNotAllowOnlySpace, spacingErrorText),
    userType: Yup.string().required(
      t("waitlist_form_errors.user_type_required")
    ),
    MemberBasedAssociationOrOrganizationFlag: Yup.boolean().required(
      t("waitlist_form_errors.select_option")
    ),
    MemberBasedAssociationOrOrganizationDescription: Yup.string()
      .required(t("waitlist_form_errors.select_option"))
      .max(128, maxCharsError(128)),
    ExistingCmhcClientFlag: Yup.boolean().required(
      t("waitlist_form_errors.select_option")
    ),
    AbleToProvideProofOfIncorporationFlag: Yup.boolean().required(
      t("waitlist_form_errors.select_option")
    ),
    termsAcknowledgement: Yup.boolean().oneOf(
      [true],
      t("waitlist_form_errors.terms_required")
    ),
    // recaptcha: Yup.string().required(
    //   t("waitlist_form_errors.recaptcha_required")
    // ),
  });
  const WaitListSchemaDeveloper2 = Yup.object().shape({
    firstName: Yup.string()
      .required(t("waitlist_form_errors.first_name_required"))
      .max(64, maxCharsError(64))
      .matches(regxNotAllowOnlySpace, spacingErrorText),
    lastName: Yup.string()
      .required(t("waitlist_form_errors.last_name_required"))
      .max(64, maxCharsError(64))
      .matches(regxNotAllowOnlySpace, spacingErrorText),
    email: Yup.string()
      .required(emailErrorText)
      .email(emailErrorText)
      .max(64, maxCharsError(64)),
    organization: Yup.string()
      .required(t("waitlist_form_errors.organization_required"))
      .max(128, maxCharsError(128))
      .matches(regxNotAllowOnlySpace, spacingErrorText),
    userType: Yup.string().required(
      t("waitlist_form_errors.user_type_required")
    ),
    MemberBasedAssociationOrOrganizationFlag: Yup.boolean().required(
      t("waitlist_form_errors.select_option")
    ),

    ExistingCmhcClientFlag: Yup.boolean().required(
      t("waitlist_form_errors.select_option")
    ),
    AbleToProvideProofOfIncorporationFlag: Yup.boolean().required(
      t("waitlist_form_errors.select_option")
    ),
    termsAcknowledgement: Yup.boolean().oneOf(
      [true],
      t("waitlist_form_errors.terms_required")
    ),
    // recaptcha: Yup.string().required(
    //   t("waitlist_form_errors.recaptcha_required")
    // ),
  });

  const WaitListSchemaInvestor = Yup.object().shape({
    firstName: Yup.string()
      .required(t("waitlist_form_errors.first_name_required"))
      .max(64, maxCharsError(64))
      .matches(regxNotAllowOnlySpace, spacingErrorText),
    lastName: Yup.string()
      .required(t("waitlist_form_errors.last_name_required"))
      .max(64, maxCharsError(64))
      .matches(regxNotAllowOnlySpace, spacingErrorText),
    email: Yup.string()
      .required(emailErrorText)
      .email(emailErrorText)
      .max(64, maxCharsError(64)),
    organization: Yup.string()
      .required(t("waitlist_form_errors.organization_required"))
      .max(128, maxCharsError(128))
      .matches(regxNotAllowOnlySpace, spacingErrorText),
    userType: Yup.string().required(
      t("waitlist_form_errors.user_type_required")
    ),
    MemberBasedAssociationOrOrganizationFlag: Yup.boolean().required(
      t("waitlist_form_errors.select_option")
    ),
    MemberBasedAssociationOrOrganizationDescription: Yup.string()
      .required(t("waitlist_form_errors.select_option"))
      .max(128, maxCharsError(128)),
    AbleToProvideProofOfAccreditationFlag: Yup.boolean().required(
      t("waitlist_form_errors.select_option")
    ),
    termsAcknowledgement: Yup.boolean().oneOf(
      [true],
      t("waitlist_form_errors.terms_required")
    ),
    recaptcha: Yup.string().required(
      t("waitlist_form_errors.recaptcha_required")
    ),
  });
  const WaitListSchemaInvestor2 = Yup.object().shape({
    firstName: Yup.string()
      .required(t("waitlist_form_errors.first_name_required"))
      .max(64, maxCharsError(64))
      .matches(regxNotAllowOnlySpace, spacingErrorText),
    lastName: Yup.string()
      .required(t("waitlist_form_errors.last_name_required"))
      .max(64, maxCharsError(64))
      .matches(regxNotAllowOnlySpace, spacingErrorText),
    email: Yup.string()
      .required(emailErrorText)
      .email(emailErrorText)
      .max(64, maxCharsError(64)),
    organization: Yup.string()
      .required(t("waitlist_form_errors.organization_required"))
      .max(128, maxCharsError(128))
      .matches(regxNotAllowOnlySpace, spacingErrorText),
    userType: Yup.string().required(
      t("waitlist_form_errors.user_type_required")
    ),
    MemberBasedAssociationOrOrganizationFlag: Yup.boolean().required(
      t("waitlist_form_errors.select_option")
    ),
    AbleToProvideProofOfAccreditationFlag: Yup.boolean().required(
      t("waitlist_form_errors.select_option")
    ),
    termsAcknowledgement: Yup.boolean().oneOf(
      [true],
      t("waitlist_form_errors.terms_required")
    ),
    recaptcha: Yup.string().required(
      t("waitlist_form_errors.recaptcha_required")
    ),
  });
  const initialValues = {
    firstName: "",
    lastName: "",
    email: "",
    organization: "",
    userType: "",
    termsAcknowledgement: false,
    recaptcha: "",
    MemberBasedAssociationOrOrganizationFlag: "",
    MemberBasedAssociationOrOrganizationDescription: "",
    ExistingCmhcClientFlag: "",
    AbleToProvideProofOfIncorporationFlag: "",
    AbleToProvideProofOfAccreditationFlag: ""
  };

  const navigate = useNavigate();
  const { i18n } = useTranslation();

  const [modalOpen, setModalOpen] = useState(false);
  const [learnMoreModalOpen, setLearnMoreModalOpen] = useState(false)
  function handleModalToggle() {
    setModalOpen(!modalOpen);
  }
  function handleLearnMoreModalToggle() {
    setLearnMoreModalOpen(!learnMoreModalOpen);
  }
  const handleWaitListFormSubmit = async (formData: WaitListInfo) => {
    try {
      await postJoinWaitList(formData);
      navigate("success");
    } catch (error) {
      navigate("error");
    }
  };

  const radioUserType = userType.map(({ text, value }) => ({
    label: text,
    value: value,
  }));
  const memberRadioOptions = [
    {
      label: t("waitlist_form.yes_label"),
      value: "true"
    },
    {
      label: t("waitlist_form.no_label"),
      value: "false"
    }
  ]
  const proofOptions = [
    {
      label: t("waitlist_form.yes_label"),
      value: "true"
    },
    {
      label: t("waitlist_form.no_label"),
      value: "false"
    }
  ]
  const existingClientOptions = [
    {
      label: t("waitlist_form.yes_label"),
      value: "true"
    },
    {
      label: t("waitlist_form.no_label"),
      value: "false"
    }
  ]
  const investorProofOptions = [
    {
      label: t("waitlist_form.yes_label"),
      value: "true"
    },
    {
      label: t("waitlist_form.no_label"),
      value: "false"
    }
  ]
  const learnMoreModalData_developer = [
    {
      title: t("waitlist_form.learnMore_title1"),
      description: t("waitlist_form.learnMore_descriprion1"),
    },
    {
      title: t("waitlist_form.learnMore_title2"),
      description: t("waitlist_form.learnMore_descriprion2")
    },
    {
      title: t("waitlist_form.learnMore_title3"),
      description: t("waitlist_form.learnMore_descriprion3"),
    }
  ]
  const learnMoreModalData_investor = [
    {
      title: t("waitlist_form.learnMore_title1"),
      description: t("waitlist_form.learnMore_descriprion_investor_1"),
    },
    {
      title: t("waitlist_form.learnMore_title_investor_3"),
      description: t("waitlist_form.learnMore_investor_descriprion_3"),
    }
  ]
  return (
    <Formik
      initialValues={initialValues}
      validateOnChange={false}
      validateOnBlur={false}
      innerRef={ref}
      validationSchema={() => {

        if (ref.current && 'values' in ref.current) {
          const currentValues = ref.current as { values: { userType: string, MemberBasedAssociationOrOrganizationFlag: string } }; // Specify the type of ref.current
          if (currentValues.values.userType === "developer" && currentValues.values.MemberBasedAssociationOrOrganizationFlag === "true") {
            return WaitListSchemaDeveloper;
          } else if (currentValues.values.userType === "developer" && currentValues.values.MemberBasedAssociationOrOrganizationFlag === "false") {
            return WaitListSchemaDeveloper2;
          } else if (currentValues.values.userType === "investor" && currentValues.values.MemberBasedAssociationOrOrganizationFlag === "true") {
            return WaitListSchemaInvestor;
          } else if (currentValues.values.userType === "investor" && currentValues.values.MemberBasedAssociationOrOrganizationFlag === "false") {
            return WaitListSchemaInvestor2
          }
        }
        return WaitListSchemaDeveloper;
      }}
      onSubmit={(values) => {
        const updatedValues = {
          firstName: values.firstName?.trim(),
          lastName: values.lastName?.trim(),
          requestorEmail: values.email?.trim(),
          organization: values.organization?.trim(),
          userType: values.userType,
          MemberBasedAssociationOrOrganizationFlag: values.MemberBasedAssociationOrOrganizationFlag,
          ExistingCmhcClientFlag: values.ExistingCmhcClientFlag,
          AbleToProvideProofOfIncorporationFlag: values.AbleToProvideProofOfIncorporationFlag,
          AbleToProvideProofOfAccreditationFlag: values.AbleToProvideProofOfAccreditationFlag,
          MemberBasedAssociationOrOrganizationDescription: values.MemberBasedAssociationOrOrganizationDescription?.trim(),
          language: i18n.language,
        };

        handleWaitListFormSubmit(updatedValues);
      }}
    >
      {({ setFieldValue, values, errors, touched }) => (
        <Form
          className={`${styles.waitlistForm} ${styles.flexCol}`}
          data-testid="waitlist-form"
          noValidate
        >
          <div className={`${styles.formSection} ${styles.flex}`}>
            <TextInput
              fieldName="firstName"
              labelText={t("waitlist_form.first_name_label")}
              data-testid="first-name"
              required
            />
            <TextInput
              fieldName="lastName"
              labelText={t("waitlist_form.last_name_label")}
              data-testid="last-name"
              required
            />
          </div>

          <div
            className={`${styles.formSection} ${styles.flexCol}`}
            style={{ gap: "1.25rem" }}
          >
            <TextInput
              fieldName="email"
              labelText={t("waitlist_form.email_label")}
              type="email"
              required
              data-testid="email"
              instructionText={t("waitlist_form.email_placeholder")}

            />
            <TextInput
              fieldName="organization"
              labelText={t("waitlist_form.organization_label")}
              data-testid="organization"
              required
            />
          </div>
          <div className={styles.radioParentContainer}>
            <fieldset className={styles.formSection}>
              <legend
                className={`${styles.labelStyle} ${errors.userType && touched.userType
                  ? styles.errorStateLabel
                  : null
                  }`}
              >
                {t("waitlist_form.user_type_label")}
              </legend>
              <RadioInput
                fieldName="userType"
                options={radioUserType}
                required
              />
            </fieldset>
          </div>
          <div>
            {values.userType && (
              <>
                <p className={styles.eligibilityLabel}>
                  {t("waitlist_form.eligibilityLabel")}
                </p>

                <div className={styles.radioParentContainer}>
                  <fieldset className={styles.formSection}>
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                      <legend
                        className={`${styles.labelStyle} ${errors.userType && touched.userType
                          ? styles.errorStateLabel
                          : null
                          }`}
                      >
                        {t("waitlist_form.member_field_label")}
                      </legend>
                      <label className={styles.learnMore} onClick={handleLearnMoreModalToggle}>{t("waitlist_form.learn_more")}</label>
                    </div>
                    <RadioInput
                      fieldName="MemberBasedAssociationOrOrganizationFlag"
                      options={memberRadioOptions}
                      required
                    />
                  </fieldset>
                </div>
                {values.MemberBasedAssociationOrOrganizationFlag === "true" ?
                  <>
                    <TextInput
                      fieldName="MemberBasedAssociationOrOrganizationDescription"
                      labelText={t("waitlist_form.specify_label")}
                      data-testid="member-yes"
                      required
                    />
                  </>
                  : ""}
              </>
            )}
            {values.userType === "developer" ?
              <>
                <div className={styles.radioParentContainer}>

                  <fieldset className={styles.formSection}>

                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                      <legend
                        className={`${styles.labelStyle} ${errors.userType && touched.userType
                          ? styles.errorStateLabel
                          : null
                          }`}
                      >
                        {t("waitlist_form.cmhc_client")}
                      </legend>
                      <label className={styles.learnMore} onClick={handleLearnMoreModalToggle}>{t("waitlist_form.learn_more")}</label>
                    </div>
                    <RadioInput
                      fieldName="ExistingCmhcClientFlag"
                      options={existingClientOptions}
                      required
                    />
                  </fieldset>
                </div>


                <div className={styles.radioParentContainer}>
                  <fieldset className={styles.formSection}>
                    <legend
                      className={`${styles.labelStyle} ${errors.userType && touched.userType
                        ? styles.errorStateLabel
                        : null
                        }`}
                    >
                      {t("waitlist_form.proof")}
                    </legend>

                    <RadioInput
                      fieldName="AbleToProvideProofOfIncorporationFlag"
                      options={proofOptions}
                      required
                    />
                  </fieldset>
                </div>
              </>

              :
              values.userType === "investor" ?
                <>
                  <div className={styles.radioParentContainer}>
                    <fieldset className={styles.formSection}>
                      <legend
                        className={`${styles.labelStyle} ${errors.userType && touched.userType
                          ? styles.errorStateLabel
                          : null
                          }`}
                      >
                        {t("waitlist_form.proof_investors")}
                      </legend>
                      <RadioInput
                        fieldName="AbleToProvideProofOfAccreditationFlag"
                        options={investorProofOptions}
                        required
                      />
                    </fieldset>
                  </div>
                </>
                :
                ""
            }
          </div>

          <TermsCheckbox
            fieldName="termsAcknowledgement"
            labelText={t("waitlist_form.terms_label")}
            termsAcknowledgmentTextStart={t(
              "waitlist_form.terms_acknowledgment_start"
            )}
            termsAcknowledgmentTextModalToggle={t(
              "waitlist_form.terms_acknowledgment_read_more"
            )}
            termsAcknowledgmentTextEnd={t(
              "waitlist_form.terms_acknowledgment_end"
            )}
            buttonToggleFunc={handleModalToggle}
            data-testid="terms-checkbox"
          />

          <TermsModal
            openState={modalOpen}
            handleClose={handleModalToggle}
            termsTitle={t("waitlist_form.terms_acknowledgment_read_more")}
            termsStartBody={t("waitlist_form.terms_modal_body_startText")}
            termsLink={t("waitlist_form.terms_modal_body_linkText")}
            termsEndBody={t("waitlist_form.terms_modal_body_endText")}
            data-testid="terms-modal"
          />
          <LearnMoreModal
            openState={learnMoreModalOpen}
            handleClose={handleLearnMoreModalToggle}
            termsTitle={t("waitlist_form.learnMore_heading")}
            termsStartBody={values.userType === "developer" ? learnMoreModalData_developer : learnMoreModalData_investor}
            data-testid="terms-modal"
          />
          <ReCAPTCHA
            sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY || ""}
            className={styles.recaptchaContainer}
            hl={i18n.language}
            data-testid="recaptcha-check"
            onChange={(value) => {
              setFieldValue("recaptcha", value);
            }}
          />
          <CommonErrorMessage fieldName="recaptcha" />

          <button type="submit" className={styles.submitButton}>
            {t("waitlist_form.request_access_button")}
          </button>
        </Form>
      )}
    </Formik>
  );
}
