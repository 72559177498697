import { useTranslation } from "react-i18next";
import { getKVArrayFormatForDropdown, PROVINCES } from "../../../Constants/Enumerations";
import { SelectInput } from "./SelectInput";

type Props = {
  fieldName: string;
  labelText?: string;
  placeholder?: string;
};

export function ProvincesSelect({ fieldName, labelText, placeholder }: Props) {
  const { t } = useTranslation("onboarding");

  const provincesTranslated = Object.keys(PROVINCES).map((val) =>
    t(`common:enumerations.provinces.${val}`)
  );

  const provincesPlaceholder = {
    label: placeholder ? placeholder : '',
    value: "",
  };
  const provinceOptions = getKVArrayFormatForDropdown(PROVINCES, provincesTranslated);
  provinceOptions.unshift(provincesPlaceholder);

  return (
    <SelectInput
      fieldName={fieldName}
      labelText={
        labelText ? labelText : t("investor.general_info_form.province_label")
      }
      options={provinceOptions}
      data-testid="province"
    />
  );
}
