import { Modal } from "@mui/material";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { ConnectIcon } from "../../assets/icons";
import styles from "./styles.module.scss";
import { SelectInput } from "../Form/common/SelectInput";
import { Form, Formik } from "formik";
import { DeveloperApi } from "../../APIAction/DeveloperAPI";
import { useUser } from "../Context/UserContext";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { InvestorGeneralInfo } from "../../TypesAndInterfaces/InvestorDetailsInterface";
import { ConnectApi } from "../../APIAction/ConnectAPI";
import { DeveloperGeneralInfo } from "../../TypesAndInterfaces/DeveloperDetailsInterface";
import { ConnectionRequestCommonElements } from "./ConnectionRequestCommonElements";

type Props = {
  openModal: boolean;
  handleClose: () => void;
  investorId: string;
  investorInfo: InvestorGeneralInfo;
  handleSubmitResponse: Function;
};

export function DevToInvestorRequest({
  openModal,
  handleClose,
  investorId,
  investorInfo,
  handleSubmitResponse,
}: Props) {
  const [projectOptions, setProjectOption] = useState<{ label: string; value: string, status: number, projectCount?: number }[]>([
    { label: "", value: "", status: 0, projectCount: 0 },
  ]);
  const [DeveloperInfo, setDeveloperInfo] = useState<DeveloperGeneralInfo>();
  const navigate = useNavigate();
  const { t } = useTranslation(["connect"]);
  const { userState } = useUser();
  const initialValues = {
    projectID: "",
    message: "",
  };
  const apiErrorPage = "/api-error";

  useEffect(() => {
    const getActiveProjectsList = async () => {
      const arr: { label: string; value: string, status: number, projectCount?: number }[] = [];
      try {
        const response = await DeveloperApi.getActiveProjects(userState.userId);

        const data = response?.data;
        data?.length > 0 &&
          data.map((project) => {
            return arr.push({
              label: project.projectName,
              value: project.projectListingID,
              status: project.projectListingStatus,
            });
          });
        const response2 = await DeveloperApi.getActivePortfolio(userState.userId);

        const data2 = response2?.data;


        data2?.length > 0 &&
          data2.map((portfolio: any) => {
            return arr.push({
              label: portfolio.portfolioName,
              value: portfolio.portfolioID,
              status: portfolio.portfolioStatus,
              projectCount: portfolio?.totalProjectCount
            });
          });

        arr.unshift({
          label: t("devToInvestor.requestModal.select_proj_helperText"),
          value: "",
          status: 0,
          projectCount: 0
        });

        arr?.length > 0 && setProjectOption(arr);
      } catch (err) {
        if (axios.isAxiosError(err) && err.response?.status !== 404) {
          navigate(apiErrorPage);
        }
      }
    };
    const getDeveloperInfomation = async () => {
      try {
        const res = await DeveloperApi.getGeneralInfoById(userState.userId);
        const data = res.data;
        setDeveloperInfo(data);
      } catch (err) {
        if (axios.isAxiosError(err) && err.response?.status !== 404) {
          navigate(apiErrorPage);
        }
      }
    };
    if (userState.isOnboarded) {
      getActiveProjectsList();
      getDeveloperInfomation();
    }
  }, [userState, navigate, t]);

  const DevToInvestorSchema = Yup.object().shape({
    projectID: Yup.string()
      .required(t("devToInvestor.error.projectID_required"))
      .max(128, t("devToInvestor.error.max_characters", { charNum: 128 })),
    message: Yup.string().max(
      1000,
      t("devToInvestor.error.max_characters", { charNum: 1000 })
    ),
  });

  const submitConnectionRequest = async (requestData: {}, type: string) => {
    try {
      await ConnectApi.postConnectRequest(requestData);
      window.dataLayer.push({
        event: "request_connect",
        development_type: type === "portfolio" ? "portfolio" : "project",
        request_type: "developer-to-investor",
      });
      handleSubmitResponse();
    } catch (err) {
      if (axios.isAxiosError(err) && err.response?.status !== 404) {
        navigate(apiErrorPage);
      }
    }
  };

  const handleSubmit = (values: { projectID: string; message: string }) => {
    const type = values.projectID.substring(0, values.projectID.indexOf(":"));

    const InvestorInfo = {
      entityID: investorId,
      entityType: "investor",
      organizationName: investorInfo.organizationName,
      city: investorInfo.city,
      province: investorInfo.province,
      emailAddress: investorInfo.emailAddress,
      phoneNumber: investorInfo.phoneNumber,
    };
    const developerInfo = {
      entityID: userState.userId,
      entityType: "developer",
      organizationName: DeveloperInfo?.organizationName,
      city: DeveloperInfo?.city,
      province: DeveloperInfo?.province,
      emailAddress: DeveloperInfo?.emailAddress,
      phoneNumber: investorInfo.phoneNumber,
    };
    const projectInfo = projectOptions?.find(
      (proj) => values.projectID === proj.value
    );
    let connectRequestData = null;
    if (type === "portfolio") {
      connectRequestData = {
        type: "connection",
        fromEntity: developerInfo,
        toEntity: InvestorInfo,
        portfolio: {
          portfolioID: projectInfo?.value,
          portfolioName: projectInfo?.label,
          portfolioStatus: projectInfo?.status,
          totalProjectCount: projectInfo?.projectCount
        },
        message: values.message,
      };
    } else {
      connectRequestData = {
        type: "connection",
        fromEntity: developerInfo,
        toEntity: InvestorInfo,
        project: {
          projectListingID: projectInfo?.value,
          projectName: projectInfo?.label,
          projectStatus: projectInfo?.status,
        },
        message: values.message,
      };
    }

    submitConnectionRequest(connectRequestData, type);
  };

  return (
    <Modal open={openModal} onClose={handleClose}>
      <div
        className={styles.ConnectModalContainer}
        data-testid="connect-request-container"
      >
        <div className={styles.modalContentWrapper}>
          <div className={styles.header}>
            <img
              src={ConnectIcon}
              alt={t("devToInvestor.requestModal.alt_text")}
              data-testid="connect-Icon"
            />
            <div className={styles.headerText} data-testid="header-text">
              <h2>{t("devToInvestor.requestModal.heading")}</h2>
              <p>{t("devToInvestor.requestModal.summary")}</p>
              <p>{t("devToInvestor.requestModal.summary1")}</p>
              <p>{t("devToInvestor.requestModal.summary2")}</p>
            </div>
          </div>
          <hr className={styles.hrDivider}></hr>
          <div className={styles.contentContainer}>
            <Formik
              initialValues={initialValues}
              validateOnChange={false}
              validateOnBlur={false}
              validationSchema={DevToInvestorSchema}
              onSubmit={(values) => {
                handleSubmit(values);
              }}
            >
              <Form>
                <SelectInput
                  fieldName="projectID"
                  labelText={t("devToInvestor.requestModal.select_proj_label")}
                  options={projectOptions}
                  data-testid="select-project"
                />
                <ConnectionRequestCommonElements
                  textAreaFieldNames={["message"]}
                  textAreaLabelText="devToInvestor.requestModal.message_label"
                  cancelButtonHandler={handleClose}
                  cancelButtonText="devToInvestor.requestModal.cancel_btn"
                  connectButtonText="devToInvestor.requestModal.connect_btn"
                />
              </Form>
            </Formik>
          </div>
        </div>
      </div>
    </Modal>
  );
}
