import { Modal } from "@mui/material";
import React, { SetStateAction, Dispatch } from "react";
import CloseIcon from "@mui/icons-material/Close";
import styles from "./ProjectCloseSuccess.module.scss";
import { CheckMark } from "../../../assets/icons";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

type Props = {
  projectCloseSuccess: boolean;
  setProjectCloseSuccess: Dispatch<SetStateAction<boolean>>;
};

export const ProjectCloseSuccess = (props: Props) => {
  const setProjectCloseSuccess = props.setProjectCloseSuccess;
  const projectCloseSuccess = props.projectCloseSuccess;
  const navigate = useNavigate();
  const { t } = useTranslation(["project"]);

  const handleClose = () => {
    setProjectCloseSuccess(false);
  };

  return (
    <Modal open={projectCloseSuccess} onClose={handleClose}>
      <div
        className={styles.closeProjectModalContainer}
        data-testid="closeProject-ModalContainer"
      >
        <button className={styles.crossIcon} onClick={handleClose}>
          <CloseIcon />
        </button>
        <img
          src={CheckMark}
          alt="Check circle icon"
          className={styles.icon}
          data-testid="checkcircle-icon"
        />
        <h2 className={styles.title} data-testid="closeProject-Title">
          {t("project:project.closing_confirmation.heading")}
        </h2>
        <p className={styles.info} data-testid="closeProject-Info">
          {t("project:project.closing_confirmation.summary")}
        </p>
        <div className={styles.buttonContainer}>
          <button
            className={styles.cancelButton}
            onClick={() => {
              navigate("/search/projects");
              localStorage.setItem("lastTabIndex", "1");
              localStorage.setItem("lastPage", "1");
            }
            }
            data-testid="closeProject-cancelButton"
          >
            {t("project:project.closing_confirmation.view_my_projects")}
          </button>
          <button
            className={styles.closeProjectButton}
            onClick={() => navigate("/projects/add")}
            data-testid="closeProjectButton"
          >
            {t("project:project.closing_confirmation.list_a_project")}
          </button>
        </div>
      </div>
    </Modal>
  );
};
