import { Skeleton } from "@mui/material";

export function RedirectSkeleton() {
  return (
    <div data-testid="skelton-container">
      <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
      <Skeleton
        variant="rectangular"
        height={60}
        sx={{ marginTop: "1rem", marginBottom: "1rem" }}
      />
      <Skeleton variant="rectangular" height={120} />
    </div>
  );
}
