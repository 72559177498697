import { PageLayout } from "../../components/Layout/PageLayout";
import { EarlyAccessBanner } from "../../components/common/EarlyAccessBanner/EarlyAccessBanner";
import { Hero } from "./pageComponents/HeroComponent/Hero";
import { ProblemStatement } from "./pageComponents/ProblemStatementComponent/ProblemStatement";
import { AboutCapitalConnect } from "./pageComponents/AboutCapitalConnect/AboutCapitalConnect";
import { HowCapitalConnectWorks } from "./pageComponents/HowCCWorks/HowCCWorks";
import { Affordability } from "./pageComponents/Affordability/Affordability";
import { Opportunity } from "./pageComponents/OpportunityComponent/Opportunity";
import { PageContent } from "../../components/Layout/PageContent";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { useIsAuthenticated } from "@azure/msal-react";
import { useUser } from "../../components/Context/UserContext";
import { useNavigate } from "react-router-dom";

export function LandingPage() {
  const isAuthenticated = useIsAuthenticated();
  const { userState } = useUser();
  const navigate = useNavigate();
  const { t } = useTranslation(["landing", "common"]);

  useEffect(() => {
    if (isAuthenticated && userState.userPersona !== "") {
      navigate("/redirect");
    }
  }, [isAuthenticated, userState.userPersona, navigate]);

  return (
    <PageLayout>
      <Hero />
      <PageContent>
        <ProblemStatement />
        <AboutCapitalConnect />
        <HowCapitalConnectWorks />
        <Affordability />
        <Opportunity />
        <EarlyAccessBanner
          heading={t("landing:early_access.heading")}
          content={t("landing:early_access.content")}
          requestaccessLink={t("landing:early_access.request_access")}
        />
      </PageContent>
    </PageLayout>
  );
}
