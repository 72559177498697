import { PageContent } from "../../../../components/Layout/PageContent";
import { PageLayout } from "../../../../components/Layout/PageLayout";
import { Link,useNavigate } from "react-router-dom";
import { TrashIcon } from "../../../../assets/icons";
import styles from "./DeleteConfirmation.module.scss";
import { useTranslation } from "react-i18next";

export function DeleteConfirmationPortfolio() {
  const { t } = useTranslation(["project"]);
  const navigate = useNavigate();
  const learnMoreLinks = [
    {
      text:  t("project:project.delete_confirmation.list_new_portfolio"),
      path: "/portfolio/add",
    },
    {
      text: t("project:project.delete_confirmation.view_my_portfolio"),
      path: "/search/projects",
    },
  ];

  return (
    <PageLayout>
      <PageContent>
        <div className={styles.confirmationContainer}>
          <img
            src={TrashIcon}
            className={styles.trashicon}
            alt="trash bin icon"
            data-testid="TrashIcon"
          />
          <div
            className={styles.deleteInfoContainer}
          >
            <h2 className={styles.pageTitle} data-testid="page-Title">
            {t("project:project.delete_confirmation.portfolio_delete_confirmation_heading")}

            </h2>

            <p className={styles.summaryText} data-testid="summary-Info">
            {t("project:project.delete_confirmation.portfolio_deleteConfirmation_summary")}

            </p>
          </div>
          <div
            className={styles.deleteLinkList}
            data-testid="delete-links"
          ></div>
          {learnMoreLinks.map((link, idx) => (
            <div key={idx} className={styles.deleteInfoLink}>
              <Link className={styles.links} to={link.path} onClick={() => {
                if (link.path === "/search/projects") {
                  navigate("/search/projects");
                  localStorage.setItem("lastTabIndex", "3");
                  localStorage.setItem("lastPage", "1");
                }
              }}>
                {link.text}
              </Link>
            </div>
          ))}
        </div>
      </PageContent>
    </PageLayout>
  );
}
