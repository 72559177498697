import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { InvestorAPI } from "../../APIAction/InvestorAPI";
import {
  InvestorGeneralInfo,
  InvestorInvestmentInformation,
  InvestorOrganizationImpact,
} from "../../TypesAndInterfaces/InvestorDetailsInterface";
import { PageLayout } from "../../components/Layout/PageLayout";
import { PageContent } from "../../components/Layout/PageContent";
import { ViewPreferences } from "./PreferenceView";
import { ViewGeneralInfo } from "./ViewGeneralInfo";
import styles from "./ViewInvestor.module.scss";
import { ViewInvestmentInfo } from "./InvestmentInfo";
import { EditIcon } from "../../assets/icons";
import axios from "axios";
import { useUser } from "../../components/Context/UserContext";
import { getKVArrayFormat, PROVINCES } from "../../Constants/Enumerations";
import { DevToInvestorRequest } from "../../components/ConnectionRequest/DevToInvestor";
import { RequestSentSuccess } from "../../components/ConnectionRequest/RequestSentSuccess";
import { ViewInvestorOrganizationImpact } from "./ViewInvestorOrganizationImpact";

export const ViewInvestorProfile = () => {
  const { t } = useTranslation(["profile", "onboarding"]);
  const { investorId = "" } = useParams();
  const { userState } = useUser();
  const navigate = useNavigate();
  const [otherUserLoggedInflag, setOtherUserLoggedInFlag] = useState(false);
  const [GeneralInfo, setGeneralInfo] = useState<InvestorGeneralInfo>();
  const [InvestmentInfo, setInvestmentInfo] =
    useState<InvestorInvestmentInformation>();
  const [isOrganizationImpactInfo, setOrganizationImpactInfo] =
    useState<InvestorOrganizationImpact>();
  const [isGenDataExist, setIsGenDataExist] = useState(false);
  const [isInvestmentDataExist, setIsInvestmentDataExist] = useState(false);
  const [isOrganizationImpactDataExist, setIsOrganizationImpactDataExist] = useState(false);
  const [connectToInvestor, setConnectToInvestor] = useState(false);
  const [showRequestSentSuccess, setShowRequestSentSuccess] = useState(false);

  useEffect(() => {
    if (investorId === "" && !userState.isOnboarded) {
      //If NOT onboarded redirect to onboarding flow
      navigate("/onboarding/investor");
    }
  }, [userState.userId, userState.isOnboarded, investorId, navigate]);

  useEffect(() => {
    const getGeneralInfomation = async (id: string) => {
      try {
        const res = await InvestorAPI.getGeneralInfoById(id);
        const data = res.data;
        setIsGenDataExist(true);
        setGeneralInfo(data);
      } catch (err) {
        if (axios.isAxiosError(err)) {
          if (err.response?.status === 404) {
            setIsGenDataExist(false);
          } else {
            navigate("/api-error");
          }
        }
      }
    };

    const getInvestmentInfomation = async (id: string) => {
      try {
        const res = await InvestorAPI.getInvestmentInfoById(id);
        const data = res.data;
        setIsInvestmentDataExist(true);
        setInvestmentInfo(data);
      } catch (err) {
        if (axios.isAxiosError(err)) {
          if (err.response?.status === 404) {
            setIsInvestmentDataExist(false);
          } else {
            navigate("/api-error");
          }
        }
      }
    };
    const getInvestorOrganizationImpactInfo = async (id: string) => {
      try {
        const res = await InvestorAPI.getOrganizationImpactInfoById(id);
        const data = res.data;
        setIsOrganizationImpactDataExist(true);
        setOrganizationImpactInfo(data);
      } catch (err) {
        if (axios.isAxiosError(err)) {
          if (err.response?.status === 404) {
            setIsOrganizationImpactDataExist(false);
          } else {
            navigate("/api-error");
          }
        }
      }
    };

    if (investorId !== "") {
      getGeneralInfomation(investorId);
      getInvestmentInfomation(investorId);
      getInvestorOrganizationImpactInfo(investorId);
      setOtherUserLoggedInFlag(userState.userId !== investorId);
    } else if (
      userState.userId !== "" &&
      userState.userPersona.toLowerCase() === "investor"
    ) {
      getGeneralInfomation(userState.userId);
      getInvestmentInfomation(userState.userId);
      getInvestorOrganizationImpactInfo(userState.userId);
      setOtherUserLoggedInFlag(false);
    }
  }, [userState, navigate, investorId]);

  const provincesTranslated = Object.keys(PROVINCES).map((val) =>
    t(`common:enumerations.provinces.${val}`)
  );
  const provinceOptions = getKVArrayFormat(PROVINCES, provincesTranslated);

  const handleEditClick = () => {
    navigate("/profile/investor/edit");
  };

  const handleConnect = () => {
    setConnectToInvestor(!connectToInvestor);
  };

  const handleRequestSent = () => {
    setConnectToInvestor(false);
    setShowRequestSentSuccess(true);
  };

  return (
    <PageLayout>
      <hr className={styles.divider} />
      <PageContent>
        <div className={styles.container}>
          <div className={styles.headerContainer}>
            <h1 className={styles.mainHeading} data-testid="main-heading">
              {t("investor.main_page.page_title")}
            </h1>
            {!otherUserLoggedInflag ? (
              <div>
                <button className={styles.link} onClick={handleEditClick}>
                  {t("investor.main_page.edit_link")}
                </button>
                <img src={EditIcon} alt="" />
              </div>
            ) : (
              ""
            )}
          </div>
          <div data-testid="general-info">
            {isGenDataExist && GeneralInfo && InvestmentInfo && (
              <ViewGeneralInfo
                genInfo={GeneralInfo}
                coInvestingFlag={InvestmentInfo.coInvestingFlag}
                provinceOptions={provinceOptions}
                handleConnect={handleConnect}
                otherUserLoggedInflag={otherUserLoggedInflag}
                updateConnectionStatus={showRequestSentSuccess === true}
              />
            )}
          </div>
          <div
            className={styles.sectionContainer}
            data-testid="preferences-info"
          >
            {isInvestmentDataExist && InvestmentInfo && (
              <ViewPreferences
                investmentDollarMinimum={InvestmentInfo.investmentDollarMinimum}
                investmentDollarMaximum={InvestmentInfo.investmentDollarMaximum}
                provinceOptions={provinceOptions}
                otherUserLoggedInflag={otherUserLoggedInflag}
              />
            )}
          </div>
          
          <div
            className={styles.sectionContainer}
            data-testid="investor-organization-impact-info"
          >
            {isOrganizationImpactDataExist && isOrganizationImpactInfo && (
              <ViewInvestorOrganizationImpact
                investorOrganizationInfo={isOrganizationImpactInfo}
                otherUserLoggedInflag={otherUserLoggedInflag}
              />
            )}
          </div>
          <div
            className={styles.sectionContainer}
            data-testid="investments-info"
          >
            {isInvestmentDataExist && InvestmentInfo && (
              <ViewInvestmentInfo
                InvestmentInfo={InvestmentInfo}
                provinceOptions={provinceOptions}
              />
            )}
          </div>
        </div>
        {connectToInvestor && GeneralInfo && (
          <DevToInvestorRequest
            openModal={connectToInvestor}
            handleClose={handleConnect}
            investorInfo={GeneralInfo}
            investorId={investorId}
            handleSubmitResponse={handleRequestSent}
          />
        )}
        {showRequestSentSuccess && (
          <RequestSentSuccess
            openModal={showRequestSentSuccess}
            handleClose={() => setShowRequestSentSuccess(false)}
          />
        )}
      </PageContent>
    </PageLayout>
  );
};
