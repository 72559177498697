import { useTranslation } from "react-i18next";
import {
  getEnumKeyByValue,
  PROVINCES
} from "../../../Constants/Enumerations";
import styles from "./FinancialDetails.module.scss";
import { FinancialDetailHighlightSingle } from "./FinancialDetailHighlightSingle";


type Props = {
  portfolioProjectDetail: any;
};

export function ProjectInPortfolioDetails({ portfolioProjectDetail }: Props) {
  const { t } = useTranslation(["profile", "onboarding", "project", "portfolio"]);

  const getProvinceName = (provinceNumber: number) => {
    const provinceMappedValue = getEnumKeyByValue(PROVINCES, provinceNumber);
    const provinceTranslated = t(
      `common:enumerations.provinces.${provinceMappedValue}`
    );
    return provinceTranslated;
  };

  return (
    <div className={styles.detailsContainer} data-testid="summary-Container">

      <FinancialDetailHighlightSingle
        highlightLabel={t("portfolio:portfolio_details.portfolio_summary.projects_included_in_project")}
        highlightValue={portfolioProjectDetail.totalProjectCount.toString()}
      />
      <FinancialDetailHighlightSingle
        highlightLabel={t("portfolio:portfolio_details.portfolio_summary.total_units_in_portfolio")}
        highlightValue={portfolioProjectDetail && portfolioProjectDetail.totalUnitPortfolioProject !== null
          ? portfolioProjectDetail.totalUnitPortfolioProject.toString()
          : "N/A"}

      />
      <FinancialDetailHighlightSingle
        highlightLabel={t("portfolio:portfolio_details.portfolio_summary.percentage_of_affordable_units")}
        highlightValue={portfolioProjectDetail && portfolioProjectDetail.belowAverageMarketRentUnitCount !== null
          ? `${portfolioProjectDetail.belowAverageMarketRentUnitCount.toString()}%`
          : "N/A"}
      />

      {portfolioProjectDetail.developmentRegions.length > 0 && portfolioProjectDetail.developmentRegions.map((region: any, index: number) => {
        return <FinancialDetailHighlightSingle
          highlightLabel={index === 0 ? t("portfolio:portfolio_details.portfolio_summary.regional_focus") : ""}
          highlightValue={`${region.cityOrRegion}, ${getProvinceName(region.province)} `}
        />
      })}

      {portfolioProjectDetail.portfolioProjectsSummaryDescription &&
        <FinancialDetailHighlightSingle
        highlightLabel={t("portfolio:portfolio_details.portfolio_summary.summary_of_projects")}
          highlightValue={portfolioProjectDetail.portfolioProjectsSummaryDescription}
        />}
    </div>
  );
}
