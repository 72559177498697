import axios from "axios";
import { ReactElement, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { ConnectApi } from "../../APIAction/ConnectAPI";
import { useUser } from "../../components/Context/UserContext";
import { InvestorGeneralInfo } from "../../TypesAndInterfaces/InvestorDetailsInterface";
import { CONNECTION_STATUS_CODE } from "../../Constants/Enumerations";
import {
  ConnectionStatusI,
  PendingConnectionStatusI,
} from "../../TypesAndInterfaces/ConnectionInterface";
import styles from "./ViewInvestor.module.scss";
import { RequestSent } from "../../components/common/ConnectionStatusTabs/RequestSent";
import { AcceptedRequest } from "../../components/common/ConnectionStatusTabs/AcceptedRequest";
import { UnavailableRequest } from "../../components/common/ConnectionStatusTabs/UnavailableRequest";
import { NewConnectionRequest } from "../../components/common/ConnectionStatusTabs/NewConnectionRequest";
import { AwaitingRequestsToast } from "../../components/common/ConnectionStatusTabs/AwaitingRequestsToast";

type ViewGeneralInfoProps = {
  genInfo: InvestorGeneralInfo;
  coInvestingFlag: boolean;
  provinceOptions: {
    label: string;
    value: number;
  }[];
  handleConnect: () => void;
  otherUserLoggedInflag: boolean;
  updateConnectionStatus: boolean;
};

export const ViewGeneralInfo = ({
  genInfo,
  coInvestingFlag,
  provinceOptions,
  handleConnect,
  otherUserLoggedInflag,
  updateConnectionStatus,
}: ViewGeneralInfoProps) => {
  const { t } = useTranslation("profile");
  const { userState } = useUser();
  const isLoggedInUserInvestor =
    userState.userPersona.toLowerCase() === "investor";

  const {
    organizationName = "",
    address = "",
    unitNumber = "",
    city = "",
    country = "",
    postalCode = "",
    province = "",
    organizationSummary = "",
  } = genInfo;

  const navigate = useNavigate();
  const [connectionStatus, setConnectionStatus] = useState<ConnectionStatusI>();
  const [pendingConnectionStatus, setPendingConnectionStatus] =
    useState<PendingConnectionStatusI>();
  const { investorId = "" } = useParams();

  useEffect(() => {
    const getConnectionStatus = async () => {
      const postData = {
        fromEntityID: userState.userId,
        fromEntityType: userState.userPersona,
        toEntityID: investorId,
      };
      try {
        const res = await ConnectApi.VerifyConnectionStatus(postData);
        const data = res.data;
        setConnectionStatus(data);
      } catch (err) {
        if (axios.isAxiosError(err)) {
          if (err.response?.status === 404) {
            return;
          } else {
            navigate("/api-error");
          }
        }
      }
    };

    if (otherUserLoggedInflag) {
      if (
        userState.userPersona.toLocaleLowerCase() === "developer" &&
        userState.isOnboarded
      ) {
        getConnectionStatus();
      }
    }
  }, [
    navigate,
    investorId,
    userState,
    otherUserLoggedInflag,
    updateConnectionStatus,
  ]);

  useEffect(() => {
    const verifyPendingConnectionStatus = async () => {
      const postData = {
        requestorEntityID: userState.userId,
        requestorEntityType: userState.userPersona,
      };
      try {
        const res = await ConnectApi.checkPendingConnectionStatus(postData);
        setPendingConnectionStatus(res?.data);
      } catch (err) {
        if (axios.isAxiosError(err)) {
          if (err.response?.status === 404) {
            return;
          } else {
            navigate("/api-error");
          }
        }
      }
    };

    !otherUserLoggedInflag && verifyPendingConnectionStatus();
  }, [userState, navigate, otherUserLoggedInflag]);

  function connectionStatusMessage(): ReactElement {
    if (
      (otherUserLoggedInflag && isLoggedInUserInvestor) ||
      !userState.isOnboarded
    ) {
      return <UnavailableRequest isInvestorPage={true} />;
    } else {
      if (
        otherUserLoggedInflag &&
        !isLoggedInUserInvestor &&
        connectionStatus?.isConnectionEstablished
      ) {
        switch (connectionStatus?.connectionStatusCode) {
          case CONNECTION_STATUS_CODE.Connected:
            return <AcceptedRequest />;
          case CONNECTION_STATUS_CODE.Pending:
            return <RequestSent />;
          case CONNECTION_STATUS_CODE.NewConnection:
            return <NewConnectionRequest />;
          default:
            return <></>;
        }
      } else {
        return (
          <button
            type="submit"
            className={styles.formButton}
            onClick={handleConnect}
          >
            {t("investor.gen_info.connect_btn")}
          </button>
        );
      }
    }
  }

  return (
    <div className={styles.genInformationContainer}>
      {pendingConnectionStatus?.isConnectionPending && (
        <AwaitingRequestsToast />
      )}
      <div className={styles.flex_container_top}>
        <div className={styles.Organizationaddress}>
          <h2 className={styles.Organizationtitle}>{organizationName}</h2>
          <p>
            {address}, {unitNumber ? `${unitNumber},` : null}
          </p>
          <p>
            {city},{" "}
            {province !== ""
              ? provinceOptions.find((val) => val.value === province)?.label
              : ""}
          </p>
          <p>
            {country}, {postalCode}
          </p>
          {coInvestingFlag === true ? (
            <div className={styles.coinvestcontainer}>
              <span className={styles.tag}>
                {t("investor.gen_info.coInvestingFlagContent")}
              </span>
            </div>
          ) : null}
        </div>
        <p className={styles.letterdesign}>
          {organizationName && organizationName.trimStart().substring(0, 1)}
        </p>
      </div>
      <div className={styles.flex_container_bottom}>
        <p className={styles.Organizationsummary}>{organizationSummary}</p>
        {otherUserLoggedInflag ? connectionStatusMessage() : null}
      </div>
    </div>
  );
};
