
import { useTranslation } from "react-i18next";
import { TextArea } from "../common/TextArea";
import { TextInput } from "../common/TextInput";
import { OpenInNew } from "@mui/icons-material";
import styles from "./styles.module.scss";
import {
    getKVArrayFormatForDropdown,
    COMMITMENT_TO_AFFORDABILITY,
} from "../../../Constants/Enumerations";
import { SelectInput } from "../common/SelectInput";
import { SubtitleLink } from "../common/SubtitleLink";
import { useState } from "react";
import { GlossaryDefinition } from "../../../pages/Developers/Modals/GlossaryDefinitionModal/GlossaryDefinition";
export function AffordabilitySecondRadioOptionForm() {
    const { t } = useTranslation(["profile", "common"]);
    const [isOpenModal, setIsOpenModal] = useState(false);
    const commitmentAffordabilityTrans = Object.keys(COMMITMENT_TO_AFFORDABILITY).map((val) =>
        t(`common:enumerations.commitment_to_affordability.${val}`)
    );
    const commitmentAffordabilityPlaceholder = {
        label: t("developer.projects.add_listing_form.select_value_option"),
        value: "",
    };
    const commitmentAffordabilityOptions: {
        label: string;
        value: string | number;
    }[] = getKVArrayFormatForDropdown(COMMITMENT_TO_AFFORDABILITY, commitmentAffordabilityTrans);
    commitmentAffordabilityOptions.unshift(commitmentAffordabilityPlaceholder);

    return (
        <div>
            <div className={styles.fieldContainer}>
                <h3 className={styles.formTitle}>
                    {t(
                        "developer.projects.add_listing_form.affordabilityDetails"
                    )}
                </h3>
            </div>
            <div>
                <p className={styles.subtitleImpact}
                >
                    {t("developer.projects.add_listing_form.affordability_subtitle")}
                    <SubtitleLink
                        fieldName="affordability_subtitle"
                        linkText={t("developer.projects.add_listing_form.link_label")}
                        setIsOpenModal={setIsOpenModal}
                    />
                </p>
                <div className={styles.noteContainer}>
                    <h2 className={styles.noteHeading}>
                        {t("developer.projects.note.heading")}
                    </h2>
                    <p>{t("developer.projects.add_listing_form.note_label")}</p>
                </div>
            </div>
            <TextInput
                fieldName="belowAverageMarketRentUnitCount"
                labelText={t(
                    "developer.projects.add_listing_form.units_below_avg_rent"
                )}
                required
                numOnly
                data-testid="units-below-avg-rent"
            />
            <TextInput
                fieldName="equalToAverageMarketRentUnitCount"
                labelText={t(
                    "developer.projects.add_listing_form.units_equal_avg_rent"
                )}
                required
                numOnly
                data-testid="units-equal-avg-rent"
            />
            <TextInput
                fieldName="aboveAverageMarketRentUnitCount"
                labelText={t(
                    "developer.projects.add_listing_form.units_above_avg_rent"
                )}
                required
                numOnly
                data-testid="units-above-avg-rent"
            />
            <div className={styles.fieldContainer}>
                <p className={styles.staticCaption}>{t("developer.projects.add_listing_form.static_label1")}</p>
                <div className={styles.housingInformationLinkWrapper}>
                    <p className={styles.staticCaption}>{t("developer.projects.add_listing_form.static_label2")}&nbsp;
                    <a
                        className={styles.housingInformationLink}
                        href={t(`developer.projects.add_listing_form.housingInformationPortal_url`)}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {t("developer.projects.add_listing_form.housingInformation_LinkText")}
                    </a>
                   {t("developer.projects.add_listing_form.static_label3")}</p>
                    <OpenInNew sx={{ height: "1rem", marginTop: "5px", color: "#004F71" }} />
                </div>
            </div>
            <SelectInput
                fieldName="affordabilityCommitment"
                labelText={t(
                    "developer.projects.add_listing_form.commitment_to_affordability"
                )}
                options={commitmentAffordabilityOptions}
                placeholder={t("developer.projects.add_listing_form.select_value_option")}
                data-testid="commitment_to_affordability"
            />
            <TextArea
                fieldName="marketLevelDefinition"
                labelText={t(
                    "developer.projects.add_listing_form.define_market_level"
                )}
                data-testid="define-market-level"
                charLimit={300}
            />
            <TextArea
                fieldName="additionalDetails"
                labelText={t(
                    "developer.projects.add_listing_form.add_details"
                )}
                data-testid="add-details"
                charLimit={300}
            />
            {isOpenModal && (
                <GlossaryDefinition
                    setCloseGlossaryModal={setIsOpenModal}
                    title={t("developer.projects.add_listing_form.affordabilityGlossary.title")}
                    glossaryContent={t(
                        "developer.projects.add_listing_form.affordabilityGlossary.affordabilityGlossaryContent",
                        {
                            returnObjects: true,
                        }
                    )}
                    paraContent={t(
                        "developer.projects.add_listing_form.affordabilityGlossary.affordabilityParaContent",
                        {
                            returnObjects: true,
                        }
                    )}
                    glossaryLink={t(
                        "developer.projects.add_listing_form.affordabilityGlossary.viewFullGlossaryLink"
                    )}
                    closeText={t("developer.projects.add_listing_form.affordabilityGlossary.closeButton")}
                    selectedId="#2"
                />
            )}
        </div>
    )
}