import { useState } from "react";
import { ExpandMore, OpenInNew } from "@mui/icons-material";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import { AccordionContainerProps } from "./AccordionContainer.types";
import styles from "./styles.module.scss";

export const AccordionContainer = ({ data, containerVal }: AccordionContainerProps) => {
  const [current, setCurrent] = useState<number[]>([]);

  const handleChange =
    (panel: number, obj: any) =>
      (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
        const newExpandedPanel = current.filter(
          (selectedItem) => selectedItem !== panel
        );
        if (obj.trackGA && isExpanded) {
          window.dataLayer.push(obj.trackGA);
        }
        isExpanded
          ? setCurrent([...current, panel])
          : setCurrent([...newExpandedPanel]);
      };

  return (
    <div>
      {data &&
        data.map((obj, index) => {
          return (
            <Accordion
              key={index}
              onChange={handleChange(index, obj)}
              disableGutters
              style={{
                minWidth: "600px",
                minHeight: "15px",
                border: "1px solid #CCCCCC",
                backgroundColor: current.includes(index)
                  ? "#FFFFFF"
                  : "#F8F8F8",
              }}
              className={styles.border}
            >
              <AccordionSummary
                expandIcon={<ExpandMore className={styles.icon} />}
              >
                <p className={styles.accordionSummary}>{obj.heading}</p>
              </AccordionSummary>
              <AccordionDetails>
                <p className={styles.accordionDetails}>
                  {obj.summary && obj.summary}
                  {Array.isArray(obj.paraContent) && obj.paraContent.map((data: { para: string }, i: number) => (
                    <p className={styles.paraText}>{data?.para}</p>
                  ))
                  }
                  {Array.isArray(obj?.descriptionContent) && obj.descriptionContent?.map((data: { subHeading: string, description: string }, i: number) => (
                    <p className={styles.paraText} key={i}>
                      <b>{data.subHeading}</b> {data.description}
                    </p>
                  ))
                  }
                  {obj.linkpath && (
                    <a
                      className={styles.accordionanchor}
                      target="_blank"
                      rel="noopener noreferrer"
                      href={obj.linkpath}
                    >
                      {obj.content}&nbsp;
                      <OpenInNew
                        sx={{ height: "1rem", width: "1rem", color: "#005C84" }}
                      />
                    </a>
                  )}
                  {obj.pdfLinks && obj.pdfLinks.map((pdf, index) => {
                    return <a
                      className={styles.accordionanchor}
                      target="_blank"
                      rel="noopener noreferrer"
                      href={pdf.path}
                      key={index}
                    >
                      {pdf.label}&nbsp;
                      <OpenInNew
                        sx={{ height: "1rem", width: "1rem", color: "#005C84" }}
                      />
                    </a>
                  })}
                </p>
              </AccordionDetails>

              {containerVal && (
                <AccordionDetails>
                  <p className={styles.accordionDetails}>
                    {obj.summary}
                    {obj.linkpath && (
                      <a
                        className={styles.accordionanchor}
                        target="_blank"
                        rel="noopener noreferrer"
                        href={obj.linkpath}
                      >
                        {obj.content}&nbsp;
                        <OpenInNew
                          sx={{ height: "1rem", width: "1rem", color: "#005C84" }}
                        />
                      </a>
                    )}
                    {obj.pdfLinks && obj.pdfLinks.map((pdf, index) => {
                      return <a
                        className={styles.accordionanchor}
                        target="_blank"
                        rel="noopener noreferrer"
                        href={pdf.path}
                        key={index}
                      >
                        {pdf.label}&nbsp;
                        <OpenInNew
                          sx={{ height: "1rem", width: "1rem", color: "#005C84" }}
                        />
                      </a>
                    })}
                    {containerVal.map((item: any) => (
                      <div style={{ marginTop: '10px' }}>
                        <h3>{item.title}</h3>
                        <p style={{ marginTop: '5px' }}>{item.desc}</p>
                      </div>
                    ))}
                  </p>
                </AccordionDetails>
              )}
            </Accordion>
          );
        })}
    </div>
  );
};
