import styles from "./PageNavigation.module.scss";
import { useEffect, useState } from "react";

type Props = {
  heading: string;
  links: { text: string; id: string }[];
  selectedLinkId?: string;
};

export function PageNavigation(props: Props) {
  const { selectedLinkId } = props
  const [clickedLink, setClickedLink] = useState<string | null>(null);

  useEffect(() => {
    if (selectedLinkId) {
      setClickedLink(selectedLinkId);
    }
  }, [selectedLinkId]);

  const handleClick = (id: string) => {
    setClickedLink(id === clickedLink ? null : id);
  };
  return (
    
    <div className={styles.PNContainer}>
      <div
        className={styles.pageNavigationContainer}
        data-testid="resources-pagenavigation"
      >
        <h2 className={styles.pageNavigationHeading}>{props.heading}</h2>
        <ul className={styles.pageNavigationList}>
          {props.links.map((link, idx) => (
            <li key={idx} className={styles.pageNavigationListItem}>
              <a href={link.id} 
              className={`${styles.pageNavigationListItemLink} ${
                clickedLink === link.id ? styles.clicked : ""
              }`}
              onClick={() => handleClick(link.id)}
              >
                {link.text}
              </a>
            </li>
          ))}
        </ul>
      </div>
      </div>
  );
}
