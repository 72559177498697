import { useTranslation } from "react-i18next";
import styles from "./styles.module.scss";
import { CalculatorIcon } from "../../../assets/icons";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

type Props = {
  portfolioListingID: string;
  affordability: any;
  data: any;
};



export const ReviewAffordability = ({ portfolioListingID, affordability, data }: Props) => {
  const { t } = useTranslation("profile");

  const navigate = useNavigate();

  const [info, setInfo] = useState<any>(null);
  const [affordabilityData, setaffordabilityData] = useState<any>(null);

  useEffect(() => {
    setInfo(data);
    setaffordabilityData(affordability);
  }, [data, affordability]);

  const affirdabilityTypesOptions = [
    {
      label: t(
        "common:enumerations.affordability_Types.SocialImpactingHousing"
      ),
      value: 1,
      description: t(
        "developer.projects.add_listing_form.socialImpactHousingDescription"
      )
    },
    {
      label: t(
        "common:enumerations.affordability_Types.FocusOnAffordableHousing"
      ),
      value: 2,
      description: t(
        "developer.projects.add_listing_form.affordableHousingDescription"
      )
    },
    {
      label: t(
        "common:enumerations.affordability_Types.BalanceBetweenAffordableAndSupply"
      ),
      value: 3,
      description: t(
        "developer.projects.add_listing_form.affirdabilityAndSupplyDescription"
      )
    },
    {
      label: t(
        "common:enumerations.affordability_Types.FocusOnHousingSupply"
      ),
      value: 4,
      description: t(
        "developer.projects.add_listing_form.housingSupplydescription"
      )
    },
  ]
  const handleEditClick = () => {
    navigate(`/portfolio/edit/${portfolioListingID}`);
  };
  return (
    <div className={styles.informationContainer}>
      <div className={styles.infoHeadingContainer}>
        <div className={styles.left}>
          <div className={styles.imgContainer}>
            <img
              src={CalculatorIcon}
              alt={t("developer.projects.add_listing_form.calculatorIcon")}
            />
          </div>
          <h3 className={styles.sectionHeading}>
            {t(
              "developer.projects.add_listing_form.affordability_benchmark_title"
            )}
          </h3>
        </div>
        <button className={styles.link}
          onClick={handleEditClick}
        >
          {t("developer.projects.review_project.edit_link")}
        </button>
      </div>
      <div className={styles.content}>
        <div className={styles.grid_container}>

          {affordabilityData !== null && affordabilityData.projectAffordabilityTypes &&
            affordabilityData.projectAffordabilityTypes.map((type: any, idx: any) => (

              <>
                <p>
                  {idx === 0 && t("developer.projects.add_listing_form.type_of_affordability_review")}
                </p>
                <p>
                  {type === 1 &&
                    <>

                      <b>{affirdabilityTypesOptions[0].label} ( {info.socialImpactingHousingCount} projects)</b>
                      <br />
                      {affirdabilityTypesOptions[0].description}

                    </>
                  }
                  {type === 2 &&
                    <>
                      <b>  {affirdabilityTypesOptions[1].label} ( {info.focusOnAffordableHousingCount} projects)</b>
                      <br />
                      {affirdabilityTypesOptions[0].description}

                    </>
                  }
                  {type === 3 &&
                    <>
                      <b>  {affirdabilityTypesOptions[2].label} ( {info.balanceBetweenAffordableAndSupplyCount} projects)</b>
                      <br />
                      {affirdabilityTypesOptions[0].description}

                    </>
                  }
                  {type === 4 &&
                    <>
                      <b>  {affirdabilityTypesOptions[3].label} ( {info.focusOnHousingSupplyCount} projects)</b>
                      <br />
                      {affirdabilityTypesOptions[0].description}

                    </>
                  }
                </p>
              </>
            ))}
          <p>{t("developer.projects.add_listing_form.affordability_details_review")}</p>
          <p>{affordabilityData !== null && affordabilityData.affordabilityDescription}</p>
        </div>
      </div>
    </div>
  );
};
