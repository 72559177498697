import { useTranslation } from "react-i18next";
import HousingInfographic from "../../../assets/images/Housing_Infographic.png";
import styles from "./styles.module.scss";

export function ProblemStatementAbout() {
  const { t } = useTranslation("about");

  return (
    <div
      className={styles.problemStatement}
      data-testid="about-problem-statement"
    >
      <div className={styles.textContainer}>
        <h2 className={styles.heading} data-testid="statement-heading">
          {t("problemStatement.header")}
        </h2>
        <p className={styles.content} data-testid="statement-content">
          {t("problemStatement.text")}
        </p>
      </div>
      <div className={styles.imageContainer} data-testid="statement-image">
        <img
          className={styles.image}
          src={HousingInfographic}
          alt={t("problemStatement.image_text")}
        />
      </div>
    </div>
  );
}
