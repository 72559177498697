import { Modal } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Dispatch, SetStateAction, useState } from "react";
import styles from "./CreatePortfolio.module.scss";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";
import { CheckMarkPortfolio } from "../../../assets/icons";

type Props = {
    setCreatePortfolio: Dispatch<SetStateAction<boolean>>;
};

export function CreatePortfolio(props: Props) {
    const [openModal, setOpenModal] = useState(true);
    const { t } = useTranslation(["project","onboarding"]);
    const setCreatePortfolio = props.setCreatePortfolio;
    const navigate = useNavigate();

    const handleClose = () => {
        setOpenModal(false);
        setCreatePortfolio(false);
    };

    const confirmCreatePortfolio = async () => {
        navigate(`/portfolio/add`);
        handleClose();

    };

    return (
        <Modal open={openModal} onClose={handleClose}>
            <div
                className={styles.deleteprojectContainer}
            >
                <div className={styles.modalContentWrapper}>
                    <div className={styles.titleContainer} data-testid="title-container">
                        <img
                            src={CheckMarkPortfolio}
                            alt=""
                            className={styles.icon}
                            data-testid="exclamation-Icon"
                        />
                        <h2 className={styles.title} data-testid="pageTitle">
                            {t("onboarding:developer.createportfolio.modal_title")}                            
                        </h2>
                    </div>

                    <button
                        onClick={handleClose}
                        className={styles.crossIcon}
                        data-testid="close-button"
                    >
                        <CloseIcon />
                    </button>

                    <div
                        className={styles.removecontainer}
                    >
                        <p> {t("onboarding:developer.createportfolio.modal_paragraph")} </p>
                        {/* <p>By selecting "Create portfolio", you will be asked to fill out details about how you are seeking investment across multiple projects.</p> */}
                    </div>

                    <div className={styles.buttonsContainer}>
                        <button
                            className={styles.closeProjectButton}
                            onClick={confirmCreatePortfolio}
                        >
                            {t("onboarding:developer.createportfolio.sure")}
                        </button>
                        <button
                            className={styles.cancelButton}
                            onClick={handleClose}
                            data-testid="Cancel-button"
                        >
                            {t("project:project.delete_modal.cancel_btn")}
                        </button>

                    </div>
                    <div className={styles.checkboxContainer}>
                        <input
                            type="checkbox"
                            onChange={() => {
                                localStorage.setItem("SkipCreatePortfolioModal", "1");
                            }}
                        />   <label>{t("onboarding:developer.createportfolio.dont_show")}</label>
                    </div>
                </div>
            </div>
        </Modal>
    );
}
