import styles from "./PortfolioSummary.module.scss";
import { useTranslation } from "react-i18next";
import {
  getEnumKeyByValue,
  getImageOptions,
  IMAGE_TYPES,
  PROVINCES,
} from "../../../Constants/Enumerations";
import {
  PortfolioGeneralInfo,
  PortfolioProjectDetail,
} from "../../../TypesAndInterfaces/PortfolioDetailsInterface";
import { DeveloperDetails } from "../../../TypesAndInterfaces/DeveloperDetailsInterface";
import { RedCircleCrossIcon } from "../../../assets/icons";
import { CheckMarkPortfolio } from "../../../assets/icons/index";
import { ProjectStatus } from "../../../components/common/Cards/ProjectStatus";

type Props = {
  portfolioProjectDetail: PortfolioProjectDetail;
  generalInformation: PortfolioGeneralInfo;
  developerData: DeveloperDetails;
  portfolioStatus: number;
  closeReasonCode: number;
};

export function PortfolioSummary(props: Props) {
  const { t } = useTranslation(["portfolio"]);

  const imagesAltTextTranslated = Object.keys(IMAGE_TYPES).map((val) =>
    t(`common:enumerations.image_alt_text.${val}`)
  );
  const projectBannersOptions = getImageOptions(
    IMAGE_TYPES,
    imagesAltTextTranslated
  );
  const selectedImage = projectBannersOptions.find(
    (banner) => banner.value === "img_1"
  );

  const provinceMappedValue = getEnumKeyByValue(
    PROVINCES,
    props.developerData.generalInformation.province
  );

  const provinceTranslated = t(
    `common:enumerations.provinces.${provinceMappedValue}`
  );
  const totalProjectCount = t(
    "portfolio_details.portfolio_summary.num_of_projects",
    { totalProjectCount: props.portfolioProjectDetail.totalProjectCount }
  );
  const totalUnitPortfolioProject =
    props.portfolioProjectDetail?.totalUnitPortfolioProject;
  const belowAverageMarketRentUnitCount =
    props.portfolioProjectDetail?.belowAverageMarketRentUnitCount;

  return (
    <div>
      <div className={styles.infoContainer}>
        <div className={styles.container}>
          <div className={styles.topRight}>
            <p
              className={styles.orgNameContainer}
              data-testid="organization-Name"
            >
              <div>
                {" "}
                {props.developerData?.generalInformation?.organizationName}
              </div>
              <div
                className={`${
                  props.portfolioStatus === 2
                    ? styles.portfolioStatus + " " + styles.greenBackground
                    : styles.portfolioStatus + " " + styles.redBackground
                }`}
              >
                <ProjectStatus
                  status={props.portfolioStatus}
                  isPortfolio={true}
                />
              </div>
            </p>
            <h2 className={styles.portfolioName} data-testid="project-Name">
              {props.generalInformation.portfolioName}
            </h2>
            <p className={styles.orgName} data-testid="organization-Name">
              {props.developerData?.generalInformation?.city}{" "}
              {provinceTranslated}
            </p>
            <h4 className={styles.locationText} data-testid="location-Info">
              {(() => {
                const unitPortfolioProjectString =
                  totalUnitPortfolioProject !== null
                    ? `(${totalUnitPortfolioProject} ${t(
                        "cards:project_cards.units"
                      )})`
                    : "";
                const belowAverageMarketRentUnitCountString =
                  belowAverageMarketRentUnitCount !== null
                    ? `${belowAverageMarketRentUnitCount}% ${t(
                        "cards:project_cards.belowAvgRent"
                      )}`
                    : "";
                return `${totalProjectCount} ${unitPortfolioProjectString} ${belowAverageMarketRentUnitCountString}`;
              })()}
            </h4>
          </div>
        </div>
        <div className={styles.containerBottom}>
          <p className={styles.summaryTitle}>
            {t("portfolio_details.portfolio_summary.description")}
          </p>
          <p className={styles.summaryText} data-testid="project-summary">
            {props.generalInformation.portfolioDescription}
          </p>
        </div>
        {props.portfolioStatus === 3 ? (
          <button className={styles.closedProject}>
            <img
              src={
                props.closeReasonCode % 2 == 0
                  ? RedCircleCrossIcon
                  : CheckMarkPortfolio
              }
              alt={
                props.closeReasonCode % 2 == 0
                  ? t("portfolio_details.main_page.portfolio_closed.wrong_icon")
                  : t("portfolio_details.main_page.portfolio_closed.check_icon")
              }
              className={styles.crossIcon}
            />
            <p className={styles.closedProjectText}>
              {props.closeReasonCode % 2 == 0
                ? t("portfolio_details.main_page.portfolio_closed.closed")
                : t("portfolio_details.main_page.portfolio_closed.funded")}
            </p>
          </button>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}
