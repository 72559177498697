import { Pagination, PaginationItem } from "@mui/material";
import { useTranslation } from "react-i18next";
import styles from "./styles.module.scss";

type Props = {
  totalRecords: number;
  pageSize: number;
  setPage: Function;
  page?: number;
  start: number;
};

export function PaginationElement(props: Props) {
  const { t } = useTranslation("common");
  const totalPages = Math.ceil(props.totalRecords / props.pageSize);
  const maxItem =
    props.start + props.pageSize - 1 > props.totalRecords
      ? props.totalRecords
      : props.start + props.pageSize - 1;
  const handleChange = (event: any, value: number) => {
    props.setPage(value);
  };
  return (
    <div className={styles.paginationContainer}>
      <p className={styles.paginationTextInfo}>
        {props.start}-{maxItem} {t("pagination.of")} {props.totalRecords} {t("pagination.items")}
      </p>
      <Pagination
        className={styles.pagionationNav}
        count={totalPages || 1}
        variant="outlined"
        shape="rounded"
        page={props.page}
        onChange={handleChange}
        renderItem={(item) => (
          <PaginationItem
            {...item}
            classes={{ selected: styles.paginationSelectedBtn }}
          />
        )}
      />
    </div>
  );
}
