import { Modal } from "@mui/material";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { ConnectIcon } from "../../assets/icons";
import styles from "./styles.module.scss";
import { Form, Formik } from "formik";
import { useUser } from "../Context/UserContext";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { ConnectApi } from "../../APIAction/ConnectAPI";
import { DeveloperDetails } from "../../TypesAndInterfaces/DeveloperDetailsInterface";
import { ProjectDetails } from "../../TypesAndInterfaces/ProjectDetailsInterface";
import { InvestorAPI } from "../../APIAction/InvestorAPI";
import { InvestorGeneralInfo } from "../../TypesAndInterfaces/InvestorDetailsInterface";
import { ConnectionRequestCommonElements } from "./ConnectionRequestCommonElements";

type Props = {
  projectData: ProjectDetails;
  openModal: boolean;
  handleClose: () => void;
  developerInfo: DeveloperDetails;
  handleSubmitResponse: Function;
};

export function InvestorToDevRequest({
  projectData,
  openModal,
  handleClose,
  developerInfo,
  handleSubmitResponse,
}: Props) {
  const [InvestorInfo, setInvestorInfo] = useState<InvestorGeneralInfo>();
  const navigate = useNavigate();
  const { t } = useTranslation(["connect"]);
  const { userState } = useUser();
  const initialValues = {
    projectID: "",
    message: "",
  };
  const apiErrorPage = "/api-error";

  useEffect(() => {
    const getInvestorInfomation = async (id: string) => {
      try {
        const res = await InvestorAPI.getGeneralInfoById(id);
        setInvestorInfo(res.data);
      } catch (err) {
        if (axios.isAxiosError(err) && err.response?.status !== 404) {
          navigate(apiErrorPage);
        }
      }
    };
    if (userState.userPersona.toLowerCase() === "investor") {
      getInvestorInfomation(userState.userId);
    }
  }, [userState, navigate]);

  const InvestorToDevSchema = Yup.object().shape({
    message: Yup.string().max(
      1000,
      t("investorToDev.error.max_characters", { charNum: 1000 })
    ),
  });

  const submitConnectionRequest = async (requestData: {}) => {
    try {
      await ConnectApi.postConnectRequest(requestData);
      window.dataLayer.push({
        event: "request_connect",
        development_type: "project",
        request_type: "investor-to-developer",
      });
      handleSubmitResponse();
    } catch (err) {
      if (axios.isAxiosError(err) && err.response?.status !== 404) {
        navigate(apiErrorPage);
      }
    }
  };

  const handleSubmit = (values: { message: string }) => {
    const DeveloperInfo = {
      entityID: projectData.developerID,
      entityType: "developer",
      organizationName: developerInfo?.generalInformation?.organizationName,
      city: developerInfo?.generalInformation?.city,
      province: developerInfo?.generalInformation?.province,
      emailAddress:
        developerInfo?.generalInformation?.connectionEmailAddress !== null
          ? developerInfo?.generalInformation?.connectionEmailAddress
          : developerInfo?.generalInformation?.emailAddress,
      phoneNumber:
        developerInfo?.generalInformation?.connectionPhoneNumber !== null
          ? developerInfo?.generalInformation?.connectionPhoneNumber
          : developerInfo?.generalInformation?.phoneNumber,
    };
    const InvestorInformation = {
      entityID: userState.userId,
      entityType: "investor",
      organizationName: InvestorInfo?.organizationName,
      city: InvestorInfo?.city,
      province: InvestorInfo?.province,
      emailAddress: InvestorInfo?.emailAddress,
      phoneNumber: InvestorInfo?.phoneNumber,
    };

    const connectRequestData = {
      type: "connection",
      fromEntity: InvestorInformation,
      toEntity: DeveloperInfo,
      project: {
        projectListingID: projectData?.id,
        projectName: projectData?.generalInformation.projectName,
      },
      message: values.message,
    };
    developerInfo &&
      InvestorInfo &&
      submitConnectionRequest(connectRequestData);
  };

  return (
    <Modal open={openModal} onClose={handleClose}>
      <div
        className={styles.ConnectModalContainer}
        data-testid="connect-request-container"
      >
        <div className={styles.modalContentWrapper}>
          <div className={styles.header}>
            <img
              src={ConnectIcon}
              alt={t("investorToDev.requestModal.alt_text")}
              data-testid="connect-Icon"
            />
            <div className={styles.headerText} data-testid="header-text">
              <h2>{`${t("investorToDev.requestModal.heading")} 
                `}</h2>
              <p>{t("investorToDev.requestModal.summary")}</p>
              <p>{t("investorToDev.requestModal.summary1")}</p>
              <p>{t("investorToDev.requestModal.summary2")}</p>
            </div>
          </div>
          <hr className={styles.hrDivider}></hr>
          <div className={styles.contentContainer}>
            <Formik
              initialValues={initialValues}
              validateOnChange={false}
              validateOnBlur={false}
              validationSchema={InvestorToDevSchema}
              onSubmit={(values) => {
                handleSubmit(values);
              }}
            >
              <Form>
                <ConnectionRequestCommonElements
                  textAreaFieldNames={["message"]}
                  textAreaLabelText="investorToDev.requestModal.message_label"
                  cancelButtonHandler={handleClose}
                  cancelButtonText="investorToDev.requestModal.cancel_btn"
                  connectButtonText="investorToDev.requestModal.connect_btn"
                />
              </Form>
            </Formik>
          </div>
        </div>
      </div>
    </Modal>
  );
}
