import styles from "./connectionHub.module.scss";
import {
  CompletedIcon,
  PendingIcon,
  TakeActionIcon,
} from "../../assets/images";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { CardProps } from "./connectionHubProps";
import { useUser } from "../Context/UserContext";
import { ConnectApi } from "../../APIAction/ConnectAPI";
import {
  getEnumKeyByValue,
  PROVINCES,
  PROJECT_LISTING_STATUS,
  CONNECTION_STATUS_CODE,
} from "../../Constants/Enumerations";
import { CardDetailsConnected } from "./CardDetailsConnected";
import { CardDetailsPending } from "./CardDetailsPending";
import { CardStatus } from "./CardStatus";

const cardBorderColors = (status: any) => {
  if (status === 1) {
    return "0.5rem solid #447f00";
  } else if (status === 3) {
    return "0.5rem solid #F89100";
  } else {
    return "0.5rem solid #656565";
  }
};

export function ConnectionCard(props: CardProps) {
  const { t } = useTranslation(["common", "connectionHub"]);
  const navigate = useNavigate();
  const {
    connectionID,
    newConnectionHandler,
    projectListingStatus,
    connectionStatusCode,
    isProject,
    totalProjectCount,
  } = props;
  const { userState } = useUser();
  const cardBorderColor = cardBorderColors(props.connectionStatusCode);
  const isConnected = connectionStatusCode === CONNECTION_STATUS_CODE.Connected;
  const isPending = connectionStatusCode === CONNECTION_STATUS_CODE.Pending;

  const provinceKey = getEnumKeyByValue(PROVINCES, props.province);
  const provinceTranslatedText = t(
    `common:enumerations.provinces.${provinceKey}`
  );

  const entityType = props.entityTypeText.toLowerCase();

  async function AcceptRequest() {
    const requestTypeString = `${props.entityTypeText.toLowerCase()}-to-${userState.userPersona.toLowerCase()}`;
    if (connectionID && newConnectionHandler) {
      try {
        await ConnectApi.acceptConnectionRequest(connectionID);
        window.dataLayer.push({
          event: "approve_request_connect",
          development_type: isProject ? "portfolio" : "project",
          request_type: requestTypeString,
        });
        newConnectionHandler(connectionID);
        return;
      } catch (error) {
        return;
      }
    }
  }

  type StatusRenderValues = {
    icon: string;
    text: string;
    detailComponent: JSX.Element;
  };

  function renderConnectionContext(): StatusRenderValues {
    if (isConnected) {
      return {
        icon: CompletedIcon,
        text: t(
          "connectionHub:ConnectionHub.connectionTab.connectionStatusConnected"
        ),
        detailComponent: (
          <CardDetailsConnected
            emailAddress={props.emailAddress || ""}
            phoneNumber={props.phoneNumber || ""}
          />
        ),
      };
    }

    if (isPending) {
      return {
        icon: PendingIcon,
        text: t(
          "connectionHub:ConnectionHub.connectionTab.connectionStatusPending"
        ),
        detailComponent: <CardDetailsPending />,
      };
    }

    return {
      icon: TakeActionIcon,
      text: t(
        "connectionHub:ConnectionHub.connectionTab.connectionStatusNoConnection"
      ),
      detailComponent: (
        <button
          onClick={AcceptRequest}
          className={styles.approveButton}
          data-testid="card-approve-button"
          disabled={projectListingStatus === PROJECT_LISTING_STATUS.Deleted}
        >
          {t("connectionHub:ConnectionHub.connectionTab.approveButtonText")}
        </button>
      ),
    };
  }

  function renderPersonaSpecificHeadings() {
    if (props.entityTypeText.toLowerCase() === "developer") {
      return (
        <h2
          onClick={(e) => {
            e.preventDefault();
            navigate("/profile/developer/developerInfo", { state: props.organizationLink });
          }}
          data-testid="card-developer-heading"
          className={`${styles.cardHeading2} ${styles.headingText}`}
        >
          {props.organizationNameText}
        </h2>
      );
    }

    return (
      <Link
        to={`/profile/investor/${props.organizationLink}`}
        className={styles.cardHeading2}
        data-testid="card-investor-heading"
      >
        {props.organizationNameText}
      </Link>
    );
  }

  function projectStatusLinkCheck() {
    // If the status is deleted, it returns plain text otherwise it will return a link to the project
    if (projectListingStatus === PROJECT_LISTING_STATUS.Deleted) {
      return (
        <p className={styles.cardSubHeading} data-testid="card-subheading">
          {props.projectName}
        </p>
      );
    }

    return (
      <Link
        to={isProject ? `/portfolio/${props.projectListingID}` : `/projects/${props.projectListingID}`}
        className={styles.cardSubHeading}
        data-testid="card-subheading"
      >
        {props.projectName}
      </Link>
    );
  }

  return (
    <div className={styles.card} style={{ borderLeft: cardBorderColor }}>
      <CardStatus
        iconSrcToRender={renderConnectionContext().icon}
        statusTextTranslation={renderConnectionContext().text}
        entityType={entityType}
      />
      {renderPersonaSpecificHeadings()}
      {projectStatusLinkCheck()}
      <div className={styles.cardLocation} data-testid="card-province">
      {isProject && `${totalProjectCount&&totalProjectCount} Projects within`} {`${props.cityText}, ${provinceTranslatedText}`}
      </div>
      {renderConnectionContext().detailComponent}
    </div>
  );
}
