import { Formik, Form } from "formik";
import { useTranslation } from "react-i18next";
import styles from "./SearchFilterSidebar.module.scss";
import { Divider } from "@mui/material";
import { CheckBoxGroupAccordion } from "../../Form/common/CheckBoxGroupAccordion";
import {
  INVESTMENT_PHASES,
  FUNDING_TYPES,
  DOLLAR_RANGE,
  getKVArrayFormat,
  getEnumKeyByValue,
  SOCIAL_INDICATORS,
  GOVERNANCE_INDICATORS,
  ENVIRONMENTAL_INDICATORS,
  AFFORDABILITY_TYPES,
  PROVINCES,
  MIN_UNIT,
  MAX_UNIT,
  LAND_STATUS_PHASES,
  ORGANIZATION_TYPE,
  PROVINCES_CODES,
  PORTFOLIO_MIN_UNIT,
} from "../../../Constants/Enumerations";
import { SearchRangeInput } from "./SearchRangeInput";

import Select, { components, Options, OptionProps } from "react-select";
import { Social, Supply, Focus, Balance } from "../../../assets/icons";
import { Accordion, AccordionSummary } from "@mui/material";
import { ExpandMore } from "@mui/icons-material";
import { useEffect, useState } from "react";
import { GlossaryDefinition } from "../../../pages/Developers/Modals/GlossaryDefinitionModal/GlossaryDefinition";
import { City } from "country-state-city";


const customStyles = {
  control: (base: any) => ({
    ...base,
    minHeight: 35,
    maxHeight: 120,
    width: 240,
    maxWidth: "90%",
    marginLeft: "1rem",
    overflow: "auto",
  }),
};
export interface DropdownOptionType {
  label: string;
  value: string;
}
type Props = {
  setFilters: Function;
  setSelectedFilter?: Function;
  cityList: any;
  setCityList: Function;
  selectedFilter?: Object;
  initialSearchValues: Object;
  setPage: Function;
  impactCriteriaList?: Options<DropdownOptionType>;
  setImpactCriteriaList?: Function;
  isPortfolio: boolean;
  expandList: boolean[];
  setExpandList: Function;
};
type ImpactCriteriaInterface = {
  social?: string[];
  environmental?: string[];
  governance?: string[];
};
type DevelopmentImpactCriteriaInterface = {
  socialImpact?: string[];
  environmentalImpact?: string[];
  governanceImpact?: string[];
};

type ProjectFilterValues = {
  investmentPhases?: string[];
  fundingTypes?: string[];
  cities?: string[];
  financingRangeMinimum?: string;
  financingRangeMaximum?: string;
  totalFundingNeededMaximum?: number | null;
  totalFundingNeededMinimum?: number | null;
  impactCriteria?: ImpactCriteriaInterface;
  landStatuses?: string[];
  organizationTypes?: string[];
  projectAffordabilityTypes?: string[];
  developmentImpacts?: DevelopmentImpactCriteriaInterface;
  investmentRangeMinimum?: number | null;
  investmentRangeMaximum?: number | null;
  provinces?: string[];
  totalUnitMaximum?: string;
  totalUnitMinimum?: string;

};

type GAEvents = {
  [key: string]: any;
  event: string;
};

export function ProjectsFilterSidebar({
  setFilters,
  setSelectedFilter,
  selectedFilter,
  initialSearchValues,
  setPage,
  impactCriteriaList,
  setImpactCriteriaList,
  cityList,
  setCityList,
  isPortfolio,
  expandList,
  setExpandList,
}: Props) {
  const { t } = useTranslation("filter");
  const [isSetAffordabilityModal, setIsAffordabilityModal] = useState(false);
  const [isoCode, setIsoCode] = useState("");

  const getProvince = (selectedProvinces: any) => {
    if (selectedProvinces.length === 1) {
      const selectedProvince = PROVINCES_CODES.find((element) => element.value === Number(selectedProvinces[0]));
      if (selectedProvince) {
        setIsoCode(selectedProvince.isoCode);
      }
    } else {
      setIsoCode(""); // Set isoCode to empty string when no province is selected
      setCityList(null);
    }
  };

  const initialValues = {
    investmentPhases: [],
    fundingTypes: [],
    financingRangeMinimum: "",
    financingRangeMaximum: "",
    provinces: [],
    cities: [],
    totalUnitMinimum: "",
    totalUnitMaximum: "",
    projectAffordabilityTypes: [],
    impactCriteria: {
      social: [],
      environmental: [],
      governance: [],
    },
    developmentImpacts: {
      socialImpact: [],
      environmentalImpact: [],
      governanceImpact: [],
    },
    totalFundingNeededMaximum: null,
    totalFundingNeededMinimum: null,
    landStatuses: [],
    investmentRangeMinimum: null,
    investmentRangeMaximum: null,
  };

  const updateFilters = {
    start: 0,
    pageSize: 6,
    sortColumn: "",
    sortDirection: 0,
    filter: {},
  };

  const investmentPhasesTranslated = Object.keys(INVESTMENT_PHASES).map((val) =>
    t(`common:enumerations.investment_phases.${val}`)
  );
  const investmentPhasesOptions = getKVArrayFormat(
    INVESTMENT_PHASES,
    investmentPhasesTranslated
  );
  const lastElement :any = investmentPhasesOptions.pop();
  investmentPhasesOptions.unshift(lastElement);

  const affordabilityTypesTranslated = Object.keys(AFFORDABILITY_TYPES).map(
    (val) => t(`common:enumerations.affordabilityCriteria_type.${val}`)
  );

  const affordabilityTypes = getKVArrayFormat(
    AFFORDABILITY_TYPES,
    affordabilityTypesTranslated
  );
  const badges = [Social, Focus, Balance, Supply];

  const affordabilitywithBadge = affordabilityTypes.map((type, index) => ({
    ...type,
    src: badges[index % badges.length],
  }));

  const funingTypesTranslated = Object.keys(FUNDING_TYPES).map((val) =>
    isPortfolio ? t(`common:enumerations.portfolio_funding_types.${val}`): t(`common:enumerations.funding_types.${val}`)
  );
  const fundingOptions = getKVArrayFormat(FUNDING_TYPES, funingTypesTranslated);

  const landStatusTranslated = Object.keys(LAND_STATUS_PHASES).map((val) =>
    t(`common:enumerations.land_status.${val}`)
  );
  const landStatusOptions = getKVArrayFormat(
    LAND_STATUS_PHASES,
    landStatusTranslated
  );

  const dolllarRangesTrans = Object.keys(DOLLAR_RANGE).map((val) =>
    t(`common:enumerations.dollar_range.${val}`)
  );

  const minUnitTrans = Object.keys(MIN_UNIT).map((val) =>
    t(`common:enumerations.min_unit.${val}`)
  );

  const portfolioMinUnitTrans = Object.keys(MIN_UNIT).map((val) =>
    t(`common:enumerations.portfolio_min_unit.${val}`)
  );
  const maxUnitTrans = Object.keys(MAX_UNIT).map((val) =>
    t(`common:enumerations.max_unit.${val}`)
  );

  const provincesTranslated = Object.keys(PROVINCES).map((val) =>
    t(`common:enumerations.provinces_all_active_portfolios_filter.${val}`)
  );

  const provinceOptions = getKVArrayFormat(PROVINCES, provincesTranslated);
  const dollarPlaceholder = { label: t("selectPlaceHolder"), value: "" };
  const dollarOptions: { label: string; value: string | number }[] =
    getKVArrayFormat(DOLLAR_RANGE, dolllarRangesTrans).map(
      ({ label, value }) => ({
        label: label,
        value: value,
      })
    );
  dollarOptions.unshift(dollarPlaceholder);

  const totalUnitPlaceHolder = { label: t("projectFilter.anyNumber"), value: "" };
  const minUnit: { label: string; value: string | number }[] = getKVArrayFormat(
    MIN_UNIT,
    minUnitTrans
  ).map(({ label, value }) => ({
    label: label,
    value: value,
  }));
  minUnit.push(totalUnitPlaceHolder);

  const portfolioMinUnit: { label: string; value: string | number }[] = getKVArrayFormat(
    PORTFOLIO_MIN_UNIT,
    portfolioMinUnitTrans
  ).map(({ label, value }) => ({
    label: label,
    value: value,
  }));
  portfolioMinUnit.push(totalUnitPlaceHolder);

  const maxUnit: { label: string; value: string | number }[] = getKVArrayFormat(
    MAX_UNIT,
    maxUnitTrans
  ).map(({ label, value }) => ({
    label: label,
    value: value,
  }));
  maxUnit.push(totalUnitPlaceHolder);

  function handleGa(v: ProjectFilterValues) {
    const gaFilters: GAEvents = { event: "apply_filter" };
    // Flush related filters from Investor Search
    gaFilters["provincial_focus_area"] = undefined;
    gaFilters["target_roi_range"] = undefined;

    if (v.investmentPhases && v.investmentPhases.length > 0) {
      const investmentPhasesMappedValue = v.investmentPhases.map((val) =>
        getEnumKeyByValue(INVESTMENT_PHASES, Number(val))
      );
      const investmentPhasesTranslated = investmentPhasesMappedValue.map(
        (val) => t(`common:enumerations.investment_phases.${val}`)
      );
      investmentPhasesTranslated.sort();
      gaFilters["project_phase"] = investmentPhasesTranslated.join(", ");
    }
    if (v.fundingTypes && v.fundingTypes.length > 0) {
      const fundingTypesMappedValues = v.fundingTypes.map((val) =>
        getEnumKeyByValue(FUNDING_TYPES, Number(val))
      );
      const fundingTypesTranslated = fundingTypesMappedValues.map((val) =>
        isPortfolio ? t(`common:enumerations.portfolio_funding_types.${val}`): t(`common:enumerations.funding_types.${val}`)
      );
      fundingTypesTranslated.sort();
      gaFilters["type_of_funding"] = fundingTypesTranslated.join(", ");
    }
    if (v.financingRangeMinimum !== "" || v.financingRangeMaximum !== "") {
      const targetRoiMaxMappedValue = getEnumKeyByValue(
        DOLLAR_RANGE,
        Number(v.financingRangeMaximum)
      );
      const targetRoiMinMappedValue = getEnumKeyByValue(
        DOLLAR_RANGE,
        Number(v.financingRangeMinimum)
      );
      const targeRoiMinTranslated = t(
        `common:enumerations.dollar_range.${targetRoiMinMappedValue}`
      );
      const targeRoiMaxTranslated = t(
        `common:enumerations.dollar_range.${targetRoiMaxMappedValue}`
      );
      let targeRoiTranslated = `${targeRoiMinTranslated}, ${targeRoiMaxTranslated}`;

      if (v.financingRangeMinimum === "") {
        targeRoiTranslated = targeRoiMaxTranslated;
      } else if (v.financingRangeMaximum === "") {
        targeRoiTranslated = targeRoiMinTranslated;
      }
      gaFilters["financing_range"] = targeRoiTranslated;
    }

    if (v.landStatuses && v.landStatuses.length > 0) {
      const landStatusMappedValue = v.landStatuses.map((val) =>
        getEnumKeyByValue(LAND_STATUS_PHASES, Number(val))
      );
      const landStatusTranslatedGa = landStatusMappedValue.map((val) =>
        t(`common:enumerations.land_status.${val}`)
      );
      landStatusTranslatedGa.sort();
      gaFilters["land_status"] = landStatusTranslatedGa.join(", ");
    }

    if (v.organizationTypes && v.organizationTypes.length > 0) {
      const organizationTypesMappedValue = v.organizationTypes.map((val) =>
        getEnumKeyByValue(ORGANIZATION_TYPE, Number(val))
      );
      const organizationTypesTranslatedGa = organizationTypesMappedValue.map((val) =>
        t(`common:enumerations.organization_type.${val}`)
      );
      organizationTypesTranslatedGa.sort();
      gaFilters["organization_type"] = organizationTypesTranslatedGa.join(", ");
    }

    if (v.projectAffordabilityTypes && v.projectAffordabilityTypes.length > 0) {
      const affordabilityTypesMappedValue = v.projectAffordabilityTypes.map((val) =>
        getEnumKeyByValue(AFFORDABILITY_TYPES, Number(val))
      );
      const affordabilityTypesTranslatedGa = affordabilityTypesMappedValue.map((val) =>
        t(`common:enumerations.affordabilityCriteria_type.${val}`)
      );
      affordabilityTypesTranslatedGa.sort();

      gaFilters["affordability_type"] = affordabilityTypesTranslatedGa.join(", ");

    }

    if (v.cities && v.cities.length > 0) {
      const cities = v.cities;
      cities.sort();
      gaFilters["city"] = cities.join(", ");

    }
    if (v.provinces && v.provinces.length > 0) {
      const provincesMappedValue = v.provinces.map((val) =>
        getEnumKeyByValue(PROVINCES, Number(val))
      );

      const provincesTranslatedGa = provincesMappedValue.map((val) =>
        t(`common:enumerations.provinces.${val}`)
      );
      provincesTranslatedGa.sort();
      gaFilters["province"] = provincesTranslatedGa.join(", ");
    }

    if (v.totalUnitMaximum !== "" || v.totalUnitMinimum !== "") {

      const totalUnitMinTranslated = v.totalUnitMinimum ?? "";

      const totalUnitMaxTranslated = v.totalUnitMaximum ?? "";
      ;
      let totalUnitTranslated = `${totalUnitMinTranslated}, ${totalUnitMaxTranslated}`;

      if (v.totalUnitMinimum === "") {
        totalUnitTranslated = totalUnitMaxTranslated;
      } else if (v.totalUnitMaximum === "") {
        totalUnitTranslated = totalUnitMinTranslated;
      }
      gaFilters["total_units"] = totalUnitTranslated;
    }

    if (v.impactCriteria && v.impactCriteria.environmental && v.impactCriteria.governance && v.impactCriteria.social) {
      const esgImpact = v.impactCriteria.environmental.concat(v.impactCriteria.governance, v.impactCriteria.social);
      if (esgImpact.length > 0) {
        gaFilters["ESG_impact_criteria"] = esgImpact.join(", ");
      }
    }

    if (v.developmentImpacts && v.developmentImpacts.environmentalImpact && v.developmentImpacts.governanceImpact && v.developmentImpacts.socialImpact) {
      const esgImpact = v.developmentImpacts.environmentalImpact.concat(v.developmentImpacts.governanceImpact, v.developmentImpacts.socialImpact);
      if (esgImpact.length > 0) {
        gaFilters["ESG_impact_criteria"] = esgImpact.join(", ");
      }
    }
    
    window.dataLayer.push(gaFilters);
  }

  const CustomOption = (innerProps: OptionProps<DropdownOptionType, true>) => {
    const style = {
      alignItems: "center",
      backgroundColor: "red",
      color: "inherit",
      display: "flex ",
    };

    // prop assignment
    const temp = {
      ...innerProps,
      style,
    };

    return (
      <components.Option
        {...temp}
        isDisabled={innerProps.isDisabled}
        isFocused={innerProps.isFocused}
        isSelected={innerProps.isSelected}
        className={
          innerProps.isSelected
            ? styles.optionSelected
            : styles.optionUnselected
        }
      >
        <input
          type="checkbox"
          checked={innerProps.isSelected}
          className={styles.checkboxStyle}
        />
        &nbsp;{innerProps.children}
      </components.Option>
    );
  };

  function resetGa() {
    const gaFilters: GAEvents = { event: "apply_filter" };

    // Flush related filters from Investor Search
    gaFilters["provincial_focus_area"] = undefined;
    gaFilters["target_roi_range"] = undefined;

    // Reset this component's filter values
    gaFilters["project_phase"] = undefined;
    gaFilters["type_of_funding"] = undefined;
    gaFilters["financing_range"] = undefined;

    window.dataLayer.push(gaFilters);
  }

  interface SocialImpactOption {
    readonly value: string;
    readonly label: string;
  }

  const socialIndicatorsTranslations = Object.keys(SOCIAL_INDICATORS).map(
    (val) => {
      return {
        value: `s${val}`,
        label: t(`impact_criteria.social.social_indicators.${val}`),
      };
    }
  );

  const governanceIndicatorsTranslations = Object.keys(
    GOVERNANCE_INDICATORS
  ).map((val) => {
    return {
      value: `g${val}`,
      label: t(`impact_criteria.governance.governance_indicators.${val}`),
    };
  });

  const environmentalIndicatorsTranslations = Object.keys(
    ENVIRONMENTAL_INDICATORS
  ).map((val) => {
    return {
      value: `e${val}`,
      label: t(`impact_criteria.environmental.environmental_indicators.${val}`),
    };
  });


  const OrganizationTypeTranslations = Object.keys(ORGANIZATION_TYPE).filter(val => val !== undefined && val !== "").map(
    (val) => t(`common:enumerations.organization_type_all_active_portfolios_filter.${val}`)
  );
  const OrganizationTypeOptions = getKVArrayFormat(
    ORGANIZATION_TYPE,
    OrganizationTypeTranslations
  );

  const OrganizationTypeOptionsAllActivePortfolios = [
    OrganizationTypeOptions[0],
    OrganizationTypeOptions[1],
    OrganizationTypeOptions[3],
    OrganizationTypeOptions[2]
  ];

  interface GovernanceImpactOption {
    readonly value: string;
    readonly label: string;
  }

  interface GroupedOption {
    readonly label: string;
    readonly options:
    | readonly SocialImpactOption[]
    | readonly GovernanceImpactOption[];
  }

  const groupedOptions: readonly GroupedOption[] = [
    {
      label: t(`impact_criteria.environmental.group_title`),
      options: environmentalIndicatorsTranslations.sort((a, b) =>
        a.label.localeCompare(b.label)
      ),
    },
    {
      label: t(`impact_criteria.social.group_title`),
      options: socialIndicatorsTranslations.sort((a, b) =>
        a.label.localeCompare(b.label)
      ),
    },
    {
      label: t(`impact_criteria.governance.group_title`),
      options: governanceIndicatorsTranslations.sort((a, b) =>
        a.label.localeCompare(b.label)
      ),
    },
  ];

  const handleChange = (index: number) => {
    setExpandList((prevExpandList: any) => {
      const newExpandList = [...prevExpandList];
      newExpandList[index] = !newExpandList[index];
      return newExpandList;
    });
  };

  const handleCityChange = (selectedOptions: any) => {
    if (selectedOptions && selectedOptions.length <= 15) {
      setCityList(selectedOptions);
    }
  };

  return (
    <div
      className={styles.sidebarContainer}
      data-testid="project-sidebar-container"
    >
      <h2 className={styles.textSpacing} data-testid="project-sidebar-heading">
        {isPortfolio ? t("projectFilter.portfolio_filter_title") : t("projectFilter.title")}
      </h2>
      <p
        className={styles.textPaddingNoTop}
        data-testid="project-sidebar-description"
      >
        { (isPortfolio === false)? t("projectFilter.filterDescription") : t("projectFilter.filterDescription_portfolio")}
      </p>
      <Divider light />

      <Formik
        initialValues={selectedFilter || initialValues}
        enableReinitialize={true}
        onSubmit={(values: ProjectFilterValues) => {
          let selectedSocialImpact = [];
          let selectedGovernanceImpact = [];
          let selectedEnvironmentalImpact = [];
          let selectedCities: string[] = [];

          if (cityList) {
            cityList.map((city: any) => (
              selectedCities.push(city.name)
            )
            )
          }
          //@ts-ignore
          values.cities = selectedCities;
          if (impactCriteriaList) {
            for (let i = 0; i < impactCriteriaList.length; i++) {
              const socialImpactitem = groupedOptions[1].options.filter(
                (e) => e.label === impactCriteriaList[i].label
              );
              if (socialImpactitem.length > 0) {
                selectedSocialImpact.push(socialImpactitem[0].label);
              }

              const governanceImpactitem = groupedOptions[2].options.filter(
                (e) => e.label === impactCriteriaList[i].label
              );
              if (governanceImpactitem.length > 0) {
                selectedGovernanceImpact.push(governanceImpactitem[0].label);
              }

              const environmentalImpactitem = groupedOptions[0].options.filter(
                (e) => e.label === impactCriteriaList[i].label
              );

              if (environmentalImpactitem.length > 0) {
                selectedEnvironmentalImpact.push(
                  environmentalImpactitem[0].label
                );
              }
            }
          }

          if (isPortfolio === false) {
            values.totalFundingNeededMaximum =
              values.financingRangeMaximum !== ""
                ? Number(values.financingRangeMaximum)
                : null;
            values.totalFundingNeededMinimum =
              values.financingRangeMinimum !== ""
                ? Number(values.financingRangeMinimum)
                : null;

            //@ts-ignore
            values.impactCriteria.social = selectedSocialImpact;
            //@ts-ignore
            values.impactCriteria.governance = selectedGovernanceImpact;
            //@ts-ignore
            values.impactCriteria.environmental = selectedEnvironmentalImpact;
            updateFilters.filter = values;
          } else {
            values.investmentRangeMaximum =
              values.financingRangeMaximum !== ""
                ? Number(values.financingRangeMaximum)
                : null;
            values.investmentRangeMinimum =
              values.financingRangeMinimum !== ""
                ? Number(values.financingRangeMinimum)
                : null;

            //@ts-ignore
            values.developmentImpacts.socialImpact = selectedSocialImpact;
            //@ts-ignore
            values.developmentImpacts.governanceImpact =
              selectedGovernanceImpact;
            //@ts-ignore
            values.developmentImpacts.environmentalImpact =
              selectedEnvironmentalImpact;
            updateFilters.filter = values;
          }

          handleGa(values);
          setFilters(updateFilters);
          setSelectedFilter && setSelectedFilter(updateFilters.filter);
          setPage(1);
        }}
        onReset={() => {
          setFilters(initialSearchValues);
          setImpactCriteriaList && setImpactCriteriaList([]);
          setSelectedFilter && setSelectedFilter(null);

          setPage(1);
          resetGa();
        }}
      >
        {(values) => (
          <Form className={styles.projectFilterSideBar}>
            <div className={styles.checkBoxGroupStyle}>
              <CheckBoxGroupAccordion
                fieldName="projectAffordabilityTypes"
                formQuestionText={t("projectFilter.affordabilityCriteria")}
                options={affordabilitywithBadge}
                data-testid="affordability"
                linkText={t("projectFilter.affordabilityGlossaryLink")}
                setIsOpenModal={setIsAffordabilityModal}
                expanded={expandList[0]}
                onChange={(index) => {
                  setSelectedFilter && setSelectedFilter(values.values);
                  handleChange(index)
                }}
                index={0}
              />
              {isSetAffordabilityModal && (
                <GlossaryDefinition
                  setCloseGlossaryModal={setIsAffordabilityModal}
                  title={t("projectFilter.affordabilityGlossary.title")}
                  glossaryContent={t(
                    "projectFilter.affordabilityGlossary.affordabilityGlossaryContent",
                    {
                      returnObjects: true,
                    }
                  )}
                  paraContent={t(
                    "projectFilter.affordabilityGlossary.affordabilityParaContent",
                    {
                      returnObjects: true,
                    }
                  )}
                  glossaryLink={t(
                    "projectFilter.affordabilityGlossary.viewFullGlossaryLink"
                  )}
                  closeText={t("projectFilter.affordabilityGlossary.closeButton")}
                />
              )}
                <Accordion
                  sx={{ borderBottom: "1px solid #cccccc" }}
                  expanded={expandList[1]}
                  onChange={() => {
                    setSelectedFilter && setSelectedFilter(values.values);
                    handleChange(1);
                  }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMore className={styles.icon} />}
                  >
                    <h3 className={styles.formQuestionText}>
                      {t("projectFilter.totalUnits")}
                    </h3>
                  </AccordionSummary>
                  <div className={styles.selectInputStyle}>
                    <SearchRangeInput
                      fieldName="totalUnitMinimum"
                      labelText={t("projectFilter.minAmount")}
                      options={isPortfolio ? portfolioMinUnit : minUnit}
                      data-testid="totalUnitMin"
                    />
                    <SearchRangeInput
                      fieldName="totalUnitMaximum"
                      labelText={t("projectFilter.maxAmount")}
                      options={maxUnit}
                      data-testid="totalUnitMax"
                    />
                  </div>
                </Accordion>
              <CheckBoxGroupAccordion
                fieldName="provinces"
                formQuestionText={t("projectFilter.provinces")}
                options={provinceOptions}
                data-testid="province"
                expanded={expandList[2]}
                onCheckBoxClick={getProvince(values.values.provinces!)!}
                onChange={(index) => {
                  setSelectedFilter && setSelectedFilter(values.values);
                  handleChange(index)
                }}
                index={2}
              />
              <Accordion sx={{
                borderBottom: "1px solid #cccccc"
              }}
                expanded={expandList[9]}
                onChange={() => {
                  setSelectedFilter && setSelectedFilter(values.values);
                  handleChange(9);
                }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMore className={styles.icon} />}
                >
                  <h3 className={styles.formQuestionText}>
                    {t("projectFilter.cities")}
                  </h3>
                </AccordionSummary>
                <p className={styles.cityCaption}>{t("projectFilter.cityCaption")}</p>
                <div className={styles.multiSelectPadding}>
                  <Select
                    isDisabled={isoCode === ""}
                    isMulti={true}
                    name="cityList"
                    value={cityList && cityList}
                    isClearable={true}
                    // @ts-ignore
                    options={
                      City.getCitiesOfState(
                        "CA",
                        isoCode
                      )}
                    onChange={(list: Options<DropdownOptionType>) => {
                      setSelectedFilter && setSelectedFilter(values.values);
                      handleCityChange(list);
                    }}
                    getOptionLabel={(options) => {
                      //@ts-ignore
                      return options["name"];
                    }}
                    getOptionValue={(options: any) => {
                      return options["name"];
                    }}
                    components={{
                      Option: CustomOption,
                    }}
                    openMenuOnFocus={true}
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    placeholder={t("impact_criteria.placeholder")}
                    styles={customStyles}
                    isSearchable={true}
                  />
                </div>
              </Accordion>
              {!isPortfolio && (
                <CheckBoxGroupAccordion
                  fieldName="investmentPhases"
                  formQuestionText={t("projectFilter.projectPhase")}
                  options={investmentPhasesOptions}
                  data-testid="investment-phases"
                  expanded={expandList[3]}
                  onChange={(index) => {
                    setSelectedFilter && setSelectedFilter(values.values);
                    handleChange(index)
                  }}
                  index={3}
                />
              )}

              <CheckBoxGroupAccordion
                fieldName="fundingTypes"
                formQuestionText={t("projectFilter.fundingTypes")}
                options={fundingOptions}
                data-testid="capital-funding-type"
                expanded={expandList[4]}
                onChange={(index) => {
                  setSelectedFilter && setSelectedFilter(values.values);
                  handleChange(index)
                }}
                index={4}
              />
              <Accordion sx={{
                borderBottom: "1px solid #cccccc"
              }}
                expanded={expandList[5]}
                onChange={() => {
                  setSelectedFilter && setSelectedFilter(values.values);
                  handleChange(5);
                }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMore className={styles.icon} />}
                >
                  <h3 className={styles.formQuestionText}>
                    {t("impact_criteria.heading")}
                  </h3>
                </AccordionSummary>
                <div className={styles.multiSelectPadding}>
                  <Select
                    isMulti={true}
                    name="impactCriteriaList"
                    value={impactCriteriaList && impactCriteriaList}
                    isClearable={true}
                    // @ts-ignore
                    options={groupedOptions}
                    onChange={(list: Options<DropdownOptionType>) => {
                      setSelectedFilter && setSelectedFilter(values.values);
                      setImpactCriteriaList && setImpactCriteriaList(list);
                    }}
                    components={{
                      Option: CustomOption,
                    }}
                    openMenuOnFocus={true}
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    placeholder={t("impact_criteria.placeholder")}
                    styles={customStyles}
                    isSearchable={true}
                  />
                </div>
              </Accordion>
              <CheckBoxGroupAccordion
                fieldName="organizationTypes"
                formQuestionText={isPortfolio ? t("projectFilter.organizationType") : t("projectFilter.project_organizationType")}
                options={isPortfolio ? OrganizationTypeOptionsAllActivePortfolios : OrganizationTypeOptions}
                data-testid="land-status-type"
                expanded={expandList[8]}
                onChange={(index) => {
                  setSelectedFilter && setSelectedFilter(values.values);
                  handleChange(index)
                }}
                index={8}
              />
              {!isPortfolio && (
                <CheckBoxGroupAccordion
                  fieldName="landStatuses"
                  formQuestionText={t("projectFilter.landStatus")}
                  options={landStatusOptions}
                  data-testid="land-status-type"
                  expanded={expandList[7]}
                  onChange={(index) => {
                    setSelectedFilter && setSelectedFilter(values.values);
                    handleChange(index)
                  }}
                  index={7}
                />
              )}
            </div>
            <Accordion
              sx={{
                borderBottom: "1px solid #cccccc",
              }}
              expanded={expandList[6]}
              onChange={() => {
                setSelectedFilter && setSelectedFilter(values.values);
                handleChange(6);
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMore className={styles.icon} />}
              >
                <h3 className={styles.formQuestionText}>
                  {t("projectFilter.financingRange")}
                </h3>
              </AccordionSummary>
              <div className={styles.selectInputStyle}>
                <SearchRangeInput
                  fieldName="financingRangeMinimum"
                  labelText={t("projectFilter.capitalMinAmount")}
                  options={dollarOptions}
                  data-testid="capitalCostRangeMin"
                />
                <SearchRangeInput
                  fieldName="financingRangeMaximum"
                  labelText={t("projectFilter.capitalMaxAmount")}
                  options={dollarOptions}
                  data-testid="capitalCostRangeMax"
                />
              </div>
            </Accordion>
            <button
              type="reset"
              className={styles.formButton2}
              data-testid="reset-button"
            >
              {t("buttonFilterClear")}
            </button>
            <button
              type="submit"
              className={styles.formButton}
              data-testid="submit-button"
            >
              {t("buttonFilterTextFormProject")}
            </button>
          </Form>
        )}
      </Formik>
    </div>
  );
}
