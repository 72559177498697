import { CheckCircle, RemoveCircle } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import styles from "./styles.module.scss";
import editIcon from "../../../assets/icons/cards/Edit_icon.svg";

type Props = {
  status: number;
  isPortfolio?: boolean;
};

export function ProjectStatus({ status, isPortfolio }: Props) {
  const { t } = useTranslation("common");
  // 1-Draft, 2-Active, 3-Cancelled, 4-Deleted
  switch (status) {
    case 2:
      return (
        <>
          <CheckCircle className={styles.statusActive} />
          <p className={styles.statusText}>
            {isPortfolio
              ? t("enumerations.portfolio_listing_status.Active")
              : t("enumerations.project_listing_status.Active")}
          </p>
        </>
      );

    case 3:
      return (
        <>
          <RemoveCircle className={styles.statusClosed} />
          <p className={styles.statusText}>
            {isPortfolio
              ? t("enumerations.portfolio_listing_status.Cancelled")
              : t("enumerations.project_listing_status.Cancelled")}
          </p>
        </>
      );

    case 4:
      return (
        <>
          <RemoveCircle className={styles.statusClosed} />
          <p className={styles.statusText}>
            {isPortfolio
              ? t("enumerations.portfolio_listing_status.Deleted")
              : t("enumerations.project_listing_status.Deleted")}
          </p>
        </>
      );

    default:
      return (
        <>
          <img src={editIcon} className={styles.statusDraft} alt="" />
          <p className={styles.statusText}>
            {isPortfolio
              ? t("enumerations.portfolio_listing_status.Draft")
              : t("enumerations.project_listing_status.Draft")}
          </p>
        </>
      );
  }
}
