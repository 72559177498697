import styles from "./FinancialDetails.module.scss";
import { useTranslation } from "react-i18next";

type Props = {
  highlightLabel: string;
  highlightValue: string;
  projectAffordabilityType?: number;
  totalNumberOfUnitsPercent?: number;
};

export function FinancialDetailHighlightSingle(props: Props) {
  const { t } = useTranslation(["project"]);

  return (
    <div className={styles.highlightContainer}>
      {props.projectAffordabilityType === 1 ? (
        <>
          <p className={styles.highlightLabel}>{props.highlightLabel}</p>
          <p className={styles.highlightValue}>{`${props.highlightValue} (${t("project.details.one_hundred_percent")})`}</p>
        </>
      ) : props.projectAffordabilityType === 2 || props.projectAffordabilityType === 3 || props.projectAffordabilityType === 4 ? (
        <>
          <p className={styles.highlightLabel}>{props.highlightLabel}</p>

          <p className={styles.highlightValue}>
            {`${props.highlightValue} (${
              props.totalNumberOfUnitsPercent !== undefined ? `${props.totalNumberOfUnitsPercent.toFixed(0)} ${t("project.details.affordable_percent")})` : ""
            }`}
          </p>
        </>
      ) : (
        <>
          <p className={styles.highlightLabel}>{props.highlightLabel}</p>
          <p className={styles.highlightValue}>{props.highlightValue}</p>
        </>
      )}
    </div>
  );
}
