import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { City } from "country-state-city";
import { useField } from "formik";
import { SelectInput } from "./SelectInput";
import { PROVINCES_CODES } from "../../../Constants/Enumerations";
import { SelectCityInput } from "./SelectCityInput";

type Props = {
  fieldName: string;
  province?: any;
  labelText?: string;
  placeholder?: string;
  backendCity?: string;
  city?: string;
};
type Option = {
  label: string;
  value: string;
}

export function CitySelect({ fieldName, province, labelText, placeholder }: Props) {
  const { t } = useTranslation("onboarding");
  const [cityList, setCityList] = useState<{ label: string; value: string }[]>([{label: "", value: ""}]);
  const [selectedCity, setSelectedCity] = useState<string>("");

  useEffect(() => {
    // Reset selected city when province changes
    if (province) {
      const selectedProvince = PROVINCES_CODES.find((element) => element.value === Number(province));
      if (selectedProvince) {
        const isoCode = selectedProvince.isoCode;
        const cityList = City.getCitiesOfState("CA", isoCode);
        const formattedCityOptions = cityList.map(city => ({ label: city.name, value: city.name }));
        setCityList([{ label: placeholder ? placeholder : '',
        value: "" }, ...formattedCityOptions]);
      }
    } else {
      // Clear city list when province is empty
      setCityList([]);
    }
    setSelectedCity("");
  }, [province]);
  

  return (
    <SelectCityInput
      fieldName={fieldName}
      labelText={labelText || t("investor.general_info_form.province_label")}
      options={cityList}
      value={selectedCity}
      data-testid="city"
      province={province}
      onChange={(selectedOption: any) => setSelectedCity(selectedOption)}
    />
  );
}
