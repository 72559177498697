import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { PageLayout } from "../../../components/Layout/PageLayout";
import { PageContent } from "../../../components/Layout/PageContent";
import styles from "./ReviewInvestor.module.scss";
import { ReviewGeneralInfo } from "./ReviewGeneralInfo";
import { ReviewInvestorInformation } from "./ReviewInvestorInformation";
import { ReviewPreferences } from "./ReviewPreferences";
import { getKVArrayFormat, PROVINCES } from "../../../Constants/Enumerations";
import { ReviewInvestorOrgImpactInfo } from "./ReviewInvestorOrgImpactInfo";

export const ReviewInvestorProfile = () => {
  const { t } = useTranslation("onboarding");
  const [esgSelection, setEsgSelection] = useState("");

  const navigate = useNavigate();

  const handleConfirm = () => {
    window.dataLayer.push({
      event: "investor_sign_up",
      esg_criteria: esgSelection,
    });

    navigate("/onboarding/investor/success");
  };

  const provincesTranslated = Object.keys(PROVINCES).map((val) =>
    t(`common:enumerations.provinces.${val}`)
  );
  const provinceOptions = getKVArrayFormat(PROVINCES, provincesTranslated);

  return (
    <PageLayout>
      <PageContent>
        <div className={styles.container}>
          <div className={styles.headerContainer}>
            <h2
              className={styles.mainHeading}
              data-testid="review-profile-heading"
            >
              {t("investor.review_profile.main_heading")}{" "}
            </h2>
            <p className={styles.subHeading}>
              {t("investor.review_profile.info")}
            </p>
          </div>
          <div
            className={styles.contentSection}
            data-testid="review-general-info"
          >
            <ReviewGeneralInfo provinceOptions={provinceOptions} />
          </div>
          <div
            className={styles.contentSection}
            data-testid="review-investment-info"
          >
            <ReviewInvestorInformation provinceOptions={provinceOptions} />
          </div>
          <div className={styles.contentSection} data-testid="review-pref-info">
            <ReviewPreferences provinceOptions={provinceOptions} />
          </div>
          <div
            className={styles.contentSection}
            data-testid="review-investor-orgimpact-info"
          >
            <ReviewInvestorOrgImpactInfo setEsgSelection={setEsgSelection} />
          </div>
          <div className={styles.buttonContainer}>
            <button
              type="submit"
              className={styles.formButton}
              onClick={handleConfirm}
              data-testid="confirm-btn"
            >
              {t("investor.review_profile.confirm_button")}
            </button>
          </div>
        </div>
      </PageContent>
    </PageLayout>
  );
};
