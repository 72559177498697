import { Modal } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Dispatch, SetStateAction, useState } from "react";
import { DeleteIcon } from "../../../assets/icons";
import styles from "./DeleteProject.module.scss";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";
import { ListingPortfolioAPI } from "../../../APIAction/ListingPortfolioAPI";

type Props = {
  setDeleteProject: Dispatch<SetStateAction<boolean>>;
  portfolioId: string;
};

export function DeletePortfolio(props: Props) {
  const [openModal, setOpenModal] = useState(true);
  const { t } = useTranslation(["project"]);
  const setDeleteProject = props.setDeleteProject;
  const portfolioId = props.portfolioId;
  const navigate = useNavigate();

  const handleClose = () => {
    setOpenModal(false);
    setDeleteProject(false);
  };

  const deleteProject = async () => {
    try {
      await ListingPortfolioAPI.deletePortfolioByID(portfolioId);
      handleClose();
      navigate("/portfolio/delete");
    } catch (error) {
      navigate("/api-error");
    }
  };

  return (
    <Modal open={openModal} onClose={handleClose}>
      <div
        className={styles.deleteprojectContainer}
        data-testid="deleteProject-container"
      >
        <div className={styles.modalContentWrapper}>
          <div className={styles.termsModalcloseicon}>
            <button
              onClick={handleClose}
              className={styles.termsModalCloseButton}
              data-testid="close-button"
            >
              <CloseIcon />
            </button>
          </div>
          <img
            src={DeleteIcon}
            className={styles.deleteicon}
            alt={t("project:project.delete_modal.alt_erroricon")}
            data-testid="delete-Icon"
          />
          <div
            className={styles.deletecontainer}
            data-testid="delete-Container"
          >
            <h2>{t("project:project.delete_modal.portfolio_deleteHeading")}</h2>
            <p>{t("project:project.delete_modal.portfolio_summary")}</p>
          </div>
          <div className={styles.buttonsContainer}>
            <button
              className={styles.cancelButton}
              onClick={handleClose}
              data-testid="Cancel-button"
            >
              {t("project:project.delete_modal.cancel_btn")}
            </button>
            <button
              className={styles.closeProjectButton}
              onClick={deleteProject}
              data-testid="deleteProject-button"
            >
              {t("project:project.delete_modal.delete_btn")}
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
}
