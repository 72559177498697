import { OpenInNew } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { HashLink as Link } from "react-router-hash-link";
import logoCanada from "../../../assets/images/Canada_logo.svg";
import styles from "./styles.module.scss";

export function Footer() {
  const { t } = useTranslation("common");

  const footerLinks = [
    {
      text: t("footerLinks.FAQs"),
      path: "/resources#faqs",
      external: false,
    },
    {
      text: t("footerLinks.Glossary"),
      path: "/resources#glossary",
      external: false,
    },
    {
      text: t("footerLinks.PrivacyPolicy"),
      path: t("footerLinks.PrivacyPolicy_url"),
      external: true,
    },
    {
      text: t("footerLinks.TermsandConditions"),
      path: "/terms",
      external: false,
    },
    {
      text: t("footerLinks.Transparency"),
      path: t("footerLinks.Transparency_url"),
      external: true,
    },
    {
      text: t("footerLinks.CMHCCorporateSite"),
      path: t("footerLinks.CMHCCorporateSite_url"),
      external: true,
    },
  ];

  return (
    <footer className={styles.footer}>
      <div className={styles.footerContent}>
        <ul className={styles.footerLinksList}>
          {footerLinks.map((link, idx) => (
            <li key={idx} className={styles.footerLinkListItem}>
              {link.external ? (
                <a
                  href={link.path}
                  className={styles.linkStyle}
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={() => {
                    if(link.path === "/resources#glossary") {
                      localStorage.setItem("glossarySelectedLinkId", "#1");
                    }
                  }}
                >
                  {link.text} <OpenInNew sx={{ height: "1rem" }} />
                </a>
              ) : (
                <Link
                  smooth
                  to={link.path}
                  className={styles.linkStyle}
                  rel="noopener noreferrer"
                  onClick={() => {
                    if(link.path === "/resources#glossary") {
                      localStorage.setItem("glossarySelectedLinkId", "#1");
                    }
                  }}
                >
                  {link.text}
                </Link>
              )}
            </li>
          ))}
        </ul>
        <hr className={styles.hrDiv} />
        <div className={styles.footerBranding} data-testid="cmhc_copyright">
          <p>
            {t("footerLinks.CHMC")} ({t("footerLinks.CHMCAbbrevation")}) &copy;{" "}
            {new Date().getFullYear()}
          </p>
          <img
            src={logoCanada}
            alt="Canada government logo"
            className={styles.canadaLogo}
          />
        </div>
      </div>
    </footer>
  );
}
