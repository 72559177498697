import { useTranslation } from "react-i18next";
import styles from "./ReviewInvestor.module.scss";
import { dollorIcon } from "../../../assets/icons";
import { useEffect, useState } from "react";
import { InvestorAPI } from "../../../APIAction/InvestorAPI";
import {
  InvestorInvestmentInformation,
  ProvinceProps,
} from "../../../TypesAndInterfaces/InvestorDetailsInterface";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useUser } from "../../../components/Context/UserContext";
import {
  currencyFormat,
  DOLLAR_RANGE,
  getKVArrayFormat,
} from "../../../Constants/Enumerations";

export const ReviewInvestorInformation = ({
  provinceOptions,
}: ProvinceProps) => {
  const { t } = useTranslation("onboarding");
  const { userState } = useUser();
  const navigate = useNavigate();
  const [investmentInfo, setInvestmentInfo] =
    useState<InvestorInvestmentInformation>();
  const [isDataExist, setIsDataExist] = useState(false);

  useEffect(() => {
    const getInvestmentInfomation = async () => {
      try {
        const res = await InvestorAPI.getInvestmentInfoById(userState.userId);
        const data = res.data;
        setIsDataExist(true);
        setInvestmentInfo(data);
      } catch (error) {
        setIsDataExist(false);
        if (axios.isAxiosError(error) && error.response?.status !== 404) {
          navigate("/api-error");
        }
      }
    };

    userState.userId !== "" && getInvestmentInfomation();
  }, [userState.userId, navigate]);

  const handleEditClick = () => {
    navigate("/onboarding/investor/edit");
  };
  const dolllarRangesTrans = Object.keys(DOLLAR_RANGE).map((val) =>
    t(`common:enumerations.dollar_range.${val}`)
  );
  const dollarOptions = getKVArrayFormat(DOLLAR_RANGE, dolllarRangesTrans).map(
    ({ label, value }) => ({ label: label, value: value })
  );

  return (
    <div className={styles.informationContainer}>
      <div className={styles.infoHeadingContainer}>
        <div className={styles.left}>
          <div className={styles.imgContainer}>
            <img src={dollorIcon} className={styles.circle} alt="" />
          </div>
          <h3 className={styles.sectionHeading}>
            {t("investor.main_page.form_accordions.investor_info.heading")}
          </h3>
        </div>
        <button className={styles.link} onClick={handleEditClick}>
          {t("investor.review_profile.edit_link")}
        </button>
      </div>
      {isDataExist && investmentInfo && (
        <div className={styles.content}>
          <p className={styles.title}>
            {t("investor.investment_info_form.investment_amount_heading")}
          </p>
          <div className={styles.grid_container}>
            <p>
              {t("investor.investment_info_form.investment_amount_question_review")}
            </p>
            <p>
              {currencyFormat(
                dollarOptions,
                investmentInfo.investmentDollarMinimum
              )}
              -{" "}
              {currencyFormat(
                dollarOptions,
                investmentInfo.investmentDollarMaximum
              )}
            </p>
            <p>{t("investor.investment_info_form.co_investment_question_review")}</p>
            <p>
              {investmentInfo.coInvestingFlag === true
                ? t("investor.investment_info_form.yes_label")
                : t("investor.investment_info_form.no_label")}
            </p>
          </div>
          <hr className={styles.horizontalLine} />
          <p className={styles.title}>
            {t("investor.investment_info_form.previous_experience_heading")}
          </p>
          <div className={styles.grid_container}>
            <p>
              {t("investor.investment_info_form.previous_experience_question")}
            </p>
            <p>
              {investmentInfo.investingExperienceFlag === true
                ? t("investor.investment_info_form.yes_label")
                : t("investor.investment_info_form.no_label")}
            </p>
          </div>
          {investmentInfo.investingExperienceFlag === true &&
            investmentInfo.projects.length > 0 &&
            investmentInfo.projects.map((experience, index) => {
              return (
                <div key={index}>
                  <hr className={styles.horizontalLine} />
                  <div className={styles.grid_container}>
                    <p>
                      {t(
                        "investor.investment_experience_form.project_name_label"
                      )}
                    </p>
                    <p>{experience.projectName}</p>
                    <p>
                      {t("investor.investment_experience_form.province_label")}
                    </p>
                    <p>
                      {
                        provinceOptions.find(
                          (val) => val.value === experience.province
                        )?.label
                      }
                    </p>
                    <p>{t("investor.investment_experience_form.city_label")}</p>
                    <p>{experience.city}</p>
                    <p>
                      {t(
                        "investor.investment_experience_form.project_desc_label"
                      )}
                    </p>
                    <p>{experience.projectDescription}</p>
                  </div>
                </div>
              );
            })}
        </div>
      )}
    </div>
  );
};
