import { useTranslation } from "react-i18next";
import styles from "./connectionHub.module.scss";

type Props = {
  iconSrcToRender: string;
  statusTextTranslation: string;
  entityType: string;
};

export function CardStatus(props: Props) {
  const { t } = useTranslation("connectionHub");

  return (
    <div className={styles.cardStatus}>
      <img
        src={props.iconSrcToRender}
        className={styles.statusImage}
        alt=""
        data-testid="card-status-icon"
      />
      <div className={styles.statusText} data-testid="card-status-text">
        {props.statusTextTranslation}
      </div>
      <div className={styles.profileText} data-testid="card-entity-text">
        {t(`connectionHub:ConnectionHub.connectionTab.${props.entityType}`)}
      </div>
    </div>
  );
}
