import { useTranslation } from "react-i18next";
import styles from "./styles.module.scss";
import { useNavigate } from "react-router-dom";
import { CalculatorIcon } from "../../../assets/icons";
import { ListingProjectAPI } from "../../../APIAction/ListingProjectAPI";
import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  getKVArrayFormatForDropdown,
  COMMITMENT_TO_AFFORDABILITY,
} from "../../../Constants/Enumerations";

type Props = {
  projectListingID: string;
};

export const ReviewAffordability = ({ projectListingID }: Props) => {
  const { t } = useTranslation("profile");
  const navigate = useNavigate();
  const [affordabilityInfo, setAffordabilityInfo] = useState<any>();
  const [isDataExist, setIsDataExist] = useState(false);

  const affirdabilityTypesOptions = [
    {
      label: t(
        "common:enumerations.affordability_Types.SocialImpactingHousing"
      ),
      value: 1,
      description: t(
        "developer.projects.add_listing_form.socialImpactHousingDescription"
      )
    },
    {
      label: t(
        "common:enumerations.affordability_Types.FocusOnAffordableHousing"
      ),
      value: 2,
      description: t(
        "developer.projects.add_listing_form.affordableHousingDescription"
      )
    },
    {
      label: t(
        "common:enumerations.affordability_Types.BalanceBetweenAffordableAndSupply"
      ),
      value: 3,
      description: t(
        "developer.projects.add_listing_form.affirdabilityAndSupplyDescription"
      )
    },
    {
      label: t(
        "common:enumerations.affordability_Types.FocusOnHousingSupply"
      ),
      value: 4,
      description: t(
        "developer.projects.add_listing_form.housingSupplydescription"
      )
    },
  ]


  useEffect(() => {
    const getAffordabilityInfomation = async () => {
      try {
        const res = await ListingProjectAPI.getProjectAffordabilityInfoById(
          projectListingID
        );
        const data = res.data;
        setIsDataExist(true);
        setAffordabilityInfo(data);
      } catch (error) {
        setIsDataExist(false);
        if (axios.isAxiosError(error) && error.response?.status !== 404) {
          navigate("/api-error");
        }
      }
    };
    projectListingID !== "" && getAffordabilityInfomation();
  }, [projectListingID, navigate]);


  const handleEditClick = () => {
    navigate(`/projects/edit/${projectListingID}`);
  };

  const commitmentAffordabilityTrans = Object.keys(COMMITMENT_TO_AFFORDABILITY).map((val) =>
    t(`common:enumerations.commitment_to_affordability.${val}`)
  );
  const commitmentAffordabilityOptions: {
    label: string;
    value: string | number;
  }[] = getKVArrayFormatForDropdown(COMMITMENT_TO_AFFORDABILITY, commitmentAffordabilityTrans);


  const affordabilityCommitment = isDataExist ?
    commitmentAffordabilityOptions.find(
      (item) => +item.value === affordabilityInfo.affordabilityCommitment ? affordabilityInfo.affordabilityCommitment : ""
    )?.label : "";

  const projectAffordabilityTypeValue = isDataExist ?
    affirdabilityTypesOptions.find(
      (item) => +item.value === affordabilityInfo.projectAffordabilityType
    ) : null;
  return (
    <div className={styles.informationContainer}>

      {isDataExist && (
        <React.Fragment>
          <div className={styles.infoHeadingContainer}>
            <div className={styles.left}>
              <div className={styles.imgContainer}>
                <img
                  src={CalculatorIcon}
                  alt={t("developer.projects.add_listing_form.calculatorIcon")}
                />
              </div>
              <h3 className={styles.sectionHeading}>
                {t(
                  "developer.projects.add_listing_form.affordability_benchmark_title"
                )}
              </h3>
            </div>
            <button className={styles.link} onClick={handleEditClick}>
              {t("developer.projects.review_project.edit_link")}
            </button>
          </div>

          <div className={styles.content}>
            <div className={styles.grid_container}>

              <p>{t("developer.projects.affordability_details.typeOfAffordability")}</p>
              <p><span className={styles.badgeValue}>{projectAffordabilityTypeValue && projectAffordabilityTypeValue.label}</span> <br></br> {projectAffordabilityTypeValue && projectAffordabilityTypeValue.description}</p>
              {projectAffordabilityTypeValue && projectAffordabilityTypeValue.value === 1 ?
                <React.Fragment>
                  <p>Total units developed through the project</p>
                  <p >{affordabilityInfo.totalUnitCount}</p>
                </React.Fragment>
                :
                <React.Fragment>
                  <p>{t("developer.projects.affordability_details.units_below_avg_rent")}</p>
                  <p >{affordabilityInfo.belowAverageMarketRentUnitCount}</p>

                  <p>{t("developer.projects.affordability_details.units_equal_avg_rent")}</p>
                  <p >{affordabilityInfo.equalToAverageMarketRentUnitCount}</p>

                  <p>{t("developer.projects.affordability_details.units_above_avg_rent")}</p>
                  <p >{affordabilityInfo.aboveAverageMarketRentUnitCount}</p>

                  <p>{t("developer.projects.affordability_details.total_units_developed")}</p>
                  <p >{affordabilityInfo.totalUnitCount}</p>
                </React.Fragment>
              }
              <p>{t("developer.projects.affordability_details.commitment_to_affordability")}</p>
              <p >{affordabilityCommitment}</p>

              <p>{t("developer.projects.affordability_details.affordability_description")}"</p>
              <p >{affordabilityInfo.marketLevelDefinition}</p>

              <p>{t("developer.projects.affordability_details.additional_details")}</p>
              <p >{affordabilityInfo.additionalDetails}</p>
            </div>
          </div>
        </React.Fragment>
      )}
    </div>

  );
};
