import { useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useUser } from "../../components/Context/UserContext";
import { PageContent } from "../../components/Layout/PageContent";
import { PageLayout } from "../../components/Layout/PageLayout";
import { RedirectSkeleton } from "./RedirectSkeleton";

export function RedirectHandler() {
  const { userState } = useUser();
  const navigate = useNavigate();

  const pathToSearchInvestors = "/search/investors";
  const pathToSearchProjects = "/search/projects";
  const pathToDevOnboarding = "/onboarding/developer";
  const pathToInvOnboarding = "/onboarding/investor";

  const redirectionHandler = useCallback(
    (urlPathDev: string, urlPathInv: string) => {
      if (userState.userPersona.toLowerCase() === "developer") {
        navigate(urlPathDev);
      } else {
        navigate(urlPathInv);
      }
    },
    [userState.userPersona, navigate]
  );

  const handleOnboardingRedirect = useCallback(() => {
    if (userState.userPersona !== "" && userState.onboardingStatus === 3) {
      if(pathToSearchProjects === '/search/projects'){
        localStorage.setItem("lastTabIndex", "0")
        localStorage.setItem("lastPage", "1");
      }
      redirectionHandler(pathToSearchInvestors, pathToSearchProjects);
    } else if (
      userState.userPersona !== "" &&
      userState.onboardingStatus !== 3
    ) {
      redirectionHandler(pathToDevOnboarding, pathToInvOnboarding);
    }
  }, [redirectionHandler, userState.userPersona, userState.onboardingStatus]);

  useEffect(() => {
    handleOnboardingRedirect();
  }, [handleOnboardingRedirect]);

  return (
    <PageLayout>
      <PageContent>
        <RedirectSkeleton />
      </PageContent>
    </PageLayout>
  );
}
