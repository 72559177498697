import { Skeleton } from "@mui/material";
import styles from "./connectionHub.module.scss";

export function SkeletonConnectionCard() {
  return (
    <div className={styles.skeletonContainer}>
      <Skeleton width={400} height={50} />
      <Skeleton width={400} height={50} />
      <Skeleton width={400} height={50} />
    </div>
  );
}
