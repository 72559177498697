import { FieldArray, Form, Formik } from "formik";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { SelectInput } from "../common/SelectInput";
import styles from "./styles.module.scss";
import { FieldArrayAddButton } from "../common/FieldArrayAddButton";
import { FieldArrayRemoveButton } from "../common/FieldArrayRemoveButton";
import { InvestorAPI } from "../../../APIAction/InvestorAPI";
import {
  InvestorInvestmentPreference,
  InvestorProps,
} from "../../../TypesAndInterfaces/InvestorDetailsInterface";
import { useEffect, useState } from "react";
import { CheckBoxGroup } from "../common/CheckBoxGroup";
import { RadioInput } from "../common/RadioInput";
import { useUser } from "../../Context/UserContext";
import {
  FUNDING_TYPES_ONBOARDING,
  getKVArrayFormat,
  getKVArrayFormatForDropdown,
  INVESTMENT_PHASES_ONBOARDING,
  LOCKUP_PERIOD,
  PROVINCES_CODES,
  ROI_RANGE,
} from "../../../Constants/Enumerations";
import { ProjectLocationFields } from "../AddProject/ProjectLocationFields";
import { City } from "country-state-city";

export function InvestmentPreferencesForm({ handleResponse }: InvestorProps) {
  const { t } = useTranslation("onboarding");

  const { userState } = useUser();


  const [PreferenceInfo, setPreferenceInfo] =
    useState<InvestorInvestmentPreference>();
  const [isDataExist, setIsDataExist] = useState(false);

  useEffect(() => {
    const getPreferenceInfomation = async () => {
      try {
        const res = await InvestorAPI.getPreferenceInfoById(userState.userId);
        const data = res.data;
        setPreferenceInfo(data);
        setIsDataExist(true);
      } catch (error) {
        setIsDataExist(false);
        handleResponse("PreferenceInfo", false, error);
      }
    };

    userState.userId !== "" && getPreferenceInfomation();
  }, [userState.userId, handleResponse]);

  const investmentPhasesTranslations = Object.keys(INVESTMENT_PHASES_ONBOARDING).map(
    (val) => t(`common:enumerations.investment_phases_onboarding.${val}`)
  );
  const investmentPhasesOptions = getKVArrayFormat(
    INVESTMENT_PHASES_ONBOARDING,
    investmentPhasesTranslations
  );

  const lockupPeriodTrans = Object.keys(LOCKUP_PERIOD).map((val) =>
    t(`common:enumerations.lockup_period.${val}`)
  );
  const lockupPeriodPlaceholder = {
    label: t(
      "investor.investment_preferences_form.funding_payback_placeholder"
    ),
    value: "",
  };

  const maximumLockupPeriodOptions: {
    label: string;
    value: string | number;
  }[] = getKVArrayFormatForDropdown(LOCKUP_PERIOD, lockupPeriodTrans);
  maximumLockupPeriodOptions.unshift(lockupPeriodPlaceholder);

  const capitalCostTranslation = Object.keys(ROI_RANGE).map((val) =>
    t(`common:enumerations.roi_range.${val}`)
  );

  const capitalCostRange = getKVArrayFormatForDropdown(
    ROI_RANGE,
    capitalCostTranslation
  );

  const capitalCostRangeMin = Array.from(capitalCostRange);
  capitalCostRangeMin.unshift({
    label: t("investor.investment_preferences_form.roi_min_placeholder"),
    value: "",
  });

  const capitalCostRangeMax = Array.from(capitalCostRange);
  capitalCostRangeMax.unshift({
    label: t("investor.investment_preferences_form.roi_max_placeholder"),
    value: "",
  });

  const capitalInjectionTranslations = Object.keys(FUNDING_TYPES_ONBOARDING).map((val) =>
    t(`common:enumerations.funding_types_onboarding.${val}`)
  );
  const investmentFundingTypes = getKVArrayFormat(
    FUNDING_TYPES_ONBOARDING,
    capitalInjectionTranslations
  );

  const initialRegionValues = {
    province: "",
    city: "",
  };

  const constructiontypeOptions = [
    {
      label: t("investor.investment_preferences_form.preferencetype_new_label"),
      value: "1",
    },
    {
      label: t(
        "investor.investment_preferences_form.preferencetype_repair_label"
      ),
      value: "2",
    },
  ];


  const radioOptions = [
    {
      label: t("developer.orgnization_info_form.org_info.yes_label"),
      value: "true",
    },
    {
      label: t("developer.orgnization_info_form.org_info.no_label"),
      value: "false",
    },
  ];

  const getCityListForProvince = (province: any) => {
    const selectedProvince = PROVINCES_CODES.find((element) => element.value === Number(province));
    if (selectedProvince) {
      const isoCode = selectedProvince.isoCode;
      return City.getCitiesOfState('CA', isoCode).map((city) => city.name);
    }
    return [];
  };

  // Function to check if city exists for a given province
  const checkCityExistence = (province: any, cityList: any) => {
    const city = PreferenceInfo?.investmentRegions.find((item) => item.province === province)?.city;
    return city && cityList.includes(city) ? city : '';
  };

  // Iterate over the data array and check if city exists for each province
  const investmentRegionsData = PreferenceInfo?.investmentRegions.map((item) => ({
    province: item.province,
    city: checkCityExistence(item.province, getCityListForProvince(item.province)),
  }));


  const ApiData = {
    roiTargetMinimum: PreferenceInfo?.roiTargetMinimum?.toString(),
    roiTargetMaximum: PreferenceInfo?.roiTargetMaximum?.toString(),
    maximumLockupPeriod: PreferenceInfo?.maximumLockupPeriod,
    //Number to string as formik work with string for radio buttons values
    constructionType: PreferenceInfo?.constructionType?.toString(),
    ableToProvideLandFlag:
      PreferenceInfo?.ableToProvideLandFlag === true ? "true" : "false",
    investmentPhases: PreferenceInfo?.investmentPhases?.map((opt) =>
      opt.toString()
    ),
    fundingTypes:
      PreferenceInfo?.fundingTypes?.map((opt) => opt.toString()) || [],
    investmentRegions: investmentRegionsData || [
      initialRegionValues,
    ],
    vulnerableGroups: PreferenceInfo?.vulnerableGroups?.map((opt) =>
      opt.toString()
    ),
    climateChangeCriteria: PreferenceInfo?.climateChangeCriteria?.map((opt) =>
      opt.toString()
    ),
  };

  const initialValues = {
    roiTargetMinimum: "",
    roiTargetMaximum: "",
    maximumLockupPeriod: "",
    investmentPhases: [],
    constructionType: "",
    fundingTypes: [],
    investmentRegions: [initialRegionValues],
    vulnerableGroups: [],
    climateChangeCriteria: [],
    ableToProvideLandFlag: "",
  };

  const preferencesInfoSchema = Yup.object().shape({
    constructionType: Yup.number().required(
      t("investor.preferences_form_error.construction_type_required")
    ),
    investmentPhases: Yup.array()
      .required(t("investor.general_form_error.general_required"))
      .min(1, t("investor.preferences_form_error.investment_phases_required")),
    fundingTypes: Yup.array()
      .required(t("investor.general_form_error.general_required"))
      .min(1, t("investor.preferences_form_error.funding_types_required")),
    roiTargetMinimum: Yup.number().required(
      t("investor.preferences_form_error.roi_min_required")
    ),
    roiTargetMaximum: Yup.number()
      .required(t("investor.preferences_form_error.roi_max_required"))
      .moreThan(
        Yup.ref("roiTargetMinimum"),
        t("investor.preferences_form_error.roi_max_lessthenmin")
      ),
    maximumLockupPeriod: Yup.string().required(
      t("investor.preferences_form_error.lockupperiod_required")
    ),
    ableToProvideLandFlag: Yup.boolean().required(
      t("investor.preferences_form_error.provide_land_error")
    ),
    investmentRegions: Yup.array()
      .of(
        Yup.object().shape({
          province: Yup.string().required(
            t("investor.general_form_error.province_required")
          ),
          city: Yup.string()
            .required(t("investor.general_form_error.city_required"))
            .max(
              64,
              t("investor.general_form_error.max_characters", { charNum: 64 })
            ),
        })
      )
      .required("Region is required")
      .min(1, "At least one is required.")
      .max(5, "A maximum of 5 is premitted"),
  });

  const submitPreferenceInformation = async (formValues: Object) => {
    try {
      await InvestorAPI.postPreferenceInfo({
        ...formValues,
        investorID: userState.userId,
      });
      handleResponse("PreferenceInfo", true);
    } catch (error) {
      handleResponse("PreferenceInfo", false, error);
    }
  };

  const updatePreferenceInformation = async (formValues: Object) => {
    try {
      await InvestorAPI.updatePreferenceInfo(userState.userId, formValues);
      handleResponse("PreferenceInfo", true);
    } catch (error) {
      handleResponse("PreferenceInfo", false, error);
    }
  };

  return (
    <Formik
      initialValues={isDataExist && PreferenceInfo ? ApiData : initialValues}
      validationSchema={preferencesInfoSchema}
      validateOnBlur={false}
      validateOnChange={false}
      enableReinitialize={true}
      onSubmit={(values) => {
        const {
          roiTargetMaximum,
          roiTargetMinimum,
          climateChangeCriteria,
          vulnerableGroups,
          constructionType,
          fundingTypes,
          investmentPhases,
          ableToProvideLandFlag,
          ...restValues
        } = values;
        const climateChangeCriteriaNumberList = climateChangeCriteria?.map(
          (str) => +str
        );
        const vulnerableGroupsNumberList = vulnerableGroups?.map((str) => +str);
        const fundingTypesNumberList = fundingTypes.map((str) => +str);
        const investmentPhasesNumberList = investmentPhases?.map((str) => +str);
        const updatedValues = {
          roiTargetMaximum: roiTargetMaximum ? +roiTargetMaximum : "",
          roiTargetMinimum: roiTargetMinimum ? +roiTargetMinimum : "",
          constructionType: constructionType ? +constructionType : "",
          climateChangeCriteria: climateChangeCriteriaNumberList,
          vulnerableGroups: vulnerableGroupsNumberList,
          fundingTypes: fundingTypesNumberList,
          investmentPhases: investmentPhasesNumberList,
          ableToProvideLandFlag:
            ableToProvideLandFlag === "true" ? true : false,
          ...restValues,
        };
        isDataExist
          ? updatePreferenceInformation({
            investorID: userState.userId,
            ...updatedValues,
          })
          : submitPreferenceInformation({ ...updatedValues });
      }}
    >
      {({ values, errors, setFieldValue }) => (
        <Form className={styles.formElement} noValidate>
          <h3 className={styles.formSectionHeading}>
            {t("investor.investment_preferences_form.funding_heading")}
          </h3>

          <h4 className={styles.formQuestion}>
            {t("investor.investment_preferences_form.preferencetype_question")}
          </h4>

          <div className={styles.fieldContainer}>
            <RadioInput
              fieldName="constructionType"
              options={constructiontypeOptions}
              data-testid="constructiontype-radio"
            />
          </div>

          <CheckBoxGroup
            fieldName="investmentPhases"
            formQuestionText={t(
              "investor.investment_preferences_form.funding_investment_types"
            )}
            options={investmentPhasesOptions}
            data-testid="investment-phases"
          />

          <CheckBoxGroup
            fieldName="fundingTypes"
            formQuestionText={t(
              "investor.investment_preferences_form.funding_funding_type"
            )}
            options={investmentFundingTypes}
            data-testid="funding-types"
          />

          <p className={styles.formQuestion}>
            {t("investor.investment_preferences_form.funding_ideal_roi")}
          </p>
          <p className={styles.helper_text} >
            {t(
              "investor.investment_preferences_form.return_on_investment_label_helper_text"
            )}
          </p>
          <div className={styles.splitFieldContainer}>
            <SelectInput
              fieldName="roiTargetMinimum"
              labelText={t(
                "investor.investment_preferences_form.roi_min_label"
              )}
              options={capitalCostRangeMin}
              data-testid="roi-target-min"
            />

            <SelectInput
              fieldName="roiTargetMaximum"
              labelText={t(
                "investor.investment_preferences_form.roi_max_label"
              )}
              options={capitalCostRangeMax}
              data-testid="roi-target-max"
            />
          </div>

          <SelectInput
            fieldName="maximumLockupPeriod"
            labelText={t(
              "investor.investment_preferences_form.funding_invest_period"
            )}
            options={maximumLockupPeriodOptions}
            data-testid="roi-period"
          />

          <h4 className={styles.formQuestion}>
            {t("investor.investment_preferences_form.able_to_provide_land_question")}
          </h4>

          <div className={styles.fieldContainer}>
            <RadioInput
              fieldName="ableToProvideLandFlag"
              options={radioOptions}
              data-testid="ableToProvideLandFlag-radio"
            />
          </div>

          <hr className={styles.horizontalLine} />

          <h3 className={styles.formSectionHeading}>
            {t("investor.investment_preferences_form.focus_areas_heading")}
          </h3>
          <p className={styles.formInfo}>
            {t("investor.investment_preferences_form.focus_areas_sub-heading")}
          </p>

          <FieldArray
            name="investmentRegions"
            render={(arrayHelpers) => (
              <div className={styles.experienceSectionContainer}>
                {values.investmentRegions &&
                  values.investmentRegions.length > 0 ? (
                  values.investmentRegions.map((region, index) => (
                    <div key={index}>
                      <div className={styles.experienceHeadingContainer}>
                        <span></span>
                        {index > 0 && (
                          <FieldArrayRemoveButton
                            onClickHandler={() => arrayHelpers.remove(index)}
                            buttonText={t(
                              "investor.investment_preferences_form.remove_region_text"
                            )}
                          />
                        )}
                      </div>
                      <ProjectLocationFields name={`investmentRegions[${index}]`} province={values.investmentRegions[index].province} isDataExist={isDataExist} />
                    </div>
                  ))
                ) : (
                  <ProjectLocationFields name={`investmentRegions[0]`} province={values.investmentRegions[0].province} />
                )}
                {values.investmentRegions.length > 0 &&
                  values.investmentRegions.length < 5 ? (
                  <div className={styles.centreAlignContainer}>
                    <FieldArrayAddButton
                      onClickHandler={() =>
                        arrayHelpers.push(initialRegionValues)
                      }
                      buttonText={t(
                        "investor.investment_preferences_form.add_region_text"
                      )}
                    />
                  </div>
                ) : null}
              </div>
            )}
          />
          <button
            type="submit"
            className={styles.formButton}
            data-testid="submit-button"
          >
            {t("investor.main_page.next_button_text")}
          </button>
        </Form>
      )}
    </Formik>
  );
}
