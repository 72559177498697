import { useTranslation } from "react-i18next";
import { ProvincesSelect } from "../common/ProvincesSelect";
import styles from "./styles.module.scss";
import { CitySelect } from "../common/CitySelect";

export function ProjectLocationFields({
  province,
  city,
  setCity,
  setFieldValue,
  isDataExist,
  name,
  cityLabelText,
  placeholder,
  cityPlaceholder,
}: any) {
  const { t } = useTranslation("profile");

  return (
    <div className={styles.splitFieldContainer}>
      <ProvincesSelect
        fieldName={name ? name + ".province" : "province"}
        labelText={t("developer.projects.general_info_form.province_label")}
        data-testid="province"
        placeholder={placeholder ?? t(
          "developer.projects.general_info_form.province_placeholder"
        )}
      />

      <CitySelect
        fieldName={name ? name + ".city" : "city"}
        province={province}
        labelText={cityLabelText ?? t("developer.projects.general_info_form.city_label")}
        placeholder={cityPlaceholder ?? ""}
      />
    </div>
  );
}
