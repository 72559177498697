import { useField } from "formik";
import { CheckBoxInputColumn } from "./CheckBoxInputColumn";
import { CommonErrorMessage } from "./CommonErrorMessage";
import styles from "./styles.module.scss";

type Props = {
  fieldName: string;
  formQuestionText: string;
  options: { label: string; value: string | number }[];
  linkText?: string;
  setIsOpenModal?: Function;
  [x: string]: unknown;
  placeholder?: string;
};

export function CheckBoxGroupColumn({
  fieldName,
  formQuestionText,
  options,
  linkText,
  setIsOpenModal,
  placeholder,
  ...rest
}: Props) {
  const [field, meta] = useField(fieldName);

  return (
    <div className={styles.fieldContainer}>
      <div className={styles.lableLink}>

        <p
          className={`${styles.formQuestion} ${meta.error && meta.touched ? styles.errorStateLabel : null
            }`}
        >
          {formQuestionText}
        </p>

        <button
          type="button"
          className={styles.glossaryLink}
          data-testid="glossary-page-link"
          onClick={(e) => {
            e.preventDefault();
            if (setIsOpenModal) {
              setIsOpenModal(true);
            }
          }}
        >
          {linkText}
        </button>
      </div>
      <p>{placeholder}</p>
      <CheckBoxInputColumn fieldName={field.name} options={options} {...rest} />
      <CommonErrorMessage fieldName={field.name} />
    </div>
  );
}
