import { DeveloperApi } from "../../APIAction/DeveloperAPI";
import { InvestorAPI } from "../../APIAction/InvestorAPI";
import { UserDispatch } from "../../TypesAndInterfaces/UserContextInterface";

export function setAzureData(
  dispatch: UserDispatch,
  personaData: string,
  onboardingStatusData: number
) {
  dispatch({
    type: "setAzureData",
    payload: { personaData, onboardingStatusData },
  });
}

export function setOptimisticOnboardingStatus(dispatch: UserDispatch) {
  dispatch({
    type: "setOptimisticOnboardedStatus",
  });
}

export function resetData(dispatch: UserDispatch) {
  dispatch({ type: "reset" });
}

async function handlePersonaPayload(persona: string, email: string) {
  if (persona.toLowerCase() === "developer") {
    const userDataRes = await DeveloperApi.postDeveloperIdByEmail(email);
    return {
      userIdData: userDataRes.id,
      userOrganizationData: userDataRes.generalInformation.organizationName,
      userOrganizationTypes: userDataRes?.organizationInformation?.organizationType || [],
      onboardingStatus: userDataRes.developerProfileCompletedFlag,
    };
  } else {
    const userDataRes = await InvestorAPI.postInvestorIdByEmail(email);
    return {
      userIdData: userDataRes.id,
      userOrganizationData: userDataRes.generalInformation?.organizationName,
      onboardingStatus: userDataRes.investorProfileCompletedFlag,  
    };
  }
}

async function handleDataQuery(
  dispatch: UserDispatch,
  persona: string,
  email: string
) {
  try {
    const userDataPayload = await handlePersonaPayload(persona, email);
    dispatch({
      type: "setApiData",
      payload: userDataPayload,
    });
  } catch (error) {
    console.error("Unable to set user data.");
    dispatch({ type: "setApiError" });
  }
}

export async function setUserApiData(
  dispatch: UserDispatch,
  email: string,
  persona: string
) {
  await handleDataQuery(dispatch, persona, email);
}
