import { useTranslation } from "react-i18next";
import { PageLayout } from "../../../components/Layout/PageLayout";
import { PageContent } from "../../../components/Layout/PageContent";
import styles from "./styles.module.scss";
import { useEffect, useState } from "react";
import { DeveloperApi } from "../../../APIAction/DeveloperAPI";
import axios from "axios";
import { NoProjectFound } from "./noProject";
import { Link, useNavigate } from "react-router-dom";
import { ProjectCardI } from "../../../TypesAndInterfaces/ProjectDetailsInterface";
import { DeveloperProjectCard } from "../../../components/common/Cards/DeveloperProjectCard";
import { useUser } from "../../../components/Context/UserContext";
import { PaginationElement } from "../../../components/common/PaginationElement/PaginationElement";
import { ProjectCardSkeleton } from "../../../components/common/Cards/ProjectCardSkeleton";
import { MyProjectsHeading } from "./MyProjectsHeading";

export const DeveloperProject = () => {
  const { t } = useTranslation("profile");
  const navigate = useNavigate();
  const { userState } = useUser();
  const [showLoader, setShowLoader] = useState(true);
  const [isDataExist, setIsDataExist] = useState(false);
  const [ProjectCards, setProjectCards] = useState({} as ProjectCardI);
  const [page, setPage] = useState(1);
  // 0-Draft, 1-Active, 2-Closed, Deleted Project should not be shown
  const initFilters = {
    start: 0, // Ist project number on current page
    pageSize: 6,
    sortColumn: "projectname",
    sortDirection: 0,
    filter: {
      developerID: userState.userId,
    },
  };

  const [filters] = useState(initFilters);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    const getProjectSearchResult = async () => {
      setShowLoader(true);
      try {
        filters.start = (page - 1) * filters.pageSize;
        filters.filter.developerID = userState.userId;
        const res = await DeveloperApi.searchDeveloperProjectsInfo(filters);
        setShowLoader(false);
        setIsDataExist(true);
        setProjectCards(res.data);
      } catch (err) {
        setShowLoader(false);
        if (axios.isAxiosError(err)) {
          if (err.response?.status === 404) {
            setIsDataExist(false);
          } else {
            navigate("/api-error");
          }
        }
      }
    };

    if (userState.isOnboarded) {
      getProjectSearchResult();
    } else {
      setShowLoader(false);
      setIsDataExist(false);
    }
  }, [userState, page, filters, navigate]);

  return (
    <PageLayout>
      <PageContent>
        <div className={styles.container}>
          <MyProjectsHeading />
          <div className={styles.contentParent}>
            <div className={styles.actionListContainer}>
              <Link className={styles.actionLinkStyle} to="/profile/developer">
                {t("developer.projects.developer_project.return_link")}
              </Link>
              {userState.isOnboarded && (
                <Link className={styles.actionLinkStyle} to="/projects/add">
                  {isDataExist &&
                  ProjectCards.data &&
                  ProjectCards.data.length > 0
                    ? t(
                        "developer.projects.developer_project.create_link_project"
                      )
                    : t(
                        "developer.projects.developer_project.create_link_no_project"
                      )}
                </Link>
              )}
            </div>
            {showLoader ? (
              <div
                className={styles.contentSection}
                data-testid="search-results-container"
              >
                <ProjectCardSkeleton />
              </div>
            ) : (
              <div className={styles.contentSection}>
                {isDataExist &&
                ProjectCards.data &&
                ProjectCards.data.length > 0 ? (
                  ProjectCards.data.map((data, idx) => {
                    return <DeveloperProjectCard key={idx} data={data} />;
                  })
                ) : (
                  <NoProjectFound />
                )}
                {isDataExist && ProjectCards.totalRecords > 6 && (
                  <PaginationElement
                    start={filters.start + 1}
                    totalRecords={ProjectCards.totalRecords}
                    page={page}
                    setPage={setPage}
                    pageSize={filters.pageSize}
                  />
                )}
              </div>
            )}
          </div>
        </div>
      </PageContent>
    </PageLayout>
  );
};
