import { NavigateFunction } from "react-router-dom";
import { ListingPortfolioAPI } from "../../../APIAction/ListingPortfolioAPI";
import * as Yup from "yup";
import { handleApiErrorRedirect } from "../../../APIAction/config";

type LoadingStateFunction = (value: boolean) => void;
type ResponseHandlerFunction = (
  isSuccess: boolean,
  portfolioListingId: string
) => void;

/**
 * A helper function to submit a new portfolio listing - general info section.
 */
export async function submitGeneralInformation(
  formValues: Object,
  loadingStateFunction: LoadingStateFunction,
  responseHandlerFunction: ResponseHandlerFunction,
  localNavigateHook: NavigateFunction
) {
  try {
    loadingStateFunction(true);
    const res = await ListingPortfolioAPI.postPortfolioGenInfo(formValues);
    const portfolioListingID = res.data && res.data.id;
    loadingStateFunction(false);
    responseHandlerFunction(true, portfolioListingID);
  } catch (error) {
    loadingStateFunction(false);
    responseHandlerFunction(false, "");
    handleApiErrorRedirect(error, localNavigateHook);
  }
}

/**
 * A helper function to update a previously existing portfolio listing - general info section.
 */
export async function updateGeneralInformation(
  portfolioListingId: string | undefined,
  formValues: Object,
  loadingStateFunction: LoadingStateFunction,
  responseHandlerFunction: ResponseHandlerFunction,
  localNavigateHook: NavigateFunction
) {
  if (!portfolioListingId) {
    return;
  }

  try {
    loadingStateFunction(true);
    await ListingPortfolioAPI.updatePortfolioGenInfo(portfolioListingId, formValues);
    loadingStateFunction(false);
    responseHandlerFunction(true, "");
  } catch (error) {
    loadingStateFunction(false);
    responseHandlerFunction(false, "");
    handleApiErrorRedirect(error, localNavigateHook);
  }
}

/**
 * A function to copare two dates in the Yup schema. Start and end dates cannot be the same day.
 * The end date must always be greater than the start date. Provides a default startingValue if undefined.
 */
export function schemaDateComparison(
  startingValue: Date,
  schema: Yup.DateSchema,
  errorTextTranslated: string
) {
  if (!startingValue) {
    const today = new Date();
    const minDate = today.setDate(today.getDate() - 1095);
    return schema.min(new Date(minDate));
  }

  const startDate = new Date(startingValue.getTime());
  const minDate = startDate.setDate(startDate.getDate() + 1);
  return schema.min(new Date(minDate), errorTextTranslated);
}

/**
 * A function to check if a value has a decimal, and if so only permit 1 decimal place
 */
export function schemaDecimalTest(val: any) {
  if (val === undefined) {
    return true;
  }

  if (Number.isInteger(val)) {
    return true;
  } else if (val.toString().split(".")[1].length > 1) {
    return false;
  } else {
    return true;
  }
}
