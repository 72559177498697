import { PageLayout } from "../../components/Layout/PageLayout";
import styles from "./ErrorPage.module.scss";
import { CryingFace } from "../../assets/icons/index";
import { PageContent } from "../../components/Layout/PageContent";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

export const ApiError = () => {
  const { t } = useTranslation("common");
  const navigate = useNavigate();
  const handleTryAgain = () => {
    navigate("/");
  };
  return (
    <PageLayout>
      <PageContent>
        <div className={styles.erorContainer}>
          <img
            data-testid="crying-face"
            src={CryingFace}
            alt={t("error_page_500.error_image_alt_text")}
          />
          <div className={styles.errorInfoContainer}>
            <h2 data-testid="error-heading" className={styles.heading}>
              {t("error_page_500.error_heading")}
            </h2>
            <p data-testid="error-content" className={styles.info}>
              {t("error_page_500.error_info")}
            </p>
          </div>
          <div>
            <button
              type="button"
              className={styles.formButton}
              data-testid="submit-button"
              onClick={handleTryAgain}
            >
              {t("error_page_500.try_again")}
            </button>
          </div>
          <a href="mailto:capital.connect@cmhc-schl.gc.ca" className={styles.link}>
            {t("error_page_500.contact_link_text")}
          </a>
        </div>
      </PageContent>
    </PageLayout>
  );
};
