import { ErrorMessage } from "formik";
import { useTranslation } from "react-i18next";
import styles from "./styles.module.scss";

type Props = {
  fieldName: string;
};

export function CommonErrorMessage({ fieldName }: Props) {
  const { t } = useTranslation("common");

  return (
    <ErrorMessage name={fieldName}>
      {(msg) => (
        <p className={styles.errorMsg}>
          {t("form.error")}: {msg}
        </p>
      )}
    </ErrorMessage>
  );
}
