import { Modal } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import styles from "./successModal.module.scss";
import { CheckMark } from "../../assets/icons";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useUser } from "../Context/UserContext";

type Props = {
  openModal: boolean;
  handleClose: () => void;
  isPortfolio?: boolean;
};

export const RequestSentSuccess = ({
  openModal,
  handleClose,
  isPortfolio,
}: Props) => {
  const navigate = useNavigate();
  const { t } = useTranslation(["connect"]);
  const { userState } = useUser();

  const navigationClick = () => {
    if (isPortfolio) {
      navigate("/search/projects");
      localStorage.setItem("lastTabIndex", "2");
      localStorage.setItem("lastPage", "1");
    } else {
      navigate("/search/projects");
      localStorage.setItem("lastTabIndex", "0");
      localStorage.setItem("lastPage", "1");
    }
  };

  return (
    <Modal open={openModal} onClose={handleClose}>
      <div
        className={styles.ConnectModalContainer}
        data-testid="RequestSentSuccessContainer"
      >
        <div className={styles.modalContentWrapper}>
          <button className={styles.crossIcon} onClick={handleClose}>
            <CloseIcon />
          </button>
          <div className={styles.headerContainer}>
            <img
              src={CheckMark}
              alt={t("devToInvestor.successModal.alt_text")}
              className={styles.icon}
              data-testid="checkMark-icon"
            />
            <h2 data-testid="Title">
              {t("devToInvestor.successModal.heading")}
            </h2>
          </div>
          <div className={styles.headerText}>
            <h4 data-testid="subTitle" className={styles.subTitle}>
              {t("devToInvestor.successModal.subTitle")}
            </h4>
            <p
              //className={styles.info}
              data-testid="summary"
            >
              <ol className={styles.orderedList}>
                <li>{t("devToInvestor.successModal.summary.item1")}</li>
                <li>{t("devToInvestor.successModal.summary.item2")}</li>
                <li>{t("devToInvestor.successModal.summary.item3")}</li>
                <li>{t("devToInvestor.successModal.summary.item4")}</li>
              </ol>
            </p>
          </div>
          <div className={styles.buttonsContainer}>
            <button
              className={styles.secondaryBtn}
              onClick={() => navigate("/connection")}
              data-testid="connect_hub_btn"
            >
              {t("devToInvestor.successModal.view_connection_btn")}
            </button>
            {userState.userPersona.toLowerCase() === "developer" ? (
              <button
                className={styles.primaryBtn}
                onClick={() => navigate("/search/investors")}
                data-testid="viewInvestorBtn"
              >
                {t("devToInvestor.successModal.view_Investor_btn")}
              </button>
            ) : (
              <button
                className={styles.primaryBtn}
                onClick={() => navigationClick()}
                data-testid="viewProjectListBtn"
              >
                {/* {t("devToInvestor.successModal.view_project_list_btn")} */}
                {isPortfolio === true
                  ? t("devToInvestor.successModal.view_portfolios_list_btn")
                  : t("devToInvestor.successModal.view_project_list_btn")}
              </button>
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
};
