import styles from "./styles.module.scss";

type Props = {
  onClickHandler: () => void;
  buttonText: string|number;
};

export function FieldArrayRemoveButton(props: Props) {
  return (
    <button
      type="button"
      className={styles.actionLinkStyle}
      onClick={props.onClickHandler}
      data-testid="field-array-remove-button"
    >
      {props.buttonText}
    </button>
  );
}
