import ListPortfolio from '../../../assets/icons/portfolio/ListPortfolio.svg'
import styles from "./styles.module.scss";
import { useTranslation } from "react-i18next";
import { CreatePortfolio } from '../../../pages/Developers/Modals/CreatePortfolio';
import { useState } from 'react';


export function PortfolioCreateListCard() {
  const { t } = useTranslation("profile");
  const [createPortfolio, setCreatePortfolio] = useState<boolean>(false);

    return (
        <div className={styles.portfolioCreateListContainer}>
            <div className={styles.createCard}>
                <img
                    src={ListPortfolio}
                    alt={''}
                    className={styles.portfolioIcon}
                    data-testid="portfolio-icon"
                />
                <h2 className={styles.title}>
                   
        {t("developer.projects.listing_opportunity.list_a_portfolio")}

                </h2>
                <p className={styles.paraText}>
        {t("developer.projects.listing_opportunity.list_a_portfolio_summary")}
                
                </p>
                <button 
                className={styles.linkButton}
                  onClick={() => setCreatePortfolio(true)} 
                  >
                    
        {t("developer.projects.listing_opportunity.create_portfolio")}

                </button>
            </div>
            {createPortfolio && (
              <CreatePortfolio
                setCreatePortfolio={setCreatePortfolio}
              />
            )}

        </div>
    );
}
