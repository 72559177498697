import { Container } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Sad_Face } from "../../assets/images";
import { PageLayout } from "../../components/Layout/PageLayout";
import styles from "./Error.module.css";

export function Error() {
  const navigate = useNavigate();

  const { t } = useTranslation("waitlist");

  return (
    <PageLayout>
      <Container
        sx={{ height: "100%", textAlign: "center", padding: "3.5rem" }}
      >
        <img
          data-testid="sad-face"
          src={Sad_Face}
          alt={t("waitlist_success_page.error_image_alt_text")}
        />
        <h2 data-testid="error-main-heading" className="errorMainHeading">
          {t("waitlist_error_page.main_heading")}
        </h2>
        <div
          className={styles.errorInfoContainer}
          data-testid="error-main-text"
        >
          <p>{t("waitlist_error_page.main_message")}</p>
        </div>
        <div className={styles.errorActionsContainer} data-testid="error-links">
          <button
            type="button"
            className={styles.btnStyle}
            onClick={() => navigate("/request-access")}
          >
            {t("waitlist_error_page.try_again_link_text")}
          </button>
          <a href="mailto:capital.connect@cmhc-schl.gc.ca" className="link">
            {t("waitlist_error_page.contact_link_text")}
          </a>
        </div>
      </Container>
    </PageLayout>
  );
}
