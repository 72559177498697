import { Container } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Happy_Face } from "../../assets/images";
import { PageLayout } from "../../components/Layout/PageLayout";
import styles from "./Success.module.css";

export function Success() {
  const { t } = useTranslation("waitlist");

  const learnMoreLinks = [
    { text: t("waitlist_success_page.about_link_text"), path: "/about" },
    {
      text: t("waitlist_success_page.resources_link_text"),
      path: "/resources",
    },
  ];

  return (
    <PageLayout>
      <Container
        sx={{ textAlign: "center", padding: "3.5rem", height: "100%" }}
      >
        <img
          data-testid="happy-face"
          src={Happy_Face}
          className={styles.success_page_icon}
          alt={t("waitlist_success_page.success_image_alt_text")}
        />
        <h2
          data-testid="success-main-heading"
          className={styles.success_main_heading}
        >
          {t("waitlist_success_page.main_heading")}
        </h2>

        <div
          className={styles.successInfoContainer}
          data-testid="success-main-text"
        >
          <p>{t("waitlist_success_page.main_message")}</p>
          <p>{t("waitlist_success_page.learn_more_message")}</p>
        </div>

        <div className={styles.successLinkList} data-testid="success-links">
          {learnMoreLinks.map((link, idx) => (
            <div key={idx} className={styles.successInfoLink}>
              <Link to={link.path} className={styles.link} onClick={() => {
                if (link.path === "/resources") {
                  localStorage.setItem("glossarySelectedLinkId", "#1");
                }
              }}>
                {link.text}
              </Link>
            </div>
          ))}
        </div>
      </Container>
    </PageLayout>
  );
}
