import { useEffect, useState } from "react";
import { InvestorCard } from "../../components/common/Cards/InvestorCard";
import { DeveloperCard } from "../../components/common/Cards/DeveloperCard";

import { PaginationElement } from "../../components/common/PaginationElement/PaginationElement";
import { InvestorCardSkeleton } from "../../components/common/Cards/InvestorCardSkeleton";
import { InvestorsFilterSidebar } from "../../components/common/SearchFilterSidebar/InvestorsFilterSidebar";
import { DevelopersFilterSidebar } from "../../components/common/SearchFilterSidebar/DevelopersFilterSidebar";


import { Title } from "../../components/common/Title/Title";
import { PageContent } from "../../components/Layout/PageContent";
import { PageLayout } from "../../components/Layout/PageLayout";
import styles from "./styles.module.scss";
import { InvestorAPI } from "../../APIAction/InvestorAPI";
import { DeveloperApi } from "../../APIAction/DeveloperAPI";

import { useTranslation } from "react-i18next";
import { NoInvestorResultsView } from "./noInvestorResults";

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { useMsal } from "@azure/msal-react";
import i18n from "../../i18n";

export function SearchInvestors() {
  const { t } = useTranslation("filter");
  const initFilters = {
    start: 0,
    pageSize: 6,
    sortDirection: 0,
    filter: {
      provincialFocusArears: [],
      organizationTypes: [],
      provincialFocusAreas: [],
      fundingTypes: [],
      projectSizes: [],
      ableToProvideLandFlag: []
    },
  };
  const [value, setValue] = useState(() => {
    return parseInt(localStorage.getItem('selectedTab') || '0');
  });

  const [filteredResults, setFilteredResults] = useState<any>();
  const [filters, setFilters] = useState(initFilters);
  const [cityList, setCityList] = useState([]);


  const [page, setPage] = useState(1);
  const [loadingData, setloadingData] = useState(true);

  const [selectedFilter, setSelectedFilter] = useState<any>(null);
  const { accounts } = useMsal();

  useEffect(() => {
    const gaUserType =
      accounts[0] && accounts[0].idTokenClaims?.extension_Persona
        ? (accounts[0].idTokenClaims.extension_Persona as string)
        : "";
    const gaUserId = accounts[0] ? accounts[0].localAccountId : "";

    if (accounts[0]) {
      const pageTitle = value === 0 ? "/search/investors" :  "/search/developers"

      if (window.dataLayer[window.dataLayer.length - 1].page_title !== pageTitle) {

        window.dataLayer.push({
          event: "virtual_page_view",
          page_title: pageTitle,
          page_language: i18n.language,
          page_location: window.location.href,
          page_environment: process.env.REACT_APP_ENV
            ? process.env.REACT_APP_ENV
            : "dev",
          user_status: "loggedin",
          user_type: gaUserType.toLowerCase(),
          user_id: gaUserId,
        });
      }
    }

  }, [value]);

  const getFilterResults = async () => {
    try {
      filters.start = (page - 1) * filters.pageSize;
      const res = await InvestorAPI.postInvestorSearch(filters);
      setFilteredResults(res);

      setloadingData(false);
    } catch (e) {
      return;
    }
  };


  const getDeveloperFilterResults = async () => {
    try {
      filters.start = (page - 1) * filters.pageSize;
      const res = await DeveloperApi.postDeveloperSearch(filters);

      setFilteredResults(res);
      setloadingData(false);
    } catch (e) {
      return;
    }
  };

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });

    setloadingData(true);

    if (value === 0) {
      getFilterResults();

    } else {
      getDeveloperFilterResults()

    }
  }, [filters, page, value]);

  const handleChange = async (event: any, newValue: number) => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    setloadingData(true);

    if (newValue === 0) {
      setPage(1);
      setSelectedFilter(null);
      setFilters(initFilters);
      getFilterResults();

    } else {
      setPage(1);
      setSelectedFilter(null);
      setFilters(initFilters);

      getDeveloperFilterResults()

    }

    setValue(newValue);
    localStorage.setItem('selectedTab', newValue.toString()); // Store selected tab index
  };


  const resultsView = loadingData ? (
    <div className={styles.searchResultsContainer} data-testid="search-investor-results-container">
      <InvestorCardSkeleton />
    </div>
  ) : (
    <div>
      {value === 0 ? (
        <div className={styles.searchResultsContainer} data-testid="search-investor-results-container">
          {filteredResults?.data && filteredResults.data.length > 0 && (
            <p className={styles.sortDirection}>
              {t("searchProjectsPage.sortInformation")}
            </p>
          )}
          {filteredResults?.data && filteredResults.data.length > 0 ? (
            //@ts-ignore
            filteredResults.data.map((data: any, idx: number) => (
              <InvestorCard key={idx} data={data} />
            ))
          ) : (
            <NoInvestorResultsView />
          )}
          {filteredResults?.data && filteredResults.totalRecords > 6 && (
            <PaginationElement
              start={filters.start + 1}
              totalRecords={filteredResults.totalRecords}
              page={page}
              setPage={setPage}
              pageSize={filters.pageSize}
            />
          )}
        </div>
      ) : (
        <div className={styles.searchResultsContainer} data-testid="search-investor-results-container">
          {filteredResults?.data && filteredResults.data.length > 0 && (
            <p className={styles.sortDirection}>
              {t("searchProjectsPage.sortInformation")}
            </p>
          )}
          {filteredResults?.data && filteredResults.data.length > 0 ? (
            //@ts-ignore
            filteredResults.data.map((data: any, idx: number) => (
              <DeveloperCard key={idx} data={data} />
            ))
          ) : (
            <NoInvestorResultsView isDeveloper={value === 1} />
          )}
          {filteredResults?.data && filteredResults.totalRecords > 6 && (
            <PaginationElement
              start={filters.start + 1}
              totalRecords={filteredResults.totalRecords}
              page={page}
              setPage={setPage}
              pageSize={filters.pageSize}
            />
          )}
        </div>
      )}
    </div>
  );

  return (
    <PageLayout>
      <Title titleText={t("searchInvestorsPage.title")} subtitle={t("searchInvestorsPage.subtitle")} />
      <PageContent>
        <Box sx={{ width: '100%' }}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" variant="fullWidth">
              <Tab sx={{ textTransform: 'none' }} label={t("searchInvestorsPage.investorTab")} {...a11yProps(0)} />
              <Tab sx={{ textTransform: 'none' }} label={t("searchInvestorsPage.developerTab")} {...a11yProps(1)} />
            </Tabs>
          </Box>
          <CustomTabPanel value={value} index={0}>
            <div className={styles.searchPageContainer} data-testid="search-page-container">
              <InvestorsFilterSidebar
                setFilteredResults={setFilteredResults}
                setFilters={setFilters}
                setPage={setPage}
                selectedFilter={selectedFilter}
                setSelectedFilter={setSelectedFilter}
                setCityList={setCityList}
                cityList={cityList}
              />
              {resultsView}
            </div>
          </CustomTabPanel>
          <CustomTabPanel value={value} index={1}>
            <div className={styles.searchPageContainer} data-testid="search-page-container">
              <DevelopersFilterSidebar
                setFilteredResults={setFilteredResults}
                setFilters={setFilters}
                setPage={setPage}
                selectedFilter={selectedFilter}
                setSelectedFilter={setSelectedFilter}
                setCityList={setCityList}
                cityList={cityList}
              />
              {resultsView}
            </div>
          </CustomTabPanel>
        </Box>
      </PageContent>
    </PageLayout>
  );
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <div>
          {children}
        </div>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
