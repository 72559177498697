import { useTranslation } from "react-i18next";
import { useState, useEffect } from "react";
import styles from "./styles.module.scss";
import { GeneralInfoIcon } from "../../../assets/icons";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { ListingPortfolioAPI } from "../../../APIAction/ListingPortfolioAPI";

export const ReviewPortfolioGeneralInfo = ({
  portfolioListingID,
}: any) => {
  const { t } = useTranslation("profile");
  const navigate = useNavigate();

  const [GeneralInfo, setGeneralInfo] = useState<any>();
  const [isDataExist, setIsDataExist] = useState(false);

  useEffect(() => {
    const getGeneralInfomation = async () => {
      try {
        const res = await ListingPortfolioAPI.getPortfolioGeneralInfoById(
          portfolioListingID
        );
        const data = res.data;
        setIsDataExist(true);
        setGeneralInfo(data);
      } catch (error) {
        setIsDataExist(false);
        if (axios.isAxiosError(error) && error.response?.status !== 404) {
          navigate("/api-error");
        }
      }
    };
    portfolioListingID !== "" && getGeneralInfomation();
  }, [portfolioListingID, navigate]);



  const handleEditClick = () => {
    navigate(`/portfolio/edit/${portfolioListingID}`);
  };

  return (
    <div className={styles.informationContainer}>
      <div className={styles.infoHeadingContainer}>
        <div className={styles.left}>
          <div className={styles.imgContainer}>
            <img src={GeneralInfoIcon} alt="" />
          </div>
          <h3 className={styles.sectionHeading}>
            {t("developer.projects.general_info_form.heading")}
          </h3>
        </div>
        <button className={styles.link} onClick={handleEditClick}>
          {t("developer.projects.review_project.edit_link")}
        </button>
      </div>

      {isDataExist && (
        <div className={styles.content}>
          <div className={styles.grid_container}>
            <p>
              {t("developer.projects.general_info_form.portfolio_name_label")}
            </p>
            <p>{GeneralInfo?.portfolioName}</p>
            <p>
              {t("developer.projects.general_info_form.portfolio_desc_label")}
            </p>
            <p>{GeneralInfo?.portfolioDescription}</p>

          </div>
        </div>
      )}
    </div>
  );
};
