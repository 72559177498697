import { useTranslation } from "react-i18next";
import styles from "./styles.module.scss";
import { dollorIcon } from "../../../assets/icons";
import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { ListingPortfolioAPI } from "../../../APIAction/ListingPortfolioAPI";
import {
  FUNDING_TYPES_PORTFOLIO,
  getKVArrayFormat,
  getEnumKeyByValue,
  INVESTMENT_PHASES,
  ROI_RANGE,
  CAPITALSOURCES_TYPES,
} from "../../../Constants/Enumerations";
import { numberWithCommas } from "../../../Constants/Utils/FormateNumber";

export const ReviewFinancialDetail = ({
  portfolioListingID,
  setCapitalStacking,
}: any) => {
  const { t } = useTranslation(["profile", "onboarding", "project"]);
  const navigate = useNavigate();
  const [FinancialDetailInfo, setFinancialDetailInfo] = useState<any>(
    {} as any
  );
  const [isDataExist, setIsDataExist] = useState(true);

  useEffect(() => {
    const getFinancialDetailsInfo = async () => {
      try {
        const res = await ListingPortfolioAPI.getFinancialInfoById(
          portfolioListingID
        );
        const data = res.data;
        setIsDataExist(true);
        setCapitalStacking(data.displayFundingFlag ? "yes" : "no");

        setFinancialDetailInfo(data);
      } catch (error) {
        setIsDataExist(false);
        if (axios.isAxiosError(error) && error.response?.status !== 404) {
          navigate("/api-error");
        }
      }
    };

    portfolioListingID !== "" && getFinancialDetailsInfo();
  }, [portfolioListingID, navigate]);

  const handleEditClick = () => {
    navigate(`/portfolio/edit/${portfolioListingID}`);
  };

  function renderYesOrNoOptions(truthyValue: boolean) {
    if (truthyValue) {
      return t(
        "profile:developer.projects.financial_details_doc_form.yes_label"
      );
    }
    return t("profile:developer.projects.financial_details_doc_form.no_label");
  }

  const targetRoiMaxMappedValue = getEnumKeyByValue(
    ROI_RANGE,
    FinancialDetailInfo.capitalCostRangeMaximum
  );
  const targetRoiMinMappedValue = getEnumKeyByValue(
    ROI_RANGE,
    FinancialDetailInfo.capitalCostRangeMinimum
  );

  const totalCost = FinancialDetailInfo.totalPortfolioCost?.toString();

  const capitalAcquiredAmount =
    FinancialDetailInfo.capitalAcquiredAmount?.toString();

  const minmiumInvestment =
    FinancialDetailInfo.minimumInvestmentAmount?.toString();

  const targeRoiMinTranslated = t(
    `common:enumerations.roi_range.${targetRoiMinMappedValue}`
  );
  const targeRoiMaxTranslated = t(
    `common:enumerations.roi_range.${targetRoiMaxMappedValue}`
  );
  const targetRoiRangeTranslated = `${targeRoiMinTranslated} - ${targeRoiMaxTranslated}`;

  const fundingTypesToRender = FinancialDetailInfo?.fundingTypes?.map(
    (val: any) => getEnumKeyByValue(FUNDING_TYPES_PORTFOLIO, val)
  );

  const fundingTypesTranslated = fundingTypesToRender
    ?.filter((val: any) => val !== undefined && val !== "")
    .map((val: any) => t(`common:enumerations.funding_types.${val}`));

  const investmentPhasesTranslations = Object.keys(INVESTMENT_PHASES).map(
    (val) => t(`common:enumerations.investment_phases.${val}`)
  );
  const investmentPhasesOptions = getKVArrayFormat(
    INVESTMENT_PHASES,
    investmentPhasesTranslations
  );

  const capitalSourcesTranslations = Object.keys(CAPITALSOURCES_TYPES).map(
    (val) => t(`common:enumerations.capitalSources_types.${val}`)
  );

  const capitalSourcesTypes = getKVArrayFormat(
    CAPITALSOURCES_TYPES,
    capitalSourcesTranslations
  );

  return (
    <div className={styles.informationContainer}>
      <div className={styles.infoHeadingContainer}>
        <div className={styles.left}>
          <div className={styles.imgContainer}>
            <img src={dollorIcon} alt="" />
          </div>
          <h3 className={styles.sectionHeading}>
            {t("developer.projects.financial_details_doc_form.heading")}
          </h3>
        </div>
        <button className={styles.link} onClick={handleEditClick}>
          {t("developer.projects.review_project.edit_link")}
        </button>
      </div>

      {isDataExist && (
        <div className={styles.content}>
          <div className={styles.grid_container}>
            <p>
              <h4>
                {t(
                  "project:project.financial_details.portfolio_title"
                )}{" "}
              </h4>
            </p>
            <p></p>
            <p>{t(
                  "project:project.financial_details.total_portfolio_cost_label"
                )}</p>
            <p>{"$" + numberWithCommas(totalCost ?? "")}</p>
            <p>
              {t(
                "project:project.financial_details.phase_project_label_review"
              )}
            </p>
            <p>
              {FinancialDetailInfo.investmentPhases &&
                FinancialDetailInfo.investmentPhases.length > 0 &&
                FinancialDetailInfo.investmentPhases.map(
                  (val: any, idx: any) => (
                    <span key={idx}>
                      {
                        investmentPhasesOptions.find((obj) => obj.value === val)
                          ?.label
                      }
                      {idx < FinancialDetailInfo.investmentPhases.length - 1
                        ? ", "
                        : " "}
                    </span>
                  )
                )}
            </p>

            <p>
              <h4>
                {t("project:project.financial_details.capital_needed_title")}
              </h4>
            </p>
            <p></p>
            <p>{t("project:project.financial_details.minimum_investment")}</p>
            <p>{"$" + numberWithCommas(minmiumInvestment ?? "")}</p>
            <p>
              {t("project:project.financial_details.capital_injection_label")}
            </p>
            <p>
              {fundingTypesTranslated &&
                fundingTypesTranslated.length > 0 &&
                fundingTypesTranslated.map((val: any, idx: any) => (
                  <span key={idx}>
                    {val}
                    {idx < fundingTypesTranslated.length - 1 ? ", " : " "}
                  </span>
                ))}
            </p>

            {!FinancialDetailInfo.displayFundingFlag && (
              <React.Fragment>
                <p>
                  {t(
                    "developer.projects.financial_details_doc_form.total_funding_needed_label"
                  )}
                </p>
                <p>
                  {"$" +
                    numberWithCommas(
                      FinancialDetailInfo.totalFundingNeeded ?? ""
                    )}
                </p>
              </React.Fragment>
            )}
            {FinancialDetailInfo.displayFundingFlag && (
              <React.Fragment>
                {FinancialDetailInfo.grantAmount && (
                  <React.Fragment>
                    <p>
                      {t(
                        "project:project.financial_details.granting"
                      )}
                    </p>
                    <p>
                      {"$" +
                        numberWithCommas(FinancialDetailInfo.grantAmount ?? "")}
                    </p>
                  </React.Fragment>
                )}

                {FinancialDetailInfo.equityAmount && (
                  <React.Fragment>
                    <p>
                      {t(
                        "project:project.financial_details.equity"
                      )}
                    </p>
                    <p>
                      {"$" +
                        numberWithCommas(
                          FinancialDetailInfo.equityAmount ?? ""
                        )}
                    </p>
                  </React.Fragment>
                )}

                {FinancialDetailInfo.debtFinancingAmount && (
                  <React.Fragment>
                    <p>
                      {t(
                        "project:project.financial_details.debt_financing"
                      )}
                    </p>
                    <p>
                      {"$" +
                        numberWithCommas(
                          FinancialDetailInfo.debtFinancingAmount ?? ""
                        )}
                    </p>
                  </React.Fragment>
                )}

                {FinancialDetailInfo.bridgeFinancingAmount && (
                  <React.Fragment>
                    <p>
                      {t(
                        "project:project.financial_details.bridge_financing"
                      )}
                    </p>
                    <p>
                      {"$" +
                        numberWithCommas(
                          FinancialDetailInfo.bridgeFinancingAmount ?? ""
                        )}
                    </p>
                  </React.Fragment>
                )}

                {FinancialDetailInfo.anyCapitalAmount && (
                  <React.Fragment>
                    <p>
                      {t(`project:project.financial_details.anyTypeofCapital`)}
                    </p>
                    <p>
                      {"$" +
                        numberWithCommas(
                          FinancialDetailInfo.anyCapitalAmount ?? ""
                        )}
                    </p>
                  </React.Fragment>
                )}
              </React.Fragment>
            )}

            <p>{t("project:project.financial_details.co_investing_label")}</p>
            <p>
              {FinancialDetailInfo?.coInvestingFlag != null
                ? renderYesOrNoOptions(FinancialDetailInfo.coInvestingFlag)
                : ""}
            </p>
            {FinancialDetailInfo?.lockInPeriodRequiredFlag != null && (
              <>
                <p>
                  {t("project:project.financial_details.lockIn_period_viewPage_label")}
                </p>

                <p>
                  {renderYesOrNoOptions(
                    FinancialDetailInfo.lockInPeriodRequiredFlag
                  )}
                </p>
              </>
            )}
            {FinancialDetailInfo.lockInPeriodDetailDescription && (
              <>
                <p>
                  {t(
                    "project:project.financial_details.lockIn_period_description"
                  )}
                </p>
                <p>{FinancialDetailInfo.lockInPeriodDetailDescription}</p>
              </>
            )}
            <p>
              <h4>
                {t("project:project.financial_details.capital_acquired_title_review")}
              </h4>
            </p>
            <p></p>
            <p>
              {t("project:project.financial_details.capital_secured_label")}
            </p>
            <p>{"$" + numberWithCommas(capitalAcquiredAmount ?? "")}</p>
            <p>
              {t("project:project.financial_details.capital_sources_label_review")}
            </p>
            <p>
              {FinancialDetailInfo.capitalSources &&
                FinancialDetailInfo.capitalSources.length > 0 &&
                FinancialDetailInfo.capitalSources.map((val: any, idx: any) => (
                  <span key={idx}>
                    {
                      capitalSourcesTypes.find((obj) => obj.value === val)
                        ?.label
                    }
                    {idx < FinancialDetailInfo.capitalSources.length - 1
                      ? ", "
                      : " "}
                  </span>
                ))}
            </p>

            <p>
              {t(
                "project:project.financial_details.current_expected_roi_label"
              )}
            </p>
            <p>{targetRoiRangeTranslated}</p>

            <p>
              {t("project:project.financial_details.additional_details_label")}
            </p>
            <p>{FinancialDetailInfo?.additionalCapitalAcquiredDetails}</p>
          </div>
        </div>
      )}
    </div>
  );
};
