import styles from "./styles.module.scss";

type Props = {
  financialDetails: { label: string; value: string }[];
};

export function IterableFinancialDetails({ financialDetails }: Props) {
  return (
    <div className={styles.financialDetails}>
      {financialDetails.map((detail, idx) => (

        <div key={idx} className={styles.financialDetailContent}>
          <p className={styles.iterableLabel}>{detail.label}</p>
          <p className={styles.iterableValue}>{detail.value ? detail.value : "undefined"}</p>
        </div>
      ))}
    </div>
  );
}
