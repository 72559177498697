import { useTranslation } from "react-i18next";
import { PageLayout } from "../../components/Layout/PageLayout";
import { EarlyAccessBanner } from "../../components/common/EarlyAccessBanner/EarlyAccessBanner";
import { Title } from "../../components/common/Title/Title";
import { TwoColumnLayout } from "../../components/common/TwoColumnLayout/TwoColumnLayout";
import { PageContent } from "../../components/Layout/PageContent";
import { ProblemStatementAbout } from "./ProblemStatementAbout/ProblemStatementAbout";
import { useUser } from "../../components/Context/UserContext";


export const AboutUs = () => {
  const { t } = useTranslation("about");
  const { userState } = useUser();

  if (userState.userId !== "") {
    return (
      <div>

        <TwoColumnLayout
          title={t("ourStory.title")}
          column1={t("ourStory.column1")}
          column2={t("ourStory.column2")}
        />

        <ProblemStatementAbout />

        <TwoColumnLayout
          title={t("aboutCHMC.title")}
          column1={t("aboutCHMC.column1")}
          column2={t("aboutCHMC.column2")}
          externalLink={t("aboutCHMC.external_link_url")}
          linkText={t("aboutCHMC.external_link_text")}
        />
      </div>
    )

  } else {
    return (
      <PageLayout>

        <Title titleText={t("aboutCapitalConnect")} />
        <PageContent>
          <TwoColumnLayout
            title={t("ourStory.title")}
            column1={t("ourStory.column1")}
            column2={t("ourStory.column2")}
          />

          <ProblemStatementAbout />

          <EarlyAccessBanner
            heading={t("earlyAccess.header")}
            content={t("earlyAccess.content")}
            requestaccessLink={t("earlyAccess.request_access")}
          />

          <TwoColumnLayout
            title={t("aboutCHMC.title")}
            column1={t("aboutCHMC.column1")}
            column2={t("aboutCHMC.column2")}
            externalLink={t("aboutCHMC.external_link_url")}
            linkText={t("aboutCHMC.external_link_text")}
          />
        </PageContent>
      </PageLayout>


    );
  }


};
