import styles from "./styles.module.scss";
import { ErrorOutlined } from "../../../assets/icons";

type Props = {
    title: string;
    content: string;
    visitFaqLinkText: string;
    handleClick: () => void;
}
export const InformationalNotice = (props: Props) => {
    const {
        title,
        content,
        visitFaqLinkText,
        handleClick
    } = props;
    return (
        <div className={styles.informationalContainer}>
            <div className={styles.titleContainer} data-testid="title-container">
                <img
                    src={ErrorOutlined}
                    alt=""
                    className={styles.icon}
                    data-testid="exclamation-Icon"
                />
                <h4 className={styles.title} data-testid="pageTitle">
                    {title}
                </h4>
            </div>
            <div className={styles.contentContainer} data-tesid="content-container">
                <p className={styles.para}>
                    {content}
                    <button className={styles.link} onClick={handleClick} data-testid="visit-faq-link">
                        {visitFaqLinkText}
                    </button>
                </p>
            </div>
        </div>
    )
}