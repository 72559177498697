import styles from "./Affordability.module.scss";
import { useTranslation } from "react-i18next";
import affordablity_desktop from "../../../../assets/images/affordability_desktop.png";

export function Affordability() {
  const { t } = useTranslation("landing");

  return (
    <div className={styles.affordability}>
      <div className={styles.textContainer}>
        <h2 className={styles.heading} data-testid="affordability-heading">
          {t("landing:affordability_banner.heading")}
        </h2>
        <h3 className={styles.body} data-testid="affordability-text">
          {t("landing:affordability_banner.summary")}
        </h3>
      </div>
      <div className={styles.imageContainer} data-testid="affordability-image">
        <img
          className={styles.affordabilityImage}
          src={affordablity_desktop}
          alt=""
        />
      </div>
    </div>
  );
}
