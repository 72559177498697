import styles from "./styles.module.scss";
import { Form, Formik, FieldArray } from "formik";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { TextArea } from "../common/TextArea";
import { TextInput } from "../common/TextInput";
import { DateInput } from "../common/DateInput";
import { ProvincesSelect } from "../common/ProvincesSelect";
import { ListingPortfolioAPI } from "../../../APIAction/ListingPortfolioAPI";
import React, { useCallback, useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { RemoveProject } from "../../../pages/Developers/Modals/RemoveProject";

import {
  schemaDateComparison,
  schemaDecimalTest,
} from "./PortfolioGeneralInformationHelpers";
import { handleApiErrorRedirect } from "../../../APIAction/config";
import { RadioInput } from "../common/RadioInput";
import {
  getKVArrayFormatForDropdown,
  LOCKUP_PERIOD,
  COMMITMENT_TO_AFFORDABILITY,
  PROVINCES_CODES,
} from "../../../Constants/Enumerations";

import { numberWithCommas } from "../../../Constants/Utils/FormateNumber";

import { FieldArrayRemoveButton } from "../common/FieldArrayRemoveButton";
import { FieldArrayAddButton } from "../common/FieldArrayAddButton";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { NumberInput } from "../common/NumberInput";
import axios from "axios";
import { SelectInput } from "../common/SelectInput";
import { RadioInputGroup } from "../common/RadioInputGroup";
import { ProjectLocationFields } from "../AddProject/ProjectLocationFields";
import Dropzone from 'react-dropzone';
import { BlobServiceClient } from '@azure/storage-blob';
import { CloudUpload, CloseIconSvg } from "../../../assets/icons";
import { UploadLoading, ErrorImage } from "../../../assets/images";
import CircularProgress from '@mui/material/CircularProgress';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { City } from "country-state-city";
import { GlossaryDefinition } from "../../../pages/Developers/Modals/GlossaryDefinitionModal/GlossaryDefinition";
import { checkFileSignature } from "../../../Constants/mimeTypeFunction";

export function ProjectsWithinPortfolioForm({
  handleResponse,
  portfolioListingId,
  selectedAccordion,
  isProjectsWithinPortfolioSubmit
}: any) {
  const apiErrorPage = "/api-error";

  const { t, i18n } = useTranslation(["profile", "onboarding"]);
  
  const [expand, setExpand] = useState([true]);
  const navigate = useNavigate();
  const [projectsWithinPortfolio, setProjectsWithinPortfolio] = useState<any>();
  const [isDataExist, setIsDataExist] = useState(false);
  const [removeProject, setRemoveProject] = useState<boolean>(false);
  const [projectAffordabilityTypes, setAffordabilityType] = useState<number[]>(
    []
  );
  const [isOpenAffordabilityModal, setIsOpenAffordabilityModal] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false); 
  
  const [isApiLoading, setIsApiLoading] = useState(false);

  const [images, setImages] = useState<any[]>([[]]);
  const [open, setOpen] = useState({ switch: false, message: "success" });


  const account = process.env.REACT_APP_QUARANTINE_STORAGE_ACCOUNT_NAME;
  const sasToken = process.env.REACT_APP_QUARANTINE_SAS_TOKEN;
  const sasTokenDocumentStorage = process.env.REACT_APP_DOCUMENT_SAS_TOKEN;

  const containerName = process.env.REACT_APP_QUARANTINE_CONTAINER_NAME;
  const blobServiceClient = new BlobServiceClient(`https://${account}.blob.core.windows.net/${sasToken}`);
  //@ts-ignore
  const containerClient = blobServiceClient.getContainerClient(containerName);

  const totalUnitPortfolioProjectValidation = (portfolioProjects: any, totalUnitPortfolioProject: number) => {
    if (!portfolioProjects || !portfolioProjects.length) return true;
                
    const sumOfUnitCounts = portfolioProjects.reduce((acc: any, project: any) => acc + (+project.unitCount || 0), 0);

    return totalUnitPortfolioProject !== undefined && totalUnitPortfolioProject >= sumOfUnitCounts;
  }

  const ref = useRef(null);
  const lockupPeriodTrans = Object.keys(LOCKUP_PERIOD).map((val) =>
    t(`common:enumerations.lockup_period.${val}`)
  );
  const lockupPeriodPlaceholder = {
    label: t("developer.projects.financial_details_doc_form.select_value_text"),
    value: "",
  };
   const handleSubmit = (values: any, setErrors :any ) => {
    
          let updatedValues = null;
          let totalProjects = 0;          
          if (
            values.displayProjectDetailsFlag === "true" ||
            values.displayProjectDetailsFlag === true
          ) {
            for (let i = 0; i < images.length; i++) {
              for (let j = 0; j < images[i].length; j++) {
                if (images[i][j].imgError && images[i][j].blobStatus !== 2) {
                  return;
                }
              }
            }
            let {
              displayProjectDetailsFlag,
              totalProjectCount,
              belowAverageMarketRentUnitCount,
              portfolioProjects,
              affordabilityTypesProjectCount,
              totalUnitPortfolioProject,
            } = values;

            let affordabilityTypesProjectCountTemp = JSON.parse(JSON.stringify(affordabilityTypesProjectCount));

            if (affordabilityTypesProjectCountTemp?.socialImpactingHousingCount && projectAffordabilityTypes.includes(1)) {
              affordabilityTypesProjectCountTemp.socialImpactingHousingCount =
                Number(
                  affordabilityTypesProjectCountTemp?.socialImpactingHousingCount
                );

              totalProjects =
                totalProjects +
                Number(
                  affordabilityTypesProjectCountTemp?.socialImpactingHousingCount
                );
            }
            if (affordabilityTypesProjectCountTemp?.focusOnAffordableHousingCount && projectAffordabilityTypes.includes(2)) {
              affordabilityTypesProjectCountTemp.focusOnAffordableHousingCount =
                Number(
                  affordabilityTypesProjectCountTemp?.focusOnAffordableHousingCount
                );

              totalProjects =
                totalProjects +
                Number(
                  affordabilityTypesProjectCountTemp?.focusOnAffordableHousingCount
                );
            }
            if (
              affordabilityTypesProjectCountTemp?.balanceBetweenAffordableAndSupplyCount && projectAffordabilityTypes.includes(3)
            ) {
              affordabilityTypesProjectCountTemp.balanceBetweenAffordableAndSupplyCount =
                Number(
                  affordabilityTypesProjectCountTemp?.balanceBetweenAffordableAndSupplyCount
                );
              totalProjects =
                totalProjects +
                Number(
                  affordabilityTypesProjectCountTemp?.balanceBetweenAffordableAndSupplyCount
                );
            }
            if (affordabilityTypesProjectCountTemp?.focusOnHousingSupplyCount && projectAffordabilityTypes.includes(4)) {
              affordabilityTypesProjectCountTemp.focusOnHousingSupplyCount = Number(
                affordabilityTypesProjectCountTemp?.focusOnHousingSupplyCount
              );
              totalProjects =
                totalProjects +
                Number(
                  affordabilityTypesProjectCountTemp?.focusOnHousingSupplyCount
                );
            }
            let portfolioProjectsTemp = JSON.parse(JSON.stringify(portfolioProjects));

            for (let i = 0; i < portfolioProjectsTemp.length; i++) {
              portfolioProjectsTemp[i].constructionType = Number(
                portfolioProjectsTemp[i].constructionType
              );

              portfolioProjectsTemp[i].projectPhase = Number(
                portfolioProjectsTemp[i]?.projectPhase
              );

              portfolioProjectsTemp[i].landStatus = Number(
                portfolioProjectsTemp[i]?.landStatus
              );

              portfolioProjectsTemp[i].unitPercentBelowMarketRent = Number(
                portfolioProjectsTemp[i]?.unitPercentBelowMarketRent
              );
              portfolioProjectsTemp[i].unitCount = Number(
                portfolioProjectsTemp[i]?.unitCount
              );
              portfolioProjectsTemp[i].affordabilityCommitment = Number(
                portfolioProjectsTemp[i]?.affordabilityCommitment
              );
              portfolioProjectsTemp[i].province = Number(
                portfolioProjectsTemp[i]?.province
              );
              portfolioProjectsTemp[i].blobs = images[i];

            }

            let totalProjectCountTemp = JSON.parse(JSON.stringify(totalProjectCount));
            let totalUnitPortfolioProjectTemp = JSON.parse(JSON.stringify(totalUnitPortfolioProject));
            let belowAverageMarketRentUnitCountTemp = JSON.parse(JSON.stringify(belowAverageMarketRentUnitCount));

            updatedValues = {
              portfolioID: portfolioListingId,
              displayProjectDetailsFlag:
                displayProjectDetailsFlag === "true" ? true : false,
              totalProjectCount: Number(totalProjectCountTemp),
              totalUnitPortfolioProject: Number(totalUnitPortfolioProjectTemp),
              belowAverageMarketRentUnitCount: Number(belowAverageMarketRentUnitCountTemp),
              portfolioProjects: portfolioProjectsTemp,
              affordabilityTypesProjectCount: affordabilityTypesProjectCountTemp,

            };
          } else {
            let {
              displayProjectDetailsFlag,
              totalProjectCount,
              totalUnitPortfolioProject,
              belowAverageMarketRentUnitCount,
              developmentRegions,
              portfolioProjectsSummaryDescription,
              affordabilityTypesProjectCount,
            } = values;
            let affordabilityTypesProjectCountTemp = JSON.parse(JSON.stringify(affordabilityTypesProjectCount));

            if (affordabilityTypesProjectCountTemp.socialImpactingHousingCount && projectAffordabilityTypes.includes(1)) {
              affordabilityTypesProjectCountTemp.socialImpactingHousingCount =
                Number(
                  affordabilityTypesProjectCountTemp.socialImpactingHousingCount
                );
              totalProjects =
                totalProjects +
                Number(
                  affordabilityTypesProjectCountTemp.socialImpactingHousingCount
                );
            }
            if (affordabilityTypesProjectCountTemp.focusOnAffordableHousingCount && projectAffordabilityTypes.includes(2)) {
              affordabilityTypesProjectCountTemp.focusOnAffordableHousingCount =
                Number(
                  affordabilityTypesProjectCountTemp.focusOnAffordableHousingCount
                );
              totalProjects =
                totalProjects +
                Number(
                  affordabilityTypesProjectCountTemp.focusOnAffordableHousingCount
                );
            }
            if (
              affordabilityTypesProjectCountTemp.balanceBetweenAffordableAndSupplyCount && projectAffordabilityTypes.includes(3)
            ) {
              affordabilityTypesProjectCountTemp.balanceBetweenAffordableAndSupplyCount =
                Number(
                  affordabilityTypesProjectCountTemp.balanceBetweenAffordableAndSupplyCount
                );
              totalProjects =
                totalProjects +
                Number(
                  affordabilityTypesProjectCountTemp.balanceBetweenAffordableAndSupplyCount
                );
            }
            if (affordabilityTypesProjectCountTemp.focusOnHousingSupplyCount && projectAffordabilityTypes.includes(4)) {
              affordabilityTypesProjectCountTemp.focusOnHousingSupplyCount = Number(
                affordabilityTypesProjectCountTemp.focusOnHousingSupplyCount
              );
              totalProjects =
                totalProjects +
                Number(
                  affordabilityTypesProjectCountTemp.focusOnHousingSupplyCount
                );
            }

            let developmentRegionsTemp = JSON.parse(JSON.stringify(developmentRegions));

            developmentRegionsTemp = developmentRegionsTemp.map((val: any) => {
              return {
                cityOrRegion: val.city,
                province: Number(val.province),
              };
            });

            let totalProjectCountTemp = JSON.parse(JSON.stringify(totalProjectCount));
            let totalUnitPortfolioProjectTemp = JSON.parse(JSON.stringify(totalUnitPortfolioProject));
            let belowAverageMarketRentUnitCountTemp = JSON.parse(JSON.stringify(belowAverageMarketRentUnitCount));

            updatedValues = {
              portfolioID: portfolioListingId,
              displayProjectDetailsFlag:
                displayProjectDetailsFlag === "true" ? true : false,
              totalProjectCount: Number(totalProjectCountTemp),
              totalUnitPortfolioProject: Number(totalUnitPortfolioProjectTemp),
              belowAverageMarketRentUnitCount: Number(belowAverageMarketRentUnitCountTemp),
              portfolioProjectsSummaryDescription:
                portfolioProjectsSummaryDescription,
              developmentRegions: developmentRegionsTemp,
              affordabilityTypesProjectCount: affordabilityTypesProjectCountTemp,
            };
          }
          // setTransValues(updatedValues);
          // setSubmitTotalProjects(totalProjects);
          // setFormikErrorsSetter(() => setErrors);
          const validationCheck = validateForm(
            updatedValues,
            totalProjects,
            setErrors
          );

          if (validationCheck) {
            isDataExist
              ? updatePortfolioProjectsInformation(updatedValues)
              : submitPortfolioProjectsInformation(updatedValues);
          }
        };        
     
  const maximumLockupPeriodOptions: {
    label: string;
    value: string | number;
  }[] = getKVArrayFormatForDropdown(LOCKUP_PERIOD, lockupPeriodTrans);
  maximumLockupPeriodOptions.unshift(lockupPeriodPlaceholder);

  const maxCharsErrorText = t(
    "developer.projects.general_form_error.max_characters",
    { charNum: 64 }
  );

  const maxCharsErrorTextProjectCount = t(
    "developer.projects.general_form_error.max_characters",
    { charNum: 50 }
  );
  const requiredError = t("developer.projects.financial_details_form_error.required_error")
  const spacingErrorText = t("developer.projects.general_form_error.no_spaces");
  const regxNotAllowOnlySpace = /^(?!\s*$)/;
  const projectWithinPortfolioSchema = Yup.object().shape({
    totalProjectCount: Yup.number().required(requiredError)
    .min(1,t("developer.projects.add_listing_form.number_above_zero"))
      .test({
        name: "totalProjectCount-Field",
        message: t("developer.projects.add_listing_form.total_project_count_error"),
        test: function (totalProjectCount: number | undefined): boolean {
          if (totalProjectCount !== undefined) {
            const { portfolioProjects } = this.parent;
            if (portfolioProjects.length > totalProjectCount) {
              return false; // Validation failed
            }
          }
          return true; // Validation passed
        }
      }),
    totalUnitPortfolioProject: Yup.number().required(requiredError)
      .test({
        name: 'units-total',
        message: t("developer.projects.add_listing_form.total_unit_error"),
        test: function (totalUnitPortfolioProject: number | undefined) {
          const { portfolioProjects } = this.parent;
          if (!portfolioProjects || !portfolioProjects.length) return true;

          return totalUnitPortfolioProjectValidation(portfolioProjects, totalUnitPortfolioProject!);
        }
      })
      .min(1, t("developer.projects.general_form_error.pattern_error_num_below")),

    belowAverageMarketRentUnitCount: Yup.number()
      .required(requiredError)
      .min(20, t("developer.projects.add_listing_form.belowAverageMarketRentUnitCountError"))
      .max(100, t("Value should not be greater than 100")),
    displayProjectDetailsFlag: Yup.boolean().required(
      requiredError
    ),
    affordabilityTypesProjectCount: Yup.object().shape({
      socialImpactingHousingCount: projectAffordabilityTypes.includes(1) ? Yup.number()
        .required(requiredError)
        .min(1, t("developer.projects.add_listing_form.number_above_zero")) : Yup.number(),
      focusOnAffordableHousingCount: projectAffordabilityTypes.includes(2) ? Yup.number()
        .required(requiredError)
        .min(1, t("developer.projects.add_listing_form.number_above_zero")) : Yup.number(),
      balanceBetweenAffordableAndSupplyCount: projectAffordabilityTypes.includes(3) ? Yup.number()
        .required(requiredError)
        .min(1, t("developer.projects.add_listing_form.number_above_zero")) : Yup.number(),
      focusOnHousingSupplyCount: projectAffordabilityTypes.includes(4) ? Yup.number()
        .required(requiredError)
        .min(1, t("developer.projects.add_listing_form.number_above_zero")) : Yup.number(),
    }),
    portfolioProjects: Yup.array()
      .of(
        Yup.object().shape({
          projectName: Yup.string()
            .required(
              requiredError
            )
            .max(64, maxCharsErrorText)
            .matches(regxNotAllowOnlySpace, spacingErrorText),
          province: Yup.number()
            .required(
              requiredError
            )
            .max(64, maxCharsErrorText),
          city: Yup.string()
            .required(t("developer.projects.general_form_error.city_required"))
            .max(64, maxCharsErrorText)
            .matches(regxNotAllowOnlySpace, spacingErrorText),
          unitCount: Yup.number()
            .required(
              t("developer.projects.general_form_error.unit_num_required")
            )
            .test({
              name: 'units-total',
              message: t("developer.projects.add_listing_form.total_unit_error"),
              test: function (unitCount: number | undefined) {
                //@ts-ignore
              const totalUnitPortfolioProject = Number(this.from[1].value.totalUnitPortfolioProject);
  
                //@ts-ignore
  
                const portfolioProjects = this.from[1].value.portfolioProjects;
  
                const sumOfUnitCounts = portfolioProjects.reduce((acc: any, project: any) => acc + (+project.unitCount || 0), 0);
  
                return totalUnitPortfolioProject !== undefined && totalUnitPortfolioProject >= sumOfUnitCounts;
  
              }

            })
            .min(1, t("developer.projects.general_form_error.unit_num_required")),
          unitPercentBelowMarketRent: Yup.number()
            .required(
              t("developer.projects.general_form_error.percent_unit_required")
            )
            .max(
              100,
              t("Value should not be greater than 100")
            )
            .min(
              20,
              t("developer.projects.add_listing_form.belowAverageMarketRentUnitCountError")
            )
            .test(
              "is-decimal",
              t("developer.projects.general_form_error.percentage_decimal"),
              (val: any) => schemaDecimalTest(val)
            ),
          affordabilityCommitment: Yup.string().required(
            t(
              "developer.projects.financial_details_form_error.roi_lockupperiod_required"
            )
          ),
          projectDescription: Yup.string()
            .required(
              t("developer.projects.general_form_error.project_desc_required")
            )
            .max(
              300,
              t("developer.projects.general_form_error.max_characters", {
                charNum: 300,
              })
            )
            .matches(regxNotAllowOnlySpace, spacingErrorText),
          constructionType: Yup.string().required(
            t(
              "developer.projects.financial_details_form_error.construction_type_required"
            )
          ),
          projectPhase: Yup.string().required(
            t(
              "developer.projects.financial_details_form_error.project_phase_required"
            )
          ),
          landStatus: Yup.string().required(
            t("developer.projects.financial_details_form_error.landstatus_required")
          ),
        })
      )
      .max(5, "A maximum of 5 is permitted")
      .nullable(true),
  });

  const projectWithinPortfolioSchema2 = Yup.object().shape({
    totalProjectCount: Yup.number().required(
      requiredError
    )
    .min(1,t("developer.projects.add_listing_form.number_above_zero")),
    affordabilityTypesProjectCount: Yup.object().shape({
      socialImpactingHousingCount: projectAffordabilityTypes.includes(1) ? Yup.number()
        .required(requiredError)
        .min(1, t("developer.projects.add_listing_form.number_above_zero")) : Yup.number(),
      focusOnAffordableHousingCount: projectAffordabilityTypes.includes(2) ? Yup.number()
        .required(requiredError)
        .min(1, t("developer.projects.add_listing_form.number_above_zero")) : Yup.number(),
      balanceBetweenAffordableAndSupplyCount: projectAffordabilityTypes.includes(3) ? Yup.number()
        .required(requiredError)
        .min(1, t("developer.projects.add_listing_form.number_above_zero")) : Yup.number(),
      focusOnHousingSupplyCount: projectAffordabilityTypes.includes(4) ? Yup.number()
        .required(requiredError)
        .min(1, t("developer.projects.add_listing_form.number_above_zero")) : Yup.number(),
    }),
    totalUnitPortfolioProject: Yup.number().required(
      requiredError
    )
      .min(1, t("developer.projects.general_form_error.pattern_error_num_below"))
    ,
    belowAverageMarketRentUnitCount: Yup.number()
      .required(requiredError)
      .min(20, t("developer.projects.add_listing_form.belowAverageMarketRentUnitCountError"))
      .max(100, t("Value should not be greater than 100")),
    displayProjectDetailsFlag: Yup.boolean().required(
      requiredError
    ),
    developmentRegions: Yup.array()
      .of(
        Yup.object().shape({
          province: Yup.number()
            .required(
              requiredError
            ),
          city: Yup.string()
            .required(t("developer.projects.general_form_error.city_required"))
            .max(64, maxCharsErrorText)
            .matches(regxNotAllowOnlySpace, spacingErrorText),
        })
      )
      .max(5, "A maximum of 5 is permitted"),
  });

  const projectInfoValues = {
    projectName: "",
    province: "",
    city: "",
    unitCount: "",
    unitPercentBelowMarketRent: "",
    affordabilityCommitment: "",
    landStatus: "",
    constructionType: "",
    projectPhase: "",
    projectDescription: "",
    expand: "",
  };

  const regionalFocusValues = {
    province: "",
    city: "",
  };
  const affordabilityTypesProjectCountValues = {
    socialImpactingHousingCount: "",
    focusOnAffordableHousingCount: "",
    focusOnHousingSupplyCount: "",
    balanceBetweenAffordableAndSupplyCount: ""
  };
 
  const initialValues = {
    totalProjectCount: "",
    totalUnitPortfolioProject: "",
    belowAverageMarketRentUnitCount: "",
    displayProjectDetailsFlag: "",
    portfolioProjects: [projectInfoValues],
    province: "",
    city: "",
    developmentRegions: [regionalFocusValues],
    portfolioProjectsSummaryDescription: "",
    affordabilityTypesProjectCount: affordabilityTypesProjectCountValues
  };

  let temp: any = [];
  let temp2: any = affordabilityTypesProjectCountValues;

  if (projectsWithinPortfolio?.portfolioProjects) {
    temp = projectsWithinPortfolio?.portfolioProjects.map((val: any) => {

      const selectedProvince = PROVINCES_CODES.find((element) => element.value === Number(val?.province));
      const isoCode = selectedProvince && selectedProvince.isoCode;
      const cityList = isoCode && City.getCitiesOfState('CA', isoCode);
      const formattedCityOptions = cityList && cityList.map(city => ({ label: city.name, value: city.name }));

      // Check if the user's city code matches any city in the city list
      const matchingCity = formattedCityOptions && formattedCityOptions.find(city => city.value === val?.city);
      return {
        city: matchingCity ? matchingCity.label : "",
        developmentImpactDescription: val.developmentImpactDescription,
        projectDescription: val.projectDescription,
        projectName: val.projectName,
        province: val.province,
        unitCount: val.unitCount,
        affordabilityCommitment: val.affordabilityCommitment.toString(),

        targetCompletionDate: val.targetCompletionDate,
        targetStartDate: val.targetStartDate,

        projectPhase: val.projectPhase.toString(),
        constructionType: val.constructionType.toString(),
        unitPercentBelowMarketRent: val.unitPercentBelowMarketRent.toString(),
        landStatus: val.landStatus.toString(),
      };
    });
  }

  if (projectsWithinPortfolio?.affordabilityTypesProjectCount) {

    temp2.socialImpactingHousingCount =
      projectAffordabilityTypes.includes(1) ? projectsWithinPortfolio?.affordabilityTypesProjectCount?.socialImpactingHousingCount &&
        projectsWithinPortfolio?.affordabilityTypesProjectCount?.socialImpactingHousingCount?.toString() : "";

    temp2.focusOnAffordableHousingCount =
      projectAffordabilityTypes.includes(2) ? projectsWithinPortfolio?.affordabilityTypesProjectCount?.focusOnAffordableHousingCount &&
        projectsWithinPortfolio?.affordabilityTypesProjectCount?.focusOnAffordableHousingCount?.toString() : "";

    temp2.balanceBetweenAffordableAndSupplyCount =
      projectAffordabilityTypes.includes(3) ? projectsWithinPortfolio?.affordabilityTypesProjectCount?.balanceBetweenAffordableAndSupplyCount &&
        projectsWithinPortfolio?.affordabilityTypesProjectCount?.balanceBetweenAffordableAndSupplyCount?.toString() : "";

    temp2.focusOnHousingSupplyCount =
      projectAffordabilityTypes.includes(4) ? projectsWithinPortfolio?.affordabilityTypesProjectCount?.focusOnHousingSupplyCount &&
        projectsWithinPortfolio?.affordabilityTypesProjectCount?.focusOnHousingSupplyCount?.toString() : "";

  }

  const getCityListForProvince = (province: any) => {
    const selectedProvince = PROVINCES_CODES.find((element) => element.value === Number(province));
    if (selectedProvince) {
      const isoCode = selectedProvince.isoCode;
      return City.getCitiesOfState('CA', isoCode).map((city) => city.name);
    }
    return [];
  };

  // Function to check if city exists for a given province
  const checkCityExistence = (province: any, cityList: any) => {
    const city = projectsWithinPortfolio?.developmentRegions?.find((item: any) => item.province === province)?.cityOrRegion;
    return city && cityList.includes(city) ? city : '';
  };

  // Iterate over the data array and check if city exists for each province
  const developmentRegionsData = projectsWithinPortfolio?.developmentRegions?.map((item: any) => ({
    province: item.province,
    city: checkCityExistence(item.province, getCityListForProvince(item.province)),
  }));

  const apiData = {
    totalProjectCount:
      projectsWithinPortfolio?.totalProjectCount &&
      numberWithCommas(projectsWithinPortfolio?.totalProjectCount.toString()),
    displayProjectDetailsFlag:
      projectsWithinPortfolio?.displayProjectDetailsFlag === true
        ? "true"
        : "false",
    portfolioProjects: temp,
    affordabilityTypesProjectCount: temp2,
    province: "",
    city: "",
    developmentRegions: projectsWithinPortfolio?.developmentRegions.length === 0 ? [regionalFocusValues] : developmentRegionsData,
    portfolioProjectsSummaryDescription:
      projectsWithinPortfolio?.portfolioProjectsSummaryDescription
        ? projectsWithinPortfolio?.portfolioProjectsSummaryDescription
        : "",
    totalUnitPortfolioProject: projectsWithinPortfolio?.totalUnitPortfolioProject &&
      projectsWithinPortfolio?.totalUnitPortfolioProject.toString(),
    belowAverageMarketRentUnitCount: projectsWithinPortfolio?.belowAverageMarketRentUnitCount
      &&
      numberWithCommas(projectsWithinPortfolio?.belowAverageMarketRentUnitCount.toString())
  };

  const constructionTypeOption = [
    {
      label: t("developer.projects.financial_details_doc_form.project_type_1"),
      value: "1",
    },
    {
      label: t("developer.projects.financial_details_doc_form.project_type_2"),
      value: "2",
    },
  ];
  const getPortfolioProjectsInfo = useCallback(
    async (id: string) => {
      try {
        setIsApiLoading(true);
        const res = await ListingPortfolioAPI.getPortfolioProjectsInfoById(id);
        const data = res.data;
        setIsApiLoading(false);
        setIsDataExist(true);

        if (data.portfolioProjects.length !== 0) {
          let arr = [];
          for (let i = 0; i < data.portfolioProjects.length; i++) {
            if (data.portfolioProjects[i].blobs) {
              arr.push(data.portfolioProjects[i].blobs);
            } else {
              arr.push([]);

            }
          }
          setImages([...arr]);
        }
        setProjectsWithinPortfolio(data);

      } catch (error) {
        setIsApiLoading(false);
        setIsDataExist(false);
        handleApiErrorRedirect(error, navigate);
      }
    },
    [navigate]
  );

  const getAffordabilityBenchmarkInfo = async (id: string) => {
    try {
      setIsApiLoading(true);
      const res = await ListingPortfolioAPI.getPortfolioAffordabilityInfoById(
        id
      );
      const data = res.data;
      setIsApiLoading(false);

      setAffordabilityType(data.projectAffordabilityTypes);
    } catch (error) {
      setIsApiLoading(false);
      handleApiErrorRedirect(error, navigate);
    }
  };
  const commitmentAffordabilityTrans = Object.keys(
    COMMITMENT_TO_AFFORDABILITY
  ).map((val) => t(`common:enumerations.commitment_to_affordability.${val}`));
  const commitmentAffordabilityPlaceholder = {
    label: t("developer.projects.add_listing_form.select_value_option"),
    value: "",
  };
  const commitmentAffordabilityOptions: {
    label: string;
    value: string | number;
  }[] = getKVArrayFormatForDropdown(
    COMMITMENT_TO_AFFORDABILITY,
    commitmentAffordabilityTrans
  );
  commitmentAffordabilityOptions.unshift(commitmentAffordabilityPlaceholder);

  const radioOptions = [
    {
      label: t("developer.projects.financial_details_doc_form.yes_label"),
      value: "true",
    },
    {
      label: t("developer.projects.financial_details_doc_form.no_label"),
      value: "false",
    },
  ];

  const projectPhaseOption = [
    {
      label: t(`common:enumerations.investment_phases.Acquisition`),
      value: 1,
    },
    {
      label: t(`common:enumerations.investment_phases.PreDevelopment`),
      value: 2,
    },
    {
      label: t(`common:enumerations.investment_phases.Construction`),
      value: 3,
    },
    {
      label: t(`common:enumerations.investment_phases.PostConstruction`),
      value: 4,
    },
    {
      label: t(`common:enumerations.investment_phases.Seed`),
      value: 5,
    },
  ];

  const projectSeedOption = projectPhaseOption.pop();
  projectSeedOption && projectPhaseOption.unshift(projectSeedOption);

  const submitPortfolioProjectsInformation = async (formValues: Object) => {
    try {
      setIsApiLoading(true);
      await ListingPortfolioAPI.postPortfolioProjectsInfo(formValues);
      setIsApiLoading(false);
      handleResponse("ProjectsWithinPortfolio", true);
    } catch (error) {
      setIsApiLoading(false);
      handleResponse("ProjectsWithinPortfolio", false);

      if (axios.isAxiosError(error) && error.response?.status !== 404) {
        navigate(apiErrorPage);
      }
    }
  };

  const updatePortfolioProjectsInformation = async (formValues: Object) => {
    if (!portfolioListingId) {
      return;
    }
    try {
      setIsApiLoading(true);
      await ListingPortfolioAPI.updatePortfolioProjectsInfo(
        portfolioListingId,
        formValues
      );
      setIsApiLoading(false);
      handleResponse("ProjectsWithinPortfolio", true);
    } catch (error) {
      setIsApiLoading(false);
      handleResponse("ProjectsWithinPortfolio", false);

      if (axios.isAxiosError(error) && error.response?.status !== 404) {
        navigate(apiErrorPage);
      }
    }
  };


  useEffect(() => {
    if (selectedAccordion === 4) {
      portfolioListingId && getAffordabilityBenchmarkInfo(portfolioListingId);
      if (isProjectsWithinPortfolioSubmit === true) {
        portfolioListingId && getPortfolioProjectsInfo(portfolioListingId);
      }
    }
  }, [selectedAccordion]);

  useEffect(() => {
    portfolioListingId && getPortfolioProjectsInfo(portfolioListingId);
    portfolioListingId && getAffordabilityBenchmarkInfo(portfolioListingId);
  }, [portfolioListingId, getPortfolioProjectsInfo]);

  const handleExpand = (e: any, index: number) => {
    //  e.preventDefault();
    let arr = expand;
    arr[index] = !expand[index];

    setExpand([...arr]);
  };
  function validateForm(values: any, totalProjects: number, setErrors: any) {
    const { totalProjectCount, affordabilityTypesProjectCount } = values;
    const sumErrorMessage = t("developer.projects.add_listing_form.sum_projects_error");
    let errors: any = {
      affordabilityTypesProjectCount: {}
    };
    if (totalProjects === totalProjectCount) {
      return true;
    } else {

      if (affordabilityTypesProjectCount.socialImpactingHousingCount) {
        errors.affordabilityTypesProjectCount = {
          socialImpactingHousingCount: sumErrorMessage,
          ...errors.affordabilityTypesProjectCount
        }

      }

      if (affordabilityTypesProjectCount.focusOnAffordableHousingCount) {
        errors.affordabilityTypesProjectCount = {
          focusOnAffordableHousingCount: sumErrorMessage,
          ...errors.affordabilityTypesProjectCount
        }

      }

      if (affordabilityTypesProjectCount.balanceBetweenAffordableAndSupplyCount) {
        errors.affordabilityTypesProjectCount = {
          balanceBetweenAffordableAndSupplyCount: sumErrorMessage,
          ...errors.affordabilityTypesProjectCount
        }

      }
      if (affordabilityTypesProjectCount.focusOnHousingSupplyCount) {
        errors.affordabilityTypesProjectCount = {
          focusOnHousingSupplyCount: sumErrorMessage,
          ...errors.affordabilityTypesProjectCount
        }
      }

      errors.totalProjectCount = sumErrorMessage;

      if (Object.keys(errors.affordabilityTypesProjectCount).length !== 0) {
        setErrors(errors);
      }
      return false;
    }
  }

  const landStatusOption = [
    {
      label: t(`common:enumerations.land_status.LandSecured`),
      value: 1,
    },
    {
      label: t(`common:enumerations.land_status.LandNeededPortfolioProject`),
      value: 2,
    },
    {
      label: t(`common:enumerations.land_status.LandAcquisitioInProcess`),
      value: 3,
    },
    {
      label: t(`common:enumerations.land_status.PreferNotToDdisclose`),
      value: 9,
    },
  ];

  function isValid(fileName: string) {

    return (new RegExp('(' + ['.jpg', '.jpeg', '.png', '.JPG', '.JPEG', '.PNG'].join('|').replace(/\./g, '\\.') + ')$')).test(fileName);
  }

  async function uploadAttachment(file: any, index: number) {

    if (!file) {  // check if the file is selected
      return;
    }
    if (!account || !sasToken || !containerName) {  // check if the credentials are set
      alert('Please make sure you have set the Azure Storage credentials in the .env file');
      return;
    }

    const existingImg = images[index].filter((img: any) => (img.blobStatus !== 2 && img.fileName === file.name));

    if (images[index].length !== 0 && (images[index][images[index].length - 1].imgError || existingImg.length !== 0)) {
      return;
    }

    try {
      const blobName = "portfolio_" + portfolioListingId?.split(":").pop() + "_" + new Date().getTime() + "_" + file.name; // Specify a default blob name if needed

      let imagesTemp = images;
      const sizeImg = file.size / 1000000;
      let isValidImage = false;

      let imgError = false;
      const formatError = isValid(file.name);
      const fileType = await checkFileSignature(file);
      
      if (fileType === 'png' || fileType === 'jpeg') {
        isValidImage = true;
      }

      let limitError = false;

      if (sizeImg > 5 || !formatError || !isValidImage) {
        imgError = true;
      }


      const activeImages = imagesTemp[index].filter((img: any) => img.blobStatus !== 2);

      if (activeImages.length >= 1) {
        imgError = true;
        limitError = true;
      }

      let img = {
        blobUri: "",
        blobName: blobName,
        fileName: file.name,
        blobStatus: 1,
        blobSize: sizeImg,
        blobOrder: images[index].length + 1,
        imgError: imgError,
        limitError: limitError,
      }
      if (limitError) {
        setOpen({
          switch: true,
          message: t("developer.projects.file_upload.error_upload_banner"),
        });
      }

      imagesTemp[index].push(img);
      setImages([...imagesTemp]);

      if (!imgError && !limitError) {
        const blobClient = containerClient.getBlockBlobClient(blobName);  // get the blob client

        await blobClient.uploadData(file, { blobHTTPHeaders: { blobContentType: file.type } }); // upload the image
        imagesTemp = images;

        imagesTemp[index][images[index].length - 1].blobUri = blobClient.url.substring(0, blobClient.url.indexOf('?'));;


        setImages([...imagesTemp]);
      }
    } catch (error) {

      let imagesTemp2 = images;
      imagesTemp2[index][images[index].length - 1].imgError = true;
      setImages([...imagesTemp2]);

    } finally {
      // setLoading(false); // Turn off loading
    }

  }

  async function handleAttachmentDrop(rawAttachments: File[], index: number) {
    uploadAttachment(rawAttachments[0], index);
  }


  async function handleClose(index: number, projectIndex: number) {
    let imagesTemp = images;
    if (isDataExist && imagesTemp[projectIndex][index].blobStatus !== 1) {
      imagesTemp[projectIndex][index].blobStatus = 2;
      imagesTemp[projectIndex][index].imgError = false;
      if (imagesTemp[projectIndex][index].blobUri.length === 0) {
        imagesTemp[projectIndex].splice(index, 1);
      }
    } else {
      imagesTemp[projectIndex][index].imgError = false;

      if (imagesTemp[projectIndex][index].blobUri.length === 0) {
        imagesTemp[projectIndex].splice(index, 1);
      } else {
        try {
          await containerClient.deleteBlob(images[projectIndex][index].blobName);
          imagesTemp[projectIndex].splice(index, 1);

        } catch (err) {
        }
      }
    }
    imagesTemp[projectIndex].forEach(function (img: any, i: number) {
      if (img.blobStatus == 2) {
        imagesTemp[projectIndex].push(imagesTemp[projectIndex][i]);
        imagesTemp[projectIndex].splice(i, 1);
      }
    });
    setImages([...imagesTemp]);
  }

  function handleAttachmentInput(event: any, index: number) {
    uploadAttachment(event.target.files[0], index);
  }

  function onInputClick(event: React.MouseEvent<HTMLInputElement, MouseEvent>) {
    const element = event.target as HTMLInputElement
    element.value = ''
  }

  useEffect(() => {       
        if (isSubmitted) {
           //@ts-ignore
         ref.current?.handleSubmit()
       }    
  }, [i18n.language]); 
  
  
  const action = (
    <React.Fragment>

      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={() => setOpen({ ...open, switch: false })}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );
  return (
    <React.Fragment>
      <Formik
        innerRef={ref}
        initialValues={isDataExist ? apiData : initialValues}
         validationSchema={() => {         
          setIsSubmitted(true);
           //@ts-ignore
           if (ref.current && ref.current.values.displayProjectDetailsFlag === "true"
           ) {
             //@ts-ignore
             ref.current.values.totalProjectCount = isNaN(ref.current.values.totalProjectCount) ? Number(ref.current.values.totalProjectCount.match(/\d+/g).join("")) : ref.current.values.totalProjectCount;
             //@ts-ignore
             ref.current.values.totalUnitPortfolioProject = isNaN(ref.current.values.totalUnitPortfolioProject) ? Number(ref.current.values.totalUnitPortfolioProject.match(/\d+/g).join("")) : ref.current.values.totalUnitPortfolioProject;
             //@ts-ignore
             ref.current.values.belowAverageMarketRentUnitCount = isNaN(ref.current.values.belowAverageMarketRentUnitCount) ? Number(ref.current.values.belowAverageMarketRentUnitCount.match(/\d+/g).join("")) : ref.current.values.belowAverageMarketRentUnitCount;
             if (projectAffordabilityTypes.includes(1)) {
               //@ts-ignore
               ref.current.values.affordabilityTypesProjectCount.socialImpactingHousingCount = isNaN(ref.current.values.affordabilityTypesProjectCount.socialImpactingHousingCount) ? Number(ref.current.values.affordabilityTypesProjectCount.socialImpactingHousingCount.match(/\d+/g).join("")) : ref.current.values.affordabilityTypesProjectCount.socialImpactingHousingCount;
             }
             if (projectAffordabilityTypes.includes(2)) {
               //@ts-ignore
               ref.current.values.affordabilityTypesProjectCount.focusOnAffordableHousingCount = isNaN(ref.current.values.affordabilityTypesProjectCount.focusOnAffordableHousingCount) ? Number(ref.current.values.affordabilityTypesProjectCount.focusOnAffordableHousingCount.match(/\d+/g).join("")) : ref.current.values.affordabilityTypesProjectCount.focusOnAffordableHousingCount;
             }
             if (projectAffordabilityTypes.includes(3)) {
               //@ts-ignore
               ref.current.values.affordabilityTypesProjectCount.balanceBetweenAffordableAndSupplyCount = isNaN(ref.current.values.affordabilityTypesProjectCount.balanceBetweenAffordableAndSupplyCount) ? Number(ref.current.values.affordabilityTypesProjectCount.balanceBetweenAffordableAndSupplyCount.match(/\d+/g).join("")) : ref.current.values.affordabilityTypesProjectCount.balanceBetweenAffordableAndSupplyCount;
             }
             if (projectAffordabilityTypes.includes(4)) {
               //@ts-ignore
               ref.current.values.affordabilityTypesProjectCount.focusOnHousingSupplyCount = isNaN(ref.current.values.affordabilityTypesProjectCount.focusOnHousingSupplyCount) ? Number(ref.current.values.affordabilityTypesProjectCount.focusOnHousingSupplyCount.match(/\d+/g).join("")) : ref.current.values.affordabilityTypesProjectCount.focusOnHousingSupplyCount;
             }
              //@ts-ignore
             for (let i = 0; i <  ref.current.values.portfolioProjects.length; i++) {
                //@ts-ignore
                ref.current.values.portfolioProjects[i].unitCount = isNaN(ref.current.values.portfolioProjects[i].unitCount) ? Number(ref.current.values.portfolioProjects[i].unitCount.match(/\d+/g).join("")) : ref.current.values.portfolioProjects[i].unitCount;
                 //@ts-ignore
                ref.current.values.portfolioProjects[i].unitPercentBelowMarketRent = isNaN(ref.current.values.portfolioProjects[i].unitPercentBelowMarketRent) ? Number(ref.current.values.portfolioProjects[i].unitPercentBelowMarketRent.match(/\d+/g).join("")) : ref.current.values.portfolioProjects[i].unitPercentBelowMarketRent;
             }
             return projectWithinPortfolioSchema;
           } else {
             //@ts-ignore
             ref.current.values.totalProjectCount = isNaN(ref.current.values.totalProjectCount) ? Number(ref.current.values.totalProjectCount.match(/\d+/g).join("")) : ref.current.values.totalProjectCount;
             //@ts-ignore
             ref.current.values.totalUnitPortfolioProject = isNaN(ref.current.values.totalUnitPortfolioProject) ? Number(ref.current.values.totalUnitPortfolioProject.match(/\d+/g).join("")) : ref.current.values.totalUnitPortfolioProject;
             //@ts-ignore
             ref.current.values.belowAverageMarketRentUnitCount = isNaN(ref.current.values.belowAverageMarketRentUnitCount) ? Number(ref.current.values.belowAverageMarketRentUnitCount.match(/\d+/g).join("")) : ref.current.values.belowAverageMarketRentUnitCount;
             if (projectAffordabilityTypes.includes(1)) {
               //@ts-ignore
               ref.current.values.affordabilityTypesProjectCount.socialImpactingHousingCount = isNaN(ref.current.values.affordabilityTypesProjectCount.socialImpactingHousingCount) ? Number(ref.current.values.affordabilityTypesProjectCount.socialImpactingHousingCount.match(/\d+/g).join("")) : ref.current.values.affordabilityTypesProjectCount.socialImpactingHousingCount;
             }
             if (projectAffordabilityTypes.includes(2)) {
               //@ts-ignore
               ref.current.values.affordabilityTypesProjectCount.focusOnAffordableHousingCount = isNaN(ref.current.values.affordabilityTypesProjectCount.focusOnAffordableHousingCount) ? Number(ref.current.values.affordabilityTypesProjectCount.focusOnAffordableHousingCount.match(/\d+/g).join("")) : ref.current.values.affordabilityTypesProjectCount.focusOnAffordableHousingCount;
             }
             if (projectAffordabilityTypes.includes(3)) {
               //@ts-ignore
               ref.current.values.affordabilityTypesProjectCount.balanceBetweenAffordableAndSupplyCount = isNaN(ref.current.values.affordabilityTypesProjectCount.balanceBetweenAffordableAndSupplyCount) ? Number(ref.current.values.affordabilityTypesProjectCount.balanceBetweenAffordableAndSupplyCount.match(/\d+/g).join("")) : ref.current.values.affordabilityTypesProjectCount.balanceBetweenAffordableAndSupplyCount;
             }
             if (projectAffordabilityTypes.includes(4)) {
               //@ts-ignore
               ref.current.values.affordabilityTypesProjectCount.focusOnHousingSupplyCount = isNaN(ref.current.values.affordabilityTypesProjectCount.focusOnHousingSupplyCount) ? Number(ref.current.values.affordabilityTypesProjectCount.focusOnHousingSupplyCount.match(/\d+/g).join("")) : ref.current.values.affordabilityTypesProjectCount.focusOnHousingSupplyCount;
             }
             return projectWithinPortfolioSchema2;
           }
         }}
        validateOnChange={false}
        validateOnBlur={false}
        enableReinitialize={true}
        onSubmit={(values,{setErrors})=> { setIsSubmitted(true);handleSubmit(values,setErrors)}}
      >
        {({ values, errors, setErrors }) => (
          <Form className={styles.formElement} noValidate>
            <Snackbar
              className={styles.ccSnackbar}
              ContentProps={{
                sx: {
                  background: "red"
                },
              }}
              anchorOrigin={{ vertical: "top", horizontal: "center" }}
              open={open.switch}
              onClose={() => setOpen({ ...open, switch: false })}
              message={open.message}
              key={"top" + "center"}
              action={action}
            />
            <NumberInput
              fieldName="totalProjectCount"
              labelText={t(
                "developer.projects.project_within_portfolio.number_of_portfolio"
              )}
              required
              data-testid="total-project-count"
            />
            <NumberInput
              fieldName="totalUnitPortfolioProject"
              labelText={t("developer.projects.project_within_portfolio.portfolio_total_number_of_units")}
              required
              data-testid="total-unit-portfolio-project"
            />
            <NumberInput
              fieldName="belowAverageMarketRentUnitCount"
              labelText={t("developer.projects.project_within_portfolio.portfolio_percentage_AMR")}
              required
              data-testid="below-average-market-rentUnitCount"
            />
            <div className={styles.lableLink}>
              <p className={styles.formQuestion}>
                {
                  t(
                    "developer.projects.project_within_portfolio.specify_number_of_portfolio"
                  )
                }
              </p>
              <button
                type="button"
                className={styles.glossaryLink}
                data-testid="glossary-page-link"
                onClick={() => {
                  setIsOpenAffordabilityModal(true);
                }}
              >
                {
                  t(
                    "developer.projects.project_within_portfolio.glossory.glossoryLink"
                  )
                }
              </button>
              {isOpenAffordabilityModal && (
                <GlossaryDefinition
                  setCloseGlossaryModal={setIsOpenAffordabilityModal}
                  title={t("profile:developer.projects.add_listing_form.affordabilityGlossary.title")}
                  glossaryContent={t(
                    "profile:developer.projects.add_listing_form.affordabilityGlossary.affordabilityGlossaryContent",
                    {
                      returnObjects: true,
                    }
                  )}
                  paraContent={t(
                    "profile:developer.projects.add_listing_form.affordabilityGlossary.affordabilityParaContent",
                    {
                      returnObjects: true,
                    }
                  )}
                  glossaryLink={t(
                    "profile:developer.projects.add_listing_form.affordabilityGlossary.viewFullGlossaryLink"
                  )}
                  closeText={t("profile:developer.projects.add_listing_form.affordabilityGlossary.closeButton")}
                  selectedId="#2"
                />
              )}
            </div>
            <p className={styles.staticCaption}>
              {
                t(
                  "developer.projects.project_within_portfolio.specify_categories_of_portfolio"
                )
              }
            </p>
            {projectAffordabilityTypes.includes(1) && (
             <NumberInput
                fieldName="affordabilityTypesProjectCount.socialImpactingHousingCount"
                labelText={t("developer.projects.project_within_portfolio.number_of_social_impacting")}
                required
              />
            )}

            {projectAffordabilityTypes.includes(2) && (
              <NumberInput
                fieldName="affordabilityTypesProjectCount.focusOnAffordableHousingCount"
                labelText={t("developer.projects.project_within_portfolio.number_of_focus_on_affordability")}
                required
              />
            )}

            {projectAffordabilityTypes.includes(3) && (
              <NumberInput
                fieldName="affordabilityTypesProjectCount.balanceBetweenAffordableAndSupplyCount"
                labelText={t("developer.projects.project_within_portfolio.number_of_balance_supply")}
                required
              />
            )}

            {projectAffordabilityTypes.includes(4) && (
              <NumberInput
                fieldName="affordabilityTypesProjectCount.focusOnHousingSupplyCount"
                labelText={t("developer.projects.project_within_portfolio.number_of_focus_supply")} 
                required
              />
            )}

            <h4 className={styles.formQuestion}>
              {t("developer.projects.project_within_portfolio.project_details")}
            </h4>
            <p className={styles.staticCaption}>
              {
                t(
                  "developer.projects.project_within_portfolio.project_details_helpertext"
                )
              }
            </p>
            <div className={styles.fieldContainer}>
              <RadioInput
                fieldName="displayProjectDetailsFlag"
                options={radioOptions}
                required
                data-testid="display-funding-flag"
              />
            </div>

            {
              //@ts-ignore
              (values.displayProjectDetailsFlag === "false" ||
               //@ts-ignore
                values.displayProjectDetailsFlag === false) && (
                <React.Fragment>
                  <div className={styles.noteContainer}>
                    <h2 className={styles.noteHeading}>
                      {t("developer.projects.note.heading")}
                    </h2>
                    <ul className={styles.noteList}>
                      <li className={styles.noteListItem}>
                        <p>
                          {t(
                            "developer.projects.project_within_portfolio.note_summary"
                          )}
                        </p>
                      </li>
                    </ul>
                  </div>
                  <FieldArray
                    name="developmentRegions"
                    render={(arrayHelpers) => (
                      <div className={styles.experienceSectionContainer}>
                        {
                          //@ts-ignore
                          values.developmentRegions &&
                            values.developmentRegions.length > 0
                            ? //@ts-ignore
                            values.developmentRegions.map((region, index) => (
                              <div
                                key={index}
                                className={styles.regionalSectionItem}
                              >
                                <div
                                  className={styles.regionalHeadingContainer}
                                >
                                  <h4 className={styles.regionalFocusHeading}>
                                    {t(
                                      "developer.projects.project_within_portfolio.reginal_focus"
                                    )}
                                  </h4>
                                  { values.developmentRegions.length > 1 && (
                                    <FieldArrayRemoveButton
                                      onClickHandler={() =>
                                        arrayHelpers.remove(index)
                                      }
                                      buttonText={t("developer.projects.project_within_portfolio.remove_text")}
                                    />
                                  )}
                                </div>
                                <ProjectLocationFields name={`developmentRegions[${index}]`} province={values.developmentRegions[index].province} isDataExist={isDataExist} />
                              </div>
                            ))
                            : null
                        }

                        {
                          //@ts-ignore

                          values.developmentRegions &&
                            //@ts-ignore
                            values.developmentRegions.length > 0 &&
                            //@ts-ignore

                            values.developmentRegions.length < 5 ? (
                            <div className={styles.centreAlignContainer}>
                              <FieldArrayAddButton
                                onClickHandler={() =>
                                  arrayHelpers.push(regionalFocusValues)
                                }
                                buttonText={t(
                                  "developer.projects.project_within_portfolio.another_development_region_link"
                                )}
                              />
                            </div>
                          ) : null
                        }
                      </div>
                    )}
                  />
                  <TextArea
                    fieldName={`portfolioProjectsSummaryDescription`}
                    labelText={t(
                      "developer.projects.project_within_portfolio.summary_of_project"
                    )}
                    data-testid="project-description"
                    placeholder={t("developer.projects.project_within_portfolio.summary_of_project_placeholder")}
                    charLimit={300}
                  />
                </React.Fragment>
              )
            }
            {
              //@ts-ignore
              (values.displayProjectDetailsFlag === "true" ||
               //@ts-ignore
                values.displayProjectDetailsFlag === true) && (
                <>
                  <div className={styles.noteContainer}>
                    <h2 className={styles.noteHeading}>
                      {t("developer.projects.note.heading")}
                    </h2>
                    <ul className={styles.noteList}>
                      <li className={styles.noteListItem}>
                        <p>
                          {t(
                            "developer.projects.project_within_portfolio.note_summary"
                          )}
                        </p>
                      </li>
                    </ul>
                  </div>

                  <FieldArray
                    name="portfolioProjects"
                    render={(arrayHelpers) => (
                      <div className={styles.experienceSectionContainer}>
                        {
                          //@ts-ignore
                          values.portfolioProjects.map((region, index) => (
                            <div
                              key={index}
                              className={styles.regionalSectionItem}
                            >
                              <div className={styles.regionalHeadingContainer}>
                                <h4 className={styles.regionalFocusHeading}>
                                  {t(
                                    "developer.projects.project_within_portfolio.project"
                                  )}{" "}
                                  {index + 1}
                                </h4>
                                <div>
                                  {values.portfolioProjects.length > 1 && (
                                    <FieldArrayRemoveButton
                                      onClickHandler={() => {
                                        setRemoveProject(true);
                                      }}
                                      buttonText={`${t(
                                        "developer.projects.project_within_portfolio.remove_project"
                                      )} ${index + 1}`}
                                    />
                                  )}
                                  {removeProject && (
                                    <RemoveProject
                                      setRemoveProject={setRemoveProject}
                                      removeProject={() => {
                                        let arr = expand;
                                        let imagesTemp = images;
                                        arr.splice(index, 1);
                                        imagesTemp.splice(index, 1);
                                        arrayHelpers.remove(index);
                                        setImages(imagesTemp);
                                        setExpand(arr);
                                      }}
                                    />
                                  )}

                                  <button
                                    className={styles.expand}
                                    onClick={(e) => {
                                      e.preventDefault();
                                      handleExpand(e, index);
                                    }}
                                  >
                                    {expand && expand[index] ? (
                                      <KeyboardArrowUpIcon />
                                    ) : (
                                      <KeyboardArrowDownIcon />
                                    )}
                                  </button>
                                </div>
                              </div>
                              {expand && expand[index] && (
                                <div>
                                  <h4 className={styles.formQuestion}>
                                    {t(
                                      "developer.projects.general_info_form.heading"
                                    )}
                                  </h4>
                                  <div>
                                    <TextInput
                                      fieldName={`portfolioProjects[${index}].projectName`}
                                      labelText={t(
                                        "developer.projects.project_within_portfolio.project_name_field"
                                      )}
                                      required
                                    />
                                  </div>
                                  <ProjectLocationFields name={`portfolioProjects[${index}]`} province={values.portfolioProjects[index].province} isDataExist={isDataExist} />
                                  <NumberInput
                                    fieldName={`portfolioProjects[${index}].unitCount`}
                                    labelText={t(
                                      "developer.projects.project_within_portfolio.number_of_units_developed"
                                    )}
                                    required
                                  />
                                  <NumberInput
                                    fieldName={`portfolioProjects[${index}].unitPercentBelowMarketRent`}
                                    labelText={t(
                                      "developer.projects.project_within_portfolio.avg_below_market_rent"
                                    )}
                                    required
                                  />
                                  <SelectInput
                                    fieldName={`portfolioProjects[${index}].affordabilityCommitment`}
                                    labelText={t(
                                      "developer.projects.add_listing_form.commitment_to_affordability"
                                    )}
                                    options={commitmentAffordabilityOptions}
                                    placeholder="Please Select"
                                    data-testid="commitment_to_affordability"
                                  />
                                  <div className={styles.splitFieldContainer}>
                                    <DateInput
                                      fieldName={`portfolioProjects[${index}].targetStartDate`}
                                      labelText={t(
                                        "developer.projects.project_within_portfolio.target_project_startDate"
                                      )}
                                      placeholderText={t(
                                        "developer.projects.general_info_form.datePlaceHolderText"
                                      )}
                                      required
                                    />

                                    <DateInput
                                      fieldName={`portfolioProjects[${index}].targetCompletionDate`}
                                      labelText={t(
                                        "developer.projects.project_within_portfolio.target_project_completionDate"
                                      )}
                                      placeholderText={t(
                                        "developer.projects.general_info_form.datePlaceHolderText"
                                      )}
                                      required
                                    />
                                  </div>
                                  <TextArea
                                    fieldName={`portfolioProjects[${index}].projectDescription`}
                                    labelText={t(
                                      "developer.projects.general_info_form.project_desc_label_portfolio"
                                    )}
                                    placeholder={t("developer.projects.project_within_portfolio.project_desc_instruction")}
                                    data-testid="project-description"
                                    charLimit={300}
                                  />

                                  <h4 className={styles.formQuestion}>
                                    {t("developer.projects.file_upload.upload_images_label")}
                                  </h4>
                                  <p>{t("developer.projects.file_upload.upload_images_description")} </p>
                                  <br />
                                  <Dropzone onDrop={(rawAttachments) => handleAttachmentDrop(rawAttachments, index)}>
                                    {({ getRootProps, getInputProps, isDragActive }) => (
                                      <div
                                        {...getRootProps({
                                          onClick: (e) => {
                                            e.stopPropagation();
                                          }
                                        })}
                                        className={isDragActive ? styles.uploadBoxDraged : styles.uploadBox}

                                      >
                                        <div className={styles.uploadContent}>
                                          <div>
                                            <img
                                              className={styles.uploadIcon}

                                              src={CloudUpload}
                                              alt={"upload image"}
                                            />
                                          </div>
                                          <div
                                            className={styles.uploadDetails}
                                          >
                                            {t("developer.projects.file_upload.drop_images")}&nbsp;
                                            <label className={styles.browser}>
                                              <input
                                                {...getInputProps()}
                                                type="file"
                                                id={`UploadFiles`}
                                                aria-labelledby={`UploadFilesLabel`}
                                                name="filename"
                                                className="form__attachment-manual-upload"
                                                onChange={(e) => handleAttachmentInput(e, index)}

                                                onClick={onInputClick}
                                                disabled={false}
                                              />
                                              {t("developer.projects.file_upload.drop_images_browse")}
                                            </label>
                                            &nbsp;{t("developer.projects.file_upload.drop_images_your_files")}</div>
                                          <div className={styles.uploadInstructions}>{t("developer.projects.file_upload.drop_images_description")}</div>
                                          <div className={styles.uploadInstructions}>{t("developer.projects.file_upload.drop_images_description_individual")}</div>
                                        </div>
                                      </div>
                                    )}

                                  </Dropzone>

                                  {images[index]?.map((item: any, idx: number) => (
                                    item.blobStatus !== 2 ?
                                      <div className={item.imgError ? styles.uploadCardError : styles.uploadCard} key={idx}>
                                        <div className={styles.imageContent}>
                                          <div>

                                            {item.blobUri.length === 0 ?
                                              <img
                                                className={styles.image}
                                                src={item.imgError ? ErrorImage : UploadLoading}
                                                alt={"upload"}
                                              />
                                              :
                                              <img
                                                className={styles.image}
                                                src={item.blobUri.includes("quarantine") ? item.blobUri + sasToken : item.blobUri + sasTokenDocumentStorage}
                                                alt={item.fileName}
                                              />

                                            }
                                          </div>
                                          <div className={styles.imagetitle}>
                                            <p>{item.fileName} <span className={styles.imageSize}>{item.blobSize} MB</span></p>
                                            {item.imgError && item.limitError ? <p> {t("developer.projects.file_upload.error_upload_limit")}</p> :
                                              item.imgError ? <p>{t("developer.projects.file_upload.error_uploaded")}</p>
                                                : item.blobUri.length === 0 ? <p className={styles.imageSize}>{t("developer.projects.file_upload.uploading")}</p>
                                                  : <p className={styles.imageSize}>{t("developer.projects.file_upload.uploaded")}</p>}                                          </div>
                                          <div
                                            onClick={() => handleClose(idx, index)}
                                          >
                                            {item.blobUri.length === 0 ?
                                              !item.imgError ? <CircularProgress /> : <img
                                                src={CloseIconSvg}
                                                alt={"close icon"}
                                              />
                                              :
                                              <img
                                                src={CloseIconSvg}
                                                alt={"close icon"}
                                              />
                                            }
                                          </div>
                                        </div>
                                      </div>
                                      :
                                      <React.Fragment></React.Fragment>
                                  ))}

                                  <div className={styles.noteContainerDocumentation}>
                                    <h2 className={styles.noteHeading}>
                                      {t("developer.projects.note.heading_2")}
                                    </h2>
                                    <ul className={styles.noteList}>
                                      <li className={styles.noteListItem}>
                                        <p>
                                          {t("developer.projects.file_upload.images_note")}
                                        </p>
                                      </li>
                                    </ul>
                                  </div>

                                  <hr className={styles.horizontalLine}></hr>
                                  <h4 className={styles.formQuestion}>
                                    {t("developer.projects.project_within_portfolio.project_details_title")}
                                  </h4>
                                  <h4 className={styles.formQuestion}>
                                    {t(
                                      "developer.projects.project_within_portfolio.typeOf_project_field"
                                    )}
                                  </h4>
                                  <div
                                    className={styles.fieldContainer}
                                    data-testid="construction-types-radio"
                                  >
                                    <RadioInput
                                      fieldName={`portfolioProjects[${index}].constructionType`}
                                      options={constructionTypeOption}
                                    />
                                  </div>
                                  <h4 className={styles.formQuestion}>
                                    {t(
                                      "developer.projects.project_within_portfolio.project_phase"
                                    )}
                                  </h4>
                                  <div className={styles.fieldContainer}>
                                    <RadioInputGroup
                                      fieldName={`portfolioProjects[${index}].projectPhase`}
                                      options={projectPhaseOption}
                                      data-testid="project-phase-radio"
                                    />
                                  </div>
                                  <h4 className={styles.formQuestion}>
                                    {t(
                                      "developer.projects.financial_details_doc_form.land_status_portfolio_label"
                                    )}
                                  </h4>
                                  <div className={styles.fieldContainer}>
                                    <RadioInputGroup
                                      fieldName={`portfolioProjects[${index}].landStatus`}
                                      options={landStatusOption}
                                      data-testid="land-status-radio"
                                    />
                                  </div>
                                  <h4 className={styles.formQuestion}>
                                    {t(
                                      "developer.projects.project_within_portfolio.impact_heading"
                                    )}
                                  </h4>
                                  {/* fieldName={`regionalFocus[${index}].province`} */}
                                  <TextArea
                                    fieldName={`portfolioProjects[${index}].developmentImpactDescription`}
                                    labelText={t(
                                      "developer.projects.project_within_portfolio.impact_description"
                                    )}
                                    data-testid="project-description"
                                    placeholder={t("developer.projects.project_within_portfolio.impact_description_placeholder")}
                                    charLimit={300}
                                  />
                                </div>
                              )}
                            </div>
                          ))
                        }

                        {values.portfolioProjects.length >=
                          Number(values.totalProjectCount) ? (
                          <p>
                            {t(
                              "developer.projects.project_within_portfolio.max_projects_added_warning"
                            )}
                          </p>
                        ) : (
                          <div className={styles.centreAlignContainer}>
                            <FieldArrayAddButton
                              onClickHandler={() => {
                                let arr = expand;
                                arr.push(true);

                                arrayHelpers.push(projectInfoValues);
                                images.push([]);

                                setExpand(arr);
                              }}
                              buttonText={t(
                                "developer.projects.project_within_portfolio.add_another_project_btn"
                              )}
                            />
                          </div>
                        )}
                      </div>
                    )}
                  />
                </>
              )
            }

            <div className={styles.buttonContainer}>
              <button
                type="submit"
                className={styles.formButton}
                data-testid="submit-button"
                disabled={isApiLoading}
              >
                {t("developer.projects.impact_criteria_form.btn_text")}
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </React.Fragment>
  );
}
