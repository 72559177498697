import styles from "./styles.module.scss";

type Props = {
  titleText: string;
  description: string;
};

export const SectionTitle = (props: Props) => {
  return (   
      <div className={styles.sectionTitleContainer}>
        <h3 className={styles.subtitle}>{props.titleText}</h3>
          <h3 className={styles.desc}>{props.description}</h3>  
      </div>
  );
};
