import { Field, useField } from "formik";
import { CommonErrorMessage } from "./CommonErrorMessage";
import styles from "./styles.module.scss";
import { numberWithCommas, removeCommas } from "../../../Constants/Utils/FormateNumber";

type Props = {
  labelText: string;
  fieldName: string;
  linkText?: string;
  setIsOpenModal?: Function;
  [x: string]: unknown;
  placeholder?: string;
  maxValue?: number;
  instructionText?: string;
};

export function NumberInput({ labelText, fieldName, linkText,
  setIsOpenModal, placeholder, maxValue = 1000000000, instructionText, ...rest }: Props) {
  const [field, meta, fieldHelpers] = useField(fieldName);

  return (
    <div className={styles.fieldContainer}>
      <div className={styles.lableLink}>
        <label
          className={`${styles.labelStyle} ${meta.error && meta.touched ? styles.errorStateLabel : null
            }`}
          htmlFor={field.name}
        >
          {labelText}
        </label>
        {linkText &&
          <button
            type="button"
            className={styles.glossaryLink}
            data-testid="glossary-page-link"
            onClick={(e) => {
              e.preventDefault();
              if (setIsOpenModal) {
                setIsOpenModal(true);
              }
            }}
          >
            {linkText}
          </button>
        }
      </div>

      <Field
        className={`${styles.textInput} ${meta.error && meta.touched ? styles.errorStateInput : null
          }`}
        id={field.name}
        {...field}
        {...rest}
        onChange={(e: any) => {
          e.preventDefault();
          if (Number(removeCommas(e.target.value)) <= maxValue) {
            fieldHelpers.setValue(e.target.value);
          }

        }}
        value={
          (field.value !== undefined && field.value !== null && field.value !== "") 
          && (Number(removeCommas(field.value)) <= maxValue) 
            ? numberWithCommas(field.value) 
            : field.value ? numberWithCommas(field.value.slice(0, -1)) : ''
        }
        maxLength={15}
        placeholder={placeholder}
      >

      </Field>
      {instructionText && <p className={styles.fieldInfoText}>{instructionText}</p>}
      <CommonErrorMessage fieldName={field.name} />
    </div>
  );
}
