import { useTranslation } from "react-i18next";
import { AccordionContainer } from "../../../components/common/AccordionContainer/AccordionContainer";
import styles from "../Resources.module.scss";
import { PageNavigation } from "../PageNavigation";
import { useEffect } from "react";

export function Glossary() {
  const { t } = useTranslation("resources");
  const pageNavLinks = [
    { text: t("glossary.subheading_impactCriteria"), id: "#1" },
    { text: t("glossary.subheading_affordabilityCriteria"), id: "#2"},
    { text: t("glossary.subheading_investment"), id: "#4" },
    { text: t("glossary.subheading_development"), id: "#5" },
    { text: t("glossary.subheading_organizationType"), id: "#6" },
  ];

  const storedLinkId = localStorage.getItem("glossarySelectedLinkId");

  function generateGlossaryAccordionData(
    labelTranslationString: string,
    totalLabels: number,
  ) {
    const accordionData = [];
  
    for (let i = 1; i <= totalLabels; i++) {
      accordionData.push({
        heading: t(`${labelTranslationString}_${i}.heading`),
        summary: t(`${labelTranslationString}_${i}.body`),
        paraContent: t(`${labelTranslationString}_${i}.paraContent`, { returnObjects: true }),
        descriptionContent:t(`${labelTranslationString}_${i}.descriptionContent`, { returnObjects: true }), 
        trackGA: {
          event: "open_resource_section",
          resource_name: t(`${labelTranslationString}_${i}.heading`),
        },
      });
    }

    return accordionData;
  }
  
  useEffect(() => {
    if (storedLinkId) {
      const element = document.getElementById(storedLinkId.slice(1)); 
      if (element) {
        element.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    }
  }, []);
 
  const accordionGlossaryImpact:any = generateGlossaryAccordionData("glossary_accordion_impact.section", 4)
  const accordionGlossaryAffordability:any = generateGlossaryAccordionData("glossary_accordion_Affordability.section", 5)
  const accordionGlossaryInvestment:any = generateGlossaryAccordionData("glossary_accordion_investment.section", 4)
  const accordionGlossaryDevelopment:any = generateGlossaryAccordionData("glossary_accordion_development.section", 5)
  const accordionGlossaryOrganization:any = generateGlossaryAccordionData("glossary_accordion_Organization.section", 4)


  return (
    <section
      className={styles.sectionContainerResources}
      id="glossary"
      data-testid="resources-glossary"
    >
      <div className={styles.sideSection}>
        <PageNavigation
          heading={t("page_navigation_heading")}
          links={pageNavLinks}
          selectedLinkId={storedLinkId!}
        />
      </div>
      <div>
    <div className={styles.container}>
      <div className={styles.info}>
      <h2 className={styles.heading}>{t("glossary.heading")}</h2>

      <h3 className={styles.subHeading} id="1">
        {t("glossary.subheading_impactCriteria")}
      </h3>
      <AccordionContainer data={accordionGlossaryImpact}/>
      <h3 className={styles.subHeading} id="2">
        {t("glossary.subheading_affordabilityCriteria")}
      </h3>
      <AccordionContainer data={accordionGlossaryAffordability}/>
      <h3 className={styles.subHeading} id="4">
        {t("glossary.subheading_investment")}
      </h3>
      <AccordionContainer data={accordionGlossaryInvestment}/>
      <h3 className={styles.subHeading} id="5">
        {t("glossary.subheading_development")}
      </h3>
      <AccordionContainer data={accordionGlossaryDevelopment}/>
      <h3 className={styles.subHeading} id="6">
      {t("glossary.subheading_organizationType")}
      </h3>
      <AccordionContainer data={accordionGlossaryOrganization}/>
      </div>
    </div>
      
      </div>
    </section>
  );
}
