import { useTranslation } from "react-i18next";
import { AccordionContainer } from "../../../components/common/AccordionContainer/AccordionContainer";
import styles from "../Resources.module.scss";

export function Faqs() {
  const { t } = useTranslation("resources");

  function generateFaqAccordionData(
    labelTranslationString: string,
    totalLabels: number
  ) {
    const accordionData = [];

    for (let i = 1; i <= totalLabels; i++) {
      accordionData.push({
        heading: t(`${labelTranslationString}_${i}.heading`),
        summary: t(`${labelTranslationString}_${i}.body`),
        trackGA: {
          event: "open_resource_section",
          resource_name: t(`${labelTranslationString}_${i}.heading`),
        },
      });
    }

    return accordionData;
  }

  const faqsAccordionGeneral = generateFaqAccordionData("faqs_accordion_general.section", 7);
  const faqsAccordionProject = generateFaqAccordionData("faqs_accordion_project.section", 2);
  const faqsAccordionMakingConnections = generateFaqAccordionData("faqs_accordion_making_connections.section", 4);


  return (
    <section
      className={styles.sectionContainer}
      id="faqs"
      data-testid="resources-faq"
    >
      <h2 className={styles.heading}>{t("faqs.heading")}</h2>
      <h3 className={styles.subHeading}>{t("faqs.subheading_general")}</h3>
      <AccordionContainer data={faqsAccordionGeneral} />

      <h3 className={styles.subHeading}>{t("faqs.subheading_project")}</h3>
      <AccordionContainer data={faqsAccordionProject} />

      <h3 className={styles.subHeading}>
        {t("faqs.subheading_making_connections")}
      </h3>
      <AccordionContainer data={faqsAccordionMakingConnections} />
    </section>
  );
}
