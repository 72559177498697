import { AddCircleOutline } from "@mui/icons-material";
import styles from "./styles.module.scss";

type Props = {
  onClickHandler: () => void;
  buttonText: string;
};

export function FieldArrayAddButton(props: Props) {
  return (
    <button
      type="button"
      className={styles.actionLinkStyle}
      onClick={props.onClickHandler}
    >
      <AddCircleOutline sx={{ height: "1rem" }} /> {props.buttonText}
    </button>
  );
}
