import { EarlyAccessBannerProps } from "./EarlyAccessBannertypes";
import styles from "./styles.module.scss";
import { Link } from "react-router-dom";

export function EarlyAccessBanner({
  heading,
  content,
  requestaccessLink,
}: EarlyAccessBannerProps) {
  const handleGTM = () =>{
    window.dataLayer.push({
      "event": "request_access"
    });
  }
  return (
    <div className={styles.earlyaccess} data-testid="earlyaccess-component">
      <div className={styles.textContent} data-testid="earlyaccess-summary">
        <h2
          className={styles.heading}
          data-testid="earlyaccess-summary-heading"
        >
          {heading}
        </h2>
        <p className={styles.body} data-testid="earlyaccess-summary-body">
          {content}
        </p>
      </div>

      <div className={styles.callToAction}>
        <Link
          className={styles.actionButton}
          to="/request-access"
          data-testid="earlyaccess-link"
          onClick={handleGTM}
        >
          {requestaccessLink}
        </Link>
      </div>
    </div>
  );
}
