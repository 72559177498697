import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { PageLayout } from "../../../components/Layout/PageLayout";
import { PageContent } from "../../../components/Layout/PageContent";
import styles from "./styles.module.scss";
import { ReviewAffordability } from "./ReviewAffordability";
import { ReviewProjectGeneralInfo } from "./ReviewProjectGeneralInfo";
import { ReviewFinancialDetail } from "./ReviewFinancialDetails";
import { ListingProjectAPI } from "../../../APIAction/ListingProjectAPI";
import axios from "axios";
import { ReviewProjectImpactCriteriaInfo } from "./ReviewProjectImpactCriteria";
import { ReviewProjectDocumentation } from "./ReviewProjectDocumentation";

export const ReviewAddProject = () => {
  const { t } = useTranslation(["onboarding", "profile"]);

  const navigate = useNavigate();
  const { projectId = "" } = useParams();

  const [esgSelection, setEsgSelection] = useState("");
  const [capitalStacking, setCapitalStacking] = useState("");

  const handleConfirm = async () => {
    const data = { projectListingID: projectId };
    try {
      projectId !== "" && (await ListingProjectAPI.postConfirmApi(data));

      window.dataLayer.push({
        event: "list_project",
        esg_criteria: esgSelection,
        capital_stacking: capitalStacking,
      });
      navigate(`/projects/success/${projectId}`);
    } catch (err) {
      if (axios.isAxiosError(err)) {
        if (err.response?.status === 404) {
          return;
        } else {
          navigate("/api-error");
        }
      }
    }
  };

  return (
    <PageLayout>
      <PageContent>
        <div className={styles.container}>
          <div className={styles.headerContainer}>
            <h2
              className={styles.mainHeading}
              data-testid="review-project-heading"
            >
              {t("profile:developer.projects.review_project.main_heading")}{" "}
            </h2>
            <p className={styles.subHeading}>
              {t("profile:developer.projects.review_project.info")}
            </p>
          </div>
          <div
            className={styles.contentSection}
            data-testid="review-affordability-info"
          >
            <ReviewAffordability
              projectListingID={projectId}
            />
          </div>
          <div
            className={styles.contentSection}
            data-testid="review-general-info"
          >
            <ReviewProjectGeneralInfo projectListingID={projectId} />
          </div>
          <div
            className={styles.contentSection}
            data-testid="review-Financial-info"
          >
            <ReviewFinancialDetail projectListingID={projectId} setCapitalStacking={setCapitalStacking} />
          </div>
          <div
            className={styles.contentSection}
            data-testid="review-Focus-info"
          >
            <ReviewProjectImpactCriteriaInfo projectListingID={projectId} setEsgSelection={setEsgSelection} />
          </div>
          <div
            className={styles.contentSection}
          >
            <ReviewProjectDocumentation projectListingID={projectId} />
          </div>

          <div className={styles.noteConfirmationContainer}>
            <h2 className={styles.noteHeading}>
              {t("profile:developer.projects.file_upload.confirm_heading")}
            </h2>
                <p>
                  {t("profile:developer.projects.file_upload.confirm_note_project")}
                </p>
          </div>

          <div className={styles.buttonContainer}>
            <button
              type="submit"
              className={styles.formButton}
              onClick={handleConfirm}
              data-testid="confirm-btn"
            >
              {t("profile:developer.projects.review_project.confirm_button")}
            </button>
          </div>
        </div>
      </PageContent>
    </PageLayout>
  );
};
