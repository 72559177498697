import { useTranslation } from "react-i18next";
import { dollorIcon } from "../../../assets/icons";
import styles from "./ReviewInfo.module.scss";
import { DeveloperApi } from "../../../APIAction/DeveloperAPI";
import { useEffect, useState } from "react";

import { useNavigate, useParams } from "react-router-dom";
import { useUser } from "../../../components/Context/UserContext";
import { getEnumKeyByValue, PROVINCES } from "../../../Constants/Enumerations";
import { DeveloperExperience } from "../../../TypesAndInterfaces/DeveloperDetailsInterface";

export const ReviewDeveloperInfo = () => {
  const { t } = useTranslation("onboarding");
  const { userState } = useUser();
  const navigate = useNavigate();
  const [dataExists, setDataExists] = useState<boolean>(false);
  const [developerProjectsData, setDeveloperProjectsData] =
    useState<DeveloperExperience>();
  const { developerId = "" } = useParams();

  useEffect(() => {
    const DeveloperInfoDataFetch = async () => {
      const response = await DeveloperApi.getDeveloperInfoById(
        developerId
      );
      setDeveloperProjectsData(response.data);
      setDataExists(true);
    };
    DeveloperInfoDataFetch();
  }, [userState.userId]);

  const handleEditClick = () => {
    navigate("/onboarding/developer/edit");
  };

  return (
    <div className={styles.informationContainer}>
      <div className={styles.infoHeadingContainer}>
        <div className={styles.left}>
          <div className={styles.imgContainer}>
            <img src={dollorIcon} className={styles.circle} alt="" />
          </div>
          <h3 className={styles.sectionHeading}>
            {t("developer.main_page.form_accordions.developerInfo.heading")}
          </h3>
        </div>
        <button className={styles.link} onClick={handleEditClick}>
          {t("investor.review_profile.edit_link")}
        </button>
      </div>
      {dataExists && (
        <div className={styles.content}>
          <p className={styles.title}>
            {t("developer.review_profile.developer_info.previous_experience_heading")}
          </p>
          <div className={styles.grid_container}>
            <p>
              {t("developer.review_profile.developer_info.previous_experience_question")}
            </p>
            <p>
              {developerProjectsData?.developingExperienceFlag === true
                ? t("investor.investment_info_form.yes_label")
                : t("investor.investment_info_form.no_label")}
            </p>
          </div>
          {developerProjectsData?.developingExperienceFlag === true &&
            developerProjectsData.projects.length > 0 &&
            developerProjectsData.projects.map((experience, index) => {
              const provinceMappedValue = getEnumKeyByValue(
                PROVINCES,
                experience.province
              );

              return (
                <div key={index}>
                  <hr className={styles.horizontalLine} />
                  <p className={styles.experienceHeading}>{`${t(
                    "developer.review_profile.developer_info.experience"
                  )} ${index + 1}`}</p>
                  <div className={styles.grid_container}>
                    <p>
                      {t(
                        "developer.review_profile.developer_info.project_name"
                      )}
                    </p>
                    <p>{experience.projectName}</p>
                    <p>
                      {t("developer.review_profile.developer_info.location")}
                    </p>
                    <p>
                    {experience.city}, {t(
                        `common:enumerations.provinces.${provinceMappedValue}`
                      )}
                    </p>
                    <p>
                      {t(
                        "developer.review_profile.developer_info.project_description"
                      )}
                    </p>
                    <p>{experience.projectDescription}</p>
                  </div>
                </div>
              );
            })}
        </div>
      )}
    </div>
  );
};
