import { useTranslation } from "react-i18next";
import styles from "./ViewInvestor.module.scss";
import { ImpactInfoDetailsSection } from "../../components/common/ImpactSection/ImpactInfoDetailsSection/ImpactInfoDetailsSection";
import { InvestorOrganizationImpact } from "../../TypesAndInterfaces/InvestorDetailsInterface";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { isEmpty } from "../../Constants/basicFunctions";

type InvestorOrganizationInfoProps = {
  investorOrganizationInfo: InvestorOrganizationImpact;
  otherUserLoggedInflag: boolean;
};

export function ViewInvestorOrganizationImpact({
  investorOrganizationInfo,
  otherUserLoggedInflag,
}: InvestorOrganizationInfoProps) {
  const {
    environmental,
    social,
    governance,
    environmentalDescription,
    socialDescription,
    governanceDescription,
  } = investorOrganizationInfo;

  const { t } = useTranslation(["profile", "onboarding"]);
  const navigate = useNavigate();
  const [noImpactInfo, setNoImpactInfo] = useState(false);
  const editInvestorProfile = "/profile/investor/edit";

  useEffect(() => {
    if (isEmpty(environmental) &&
      isEmpty(social) &&
      isEmpty(governance) &&
      isEmpty(environmentalDescription) &&
      isEmpty(socialDescription) &&
      isEmpty(governanceDescription)) {
      setNoImpactInfo(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [investorOrganizationInfo]);

  return (
    <>
      <div className={styles.content} data-testid="view-impact-container">
        <h2 className={styles.mainHeading} data-testid={"page-title"}>
          {t("investor.investor_impact_criteria_info.page_title")}
        </h2>
        {!noImpactInfo &&
          <div className="impactInfoDetailsSection" data-testid="impact-infoDetails-section">
            {/* Environmental */}
            <ImpactInfoDetailsSection
              titleText={t("investor.investor_impact_criteria_info.environmental")}
              descriptionLabel={t("investor.investor_impact_criteria_info.environmenta_des_label")}
              description={environmentalDescription}
              noImpactLabel={t("investor.investor_impact_criteria_info.noImpactLabel")}
              addImpactLinkText={t("investor.investor_impact_criteria_info.addImpactLinkText")}
              addImpactLink={editInvestorProfile}
              otherUserLoggedInflag={otherUserLoggedInflag}
              tagsData={environmental}
            />

            {/* /* Social */}
            <ImpactInfoDetailsSection
              titleText={t("investor.investor_impact_criteria_info.social")}
              descriptionLabel={t("investor.investor_impact_criteria_info.social_des_label")}
              description={socialDescription}
              noImpactLabel={t("investor.investor_impact_criteria_info.noImpactLabel")}
              addImpactLinkText={t("investor.investor_impact_criteria_info.addImpactLinkText")}
              addImpactLink={editInvestorProfile}
              otherUserLoggedInflag={otherUserLoggedInflag}
              tagsData={social}
            />

            {/* Governance */}
            <ImpactInfoDetailsSection
              titleText={t("investor.investor_impact_criteria_info.governance")}
              descriptionLabel={t("investor.investor_impact_criteria_info.governance_des_label")}
              description={governanceDescription}
              noImpactLabel={t("investor.investor_impact_criteria_info.noImpactLabel")}
              addImpactLinkText={t("investor.investor_impact_criteria_info.addImpactLinkText")}
              addImpactLink={editInvestorProfile}
              otherUserLoggedInflag={otherUserLoggedInflag}
              tagsData={governance}
            />

          </div>
        }
        {noImpactInfo &&
          <div className={styles.noOrgImpact}>
            <p>{t("investor.investor_impact_criteria_info.noImpactSectionText")}</p>
            {!otherUserLoggedInflag && <button
              type="button"
              className={styles.link}
              data-testid="add-impact-link"
              onClick={() => { navigate(editInvestorProfile) }}
            >
              {t("investor.investor_impact_criteria_info.addImpactLinkText")}
            </button>
            }
          </div>
        }
      </div >
    </>
  );
};
