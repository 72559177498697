import { useTranslation } from "react-i18next";

import styles from "./ViewDeveloper.module.scss";
import { ProjectStatus } from "../../../components/common/Cards/ProjectStatus";
import ShowMoreLess from "../../../components/common/ShowMoreLess";
import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { DeveloperProjectInfo, ProjectInfo } from "../../../TypesAndInterfaces/DeveloperDetailsInterface";
import { FUNDING_TYPES, getEnumKeyByValue } from "../../../Constants/Enumerations";
import { numberWithCommas } from "../../../Constants/Utils/FormateNumber";
import { useUser } from "../../../components/Context/UserContext";

type ViewProjectInfoProps = {
    projectInfo: DeveloperProjectInfo[];
    provinceOptions: {
        label: string;
        value: number;
    }[];
    otherUserLoggedInflag: boolean;
    developerId?: string;
};

export const ViewProjectInfo = ({
    projectInfo,
    provinceOptions,
    otherUserLoggedInflag,
    developerId
}: ViewProjectInfoProps) => {


    const { t } = useTranslation(["profile", "developer"]);
    const navigate = useNavigate();
    const location = useLocation();
    const { userState } = useUser();

    const limit = 4;
    const projectFinderPage = '/projects/add';
    const pathname = location.pathname;
    const projectsPath = pathname.replace(pathname, '/projects/');

    const fundingTypesMappedValues = (fundingTypes: number[]): string | undefined => {
        const fundingTypesMappedData = fundingTypes.map((val) =>
            getEnumKeyByValue(FUNDING_TYPES, val)
        )
        const fundingTypesTranslated = fundingTypesMappedData.map((val) =>
            t(`common:enumerations.funding_types.${val}`)
        );
        const fundingTypesData = fundingTypesTranslated?.map(
            (val, idx) =>
                `${val}`
        )
        return fundingTypesData.join(', ');
    }

    const projectCards: (project: ProjectInfo, index: number) => React.ReactNode = (project, index) => {
        const totalNumberOfUnitsPercent = project.totalUnitCount && project.belowAverageMarketRentUnitCount ? (project.belowAverageMarketRentUnitCount / project.totalUnitCount) * 100 : 0;

        return (
            <div className={styles.projectsTile} key={index}>
                <div className={styles.projectSummary}>
                    <div
                        className={`${styles.projectStatus} ${project.projectListingStatus === 2 ? styles.greenBackground : styles.redBackground
                            }`}
                        data-testid="project-status"
                    >
                        <ProjectStatus status={project.projectListingStatus} />
                    </div>

                    <div className={styles.projectDetails}>
                        <button
                            type="button"
                            className={styles.projectCardLink}
                            data-testid="proj-name-link"
                            onClick={() => { window.location.href = projectsPath + `${project.projectListingID}` }}
                        >
                            {project.projectName}
                        </button>
                        <p
                            className={styles.projectDetailText}
                            data-testid="address"
                        >{`${project.city}, ${provinceOptions.find((val) => val.value === project.province)?.label}`}</p>
                    </div>
                </div>
                <div className={styles.preferenceContainer}>
                    <p className={styles.totalCostTitle}>{t("developer.developer_view_project.capitalNeeded")}</p>
                    <p className={styles.totalCost}>{"$" + numberWithCommas(project.totalFundingNeeded)}</p>
                    {project.totalUnitCount && project.projectAffordabilityType &&
                        <React.Fragment>
                            <p className={styles.totalCostTitle}>
                                {t("developer.developer_view_project.totalNumberOfUnits")}
                            </p>
                            <p className={styles.totalCost}>
                                {project.totalUnitCount} <span className={styles.totalCostTitle}>({project.projectAffordabilityType === 1 ? 100 : totalNumberOfUnitsPercent.toFixed(0)}{t("developer.developer_view_project.affordableUnits")})</span>
                            </p>
                        </React.Fragment>
                    }
                </div>
            </div>
        )
    }

    return (
        <div data-testid="projects" className={styles.projectsContainer}>
            <h3 className={styles.mainHeading} data-testid="title">{t("developer.developer_view_project.heading")} {`(${projectInfo.length})`} </h3>
            {projectInfo.length > 0 ?
                (<div className={styles.projectCards}>
                    <ShowMoreLess content={projectInfo} limit={limit} renderFunction={projectCards} />
                </div>)
                :
                (<div className={styles.noProject}>
                    <p>{otherUserLoggedInflag ? t("developer.developer_view_project.noProjectsDeveloper") : t("developer.developer_view_project.noProjectsLabel")}</p>
                    {userState.userId === developerId && otherUserLoggedInflag ? (
                        <button
                            type="button"
                            className={styles.link}
                            data-testid="add-impact-link"
                            onClick={() => { navigate(projectFinderPage) }}
                        >
                            {t("developer.developer_view_project.clickToAddProjectLink")}
                        </button>) : ""
                    }
                </div>)
            }
        </div>
    );
};
