import { Formik, Form } from "formik";
import { useEffect, useState } from "react";
import React from "react";

import { useTranslation } from "react-i18next";
import { CheckBoxGroup } from "../common/CheckBoxGroup";
import * as Yup from "yup";
import { SelectInput } from "../common/SelectInput";
import { RadioInput } from "../common/RadioInput";
import { TextInput } from "../common/TextInput";
import { NumberInput } from "../common/NumberInput";

import { AddProjectProps, ProjFinancialDetailI } from "./AddProjectProps";
import styles from "./styles.module.scss";
import { TextArea } from "../common/TextArea";
import { ListingProjectAPI } from "../../../APIAction/ListingProjectAPI";
import {
  DOLLAR_RANGE,
  FUNDING_TYPES,
  getKVArrayFormat,
  getKVArrayFormatForDropdown,
  INVESTMENT_PHASES,
  LOCKUP_PERIOD,
  ROI_RANGE,
  CAPITALSOURCES_TYPES
} from "../../../Constants/Enumerations";
import { removeCommas, numberWithCommas } from "../../../Constants/Utils/FormateNumber";
import { GlossaryDefinition } from "../../../pages/Developers/Modals/GlossaryDefinitionModal/GlossaryDefinition";

import axios from "axios";
import { useNavigate } from "react-router-dom";
import { RadioInputGroup } from "../common/RadioInputGroup";

export function ProjectFinancialDetails({
  handleResponse,
  projectListingId,
}: AddProjectProps) {
  const { t } = useTranslation(["profile", "common", "project"]);
  const navigate = useNavigate();
  const [isDataExist, setIsDataExist] = useState(false);
  const [financialDetailsInfo, setfinancialDetailsInfo] =
    useState<ProjFinancialDetailI>();
  const [isApiLoading, setIsApiLoading] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isTotalCapitalOpenModal, setIsTotalCapitalOpenModal] = useState(false);

  const apiErrorPage = "/api-error";

  const sumErrorMessage = t("developer.projects.financial_details_doc_form.sum_error");

  const amountErrorMessage = t("developer.projects.financial_details_doc_form.amount_error");

  const requiredErrorMessage = t("developer.projects.financial_details_form_error.required_error");

  useEffect(() => {
    const getFinancialDetailsInformation = async (id: string) => {
      try {
        setIsApiLoading(true);
        const res = await ListingProjectAPI.getFinancialInfoById(id);
        const data = res.data;
        setIsApiLoading(false);
        setfinancialDetailsInfo(data);
        setIsDataExist(true);
      } catch (error) {
        setIsApiLoading(false);
        setIsDataExist(false);
        handleResponse("FinancialDetails", false);

        if (axios.isAxiosError(error) && error.response?.status !== 404) {
          navigate(apiErrorPage);
        }
      }
    };

    projectListingId && getFinancialDetailsInformation(projectListingId);
  }, [projectListingId, handleResponse, navigate]);

  const landStatusOption = [
    {
      label: t(`common:enumerations.land_status.LandSecured`),
      value: 1,
    },
    {
      label: t(`common:enumerations.land_status.LandNeeded`),
      value: 2,
    },
    {
      label: t(`common:enumerations.land_status.LandAcquisitioInProcess`),
      value: 3,
    },
    {
      label: t(`common:enumerations.land_status.PreferNotToDdisclose`),
      value: 9,
    }
  ];

  const initialValues = {
    financingRangeMinimum: "",
    financingRangeMaximum: "",
    coInvestingFlag: undefined,
    lockInPeriodDetailDescription: "",
    lockInPeriodRequiredFlag: "",
    constructionType: "",
    investmentPhases: [],
    capitalCostRangeMinimum: "",
    capitalCostRangeMaximum: "",
    fundingTypes: [],
    governmentFundingSupportFlag: "",
    governmentProgramSpecifics: "",
    displayFundingFlag: "",
    totalProjectCost: "",
    grantAmount: "",
    equityAmount: "",
    debtFinancingAmount: "",
    bridgeFinancingAmount: "",
    anyCapitalAmount: "",

    capitalAcquiredAmount: "",
    totalFundingNeeded: "",
    capitalSources: [],
    projectPhase: "",
    landStatus: ""

  };

  const ApiData = {
    financingRangeMinimum: financialDetailsInfo?.financingRangeMinimum,
    financingRangeMaximum: financialDetailsInfo?.financingRangeMaximum,
    coInvestingFlag: financialDetailsInfo?.coInvestingFlag === true ? "true" :
      financialDetailsInfo?.coInvestingFlag === false ? "false" : undefined,
    lockInPeriodRequiredFlag: financialDetailsInfo?.lockInPeriodRequiredFlag === true ? "true" : financialDetailsInfo?.lockInPeriodRequiredFlag === false ? "false" : undefined,
    lockInPeriodDetailDescription: financialDetailsInfo?.lockInPeriodDetailDescription && financialDetailsInfo.lockInPeriodDetailDescription,
    constructionType:
      financialDetailsInfo?.constructionType &&
      financialDetailsInfo?.constructionType.toString(),
    investmentPhases:
      financialDetailsInfo?.investmentPhases &&
      financialDetailsInfo.investmentPhases.map((opt) => opt.toString()),
    capitalCostRangeMinimum:
      financialDetailsInfo?.capitalCostRangeMinimum?.toString(),
    capitalCostRangeMaximum:
      financialDetailsInfo?.capitalCostRangeMaximum?.toString(),
    fundingTypes:
      financialDetailsInfo?.fundingTypes &&
      financialDetailsInfo.fundingTypes.map((opt) => opt.toString()),
    governmentFundingSupportFlag:
      financialDetailsInfo?.governmentFundingSupportFlag === true
        ? "true"
        : "false",
    governmentProgramSpecifics:
      financialDetailsInfo?.governmentProgramSpecifics,
    displayFundingFlag: financialDetailsInfo?.displayFundingFlag === true ? "true" : "false",
    totalProjectCost: financialDetailsInfo?.totalProjectCost &&
      numberWithCommas(financialDetailsInfo?.totalProjectCost.toString()),


    grantAmount: financialDetailsInfo?.grantAmount && financialDetailsInfo?.displayFundingFlag ?
      numberWithCommas(financialDetailsInfo?.grantAmount.toString()) : "",
    equityAmount: financialDetailsInfo?.equityAmount && financialDetailsInfo?.displayFundingFlag ?
      numberWithCommas(financialDetailsInfo?.equityAmount.toString()) : "",
    debtFinancingAmount: financialDetailsInfo?.debtFinancingAmount && financialDetailsInfo?.displayFundingFlag ?
      numberWithCommas(financialDetailsInfo?.debtFinancingAmount.toString()) : "",
    bridgeFinancingAmount: financialDetailsInfo?.bridgeFinancingAmount && financialDetailsInfo?.displayFundingFlag ?
      numberWithCommas(financialDetailsInfo?.bridgeFinancingAmount.toString()) : "",
    anyCapitalAmount: financialDetailsInfo?.anyCapitalAmount && financialDetailsInfo?.displayFundingFlag ?
      numberWithCommas(financialDetailsInfo?.anyCapitalAmount.toString()) : "",

    totalFundingNeeded: financialDetailsInfo?.totalFundingNeeded && !financialDetailsInfo?.displayFundingFlag ?
      numberWithCommas(financialDetailsInfo?.totalFundingNeeded.toString()) : "",
    capitalAcquiredAmount: financialDetailsInfo?.capitalAcquiredAmount &&
      numberWithCommas(financialDetailsInfo?.capitalAcquiredAmount.toString()),
    projectPhase: financialDetailsInfo?.projectPhase && financialDetailsInfo?.projectPhase.toString(),
    capitalSources: financialDetailsInfo?.capitalSources && financialDetailsInfo.capitalSources.map((opt) => opt.toString()),
    additionalCapitalAcquiredDetails: financialDetailsInfo?.additionalCapitalAcquiredDetails && financialDetailsInfo?.additionalCapitalAcquiredDetails,
    landStatus: landStatusOption.find(item => item.value === financialDetailsInfo?.landStatus) ? financialDetailsInfo?.landStatus.toString() : "",
  };

  const lockupPeriodTrans = Object.keys(LOCKUP_PERIOD).map((val) =>
    t(`common:enumerations.lockup_period.${val}`)
  );
  const lockupPeriodPlaceholder = {
    label: t("developer.projects.financial_details_doc_form.select_value_text"),
    value: "",
  };
  const maximumLockupPeriodOptions: {
    label: string;
    value: string | number;
  }[] = getKVArrayFormatForDropdown(LOCKUP_PERIOD, lockupPeriodTrans);
  maximumLockupPeriodOptions.unshift(lockupPeriodPlaceholder);

  const isAmountRequired = (fundingType: number) => ({
    is: (fundingTypes: string[], displayFundingFlag: boolean) => 
      displayFundingFlag === true && fundingTypes.includes(`${fundingType}`),
    then: Yup.number()
      .transform((o, v) => parseFloat(v.replace(/,/g, '')))
      .required(requiredErrorMessage)
      .min(1, amountErrorMessage),
    otherwise: Yup.number().notRequired()
  })

  const financialDetailsSchema = Yup.object().shape({
    totalProjectCost: Yup.string().required(
      t("developer.projects.financial_details_form_error.required_error")
    ),
    capitalAcquiredAmount: Yup.string().required(
      t("developer.projects.financial_details_form_error.required_error")
    ),
    projectPhase: Yup.string().required(
      t("developer.projects.financial_details_form_error.project_phase_required")
    ),
    landStatus: Yup.string().required(
      t("developer.projects.financial_details_form_error.landstatus_required")
    ),
    displayFundingFlag: Yup.boolean().required(
      t("developer.projects.financial_details_form_error.displayFundingFlag_required")
    ),
    constructionType: Yup.string().required(
      t(
        "developer.projects.financial_details_form_error.construction_type_required"
      )
    ),
    investmentPhases: Yup.array()
      .required(
        t(
          "developer.projects.financial_details_doc_form.general_form_error.general_required"
        )
      )
      .min(
        1,
        t(
          "developer.projects.financial_details_form_error.investment_phases_project_required"
        )
      ),
    capitalCostRangeMinimum: Yup.number().required(
      t("developer.projects.financial_details_form_error.roi_min_required")
    ),
    capitalCostRangeMaximum: Yup.number()
      .required(
        t("developer.projects.financial_details_form_error.roi_max_required")
      )
      .moreThan(
        Yup.ref("capitalCostRangeMinimum"),
        t("developer.projects.financial_details_form_error.roi_required")
      ),
    fundingTypes: Yup.array()
      .required(
        t(
          "developer.projects.financial_details_doc_form.general_form_error.general_required"
        )
      )
      .min(
        1,
        t(
          "developer.projects.financial_details_form_error.fundingtypes_required"
        )
      ),
    governmentProgramSpecifics: Yup.string().max(
      1000,
      t("developer.projects.financial_details_form_error.max_characters", { charNum: 1000 })
    ),
    anyCapitalAmount: Yup.number()
      .when(['fundingTypes', 'displayFundingFlag'], isAmountRequired(FUNDING_TYPES.AnyTypeofCapital)),
    bridgeFinancingAmount: Yup.number()
      .when(['fundingTypes', 'displayFundingFlag'], isAmountRequired(FUNDING_TYPES.BridgeFinancing)),
    debtFinancingAmount: Yup.number()
      .when(['fundingTypes', 'displayFundingFlag'], isAmountRequired(FUNDING_TYPES.DebtFinancing)),
    equityAmount: Yup.number()
      .when(['fundingTypes', 'displayFundingFlag'], isAmountRequired(FUNDING_TYPES.Equity)),
    grantAmount: Yup.number()
      .when(['fundingTypes', 'displayFundingFlag'], isAmountRequired(FUNDING_TYPES.Granting)),
    totalFundingNeeded: Yup.number().when(["displayFundingFlag"], {
      is: false,
      then: Yup.number()
        .transform((o, v) => parseFloat(v.replace(/,/g, "")))
        .required(requiredErrorMessage)
        .min(1, amountErrorMessage),
      otherwise: Yup.number().notRequired(),
    }),
  });

  const submitfinancialDetailsInformation = async (formValues: Object) => {
    try {
      setIsApiLoading(true);
      await ListingProjectAPI.postFinancialInfo(formValues);
      setIsApiLoading(false);
      handleResponse("FinancialDetails", true);
    } catch (error) {
      setIsApiLoading(false);
      handleResponse("FinancialDetails", false);

      if (axios.isAxiosError(error) && error.response?.status !== 404) {
        navigate(apiErrorPage);
      }
    }
  };

  const updatefinancialDetailsInformation = async (formValues: Object) => {
    if (!projectListingId) {
      return;
    }
    try {
      setIsApiLoading(true);
      await ListingProjectAPI.updateFinancialInfo(projectListingId, formValues);
      setIsApiLoading(false);
      handleResponse("FinancialDetails", true);
    } catch (error) {
      setIsApiLoading(false);
      handleResponse("FinancialDetails", false);

      if (axios.isAxiosError(error) && error.response?.status !== 404) {
        navigate(apiErrorPage);
      }
    }
  };

  const dolllarRangesTrans = Object.keys(DOLLAR_RANGE).map((val) =>
    t(`common:enumerations.dollar_range.${val}`)
  );

  const dollarPlaceholderMin = {
    label: t(
      "developer.projects.financial_details_doc_form.funding_amount_min_placeholder"
    ),
    value: "",
  };

  const investmentMinAmountOptions = getKVArrayFormatForDropdown(
    DOLLAR_RANGE,
    dolllarRangesTrans
  ).map(({ label, value }) => ({ label: label, value: value }));
  investmentMinAmountOptions.unshift(dollarPlaceholderMin);

  const dollarPlaceholderMax = {
    label: t(
      "developer.projects.financial_details_doc_form.funding_amount_max_placeholder"
    ),
    value: "",
  };

  const investmentMaxAmountOptions = getKVArrayFormatForDropdown(
    DOLLAR_RANGE,
    dolllarRangesTrans
  ).map(({ label, value }) => ({ label: label, value: value }));
  investmentMaxAmountOptions.unshift(dollarPlaceholderMax);

  const capitalCostTranslation = Object.keys(ROI_RANGE).map((val) =>
    t(`common:enumerations.roi_range.${val}`)
  );

  const capitalCostRange = getKVArrayFormatForDropdown(
    ROI_RANGE,
    capitalCostTranslation
  );

  const capitalCostRangeMin = Array.from(capitalCostRange);
  capitalCostRangeMin.unshift({
    label: t(
      "developer.projects.financial_details_doc_form.funding_amount_min_placeholder"
    ),
    value: "",
  });

  const capitalCostRangeMax = Array.from(capitalCostRange);
  capitalCostRangeMax.unshift({
    label: t(
      "developer.projects.financial_details_doc_form.funding_amount_max_placeholder"
    ),
    value: "",
  });

  const radioOptions = [
    {
      label: t("developer.projects.financial_details_doc_form.yes_label"),
      value: "true",
    },
    {
      label: t("developer.projects.financial_details_doc_form.no_label"),
      value: "false",
    },
  ];

  const constructionTypeOption = [
    {
      label: t("developer.projects.financial_details_doc_form.project_type_1"),
      value: "1",
    },
    {
      label: t("developer.projects.financial_details_doc_form.project_type_2"),
      value: "2",
    },
  ];


  const projectPhaseOption = [
    {
      label: t(`common:enumerations.investment_phases.Acquisition`),
      value: 1,
    },
    {
      label: t(`common:enumerations.investment_phases.PreDevelopment`),
      value: 2,
    },
    {
      label: t(`common:enumerations.investment_phases.Construction`),
      value: 3,
    },
    {
      label: t(`common:enumerations.investment_phases.PostConstruction`),
      value: 4,
    },
    {
      label: t(`common:enumerations.investment_phases.Seed`),
      value: 5,
    },
  ];

  const projectSeedOption = projectPhaseOption.pop();
  projectSeedOption && projectPhaseOption.unshift(projectSeedOption);

  const investmentPhasesTranslations = Object.keys(INVESTMENT_PHASES).map(
    (val) => t(`common:enumerations.investment_phases.${val}`)
  );
  const investmentPhasesOptions = getKVArrayFormat(
    INVESTMENT_PHASES,
    investmentPhasesTranslations
  );

  const seedOption = investmentPhasesOptions.pop();
  seedOption && investmentPhasesOptions.unshift(seedOption);


  const capitalInjectionTranslations = Object.keys(FUNDING_TYPES).map((val) =>
    t(`common:enumerations.funding_types.${val}`)
  );


  const capitalSourcesTranslations = Object.keys(CAPITALSOURCES_TYPES).map((val) =>
    t(`common:enumerations.capitalSources_types.${val}`)
  );

  const capitalInjectionTypes = getKVArrayFormat(
    FUNDING_TYPES,
    capitalInjectionTranslations
  );

  const capitalSourcesTypes = getKVArrayFormat(
    CAPITALSOURCES_TYPES,
    capitalSourcesTranslations
  );

  function validateForm(values: any, setErrors: any) {
    const { totalProjectCost, totalFundingNeeded, capitalAcquiredAmount, anyCapitalAmount, bridgeFinancingAmount, debtFinancingAmount, equityAmount, grantAmount, displayFundingFlag } = values;
    let errors: any = {};

    if (totalProjectCost === 0 || totalProjectCost === null) {
      errors.totalProjectCost = amountErrorMessage;
    }

    if ((totalFundingNeeded === 0 || totalFundingNeeded === null) && displayFundingFlag === false) {
      errors.totalFundingNeeded = amountErrorMessage;

    }

    if ((capitalAcquiredAmount === 0 || capitalAcquiredAmount === null)) {
      errors.capitalAcquiredAmount = amountErrorMessage;
    }

    if (Object.keys(errors).length !== 0) {
      setErrors(errors);
      return true;
    }

    if (displayFundingFlag === true) {
      const total = anyCapitalAmount + bridgeFinancingAmount + debtFinancingAmount + equityAmount + grantAmount;

      if (totalProjectCost > 0 && capitalAcquiredAmount > 0) {
        if ((total + capitalAcquiredAmount) > totalProjectCost) {

          if (totalProjectCost > 0) {
            errors.totalProjectCost = sumErrorMessage;

          }
          if (capitalAcquiredAmount > 0) {
            errors.capitalAcquiredAmount = sumErrorMessage;

          }
          if (anyCapitalAmount > 0) {
            errors.anyCapitalAmount = sumErrorMessage;

          }
          if (bridgeFinancingAmount > 0) {
            errors.bridgeFinancingAmount = sumErrorMessage;

          }
          if (debtFinancingAmount > 0) {
            errors.debtFinancingAmount = sumErrorMessage;

          }
          if (equityAmount > 0) {
            errors.equityAmount = sumErrorMessage;


          }
          if (grantAmount > 0) {
            errors.grantAmount = sumErrorMessage;

          }
          setErrors(errors);
          return true;

        }
      }

    } else {
      if (totalProjectCost > 0 && totalFundingNeeded > 0 && capitalAcquiredAmount > 0) {
        if ((totalFundingNeeded + capitalAcquiredAmount) > totalProjectCost) {
          setErrors({ totalProjectCost: sumErrorMessage, totalFundingNeeded: sumErrorMessage, capitalAcquiredAmount: sumErrorMessage });
          return true;
        }

      }
    }
    if (Object.keys(errors).length !== 0) {


      setErrors(errors);
      return true;
    }

    return false;
  }

  return (
    <Formik
      initialValues={isDataExist ? ApiData : initialValues}
      validationSchema={financialDetailsSchema}
      validateOnChange={false}
      validateOnBlur={false}
      enableReinitialize={true}

      onSubmit={(values: any, { setErrors }) => {

        let {
          financingRangeMaximum,
          financingRangeMinimum,
          coInvestingFlag,
          lockInPeriodRequiredFlag,
          constructionType,
          investmentPhases,
          fundingTypes,
          capitalCostRangeMaximum,
          capitalCostRangeMinimum,
          governmentFundingSupportFlag,
          displayFundingFlag,
          totalProjectCost,
          grantAmount,
          equityAmount,
          debtFinancingAmount,
          bridgeFinancingAmount,
          anyCapitalAmount,
          capitalAcquiredAmount,
          totalFundingNeeded,
          capitalSources,
          projectPhase,
          ...restValues
        } = values;

        let totalFundingNeededTotal = 0;
        const capitalInjectionPhasesNunberList = fundingTypes?.map(
          (str: string) => +str
        );
        if (displayFundingFlag === "true" || displayFundingFlag === true) {

          if (grantAmount && capitalInjectionPhasesNunberList.filter((value: any) => value === 1).length !== 0) {
            totalFundingNeededTotal = totalFundingNeededTotal + Number(removeCommas(grantAmount));
          }
          if (equityAmount && capitalInjectionPhasesNunberList.filter((value: any) => value === 5).length !== 0) {
            totalFundingNeededTotal = totalFundingNeededTotal + Number(removeCommas(equityAmount));
          }
          if (debtFinancingAmount && capitalInjectionPhasesNunberList.filter((value: any) => value === 2).length !== 0) {
            totalFundingNeededTotal = totalFundingNeededTotal + Number(removeCommas(debtFinancingAmount));
          }
          if (bridgeFinancingAmount && capitalInjectionPhasesNunberList.filter((value: any) => value === 4).length !== 0) {
            totalFundingNeededTotal = totalFundingNeededTotal + Number(removeCommas(bridgeFinancingAmount));
          }
          if (anyCapitalAmount && capitalInjectionPhasesNunberList.filter((value: any) => value === 9).length !== 0) {
            totalFundingNeededTotal = totalFundingNeededTotal + Number(removeCommas(anyCapitalAmount));
          }
          totalFundingNeeded = String(totalFundingNeededTotal);
        }

        const investmentPhasesNumberList = investmentPhases?.map((str: string) => +str);

        const capitalSourcesNunberList = capitalSources?.map(
          (str: string) => +str
        );

        const updatedValues = {
          financingRangeMaximum: null,
          financingRangeMinimum: null,
          coInvestingFlag: coInvestingFlag === "true" ? true : coInvestingFlag === "false" ? false : undefined,
          lockInPeriodRequiredFlag: lockInPeriodRequiredFlag === "" ? undefined : lockInPeriodRequiredFlag === "true" ? true : lockInPeriodRequiredFlag === "false" ? false : undefined,
          constructionType: constructionType ? +constructionType : "", // good
          investmentPhases: investmentPhasesNumberList,
          fundingTypes: capitalInjectionPhasesNunberList,
          capitalCostRangeMaximum: capitalCostRangeMaximum
            ? +capitalCostRangeMaximum
            : "",
          capitalCostRangeMinimum: capitalCostRangeMinimum
            ? +capitalCostRangeMinimum
            : "",
          governmentFundingSupportFlag:
            governmentFundingSupportFlag === "true" ? true : false,
          projectListingID: projectListingId,
          displayFundingFlag: displayFundingFlag === "true" ? true : false,
          totalProjectCost: Number(removeCommas(totalProjectCost)),
          grantAmount: grantAmount && (displayFundingFlag === "true" || displayFundingFlag === true) && capitalInjectionPhasesNunberList.filter((value: any) => value === 1).length !== 0 ? Number(removeCommas(grantAmount)) : null,
          equityAmount: equityAmount && (displayFundingFlag === "true" || displayFundingFlag === true) && capitalInjectionPhasesNunberList.filter((value: any) => value === 5).length !== 0 ? Number(removeCommas(equityAmount)) : null,
          debtFinancingAmount: debtFinancingAmount && (displayFundingFlag === "true" || displayFundingFlag === true) && capitalInjectionPhasesNunberList.filter((value: any) => value === 2).length !== 0 ? Number(removeCommas(debtFinancingAmount)) : null,
          bridgeFinancingAmount: bridgeFinancingAmount && (displayFundingFlag === "true" || displayFundingFlag === true) && capitalInjectionPhasesNunberList.filter((value: any) => value === 4).length !== 0 ? Number(removeCommas(bridgeFinancingAmount)) : null,
          anyCapitalAmount: anyCapitalAmount && (displayFundingFlag === "true" || displayFundingFlag === true) && capitalInjectionPhasesNunberList.filter((value: any) => value === 9).length !== 0 ? Number(removeCommas(anyCapitalAmount)) : null,

          totalFundingNeeded: totalFundingNeeded ? Number(removeCommas(totalFundingNeeded)) : null,
          capitalAcquiredAmount: Number(removeCommas(capitalAcquiredAmount)),
          capitalSources: capitalSourcesNunberList,
          projectPhase: Number(projectPhase),
          ...restValues,
        };

        const validationCheck = validateForm(updatedValues, setErrors);

        if (!validationCheck) {
          isDataExist
            ? updatefinancialDetailsInformation(updatedValues)
            : submitfinancialDetailsInformation(updatedValues);
        }

      }}
    >
      {({
        values,
        errors,
      }) => (
        <Form className={styles.formElement} noValidate>
          <h3 className={styles.formSectionHeading}>
            {t("developer.projects.financial_details_doc_form.total_project_details")}
          </h3>
          <NumberInput
            fieldName="totalProjectCost"
            labelText={t("developer.projects.financial_details_doc_form.total_project_label")}
            required
            data-testid="total-project-cost"
          />

          <h4 className={styles.formQuestion}>
            {t("developer.projects.financial_details_doc_form.project_types")}
          </h4>
          <div
            className={styles.fieldContainer}
            data-testid="construction-types-radio"
          >
            <RadioInputGroup
              fieldName="constructionType"
              options={constructionTypeOption}
            />
          </div>

          <CheckBoxGroup
            fieldName="investmentPhases"
            formQuestionText={t(
              "developer.projects.financial_details_doc_form.funding_capital_investment"
            )}
            options={investmentPhasesOptions}
            data-testid="investment-phases"
          />
          <h4 className={styles.formQuestion}>
            {t("developer.projects.financial_details_doc_form.project_phase_label")}
          </h4>
          <div
            className={styles.fieldContainer}
          >
            <RadioInputGroup
              fieldName="projectPhase"
              options={projectPhaseOption}
              data-testid="project-phase-radio"
            />
          </div>
          <h4 className={styles.formQuestion}>
            {t("developer.projects.financial_details_doc_form.land_status_label")}
          </h4>
          <div
            className={styles.fieldContainer}
          >
            <RadioInputGroup
              fieldName="landStatus"
              options={landStatusOption}
              data-testid="land-status-radio"
            />
          </div>
          <hr></hr>
          <h3 className={styles.formSectionHeading}>
            {t("developer.projects.financial_details_doc_form.capital_needed_label")}
          </h3>
          <CheckBoxGroup
            fieldName="fundingTypes"
            formQuestionText={t("developer.projects.financial_details_doc_form.capital_injection_label"
            )}
            options={capitalInjectionTypes}
            data-testid="capital-funding-type"
            setIsOpenModal={setIsOpenModal}
            linkText={t("glossary.capitalAcquiredGlossaryContent.glossaryDefinitionLink")}

          />

          {isOpenModal && (
            <GlossaryDefinition
              setCloseGlossaryModal={setIsOpenModal}
              title={t("glossary.capitalAcquiredGlossaryContent.title")}
              glossaryContent={t(
                "glossary.capitalAcquiredGlossaryContent.capitalAcquiredGlossaryList",
                { returnObjects: true }
              )}
              glossaryLink={t("glossary.viewFullGlossaryLink")}
              closeText={t("glossary.closeButton")}
              selectedId="#4"
            />
          )}


          <h4 className={styles.formQuestion}>
            {t("developer.projects.financial_details_doc_form.display_funding_label")}
          </h4>
          <p className={styles.formSectionParagraph}>{t(
            "developer.projects.financial_details_doc_form.display_funding_label_helper_Text"
          )}</p>
          <div className={styles.fieldContainer}>
            <RadioInput
              fieldName="displayFundingFlag"
              options={radioOptions}
              required
              data-testid="display-funding-flag"
            />
          </div>

          {(values.displayFundingFlag === "false" || values.displayFundingFlag === false) &&
            <React.Fragment>
              <NumberInput
                fieldName="totalFundingNeeded"
                labelText={t("developer.projects.financial_details_doc_form.total_funding_needed_label")}
                required={values.displayFundingFlag === "false" ? true : false}
                data-testid="total-funding-needed"
                instructionText={t("developer.projects.financial_details_doc_form.mezzanine_financing_amount_label_placeholder")}
              />
            </React.Fragment>

          }

          {(values.displayFundingFlag === "true" || values.displayFundingFlag === true) &&
            <React.Fragment>
              <div className={styles.noteContainer}>
                <h2 className={styles.noteHeading}>
                  {t("developer.projects.note.heading")}
                </h2>
                <ul className={styles.noteList}>
                  <li className={styles.noteListItem}>
                    <p>{t("developer.projects.financial_details_doc_form.noteItem")}</p>
                  </li>
                </ul>
              </div>



              {(values?.fundingTypes?.filter((value: string | string[]) => value.includes('1'))[0] === '1') &&
                <React.Fragment>
                  <NumberInput
                    fieldName="grantAmount"
                    labelText={t("developer.projects.financial_details_doc_form.grant_amount_label")}
                    data-testid="grant-amount"
                    instructionText={t("developer.projects.financial_details_doc_form.grant_amount_label_placeholder")}
                  />
                </React.Fragment>
              }

             {values?.fundingTypes?.filter((value: string | string[]) => value.includes('2'))[0] === '2' &&
                <NumberInput
                  fieldName="debtFinancingAmount"
                  labelText={t("developer.projects.financial_details_doc_form.debt_financing_amount_label")}
                  data-testid="debt-financing-amount"
                  instructionText={t("developer.projects.financial_details_doc_form.mezzanine_financing_amount_label_placeholder")}
                />}

              {values?.fundingTypes?.filter((value: string | string[]) => value.includes('4'))[0] === '4' &&
                <NumberInput
                  fieldName="bridgeFinancingAmount"
                  labelText={t("developer.projects.financial_details_doc_form.bridge_financing_amount_label")}
                  data-testid="bridge-financing-amount"
                  instructionText={t("developer.projects.financial_details_doc_form.mezzanine_financing_amount_label_placeholder")}
                />}

              {values?.fundingTypes?.filter((value: string | string[]) => value.includes('5'))[0] === '5' &&
                <NumberInput
                  fieldName="equityAmount"
                  labelText={t("developer.projects.financial_details_doc_form.equity_amount_label")}
                  data-testid="equity-amount"
                  instructionText={t("developer.projects.financial_details_doc_form.mezzanine_financing_amount_label_placeholder")}
                />}

              {values?.fundingTypes?.filter((value: string | string[]) => value.includes('9'))[0] === '9' &&
                <NumberInput
                  fieldName="anyCapitalAmount"
                  labelText={t("developer.projects.financial_details_doc_form.any_capital_amount_label")}
                  data-testid="any-capital-amount"
                  instructionText={t("developer.projects.financial_details_doc_form.mezzanine_financing_amount_label_placeholder")}
                />}



            </React.Fragment>
          }

          <h4 className={styles.formQuestion}>
            {t("developer.projects.financial_details_doc_form.co_investing_label")}
          </h4>
          <p className={styles.formSectionParagraph}>{t(
            "developer.projects.financial_details_doc_form.co_investing_label_project_Helper_Text"
          )}</p>
          <div className={styles.fieldContainer}>
            <RadioInput
              fieldName="coInvestingFlag"
              options={radioOptions}
            />
          </div>
          <h4 className={styles.formQuestion}>
            {t("developer.projects.financial_details_doc_form.lockIn_period_label")}
          </h4>
          <div className={styles.fieldContainer}>
            <RadioInput
              fieldName="lockInPeriodRequiredFlag"
              options={radioOptions}
            />
          </div>
          <TextArea
            fieldName="lockInPeriodDetailDescription"
            labelText={t(
              "developer.projects.financial_details_doc_form.lockIn_Period_description_label"
            )}
            charLimit={300}
            placeholder={t("developer.projects.financial_details_doc_form.lockIn_Period_description_instruction")}
          />
          <hr></hr>
          <h3 className={styles.formSectionHeading}>
            {t("developer.projects.financial_details_doc_form.capital_acquired_amount_label")}
          </h3>
          <NumberInput
            fieldName="capitalAcquiredAmount"
            labelText={t(
              "developer.projects.financial_details_doc_form.capital_secured_label"
            )}
            required
            setIsOpenModal={setIsTotalCapitalOpenModal}
            linkText={t("glossary.totalCapitalSecuredCommittedContent.glossaryDefinitionLink")}
            data-testid="capital-acquired-amount"
          />
          {isTotalCapitalOpenModal && (
            <GlossaryDefinition
              setCloseGlossaryModal={setIsTotalCapitalOpenModal}
              glossaryContent={[]}
              title={t("glossary.totalCapitalSecuredCommittedContent.title")}
              paraContent={t("glossary.totalCapitalSecuredCommittedContent.paraContent", {
                returnObjects: true,
              })}
              glossaryLink={t("glossary.viewFullGlossaryLink")}
              closeText={t("glossary.closeButton")}
              selectedId="#4"
            />
          )}

          <CheckBoxGroup
            fieldName="capitalSources"
            formQuestionText={t(
              "developer.projects.financial_details_doc_form.capital_sources_label"
            )}
            required
            options={capitalSourcesTypes}
            data-testid="capital-sources"
          />
          <p className={`${styles.formQuestion} ${styles.mrgn_btm}`}>
            {t(
              "developer.projects.financial_details_doc_form.return_on_investment_label"
            )}
          </p>
          <p className={styles.helper_text} >{t(
            "developer.projects.financial_details_doc_form.return_on_investment_label_helper_text"
          )}
          </p>
          <div className={styles.splitFieldContainer}>
            <SelectInput
              fieldName="capitalCostRangeMinimum"
              labelText={t(
                "developer.projects.financial_details_doc_form.roi_min_label"
              )}
              options={capitalCostRangeMin}
              data-testid="capitalCostRangeMin"
            />
            <SelectInput
              fieldName="capitalCostRangeMaximum"
              labelText={t(
                "developer.projects.financial_details_doc_form.roi_max_label"
              )}
              options={capitalCostRangeMax}
              data-testid="capitalCostRangeMax"
            />
          </div>
          <TextArea
            fieldName="additionalCapitalAcquiredDetails"
            labelText={t(
              "developer.projects.financial_details_doc_form.additional_capital_acquired_details_label"
            )}
            placeholder={t(
              "developer.projects.financial_details_doc_form.additional_capital_acquired_details_placeholder"
            )}
            charLimit={300}
          />
          <div className={styles.buttonContainer}>
            <button
              type="submit"
              className={styles.formButton}
              data-testid="submit-button"
              disabled={isApiLoading}
            >
              {t(
                "developer.projects.financial_details_doc_form.Save_Continue_button"
              )}
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
}