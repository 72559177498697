import { DeveloperGeneralInfo } from "../../../TypesAndInterfaces/DeveloperDetailsInterface";
import { useNavigate } from "react-router-dom";

import styles from "./ViewDeveloper.module.scss";

type ViewGeneralInfoProps = {
  genInfo: DeveloperGeneralInfo;
  provinceOptions: {
    label: string;
    value: number;
  }[];
  projectDetailsPage?: boolean;
  id?: string;
};

export const ViewGeneralInfo = ({
  genInfo,
  provinceOptions,
  projectDetailsPage,
  id,
}: ViewGeneralInfoProps) => {
  const navigate = useNavigate();

  const {
    organizationName = "",
    address = "",
    unitNumber = "",
    city = "",
    country = "",
    postalCode = "",
    province = "",
    organizationSummary = "",
    websiteLink = "test",
  } = genInfo;

  const handleOpenLink = (link: string) => {
    // Check if the link is absolute
    const isAbsolute =
      link.startsWith("http://") || link.startsWith("https://");
    // If the link is absolute, open it directly
    if (isAbsolute) {
      window.open(link, "_blank");
    } else {
      // If the link is relative, construct the absolute URL
      const absoluteURL = window.location.protocol + "//" + link;
      window.open(absoluteURL, "_blank");
    }
  };



  return (
    <div className={styles.genInformationContainer}>
      <div className={styles.flex_container_top}>
        <div className={styles.Organizationaddress}>
          <h2
            className={
              projectDetailsPage
                ? styles.OrganizationtitleHyperLink
                : styles.Organizationtitle
            }
            onClick={id ? (e) => {
              e.preventDefault();
              navigate("/profile/developer/developerInfo", { state: id });
            }: undefined}
          >
            {organizationName}
          </h2>
          <p>
            {address}, {unitNumber ? `${unitNumber},` : null}
          </p>
          <p>
            {city},
            {provinceOptions.find((val) => val.value === province)?.label}
          </p>
          <p>
            {country}, {postalCode}
          </p>
        </div>
        <p className={styles.letterdesign}>
          {organizationName && organizationName.trimStart().substring(0, 1)}
        </p>
      </div>
      <hr className={styles.formHorizontalLine} />
      <div className={styles.flex_container_bottom}>
        <a
          href={websiteLink}
          className={styles.websiteLink}
          target="_blank"
          rel="noreferrer"
          onClick={(e) => {
            e.preventDefault();
            handleOpenLink(websiteLink);
          }}
         aria-label={"Visit Website"}
        >
          {websiteLink}
        </a>
        <p className={styles.Organizationsummary}>{organizationSummary}</p>
      </div>
    </div>
  );
};
