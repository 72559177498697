import axios from "axios";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { InvestorAPI } from "../../APIAction/InvestorAPI";
import { useUser } from "../../components/Context/UserContext";
import { InvestorInvestmentPreference } from "../../TypesAndInterfaces/InvestorDetailsInterface";
import {
  CLIMATE_CHANGE_CRITERIA,
  currencyFormat,
  DOLLAR_RANGE,
  FUNDING_TYPES_ONBOARDING,
  getKVArrayFormat,
  INVESTMENT_PHASES_ONBOARDING,
  LOCKUP_PERIOD,
  ROI_RANGE,
  VULNERABLE_GROUPS,
} from "../../Constants/Enumerations";
import styles from "./ViewInvestor.module.scss";

type Props = {
  investmentDollarMinimum: number;
  investmentDollarMaximum: number;
  provinceOptions: {
    label: string;
    value: number;
  }[];
  otherUserLoggedInflag: boolean;
};

export const ViewPreferences = ({
  provinceOptions,
  investmentDollarMinimum,
  investmentDollarMaximum,
  otherUserLoggedInflag,
}: Props) => {
  const { userState } = useUser();
  const { investorId = "" } = useParams();
  const navigate = useNavigate();
  const [PreferenceInfo, setPreferenceInfo] =
    useState<InvestorInvestmentPreference>();
  const [isDataExist, setIsDataExist] = useState(false);

  useEffect(() => {
    const getPreferenceInfomation = async (investorId: string) => {
      try {
        const res = await InvestorAPI.getPreferenceInfoById(investorId);
        const data = res.data;
        setIsDataExist(true);
        setPreferenceInfo(data);
      } catch (error) {
        setIsDataExist(false);
        if (axios.isAxiosError(error) && error.response?.status !== 404) {
          navigate("/api-error");
        }
      }
    };

    if (otherUserLoggedInflag && investorId !== "") {
      getPreferenceInfomation(investorId);
    } else if (
      userState.userId !== "" &&
      userState.userPersona.toLowerCase() === "investor"
    ) {
      getPreferenceInfomation(userState.userId);
    }
  }, [userState, navigate, otherUserLoggedInflag, investorId]);

  const { t } = useTranslation(["profile", "onboarding"]);

  const capitalInjectionTranslations = Object.keys(FUNDING_TYPES_ONBOARDING)?.filter((val:any) => val !== undefined && val !== "").map((val:any) =>
  t(`common:enumerations.funding_types_onboarding.${val}`)
  );
  
  const investmentFundingTypes = getKVArrayFormat(
    FUNDING_TYPES_ONBOARDING,
    capitalInjectionTranslations
  );

  const investmentPhasesTranslations = Object.keys(INVESTMENT_PHASES_ONBOARDING).map(
    (val) => t(`common:enumerations.investment_phases_onboarding.${val}`)
  );
  const investmentPhasesOptions = getKVArrayFormat(
    INVESTMENT_PHASES_ONBOARDING,
    investmentPhasesTranslations
  );

  const vulnerableGroupsTranslations = Object.keys(VULNERABLE_GROUPS).map(
    (val) => t(`common:enumerations.vulnerable_groups.${val}`)
  );
  const vulnerableGroupsOptions = getKVArrayFormat(
    VULNERABLE_GROUPS,
    vulnerableGroupsTranslations
  );

  const climateChangeTranslations = Object.keys(CLIMATE_CHANGE_CRITERIA).map(
    (val) => t(`common:enumerations.climate_change_criteria.${val}`)
  );
  const climateChangeCriteriaOptions = getKVArrayFormat(
    CLIMATE_CHANGE_CRITERIA,
    climateChangeTranslations
  );

  const lockupPeriodTrans = Object.keys(LOCKUP_PERIOD).map((val) =>
    t(`common:enumerations.lockup_period.${val}`)
  );

  const maximumLockupPeriodOptions: {
    label: string;
    value: string | number;
  }[] = getKVArrayFormat(LOCKUP_PERIOD, lockupPeriodTrans);

  const capitalCostTranslation = Object.keys(ROI_RANGE).map((val) =>
    t(`common:enumerations.roi_range.${val}`)
  );

  const roiTargets = getKVArrayFormat(ROI_RANGE, capitalCostTranslation);

  const constructionTypeOption = [
    {
      label: t("investor.preference_info.preferencetype_new_label"),
      value: "1",
    },
    {
      label: t("investor.preference_info.preferencetype_repair_label"),
      value: "2",
    },
  ];

  const investmentRegions =
    PreferenceInfo &&
    PreferenceInfo.investmentRegions &&
    PreferenceInfo.investmentRegions.length > 0 &&
    PreferenceInfo.investmentRegions.map((region) => {
      const province = provinceOptions.find(
        (val) => val.value === region.province
      )?.label;
      const city = region.city;
      return { province, city };
    });

  const dolllarRangesTrans = Object.keys(DOLLAR_RANGE).map((val) =>
    t(`common:enumerations.dollar_range.${val}`)
  );
  const dollarOptions = getKVArrayFormat(DOLLAR_RANGE, dolllarRangesTrans).map(
    ({ label, value }) => ({ label: label, value: value })
  );

  const investmentToDisplay =
    investmentDollarMinimum && investmentDollarMaximum
      ? `${currencyFormat(dollarOptions, investmentDollarMinimum)} 
  - ${currencyFormat(dollarOptions, investmentDollarMaximum)}`
      : "";

  function renderYesOrNoOptions(truthyValue: boolean) {
    if (truthyValue) {
      return t("onboarding:developer.orgnization_info_form.org_info.yes_label");
    }
    return t("onboarding:developer.orgnization_info_form.org_info.no_label");
  }

  return (
    <>
      {isDataExist && PreferenceInfo && (
        <div className={styles.content}>
          <p className={styles.mainHeading_investor}>
            {t("investor.preference_info.heading")}
          </p>
          {/* Contruction Types */}
          {PreferenceInfo.constructionType && (
            <p className={styles.investmentPreftypes}>
              {t("investor.preference_info.construction_type")}
            </p>
          )}
          <div className={styles.outercontainer}>
            <span className={styles.tag}>
              {
                constructionTypeOption.find(
                  (item) => +item.value === PreferenceInfo.constructionType
                )?.label
              }
            </span>
          </div>
          {/* Investment types */}
          {PreferenceInfo.investmentPhases &&
            PreferenceInfo.investmentPhases.length > 0 && (
              <p className={styles.investmentPreftypes}>
                {t("investor.preference_info.investment_type")}
              </p>
            )}
          <div className={styles.outercontainer}>
            {PreferenceInfo.investmentPhases &&
              PreferenceInfo.investmentPhases.length > 0 &&
              PreferenceInfo.investmentPhases.map((val, idx) => (
                <span key={idx} className={styles.tag}>
                  {
                    investmentPhasesOptions.find((obj) => obj.value === val)
                      ?.label
                  }
                </span>
              ))}
          </div>
          {/* Funding Types */}
          {PreferenceInfo.fundingTypes &&
            PreferenceInfo.fundingTypes.length > 0 && (
              <p className={styles.investmentPreftypes}>
                {t("investor.preference_info.funding_type")}
              </p>
            )}
          <div className={styles.outercontainer}>
            {PreferenceInfo.fundingTypes &&
              PreferenceInfo.fundingTypes.length > 0 &&
              PreferenceInfo.fundingTypes.map((val, idx) => (
                <span key={idx} className={styles.tag}>
                  {
                    investmentFundingTypes.find((obj) => obj.value === val)
                      ?.label
                  }
                </span>
              ))}
          </div>

          {/* Target ROI */}
          {(PreferenceInfo.roiTargetMinimum ||
            PreferenceInfo.roiTargetMaximum) && (
            <p className={styles.investmentPreftypes}>
              {t("investor.preference_info.target_ROI")}
            </p>
          )}
          <div className={styles.outercontainer}>
            <span className={styles.tag}>
              {
                roiTargets.find(
                  (obj) => obj.value === PreferenceInfo.roiTargetMinimum
                )?.label
              }{" "}
              -{" "}
              {
                roiTargets.find(
                  (obj) => obj.value === PreferenceInfo.roiTargetMaximum
                )?.label
              }
            </span>
          </div>

          {/* Maximum Lockup period */}
          {PreferenceInfo.maximumLockupPeriod && (
            <p className={styles.investmentPreftypes}>
              {t("investor.preference_info.target_period")}
            </p>
          )}
          <div className={styles.outercontainer}>
            <span className={styles.tag}>
              {
                maximumLockupPeriodOptions.find(
                  (obj) => obj.value === PreferenceInfo.maximumLockupPeriod
                )?.label
              }
            </span>
          </div>
          {/* Land Provide Flag */}
            <p className={styles.investmentPreftypes}>
              {t("investor.preference_info.provide_land")}
            </p>        
          <div className={styles.outercontainer}>
            <span className={styles.tag}>
              {renderYesOrNoOptions(PreferenceInfo.ableToProvideLandFlag)}
            </span>
          </div>
          {/* Range of Investment Dollars */}
          {(investmentDollarMinimum || investmentDollarMaximum) && (
            <p className={styles.investmentPreftypes}>
              {t("investor.preference_info.range_investment")}
            </p>
          )}
          <div className={styles.outercontainer}>
            <span className={styles.tag}> {investmentToDisplay}</span>
          </div>

          {/* Geographical focus */}
          {investmentRegions && (
            <p className={styles.investmentPreftypes}>
              {t("investor.preference_info.geographical_focus")}
            </p>
          )}
          <div className={styles.outercontainer}>
            {investmentRegions &&
              investmentRegions.map((region, index) => (
                <span
                  key={index}
                  className={styles.tag}
                >{`${region.province}, ${region.city} `}</span>
              ))}
          </div>
          {/* Vulnerable Groups  */}
          {PreferenceInfo.vulnerableGroups &&
            PreferenceInfo.vulnerableGroups.length > 0 && (
              <p className={styles.investmentPreftypes}>
                {t("investor.preference_info.vulnerable_group")}
              </p>
            )}
          <div className={styles.outercontainer}>
            {PreferenceInfo.vulnerableGroups &&
              PreferenceInfo.vulnerableGroups.length > 0 &&
              PreferenceInfo.vulnerableGroups.map((val, idx) => (
                <span key={idx} className={styles.tag}>
                  {
                    vulnerableGroupsOptions.find((obj) => obj.value === val)
                      ?.label
                  }
                </span>
              ))}
          </div>
          {/* Quality, Environment and Climate Change Criteria */}
          {PreferenceInfo.climateChangeCriteria &&
            PreferenceInfo.climateChangeCriteria.length > 0 && (
              <p className={styles.investmentPreftypes}>
                {t("investor.preference_info.climate_change")}
              </p>
            )}
          <div className={styles.outercontainer}>
            {PreferenceInfo.climateChangeCriteria &&
              PreferenceInfo.climateChangeCriteria.length > 0 &&
              PreferenceInfo.climateChangeCriteria.map((val, idx) => (
                <span key={idx} className={styles.tag}>
                  {
                    climateChangeCriteriaOptions.find(
                      (obj) => obj.value === val
                    )?.label
                  }
                </span>
              ))}
          </div>
        </div>
      )}
    </>
  );
};
