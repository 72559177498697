import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";

import { ProjectFinancialDetails } from "../../../TypesAndInterfaces/ProjectDetailsInterface";
import styles from "./FinancialDetailsChart.module.scss";
import { numberWithCommas } from "../../../Constants/Utils/FormateNumber";


import { PieChart, Pie, Tooltip, Label, Cell } from "recharts";

type Props = {
    financialDetail: ProjectFinancialDetails;
};

export function FinancialDetailsChart({ financialDetail }: Props) {
    const { t } = useTranslation(["project"]);
    const [totalFundingNeeded, setTotalFundingNeeded] = useState(0);
    const [width, setWidth] = useState(window.innerWidth);

    const [data, setData] = useState([{ name: "", amount: 0, color: '#009BB5' }]);   

    useEffect(() => {
        let dataTemp = [
        { name: t("project:project.financial_details.funding_types_chart.granting"), amount: 0, color: '#009BB5' },
        { name: t("project.financial_details.funding_types_chart.equity"), amount: 0, color: '#7A64D8' },
        { name: t("project.financial_details.funding_types_chart.debt_financing"), amount: 0, color: '#812822' },
        { name: t("project.financial_details.funding_types_chart.mezzanine_financing"), amount: 0, color: '#DA291C' },
        { name: t("project.financial_details.funding_types_chart.bridge_financing"), amount: 0, color: '#ED948D' },
        { name: t("project.financial_details.funding_types_chart.other_capital_outside_capital_connect"), amount: 0, color: '#CCCCCC' },
        { name: t("project.financial_details.funding_types_chart.capital_secured"), amount: 0, color: '#E6EFF3' },
        { name: t("project.financial_details.funding_types_chart.capital_needed"), amount: 0, color: '#004F71' },
        ];

        if (financialDetail.displayFundingFlag === false) {
            if (financialDetail.totalFundingNeeded) {
                setTotalFundingNeeded(financialDetail.totalFundingNeeded);

                dataTemp[7].amount = financialDetail.totalFundingNeeded;
            }
            if (financialDetail.capitalAcquiredAmount) {

                dataTemp[6].amount = financialDetail.capitalAcquiredAmount;
                //@ts-ignore
                dataTemp[5].amount = financialDetail.totalProjectCost - financialDetail.totalFundingNeeded - financialDetail.capitalAcquiredAmount;

            }
        } else {
            if (financialDetail.grantAmount) {
                dataTemp[0].amount = financialDetail.grantAmount;
            }
            if (financialDetail.equityAmount) {

                dataTemp[1].amount = financialDetail.equityAmount;
            }
            if (financialDetail.debtFinancingAmount) {

                dataTemp[2].amount = financialDetail.debtFinancingAmount;
            }
            if (financialDetail.bridgeFinancingAmount) {

                dataTemp[4].amount = financialDetail.bridgeFinancingAmount;
            }
            if (financialDetail.capitalAcquiredAmount) {
                dataTemp[6].amount = financialDetail.capitalAcquiredAmount;
                //@ts-ignore
                dataTemp[5].amount = financialDetail.totalProjectCost - financialDetail.totalFundingNeeded - financialDetail.capitalAcquiredAmount;
            }

            if (financialDetail.totalFundingNeeded) {
                setTotalFundingNeeded(financialDetail.totalFundingNeeded);
            }

        }

        dataTemp = dataTemp.filter(temp => temp.amount !== 0);

        setData(dataTemp);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    useEffect(() => {
        const handleResize = () => {
            setWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <div className={styles.summaryContainer} data-testid="summary-Container">
            <h4
                className={styles.chartTitle}
            >
                {t("project:project.financial_details.capital_connect_chart_title")} {"$" + numberWithCommas(String(totalFundingNeeded))}
            </h4>
            <div
                className={styles.chartList}
            >
                <div
                    className={styles.chartPie}
                >
                    <PieChart
                        width={width < 960 ? 250 : 300}
                        height={width < 960 ? 250 : 300}
                    >
                        <Tooltip formatter={(value) => `$${numberWithCommas(String(value))}`}/>
                        <Pie
                            data={data}
                            dataKey="amount"
                            outerRadius={width < 960 ? 110 : 150}
                            innerRadius={width < 960 ? 90 : 120}
                        >
                            {data.map((entry, index) => (
                                <Cell key={`cell-${index}`}
                                    fill={entry.color}
                                />
                            ))}

                            <Label
                                value={`${t("project:project.financial_details.total_project_cost_label")} $${numberWithCommas(String(financialDetail.totalProjectCost))}`}
                                position="center"
                                width={width < 960 ? 150 : 300}
                            />

                        </Pie>
                    </PieChart>
                </div>
                <div

                    className={styles.chartOptions}

                >
                    {data.map((entry, index) => (
                        <div
                            className={styles.options}
                        >
                            <div>
                                <svg height="10" width="10">
                                    <circle cx="5" cy="5" r="4" stroke="black" stroke-width="1" fill={entry.color} />
                                    </svg> &nbsp; {entry.name} &nbsp;({"$" + numberWithCommas(String(entry.amount))})
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}
