import { FieldArray, Form, Formik } from "formik";
import { SyntheticEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { ProvincesSelect } from "../common/ProvincesSelect";
import { RadioInput } from "../common/RadioInput";
import { TextArea } from "../common/TextArea";
import { TextInput } from "../common/TextInput";
import { FieldArrayAddButton } from "../common/FieldArrayAddButton";
import { FieldArrayRemoveButton } from "../common/FieldArrayRemoveButton";
import styles from "./DevelopmentExperience.module.scss";
import { DeveloperApi } from "../../../APIAction/DeveloperAPI";
import { useNavigate } from "react-router-dom";
import { useUser } from "../../Context/UserContext";
import { DeveloperExperience } from "../../../TypesAndInterfaces/DeveloperDetailsInterface";
import { ProjectLocationFields } from "../AddProject/ProjectLocationFields";
import { PROVINCES_CODES } from "../../../Constants/Enumerations";
import { City } from "country-state-city";

type Props = {
  handleResponse: (
    formLable: string,
    successStatus: boolean,
    error?: any
  ) => void;
  developerId?: string
};

export function DevelopmentExperience({ handleResponse, developerId }: Props) {
  const { t } = useTranslation("onboarding");

  const { userState } = useUser();

  const [isDataAvailable, setIsDataAvailable] = useState<boolean>(false);
  const [developerExperienceData, setDeveloperExperienceData] =
    useState<DeveloperExperience>();
  const navigate = useNavigate();

  useEffect(() => {
    const getDeveloperData = async () => {
      try {
        const response = await DeveloperApi.getDeveloperInfoById(
          userState.userId
        );
        const data = response.data;
        setIsDataAvailable(true);
        setDeveloperExperienceData(data);
      } catch (error) {
        setIsDataAvailable(false);
        handleResponse("GeneralInfo", false, error);
      }
    };
    userState.userId !== "" && getDeveloperData();
  }, [userState.userId, handleResponse]);

  const initialExperienceValues = {
    projectName: "",
    province: "",
    city: "",
    projectDescription: "",
  };
  let developerExpProjects: any = [];
  if (developerExperienceData?.projects) {
    developerExpProjects = developerExperienceData?.projects.map((val: any) => {
      const selectedProvince = PROVINCES_CODES.find((element) => element.value === Number(val?.province));
      const isoCode = selectedProvince && selectedProvince.isoCode;
      const cityList = isoCode && City.getCitiesOfState('CA', isoCode);
      const formattedCityOptions = cityList && cityList.map(city => ({ label: city.name, value: city.name }));

      // Check if the user's city code matches any city in the city list
      const matchingCity = formattedCityOptions && formattedCityOptions.find(city => city.value === val?.city);
      return {
        city: matchingCity ? matchingCity?.label : "",
        projectName: val?.projectName,
        projectDescription: val?.projectDescription,
        province: val?.province,
      };
    });
  }

  const developerValuesFromAPI = {
    developingExperienceFlag: developerExperienceData?.developingExperienceFlag
      ? "true"
      : "false",
    projects: developerExpProjects,
  };

  const initialValues = {
    developingExperienceFlag: "",
    projects: [initialExperienceValues],
  };

  const generalRadioOptions = [
    {
      label: t("developer.main_page.developerinfo_form.yes_lable"),
      value: "true",
    },
    {
      label: t("developer.main_page.developerinfo_form.no_lable"),
      value: "false",
    },
  ];

  const maxCharsErrorText = t("investor.general_form_error.max_characters", {
    charNum: 64,
  });

  const generalInfoSchema = Yup.object().shape({
    developingExperienceFlag: Yup.boolean().required(
      t("developer.developer_form_error.previouse_experince_requires")
    ),
    projects: Yup.array()
      .of(
        Yup.object().shape({
          projectName: Yup.string()
            .required(t("investor.general_form_error.general_required"))
            .max(64, maxCharsErrorText),
          province: Yup.string().required(
            t("investor.general_form_error.province_required")
          ),
          city: Yup.string()
            .required(t("investor.general_form_error.city_required"))
            .max(64, maxCharsErrorText),
          projectDescription: Yup.string()
            .required(t("investor.general_form_error.general_required"))
            .max(
              1000,
              t("investor.general_form_error.max_characters", { charNum: 1000 })
            ),
        })
      )
      .max(5, "A maximum of 5 is permitted")
      .nullable(true),
  });

  const updateDeveloperProjectData = async (formData: Object) => {
    try {
      await DeveloperApi.updateDeveloperInfoById(userState.userId, {
        developerID: userState.userId,
        ...formData,
      });
      handleResponse("DeveloperInfo", true);
      navigate("/profile/developer/success");
    } catch (error) {
      handleResponse("DeveloperInfo", false, error);
    }
  };

  const submitHandler = async (formData: object) => {
    try {
      await DeveloperApi.postDeveloperInfo({
        developerID: userState.userId,
        ...formData,
      });
      handleResponse("DeveloperInfo", true);
    } catch (error) {
      handleResponse("DeveloperInfo", false, error);
    }
  };

  return (
    <Formik
      initialValues={isDataAvailable ? developerValuesFromAPI : initialValues}
      validateOnChange={false}
      validateOnBlur={false}
      enableReinitialize={true}
      validationSchema={generalInfoSchema}
      onSubmit={(values) => {
        const { developingExperienceFlag, ...rest } = values;
        const updatedValues = {
          developingExperienceFlag:
            developingExperienceFlag === "true" ? true : false,
          developerID: userState.userId === "" ? developerId : userState.userId,
          ...rest,
        };
        isDataAvailable
          ? updateDeveloperProjectData(updatedValues)
          : submitHandler(updatedValues);
      }}
    >
      {({ values, setFieldValue }) => (
        <Form noValidate>
          <h3
            className={styles.developerExperienceHeading}
            data-testid="developer-heading"
          >
            {t(
              "developer.main_page.developerinfo_form.previous_experience_heading"
            )}
          </h3>
          <p
            className={styles.developerExperienceInfo}
            data-testid="developer-info"
          >
            {t(
              "developer.main_page.developerinfo_form.previous_experience_info"
            )}
          </p>
          <p className={styles.developerExperienceQuestion}>
            {t(
              "developer.main_page.developerinfo_form.previous_experience_question"
            )}
          </p>
          <RadioInput
            fieldName="developingExperienceFlag"
            data-testid="experience-flag"
            options={generalRadioOptions}
            onClick={(e: SyntheticEvent<HTMLInputElement>) => {
              if (e.currentTarget.value === "true") {
                setFieldValue("projects", [initialExperienceValues]);
              } else if (e.currentTarget.value === "false") {
                setFieldValue("projects", []);
              }
            }}
          />
          {values.developingExperienceFlag === "true" && (
            <FieldArray
              name="projects"
              render={(arrayHelpers) => (
                <div>
                  {values.developingExperienceFlag &&
                    values.projects &&
                    values.projects.length > 0
                    ? values.projects.map((exp:any, index:number) => (
                      <div key={index}>
                        <hr className={styles.horizontalLine} />
                        <div className={styles.developerExperience}>
                          <p className={styles.developerExperienceTitle}>
                            {t(
                              "developer.main_page.developerinfo_form.development_experience_title"
                            )}
                            {index > 0? ' ' + (index +1): ' ' + 1}
                          </p>
                          {values.projects.length > 1 && (
                            <FieldArrayRemoveButton
                              onClickHandler={() =>
                                arrayHelpers.remove(index)
                              }
                              buttonText={t(
                                "developer.main_page.developerinfo_form.development_experience_remove"
                              ) +' '+ (index +1)}
                            />
                          )}
                        </div>
                        <TextInput
                          fieldName={`projects[${index}].projectName`}
                          labelText={t(
                            "developer.main_page.developerinfo_form.previous_project_name"
                          )}
                        />

                        <ProjectLocationFields 
                          name={`projects[${index}]`} 
                          province={values?.projects && values?.projects[index]?.province!} 
                          placeholder={t("developer.main_page.developerinfo_form.province_placeholder")}/>
                        <TextArea
                          fieldName={`projects[${index}].projectDescription`}
                          labelText={t(
                            "developer.main_page.developerinfo_form.previous_project_brief_detail_heading"
                          )}
                        />
                      </div>
                    ))
                    : null}
                  {values.projects &&
                    values.projects.length > 0 &&
                    values.projects.length < 5 ? (
                    <div className={styles.addDeveloperButton}>
                      <FieldArrayAddButton
                        onClickHandler={() =>
                          arrayHelpers.push(initialExperienceValues)
                        }
                        buttonText={t(
                          "developer.main_page.developerinfo_form.development_experience_add"
                        )}
                      />
                    </div>
                  ) : null}
                </div>
              )}
            />
          )}
          <button
            type="submit"
            className={styles.formButton}
            data-testid="submitButton"
          >
            {isDataAvailable
              ? t("investor.investment_preferences_form.save_button")
              : t(
                "developer.main_page.developerinfo_form.project_info_submit_button"
              )}
          </button>
        </Form>
      )}
    </Formik>
  );
}
