import React from "react";
import { useTranslation } from "react-i18next";

import styles from "./FinancialDetails.module.scss";
import { FinancialDetailHighlightSingle } from "./FinancialDetailHighlightSingle";
import { AffordabilityProjectBadge } from "../../Developers/ProjectDetails/AffordabilityProjectBadge";

import {
  getKVArrayFormatForDropdown,
  COMMITMENT_TO_AFFORDABILITY,
} from "../../../Constants/Enumerations";

type Props = {
  affordabilityDetails: any;
  data?:any;
};

export function AffordabilityDetails({ affordabilityDetails,data }: Props) {

  const { t } = useTranslation(["profile", "onboarding", "project"]);
  const commitmentAffordabilityTrans = Object.keys(COMMITMENT_TO_AFFORDABILITY).map((val) =>
    t(`common:enumerations.commitment_to_affordability.${val}`)
  );
  const commitmentAffordabilityOptions: {
    label: string;
    value: string | number;
  }[] = getKVArrayFormatForDropdown(COMMITMENT_TO_AFFORDABILITY, commitmentAffordabilityTrans);



  return (
    <div className={styles.summaryContainer} data-testid="summary-Container">
      <React.Fragment>

        {affordabilityDetails.projectAffordabilityTypes.map((projectBadge: number, idx: number) => (
          <AffordabilityProjectBadge
            highlightLabel={idx === 0 ? t("portfolio:portfolio_details.affordability_details.type_of_affordability") : ""}
            highlightValue={projectBadge}
            projectCountData = {data}
          />
        ))}
        {affordabilityDetails.affordabilityDescription !== " " &&
          <FinancialDetailHighlightSingle
            highlightLabel={t("portfolio:portfolio_details.affordability_details.affordability_description")}
            highlightValue={affordabilityDetails.affordabilityDescription ? affordabilityDetails.affordabilityDescription : ""}
          />
        }
      </React.Fragment>

    </div>
  );
}
