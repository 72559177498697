import { useTranslation } from "react-i18next";
import { OrganizationIcon } from "../../../assets/icons";
import styles from "./ReviewInfo.module.scss";
import { DeveloperApi } from "../../../APIAction/DeveloperAPI";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useUser } from "../../../components/Context/UserContext";
import {
  FINANCING_TYPE,
  getKVArrayFormat,
  ORGANIZATION_TYPE,
  PROJECT_SIZE,
  PROVINCES,
} from "../../../Constants/Enumerations";
import { DeveloperOrganizationInfo } from "../../../TypesAndInterfaces/DeveloperDetailsInterface";

export const ReviewOrganizationInfo = () => {
  const { t } = useTranslation("onboarding");
  const { userState } = useUser();
  const navigate = useNavigate();
  const [dataExists, setDataExists] = useState<boolean>(false);
  const [organizationInfoData, setOrganizationInfoData] =
    useState<DeveloperOrganizationInfo>();
  const { developerId = "" } = useParams();

  useEffect(() => {
    const OrganizationInfoDataFetch = async () => {
      const response = await DeveloperApi.getOrganizationInfoById(
        developerId
      );
      setOrganizationInfoData(response.data);
      //setOrganizationInfoData(tmpData);
      setDataExists(true);
    };
    OrganizationInfoDataFetch();
  }, [userState.userId]);

  const provincesTranslated = Object.keys(PROVINCES).map((val) =>
    t(`common:enumerations.provinces.${val}`)
  );
  const provinceOptions = getKVArrayFormat(PROVINCES, provincesTranslated);

  const OrganizationTypeTranslations = Object.keys(ORGANIZATION_TYPE).filter(val => val !== undefined && val !== "").map(
    (val) => t(`common:enumerations.organization_type.${val}`)
  );
  const OrganizationTypeOptions = getKVArrayFormat(
    ORGANIZATION_TYPE,
    OrganizationTypeTranslations
  );

  const organizationType =
    organizationInfoData?.organizationType &&
    organizationInfoData.organizationType.length > 0 &&
    organizationInfoData.organizationType.map(
      (val, idx) =>
        OrganizationTypeOptions.find((obj) => obj.value === val)?.label
    );

  const FinancingPreviouslyReceivedTranslations = Object.keys(
    FINANCING_TYPE
  ).map((val) => t(`common:enumerations.financing_type.${val}`));

  const FinancingPreviouslyReceivedOptions = getKVArrayFormat(
    FINANCING_TYPE,
    FinancingPreviouslyReceivedTranslations
  );

  const financingType =
    organizationInfoData?.financingPreviouslyReceived &&
    organizationInfoData.financingPreviouslyReceived.length > 0 &&
    organizationInfoData.financingPreviouslyReceived.map(
      (val, idx) =>
        FinancingPreviouslyReceivedOptions.find((obj) => obj.value === val)
          ?.label
    );

  const averageProjectSizeTranslation = Object.keys(PROJECT_SIZE).map((val) =>
    t(`common:enumerations.project_size.${val}`)
  );
  const averageProjectSizeRange = getKVArrayFormat(
    PROJECT_SIZE,
    averageProjectSizeTranslation
  );

  const averageProjectSizeValue =
    organizationInfoData &&
    averageProjectSizeRange.find(
      (obj) => obj.value === organizationInfoData.averageProjectSize
    )?.label;

  const handleEditClick = () => {
    navigate("/onboarding/developer/edit");
  };

  return (
    <div className={styles.informationContainer}>
      <div className={styles.infoHeadingContainer}>
        <div className={styles.left}>
          <div className={styles.imgContainer}>
            <img src={OrganizationIcon} alt="" />
          </div>
          <h3 className={styles.sectionHeading}>
            {t("developer.review_profile.org_info.org_info_heading")}
          </h3>
        </div>
        <button className={styles.link} onClick={handleEditClick}>
          {t("developer.review_profile.edit_link")}
        </button>
      </div>
      {dataExists && organizationInfoData && (
        <div className={styles.content}>
          <div className={styles.grid_container}>
            <p>{t("developer.review_profile.org_info.year_establishment")}</p>
            <p>{organizationInfoData.establishmentYear}</p>
            <p>{t("developer.review_profile.org_info.org_type")}</p>
            <p>
              {organizationType &&
                organizationType?.map(
                  (val, idx) =>
                    `${val}${idx < organizationType.length - 1 ? ", " : ""}`
                )}
            </p>
          </div>
          <hr className={styles.horizontalLine} />
          <p className={styles.title}>
            {t("developer.review_profile.org_info.focus_area")}
          </p>
          <div className={styles.grid_container}>
            <p>{t("developer.review_profile.org_info.population_focus")}</p>
            <p>{organizationInfoData.populationFocus}</p>
            <p>{t("developer.review_profile.org_info.org_impact_desc")}</p>
            <p>{organizationInfoData.impactDescription}</p>
            {organizationInfoData.regionalFocus.length > 0 &&
              organizationInfoData.regionalFocus.map((region, index) => {
                return (
                  <>
                    <p>
                      {t("developer.review_profile.org_info.regional_focus")}
                    </p>
                    <p>
                      {`${provinceOptions.find(
                        (val) => val.value === region.province
                      )?.label
                        }, ${region.city}`}
                    </p>
                  </>
                );
              })}
          </div>
          <hr className={styles.horizontalLine} />
          <p className={styles.title}>
            {t("developer.review_profile.org_info.financial_detail")}
          </p>
          <div className={styles.grid_container}>
            <p>{t("developer.review_profile.org_info.avg_project_size")}</p>
            <p>{averageProjectSizeValue}</p>
            <p>{t("developer.review_profile.org_info.affordability")}</p>
            <p>{organizationInfoData.affordabilityOffered}</p>
            <p>{t("developer.review_profile.org_info.finance_recived")}</p>
            <p>
              {financingType &&
                financingType?.map(
                  (val, idx) =>
                    `${val}${idx < financingType.length - 1 ? ", " : ""}`
                )}
            </p>
          </div>
        </div>
      )}
    </div>
  );
};
